import React from 'react';

import { CheckboxInput } from 'components/UI/InputComponent/styles';

function TableCheckbox({ isChecked, onChange, isDisabled, label }) {
  return (
    <CheckboxInput
      data-checkbox-for={label}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
}

export default TableCheckbox;
