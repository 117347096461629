import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { systemUserInProgressSelector, notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';
import paths from '../../../../constants/paths';
import { systemTypesKeys, getTierStatusNames, getTierTypeNames } from '../../../../constants';
import TiersTable from './TiersTable';
import {
  getProgressSystemMessage,
  getSystemHaveUpdetedUserMessage,
} from '../../../../constants/tooltips';

function SystemTiersPage({ withSystemHeader = true }) {
  const { t, i18n } = useTranslation();
  const tierStatusNames = getTierStatusNames();
  const tierTypeNames = getTierTypeNames();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const systemUserInProgress = useSelector(systemUserInProgressSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const systemUserInProgressMessage = useMemo(
    () => (systemUserInProgress ? getSystemHaveUpdetedUserMessage() : null),
    [systemUserInProgress, i18n.language]
  );
  const [isShowFilters, setIsShowFilters] = useState(false);

  const filterData = [
    {
      id: 'q',
      name: t('common.name'),
    },
    {
      id: 'type',
      name: t('pages.systemTierPage.tierType'),
      type: 'multiSelect',
      options: formatFilterOptions(tierTypeNames),
    },
    {
      id: 'currentRelease',
      name: t('common.currentRelease'),
      isHidden: systemType === systemTypesKeys.EXTERNAL,
    },
    {
      id: 'serverTierNameQuery',
      name: t('common.serverTier'),
      isHidden: systemType === systemTypesKeys.EXTERNAL,
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(tierStatusNames),
    },
    {
      id: 'date',
      name: t('common.updateDate'),
      type: 'date',
    },
  ];
  const systemTiersfilters = useMultiSelectFilterSection(filterData, 'tiers');

  const canTierCreate =
    systemId &&
    (systemType === systemTypesKeys.EXTERNAL
      ? checkedPermissions(scopes.platform.externalSystemCreateTier, true)
      : checkedPermissions(scopes.tier.tierCreate) ||
        checkedPermissions(scopes.tier.tierCreateDev));

  const disabledMessage = useMemo(
    () => notActiveSystemMessage || systemUserInProgressMessage,
    [systemId, notActiveSystemMessage, systemUserInProgressMessage, i18n.language]
  );

  const addTierButton = (
    <>
      {canTierCreate && (
        <Tooltip
          className={disabledMessage ? undefined : 'd-none'}
          content={disabledMessage}
          exitDelay={150}
          animationDuration={150}
        >
          <div>
            <BlueButton
              isDisabled={disabledMessage}
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemTiersFullPaths.addTier, {
                    systemType,
                    systemId,
                    action: paths.systemTierPaths.addTier,
                  })}
                />
              )}
            >
              {t('common.add')}
            </BlueButton>
          </div>
        </Tooltip>
      )}
    </>
  );

  return (
    <PageLayout
      title={t('common.tiers')}
      systemLayout
      withSystemHeader={withSystemHeader}
      withSystemSelector
      leftSideContent={isShowFilters && <>{addTierButton}</>}
    >
      <TiersTable
        addButton={addTierButton}
        tableFilters={systemTiersfilters}
        setIsShowFilters={setIsShowFilters}
        isShowFilters={isShowFilters}
      />
    </PageLayout>
  );
}

export default SystemTiersPage;
