import React from 'react';
import { generatePath, useRouteMatch } from 'react-router';

import StatusComponent, { getMilestoneStatus } from 'components/Common/StatusComponent';
import paths from 'constants/paths';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

function MilestoneStatusInfo({ milestone }) {
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const milestoneStatus = getMilestoneStatus();

  if (!milestone) {
    return '-';
  }

  return (
    <div className="d-flex flex-row ">
      {milestone?.status ? (
        <LinkWithPermission
          system={milestone?.system}
          scope={scopes.milestone.milestoneViewForm}
          to={generatePath(
            paths.systemFullPaths.milestones + paths.systemMilestonesPaths.milestone,
            {
              systemId,
              systemType,
              milestoneId: milestone.uuid,
            }
          )}
          isTableLink
        >
          <StatusComponent
            statusId={milestone?.status}
            statusWithTooltip
            statuses={milestoneStatus}
          >
            {milestone?.name}
          </StatusComponent>
        </LinkWithPermission>
      ) : (
        milestone?.name
      )}
    </div>
  );
}

export default MilestoneStatusInfo;
