import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';

import useFormDynamicFields from 'hooks/useFormDynamicFields';

import DevelopersForm from './DevelopersForm';
import { SystemBlockWrapper } from '../../styles';

const dynamicParentField = 'developers';

function Developer() {
  const { t } = useTranslation();
  const { fieldUuids } = useFormDynamicFields(dynamicParentField);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('step', 4);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.developer')}</div>

      {fieldUuids.map((fieldUuid, index) => (
        <DevelopersForm key={fieldUuid} fieldUuid={fieldUuid} index={index} />
      ))}
    </SystemBlockWrapper>
  );
}

export default Developer;
