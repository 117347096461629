import React, { useState } from 'react';
import * as rdrLocales from 'react-date-range/dist/locale';
import onClickOutside from 'react-onclickoutside';
import { useTranslation } from 'react-i18next';

import C from 'constants/C';
import * as styles from './styles';

const RangePicker = ({ saveDate, cancelDate, startDate, endDate, filterStyles }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [range, setRange] = useState([
    {
      startDate,
      endDate,
      key: C.common.notifications.calendarSettings.KEY_RANGE,
    },
  ]);

  RangePicker.handleClickOutside = () => {
    cancelDate();
  };
  const locale = i18n.language.slice(0, 2) === 'ru' ? rdrLocales.ru : rdrLocales.enUS;

  return (
    <styles.OutsideHeaderWrapper filterStyles={filterStyles}>
      <styles.OutsideHeader onClick={cancelDate} />
      <styles.DatePickerWrapper>
        <styles.DatePicker
          locale={locale}
          onChange={item => setRange([item.selection])}
          ranges={range}
          months={C.common.notifications.calendarSettings.COUNT_MONTHS_IN_CALENDAR}
          showMonthAndYearPickers={false}
          weekdayDisplayFormat={C.common.dateFormat.WEEK_DAY}
          monthDisplayFormat={C.common.dateFormat.FULL_MONTH_WITH_YEAR}
          direction={C.common.notifications.calendarSettings.TYPE}
          rangeColors={C.common.notifications.calendarSettings.COLORS}
        />
        <styles.Buttons>
          <styles.ApplyButton
            onClick={() => {
              saveDate(range);
            }}
          >
            {t('misc.notifications.calendar.APPLY')}
          </styles.ApplyButton>
          <styles.CancelButton
            onClick={() => {
              cancelDate();
            }}
          >
            {t('misc.notifications.calendar.CANCEL')}
          </styles.CancelButton>
        </styles.Buttons>
      </styles.DatePickerWrapper>
    </styles.OutsideHeaderWrapper>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => RangePicker.handleClickOutside,
};

export default onClickOutside(RangePicker, clickOutsideConfig);
