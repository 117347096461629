import React from 'react';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
  Tooltip,
} from '@patternfly/react-core';
import { ReactComponent as HotfixSVG } from 'assets/images/releases/hotfix.svg';
import { useTranslation } from 'react-i18next';

import RelativeDate from 'components/Common/RelativeDate';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';

import * as S from './styles';

function ReleaseInfo({ releaseData }) {
  const { t } = useTranslation();

  if (!releaseData) {
    return null;
  }

  return (
    <DescriptionList
      columnModifier={{
        default: '2Col',
      }}
      className="m-4 w-75 font-14"
    >
      <S.DescriptionListTermWrapper>
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.version')}</DescriptionListTerm>
          <DescriptionListDescription>
            <div className="d-flex">
              {releaseData.name}
              {releaseData.hotfix && (
                <div className="d-flex">
                  <Tooltip content={t('common.hotfix')} exitDelay={150} animationDuration={150}>
                    <HotfixSVG className="ml-2" />
                  </Tooltip>
                </div>
              )}
            </div>
          </DescriptionListDescription>
          <DescriptionListTerm className="mt-3">{t('common.description')}</DescriptionListTerm>
          <DescriptionListDescription>{releaseData.description || '-'}</DescriptionListDescription>
        </DescriptionListGroup>
      </S.DescriptionListTermWrapper>
      <S.DescriptionListTermWrapper>
        <DescriptionListGroup />
      </S.DescriptionListTermWrapper>
      {releaseData?.createdAt && (
        <>
          <DescriptionListGroup>
            <DescriptionListTerm>{t('pages.systemReleasePage.createdAt')}</DescriptionListTerm>
            <DescriptionListDescription>
              <RelativeDate date={releaseData.createdAt} withSeconds position="right" />
            </DescriptionListDescription>
          </DescriptionListGroup>
          <DescriptionListGroup />
        </>
      )}
      {!!releaseData.approvedAt && (
        <>
          <DescriptionListGroup>
            <DescriptionListTerm>{t('pages.systemReleasePage.approvalDate')}</DescriptionListTerm>
            <DescriptionListDescription>
              <RelativeDate date={releaseData.approvedAt} withSeconds position="right" />
            </DescriptionListDescription>
          </DescriptionListGroup>
          <DescriptionListGroup />
        </>
      )}
      {releaseData.cancelReason && (
        <>
          <DescriptionListGroup>
            <DescriptionListTerm>{t('pages.systemReleasePage.cancelReason')}</DescriptionListTerm>
            <DescriptionListDescription>{releaseData.cancelReason}</DescriptionListDescription>
          </DescriptionListGroup>
          <DescriptionListGroup />
        </>
      )}
      {!!releaseData.cancelledAt && (
        <>
          <DescriptionListGroup>
            <DescriptionListTerm>{t('pages.systemReleasePage.cancelDate')}</DescriptionListTerm>
            <DescriptionListDescription>
              <RelativeDate date={releaseData.cancelledAt} withSeconds position="right" />
            </DescriptionListDescription>
          </DescriptionListGroup>
          <DescriptionListGroup />
        </>
      )}
      <DescriptionListGroup>
        <DescriptionListTerm>{t('common.updatedBy')}</DescriptionListTerm>
        <DescriptionListDescription>
          <LastUpdatedStatusWithLink
            lastUpdatedBy={releaseData.lastUpdatedBy}
            lastUpdatedAt={releaseData.lastUpdatedAt}
          />
        </DescriptionListDescription>
      </DescriptionListGroup>
      <DescriptionListGroup />
    </DescriptionList>
  );
}

export default ReleaseInfo;
