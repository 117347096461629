import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import paths from 'constants/paths';

import AdminRoute from 'routes/AdminRoute';
import ChooseSystemPage from 'pages/AddNewSystemRouter/ChooseSystemPage';
import AddSystemPage from 'pages/AddNewSystemRouter/AddSystemPage';
import { scopes } from 'constants/scopes';

const AddNewSystemRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute
      exact
      path={path}
      scope={scopes.platform.systemCreate}
      component={ChooseSystemPage}
    />

    <AdminRoute
      exact
      path={`${path}${paths.newSystemPaths.add}`}
      scope={scopes.platform.systemCreate}
      component={AddSystemPage}
    />

    <Redirect to={paths.routePaths.newSystem} />
  </Switch>
);
export default AddNewSystemRouter;
