import React from 'react';
import { PopoverPosition } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import PopoverButton from 'components/Common/PopoverButton';
import { ContactIcon } from 'components/UI/ContactIcon';
import { TextButton } from 'components/UI/Button';

import * as S from './styles';

const checkingTargetId = 'target';

function ServiceEndpointData({ serviceEndpointTo, key }) {
  const { t } = useTranslation();

  const bodyContent = (
    <S.Wrapper id={checkingTargetId}>
      <div className="d-sm-flex flex-column justify-content-between pt-2" id={checkingTargetId}>
        <div className="d-sm-flex flex-column" id={checkingTargetId}>
          <div className="font-weight-medium font-14 mb-2" id={checkingTargetId}>
            {t('pages.systemIntegrationRelationsPage.inRequest')}
          </div>
          <div id={checkingTargetId}>
            {serviceEndpointTo?.requestData?.map(item => (
              <S.DataItem id={checkingTargetId}>{item}</S.DataItem>
            ))}
          </div>
        </div>
      </div>
      <div className="d-sm-flex flex-column justify-content-between pt-2" id={checkingTargetId}>
        <div className="d-sm-flex flex-column" id={checkingTargetId}>
          <div className="font-weight-medium font-14 mb-2" id={checkingTargetId}>
            {t('pages.systemIntegrationRelationsPage.inResponse')}
          </div>
          <div id={checkingTargetId}>
            {serviceEndpointTo?.responseData?.map(item => (
              <S.DataItem id={checkingTargetId}>{item}</S.DataItem>
            ))}
          </div>
        </div>
      </div>
    </S.Wrapper>
  );

  return (
    <div>
      <PopoverButton
        headerContent={t('pages.systemIntegrationRelationsPage.rquestResponseData')}
        bodyContent={bodyContent}
        popoverPosition={PopoverPosition.top}
      >
        <TextButton variant="link" key={key} icon={<ContactIcon />}>
          {t('pages.systemIntegrationRelationsPage.rquestResponseData')}
        </TextButton>
      </PopoverButton>
    </div>
  );
}

export default ServiceEndpointData;
