import http from 'api/config';

const getServiceEndpoints = ({ systemId, ...params }) =>
  http.get(`systems/${systemId}/service-endpoints`, { params });

const getServiceEndpointById = (systemId, endpointsId) =>
  http.get(`systems/${systemId}/service-endpoints/${endpointsId}`);

const updateServiceEndpoints = (systemId, body) =>
  http.put(`systems/${systemId}/service-endpoints`, body);

// TODO change
const createServiceEndpoints = (systemId, body) =>
  http.post(`systems/${systemId}/service-endpoints`, body);

const deleteServiceEndpoints = (systemId, serviceId) =>
  http.delete(`systems/${systemId}/service-endpoints/${serviceId}`);

const createServiceEndpointsTiers = (systemId, serviceId, body) =>
  http.post(`systems/${systemId}/service-endpoints/${serviceId}/tiers`, body);

const deleteServiceEndpointsTiers = (systemId, serviceId, tiersId) =>
  http.delete(`systems/${systemId}/service-endpoints/${serviceId}/tiers/${tiersId}`);

export default {
  getServiceEndpoints,
  getServiceEndpointById,
  updateServiceEndpoints,
  createServiceEndpoints,
  deleteServiceEndpoints,
  createServiceEndpointsTiers,
  deleteServiceEndpointsTiers,
};
