export const milestonesAction = {
  edit: 'edit',
  new: 'new',
};

export const systemMilestonesPaths = {
  new: `/${milestonesAction.new}`,
  edit: `/:milestoneId/:action(${milestonesAction.edit})`,
  milestone: `/:milestoneId`,
  issuesNew: `/issues/new`,
  milestoneIssuesNew: `/:milestoneId/issues/new`,
  milestoneIssuesEdit: `/issues/:issuesId/:action(${milestonesAction.edit})`,
  milestoneIdIssuesEdit: `/:milestoneId/issues/:issuesId/:action(${milestonesAction.edit})`,
};
