import { createSelector } from 'reselect';
import { checkedServerError } from 'utils';

const tierState = state => state.tiers;

export const tiersSelector = createSelector([tierState], tiers => tiers);

export const currentTierSelector = createSelector([tierState], ({ tierById }) => tierById);

export const currentTierIsLoadingSelector = createSelector(
  [tierState],
  ({ tierByIdIsLoading }) => tierByIdIsLoading
);

export const currentTierErrorSelector = createSelector([tierState], ({ tierByIdError }) =>
  checkedServerError(tierByIdError)
);

export const serverTierStandsSelector = createSelector(
  [tierState],
  ({ serverTiers }) => serverTiers
);
