import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    data: [],
    isLoading: true,
    total: 0,
    isError: null,
    limit: null,
    importService: {},
    importServiceIsLoading: true,
    importServiceIsError: null,
    repositories: { data: [], isLoading: true, total: 0, isError: null },
    selectedRepositories: { data: [], isLoading: true, total: 0, isError: null },
    serviceByActiveStatus: {},
    serviceByActiveStatusLoading: true,
  },
  reducers: {
    clearServicesState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.limit = null;
    },
    clearImportServicesState: state => {
      state.importService = {};
      state.importServiceIsLoading = true;
      state.importServiceIsError = null;
    },
    clearRepositoriesState: state => {
      state.repositories = { data: [], isLoading: true, total: 0, isError: null };
    },
    clearSelectedRepositoriesState: state => {
      state.selectedRepositories = { data: [], isLoading: true, total: 0, isError: null };
    },
  },
  extraReducers: {
    [actions.fetchServices.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchServices.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.limit = meta?.paging?.limit;
      state.isError = null;
    },
    [actions.fetchServices.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
    [actions.fetchImportService.pending]: state => {
      state.importServiceIsLoading = true;
      state.importServiceIsError = null;
    },
    [actions.fetchImportService.fulfilled]: (state, { payload }) => {
      state.importService = payload;
      state.importServiceIsLoading = false;
      state.importServiceIsError = null;
    },
    [actions.fetchImportService.rejected]: (state, { error }) => {
      state.importServiceIsLoading = false;
      state.importServiceIsError = checkedServerError(error.message);
    },
    [actions.fetchRepositories.pending]: state => {
      state.repositories.isLoading = true;
      state.repositories.isError = null;
    },
    [actions.fetchRepositories.fulfilled]: (state, { payload: { data, meta } }) => {
      state.repositories.data = data;
      state.repositories.isLoading = false;
      state.repositories.total = meta.total;
      state.repositories.isError = null;
    },
    [actions.fetchRepositories.rejected]: (state, { error }) => {
      state.repositories.isLoading = false;

      if (checkedServerError(error.message)) {
        state.repositories.data = [];
        state.repositories.total = 0;
        state.repositories.isError = true;
      }
    },

    [actions.fetchSelectedRepositories.pending]: state => {
      state.selectedRepositories.isLoading = true;
      state.selectedRepositories.isError = null;
    },
    [actions.fetchSelectedRepositories.fulfilled]: (state, { payload: { data, meta } }) => {
      state.selectedRepositories.data = data;
      state.selectedRepositories.isLoading = false;
      state.selectedRepositories.total = meta.total;
      state.selectedRepositories.isError = null;
    },
    [actions.fetchSelectedRepositories.rejected]: (state, { error }) => {
      state.selectedRepositories.isLoading = false;

      if (checkedServerError(error.message)) {
        state.selectedRepositories.data = [];
        state.selectedRepositories.total = 0;
        state.selectedRepositories.isError = true;
      }
    },

    [actions.fetchServiceByActiveStatus.pending]: state => {
      state.serviceByActiveStatus = {};
      state.serviceByActiveStatusLoading = true;
    },
    [actions.fetchServiceByActiveStatus.fulfilled]: (state, { payload }) => {
      state.serviceByActiveStatus = payload;
      state.serviceByActiveStatusLoading = false;
    },
    [actions.fetchServiceByActiveStatus.rejected]: state => {
      state.serviceByActiveStatusLoading = false;
    },
  },
});
export const {
  clearServicesState,
  clearImportServicesState,
  clearRepositoriesState,
  clearSelectedRepositoriesState,
} = servicesSlice.actions;

export default servicesSlice.reducer;
