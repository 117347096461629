import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AlertGroup, Switch, Tooltip } from '@patternfly/react-core';
import { ReactComponent as QuestionCircleSVG } from 'assets/images/services/question-circle.svg';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import api from 'api';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';

import { AlertStyled, RepositoryBlockWrapper } from '../styles';
import { getApiErrorMessage } from '../../../../../utils';

function GroupImport({
  isEnableSsl,
  setIsEnableSsl,
  testConnect,
  getRepository,
  createImportService,
}) {
  const { t, i18n } = useTranslation();
  const { setValue, watch } = useFormContext();
  const [timerId, setTimerId] = useState(null);
  const [isFocusInputs, setIsFocusInputs] = useState(false);
  const [withAlerts, setWithAlerts] = useState(false);
  const groupUrlValue = watch('groupUrl');
  const tokenValue = watch('token');
  const connectionValue = watch('connection');
  const tooltipMassage = t('pages.systemImportServicePage.tooltipMassage');
  const tokenMessage = t('pages.systemImportServicePage.tokenMessage');
  const sslMessage = t('pages.systemImportServicePage.sslMessage');
  const switchLabel = t('pages.systemImportServicePage.sslVerification');

  const checkedTestConnect = async () => {
    if (!groupUrlValue?.length || !tokenValue?.length) {
      return;
    }

    setWithAlerts(true);
    setValue('connection', '');
    setIsFocusInputs(true);

    try {
      await testConnect.run(
        api.repositories.testImportServices(
          {
            token: tokenValue,
            url: groupUrlValue,
            enableSsl: isEnableSsl,
          },
          { errorIsIgnored: true }
        )
      );
      await getRepository();

      setValue('connection', true);
    } catch (err) {
      if (err) {
        setIsFocusInputs(false);
        setValue('connection', false);
      }
    }
  };

  useEffect(() => {
    if (!testConnect.isLoading) {
      checkedTestConnect();
    }
  }, [isEnableSsl]);

  useEffect(() => {
    if (groupUrlValue?.length && tokenValue?.length) {
      if (timerId) {
        clearTimeout(timerId);
      }

      if (!testConnect.isLoading && !isFocusInputs) {
        const newTimerId = setTimeout(() => checkedTestConnect(), 3000);

        setTimerId(newTimerId);
      }
    }
  }, [groupUrlValue, tokenValue, testConnect.isLoading]);

  useEffect(() => {
    if (groupUrlValue?.length || tokenValue?.length) {
      setWithAlerts(false);
      setValue('connection', null);
      setIsFocusInputs(false);
    }
  }, [groupUrlValue, tokenValue]);

  useEffect(() => {
    setValue('step', 1);

    if (connectionValue) {
      checkedTestConnect();
    }
  }, []);

  const handleChange = (_event, checked) => {
    setIsEnableSsl(checked);
  };

  const alert = useMemo(() => {
    if (testConnect.isLoading || (!connectionValue && createImportService.isLoading)) {
      return (
        <AlertStyled
          isInline
          variant="info"
          title={t('pages.systemImportServicePage.inProgressMessage')}
          customIcon={
            <Loader
              type="TailSpin"
              color="#0066CC"
              height={16}
              width={16}
              style={{ AlignSelf: 'center', marginTop: '-2px' }}
            />
          }
        />
      );
    }

    if (testConnect.isSuccess) {
      return (
        <AlertStyled
          title={t('pages.systemImportServicePage.successMessage')}
          variant="success"
          isInline
        />
      );
    }

    const apiErrorMessage =
      getApiErrorMessage(testConnect.error, t) ||
      (testConnect?.error?.message && t('api.errorCode.serviceImport_gitlabUnknownError'));

    if (apiErrorMessage) {
      return <AlertStyled title={apiErrorMessage} variant="danger" isInline />;
    }
  }, [testConnect.error, testConnect.isLoading, createImportService.isLoading, i18n.language]);

  return (
    <RepositoryBlockWrapper>
      <div className="d-flex font-20 font-weight-medium mb-4">
        {t('pages.systemImportServicePage.repoGroupData')}
        <Tooltip content={tooltipMassage} exitDelay={150} animationDuration={150}>
          <QuestionCircleSVG className="ml-2 vertical-align-middle" />
        </Tooltip>
      </div>
      <InputComponent
        label={t('pages.systemImportServicePage.groupUrl')}
        name="groupUrl"
        isRequired
        withIconByStatus={connectionValue}
        onBlurAction={checkedTestConnect}
        disabled={testConnect.isLoading || createImportService.isLoading}
      />
      <div className="my-4">
        <InputComponent
          label={t('pages.systemImportServicePage.token')}
          name="token"
          isRequired
          tooltipMessage={tokenMessage}
          withIconByStatus={connectionValue}
          onBlurAction={checkedTestConnect}
          disabled={testConnect.isLoading || createImportService.isLoading}
        />
      </div>
      <div className="d-flex">
        <Switch
          key="isEnableSsl"
          label={switchLabel}
          labelOff={switchLabel}
          data-input-for={switchLabel}
          isChecked={isEnableSsl}
          onChange={handleChange}
          ouiaId="BasicSwitch"
          disabled={testConnect.isLoading || createImportService.isLoading}
        />
        <Tooltip content={sslMessage} exitDelay={150} animationDuration={150}>
          <QuestionCircleSVG className="ml-2 vertical-align-middle" />
        </Tooltip>
      </div>
      {withAlerts && (
        <div className="my-4">
          <AlertGroup>{alert}</AlertGroup>
        </div>
      )}
      <InputComponent name="connection" component={inputComponents.hiddenInput} />
    </RepositoryBlockWrapper>
  );
}

export default GroupImport;
