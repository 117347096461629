import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { InputBlockWrapper } from 'components/UI/View';
import { protocolNames } from 'constants';

import {
  phoneValidation,
  getValidUrlValidation,
  emailValidation,
  getContactsDescriptionMap,
} from 'constants/vaidations';
import Representative from '../Representative';

const dynamicParentField = 'developers';

const DevelopersForm = ({ fieldUuid, index }) => {
  const { t } = useTranslation();
  const contactsDescriptionMap = getContactsDescriptionMap();
  const { setValue, watch } = useFormContext();

  const isStartDateValue = watch(`${dynamicParentField}[${index}].startDate`);
  const isEndDateValue = watch(`${dynamicParentField}[${index}].endDate`);
  const phoneValidationRules = useMemo(() => phoneValidation, []);
  const siteValidationRules = useMemo(() => getValidUrlValidation, []);
  const emailValidationRules = useMemo(() => emailValidation, []);

  useEffect(() => {
    if (isStartDateValue > isEndDateValue) {
      setValue(`${dynamicParentField}[${index}].endDate`, '');
    }
  }, [isStartDateValue, isEndDateValue]);

  return (
    <div className="d-sm-flex flex-column gap-4" key={fieldUuid}>
      <InputComponent
        label={t('common.name')}
        name={`${dynamicParentField}[${index}].name`}
        description={contactsDescriptionMap.name}
        maxLength={150}
      />
      <InputComponent
        label={t('common.site')}
        name={`${dynamicParentField}[${index}].site`}
        maxLength={150}
        description={contactsDescriptionMap.site}
        validationRules={siteValidationRules}
        defaultValue={protocolNames.https}
      />
      <InputBlockWrapper columnsCount={2}>
        <InputComponent
          label={t('common.phone')}
          name={`${dynamicParentField}[${index}].phone`}
          component={inputComponents.phoneInput}
          description={contactsDescriptionMap.phone}
          validationRules={phoneValidationRules}
        />
        <InputComponent
          label={t('common.email')}
          name={`${dynamicParentField}[${index}].email`}
          maxLength={300}
          description={contactsDescriptionMap.email}
          placeholder="___@___.__"
          validationRules={emailValidationRules}
        />
      </InputBlockWrapper>
      <InputComponent
        label={t('common.realAddress')}
        name={`${dynamicParentField}[${index}].address`}
        maxLength={150}
        description={contactsDescriptionMap.address}
      />
      <Representative parentField={`${dynamicParentField}[${index}]`} />
    </div>
  );
};

export default DevelopersForm;
