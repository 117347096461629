import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import Loader from 'react-loader-spinner';
import { Tooltip, Label } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useModal from 'hooks/useModal';
import useAsync from 'hooks/useAsync';

import { componentsStatusIcons, systemComponentsStatusIcons } from '../statuses';

import { StatusWrapper, ContentWrapper, StatusTooltipWrapper, StatusWithTooltip } from '../styles';
import {
  getComponentsNames,
  quoteStatusKeys,
  releasesDeployStatusKeys,
  systemStatusKeys,
  tierStatusKeys,
} from '../../../../constants';

function ObjectComponentsStatus({
  status,
  objectUuid,
  objectType,
  statusId,
  statusText,
  isSystemList,
  isSystemDetails,
  statusWithTooltip,
  smallText,
  currentChildren,
  alignItems = 'center',
}) {
  const { t } = useTranslation();
  const componentsNames = getComponentsNames();
  const finalStatuses = [
    systemStatusKeys.ARCHIVE,
    systemStatusKeys.ACTIVE,
    releasesDeployStatusKeys.DEPLOYED,
    tierStatusKeys.PENDING,
    quoteStatusKeys.SET,
  ];
  const { run, data, isLoading, isSuccess } = useAsync();
  const [isWait, setIsWait] = useState(false);
  const { isModalVisible, setIsModalVisible } = useModal(false);
  const isFinalStatus = finalStatuses.includes(statusId);

  const requestFunction = () => {
    if (!isFinalStatus) {
      run(api.monitoring.getObjectStatus({ objectUuid, objectType }));
    }
  };

  useEffect(() => {
    if (isWait) {
      if (isModalVisible) {
        requestFunction();
        setIsModalVisible(false);
      }

      setIsWait(false);
    }
  }, [isWait]);

  useEffect(() => {
    if (status && isSuccess && data && isModalVisible) {
      setTimeout(() => {
        requestFunction();
      }, 1000);
    }
  }, [status, isFinalStatus, isModalVisible]);

  const currentDebounce = data?.length ? 300 : 800;
  const checkedMouseEvent = debounce(() => {
    setIsWait(true);
  }, currentDebounce);
  const onMouseEnter = () => {
    setIsModalVisible(true);
    checkedMouseEvent();
  };
  const onMouseLeave = () => {
    setIsModalVisible(false);
    setIsWait(false);
  };

  const components = useMemo(
    () =>
      data
        ?.filter(({ componentStatus }) => componentStatus !== systemStatusKeys.ACTIVE)
        .map((item, index) => {
          const statuses =
            isSystemList || isSystemDetails ? systemComponentsStatusIcons : componentsStatusIcons;
          const componentStatus = statuses?.[item.componentStatus];

          return (
            <ContentWrapper
              key={objectUuid}
              $isSystemList={isSystemList}
              className={index ? 'd-flex mt-1' : 'd-flex'}
            >
              {componentStatus?.icon}{' '}
              <span className="font-14 font-weight-normal">
                {componentsNames[item.hyperionComponent]}
              </span>
            </ContentWrapper>
          );
        }),
    [data]
  );

  const content = components?.length ? components : t('common.noData');

  if (statusWithTooltip) {
    return (
      <span className={`d-flex flex-row align-items-${alignItems}`}>
        {status && (
          <Tooltip
            className={!isFinalStatus ? undefined : 'd-none'}
            content={
              <div className="position-relative px-2">
                {!isSuccess || isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="#048E94"
                    height={20}
                    width={20}
                    style={{ AlignSelf: 'center' }}
                  />
                ) : (
                  content
                )}
              </div>
            }
            exitDelay={150}
            animationDuration={150}
          >
            <StatusTooltipWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <StatusWithTooltip background={status.background}>
                {smallText || status.smallText}
              </StatusWithTooltip>
            </StatusTooltipWrapper>
          </Tooltip>
        )}

        <span className="line-height-normal mr-2">{currentChildren}</span>
      </span>
    );
  }

  return (
    <Tooltip
      className={!isFinalStatus ? undefined : 'd-none'}
      content={
        <div className="position-relative px-2">
          {!isSuccess || isLoading ? (
            <Loader
              type="TailSpin"
              color="#048E94"
              height={20}
              width={20}
              style={{ AlignSelf: 'center' }}
            />
          ) : (
            content
          )}
        </div>
      }
      exitDelay={150}
      animationDuration={150}
    >
      <Label
        className="cursor-default"
        icon={status?.icon}
        color={status?.color}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StatusWrapper>{statusText || status?.text}</StatusWrapper>
      </Label>
    </Tooltip>
  );
}

export default ObjectComponentsStatus;
