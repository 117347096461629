import styled, { css } from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { Select } from '@patternfly/react-core/deprecated';

import { RowView } from '../View';

export const InputCss = css`
  height: 37px;
  font-size: 16px;
  background: #ffffff;
  outline: none;
  padding: 6px 8px;
  border: 1px solid #f0f0f0;
  border-bottom-color: #8a8d90;

  ::placeholder {
    color: #a6aaad;
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: #f0f0f0;
    border: 1px solid transparent;
    border-bottom: 2px solid transparent;
  `};

  ${({ $isError, $isFocus, $inputStatus, $isFieldValue }) =>
    $inputStatus === false ||
    ($isError && !(($isFocus || $isFieldValue) && $isError.type === 'required'))
      ? `border-bottom: 2px solid #c9190b;
         padding-right: 32px;`
      : ''};

  ${({ $inputStatus, $isError }) =>
    $inputStatus !== undefined && $inputStatus && !$isError
      ? `border-bottom: 2px solid green;
         padding-right: 32px;`
      : ''}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ $descriptionNoWrap }) => $descriptionNoWrap && `margin-top: 21px;`}
  ${({ $disabledWithoutAngelIcon }) => $disabledWithoutAngelIcon && 'width: fit-content;'}
`;

export const RowWrapper = styled.div`
  ${({ $descriptionNoWrap }) => !$descriptionNoWrap && 'position: relative;'}

  ${({ withoutOptions }) => withoutOptions && `max-width: 467.5px;`};

  ${({ isCheckbox }) =>
    isCheckbox &&
    `
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 16px;
    margin-left: -12px;
  `};
`;

export const Input = styled.input`
  ${InputCss}
  height: 37px;
  width: 100%;
`;

export const DateInput = styled.input`
  ${InputCss}
  width: 100%;
  height: 37px;
  pointer-events: none;
`;

export const TextValue = styled.span.attrs({
  className: 'font-16 pr-2',
})`
  display: inline-block;
  width: 100%;
`;

export const NumberWithTextInput = styled(ReactInputMask)`
  ${InputCss}
  width: 100%;
`;

export const NumberInputWithDropdown = styled.input`
  ${InputCss}
  width: 100%;

  ${({ withRigthPadding }) => withRigthPadding && 'padding-right: 32px;'}
`;

export const NumberInput = styled.input`
  ${InputCss}
  width: 100%;
  height: 37px;
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin: 0 12px;
  vertical-align: middle;
  padding-left: 3px;
  cursor: pointer;
`;

export const PhoneInput = styled(ReactInputMask)`
  ${InputCss}
  width: 100%;
`;

export const Textarea = styled.textarea`
  ${InputCss}
  width: 100%;
  height: 100px;
  min-width: 100%;
  max-width: max-content;
`;

export const Label = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #151515;

  ${({ $descriptionNoWrap }) =>
    $descriptionNoWrap &&
    `
    white-space: nowrap !important;
    position: absolute;
    top: 0px;
  `}
`;

export const DynamicText = styled.div`
  margin-bottom: -21px;
  line-height: 21px;

  ${({ $descriptionNoWrap }) =>
    $descriptionNoWrap &&
    `
    white-space: nowrap !important;
    position: absolute;
    bottom: 0px;
  `}
`;

export const RedText = styled(Label)`
  color: #c9190b;
  margin-bottom: ${({ $withoutBottomMargin }) => ($withoutBottomMargin ? '0' : '-21px')};

  ${({ $descriptionNoWrap }) =>
    $descriptionNoWrap &&
    `
    top: 57px;
  `}
`;

export const RequireIcon = styled.label.attrs({
  className: 'text-red font-weight-normal ml-1',
})`
  line-height: 13px;
  vertical-align: text-bottom;
`;

export const HiddenInput = styled.input`
  display: none;
`;

// Select
export const MenuWrapper = styled.div`
  padding: 8px 16px;
`;

export const GreyText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #8b8d8f;
`;

export const InputValueWrapper = styled.div`
  width: 400px;
  word-break: break-word;
  hyphens: auto;
`;

export const TooltipText = styled.div`
  font-size: 12px;
  line-height: 18px;
  max-width: 250px;
`;

export const RadioDescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8b8d8f;
  margin-left: 37px;
  cursor: pointer;
`;

export const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

export const RadioInputWrapper = styled.div`
  width: 150px;
  font-size: 14px;
  line-height: 21px;
  color: #151515;
  align-items: center;
  display: flex;
  margin-left: 10px;
  cursor: pointer;

  ${({ withMargin }) =>
    withMargin &&
    `
    margin-top: 12px;
  `};
`;

export const RadioBlockWrapper = styled.div`
  display: block;

  ${({ rowDirection }) =>
    rowDirection &&
    `
    display: flex;
    flex-direction: row;
    width: 462px;
  `};
`;

export const ExampleWrapper = styled(RowView)`
  margin-top: 4px;
  margin-bottom: 2px;
  color: #6a6e73;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const ExampleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #0066cc;
  padding: 0 8px;
  cursor: pointer;
`;

export const StyledSelect = styled(Select)`
  .pf-v5-m-typeahead {
    height: auto !important;
  }

  .pf-v5-c-chip__text {
    padding: 0 3px !important;
  }

  .pf-v5-c-chip {
    margin: 0 2px 1px 0;
  }

  .pf-v5-c-select__toggle {
    height: 37px !important;
  }

  .pf-v5-c-select__menu {
    ${({ height }) => height && `max-height: ${height};`}
  }

  .pf-v5-m-selected {
    background-color: transparent !important;
  }

  .pf-v5-c-select__toggle-text {
    ${({ $color }) => $color && `color: #a6aaad;`}
  }

  .pf-v5-c-select__toggle-typeahead {
    ::placeholder {
      color: #a6aaad;
      opacity: 1;
    }
  }

  .pf-v5-c-select__menu-item-main {
    white-space: normal !important;
  }

  .pf-v5-c-select__menu-search {
    border-bottom: 1px solid #dee2e6;
  }

  .pf-v5-c-select__menu-item {
    white-space: normal !important;
  }

  .pf-v5-c-select__menu-wrapper {
    ${({ $withHoverDescription }) =>
      $withHoverDescription &&
      `
    &:hover {
      & > button > span > div > div {
        display: block;
      }
    }
  `};
  }

  ${({ $disabledWithoutAngelIcon }) =>
    $disabledWithoutAngelIcon &&
    `
      width: 55px;

      .pf-v5-c-select__toggle-wrapper {
        max-width: fit-content;
      }

      .pf-v5-c-select__toggle-arrow {
        display: none;
      }
  `}
`;

export const StyledMultiSelect = styled(Select)`
  .pf-v5-m-disabled {
    ${({ disabled }) => !disabled && 'background-color: #f0f0f0 !important;'}
  }

  .pf-v5-c-select__toggle-button {
    height: 37px;
  }

  .pf-v5-c-select__toggle-clear {
    height: 37px;
  }

  .pf-v5-c-chip__text {
    padding: 0 3px !important;
  }

  .pf-v5-c-chip {
    margin: 0 2px 1px 0;
  }

  .pf-v5-c-select__menu {
    max-width: 317px;
    max-height: 300px;
    overflow: overlay;
  }

  .pf-v5-c-select__menu-item {
    white-space: normal !important;
  }

  .pf-v5-m-selected {
    background-color: transparent !important;
  }

  .pf-v5-c-select__toggle-typeahead {
    ::placeholder {
      color: #a6aaad;
      opacity: 1;
    }
  }
`;

export const IconByStatus = styled.div.attrs({
  className: 'position-absolute',
})`
  top: 6px;
  right: ${({ $right }) => $right || '12px'};
`;

export const StyledLoader = styled.div.attrs({
  className: 'position-absolute',
})`
  top: 33px;
  right: ${({ isModalForm }) => (isModalForm ? '-25px' : '-35px')};
`;
