import React, { useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import { scopes } from 'hooks/useUserPermissions';
import FileImage from 'hooks/useUploadButton/FileImage';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { documentsSelector } from 'store/documents/selectors';
import { clearDocumentsState } from 'store/documents/reducers';
import { fetchDocuments } from 'store/documents/actions';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import WarningModal from 'components/Common/WarningModal';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import TruncateWithTooltipText from 'components/Common/TruncateWithTooltipText';
import ReadMoreBlock from 'components/Common/ReadMoreBlock';
import ModalInfo from 'components/Common/ModalInfo';
import StatusComponent, { getSystemStatuses } from 'components/Common/StatusComponent';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { systemTypesKeys, getDocumentsTypeNames } from 'constants';
import paths from 'constants/paths';

import DocumentObjectType from './DocumentObjectType';
import DocumentActions from './DocumentActions';

function DocumentsTable({ addButton, isShowFilters, setIsShowFilters, currentTotal }) {
  const { t } = useTranslation();
  const systemStatuses = getSystemStatuses();
  const documentsTypeNames = getDocumentsTypeNames();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, releaseId, issuesId, tierId, integrationId, serverTierId },
  } = useRouteMatch();

  const deleteDocumentFormModal = useModal();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal();
  const changeFile = useAsync();
  const deleteFile = useAsync();
  const { data, total, isLoading, isError } = useSelector(documentsSelector);
  const entityId = tierId || issuesId || releaseId || integrationId;
  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'description',
      name: t('common.description'),
    },
    {
      id: 'objectType',
      name: t('common.object'),
      type: 'multiSelect',
      options: formatFilterOptions(documentsTypeNames),
      isHidden: entityId,
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];

  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'documents'
  );

  const onInfoClick = item => {
    setModalData(item);
    toggleModal();
  };

  const onDeleteFile = e => {
    e.preventDefault();
    deleteFile.run(api.documents.deleteFile(deleteDocumentFormModal.modalData.uuid));
  };

  function requestFunction(params) {
    dispatch(fetchDocuments(params));
  }

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      parent: systemId,
      ascending: false,
      orderBy: 'lastUpdatedAt',
      objectUuid: entityId,
      documentArea: serverTierId ? 'SERVER_TIER' : 'SYSTEM',
      ...filterParams?.date,
      date: undefined,
    }),
    [systemId, filterParams, entityId]
  );

  useSocketAsync({
    topic: 'documents',
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters(hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, hasSelectedFilters]);

  useEffect(() => {
    if (deleteFile.isSuccess) {
      deleteDocumentFormModal.toggleModal();
    }
  }, [deleteFile.isSuccess]);

  useEffect(() => () => dispatch(clearDocumentsState()), []);

  const headerData = [
    {
      key: 'name',
      label: t('pages.systemDocumentsPage.typeAndName'),
      withSort: true,
      width: 30,
    },
    {
      key: 'description',
      label: t('common.description'),
      withSort: true,
      width: 30,
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      withSort: true,
      isHidden: systemId,
    },
    {
      key: 'objectType',
      label: t('common.object'),
      withSort: true,
      isHidden: entityId,
      minWidth: '150px',
    },
    {
      key: 'lastUpdatedBy',
      withSort: true,
      label: t('common.updatedBy'),
      minWidth: '150px',
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
      minWidth: '150px',
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = data?.map((document, index) => ({
    id: `${document.uuid}${index}`,
    data: [
      {
        key: 'name',
        content: document.name && (
          <div className="d-flex flex-row">
            <FileImage fileName={document.fileName} />
            <TruncateWithTooltipText content={document.name} />
          </div>
        ),
      },
      {
        key: 'description',
        content: <ReadMoreBlock content={document.description} withoutExpandButton />,
        type: document.description?.length ? types.textLink : null,
        onClick: () => onInfoClick(document),
      },
      {
        key: 'system.shortName',
        content: document?.system?.shortName && (
          <LinkWithPermission
            system={document?.system}
            scope={scopes.system.systemViewForm}
            to={generatePath(paths.routePaths.system, {
              systemId: document.system.uuid,
              systemType: document.system.type,
            })}
            isTableLink
          >
            <StatusComponent
              statusId={
                document.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : document.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {document.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'objectType',
        content: document?.system && (
          <DocumentObjectType
            system={document?.system}
            type={document.objectType}
            objectDescription={document.objectDescription}
            objectUuid={document.objectUuid}
          />
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: <LastUpdatedStatusWithLink lastUpdatedBy={document?.lastUpdatedBy} isTableLink />,
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: document?.lastUpdatedAt,
      },
      {
        key: 'dropdownMenu',
        content: (
          <DocumentActions
            id={`${document.uuid}${index}`}
            document={document}
            deleteDocumentFormModal={deleteDocumentFormModal}
          />
        ),
      },
    ],
  }));

  return (
    <>
      {isShowFilters && <div className="mx-4">{FilterComponent}</div>}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        tableName="documents"
        requestParams={requestParams}
        total={total}
        withStandartPagination
        isLoading={isLoading}
        hasSelectedFilters={hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />

      <WarningModal
        title={t('pages.systemDocumentsPage.deletingDocument')}
        text={t('pages.systemDocumentsPage.deleteDocument')}
        label={t('pages.systemDocumentsPage.documentName')}
        isLoading={changeFile.isLoading}
        onClick={onDeleteFile}
        name={deleteDocumentFormModal.modalData?.name}
        {...deleteDocumentFormModal}
      />

      <ModalInfo
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        title={t('common.description')}
      >
        <div className="pre-line hyphens-break-word">{modalData?.description}</div>
      </ModalInfo>
    </>
  );
}

export default DocumentsTable;
