import React from 'react';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import useDropdownFilterSection from 'hooks/useDropdownFilterSection';
import paths from 'constants/paths';
import { BlueButton } from 'components/UI/Button';
import PageLayout from 'components/UI/PageLayout';
import DictionariesTable from './DictionariesTable';

function DictionariesPage() {
  const { t } = useTranslation();
  const { filterParams, SearchInput } = useDropdownFilterSection([], 'dictionaries');

  const leftContent = (
    <div className="d-flex flex-row mr-4">
      {SearchInput}
      <BlueButton $marginleft to={generatePath(paths.adminDictionariesPaths.new)}>
        {t('common.add')}
      </BlueButton>
    </div>
  );

  return (
    <PageLayout title={t('common.dictionaries')} leftSideContent={leftContent} withoutSidePadding>
      <DictionariesTable filterParams={filterParams} />
    </PageLayout>
  );
}

export default DictionariesPage;
