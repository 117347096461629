import { createSelector } from 'reselect';
import { checkedServerError, generateDropdownData } from 'utils';
import { systemStatusKeys } from 'constants';
import i18n from '../../i18n';

const { t } = i18n;

const systemsState = state => state.systems;

export const systemsSelector = createSelector([systemsState], systems => systems.data);

export const systemsErrorSelector = createSelector([systemsState], ({ systemsError }) =>
  checkedServerError(systemsError)
);

export const systemsMetaSelector = createSelector([systemsState], systems => systems.meta);

export const systemsIsLoadingSelector = createSelector(
  [systemsState],
  systems => systems.isLoading
);

export const systemsForDropdownSelector = createSelector([systemsSelector], data =>
  generateDropdownData(data, t('misc.chooseSystem'), 'shortName')
);

export const currentSystemSelector = createSelector(
  [systemsState],
  ({ currentSystem }) => currentSystem
);

export const currentSystemLoadingSelector = createSelector(
  [systemsState],
  systems => systems.currentSystemLoading
);

export const currentSystemErrorSelector = createSelector(
  [systemsState],
  ({ currentSystemError }) => currentSystemError
);

export const currentSystemContactsSelector = createSelector(
  [currentSystemSelector],
  currentSystem => currentSystem?.developer?.representatives ?? []
);

export const currentSystemTiersSelector = createSelector(
  [currentSystemSelector],
  currentSystem => currentSystem?.tiers || []
);

export const currentSystemTotalSummarysSelector = createSelector(
  [currentSystemSelector],
  currentSystem => currentSystem?.totalSummary || {}
);

export const currentSystemTiersForDropdownSelector = createSelector(
  [currentSystemTiersSelector],
  currentSystemTiers => generateDropdownData(currentSystemTiers, t('common.all'))
);

export const isNotEditableSystemSelector = createSelector(
  [currentSystemSelector],
  currentSystem =>
    currentSystem?.status !== systemStatusKeys.ACTIVE &&
    currentSystem?.status !== systemStatusKeys.ERROR &&
    currentSystem?.uuid
);

export const isActiveSystemSelector = createSelector(
  [currentSystemSelector],
  currentSystem => currentSystem?.uuid && currentSystem?.status === systemStatusKeys.ACTIVE
);

export const notActiveSystemSelector = createSelector(
  [currentSystemSelector],
  currentSystem => currentSystem?.uuid && currentSystem?.status !== systemStatusKeys.ACTIVE
);

export const systemUserInProgressSelector = createSelector(
  [currentSystemSelector],
  currentSystem =>
    currentSystem?.totalSummary?.userTotal &&
    (currentSystem.totalSummary.userTotal?.notUpdatedUser ||
      currentSystem.totalSummary.userTotal?.error ||
      currentSystem.totalSummary.userTotal?.inProgress)
);

export const systemsOptionsSelector = createSelector(
  [systemsState],
  systems => systems.systemsOptions
);

export const systemsOptionsIsLoadingSelector = createSelector(
  [systemsState],
  systems => systems.systemsOptionsIsLoading
);
