import http from 'api/config';

const getIssues = params => http.get(`issues`, { params });

const getIssuesById = issueUuid => http.get(`issues/${issueUuid}`);

const createIssue = body => http.post(`issues`, body);

const editIssue = (body, issueUuid) => http.put(`issues/${issueUuid}`, body);

const changeIssue = (issueUuid, params) => http.patch(`issues/${issueUuid}`, params);

const changeAllIssues = list => http.patch(`issues/status`, list);

const deleteIssues = list =>
  http.delete(`issues`, {
    data: list,
  });

export default {
  getIssues,
  getIssuesById,
  createIssue,
  editIssue,
  changeIssue,
  changeAllIssues,
  deleteIssues,
};
