import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import { serviceEndpointsSelector } from 'store/serviceEndpoints/selectors';
import { fetchServiceEndpoints } from 'store/serviceEndpoints/actions';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { getFilteredOptions } from 'utils';
import DocumentsButton from 'components/Common/DocumentsButton';
import { BlueButton } from 'components/UI/Button';

import { ReactComponent as VectorLeftSvg } from 'assets/images/integrations/VectorLeft.svg';
import { ReactComponent as VectorRightSvg } from 'assets/images/integrations/VectorRight.svg';

import ServicesForm from './ServicesForm';
import ServiceEndpointData from '../../IntegrationsPage/IntegrationSystemsTable/IntegrationsTable/ServiceEndpointData';

import * as S from '../styles';

function ServiceSelection({ isEditService, setCurrentStepId, editDocuments }) {
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { t } = useTranslation();
  const servicesOptions = useAsync();
  const allProtocols = useAsync();
  const allAuthTypes = useAsync();
  const { data, isLoading } = useSelector(serviceEndpointsSelector);
  const disabledMessage = t('pages.systemIntegrationRelationsPage.serviceAlreadyInUse');
  const messageWithService = t('pages.systemIntegrationRelationsPage.selectOrAddService');
  const messageWithoutService = t('pages.systemIntegrationRelationsPage.addService');
  const { setValue, watch } = useFormContext();
  const { isModalVisible, setIsModalVisible, toggleModal } = useModal(isEditService);

  const isInbound = watch(`inbound`);
  const isSystemUuid = watch(`systemUuid`);
  const isSystemFrom = watch(`systemFrom`);
  const isSystemTo = watch(`systemTo`);
  const isServiceEndpointTo = watch(`serviceEndpointTo`);
  const isServiceEndpointToUuid = watch(`serviceEndpointTo.uuid`);

  const checkedService = useMemo(
    () => data.find(item => item.uuid === isServiceEndpointToUuid),
    [data, isServiceEndpointToUuid]
  );

  const requestParams = useMemo(
    () => ({
      limit: 1000,
      ascending: true,
      systemId: isInbound ? systemId : isSystemUuid,
    }),
    [isSystemUuid, systemId, isInbound]
  );

  const servicesOptionsRequestParams = useMemo(
    () => ({
      systemWith: isSystemTo?.uuid,
    }),
    [isSystemTo?.uuid]
  );

  useEffect(() => {
    if (!isEditService) {
      setCurrentStepId(2);
    }

    if (!isServiceEndpointTo?.name) {
      setValue(`serviceEndpointTo.async`, null);
    }
  }, []);

  useEffect(() => {
    if (isSystemTo?.uuid && isSystemFrom?.uuid) {
      dispatch(fetchServiceEndpoints(requestParams));

      servicesOptions.run(
        api.integrations.allIntegrationServicesOptions(servicesOptionsRequestParams)
      );
    }
  }, [isSystemFrom?.uuid, isSystemTo?.uuid]);

  const getServiceOptions = () =>
    generateSelectOptions(data, null, null, null, {
      getLabel: services => services.name,
      getValue: services => services.uuid,
      getDisabled: services =>
        servicesOptions.data?.data?.map(item => item.uuid).includes(services.uuid),
      tooltipMessage: disabledMessage,
      getDescription: services => services.description,
    });

  useEffect(() => {
    if (isModalVisible) {
      allProtocols.run(api.entry.getProtocols());
      allAuthTypes.run(api.entry.getAuthTypes());
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!isServiceEndpointTo?.uuid?.trim().length && isServiceEndpointTo?.name) {
      setIsModalVisible(true);
    }
  }, [isServiceEndpointTo]);

  useEffect(() => {
    if (!isModalVisible && checkedService && isServiceEndpointToUuid) {
      const { name, description, documents, protocol, requestData, responseData, async, authType } =
        checkedService;
      setValue(`serviceEndpointTo`, {
        name,
        description,
        documents,
        protocol,
        authType,
        requestData,
        responseData,
        async,
        uuid: isServiceEndpointToUuid,
      });
    }
  }, [isModalVisible, checkedService, isServiceEndpointToUuid]);

  useEffect(() => {
    if (!isModalVisible && !isServiceEndpointTo?.uuid && !isServiceEndpointTo?.name) {
      setValue(`serviceEndpointTo`, {
        name: '',
        description: '',
        documents: [],
        protocol: '',
        authType: '',
        requestData: [],
        responseData: [],
        async: null,
        uuid: ' ',
      });
    }
  }, [isModalVisible, isServiceEndpointTo]);

  const onAddButtonClick = e => {
    e.preventDefault();
    setValue(`serviceEndpointTo`, {
      name: '',
      description: '',
      documents: [],
      protocol: '',
      authType: '',
      requestData: [],
      responseData: [],
      async: null,
    });
    toggleModal();
  };

  return (
    <>
      <div className="font-20 font-weight-medium">
        {t('pages.systemAddServicePage.isIntegrationService')}
      </div>

      <S.SystemsNamesText>
        <S.TextWrapper>
          {t('pages.systemAddIntegrationPage.interactionBetween')}&nbsp;
        </S.TextWrapper>
        {isInbound ? isSystemTo?.name : isSystemFrom?.name}
        <S.VectorWrapper>{isInbound ? <VectorLeftSvg /> : <VectorRightSvg />}</S.VectorWrapper>
        {isInbound ? isSystemFrom?.name : isSystemTo?.name}
      </S.SystemsNamesText>

      <S.MessageWrapper>
        {data?.length > 0 ? messageWithService : messageWithoutService}
      </S.MessageWrapper>

      {isModalVisible && (
        <S.ServiceFormWrapper>
          <BlueButton variant="secondary" $marginRight onClick={onAddButtonClick}>
            {t('pages.systemIntegrationRelationsPage.serviceAddingCancellation')}
          </BlueButton>
          <ServicesForm
            allProtocols={allProtocols.data?.data}
            allAuthTypes={allAuthTypes.data?.data}
            isEditService={isEditService}
            editDocuments={editDocuments}
          />
        </S.ServiceFormWrapper>
      )}

      {!isModalVisible && (
        <>
          <div className="mt-4">
            <InputComponent
              label={t('pages.systemIntegrationRelationsPage.availableServices')}
              name="serviceEndpointTo.uuid"
              component={inputComponents.newSelect}
              isLoading={isLoading}
              newSelectVariant="typeahead"
              newSelectOnFilter={getFilteredOptions}
              placeholder={t('common.searchByNameAndDescription')}
              isRequired
              options={servicesOptions.data?.data?.length ? getServiceOptions() : []}
              maxLength="100"
            />
          </div>

          {checkedService ? (
            <S.ServiceWrapper>
              <S.ServiceNameWrapper>
                <S.ServiceName>{checkedService?.name}</S.ServiceName>
              </S.ServiceNameWrapper>
              <S.ServiceDescription>{checkedService.description}</S.ServiceDescription>
              {(!!checkedService.requestData.length || !!checkedService.responseData.length) && (
                <ServiceEndpointData serviceEndpointTo={checkedService} key={checkedService.uuid} />
              )}

              <S.ServiceTypes>
                {checkedService.protocol && (
                  <>
                    {t('pages.systemIntegrationRelationsPage.protocol')}:{' '}
                    <S.ServiceTypesItem>{checkedService.protocol}</S.ServiceTypesItem>
                  </>
                )}
                {checkedService.authType && (
                  <>
                    {t('pages.systemIntegrationRelationsPage.authType')}:{' '}
                    <S.ServiceTypesItem>{checkedService.authType}</S.ServiceTypesItem>
                  </>
                )}
                {t('pages.systemIntegrationRelationsPage.asyncType')}:
                {checkedService.async === null ? (
                  <S.ServiceTypesItem>{t('common.notSelected')}</S.ServiceTypesItem>
                ) : (
                  <S.ServiceTypesItem>
                    {checkedService.async
                      ? t('pages.systemIntegrationRelationsPage.async')
                      : t('pages.systemIntegrationRelationsPage.sync')}
                  </S.ServiceTypesItem>
                )}
                {!!checkedService.documents?.length && (
                  <>
                    {t('common.documents')}:
                    <S.DocumentsButtonWrapper>
                      <DocumentsButton documents={checkedService.documents} />
                    </S.DocumentsButtonWrapper>
                  </>
                )}
              </S.ServiceTypes>
            </S.ServiceWrapper>
          ) : (
            <S.ServiceFormWrapper>
              <BlueButton variant="secondary" $marginRight onClick={onAddButtonClick}>
                {t('pages.systemIntegrationRelationsPage.addServiceTo', [isSystemTo?.name])}
              </BlueButton>
            </S.ServiceFormWrapper>
          )}
        </>
      )}
    </>
  );
}

export default ServiceSelection;
