import styled, { css } from 'styled-components';
import { Alert } from '@patternfly/react-core';
import { Wizard } from '@patternfly/react-core/deprecated';

import { InputsBlockWrapper } from 'components/UI/View';
import { BlueButtonTextCss } from 'components/UI/Button';

export const WizardStyled = styled(Wizard)`
  border-top: 1px solid #d2d2d2;

  .pf-v5-c-wizard__outer-wrap {
    z-index: 1 !important;
    min-height: auto !important;
  }

  .pf-v5-c-wizard__toggle {
    z-index: 2 !important;
  }

  .pf-v5-c-wizard__nav {
    background-color: #ffffff;
  }
`;

export const StyledAlert = styled(Alert)`
  .pf-v5-c-alert__title {
    font-weight: 500;
  }
`;

export const BlockWrapper = styled(InputsBlockWrapper)`
  max-width: 1000px;
`;

export const FlexRowDirection = css`
  display: flex;
  flex-direction: row;
`;

export const MessageWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8d8f;
  padding-top: 8px;
  white-space: pre-line;
`;

export const VectorWrapper = styled.div`
  margin: 0 11px;
`;

export const TextWrapper = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const BondTiersFormWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  max-width: 1000px;
  margin-top: 16px;
  padding: 24px;
  border: 1px solid #f0f0f0;
`;

export const WarningMessage = styled.div`
  margin: 0 0 24px;
`;

export const SubTitle = styled.div`
  ${FlexRowDirection}
  margin-bottom: 10px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
`;

export const TitleMessageWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8b8d8f;
  white-space: pre-line;
  max-width: 1000px;
`;

export const ServiceFormWrapper = styled.div`
  margin-top: 24px;
`;

export const SystemsNamesText = styled(TitleMessageWrapper)`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #151515;
  align-items: center;
  padding-top: 8px;
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
`;

export const DirectionButtonWrapper = styled.div`
  ${FlexRowDirection}
`;

export const DirectionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  margin: 8px 8px 0 0;
  background-color: #ffffff;
  padding: 8px 19px 8px 19px;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: rgb(106, 110, 115);
    background-color: rgb(210, 210, 210);
    cursor: auto;
  `}

  ${({ isActive }) =>
    isActive &&
    `
    border: 2px solid #0f69c9;
    background-color: #f4fafe;
    cursor: pointer;
    color: rgb(21, 21, 21);
  `};
`;

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #f0f0f0;
  padding: 24px;
  margin: 24px 0;
`;

export const ServiceName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const ServiceDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ServiceTypes = styled(ServiceDescription)`
  ${FlexRowDirection}
  color: #6a6e73;
  margin-top: 16px;
  white-space: nowrap;
`;

export const ServiceTypesItem = styled(ServiceDescription)`
  font-weight: 600;
  margin: 0 30px 0 8px;
  color: #151515;
  white-space: nowrap;
`;

export const UploadButtonWrapper = styled.div`
  max-width: 600px;
`;

export const SubTitleInEditIntegration = styled(SubTitle)`
  flex-direction: row-reverse;
`;

export const BlockServicesWrapper = styled(BlockWrapper)`
  grid-template-columns: 250px 250px 235px;
  grid-column-gap: 0;
`;

export const BlockServicesImputWrapper = styled.div`
  margin-right: 16px;
`;

export const IntegarationFrequencyTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
`;

export const DocumentsButtonWrapper = styled.div`
  margin: -5px 0 0 10px;
`;

export const SectionWithButton = styled.div`
  display: flex;
  margin: 30px 0 15px;
`;

export const AddNewSectionButton = styled.button`
  border: none;
  background: transparent;
  ${BlueButtonTextCss}
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  display: flex;
  margin-bottom: 90px;

  &::before {
    content: '+';
    font-size: 24px;
    line-height: 30px;
    margin-right: 5px;
  }
`;

export const TierLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #6a6e73;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-top: 2px;
  margin-bottom: 16px;
  word-break: break-word;
  hyphens: auto;
`;

export const DynamicFormWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  max-width: 1000px;
  margin-top: 16px;

  ${({ standartWrapper }) =>
    standartWrapper &&
    `
    border: 0;
    padding: 0;
    margin: 0;
  `};
`;

export const ServiceNameWrapper = styled.div`
  ${FlexRowDirection}
  align-items: center;
  gap: 10px;
`;

export const InputComponentWrapper = styled.div`
  height: fit-content;
`;

export const TierToUri = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 6px 0 0 10px;
  max-width: 300px;
  text-overflow: ellipsis;
  width: fit-content;
`;

export const TierToUriTitle = styled.div`
  width: fit-content;
  margin: 2px 0 0 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #151515;
`;
