import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchMilestones = createAsyncThunk('milestones/fetchMilestones', async params => {
  const response = await api.milestones.getAllMilestones(params);

  return response.data;
});

export const fetchMilestonesForSystemEdit = createAsyncThunk(
  'milestones/fetchMilestonesForSystemEdit',
  async params => {
    const response = await api.milestones.getAllMilestones(params);

    return response.data;
  }
);
