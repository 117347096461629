import React from 'react';
import { generatePath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Tooltip, Truncate } from '@patternfly/react-core';
import AngleRightIcon from '@patternfly/react-icons/dist/esm/icons/angle-right-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';

import StatusComponent, { getServiceAvailableStatuses } from 'components/Common/StatusComponent';
import paths from 'constants/paths';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import ServiceVersionsStatus from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable/ServiceVersionsStatus';
import { getVersionDescription } from '../../../utils';

function ServiceVersionHeader({ serviceVersion }) {
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const serviceAvailableStatuses = getServiceAvailableStatuses();

  return (
    <div className="d-flex font-weight-normal">
      <LinkWithPermission
        system={serviceVersion?.system}
        scope={scopes.service.serviceViewForm}
        to={
          serviceVersion?.service?.uuid
            ? generatePath(paths.systemFullPaths.services + paths.systemServicesPaths.service, {
                systemId,
                systemType,
                serviceId: serviceVersion.service.uuid,
              })
            : undefined
        }
        isTableLink
      >
        <StatusComponent
          statusId={serviceVersion?.service?.status}
          statusWithTooltip
          statuses={serviceAvailableStatuses}
          objectType="SERVICE"
          baselineAlign
        >
          <Truncate
            className="font-20 font-weight-medium hyphens word-break-break-all min-width-auto"
            content={serviceVersion?.service?.name}
          />
        </StatusComponent>
      </LinkWithPermission>

      <div className="d-flex flex-row flex-nowrap align-self-flex-start">
        <AngleRightIcon className="font-16 font-weight-normal text-gray-medium mr-2" />
        <span className="font-20 font-weight-medium">{serviceVersion?.tag}</span>
        <div className="d-flex ml-2 title-line-height font-weight-normal">
          <ServiceVersionsStatus version={serviceVersion} withoutStatusText="" />
        </div>
        {(!serviceVersion?.addedViaPortal || serviceVersion?.usedDefaultBranch === false) && (
          <div className="d-flex ml-2 title-line-height font-weight-normal cursor-pointer">
            <Tooltip content={getVersionDescription(serviceVersion, true)} exitDelay={150}>
              <InfoCircleIcon className="text-danger" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceVersionHeader;
