import styled from 'styled-components';
import { InputsBlockWrapper } from 'components/UI/View';

export const TasksForm = styled.form.attrs({
  className: 'pl-4 mb-4',
})`
  width: 654px;
`;

export const TasksBlockWrapper = styled(InputsBlockWrapper)`
  gap: 20px;
`;
