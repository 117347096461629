import React from 'react';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/UI/TextBlock';
import { getUserAdminNames } from 'constants';

function UserProfile({ fullName = '-', login = '-', email, phone, workplace = '-', admin }) {
  const { t } = useTranslation();
  const userAdminNames = getUserAdminNames();
  return (
    <div className="d-sm-flex flex-column gap-4 ml-4 mt-4">
      <TextBlock greyColor title={t('pages.adminUserPage.fullName')}>
        {fullName}
      </TextBlock>
      <TextBlock greyColor title={t('common.login')}>
        {login}
      </TextBlock>
      <TextBlock greyColor title={t('common.email')}>
        {email ? <a href={`mailto:${email}`}>{email}</a> : '-'}
      </TextBlock>
      <TextBlock greyColor title={t('common.phone')}>
        {phone ? <a href={`tel:${phone}`}>{phone}</a> : '-'}
      </TextBlock>
      <TextBlock greyColor title={t('common.work')}>
        {workplace}
      </TextBlock>
      <TextBlock greyColor title={t('pages.adminUsersPage.accessLevel')}>
        {admin ? userAdminNames.admin : userAdminNames.user}
      </TextBlock>
    </div>
  );
}

export default UserProfile;
