import http from 'api/config';

const getService = uuid => http.get(`services/${uuid}`);

const getServiceVersions = uuid => http.get(`services/${uuid}/service-versions`);

const addService = body => http.post(`services`, body);

const addEditService = ({ serviceId, ...body }) => http.put(`services/${serviceId}`, body);

const changeAllServices = list => http.put(`services`, list);

const deleteService = uuid => http.delete(`services/${uuid}`);

const getAllServices = params => http.get(`services`, { params });

const getServicesOptions = params => http.get(`services/options`, { params });

const getBranchesOptions = ({ uuid, ...params }) =>
  http.get(`services/${uuid}/branches`, { params });

const archiveService = uuid => http.post(`services/${uuid}/archive`);

const restoreService = uuid => http.post(`services/${uuid}/restore`);

const getServiceInfo = data => http.post(`/services/information`, data);

export default {
  getService,
  getServiceVersions,
  addService,
  addEditService,
  changeAllServices,
  deleteService,
  getAllServices,
  getServicesOptions,
  getBranchesOptions,
  archiveService,
  restoreService,
  getServiceInfo,
};
