import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, WizardStep } from '@patternfly/react-core';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import {
  clearImportServicesState,
  clearRepositoriesState,
  clearSelectedRepositoriesState,
} from 'store/services/reducers';
import { notActiveSystemSelector } from 'store/systems/selectors';
import {
  importServiceIsLoadingSelector,
  importServiceSelector,
  selectedRepositoriesSelector,
} from 'store/services/selectors';
import { repositoryStatusKeys } from 'constants';
import PageLayout from 'components/UI/PageLayout';

import GroupImport from './GroupImport';
import RepositoriesSelection from './RepositoriesSelection';
import RepositoriesImport from './RepositoriesImport';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

const repositoryModel = {
  group: {},
  groupUrl: '',
  groupUuid: undefined,
  token: '',
  checkedRepositories: [],
  checkedSelectedRepositories: [],
  connection: undefined,
};

function ImportServicesPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { run, isSuccess, isLoading } = useAsync();
  const createImportService = useAsync();
  const testConnect = useAsync();
  const formMethods = useForm({
    defaultValues: repositoryModel,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const title = t('pages.systemServicePage.importTitle');
  const disableMessage = () => t('pages.systemServicesPage.importServiceWarningMessage');

  const { state } = history.location;

  const [serviseImportData, setServiseImportData] = useState({});
  const [excludedIds, setExcludedIds] = useState(formMethods.getValues('excludedIds') || []);
  const [isMassChecked, setIsMassChecked] = useState(
    formMethods.getValues('isMassChecked') || false
  );
  const [isEnableSsl, setIsEnableSsl] = useState(true);
  const [currentActiveStep, setCurrentActiveStep] = useState(1);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const importService = useSelector(importServiceSelector);
  const importServiceIsLoading = useSelector(importServiceIsLoadingSelector);
  const selectedRepositories = useSelector(selectedRepositoriesSelector);

  const [excludedSelectedIds, setExcludedSelectedIds] = useState([]);
  const [isSelectedMassChecked, setIsSelectedMassChecked] = useState(false);

  const groupUrlValue = formMethods.watch('groupUrl');
  const tokenValue = formMethods.watch('token');
  const connectionValue = formMethods.watch('connection');
  const isStepIndex = formMethods.watch('step');
  const checkedRepositoriesValue = formMethods.watch('checkedRepositories');
  const isFirstStep = isStepIndex === 1;

  useEffect(() => {
    if (state?.isServiceVersion) {
      formMethods.reset({
        serviceVersions: [
          {
            service: { uuid: state.isServiceVersion.service.uuid },
            uuid: state.isServiceVersion.uuid,
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (isStepIndex > currentActiveStep) {
      setCurrentActiveStep(isStepIndex);
    }

    if (isStepIndex === 2) {
      setIsSelectedMassChecked(false);
      setExcludedSelectedIds([]);
    }
  }, [isStepIndex]);

  useEffect(() => {
    if (connectionValue) {
      formMethods.setValue('connection', null);
      createImportService.setData({});
    }

    if (checkedRepositoriesValue?.length) {
      formMethods.setValue('checkedRepositories', []);
      formMethods.setValue('checkedSelectedRepositories', []);
    }
  }, [tokenValue, groupUrlValue, isEnableSsl]);

  useEffect(() => {
    formMethods.setValue('isMassChecked', isMassChecked);
  }, [isMassChecked]);

  useEffect(() => {
    formMethods.setValue('excludedIds', excludedIds);
  }, [excludedIds]);

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  useEffect(() => {
    if (isSuccess) {
      closeForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (connectionValue === null || connectionValue === false) {
      dispatch(clearRepositoriesState());
    }
  }, [connectionValue]);

  useEffect(
    () => () => {
      history.replace({ state: {} });
      dispatch(clearRepositoriesState());
      dispatch(clearSelectedRepositoriesState());
      dispatch(clearImportServicesState());
    },
    []
  );

  const getRepository = async onNext => {
    const formatData = {
      token: tokenValue,
      groupUrl: groupUrlValue,
      enableSsl: isEnableSsl,
      system: { uuid: systemId },
    };

    if (!serviseImportData?.token || !isEqual(formatData, serviseImportData)) {
      try {
        const serviceImport = await createImportService.run(
          api.repositories.createImportService(formatData)
        );
        setServiseImportData(formatData);
        formMethods.setValue('group', serviceImport);
        formMethods.setValue('groupUuid', serviceImport.uuid);
        formMethods.setValue('connection', true);

        if (onNext) {
          onNext();
        }
        return;
      } catch (err) {
        if (err) {
          setServiseImportData({});
          formMethods.setValue('group', {});
          formMethods.setValue('connection', false);
          formMethods.setValue('checkedRepositories', []);
          formMethods.setValue('checkedSelectedRepositories', []);

          return formMethods.setValue('groupUuid', undefined);
        }
      }
    }

    if (onNext) {
      onNext();
    }
  };

  const steps = [
    {
      id: 1,
      name: t('pages.systemServicePage.repoGroups'),
      component: (
        <GroupImport
          isEnableSsl={isEnableSsl}
          setIsEnableSsl={setIsEnableSsl}
          testConnect={testConnect}
          getRepository={getRepository}
          createImportService={createImportService}
        />
      ),
    },
    {
      id: 2,
      name: t('pages.systemServicePage.selectingRepositories'),
      component: (
        <RepositoriesSelection
          excludedIds={excludedIds}
          setExcludedIds={setExcludedIds}
          isMassChecked={isMassChecked}
          setIsMassChecked={setIsMassChecked}
        />
      ),
    },
    {
      id: 3,
      name: t('pages.systemServicePage.creationServices'),
      component: (
        <RepositoriesImport
          excludedIds={excludedSelectedIds}
          setExcludedIds={setExcludedSelectedIds}
          isMassChecked={isSelectedMassChecked}
          setIsMassChecked={setIsSelectedMassChecked}
        />
      ),
    },
  ];

  const isDisabledStep = index =>
    ((index === 1 || index === 3) && !connectionValue) ||
    (index === 2 && (!checkedRepositoriesValue?.length ? !isMassChecked : false));
  const isLastStep = isStepIndex === steps?.length;
  const isNextDisabled =
    !tokenValue ||
    !groupUrlValue ||
    !connectionValue ||
    createImportService.isLoading ||
    testConnect.isLoading ||
    isLastStep;
  const statusTotalKeys = Object.keys(importService?.totalSummary?.statusTotal || {});
  const isValidForm =
    !selectedRepositories.isLoading &&
    !isLoading &&
    !importServiceIsLoading &&
    statusTotalKeys?.length &&
    !statusTotalKeys?.includes(repositoryStatusKeys.NEW);

  const onSubmit = async ({ groupUuid }) => {
    if (isValidForm) {
      run(api.repositories.importServices(groupUuid));
    }
  };

  const saveButton = (
    <div className="mb-4">
      <Button
        onClick={e => formMethods.handleSubmit(onSubmit)(e)}
        isDisabled={!isValidForm || notActiveSystemMessage || isLoading}
        isLoading={isLoading}
      >
        {t('pages.systemServicePage.import')}
      </Button>
    </div>
  );

  return (
    <PageLayout
      fullHeight
      title={t('pages.systemServicePage.servicesImport')}
      withoutSidePadding
      withSystemSelector
    >
      <FormProvider {...formMethods}>
        <div className="h-100">
          <S.WizardStyled $isLastStep={isLastStep}>
            {steps?.map((step, index) => (
              <WizardStep
                id={step.id}
                key={step.id}
                name={step.name}
                mainAriaLabel={`${title}-data`}
                isDisabled={isDisabledStep(index)}
                footer={{
                  nextButtonText: t('common.forward'),
                  backButtonText: t('common.back'),
                  isBackHidden: isFirstStep,
                  isNextDisabled: isStepIndex === 2 ? isDisabledStep(isStepIndex) : isNextDisabled,
                }}
              >
                <form onSubmit={e => formMethods.handleSubmit(onSubmit)(e)} className="h-100">
                  {step.component}
                  <S.CanselButtonWrapper $isBackHidden={isFirstStep} $isLastStep={isLastStep}>
                    <Button variant="link" onClick={closeForm}>
                      {t('common.cancel')}
                    </Button>
                  </S.CanselButtonWrapper>

                  {isLastStep && (
                    <S.SaveButtonWrapper>
                      {!isValidForm ? (
                        <Tooltip
                          content={!isLoading && (disableMessage() || notActiveSystemMessage)}
                          className={!isLoading ? undefined : 'd-none'}
                          exitDelay={150}
                          animationDuration={150}
                          position="left"
                        >
                          {saveButton}
                        </Tooltip>
                      ) : (
                        saveButton
                      )}
                    </S.SaveButtonWrapper>
                  )}
                </form>
              </WizardStep>
            ))}
          </S.WizardStyled>
        </div>
      </FormProvider>
    </PageLayout>
  );
}

export default ImportServicesPage;
