import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import WarningModal from 'components/Common/WarningModal';

function DeleteRepositoryForm({
  requestFunction,
  isModalVisible,
  toggleModal,
  modalData,
  setModalData,
  groupUuidValue,
}) {
  const { t } = useTranslation();
  const deleteRepository = useAsync();

  useEffect(() => {
    if (deleteRepository.isSuccess) {
      if (requestFunction) {
        requestFunction(modalData, modalData?.uuid);
      }

      setModalData();
      toggleModal();
    }
  }, [deleteRepository.isSuccess]);

  const onClick = () =>
    modalData?.uuid
      ? deleteRepository.run(api.repositories.deleteRepository(groupUuidValue, modalData.uuid))
      : deleteRepository.run(api.repositories.massDeleteRepository(groupUuidValue, modalData));

  const multipleEditTitle =
    !modalData?.repositories || modalData?.repositories?.length > 1
      ? t('pages.systemServicesPage.massDeletingServices')
      : t('pages.systemServicesPage.deletingService');
  const multipleEditText = t('pages.systemServicesPage.massDeleteServices');
  const title = modalData?.uuid ? t('pages.systemServicesPage.deletingService') : multipleEditTitle;
  const text = modalData?.uuid ? t('pages.systemServicesPage.deleteService') : multipleEditText;

  return (
    <WarningModal
      title={title}
      text={text}
      isLoading={deleteRepository.isLoading}
      onClick={onClick}
      name={modalData?.name}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    />
  );
}

export default DeleteRepositoryForm;
