import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Tab, TabTitleText } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getMilestoneStatus } from 'components/Common/StatusComponent';
import { isActualFilterFunction } from 'utils';
import { milestoneStatusKeys } from 'constants';
import Issues from 'pages/SystemRouter/SystemIssuesRouter/IssuesPage/Issues';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';

import MilestoneInfo from './MilestoneInfo';
import MilestoneActions from '../MilestonesPage/MilestoneActions';
import { featureFlags } from '../../../../constants/features';

import * as S from './styles';

function CurrentMilestonePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    params: { milestoneId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs();
  const milestoneStatus = getMilestoneStatus();
  const { run, data, isLoading, isError } = useAsync();
  const changeMilestones = useAsync();
  const deleteMilestone = useAsync();
  const [isShowFilters, setIsShowFilters] = useState(false);

  const requestFunction = () => run(api.milestones.getMilestonesById(milestoneId));

  useSocketAsync({
    topic: 'milestones',
    filterByUuid: milestoneId,
    onMessage: () => requestFunction(),
  });

  useEffect(() => {
    requestFunction();
  }, []);

  useEffect(() => {
    if (changeMilestones.isSuccess) {
      requestFunction();
    }
  }, [changeMilestones.isSuccess]);

  useEffect(() => {
    if (deleteMilestone.isSuccess) {
      history.goBack();
    }
  }, [deleteMilestone.isSuccess]);

  const isActual = data && isActualFilterFunction(data);
  const isOpenMilestone =
    data?.status === milestoneStatusKeys.OPEN || data?.status === milestoneStatusKeys.OVERDUE;

  const leftSideContent = (
    <div className="mx-4">
      {data && (
        <MilestoneActions
          milestone={data}
          changeMilestones={changeMilestones}
          deleteMilestone={deleteMilestone}
          isBlueButtonDropdown
        />
      )}
    </div>
  );

  const title = (
    <div className="d-flex">
      <span className="mr-2">{data?.name}</span>
      <div className="d-flex ml-2 title-line-height">
        <StatusComponent statusId={data?.status} statuses={milestoneStatus} />
      </div>
      {isActual && <S.ActualMilestone>{t('pages.systemMilestonePage.current')}</S.ActualMilestone>}
    </div>
  );

  return (
    <PageLayout
      title={title}
      leftSideContent={leftSideContent}
      withoutSidePadding
      withSystemSelector
      isWaitLoading={{ isLoading }}
      isError={isError}
    >
      <S.TabsWrapper>
        <TabsStyled
          activeKey={currentTab}
          onSelect={onSelectTab}
          aria-label={t('common.authorizationService')}
        >
          <Tab eventKey={0} title={<TabTitleText>{t('common.description')}</TabTitleText>}>
            <MilestoneInfo milestone={data} isOpenMilestone={isOpenMilestone} />
          </Tab>
          {featureFlags.isIssuesFeatureEnabled && (
            <Tab
              eventKey={1}
              title={
                <TabTitleText className="d-flex">
                  {t('common.issues')}
                  <TotalSummaryBadge
                    total={data?.totalSummary?.issuesTotal?.total}
                    isSelect={currentTab === 1}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <Issues
                  isShowFilters={isShowFilters}
                  setIsShowFilters={setIsShowFilters}
                  currentTotal={data?.uuid && data?.totalSummary?.issuesTotal?.total}
                />
              </div>
            </Tab>
          )}
        </TabsStyled>
      </S.TabsWrapper>
    </PageLayout>
  );
}

export default CurrentMilestonePage;
