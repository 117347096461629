import React, { useEffect, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { serverTierStatusKeys } from 'constants/index';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';
import { getProgressServerTierMessage } from 'constants/tooltips';
import useModal from 'hooks/useModal';
import WarningModal from 'components/Common/WarningModal';
import useAsync from 'hooks/useAsync';
import { useTranslation } from 'react-i18next';

function ClusterActions({ cluster, isBlueButtonDropdown, requestFunction }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { optionsFilterByScopes } = useUserPermissions();
  const deleteServerTierModal = useModal();
  const deleteCluster = useAsync();
  const hasTiers = cluster?.totalSummary?.tierTotal?.total;
  const notActiveClusterMessage =
    cluster?.status !== serverTierStatusKeys.ACTIVE && getProgressServerTierMessage();
  const notActiveOrErrorClusterMessage =
    cluster?.status !== serverTierStatusKeys.ACTIVE &&
    cluster?.status !== serverTierStatusKeys.ERROR &&
    getProgressServerTierMessage();

  const onFilterChange = value => {
    if (!cluster?.uuid) {
      return;
    }

    if (value === 'edit') {
      return history.push(
        generatePath(paths.adminFullPaths.serverTiers + paths.serverTiersPaths.edit, {
          serverTierId: cluster.uuid,
          action: paths.serverTiersActions.edit,
        })
      );
    }

    if (value === 'newEquipment') {
      return history.push(
        generatePath(paths.adminFullPaths.serverTiers + paths.serverTiersPaths.newEquipment, {
          serverTierId: cluster.uuid,
        })
      );
    }

    if (value === 'delete') {
      deleteServerTierModal.setModalData(cluster);
      return deleteServerTierModal.toggleModal();
    }
  };

  const optionData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'edit',
          name: t('common.edit'),
          isDisabled: notActiveClusterMessage,
          tooltip: notActiveClusterMessage,
          scope: scopes.platform.clusterUpdate,
          isPlatformScope: true,
        },
        {
          id: 'newEquipment',
          name: t('pages.adminServerTiers.addEquipment'),
          isDisabled: notActiveClusterMessage,
          tooltip: notActiveClusterMessage,
          scope: scopes.platform.clusterAddEquipment,
          isPlatformScope: true,
        },
        {
          id: 'delete',
          name: t('common.delete'),
          scope: scopes.platform.clusterDelete,
          isDisabled: hasTiers || notActiveOrErrorClusterMessage,
          tooltip: hasTiers
            ? t('pages.adminServerTiers.noTiersOnServerTier')
            : notActiveOrErrorClusterMessage,
          isPlatformScope: true,
        },
      ]),
    [
      cluster,
      optionsFilterByScopes,
      notActiveClusterMessage,
      notActiveOrErrorClusterMessage,
      hasTiers,
    ]
  );
  const onDelete = () => deleteCluster.run(api.serverTiers.deleteTier(cluster.uuid));

  useEffect(() => {
    if (deleteCluster.isSuccess) {
      if (requestFunction) {
        requestFunction();
      }

      deleteServerTierModal.toggleModal();
    }
  }, [deleteCluster.isSuccess]);

  return (
    <div className="float-right">
      {!!optionData?.length && (
        <Dropdown
          id={cluster?.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          buttonLabel="Actions"
        />
      )}

      <WarningModal
        title={t('pages.adminServerTiers.deletingServerTier')}
        text={t('pages.adminServerTiers.deleteServerTier')}
        label={t('pages.adminServerTiers.serverTierName')}
        name={cluster?.name}
        isLoading={deleteServerTierModal.isLoading}
        buttenText={t('common.delete')}
        onClick={onDelete}
        {...deleteServerTierModal}
      />
    </div>
  );
}

export default ClusterActions;
