import React, { useMemo, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';

import paths from 'constants/paths';
import Issues from './Issues';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

function IssuesPage() {
  const { t, i18n } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentSystem = useSelector(currentSystemSelector);
  const isCantChangeForm = checkedPermissions(scopes.issue.issueCreate) && systemId;
  const [isShowFilters, setIsShowFilters] = useState(false);

  const addIssueButton = isCantChangeForm && (
    <Tooltip
      className={notActiveSystemMessage ? undefined : 'd-none'}
      content={notActiveSystemMessage}
      exitDelay={150}
      animationDuration={150}
    >
      <div>
        <BlueButton
          variant="primary"
          isDisabled={notActiveSystemMessage}
          component={props => (
            <Link {...props} to={generatePath(paths.systemIssuesFullPaths.newIssue)} />
          )}
        >
          {t('common.add')}
        </BlueButton>
      </div>
    </Tooltip>
  );

  const leftSideContent = isShowFilters && <>{addIssueButton}</>;

  return (
    <PageLayout
      systemLayout
      withSystemHeader
      withSystemSelector
      title={t('common.issues')}
      leftSideContent={leftSideContent}
    >
      <Issues
        addIssueButton={isCantChangeForm ? addIssueButton : undefined}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        currentTotal={currentSystem?.uuid && currentSystem?.totalSummary?.issueTotal?.total}
      />
    </PageLayout>
  );
}

export default IssuesPage;
