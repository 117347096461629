import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import InProgressIcon from '@patternfly/react-icons/dist/esm/icons/in-progress-icon';
import AttentionBellIcon from '@patternfly/react-icons/dist/esm/icons/attention-bell-icon';

import { getTotalSummaryNames } from 'constants';

function TotalSummaryStatuses({ totalSummary }) {
  const styles = 'ml-3 align-items-center d-flex line-height-normal';
  const totalSummaryNames = getTotalSummaryNames();

  return (
    <span className="d-flex line-height-normal">
      {totalSummary?.error > 0 && (
        <span className={styles}>
          {totalSummary?.error}
          <Tooltip
            content={totalSummary?.errorMessage || totalSummaryNames.ERROR}
            exitDelay={1}
            animationDuration={1}
          >
            <ExclamationCircleIcon className="ml-2 text-red cursor-pointer" />
          </Tooltip>
        </span>
      )}
      {totalSummary?.inProgress > 0 && (
        <span className={styles}>
          {totalSummary?.inProgress}
          <Tooltip
            content={totalSummary?.inProgressMessage || totalSummaryNames.NEW}
            exitDelay={1}
            animationDuration={1}
          >
            <InProgressIcon className="ml-2 text-gren cursor-pointer" />
          </Tooltip>
        </span>
      )}
      {totalSummary?.requireApproval > 0 && (
        <span className={styles}>
          {totalSummary?.requireApproval}
          <Tooltip content={totalSummaryNames.READY} exitDelay={1} animationDuration={1}>
            <AttentionBellIcon className="ml-2 text-red cursor-pointer" />
          </Tooltip>
        </span>
      )}
    </span>
  );
}

export default TotalSummaryStatuses;
