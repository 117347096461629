import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import api from 'api';
import useAsync from 'hooks/useAsync';
import paths from 'constants/paths';
import { CloseButton } from 'components/UI/CloseButton';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { getTooltipMessage } from 'constants/tooltips';
import { releaseOptions, releaseStatusKeys } from 'constants';

import * as S from './styles';

export const filterReleasesStatus = isDeployToDevAction =>
  isDeployToDevAction
    ? [
        releaseStatusKeys.NEW,
        releaseStatusKeys.READY,
        releaseStatusKeys.APPROVED,
        releaseStatusKeys.CANCELLED,
      ]
    : [releaseStatusKeys.APPROVED];

function DeployReleaseForm({ isModalVisible, toggleModal, modalData, isDeployToDevAction }) {
  const { t } = useTranslation();
  const tooltipMessage = getTooltipMessage();
  const {
    params: { releaseId },
  } = useRouteMatch();
  const getLastRelease = useAsync();
  const deployRelease = useAsync();
  const getReleasesOptions = useAsync();
  const history = useHistory();
  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'all',
  });

  const choosenReleaseUuid = formMethods.watch('release.uuid');
  const releasesOptions = useMemo(
    () => generateSelectOptions(getReleasesOptions.data?.data, 'uuid', 'name'),
    [getReleasesOptions.data?.data]
  );
  const requestParams = useMemo(
    () => ({
      tier: modalData?.tierId,
      ascending: false,
      orderBy: 'lastUpdatedAt',
      limit: 1,
    }),
    [releaseId, modalData?.tierId]
  );
  const optionsRequestParams = useMemo(
    () => ({
      system: modalData?.system.uuid,
      status: filterReleasesStatus(isDeployToDevAction),
      limit: 10,
      ascending: false,
      orderBy: 'lastUpdatedAt',
    }),
    [modalData?.system, isDeployToDevAction]
  );

  const searchFilter = async q => {
    try {
      const filterOptions = await getReleasesOptions.run(
        api.releases.getReleasesOptions({
          ...optionsRequestParams,
          q,
        })
      );

      return generateSelectOptions(filterOptions.data, 'uuid', 'name');
    } catch (e) {
      return [];
    }
  };

  function requestOptionsFunction(params) {
    getReleasesOptions.run(
      api.releases.getReleasesOptions({
        ...optionsRequestParams,
        ...params,
      })
    );
  }

  useEffect(() => {
    if (isModalVisible && modalData?.tierId) {
      getLastRelease.run(api.tiers.getDeployedRelease(requestParams));
    } else {
      formMethods.reset();
      getReleasesOptions.reset([]);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (getLastRelease.data?.data) {
      formMethods.setValue('standVersion', getLastRelease.data.data[0]?.release?.name || '');
    }
  }, [getLastRelease.data?.data]);

  const onClickGeneratePath = tab => ({
    pathname: generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
      systemId: modalData?.system.uuid,
      systemType: modalData?.system.type,
      releaseId: choosenReleaseUuid || releaseId,
      action: releaseOptions.view,
    }),
    state: {
      currentTab: tab,
    },
  });

  useEffect(() => {
    if (deployRelease.isSuccess && (choosenReleaseUuid || releaseId)) {
      toggleModal();
      history.push(onClickGeneratePath(2));
    }
  }, [deployRelease.isSuccess]);

  const onDeployReleaseClick = ({ release }) => {
    deployRelease.run(
      api.releases.deployRelease(release.uuid, {
        tier: { uuid: modalData?.tierId },
      })
    );
  };

  const onClose = () => {
    formMethods.reset();
    toggleModal();
  };

  const isDeployButtonDisabled = isDeployToDevAction && !getReleasesOptions.data?.data?.length;
  const isDisabled = !formMethods.formState.isValid;

  return (
    <S.ReactModalStyled isOpen={isModalVisible} onRequestClose={toggleModal} ariaHideApp={false}>
      <div className="d-flex flex-row justify-content-between p-4">
        <span className="font-24 font-weight-medium">
          {isDeployToDevAction
            ? t('pages.systemTierPage.deployToDevTier')
            : t('pages.systemTierPage.deployToTier')}
        </span>
        <CloseButton onClick={onClose} />
      </div>

      <FormProvider {...formMethods}>
        <S.DeployBlockWrapper>
          <InputComponent
            label={t('common.release')}
            name="release.uuid"
            component={inputComponents.newSelect}
            newSelectVariant="typeahead"
            searchFilter={searchFilter}
            options={releasesOptions}
            withInfiniteScroll={{
              total: getReleasesOptions.data?.meta?.total,
              offset: 10,
              loadMore: requestOptionsFunction,
            }}
            placeholder={t('pages.systemTierPage.searchByVersionOrDescription')}
            isRequired
            disabled={isDeployButtonDisabled}
            tooltipMessage={tooltipMessage.requiredField}
          />
          <div className="pt-4">
            <InputComponent
              label={t('pages.systemServiceVersionPage.currentVersion')}
              name="standVersion"
              disabled
            />
          </div>
        </S.DeployBlockWrapper>

        <ActionListGroup className="p-4">
          <Tooltip
            className={isDisabled ? undefined : 'd-none'}
            content={isDisabled && tooltipFormDisableMessage()}
            exitDelay={150}
            animationDuration={150}
          >
            <ActionListItem>
              <Button
                onClick={formMethods.handleSubmit(onDeployReleaseClick)}
                isLoading={deployRelease.isLoading || formMethods.formState.isSubmitting}
                isDisabled={
                  deployRelease.isLoading || formMethods.formState.isSubmitting || isDisabled
                }
              >
                {t('common.deploy')}
              </Button>
            </ActionListItem>
          </Tooltip>
          <ActionListItem>
            <Button variant="link" id="cancel-button" className="ml-3" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </ActionListItem>
        </ActionListGroup>
      </FormProvider>
    </S.ReactModalStyled>
  );
}

export default DeployReleaseForm;
