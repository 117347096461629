import React from 'react';

import LimitsParamet from 'pages/AdminRouter/ServerTiersRouter/ServerTierPage/ServerTierEquipment/EquipmentTable/LimitsParamet';

import { cpuUnitKeys, limitsKeys } from '../../../constants';

export const getAvailableResources = ({ total = 0, used = 0, limits = 0 }) =>
  parseFloat(total) - Math.max(parseFloat(used), parseFloat(limits)) || 0;

function LimitsBlock({ availableResources, limits, numbersAfterComma }) {
  const availableCpu =
    limits?.CPU?.value ||
    (availableResources && getAvailableResources(availableResources?.CPU || {}).toFixed(3));
  const availableMemory =
    limits?.MEMORY?.value ||
    (availableResources && getAvailableResources(availableResources?.MEMORY || {}));
  const availableStorage =
    limits?.STORAGE?.value ||
    (availableResources && getAvailableResources(availableResources?.STORAGE || {}));

  return (
    <>
      <LimitsParamet
        resource={{
          value: availableCpu,
          unitType: limits?.CPU?.unitType || cpuUnitKeys.cores,
        }}
        limitType={limitsKeys.CPU}
        isLabel
      />
      <LimitsParamet
        resource={{
          value: availableMemory,
          unitType: limits?.MEMORY?.unitType,
        }}
        limitType={limitsKeys.MEMORY}
        isLabel
        numbersAfterComma={numbersAfterComma}
      />
      <LimitsParamet
        resource={{
          value: availableStorage,
          unitType: limits?.STORAGE?.unitType,
        }}
        limitType={limitsKeys.STORAGE}
        isLabel
        numbersAfterComma={numbersAfterComma}
      />
    </>
  );
}

export default LimitsBlock;
