import React from 'react';
import { useTranslation } from 'react-i18next';

import useComponentVisible from 'hooks/useComponentVisible';

import * as S from './styles';

const ClipboardMenu = ({ url, children, label, withLickButton }) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, toggleComponentVisible } = useComponentVisible(false);

  const copy = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <S.Wrapper ref={ref} onClick={toggleComponentVisible} aria-labelledby={label}>
      {children}
      <S.ClipboardWrapper active={isComponentVisible} withLickButton={withLickButton}>
        <S.ClipboardUrl>{url}</S.ClipboardUrl>
        <S.ClipboardButton withLickButton={withLickButton} onClick={copy}>
          {t('components.clipboard.copy')}
        </S.ClipboardButton>
        {withLickButton && (
          <S.LinkButton href={url} target="_blank">
            {t('components.clipboard.go')}
          </S.LinkButton>
        )}
      </S.ClipboardWrapper>
    </S.Wrapper>
  );
};

export default ClipboardMenu;
