import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchServiceEndpoints = createAsyncThunk(
  'serviceEndpoints/fetchServiceEndpoints',
  async params => {
    const { data } = await api.serviceEndpoints.getServiceEndpoints(params);

    return data;
  }
);
