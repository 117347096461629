import { InputsBlockWrapper } from 'components/UI/View';
import styled from 'styled-components';

export const TasksForm = styled.form.attrs({
  className: 'pl-4 mb-2',
})`
  width: 655px;
`;

export const TasksBlockWrapper = styled(InputsBlockWrapper)`
  gap: 20px;
`;

export const InputComponentWrapper = styled.div`
  width: 655px;
`;
