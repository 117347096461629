import React from 'react';
import { Badge } from '@patternfly/react-core';

import { RowView } from 'components/UI/View';

import * as S from './styles';

function MenuComponent({
  menu = [],
  isChild,
  generateSystemPath,
  expandedItems,
  setExpandedItems,
}) {
  return (
    <>
      {menu.map(item => {
        if (item.isHidden) {
          return null;
        }

        const isExpanded = expandedItems.includes(item.url);

        const onMenuItemClick = e => {
          e.stopPropagation();
          if (item?.isCanExpand) {
            e.preventDefault();
            setExpandedItems(prevState =>
              isExpanded
                ? prevState.filter(element => element !== item.url)
                : [...prevState, item.url]
            );
          }
        };

        return (
          <React.Fragment key={item.url}>
            <S.MenuItem
              key={item.url}
              to={item.url}
              exact={item.exact}
              child={isChild ? 1 : 0}
              onClick={onMenuItemClick}
            >
              <RowView>
                <S.MenuText className="nav-item">
                  <span>{item.name}</span>
                  {!!item?.total && (
                    <Badge className="ml-2" key={item.url} isRead>
                      {item.total}
                    </Badge>
                  )}
                </S.MenuText>
              </RowView>
              {item?.isCanExpand && item.children?.length > 0 && (
                <S.ArrowButtonWrapper>
                  <S.ArrowDownIcon isExpanded={isExpanded} />
                </S.ArrowButtonWrapper>
              )}
            </S.MenuItem>
            {item.needDivider && <S.Divider />}
            {(!item?.isCanExpand || isExpanded) && (
              <MenuComponent
                menu={item.children}
                isChild
                generateSystemPath={generateSystemPath}
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default MenuComponent;
