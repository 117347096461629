import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

import { SelectGroup, SelectOption, SelectVariant } from '@patternfly/react-core/deprecated';
import { currentSystemSelector } from 'store/systems/selectors';

import * as S from './styles';

function MultiSelectorDropdown({
  needText,
  optionData = [],
  onFilterChange,
  needDeleteAll,
  checkedIds = [],
  onDeleteFiltersChange,
}) {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const currentSystem = useSelector(currentSystemSelector);
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(checkedIds);

  const titleId = 'checkbox-select-with-counts-id';

  useEffect(() => {
    if (checkedIds) {
      setSelected(checkedIds.map(item => optionData.find(option => option.id === item)?.name));
    }
  }, [optionData]);

  const onToggle = () => setOpen(!isOpen);

  const onSelect = (event, selection) => {
    if (selected.includes(selection)) {
      setSelected(prevState => prevState.filter(item => item !== selection));
    } else {
      setSelected(prevState => [...prevState, selection]);
    }
  };

  const clearSelection = id => {
    onDeleteFiltersChange(id);
    setSelected([]);
  };

  useEffect(() => {
    if (systemId !== currentSystem?.uuid) {
      onDeleteFiltersChange();
    }
  }, [systemId, currentSystem?.uuid]);

  let options = [];

  const groups = uniq(optionData.map(option => option.group).filter(Boolean));

  if (groups.length) {
    optionData.forEach(option => ({ ...option, id: `${option.group}_${option.id}` }));

    options = groups.map(group => (
      <SelectGroup label={group} key={group}>
        {optionData
          .filter(option => option.group === group)
          .map(option => (
            <SelectOption
              key={option.id}
              value={option.name}
              onClick={() => onFilterChange(option.id)}
            >
              {option.content}
            </SelectOption>
          ))}
      </SelectGroup>
    ));
  } else {
    options = optionData.map(option => (
      <SelectOption key={option.id} value={option.name} onClick={() => onFilterChange(option.id)} />
    ));
  }

  if (needDeleteAll) {
    options.push(
      <S.ResetAllButton onClick={() => clearSelection(checkedIds.id)}>
        {t('components.select.resetAll')}
      </S.ResetAllButton>
    );
  }

  return (
    <S.StyledSelector
      variant={SelectVariant.checkbox}
      aria-label="Select Input"
      onToggle={onToggle}
      onSelect={onSelect}
      selections={selected}
      isOpen={isOpen}
      isGrouped={groups.length > 0}
      placeholderText={needText || t('common.all')}
      aria-labelledby={titleId}
    >
      {options}
    </S.StyledSelector>
  );
}

export default MultiSelectorDropdown;
