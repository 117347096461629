import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .pf-v5-c-tab-content {
    flex: 1;
  }
`;

export const DevLabel = styled.div.attrs({
  className: 'font-14 font-weight-normal text-white px-2 py-1 ml-3 text-nowrap',
})`
  background: #6753ac;
  border-radius: 14px;
`;
