import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import { dateConverter, dateIsExceeded } from 'utils';
import { useTranslation } from 'react-i18next';

function MilestoneDates({ startDate, endDate, isOpenMilestone, rightContent }) {
  const { t } = useTranslation();
  const styles = `d-flex flex-row text-nowrap${rightContent ? ' justify-content-end' : ''}`;
  const messageForMilestoneDateIsExceeded = t('pages.systemAddMilestonePage.milestoneExpired');

  return (
    <div className={styles}>
      {startDate && `${dateConverter(startDate)} – `}
      {isOpenMilestone && dateIsExceeded(endDate) ? (
        <div>
          <span className="text-red">{dateConverter(endDate)}</span>
          <Tooltip
            content={messageForMilestoneDateIsExceeded}
            exitDelay={150}
            animationDuration={150}
          >
            <ExclamationCircleIcon className="ml-2 text-red" />
          </Tooltip>
        </div>
      ) : (
        <>{`${dateConverter(endDate)}`}</>
      )}
    </div>
  );
}

export default MilestoneDates;
