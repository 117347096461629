import React from 'react';

import { getLimitsLabels, limitsKeys } from 'constants';
import { convertLimits, convertValueToBytes, convertValueToMillicores } from 'utils';

function LimitsParamet({ resource, limitType, isLabel, numbersAfterComma = 3 }) {
  const styles = isLabel ? 'font-14 font-weight-medium mb-2' : 'font-12';
  const limitsLabels = getLimitsLabels();
  const formatValue =
    limitType === limitsKeys.CPU
      ? convertValueToMillicores(resource?.value, resource?.unitType)
      : convertValueToBytes(resource?.value, resource?.unitType);

  return (
    <div key={limitType}>
      <div className={`d-flex nowrap ${styles}`}>{limitsLabels[limitType]}</div>
      <div className={`d-flex ${isLabel ? 'font-16' : 'font-14'} nowrap`}>
        {convertLimits(formatValue, limitType, isLabel ? numbersAfterComma : 1, true)}
      </div>
    </div>
  );
}

export default LimitsParamet;
