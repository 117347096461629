import React from 'react';
import { Controller } from 'react-hook-form';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import { numberInputValueRegex } from 'constants/vaidations';

import { Input, ExampleButton, ExampleWrapper, TextValue, IconByStatus } from './styles';

const InputController = ({
  name,
  control,
  rules,
  examples,
  label,
  onBlurAction,
  onlyTextValue,
  withIconByStatus,
  setIsFocus,
  isFocus,
  isFieldValue,
  isTextInput,
  onFocusAction,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={inputProps.defaultValue || ''}
    render={({ field }) => {
      const onChange = e => {
        if (isTextInput && e.target.value.match(numberInputValueRegex)) {
          return;
        }

        field.onChange(e);
      };
      const fieldValue = (!!field.value?.length && field.value?.trim()) || undefined;

      return (
        <div className="position-relative">
          {onlyTextValue ? (
            <TextValue>{field.value?.trim() || '-'}</TextValue>
          ) : (
            <>
              <Input
                $onlyTextValue={onlyTextValue}
                $inputStatus={fieldValue ? withIconByStatus : undefined}
                data-input-for={label}
                {...field}
                {...inputProps}
                value={field.value === undefined ? '' : field.value}
                onFocus={() => {
                  setIsFocus(true);
                  if (onFocusAction) {
                    onFocusAction();
                  }
                }}
                isFocus={isFocus}
                $isFieldValue={isFieldValue}
                onChange={onChange}
                onBlur={() => {
                  field.onChange(fieldValue);
                  setIsFocus(false);
                  if (onBlurAction) {
                    onBlurAction();
                  }
                }}
              />
              {fieldValue && (
                <IconByStatus>
                  {withIconByStatus === false && <ExclamationCircleIcon className="text-red" />}
                  {withIconByStatus === true && <CheckCircleIcon className="text-gren-light" />}
                </IconByStatus>
              )}
            </>
          )}
          {!!examples.length && (
            <ExampleWrapper>
              {examples.map(example => (
                <ExampleButton key={label + example} onClick={() => onChange(example)}>
                  {example}
                </ExampleButton>
              ))}
            </ExampleWrapper>
          )}
        </div>
      );
    }}
  />
);

export default InputController;
