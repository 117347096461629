import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import IntegrationsPage from './IntegrationsPage';
import AddEditPage from './AddEditPage';
import { featureFlags } from '../../../constants/features';
import ProtectedFeatureRoute from '../../../routes/ProtectedFeatureRoute';

const SystemIntegrationsRouter = ({ match: { path } }) => (
  <ProtectedFeatureRoute isFeatureEnabled={featureFlags.isIntegrationsFeatureEnabled}>
    <Switch>
      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemIntegrationsPaths.add}`}
        component={AddEditPage}
        scope={scopes.integration.integrationCreate}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemIntegrationsPaths.editService}`}
        component={AddEditPage}
        scope={scopes.integration.integrationUpdate}
      />

      <Route
        exact
        path={`${path}${paths.systemIntegrationsPaths.editTiersBound}`}
        component={AddEditPage}
      />

      <Route exact path={path} component={IntegrationsPage} />

      <Redirect to={path} />
    </Switch>
  </ProtectedFeatureRoute>
);

export default SystemIntegrationsRouter;
