import styled from 'styled-components';
import { InformationBlockWrapper, BlockWrapper } from 'components/UI/View';

export const Wrapper = styled.div`
  width: 786px;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  color: #151515;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export const WindowCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 786px;
  padding: 24px 24px 24px;
  box-shadow: inset 0px -1px 0px #f0f0f0, inset 1px 0px 0px #ffffff, inset 0px 1px 0px #ffffff,
    inset -1px 0px 0px #ffffff;
`;

export const WindowRespEmployeeInfo = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const WindowCompanyNameInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InformationBlock = styled(InformationBlockWrapper)`
  border-bottom: 1px solid #f0f0f0;
  margin-top: -16px;
  padding: 16px 24px;
`;

export const InformationBlockHead = styled.thead`
  width: 100%;
  margin-top: -16px;
`;

export const InformationBlockTableLine = styled.tr`
  ${BlockWrapper}
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  padding: 8px 16px;
`;

export const InformationBlockTableHeadLine = styled(InformationBlockTableLine)`
  margin-top: 16px;
`;

export const NameWrapper = styled.div`
  color: #151515;
  font-weight: 600;
  font-size: 14px;
`;

export const LinkWrapper = styled.a`
  ${NameWrapper}
  font-weight: 400;
  line-height: 20px;
  min-width: 50px;
`;

export const CompanyWrapper = styled.div`
  color: #151515;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;
