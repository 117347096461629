import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { currentSystemSelector } from 'store/systems/selectors';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from 'components/UI/DeleteButton';

import { getContactsDescriptionMap, phoneValidation, emailValidation } from 'constants/vaidations';

import ContactUserSelect from './ContactUserSelect';
import { DynamicFormWrapper } from '../../../styles';

const ContactsForm = ({ dynamicParentField, fieldUuids, fieldUuid, index, deleteField }) => {
  const contactsDescriptionMap = getContactsDescriptionMap();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const currentSystem = useSelector(currentSystemSelector);

  const systemContactPerson = watch(`systemContactPersons[${index}]`);
  const contactValue = watch(`${dynamicParentField}[${index}]`);
  const removedContactPersons = watch('removedContactPersons');
  const phoneValidationRules = useMemo(() => phoneValidation, []);
  const emailValidationRules = useMemo(() => emailValidation, []);

  useEffect(() => {
    if (systemContactPerson) {
      setValue(`${dynamicParentField}[${index}]`, systemContactPerson);
    }
  }, []);

  const onDeleteClick = () => {
    if (contactValue?.uuid) {
      setValue('removedContactPersons', [
        ...(removedContactPersons?.length ? removedContactPersons : []),
        {
          uuid: contactValue?.uuid,
        },
      ]);
    }

    setValue(`${dynamicParentField}[${index}]`, {
      fullName: '',
      email: '',
      phone: '',
      workplace: '',
      comment: '',
      userUuid: '',
      uuid: undefined,
      selectedOption: { fullName: '', uuid: '' },
      userOptions: [],
    });
    deleteField(fieldUuid, index);
  };

  return (
    <DynamicFormWrapper key={fieldUuid}>
      <div>
        {fieldUuids.length > 1 && (
          <div className="d-flex float-inline-end">
            <DeleteButton onClick={onDeleteClick}>
              {t('pages.addNewSystemPage.deleteContact')}
            </DeleteButton>
          </div>
        )}

        <ContactUserSelect
          fieldName={`${dynamicParentField}[${index}].userUuid`}
          selectedSystem={currentSystem}
          inputDescription={contactsDescriptionMap.name}
          dynamicParentField={dynamicParentField}
          index={index}
        />
      </div>
      <InputComponent
        label={t('common.email')}
        name={`${dynamicParentField}[${index}].email`}
        maxLength={300}
        description={contactsDescriptionMap.email}
        placeholder="___@___.__"
        isRequired
        validationRules={emailValidationRules}
      />
      <InputComponent
        label={t('common.phone')}
        name={`${dynamicParentField}[${index}].phone`}
        component={inputComponents.phoneInput}
        description={contactsDescriptionMap.phone}
        isRequired
        validationRules={phoneValidationRules}
      />
      <InputComponent
        label={t('common.work')}
        name={`${dynamicParentField}[${index}].workplace`}
        maxLength={150}
        description={contactsDescriptionMap.workplace}
        isRequired
      />
      <InputComponent
        label={t('common.comment')}
        name={`${dynamicParentField}[${index}].comment`}
        component={inputComponents.textarea}
        description={contactsDescriptionMap.comment}
        maxLength={500}
      />
    </DynamicFormWrapper>
  );
};

export default ContactsForm;
