export default {
  link: 'link',
  externalLink: 'externalLink',
  textLink: 'textLink',
  expendedContent: 'expendedContent',
  centerContent: 'centerContent',
  rightContent: 'rightContent',
  withId: 'withId',
  relativeTime: 'relativeTime',
};
