import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@patternfly/react-core';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HotfixSVG } from 'assets/images/releases/hotfix.svg';

import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchReleases } from 'store/releases/actions';
import { releasesSelector } from 'store/releases/selectors';
import { clearReleasesState } from 'store/releases/reducers';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent, {
  getReleasesAvailableStatuses,
  getSystemStatuses,
} from 'components/Common/StatusComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import TotalSummary from 'components/Common/TotalSummary';
import { featureFlags } from 'constants/features';
import { getReleasesTooltip, getTooltipMessage } from 'constants/tooltips';
import {
  getReleaseStatusNames,
  getReleaseTypeNames,
  releaseOptions,
  systemTypesKeys,
} from 'constants';

import ReleaseActions from '../ReleaseActions';
import DeployForm from '../../CurrentReleasesPage/DeployForm';
import DeclineReleaseForm from '../../CurrentReleasesPage/DeclineReleaseForm';
import { tableFiltersToQueryParams } from './formatters';

import * as S from './styles';

function ReleasesTable({
  serviceVersionId,
  addButton,
  setIsShowFilters,
  isShowFilters,
  currentTotal,
}) {
  const { t } = useTranslation();

  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, issuesId, serviceId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();

  const releases = useSelector(releasesSelector);
  const releasesAvailableStatuses = getReleasesAvailableStatuses();
  const releaseStatusNames = getReleaseStatusNames();
  const releaseTypeNames = getReleaseTypeNames();
  const tooltipMessage = getTooltipMessage();
  const releasesTooltip = getReleasesTooltip();
  const systemStatuses = getSystemStatuses();
  const changeRelease = useAsync();
  const declineReleaseModal = useModal();
  const deployReleaseModal = useModal();

  const filterData = [
    {
      id: 'q',
      name: t('common.version'),
    },
    {
      id: 'type',
      name: t('pages.systemReleasesPage.releaseType'),
      type: 'multiSelect',
      options: formatFilterOptions(releaseTypeNames),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(releaseStatusNames),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];

  const releasesTableFilters = useMultiSelectFilterSection(filterData, 'releases');

  useSocketAsync({
    topic: 'releases',
    filterBySystemUuid: systemId,
    options: { callBackThrottleTime: 1000 },
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  function requestFunction(requestParams) {
    dispatch(fetchReleases(tableFiltersToQueryParams(requestParams)));
  }

  const requestParams = useMemo(
    () => ({
      ...releasesTableFilters?.filterParams,
      system: systemId,
      serviceVersion: serviceVersionId,
      ascending: false,
      orderBy: 'lastUpdatedAt',
      service: serviceId,
      issue: issuesId,
      ...releasesTableFilters.filterParams?.date,
      date: undefined,
    }),
    [systemId, releasesTableFilters?.filterParams, serviceId, issuesId]
  );

  useEffect(() => () => dispatch(clearReleasesState()), []);

  useEffect(() => {
    if (changeRelease.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [changeRelease.isSuccess]);

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters?.(
        releasesTableFilters?.hasSelectedFilters || Boolean(releases.total) || Boolean(currentTotal)
      );
    }
  }, [releasesTableFilters?.hasSelectedFilters, releases.total, currentTotal]);

  const onClickGeneratePath = (item, tab) => ({
    pathname: generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
      systemId: item.system.uuid,
      systemType: item.system.type,
      releaseId: item.uuid,
      action: releaseOptions.view,
    }),
    state: {
      currentTab: tab,
    },
  });

  const headerData = [
    {
      key: 'name',
      label: t('common.version'),
      withSort: true,
    },
    {
      key: 'description',
      label: t('common.description'),
      width: 25,
    },
    {
      key: 'serviceVersionTotal',
      label: t('common.serviceVersions'),
      isHidden: !featureFlags.isServiceVersionsFeatureEnabled && serviceId && issuesId,
    },
    {
      key: 'tierReleaseTotal',
      label: t('common.deployments'),
    },
    {
      key: 'releaseIssueTotal',
      label: t('common.issues'),
      isHidden:
        !featureFlags.isIssuesFeatureEnabled &&
        !checkedPermissions(scopes.release.releaseViewIssues),
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      isHidden: systemId,
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = releases.data.map(item => ({
    id: item.uuid,
    data: [
      {
        key: 'name',
        content: (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.release.releaseViewForm}
            to={
              item.system.uuid
                ? generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
                    systemId: item.system.uuid,
                    systemType: item.system.type,
                    releaseId: item.uuid,
                    action: releaseOptions.view,
                  })
                : undefined
            }
            isTableLink
          >
            <div className="d-flex font-14 hyphens pre-line">
              {item.name}
              {item.hotfix && (
                <Tooltip content={t('common.hotfix')} exitDelay={150} animationDuration={150}>
                  <HotfixSVG className="ml-2" />
                </Tooltip>
              )}
            </div>
          </LinkWithPermission>
        ),
      },
      {
        key: 'description',
        content: item.description,
      },
      {
        key: 'serviceVersionTotal',
        content: (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.release.releaseViewForm}
            to={onClickGeneratePath(item, 1)}
            isTableLink
          >
            <TotalSummary
              total={item.totalSummary?.serviceVersionTotal.total}
              inProgress={item.totalSummary?.serviceVersionTotal.inProgress}
              error={item.totalSummary?.serviceVersionTotal.error}
              isServiceVersionTotal
              errorMessage={releasesTooltip.releaseVersionError}
            />
          </LinkWithPermission>
        ),
      },
      {
        key: 'tierReleaseTotal',
        content: (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.release.releaseViewForm}
            to={onClickGeneratePath(item, 2)}
            isTableLink
          >
            <TotalSummary
              total={item.totalSummary?.tierReleaseTotal.total}
              inProgress={item.totalSummary?.tierReleaseTotal.inProgress}
              error={item.totalSummary?.tierReleaseTotal.error}
              inProgressMessage={tooltipMessage.inProgressMessage}
              tierReleaseTotal
            />
          </LinkWithPermission>
        ),
      },
      {
        key: 'releaseIssueTotal',
        content: (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.release.releaseViewForm}
            to={onClickGeneratePath(item, 3)}
            isTableLink
          >
            {((systemId && checkedPermissions(scopes.release.releaseViewIssues)) || !systemId) && (
              <TotalSummary total={item.totalSummary?.releaseIssueTotal.total} releaseIssueTotal />
            )}
          </LinkWithPermission>
        ),
      },
      ...(!systemId
        ? [
            {
              key: 'system.shortName',
              content: (
                <LinkWithPermission
                  system={item?.system}
                  scope={scopes.system.systemViewForm}
                  to={generatePath(paths.routePaths.system, {
                    systemId: item.system.uuid,
                    systemType: item.system.type,
                  })}
                  isTableLink
                >
                  <StatusComponent
                    statusId={
                      item.system.type === systemTypesKeys.EXTERNAL
                        ? systemTypesKeys.EXTERNAL
                        : item.system.status
                    }
                    statusWithTooltip
                    statuses={systemStatuses}
                    truncate
                  >
                    {item.system.shortName}
                  </StatusComponent>
                </LinkWithPermission>
              ),
            },
          ]
        : []),
      {
        key: 'status',
        content: item?.status && (
          <S.StatusWrapper>
            <StatusComponent statusId={item.status} statuses={releasesAvailableStatuses} />
          </S.StatusWrapper>
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={item?.lastUpdatedBy}
            system={item?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: item?.lastUpdatedAt,
      },
      {
        key: 'dropdownMenu',
        content: (
          <ReleaseActions
            release={item}
            deployReleaseModal={deployReleaseModal}
            declineReleaseModal={declineReleaseModal}
            changeRelease={changeRelease}
          />
        ),
      },
    ],
  }));

  return (
    <>
      {isShowFilters && (
        <div className="d-flex flex-row align-items-baseline ml-4">
          {releasesTableFilters?.FilterComponent}
        </div>
      )}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        tableName="releases"
        requestParams={requestParams}
        total={releases.total}
        withStandartPagination
        isLoading={releases.isLoading}
        hasSelectedFilters={releasesTableFilters?.hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={releases.isError}
      />

      <DeployForm {...deployReleaseModal} isDevTier={deployReleaseModal.modalData?.deployToDev} />

      <DeclineReleaseForm
        {...declineReleaseModal}
        callback={() => tableRef.current?.requestDataWithParams()}
      />
    </>
  );
}

export default ReleasesTable;
