import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MenuSVG from 'assets/images/menu/menu.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #1b1d23;
  height: 76px;
  min-height: 76px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
`;

export const MenuBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MenuIco = styled.div`
  display: flex;
  height: 18px;
  width: 21px;
  background: url(${MenuSVG}) center no-repeat;
  background-size: cover;
  margin-left: 25px;
  cursor: pointer;
`;

export const Logo = styled(Link)`
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 8px;
  text-decoration: none;
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  height: 24px;
`;

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
`;

export const LanguageList = styled.div`
  display: flex;
  margin-right: 24px;

  button {
    background: transparent;
    border: none;
    padding: 0 16px;
    width: 52px;

    &:hover {
      font-weight: bold;
    }

    &:first-child {
      border-right: 1px solid #4e556a;
    }
  }
`;

export const ExitBlock = styled.div`
  display: flex;
  margin-left: 16px;
  padding-left: 16px;
  margin-right: 24px;
  cursor: pointer;
  border-left: 1px solid #4e556a;
  width: 84px;
`;

export const ExitText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  font-weight: 400;
  color: #ffffff;

  &:hover {
    font-weight: bold;
  }
`;
