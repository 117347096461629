import styled from 'styled-components';

import { Popover } from '@patternfly/react-core';

export const PopoverStyled = styled(Popover)`
  .pf-v5-c-popover__content {
    min-width: 250px !important;
  }

  .pf-v5-c-title {
    box-sizing: border-box;
    font-family: Inter, Roboto, sans-serif;
  }

  .pf-v5-m-link {
    outline: none;
  }
`;

export const ButtonsWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-row',
})`
  gap: 11px;
`;

export const DetailsWrapper = styled.div.attrs({
  className: 'd-sm-flex justify-content-end cursor-pointer',
})`
  width: 32px;
`;

export const RestartWrapper = styled.div`
  height: fit-content;
`;
