import styled from 'styled-components';
import WithInfoSVG from 'assets/images/tasks/WithInfo.svg';

export const TitleInfoIcon = styled.span`
  position: absolute;
  float: right;
  height: 16px;
  width: 16px;
  background: #0f69c9;
  -webkit-mask-image: url(${WithInfoSVG});
  mask-image: url(${WithInfoSVG});
  mask-repeat: no-repeat;
  margin: 4px 0px 4px 8px;
`;
