import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorServerSVG } from 'assets/images/basic/error-server.svg';

const ErrorPageMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center">
        <ErrorServerSVG className="" style={{ marginBottom: '42px' }} />
      </div>
      <div className="align-self-center">
        <h2 className="font-24 font-weight-medium text-center my-2">
          {t('pages.errorPage.serverNotResponding')}
        </h2>
        <div className="font-16 font-weight-normal text-center">
          {t('pages.errorPage.serverNotRespondingMessage')}
        </div>
      </div>
    </>
  );
};

export default ErrorPageMessage;
