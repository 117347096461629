import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import { isBoolean } from 'lodash';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { declOfNum } from 'utils';
import { EditPencilIcon } from 'components/UI/EditPencilIcon';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { intervalMethodKeys, getBackupFrequencyUnit } from 'constants';
import Frequency from 'components/Common/Frequency';
import StatusComponent from 'components/Common/StatusComponent';
import { getIntervalSettingMethod } from 'components/Common/StatusComponent/statuses';
import * as S from './styles';

function EditFrequency({
  value = 0,
  unit = 'DAYS',
  isCalculated,
  componentUuid,
  requestFunction,
  isOpen,
  withStatus,
  isAdministration,
}) {
  const { t } = useTranslation();
  const backupFrequencyUnit = getBackupFrequencyUnit();
  const intervalSettingMethod = getIntervalSettingMethod();
  const { run, isSuccess, isLoading } = useAsync();
  const { checkedPermissions } = useUserPermissions();
  const { isModalVisible, toggleModal, setIsModalVisible } = useModal();
  const formMethods = useForm({
    defaultValues: {
      value,
      unit,
    },
  });

  const valueFrequencie = formMethods.watch('value');
  const unitFrequencie = formMethods.watch('unit');

  const frequencyConvert = item => backupFrequencyUnit[item];
  const backupUnit = ['MINUTES', 'HOURS', 'DAYS', 'MONTHS', 'YEARS'];
  const defaultText = withStatus
    ? t('pages.systemTierPage.notSpecifiedUpper')
    : t('pages.systemTierPage.notSpecifiedLower');
  const text = withStatus
    ? t('pages.systemTierPage.specifiedUpper')
    : t('pages.systemTierPage.specifiedLower');
  const backupFrequencies = backupUnit.map(item => ({
    id: item,
    name: declOfNum(valueFrequencie, frequencyConvert(item), false, true),
  }));

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    }
  }, []);

  useEffect(() => {
    if (unitFrequencie !== unit || valueFrequencie !== value) {
      formMethods.reset({
        value,
        unit,
      });
    }
  }, [unit, value]);

  useEffect(() => {
    if (isModalVisible) {
      formMethods.setValue('value', value);
      formMethods.setValue('unit', unit);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isSuccess) {
      requestFunction?.();
      toggleModal();
    }
  }, [isSuccess]);

  const CancelIcon = () => {
    formMethods.reset();
    toggleModal();
  };

  const onSubmit = async data => {
    const formatedData = {
      value: data.value,
      unit: data.unit,
    };

    run(api.backups.changeComponentsFrequency(componentUuid, formatedData));
  };

  const canChangeFrequency =
    componentUuid &&
    checkedPermissions(
      isAdministration ? scopes.platform.backupUpdate : scopes.tier.tierUpdateBackupPeriod,
      isAdministration
    );

  return (
    <FormProvider {...formMethods}>
      <S.FormWrapper onSubmit={formMethods.handleSubmit(onSubmit)} className="d-flex">
        {!isModalVisible && (
          <div className="d-sm-flex flex-column">
            <div className="d-flex position-relative">
              {withStatus && isBoolean(isCalculated) && (
                <StatusComponent
                  statusId={
                    isCalculated
                      ? intervalMethodKeys.AUTOMATICALLY
                      : isCalculated === false && intervalMethodKeys.MANUALLY
                  }
                  statusWithTooltip
                  statuses={intervalSettingMethod}
                />
              )}
              {value === 0 ? (
                defaultText
              ) : (
                <Frequency value={valueFrequencie} unit={unitFrequencie} text={text} />
              )}
              {canChangeFrequency && <EditPencilIcon className="ml-2" onClick={toggleModal} />}
            </div>
          </div>
        )}

        {isModalVisible && (
          <div className="d-flex position-relative">
            <span className="py-2">{text}</span>
            <S.InputComponentWrapper>
              <InputComponent
                name="value"
                component={inputComponents.numberInput}
                intNumber
                isRequired
                withoutErrorsMessage
              />
            </S.InputComponentWrapper>
            <S.InputSelectWrapper>
              <InputComponent
                name="unit"
                component={inputComponents.newSelect}
                onlySelect
                checkedValue={backupFrequencies.find(({ id }) => id === unit)?.id}
                isRequired
                withoutErrorsMessage
                withoutClearButton
                options={generateSelectOptions(backupFrequencies, 'id', 'name')}
              />
            </S.InputSelectWrapper>
            {!isOpen && (
              <>
                <CheckIcon
                  className="ml-3 text-blu cursor-pointer"
                  disabled={!isLoading}
                  onClick={formMethods.handleSubmit(onSubmit)}
                />
                <TimesIcon className="ml-3 cursor-pointer" onClick={CancelIcon} />
              </>
            )}
          </div>
        )}
      </S.FormWrapper>
    </FormProvider>
  );
}

export default EditFrequency;
