import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ReactModalStyled = styled(ReactModal)`
  position: absolute;
  transform: translateX(-50%);
  border: 1px solid #cccccc;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 0px;
  width: 640px;
  max-height: 70vh;
  top: 15%;
  left: 50%;
  min-height: 280px;
  padding: 0px 8px;

  ${({ isSmallModal }) => isSmallModal && 'overflow-y: auto;'}
`;

export const Form = styled.form.attrs({
  className: 'd-sm-flex flex-column mx-4 mb-4',
})`
  gap: 24px;
  max-width: 750px;
`;
