import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import IssuesPage from './IssuesPage';
import EditIssuesPage from './EditIssuesPage';
import CurrentIssuePage from './CurrentIssuePage';
import { featureFlags } from '../../../constants/features';
import ProtectedFeatureRoute from '../../../routes/ProtectedFeatureRoute';

const SystemIssuesRouter = ({ match: { path } }) => (
  <ProtectedFeatureRoute isFeatureEnabled={featureFlags.isIssuesFeatureEnabled}>
    <Switch>
      <ProtectedSystemRoute
        path={`${path}${paths.systemIssuesPaths.newIssue}`}
        component={EditIssuesPage}
        scope={scopes.issue.issueCreate}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemIssuesPaths.editIssue}`}
        component={EditIssuesPage}
        scope={scopes.issue.issueUpdate}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemIssuesPaths.issues}`}
        component={CurrentIssuePage}
        scope={scopes.issue.issueViewSection}
      />

      <Route path={path} component={IssuesPage} />

      <Redirect to={path} />
    </Switch>
  </ProtectedFeatureRoute>
);

export default SystemIssuesRouter;
