import React from 'react';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { dateConverter } from 'utils';

import IssueDates from '../../IssuesPage/Issues/IssuesTable/IssueDates';
import IssueClosedAtDate from '../../IssuesPage/Issues/IssuesTable/IssueClosedAtDate';

import MilestoneStatusInfo from './MilestoneStatusInfo';

import * as S from './styles';

function IssueInfo({ issue }) {
  const { t } = useTranslation();

  if (!issue) {
    return null;
  }

  return (
    <div className="m-4 w-75 font-14">
      <DescriptionList
        columnModifier={{
          default: '2Col',
        }}
      >
        <S.DescriptionListTermWrapper>
          <DescriptionListGroup>
            <DescriptionListTerm>{t('common.description')}</DescriptionListTerm>
            <DescriptionListDescription>{issue.description || '-'}</DescriptionListDescription>
            <DescriptionListTerm className="mt-3">{t('common.startDate')}</DescriptionListTerm>
            <DescriptionListDescription>
              {`${dateConverter(issue.startDate)}`}
            </DescriptionListDescription>
          </DescriptionListGroup>
        </S.DescriptionListTermWrapper>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.plannedEndDate')}</DescriptionListTerm>
          <DescriptionListDescription>
            <IssueDates
              startDate={issue.startDate}
              dueDate={issue.dueDate}
              closedAt={issue.closedAt}
              milestoneEndDate={issue.milestone?.endDate}
            />
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('pages.systemIssuesPage.closingDate')}</DescriptionListTerm>
          <DescriptionListDescription>
            <IssueClosedAtDate dueDate={issue.dueDate} closedAt={issue.closedAt} />
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.milestone')}</DescriptionListTerm>
          <DescriptionListDescription>
            <MilestoneStatusInfo milestone={issue.milestone} />
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.updatedBy')}</DescriptionListTerm>
          <DescriptionListDescription>
            <LastUpdatedStatusWithLink
              lastUpdatedBy={issue?.lastUpdatedBy}
              lastUpdatedAt={issue?.lastUpdatedAt}
            />
          </DescriptionListDescription>
        </DescriptionListGroup>
      </DescriptionList>
    </div>
  );
}

export default IssueInfo;
