import React, { useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { BlueButton } from 'components/UI/Button';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import { usersActions } from 'constants/paths/adminUsersPaths';
import { getUserAdminNames, getUserPlatfornmStatusNames } from 'constants';
import UsersTable from './UsersTable';

function UsersPage() {
  const { t } = useTranslation();
  const userAdminNames = getUserAdminNames();
  const userPlatfornmStatusNames = getUserPlatfornmStatusNames();
  const { checkedPermissions } = useUserPermissions();
  const [isShowFilters, setIsShowFilters] = useState(false);
  const filterData = [
    {
      id: 'fullName',
      name: t('common.fio'),
    },
    {
      id: 'login',
      name: t('common.login'),
    },
    {
      id: 'email',
      name: t('common.email'),
    },
    {
      id: 'phone',
      name: t('common.phone'),
    },
    {
      id: 'workplace',
      name: t('common.work'),
    },
    {
      id: 'admin',
      name: t('pages.adminUsersPage.accessLevel'),
      type: 'multiSelect',
      options: formatFilterOptions(userAdminNames),
    },
    {
      id: 'status',
      name: t('pages.adminUsersPage.activity'),
      type: 'multiSelect',
      options: formatFilterOptions(userPlatfornmStatusNames),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const usersTableFilter = useMultiSelectFilterSection(filterData, 'users');

  const addUsersButton = checkedPermissions(scopes.user.userAddToSystem, true) && (
    <BlueButton
      component={props => (
        <Link
          {...props}
          to={generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.new, {
            action: usersActions.new,
          })}
        />
      )}
    >
      {t('common.add')}
    </BlueButton>
  );

  const leftSideContent = isShowFilters && <div className="mr-4">{addUsersButton}</div>;

  return (
    <PageLayout
      title={t('common.platformUsers')}
      withoutSidePadding
      leftSideContent={leftSideContent}
      fullHeight
    >
      <UsersTable
        isAdministration
        addButton={
          checkedPermissions(scopes.user.userAddToSystem, true) ? addUsersButton : undefined
        }
        tableFilters={usersTableFilter}
        setIsShowFilters={setIsShowFilters}
        isShowFilters={isShowFilters}
      />
    </PageLayout>
  );
}

export default UsersPage;
