import styled from 'styled-components';

export const ModalWrapper = styled.div.attrs({
  className: 'd-flex flex-column align-items-start gap-3 p-4',
})`
  min-width: 450px;
  min-height: 160px;
  max-width: 900px;
  max-height: 500px;
`;
