import { createSelector } from 'reselect';

const servicesState = state => state.services;

export const servicesSelector = createSelector([servicesState], services => services);

export const importServiceSelector = createSelector(
  [servicesState],
  ({ importService }) => importService
);

export const importServiceIsLoadingSelector = createSelector(
  [servicesState],
  ({ importServiceIsLoading }) => importServiceIsLoading
);

export const repositoriesSelector = createSelector(
  [servicesState],
  ({ repositories }) => repositories
);

export const selectedRepositoriesSelector = createSelector(
  [servicesState],
  ({ selectedRepositories }) => selectedRepositories
);

export const serviceByActiveStatusSelector = createSelector(
  [servicesState],
  ({ serviceByActiveStatus }) => serviceByActiveStatus
);

export const serviceByActiveStatusLoadingSelector = createSelector(
  [servicesState],
  ({ serviceByActiveStatusLoading }) => serviceByActiveStatusLoading
);
