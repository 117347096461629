import React, { useState } from 'react';

import TopMenu from './TopMenu';
import LeftMenu from './LeftMenu';

import * as S from './styles';

function MenuLayout({ children }) {
  const [isShowLeftMenu, setIsShowLeftMenu] = useState(true);

  return (
    <S.MainWrapper>
      <TopMenu setIsShowLeftMenu={setIsShowLeftMenu} />
      <S.Row showLeftMenu={isShowLeftMenu}>
        <LeftMenu showLeftMenu={isShowLeftMenu} />
        {children}
      </S.Row>
    </S.MainWrapper>
  );
}

export default MenuLayout;
