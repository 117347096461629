import React from 'react';
import moment from 'moment';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { Button, ButtonVariant } from '@patternfly/react-core';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';
import { dateConverter } from 'utils';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/UI/TextBlock';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import PopoverButton from 'components/Common/PopoverButton';
import paths from 'constants/paths';

import SystemDeveloper from './SystemDeveloper';

function SystemDetails({ system, lastContract, developer, isInternalSystem }) {
  const { t } = useTranslation();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();

  return (
    <div className="d-flex align-items-start flex-column gap-1 mt-3">
      <TextBlock greyColor title={t('common.name')}>
        <div className="word-wrap-break min-line-height font-14">{system?.name}</div>
      </TextBlock>

      {system?.description && (
        <TextBlock greyColor title={t('common.description')}>
          <div className="hyphens-break-word font-14">{system.description}</div>
        </TextBlock>
      )}

      {isInternalSystem && (
        <>
          {lastContract && (
            <TextBlock greyColor title={t('pages.systemDetailsPage.contract')} font="14">
              №{lastContract?.number} {dateConverter(moment(lastContract?.startDate).format())}–
              {dateConverter(moment(lastContract?.endDate).format())}
            </TextBlock>
          )}

          {system?.responsible?.uuid && (
            <TextBlock greyColor title={t('pages.systemDetailsPage.responsible')} font="14">
              <LastUpdatedStatusWithLink
                lastUpdatedBy={system.responsible}
                system={system}
                hideDateTime
                linkTo={
                  system.responsible.uuid
                    ? generatePath(paths.routePaths.system + paths.systemPaths.userDetails, {
                        systemId,
                        systemType,
                        userId: system.responsible.uuid,
                      })
                    : undefined
                }
              />
            </TextBlock>
          )}

          <TextBlock greyColor title={t('pages.systemDetailsPage.developer')} font="14">
            <p>{developer?.name}</p>
            <PopoverButton
              maxWidth="800px"
              position="right"
              headerContent={
                <h3 className="mt-3 mb-1 font-weight-medium">
                  {t('pages.systemDetailsPage.contacts')}
                </h3>
              }
              bodyContent={<SystemDeveloper {...developer} />}
              withTopPadding
            >
              <Button variant={ButtonVariant.link} className="p-0 font-14">
                {t('pages.systemDetailsPage.contactsAndResponsible')}
              </Button>
            </PopoverButton>
          </TextBlock>

          {(system?.gitlabGroupUrl || system?.wikiUrl) && (
            <TextBlock greyColor title={t('pages.systemDetailsPage.links')} font="14">
              {system?.gitlabGroupUrl && (
                <div className="mb-1">
                  <a href={system?.gitlabGroupUrl} target="_blank" rel="noreferrer">
                    {t('pages.systemDetailsPage.openCodebase')}
                    <ExternalLinkAltIcon className="ml-1" />
                  </a>
                </div>
              )}
              {system?.wikiUrl && (
                <div className="mb-1">
                  <a href={system?.wikiUrl} target="_blank" rel="noreferrer">
                    {t('pages.systemDetailsPage.openDatabase')}
                    <ExternalLinkAltIcon className="ml-1" />
                  </a>
                </div>
              )}
            </TextBlock>
          )}
        </>
      )}
    </div>
  );
}

export default SystemDetails;
