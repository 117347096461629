import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Tooltip, WizardStep } from '@patternfly/react-core';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUploadButton from 'hooks/useUploadButton';
import useModal from 'hooks/useModal';
import { notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import Dropdown from 'components/Dropdown';
import {
  issueStatusKeys,
  documentsTypeKeys,
  serviceVersionsStatusKeys,
  serviceVersionsPipelineKeys,
  serviceStatusKeys,
} from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';
import { featureFlags } from 'constants/features';
import { getProgressSystemMessage } from 'constants/tooltips';
import { incrementVersion } from 'utils';

import GeneralInformation from './GeneralInformation';
import ServicesSelection from './ServicesSelection';
import TasksSelection from './TasksSelection';
import Documents from './Documents';
import ErrorsWarningModal from './ErrorsWarningModal';

import * as S from './styles';

const fieldsToValidate = { 1: ['name'], 2: ['serviceVersions'] };

function AddReleasePage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, releaseId, action },
  } = useRouteMatch();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentRelease = useAsync();
  const allServicesCheckBox = useAsync();
  const allTasksCheckBox = useAsync();
  const newRelease = useAsync();
  const sendToApproval = useAsync();
  const allIssuesInRelease = useAsync();
  const releaseVersions = useAsync();
  const getLastVersionRelease = useAsync();
  const releaseWithErrorsWarningModal = useModal();

  const [currentActiveStep, setCurrentActiveStep] = useState(1);
  const [isCanRedirectAfterSave, setIsCanRedirectAfterSave] = useState(true);
  const [isApproveAfterSave, setIsApproveAfterSave] = useState(false);
  const [customVersions, setCustomVersions] = useState({});
  const [unfilledStepsData, setUnfilledStepsData] = useState({});

  const { state } = history.location;

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const isEditAction = action === paths.releaseActions.edit;
  const { UploadButtonComponent, changeFilesOnEditSubmit } = useUploadButton({
    objectType: documentsTypeKeys.RELEASE,
    setValue: formMethods.setValue,
    filesToShow: currentRelease.data?.documents,
    isEditAction,
  });

  const isNewAction = action === paths.releaseActions.new;
  const serviceVersionsValue = formMethods.watch('serviceVersions');

  const nameValue = formMethods.watch('name');
  const currentStep = formMethods.watch('step');
  const isFirstStep = currentStep === 1;

  const hasFailedServiceVersions = useMemo(
    () =>
      Object.keys(customVersions)
        .filter(key =>
          serviceVersionsValue?.find(version => version.uuid === customVersions[key]?.uuid)
        )
        .some(
          key =>
            customVersions[key] &&
            customVersions[key]?.pipelineStatus !== serviceVersionsPipelineKeys.SUCCESS &&
            customVersions[key]?.pipeline?.status !== serviceVersionsPipelineKeys.SUCCESS
        ),
    [customVersions, serviceVersionsValue, state?.isServiceVersion]
  );

  const hasTestFailedServiceVersions = useMemo(
    () =>
      Object.keys(customVersions)
        .filter(key =>
          serviceVersionsValue?.find(version => version.uuid === customVersions[key]?.uuid)
        )
        .some(
          key =>
            customVersions[key] &&
            customVersions[key]?.serviceVersionStatus === serviceVersionsStatusKeys.PROBLEM_FOUND
        ),
    [customVersions, serviceVersionsValue, state?.isServiceVersion]
  );

  const allCheckBoxData = useMemo(
    () => allServicesCheckBox.data?.data.filter(service => service.lastVersion),
    [allServicesCheckBox.data]
  );

  const requestParams = useMemo(
    () => ({
      system: systemId,
      limit: 1000,
      status: [
        serviceStatusKeys.NEW,
        serviceStatusKeys.ACTIVE,
        serviceStatusKeys.UPDATING_IN_PROGRESS,
        serviceStatusKeys.ERROR,
      ],
    }),
    [systemId]
  );

  const issuesRequestParams = useMemo(
    () => ({
      system: systemId,
      status: [issueStatusKeys.NEW, issueStatusKeys.IN_PROGRESS],
      ascending: false,
      orderBy: 'dueDate',
    }),
    [systemId]
  );

  const requestReleaseVersionsParams = useMemo(
    () => ({
      orderBy: 'createdAt',
      ascending: false,
      release: releaseId,
    }),
    []
  );

  const requestReleaseVersionsFunction = params =>
    releaseVersions.run(api.serviceVersion.getServicesVersions(params));

  const getLastVersionReleaseWithIncrementTag = async () => {
    const response = await getLastVersionRelease.run(
      api.releases.getAllReleases({
        system: systemId,
        offset: 0,
        limit: 1,
        orderBy: 'createdAt',
        ascending: false,
      })
    );

    const lastVersionName = response.data?.length && response.data[0]?.name;

    if (lastVersionName) {
      incrementVersion(lastVersionName, newName =>
        formMethods.setValue('name', newName, { shouldValidate: true })
      );
    } else {
      formMethods.setValue('name', '');
    }
  };

  useEffect(() => {
    if (!allServicesCheckBox.data?.length) {
      allServicesCheckBox.run(api.services.getAllServices(requestParams));
    }
    if (!allTasksCheckBox.data?.length) {
      allTasksCheckBox.run(api.issues.getIssues(issuesRequestParams));
    }
    if (state?.isServiceVersion) {
      formMethods.reset({
        ...formMethods.getValues(),
        serviceVersions: [
          {
            service: { uuid: state.isServiceVersion.service.uuid },
            uuid: state.isServiceVersion.uuid,
          },
        ],
      });
    }

    if (!releaseId) {
      getLastVersionReleaseWithIncrementTag();
    }
  }, []);

  useEffect(() => {
    if (releaseId) {
      requestReleaseVersionsFunction(requestReleaseVersionsParams);
      currentRelease.run(api.releases.getRelease(releaseId));
    }
  }, [releaseId]);

  useEffect(() => {
    if (currentRelease.data && allIssuesInRelease.data?.data && isEditAction) {
      const currentReleaseIssues = allIssuesInRelease.data.data.map(item => item.issue.uuid);
      const allResolvedIssues = allIssuesInRelease.data.data?.filter(
        item => item?.implemented === true
      );

      const releaseIssues = currentReleaseIssues.map(item => ({
        issue: {
          uuid: item,
        },
        implemented: allResolvedIssues?.map(el => el?.issue?.uuid)?.includes(item),
      }));

      formMethods.reset({
        ...formMethods.getValues(),
        name: currentRelease.data.name,
        description: currentRelease.data.description,
        hotfix: currentRelease.data.hotfix || false,
        releaseIssues: releaseIssues || [],
        uuid: currentRelease.data.uuid,
        serviceVersions: releaseVersions.data?.data.map(item => ({
          service: { uuid: item.service.uuid },
          uuid: item.uuid,
        })),
      });
    }
  }, [currentRelease.data, releaseVersions.data?.data, allIssuesInRelease.data?.data]);

  const clearForm = () => {
    formMethods.reset();
  };

  function releaseIssuesRequestFunction(params) {
    allIssuesInRelease.run(api.releases.getReleaseIssues(releaseId, params));
  }

  const releaseIssuesRequestParams = useMemo(
    () => ({
      limit: 5,
      orderBy: 'dueDate',
      ascending: false,
    }),
    []
  );

  useEffect(() => {
    if (!allIssuesInRelease.data?.data && releaseId) {
      releaseIssuesRequestFunction(releaseIssuesRequestParams);
    }
    if (state?.isServiceVersion) {
      setCustomVersions({
        [state?.isServiceVersion.service.uuid]: {
          addedViaPortal: state?.isServiceVersion.addedViaPortal,
          usedDefaultBranch: state?.isServiceVersion.usedDefaultBranch,
          description: state?.isServiceVersion,
          lastUpdatedAt: state?.isServiceVersion.lastUpdatedAt,
          pipelineStatus: state?.isServiceVersion.pipeline.status,
          serviceVersionStatus: state?.isServiceVersion.status,
          tag: state?.isServiceVersion.tag,
          uuid: state?.isServiceVersion.uuid,
        },
      });
    }
  }, []);

  const closeForm = () => {
    clearForm();

    return history.goBack();
  };

  useEffect(() => {
    if (isApproveAfterSave && newRelease.isSuccess) {
      sendToApproval.run(api.releases.sendToApprovalRelease(newRelease.data?.uuid));
    }

    if (isCanRedirectAfterSave && newRelease.isSuccess && !isApproveAfterSave) {
      if (!sendToApproval.isLoading) {
        closeForm();
      }
    }
  }, [newRelease.isSuccess, isApproveAfterSave]);

  useEffect(() => {
    if (!isCanRedirectAfterSave && sendToApproval.isSuccess && !isNewAction) {
      closeForm();
    }

    if (isCanRedirectAfterSave && sendToApproval.isSuccess && isNewAction) {
      setIsApproveAfterSave(true);
      closeForm();
    }
  }, [sendToApproval.isSuccess, isNewAction]);

  useEffect(
    () => () => {
      setUnfilledStepsData(fieldsToValidate);
      if (isEditAction) {
        clearForm();
      }
      history.replace({ state: {} });
    },
    []
  );

  const checkedformData = stepIndex => {
    const errors = Object.keys(formMethods.formState.errors);
    fieldsToValidate[stepIndex]?.forEach(field => {
      setUnfilledStepsData(prev => {
        prev[stepIndex] =
          !formMethods.getValues(field)?.length || errors.includes(field) ? [field] : [];
        return prev;
      });
    });
  };

  const valudedStep = async step => {
    await formMethods.trigger();
    checkedformData(step);
  };

  const steps = [
    {
      id: 1,
      name: t('common.generalInformation'),
      component: (
        <GeneralInformation
          systemId={systemId}
          valudedStep={valudedStep}
          lastVersionName={getLastVersionRelease.data?.data?.[0]?.name}
          isLoading={getLastVersionRelease.isLoading}
        />
      ),
    },
    {
      id: 2,
      name: t('common.services'),
      component: (
        <ServicesSelection
          serviceVersionsValue={serviceVersionsValue}
          releaseVersionsData={releaseVersions.data?.data}
          customVersions={customVersions}
          setCustomVersions={setCustomVersions}
          allCheckBoxData={allCheckBoxData}
          valudedStep={valudedStep}
        />
      ),
    },
    ...(featureFlags.isIssuesFeatureEnabled
      ? [
          {
            id: 3,
            name: t('common.issues'),
            component: <TasksSelection allCheckBoxTasksData={allTasksCheckBox.data?.data} />,
          },
        ]
      : []),
    {
      id: 4,
      name: t('common.documents'),
      component: (
        <Documents
          documents={currentRelease.data?.documents}
          UploadButtonComponent={UploadButtonComponent}
        />
      ),
    },
  ];

  const isLastStep = currentStep === steps?.length;
  const isValidForm =
    nameValue &&
    serviceVersionsValue?.length &&
    !Object.values(unfilledStepsData).find(item => item?.length);

  const onSubmit = async (formData, e) => {
    const isSaveAction =
      e === 'onSendForApproval' ||
      e?.target.innerText === t('common.saveChanges') ||
      e?.target.innerText === t('common.add') ||
      e?.target.innerText === t('common.send');

    releaseWithErrorsWarningModal.setIsModalVisible(false);

    if (newRelease.isLoading || !isValidForm) {
      return;
    }

    if (isSaveAction) {
      const addedDocuments = formData.addedDocuments?.map(file => ({
        ...file,
        description: formData.documentsDescription,
      }));
      const formatedDocuments = {
        removedDocuments: formData.removedDocuments.length ? formData.removedDocuments : undefined,
        addedDocuments: addedDocuments.length ? addedDocuments : undefined,
      };

      if (!releaseId && isValidForm) {
        const formatedData = {
          releaseIssues: [],
          ...formData,
          ...formatedDocuments,
          uuid: uuid(),
          system: { uuid: systemId },
          documentsDescription: undefined,
          step: undefined,
        };

        await newRelease.run(api.releases.createRelease(formatedData));
      }

      if (releaseId && isValidForm) {
        const formatedData = {
          ...formData,
          uuid: releaseId,
          ...formatedDocuments,
          releaseIssues: formData.releaseIssues || [],
          system: { uuid: systemId },
          documentsDescription: undefined,
          step: undefined,
        };

        changeFilesOnEditSubmit(formData.documentsDescription);

        await newRelease.run(api.releases.editRelease(releaseId, formatedData));
      }
    }
  };

  const onSendForApprovalClick = async (data, e) => {
    if (!isNewAction) {
      setIsCanRedirectAfterSave(false);
      await onSubmit(data, e);
      return sendToApproval.run(api.releases.sendToApprovalRelease(releaseId));
    }

    setIsApproveAfterSave(true);
    await onSubmit(data, e);
  };

  const onSaveClick = e => {
    if (hasTestFailedServiceVersions || hasFailedServiceVersions) {
      return releaseWithErrorsWarningModal.toggleModal();
    }

    return formMethods.handleSubmit(onSubmit)(e);
  };

  const onApprovalClick = e => {
    if (hasTestFailedServiceVersions || hasFailedServiceVersions) {
      releaseWithErrorsWarningModal.setModalData(true);
      releaseWithErrorsWarningModal.toggleModal();
      return;
    }

    return formMethods.handleSubmit(onSendForApprovalClick)(e);
  };

  const titleMessage = isEditAction
    ? t('pages.systemAddReleasesPage.edit')
    : t('pages.systemAddReleasesPage.create');

  const saveConfimButtonIsLoading =
    newRelease.isLoading || sendToApproval.isLoading || formMethods.formState.isSubmitting;
  const isSaveConfimButtonDisabled =
    saveConfimButtonIsLoading || notActiveSystemMessage || !isValidForm;

  const approvedClick = e => {
    formMethods.handleSubmit(
      releaseWithErrorsWarningModal.modalData ? onSendForApprovalClick : onSubmit
    )(e);
  };

  useEffect(() => {
    if (currentStep > currentActiveStep) {
      setCurrentActiveStep(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 2 && serviceVersionsValue?.length) {
      setUnfilledStepsData(prev => {
        prev[currentStep] = [];
        return prev;
      });
      formMethods.trigger('serviceVersions');
    }
  }, [serviceVersionsValue?.length]);

  const errors = Object.keys(formMethods.formState.errors) || [];
  useEffect(() => {
    if (currentActiveStep > currentStep || unfilledStepsData[currentStep]?.length) {
      if (unfilledStepsData[currentStep]?.length || errors.length) {
        formMethods.trigger();
      }

      checkedformData(currentStep);
    }
  }, [errors.length]);

  useEffect(() => {
    if (currentActiveStep > currentStep) {
      valudedStep(currentStep);
    }
  }, [currentStep]);

  const optionsData = [
    {
      id: 'onSendForApproval',
      name: t('pages.systemReleasesPage.sendForApproval'),
      isDisabled: notActiveSystemMessage,
      tooltip: notActiveSystemMessage,
    },
  ];

  return (
    <PageLayout fullHeight title={titleMessage} withoutSidePadding withSystemSelector>
      <FormProvider {...formMethods}>
        <div className="h-100">
          <S.WizardStyled $isLastStep={isLastStep}>
            {steps.map(step => (
              <WizardStep
                id={step.id}
                key={step.id}
                status={unfilledStepsData[step.id]?.length ? 'error' : 'default'}
                name={step.name}
                currentStep={currentStep}
                footer={{
                  nextButtonText: t('common.forward'),
                  backButtonText: t('common.back'),
                  isBackHidden: isFirstStep,
                }}
              >
                <form onSubmit={e => formMethods.handleSubmit(onSubmit)(e)} className="h-100">
                  {step.component}
                  <S.CanselButtonWrapper $isBackHidden={isFirstStep} $isLastStep={isLastStep}>
                    <Button variant="link" onClick={closeForm}>
                      {t('common.cancel')}
                    </Button>
                  </S.CanselButtonWrapper>
                  <S.SaveButtonWrapper>
                    <Tooltip
                      className={
                        (notActiveSystemMessage || !isValidForm) && !saveConfimButtonIsLoading
                          ? undefined
                          : 'd-none'
                      }
                      content={
                        !saveConfimButtonIsLoading &&
                        ((notActiveSystemMessage?.length && notActiveSystemMessage) ||
                          tooltipFormDisableMessage())
                      }
                      exitDelay={150}
                      animationDuration={150}
                    >
                      <S.ButtonWrapper>
                        <Dropdown
                          id={systemId}
                          optionData={optionsData}
                          onFilterChange={onApprovalClick}
                          isBlueButtonDropdown
                          positionRight
                          directionTop
                          withoutMarginLeft
                          isDisabled={isSaveConfimButtonDisabled}
                          splitButtonOptions={
                            <S.MenuToggleActionStyled
                              key="split-action-primary"
                              onClick={onSaveClick}
                              isDisabled={isSaveConfimButtonDisabled}
                              className="px-3 width-webkit-fill-available"
                            >
                              {t(releaseId ? 'common.saveChanges' : 'common.add')}
                            </S.MenuToggleActionStyled>
                          }
                        />
                      </S.ButtonWrapper>
                    </Tooltip>
                  </S.SaveButtonWrapper>
                </form>
              </WizardStep>
            ))}
          </S.WizardStyled>
        </div>
      </FormProvider>

      <ErrorsWarningModal
        {...releaseWithErrorsWarningModal}
        isLoading={saveConfimButtonIsLoading}
        approvedClick={approvedClick}
      />
    </PageLayout>
  );
}

export default AddReleasePage;
