import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import {
  getServiceCodeValidation,
  getServiceNameValidation,
  systemAndTierCodeRegex,
  conflictName,
  getMessageForRepositoryName,
  getMessageForRepositoryCode,
  conflictCode,
  getServiceFieldsValidateMessage,
} from 'constants/vaidations';
import { getServiceTypeOptions, serviceTypeKeys, getServiceFormTooltip } from 'constants';
import { translit } from 'utils';

import ServicePorts from './ServicePorts';

function ServiceForm({
  isModalForm,
  groupUuidValue,
  serviceUuid,
  getService,
  modalData,
  isModalVisible,
}) {
  const { t } = useTranslation();
  const serviceFormTooltip = getServiceFormTooltip();
  const serviceTypeOptions = getServiceTypeOptions();
  const {
    params: { systemId, serviceId },
  } = useRouteMatch();
  const buildTemplates = useAsync();
  const serviceFieldsValidateMessage = getServiceFieldsValidateMessage();
  const { watch, setValue, trigger } = useFormContext();
  const typeValue = watch(`type`);
  const nameValue = watch(`name`);
  const codeValue = watch(`code`);
  const buildTemplateValue = watch(`buildTemplate`);

  const searchFilter = async q => {
    try {
      const filterOptions = await buildTemplates.run(api.entry.getBuildTemplate({ limit: 10, q }));

      return generateSelectOptions(filterOptions.data, 'id', 'name');
    } catch (e) {
      return [];
    }
  };

  function requestOptionsFunction(params) {
    buildTemplates.run(
      api.entry.getBuildTemplate({ limit: 10, ascending: true, orderBy: 'name', ...params })
    );
  }

  useEffect(() => {
    if (!typeValue?.length && ((isModalForm && isModalVisible) || !serviceId)) {
      setValue(`type`, serviceTypeOptions?.[0].value);
    }
  }, []);

  useEffect(() => {
    if (isModalForm && isModalVisible) {
      trigger('name');
      trigger('code');
    }
  }, [isModalForm, isModalVisible]);

  useEffect(() => {
    if (systemId && getService?.isSuccess && getService.data?.buildTemplate?.id) {
      setValue('buildTemplate.id', getService.data.buildTemplate?.id || '');
    }
  }, [getService?.isSuccess, getService?.data?.buildTemplate?.id]);

  useEffect(() => {
    if (!serviceId && !isModalForm) {
      const translittedName = translit(nameValue)?.match(systemAndTierCodeRegex)?.join('-') || '';
      setValue('code', translittedName, { shouldValidate: !!translittedName });
    }
  }, [nameValue]);

  const serviceNameValidation = useMemo(
    () => getServiceNameValidation(systemId, serviceUuid || serviceId, groupUuidValue),
    [systemId, serviceId, serviceUuid, groupUuidValue]
  );
  const serviceCodeValidation = useMemo(
    () => getServiceCodeValidation(systemId, serviceUuid || serviceId, groupUuidValue),
    [systemId, serviceId, serviceUuid, groupUuidValue]
  );

  const allBuildTemplateOptions = useMemo(
    () => generateSelectOptions(buildTemplates.data?.data, 'id', 'name'),
    [buildTemplates.data?.data]
  );

  const directionBuildTemplateInput = isModalForm && 'up';

  const warningNameMessage =
    modalData &&
    (modalData.service?.name === nameValue || nameValue === conflictName) &&
    getMessageForRepositoryName();

  const warningCodeMessage =
    modalData &&
    (modalData.service?.code?.toLowerCase() === codeValue?.toLowerCase() ||
      codeValue?.toLowerCase() === conflictCode) &&
    getMessageForRepositoryCode();

  return (
    <>
      <>
        <InputComponent
          label={t('common.name')}
          name="name"
          isRequired
          maxLength={150}
          validationRules={serviceNameValidation}
          tooltipMessage={serviceFormTooltip.name}
          warningMessage={warningNameMessage}
          description={serviceFieldsValidateMessage.name}
        />
        <InputComponent
          label={t('common.repositoryCode')}
          name="code"
          isRequired
          validationRules={serviceCodeValidation}
          maxLength={255}
          tooltipMessage={serviceFormTooltip.code}
          description={serviceFieldsValidateMessage.code}
          disabled={serviceId}
          warningMessage={warningCodeMessage}
        />
        <InputComponent
          component={inputComponents.textarea}
          label={t('common.description')}
          name="description"
          maxLength={255}
          tooltipMessage={serviceFormTooltip.description}
          description={serviceFieldsValidateMessage.description}
        />
      </>

      <InputComponent
        label={t('pages.systemAddServicePage.buildTemplate')}
        name="buildTemplate.id"
        component={inputComponents.newSelect}
        newSelectVariant="typeahead"
        searchFilter={searchFilter}
        options={allBuildTemplateOptions}
        checkedValue={{ value: buildTemplateValue?.id, label: buildTemplateValue?.name }}
        withInfiniteScroll={{
          total: buildTemplates.data?.meta.total,
          offset: 10,
          loadMore: requestOptionsFunction,
        }}
        isRequired
        tooltipMessage={serviceFormTooltip.buildTemplate}
        height="300px"
        direction={directionBuildTemplateInput}
        placeholder={t('common.selectFromList')}
        isLoading={buildTemplates.isLoading}
        withoutClearButton
        isModalForm={isModalForm}
      />
      <InputComponent
        label={t('common.serviceType')}
        name="type"
        component={inputComponents.radioInput}
        defaultValue={serviceTypeOptions[0].value}
        options={serviceTypeOptions}
        rowDirection
        isRequired={isModalForm}
        tooltipMessage={serviceFormTooltip.type}
      />
      {typeValue !== serviceTypeKeys.LIBRARY && <ServicePorts isModalForm={isModalForm} />}
      {typeValue === serviceTypeKeys.EXTERNAL && (
        <>
          <InputComponent
            label={t('pages.systemAddServicePage.relativePath')}
            name="path"
            isRequired
            tooltipMessage={serviceFormTooltip.path}
          />
          <div className="mt-1">
            <InputComponent
              name="integration"
              switchLabel={t('pages.systemAddServicePage.isIntegrationService')}
              withoutLabel
              component={inputComponents.switchInput}
              tooltipMessage={serviceFormTooltip.integration}
              defaultValue={false}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ServiceForm;
