import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { fetchDictionaries } from 'store/dictionaries/actions';
import { dictionariesSelector } from 'store/dictionaries/selectors';
import useAsync from 'hooks/useAsync';
import Dropdown from 'components/Dropdown';
import InputComponent from 'components/UI/InputComponent';
import DataTable from 'components/Common/DataTable';

import * as S from './styles';

function EditDictionariesTable({
  filterParams,
  currentDictionary,
  isCantChangeForm,
  checkedUuids,
  setCheckedUuids,
  checkedValidation,
  setCheckedValidation,
  toggleModal,
}) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const toDeleteDictionary = useAsync();
  const [checkedEditClick, setCheckedEditClick] = useState([]);
  const optionData = [
    {
      id: 'edit',
      name: t('common.edit'),
    },
    {
      id: 'merge',
      name: t('pages.adminDictionaries.combine'),
    },
    {
      id: 'delete',
      name: t('common.delete'),
    },
  ];
  const headerData = [
    {
      key: 'name',
      label: t('pages.adminDictionaries.value'),
      width: '300px',
      withSort: true,
    },
    {
      key: 'maxOccurs',
      label: t('pages.adminDictionaries.used'),
      width: '150px',
      withSort: true,
    },
    {
      key: 'id',
      label: 'ID',
      width: '60px',
      withSort: true,
    },
    {
      key: 'version',
      label: t('common.updatedAt'),
      width: '180px',
      withSort: true,
    },
    {
      key: 'parentName',
      label: t('pages.adminDictionaries.author'),
      width: 'minmax(120px, auto)',
    },
    {
      key: 'dropdownMenu',
      label: '',
      width: '30px',
    },
  ];
  const { trigger } = useFormContext();
  const dictionaryValues = useWatch({ name: 'oldAttributes' });

  function requestFunction(requestParameters) {
    dispatch(fetchDictionaries(requestParameters));
  }

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      limit: 10,
      ascending: false,
    }),
    [filterParams]
  );

  const { data, total } = useSelector(dictionariesSelector);

  useEffect(() => {
    if (!data) {
      tableRef.current?.requestDataWithParams();
    }
  }, []);

  useEffect(() => {
    if (toDeleteDictionary.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [toDeleteDictionary, checkedValidation]);

  useEffect(() => {
    if (setCheckedValidation) {
      setCheckedValidation([]);
    }

    trigger();
  }, [dictionaryValues]);

  const onEditButtonClick = id => {
    setCheckedEditClick(prevState => [...prevState, id]);
  };

  const onMergeButtonClick = id => {
    setCheckedUuids(prevState => [...prevState, id]);
    toggleModal();
  };

  const onDeleteButtonClick = id => {
    toDeleteDictionary.run(api.dictionaries.deleteDictionary(id));
  };

  const oncheckedValidation = id => checkedValidation.find(itemId => itemId === id);

  const onFilterChange = (value, id) => {
    if (value === 'edit') {
      return onEditButtonClick(id);
    }
    if (value === 'merge') {
      return onMergeButtonClick(id);
    }

    return onDeleteButtonClick(id);
  };

  const formattedBodyData = currentDictionary?.attributes?.map((item, index) => ({
    id: item.id,
    rowColor:
      (checkedUuids.find(id => id === item.id) && '#F4FAFE') ||
      (oncheckedValidation(item.id) && '#E5E5E5'),
    data: [
      {
        key: 'name',
        content: checkedEditClick.find(id => id === item.id) ? (
          <S.NameWrapper color={oncheckedValidation(item.id)}>
            <InputComponent name={`oldAttributes[${index}].name`} isRequired />
          </S.NameWrapper>
        ) : (
          item.name
        ),
      },
      {
        key: 'maxOccurs',
        content: item?.maxOccurs,
      },
      {
        key: 'id',
        content: item?.id,
      },
      {
        key: 'version',
        content: currentDictionary.version,
      },
      {
        key: 'parentName',
        content: currentDictionary.parent.name,
      },
      {
        key: 'dropdownMenu',
        content: (
          <Dropdown
            id={item?.uuid}
            optionData={optionData}
            onFilterChange={value => onFilterChange(value, item.id)}
          />
        ),
      },
    ],
  }));

  return (
    <DataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      withStandartPagination={!isCantChangeForm}
      requestParams={requestParams}
      requestFunction={requestFunction}
      total={total}
      withCheckboxes={!isCantChangeForm}
      checkedUuids={checkedUuids}
      setCheckedUuids={setCheckedUuids}
    />
  );
}

export default EditDictionariesTable;
