import styled, { css } from 'styled-components';

const ClipboardItem = css`
  display: block;
  white-space: nowrap;
  padding: 8px 12px 7px 12px;
  font-size: 12px;
  color: #ffffff;
`;

export const Wrapper = styled.button`
  border: none;
  background: #fff;
  position: relative;
`;

export const ClipboardWrapper = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};

  flex-direction: row;
  position: absolute;
  bottom: 33px;
  left: calc(50% - ${({ withLickButton }) => (withLickButton ? '166.5px' : ' 130.5px')});
  z-index: 101;
  box-shadow: 0px 4px 8px 4px #00000024;
  background: #212427;
  border-radius: 4px;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 32px;
    border: 8px solid transparent;
    border-top: 8px solid #212427;
  }
`;

export const ClipboardUrl = styled.div`
  ${ClipboardItem}
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 167px;
`;

export const ClipboardButton = styled.div`
  ${ClipboardItem}
  cursor: pointer;
  border-left: 1px solid rgb(106, 110, 115);

  border-right: ${({ withLickButton }) =>
    withLickButton ? '1px solid rgb(106, 110, 115)' : 'none'};
`;

export const LinkButton = styled.a`
  ${ClipboardItem}
  cursor: pointer;
  text-decoration: none;

  /* position: absolute; */
  /* width: 52px;
  height: 16px; */

  /* Tiny text/600 */

  /* font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px; */
  /* identical to box height, or 133% */

  /* Color/000 */

  color: #ffffff;
`;
