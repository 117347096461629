import React, { useMemo } from 'react';
import { PopoverPosition, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions from 'hooks/useUserPermissions';
import StatusComponent from 'components/Common/StatusComponent';
import { serviceJobStatusKeys, serviceStageStatusKeys, serviceStatusKeys } from 'constants';
import { TextButton } from 'components/UI/Button';
import { declOfNum } from 'utils';
import {
  getServiceJobsStatus,
  getServiceStagesStatus,
} from 'components/Common/StatusComponent/statuses';
import { ReactComponent as RestartSVG } from 'assets/images/repositories/Restart.svg';

import * as S from './styles';

const availableStagesStatus = {
  [serviceStageStatusKeys.CREATED]: [serviceJobStatusKeys.CREATED],
  [serviceStageStatusKeys.PENDING]: [serviceJobStatusKeys.PENDING],
  [serviceStageStatusKeys.SCHEDULED]: [serviceJobStatusKeys.SCHEDULED],
  [serviceStageStatusKeys.RUNNING]: [serviceJobStatusKeys.RUNNING],
  [serviceStageStatusKeys.CANCELLED]: [serviceJobStatusKeys.CANCELLED],
  [serviceStageStatusKeys.SKIPPED]: [serviceJobStatusKeys.SKIPPED],
  [serviceStageStatusKeys.MANUAL]: [serviceJobStatusKeys.MANUAL],
  [serviceStageStatusKeys.FAILED]: [
    serviceJobStatusKeys.FAILED,
    serviceJobStatusKeys.WARNING,
    serviceJobStatusKeys.SKIPPED,
  ],
};

function PipelineStages({ pipeline, uuid, system, service }) {
  const { t, i18n } = useTranslation();
  const serviceJobsStatus = getServiceJobsStatus();
  const serviceStagesStatus = getServiceStagesStatus();
  const declensionJobs = [
    t('pages.systemServiceVersionPage.plural.tasks1'),
    t('pages.systemServiceVersionPage.plural.tasks2'),
    t('pages.systemServiceVersionPage.plural.tasks3'),
  ];
  const retry = useAsync();
  const { notActiveSystemMessage } = useUserPermissions(system);
  const retryJob = (versionId, jobId) => retry.run(api.pipelines.retryJob(versionId, jobId));

  const pipelineStages = useMemo(
    () =>
      pipeline?.stages?.map(item => {
        const allJobsNames = pipeline?.jobs.map(job => job.stage === item && job.name);
        const checkedJobsNames = allJobsNames.filter(el => Boolean(el));

        return checkedJobsNames.map(
          name =>
            pipeline?.jobs
              .filter(job => job.name === name)
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .slice(-1)[0]
        );
      }),
    [pipeline?.stages]
  );

  const notActiveServiceMessage =
    service?.uuid &&
    service.status !== serviceStatusKeys.ACTIVE &&
    t('pages.systemServiceVersionPage.unableToRestart1');
  const notActiveMessage = useMemo(
    () =>
      (notActiveSystemMessage && t('pages.systemServiceVersionPage.unableToRestart2')) ||
      notActiveServiceMessage,
    [notActiveSystemMessage, notActiveServiceMessage, i18n.language]
  );

  if (!pipeline) {
    return null;
  }

  const getStageStatus = jobsStatuses => {
    if (
      availableStagesStatus[serviceStageStatusKeys.CREATED].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.CREATED;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.PENDING].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.PENDING;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.SCHEDULED].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.SCHEDULED;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.RUNNING].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.RUNNING;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.CANCELLED].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.CANCELLED;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.SKIPPED].find(item =>
        jobsStatuses.includes(item)
      )
    ) {
      return serviceStageStatusKeys.SKIPPED;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.MANUAL].find(item => jobsStatuses.includes(item))
    ) {
      return serviceStageStatusKeys.MANUAL;
    }

    if (
      availableStagesStatus[serviceStageStatusKeys.FAILED].find(item => jobsStatuses.includes(item))
    ) {
      return serviceStageStatusKeys.FAILED;
    }

    if (
      jobsStatuses.filter(item => item === serviceJobStatusKeys.SUCCESS).length ===
      jobsStatuses.length
    ) {
      return serviceStageStatusKeys.SUCCESS;
    }
  };

  return pipelineStages?.map((stage, index) => {
    const jobsStatuses = stage?.map(item => item.status?.toUpperCase());

    return (
      <S.PopoverStyled
        key={`${uuid + index}`}
        aria-label="Popup"
        position={PopoverPosition.bottom}
        headerContent={declOfNum(stage.length, declensionJobs, false)}
        className="popoverStyled"
        showClose={false}
        bodyContent={
          <div className="d-sm-flex flex-column">
            {stage
              .sort((a, b) => a.sortOrder - b.sortOrder)
              ?.map(job => (
                <div
                  key={job?.gitlabId || job?.uuid}
                  className="d-flex justify-content-between mt-2"
                >
                  <div className="d-flex flex-row gap-1 mr-2">
                    <StatusComponent
                      iconStatus
                      statuses={serviceJobsStatus}
                      statusId={job.status?.toUpperCase()}
                    />

                    <TextButton variant="link" href={job.webUrl}>
                      {`${job.gitlabId}: ${job.name}`}
                    </TextButton>
                  </div>

                  <S.ButtonsWrapper>
                    <S.RestartWrapper>
                      <Tooltip
                        content={notActiveMessage || t('pages.systemServiceVersionPage.restart')}
                        exitDelay={150}
                        animationDuration={150}
                        position="right"
                      >
                        <RestartSVG
                          className={
                            notActiveMessage
                              ? 'cursor-default'
                              : 'cursor-pointer vertical-align-middle'
                          }
                          onClick={() =>
                            !notActiveMessage ? retryJob(pipeline?.uuid, job?.uuid) : undefined
                          }
                        />
                      </Tooltip>
                    </S.RestartWrapper>
                  </S.ButtonsWrapper>
                </div>
              ))}
          </div>
        }
      >
        <StatusComponent
          iconStatus={pipeline?.stages[index]}
          statuses={serviceStagesStatus}
          statusId={getStageStatus(jobsStatuses)}
          withPointerCursor
        />
      </S.PopoverStyled>
    );
  });
}

export default PipelineStages;
