import styled from 'styled-components';

export const DeployWrapper = styled.div`
  width: 760px;
`;

export const TierLinkWrapper = styled.div`
  color: #6a6e73;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  hyphens: auto;
`;

export const NameLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LinkIcon = styled.div.attrs({
  className: 'ml-1',
})`
  width: 3px;
`;
