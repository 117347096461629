import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import { serviceTypeKeys } from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import ServiceForm from './ServiceForm';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

function AddServicePage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, serviceId },
  } = useRouteMatch();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { run, isSuccess, isLoading } = useAsync();
  const getService = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const typeValue = formMethods.watch(`type`);
  const portsValue = formMethods.watch(`ports`);

  useEffect(() => {}, []);

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  useEffect(() => {
    if (isSuccess) {
      closeForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (serviceId) {
      getService.run(api.services.getService(serviceId));
    }
  }, []);

  useEffect(() => {
    if (getService?.data && serviceId) {
      formMethods.reset({
        name: getService.data.name,
        code: getService.data.code,
        description: getService.data.description,
        buildTemplate: {
          id: getService.data?.buildTemplate?.id || '',
          name: getService.data?.buildTemplate?.name || '',
        },
        type: getService.data.type || serviceTypeKeys.INTERNAL,
        ports: getService.data.ports,
        path: getService.data.path,
        integration: getService.data.integration,
      });
    }
  }, [getService?.data]);

  useEffect(
    () => () => {
      formMethods.reset();
    },
    []
  );

  const onSubmit = async ({ integration, buildTemplate, ...data }, e) => {
    if (isLoading || e.target.innerText !== t(serviceId ? 'common.saveChanges' : 'common.add')) {
      return;
    }

    const currentFunction = serviceId ? api.services.addEditService : api.services.addService;
    const formatedData = {
      ...data,
      buildTemplate: buildTemplate && { id: buildTemplate.id || {} },
      integration,
      uuid: serviceId || undefined,
      ...(typeValue === serviceTypeKeys.LIBRARY
        ? {
            ports: undefined,
            path: undefined,
            integration: undefined,
          }
        : {}),
      ...(typeValue === serviceTypeKeys.INTERNAL
        ? {
            path: undefined,
            integration: undefined,
          }
        : {}),
      serviceId,
      system: { uuid: systemId },
    };

    run(currentFunction(formatedData));
  };

  const title = serviceId
    ? t('pages.systemAddServicePage.edit')
    : t('pages.systemAddServicePage.create');
  const validedFields = [
    'name',
    'code',
    'buildTemplate.id',
    ...(typeValue === serviceTypeKeys.INTERNAL || typeValue === serviceTypeKeys.EXTERNAL
      ? [['number', 'transportProtocol']]
      : []),
    ...(typeValue === serviceTypeKeys.EXTERNAL ? ['path'] : []),
  ];

  const isDisabled =
    notActiveSystemMessage ||
    validedFields.filter(field => {
      if (typeof field === 'string') {
        return !formMethods.getValues(field);
      }

      return field?.filter(item => portsValue.find(port => !port[item]))?.length;
    })?.length ||
    !!Object.keys({ ...formMethods.formState.errors?.name, ...formMethods.formState.errors?.code })
      .length;

  return (
    <PageLayout title={title} withoutSidePadding withSystemSelector>
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ServiceForm getService={getService} />
          <ActionListGroup className="my-4">
            <ActionListItem>
              <Tooltip
                className={isDisabled ? undefined : 'd-none'}
                content={notActiveSystemMessage || tooltipFormDisableMessage()}
                exitDelay={150}
                animationDuration={150}
              >
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  isLoading={formMethods.formState.isSubmitting || isLoading}
                  isDisabled={formMethods.formState.isSubmitting || isLoading || isDisabled}
                >
                  {t(serviceId ? 'common.saveChanges' : 'common.add')}
                </Button>
              </Tooltip>
            </ActionListItem>
            <ActionListItem>
              <Button variant="link" id="cancel-button" className="ml-3" onClick={closeForm}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </PageLayout>
  );
}

export default AddServicePage;
