import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import C from 'constants/C';

export const convertNotificationDTO = res => ({
  id: res.uuid,
  time: res.creationDate,
  getTitle: language =>
    format(res.creationDate, C.common.dateFormat.HHMM, {
      locale: language.startsWith('ru') ? ru : en,
    }),
  text: res.message,
  icon: res.icon,
  systemId: res.system.uuid,
  linkId: res.link.uuid,
  linkName: res.link.name,
  event: res.event,
});

export const compareNotificationByDate = response => {
  const responseArray = response?.data?.data || [];
  const resultArray = [];

  responseArray.forEach(res => {
    const findDateIdx = resultArray.findIndex(r => r.date === res.creationDate);
    if (findDateIdx !== -1) {
      resultArray[findDateIdx].data.push(convertNotificationDTO(res));
    } else {
      resultArray.push({
        getTitle: language =>
          format(res.creationDate, C.common.dateFormat.DD_MMMM_YYYY, {
            locale: language.startsWith('ru') ? ru : en,
          }),
        date: res.creationDate,
        data: [convertNotificationDTO(res)],
      });
    }
  });

  return resultArray;
};
