import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { getUserEventTypeOptions, getUserEventTypesNames } from 'constants';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import useAsync from 'hooks/useAsync';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import AuditDescription from './AuditDescription';

function AuditTable({ userAudit }) {
  const { t } = useTranslation();
  const userEventTypeOptions = getUserEventTypeOptions();
  const userEventTypesNames = getUserEventTypesNames();
  const audit = useAsync({ status: 'pending' });
  const [isShowFilters, setIsShowFilters] = useState(false);
  const filterData = [
    {
      id: 'ipAddress',
      name: t('pages.adminAuditPage.ip'),
    },
    {
      id: 'eventType',
      name: t('pages.adminAuditPage.eventType'),
      type: 'multiSelect',
      options: userEventTypeOptions,
    },
    {
      id: 'createdByQuery',
      name: t('pages.adminAuditPage.createdBy'),
      isHidden: userAudit,
    },
    {
      id: 'date',
      name: t('pages.adminAuditPage.date'),
      type: 'date',
    },
  ];
  const auditTableFilters = useMultiSelectFilterSection(filterData, 'audit');
  const requestFunction = requestParams => {
    audit.run(api.audit.getAudit(requestParams));
  };
  const requestParams = useMemo(
    () => ({
      ascending: false,
      orderBy: 'createdAt',
      createdBy: userAudit,
      ...auditTableFilters?.filterParams,
      ...auditTableFilters.filterParams?.date,
    }),
    [auditTableFilters?.filterParams]
  );
  const headerData = [
    {
      key: 'ipAddress',
      label: t('pages.adminAuditPage.ip'),
      minWidth: '140px',
      withSort: true,
    },
    {
      key: 'eventType',
      label: t('pages.adminAuditPage.eventType'),
      minWidth: '200px',
      withSort: true,
    },
    {
      key: 'description',
      label: t('pages.adminAuditPage.description'),
      maxWidth: '560px',
    },
    {
      key: 'createdBy',
      label: t('pages.adminAuditPage.createdBy'),
      minWidth: '200px',
      withSort: true,
    },
    {
      key: 'createdAt',
      label: t('pages.adminAuditPage.date'),
      withSort: true,
      minWidth: '160px',
    },
  ];
  const formattedBodyData = audit.data?.data.map(item => ({
    id: item.uuid,
    data: [
      {
        key: 'ipAddress',
        content: item.ipAddress,
      },
      {
        key: 'eventType',
        content: userEventTypesNames[item.eventType],
      },
      {
        key: 'description',
        content: <AuditDescription event={item} />,
      },
      {
        key: 'createdBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={item?.createdBy}
            system={item?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'createdAt',
        type: types.relativeTime,
        content: item?.createdAt,
        withSeconds: true,
      },
    ],
  }));

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(!!audit.data?.meta?.total || auditTableFilters?.hasSelectedFilters);
    }
  }, [audit.data?.meta?.total, auditTableFilters?.hasSelectedFilters]);

  return (
    <>
      <div className="d-flex align-items-start mx-4">
        {isShowFilters && auditTableFilters.FilterComponent}
      </div>
      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        total={audit.data?.meta?.total}
        isLoading={audit.isLoading}
        tableName="audit"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={requestParams}
        hasSelectedFilters={auditTableFilters?.hasSelectedFilters}
        emptyTableMessage={t(
          userAudit
            ? 'pages.adminAuditPage.emptyUserTableMessage'
            : 'pages.adminAuditPage.emptyTableMessage'
        )}
      />
    </>
  );
}

export default AuditTable;
