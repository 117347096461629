import styled from 'styled-components';

import { InputCss } from 'components/UI/InputComponent/styles';

import SearchSVG from 'assets/images/system_page/searchRep.svg';
import { ReactComponent as CalendarSVG } from 'assets/images/notifications/calendar.svg';

export const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 16px;

  ${({ isFilterSearch }) =>
    isFilterSearch &&
    `
    flex-direction: column;
  `}
`;

export const FilterSearchWrapper = styled.div.attrs({
  className: 'd-flex flex-row',
})`
  margin-left: ${({ $withoutMarginLeft }) => ($withoutMarginLeft ? '0px' : '24px')};
`;

export const FilterBlock = styled.div`
  display: flex;
  margin-right: ${({ $marginRight }) => $marginRight ?? '16px'};
  align-items: center;
`;

export const DatesBlock = styled.div`
  position: relative;
  padding: 8px 12px;
  background: #ffffff;
  cursor: pointer;
  width: 230px;
  box-shadow: inset 0px -1px 0px #6a6e73;
  margin-left: 8px;
  z-index: 2;

  display: flex;
  box-shadow: inset 0px -1px 0px #8a8d90, inset 1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0,
    inset -1px 0px 0px #f0f0f0;
`;

export const DatesText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
`;

export const CalendarIcon = styled(CalendarSVG)`
  display: flex;
  width: 14px;
  height: 16px;
`;

export const SearchInput = styled.input`
  ${InputCss}
  background: url(${SearchSVG}) no-repeat 12px 10px #FFFFFF;
  padding-left: 36px;
  min-width: 290px;
  ${({ fullWidth }) => fullWidth && 'flex: 1'}
`;
