import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { changeCurrentSystem } from 'store/systems/reducers';
import { currentSystemSelector } from 'store/systems/selectors';
import ModalHeader from 'components/Common/ModalHeader';
import { BlueButton } from 'components/UI/Button';
import UserSelect from 'pages/AdminRouter/UsersRouter/UserCreatePage/UserSelect';
import { systemStatusKeys } from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';

function SystemRestoreModal({ isModalVisible, toggleModal }) {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { run, isSuccess, isLoading } = useAsync();
  const currentSystem = useSelector(currentSystemSelector);
  const [isUserHaveAlerts, setIsUserHaveAlerts] = useState(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        changeCurrentSystem({ ...currentSystem, status: systemStatusKeys.RESTORING_IN_PROGRESS })
      );
      toggleModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isModalVisible) {
      formMethods.reset();
    }
  }, [isModalVisible]);

  const onSubmit = ({ user }) =>
    run(
      api.systems.restoreSystem(systemId, {
        uuid: user,
      })
    );

  return (
    <S.ReactModalStyled isOpen={isModalVisible} onRequestClose={toggleModal} ariaHideApp={false}>
      <ModalHeader title={t('common.restoring')} toggleModal={toggleModal} />
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="d-sm-flex flex-column gap-3">
            <div className="font-14 font-weight-bold">
              {t('pages.addNewSystemPage.responsible')}
            </div>
            <UserSelect
              setIsUserHaveAlerts={setIsUserHaveAlerts}
              selectedSystem={currentSystem}
              isModalForm
            />
          </div>

          <ActionListGroup>
            <Tooltip
              content={isUserHaveAlerts && tooltipFormDisableMessage()}
              className={isUserHaveAlerts ? undefined : 'd-none'}
              exitDelay={150}
              animationDuration={150}
            >
              <ActionListItem>
                <BlueButton
                  $marginRight
                  onClick={formMethods.handleSubmit(onSubmit)}
                  isDisabled={isLoading || formMethods.formState.isSubmitting || isUserHaveAlerts}
                  isLoading={isLoading}
                >
                  {t('common.restoreShort')}
                </BlueButton>
              </ActionListItem>
            </Tooltip>

            <ActionListItem>
              <Button variant="link" id="cancel-button" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </S.ReactModalStyled>
  );
}

export default SystemRestoreModal;
