import React from 'react';
import ReactModal from 'react-modal';

import { CloseButton } from 'components/UI/CloseButton';

import * as S from './styles';

function ModalInfo({ children, isModalVisible, toggleModal, title }) {
  return (
    <ReactModal isOpen={isModalVisible} onRequestClose={toggleModal} ariaHideApp={false}>
      <S.ModalWrapper>
        <div className="d-flex justify-content-between w-100">
          <span className="font-24 font-weight-medium">{title}</span>
          <div className="ml-4">
            <CloseButton className="vertical-align-sub" onClick={toggleModal} />
          </div>
        </div>
        {children}
      </S.ModalWrapper>
    </ReactModal>
  );
}

export default ModalInfo;
