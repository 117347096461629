import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchServerTiers = createAsyncThunk(
  'serverTiers/fetchServerTiers',
  async ({ isConcatData, q, ...params }) => {
    const response = await api.serverTiers.getAllServerTiers({
      ...params,
      nameQuery: q,
    });
    return {
      ...response.data,
      isConcatData,
    };
  }
);

export const fetchServerTier = createAsyncThunk('systems/fetchServerTier', async params => {
  const { data } = await api.serverTiers.getServerTier(params);

  return data;
});
