import React, { useEffect } from 'react';

import api from 'api';
import useAsync from 'hooks/useAsync';
import WarningModal from 'components/Common/WarningModal';
import { useTranslation } from 'react-i18next';

function DeleteTierModal({ toggleModal, isModalVisible, modalData, requestFunction }) {
  const { t } = useTranslation();
  const { isSuccess, run, isLoading } = useAsync();

  const deleteTier = () => {
    run(api.tiers.deleteTier({ tierId: modalData?.uuid }));
  };

  useEffect(() => {
    if (isSuccess) {
      if (requestFunction) {
        requestFunction();
      }
      toggleModal();
    }
  }, [isSuccess]);

  return (
    <WarningModal
      title={t('pages.systemTierPage.deletingTier')}
      text={t('pages.systemTierPage.deleteTier')}
      label={t('pages.systemTierPage.tierName')}
      isLoading={isLoading}
      onClick={deleteTier}
      name={modalData?.code}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    />
  );
}

export default DeleteTierModal;
