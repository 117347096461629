import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { currentSystemLoadingSelector, currentSystemSelector } from 'store/systems/selectors';
import { RowView, SpaceBeetweenRowView } from 'components/UI/View';
import ErrorPageMessage from 'pages/ErrorPage/ErrorPageMessage';

import ContextSelectorSystem from '../../Common/ContextSelectorSystem';
import LoadingIndicator from '../LoadingIndicator';

import * as S from './styles';

function PageLayout({
  title,
  leftSideContent,
  fullHeight,
  baselineTop,
  alignSelfCenter,
  withoutSideMarggin,
  withoutSidePadding,
  withSystemSelector,
  children,
  withoutMarginBottom,
  alignSelfCenterTitle,
  isWaitLoading,
  withoutFlex,

  systemLayout,
  withSystemHeader,
  blockSet,

  mainPage,
  documentPage,

  fullHeightPage,

  isError,
  isLoading,
}) {
  const {
    params: { systemId },
  } = useRouteMatch();
  const currentSystem = useSelector(currentSystemSelector);
  const systemLoading = useSelector(currentSystemLoadingSelector);

  const titleSection = title ? (
    <SpaceBeetweenRowView baselineTop={baselineTop}>
      {withoutMarginBottom ? (
        <S.TitleNoMargin withoutSidePadding={withoutSidePadding}>{title}</S.TitleNoMargin>
      ) : (
        <S.Title
          withoutSidePadding={withoutSidePadding}
          alignSelfCenterTitle={alignSelfCenterTitle}
        >
          {title}
        </S.Title>
      )}
      <RowView alignSelfCenter={alignSelfCenter}>{leftSideContent}</RowView>
    </SpaceBeetweenRowView>
  ) : null;

  if (isError) {
    return (
      <div className="d-sm-flex flex-column flex-fill bg-white">
        <div className="m-auto p-4">
          <ErrorPageMessage />
        </div>
      </div>
    );
  }

  if (systemLayout) {
    return (
      <S.SystemWrapper>
        {withSystemSelector && !blockSet && (
          <div className="pl-4 py-2 border-bottom bg-white">
            <ContextSelectorSystem />
          </div>
        )}
        <S.SystemContent
          isNoTitle={!titleSection}
          withSystemHeader={withSystemHeader}
          withoutSideMarggin={withoutSideMarggin}
        >
          <div className="mx-4">{titleSection}</div>
          {children}
        </S.SystemContent>
      </S.SystemWrapper>
    );
  }

  if (mainPage) {
    return <S.MainPageWrapper>{children}</S.MainPageWrapper>;
  }

  if (documentPage) {
    return (
      <S.DocumentPageWrapper>
        <S.MainPageLine>
          <h1 className="font-24 text-black my-4 mr-3">{title}</h1>
          <S.LeftSideContent>{leftSideContent}</S.LeftSideContent>
        </S.MainPageLine>
        {children}
      </S.DocumentPageWrapper>
    );
  }

  if (fullHeightPage) {
    return (
      <div className="d-sm-flex flex-column flex-fill bg-white">
        {title && (
          <div className="d-sm-flex flex-row justify-content-between">
            <h1 className="font-24 text-black mt-4 mx-4 mb-3">{title}</h1>
            <div className="d-flex flex-row mx-4">{leftSideContent}</div>
          </div>
        )}
        {children}
      </div>
    );
  }

  const withLoading =
    (isWaitLoading && (systemId ? systemLoading && !currentSystem?.uuid : true)) ||
    isWaitLoading?.isLoading;

  return (
    <S.Wrapper
      className="position-relative"
      fullHeight={fullHeight}
      blockSet={blockSet}
      withoutSidePadding={withoutSidePadding}
      withoutFlex={withoutFlex}
    >
      {(withLoading || isLoading) && (
        <S.TableSpinner>
          <LoadingIndicator />
        </S.TableSpinner>
      )}

      {withSystemSelector && !blockSet && (
        <div className="pl-4 py-2 border-bottom">
          <ContextSelectorSystem />
        </div>
      )}

      {!withLoading && !isLoading && (
        <>
          {titleSection}
          {children}
        </>
      )}
    </S.Wrapper>
  );
}

export default PageLayout;
