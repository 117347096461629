import styled from 'styled-components';
import { ModalBlockTitle } from 'components/UI/View';

export const BlockTitle = styled(ModalBlockTitle)`
  margin: 3px 0 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const DataBlockWrapper = styled.div`
  margin-bottom: 20px;
`;
