import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { Button, Tooltip } from '@patternfly/react-core';
import { checkedTierTotal, formatFilterOptions } from 'utils';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import UsersTable from 'pages/AdminRouter/UsersRouter/UsersPage/UsersTable';
import paths from 'constants/paths';
import { getSystemRoleLabels } from 'constants/roles';
import { usersActions } from 'constants/paths/adminUsersPaths';
import { useTranslation } from 'react-i18next';
import { getProgressSystemMessage } from '../../../constants/tooltips';

function UsersPage() {
  const { t, i18n } = useTranslation();
  const systemRoleLabels = getSystemRoleLabels();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentSystem = useSelector(currentSystemSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const options = [
    {
      id: 'fullName',
      name: t('common.fio'),
    },
    {
      id: 'login',
      name: t('common.login'),
    },
    {
      id: 'email',
      name: t('common.email'),
    },
    {
      id: 'phone',
      name: t('common.phone'),
    },
    {
      id: 'workplace',
      name: t('common.work'),
    },
    {
      id: 'role',
      name: t('common.role'),
      type: 'multiSelect',
      options: formatFilterOptions(systemRoleLabels),
      isHidden: !systemId,
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const usersTableFilter = useMultiSelectFilterSection(options, 'users');

  const notActiveMessage = useMemo(
    () => notActiveSystemMessage || checkedTierTotal(currentSystem?.totalSummary?.tierTotal),
    [notActiveSystemMessage, currentSystem?.totalSummary?.tierTotal, i18n.language]
  );

  const addUsersButton = (
    <>
      {checkedPermissions(scopes.user.userAddToSystem) && (
        <Tooltip
          className={notActiveMessage ? undefined : 'd-none'}
          content={notActiveMessage}
          exitDelay={150}
          animationDuration={150}
        >
          <div>
            <Button
              variant="primary"
              isAriaDisabled={notActiveMessage}
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemPaths.newUser, {
                    action: usersActions.new,
                  })}
                />
              )}
            >
              {t('common.add')}
            </Button>
          </div>
        </Tooltip>
      )}
    </>
  );

  return (
    <PageLayout
      title={t('common.users')}
      systemLayout
      withSystemSelector
      leftSideContent={isShowFilters && addUsersButton}
    >
      <UsersTable
        systemId={systemId}
        currentSystem={currentSystem}
        addButton={addUsersButton}
        tableFilters={usersTableFilter}
        setIsShowFilters={setIsShowFilters}
        isShowFilters={isShowFilters}
        hasFilters={systemId && Boolean(currentSystem?.totalSummary?.userTotal.total)}
      />
    </PageLayout>
  );
}

export default UsersPage;
