import React from 'react';
import { Controller } from 'react-hook-form';

import { PhoneInput } from './styles';

const standartMask = '+7 (999) 999-99-99';
const placeholder = '+7 (___) ___-__-__';

const PhoneInputController = ({ name, setIsFocus, control, rules, label, ...inputProps }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue=""
    render={({ field }) => (
      <PhoneInput
        {...field}
        {...inputProps}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
        mask={standartMask}
        maskChar="_"
        placeholder={placeholder}
        data-input-for={label}
      />
    )}
  />
);
export default PhoneInputController;
