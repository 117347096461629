import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { currentServiceVersionSelector } from 'store/serviceVersions/selectors';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import TruncateWithTooltipText from 'components/Common/TruncateWithTooltipText';
import { useTranslation } from 'react-i18next';

function ServiceVersionArtifacts({ serviceVersionId }) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const [isShowFilters, setIsShowFilters] = useState(false);

  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'version',
      name: t('common.version'),
    },
    {
      id: 'type',
      name: t('common.type'),
    },
    {
      id: 'registry',
      name: t('common.repo'),
    },
  ];
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'version',
      label: t('common.version'),
      withSort: true,
    },
    {
      key: 'type',
      label: t('common.type'),
      withSort: true,
    },
    {
      key: 'registry',
      label: t('common.repo'),
      withSort: true,
    },
    {
      key: 'createdAt',
      label: t('common.createDate'),
      withSort: true,
    },
  ];
  const artifacts = useAsync();
  const serviceVersion = useSelector(currentServiceVersionSelector);
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'serviceVersionArtifacts'
  );
  const requestFunction = params =>
    serviceVersionId &&
    artifacts.run(api.serviceVersion.getServiceVersionArtifacts(serviceVersionId, params));
  const defaultParams = useMemo(
    () => ({
      orderBy: 'createdAt',
      ascending: false,
      serviceVersion: serviceVersionId,
      limit: 10,
      ...filterParams,
    }),
    [filterParams]
  );

  useEffect(() => {
    if (serviceVersion?.totalSummary?.artifactTotal?.total >= 0) {
      tableRef.current?.requestDataWithParams({ isSilentMode: true });
    }
  }, [serviceVersion?.totalSummary?.artifactTotal?.total]);

  const formattedBodyData = artifacts.data?.data?.map(item => ({
    id: item.uuid,
    data: [
      {
        key: 'name',
        content: item.name,
      },
      {
        key: 'version',
        content: item.version,
      },
      {
        key: 'type',
        content: item.type,
      },
      {
        key: 'registry',
        content: <TruncateWithTooltipText content={item.registry} />,
      },
      {
        key: 'createdAt',
        type: types.relativeTime,
        content: item.createdAt,
      },
    ],
  }));

  useEffect(() => {
    setIsShowFilters(artifacts.data?.meta?.total || artifacts.isLoading || hasSelectedFilters);
  }, [artifacts.data, artifacts.isLoading]);

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{FilterComponent}</div>}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        total={artifacts.data?.meta?.total}
        isLoading={artifacts.isLoading}
        tableName="serviceVersionArtifacts"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={defaultParams}
        hasSelectedFilters={hasSelectedFilters}
        emptyTableMessage={
          <div className="text-center font-20 font-weight-medium">
            <p>{t('common.tableNoDataMessage')}</p>
            <p>{t('common.tableNoDataMessage2')}</p>
          </div>
        }
        isError={artifacts.isError}
      />
    </>
  );
}

export default ServiceVersionArtifacts;
