import React from 'react';

import SelectComponent from './SelectComponent';
import AsyncSelectComponent from './AsyncSelectComponent';

export const auditInputComponents = {
  select: 'select',
  asyncSelect: 'asyncSelect',
};

const AuditInputComponent = ({
  label,
  onChange,
  component,
  options,
  // select
  isDate,
  defaultValue,
  // async select
  disabled,
  isMulti,
  values,
  height,
}) => {
  const renderedComponent = () => {
    switch (component) {
      case auditInputComponents.asyncSelect: {
        return (
          <AsyncSelectComponent
            isMulti={isMulti}
            onChange={onChange}
            disabled={disabled}
            options={options}
            values={values}
          />
        );
      }
      case auditInputComponents.select: {
        return (
          <SelectComponent
            options={options}
            onChange={onChange}
            isDate={isDate}
            defaultValue={defaultValue}
            heigh={height}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <span className="font-weight-medium font-14">{label}</span>
      {renderedComponent()}
    </div>
  );
};

export default AuditInputComponent;
