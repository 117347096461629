export const addSystemStorageKey = 'addNewExternalSystemForm';

export const editSystemStorageKey = 'editExternalSystemForm';

export const systemActiveStepStorageKey = 'externalSystemFormActiveStep';

export const systemEditActiveStepStorageKey = 'externalEditSystemFormActiveStep';

export const editSystemUnfilledStages = 'editExternalSystemUnfilledStages';

export const addSystemUnfilledStages = 'addExternalSystemUnfilledStages';

export const stepsKeys = {
  generalInfo: 'generalInfo',
  contactPersons: 'contactPersons',
  tiers: 'tiers',
};
