import React from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';

import SectionHeader from 'components/Common/SectionHeader';
import { CloseButton } from 'components/UI/CloseButton';
import LimitsMonitoring from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/Tier/LimitsMonitoring';

import * as S from './styles';

function ModalStatistic({ isModalVisible, toggleModal, serverTierUuid }) {
  const { t } = useTranslation();
  const { checkedPermissions } = useUserPermissions();

  return (
    <ReactModal isOpen={isModalVisible} ariaHideApp={false} onRequestClose={toggleModal}>
      <S.ModalBody>
        <SectionHeader title={t('pages.adminServerTiers.monitoring')}>
          <CloseButton onClick={toggleModal} />
        </SectionHeader>
        <LimitsMonitoring
          requestFunction={
            checkedPermissions(scopes.platform.clusterViewMonitoring, true)
              ? api.monitoring.getServerTierMonitoring
              : undefined
          }
          uuid={serverTierUuid}
        />
      </S.ModalBody>
    </ReactModal>
  );
}

export default ModalStatistic;
