import { createSelector } from 'reselect';

const usersState = state => state.users;

export const usersSelector = createSelector([usersState], users => users);

export const platformUsersSelector = createSelector(
  [usersState],
  ({ platformUsers }) => platformUsers
);
