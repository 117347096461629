import styled from 'styled-components';

import { ReactComponent as CloseButtonSVG } from 'assets/images/system_page/closeButton.svg';

export const CloseButton = styled(CloseButtonSVG)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 5px;
`;
