import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  Divider,
  DropdownList,
  InputGroup,
  InputGroupItem,
  MenuSearch,
  MenuSearchInput,
  SearchInput,
  DropdownItem,
} from '@patternfly/react-core';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import { useTranslation } from 'react-i18next';

import { currentSystemLoadingSelector } from 'store/systems/selectors';
import RelativeDate from 'components/Common/RelativeDate';
import useInfinitePagination from 'components/Common/DataTable/hooks/useInfinitePagination';
import LoadingIndicator from 'components/UI/LoadingIndicator';

import * as S from './styles';

const observerPlaceOffset = 4;

function ContextSelectorOptions({
  total,
  options = [],
  getOption,
  requestParams,
  requestFunction,
  callback,
  onEditClick,
  checkedValue,
  selectorItem,
  searchValue,
  setSearchValue,
  isDateDescription,
  isLoading,
  closeCallback,
  setCustomVersions,
  serviceUuid,
}) {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const loadingSystem = useSelector(currentSystemLoadingSelector);
  const {
    params: { systemId },
  } = useRouteMatch();

  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [isOptions, setOptions] = useState([]);

  const rowsCount = isOptions?.length;

  const { ref: paginationRef } = useInfinitePagination({
    rowsCount,
    withInfinitePagination: rowsCount > 0,
    loadMore: () => {
      requestFunction({ ...requestParams, offset: rowsCount, isConcatData: true });
    },
    total,
  });

  const onSetSelected = option => {
    if (systemId && loadingSystem) {
      return;
    }
    if (option && callback) {
      callback(option);
    }

    if (option && !checkedValue) {
      setSelected(option);
    }

    if (!option && checkedValue) {
      const selectItem = selectorItem(option, checkedValue);
      setSelected(selectItem);
    }

    if (onEditClick) {
      onEditClick();
    }
  };

  useEffect(() => {
    const selectItem = selectorItem(checkedValue);
    setSelected(selectItem);
  }, []);

  useEffect(() => {
    const selectItem = selectorItem(checkedValue);
    setSelected(selectItem);
  }, [checkedValue]);

  useEffect(() => {
    const currentOption = options.find(option => option.label === checkedValue?.value);

    if (
      options?.length &&
      checkedValue &&
      currentOption &&
      (currentOption?.pipelineStatus !== checkedValue?.pipelineStatus ||
        currentOption?.status !== checkedValue?.status)
    ) {
      // const selectItem = selectorItem(checkedValue);

      const selectItem = selectorItem(currentOption);

      setCustomVersions(prev => ({
        ...prev,
        [serviceUuid]: {
          ...(prev[serviceUuid] ?? {}),
          description: {
            ...(prev[serviceUuid]?.description ?? {}),

            pipelineStatus: currentOption.pipelineStatus,
            serviceVersionStatus: currentOption.status,
          },
          pipelineStatus: currentOption.pipelineStatus,
          serviceVersionStatus: currentOption.status,
        },
      }));

      setSelected(selectItem);
    }
  }, [isOptions?.length]);

  useEffect(() => {
    setOptions(options);
  }, [options]);

  useEffect(() => {
    if (isOpen) {
      requestFunction(requestParams);
    } else {
      closeCallback?.();
    }
  }, [requestParams, isOpen]);

  const onToggle = () => setOpen(!isOpen);

  const onSelect = option => {
    onSetSelected(option);
    setOpen(false);
  };

  const onSearchInputChange = item => {
    setSearchValue(item);
  };

  const optionsData = useMemo(() => {
    const currentItem = selected.props?.children.props?.children.props?.children;
    const data = (
      <>
        {isOptions.map(option => (
          <DropdownItem
            key={option?.value}
            isDisabled={option.disabled}
            onClick={() => onSelect(option)}
          >
            <div className="d-flex flex-column align-items-start w-100">
              <div className="d-flex flex-row justify-content-between w-100">
                {getOption && getOption(option)}
                {currentItem === option.label && <CheckIcon className="font-12 text-blu" />}
              </div>
              {option?.lastUpdatedAt && (
                <S.DescriptionWrapper>
                  {isDateDescription ? (
                    <RelativeDate date={option.lastUpdatedAt} position="bottom" />
                  ) : (
                    option.lastUpdatedAt
                  )}
                </S.DescriptionWrapper>
              )}
            </div>
          </DropdownItem>
        ))}
        {rowsCount > observerPlaceOffset && <div ref={paginationRef} />}
      </>
    );

    return (
      <>
        {!rowsCount ? (
          <div className="w-100 py-2 px-3">
            <DropdownItem className="font-16" isDisabled>
              {t('common.noResults')}
            </DropdownItem>
          </div>
        ) : (
          data
        )}
      </>
    );
  }, [isOptions, searchValue]);

  return (
    <div className="d-flex flex-row">
      <S.StyledDropdown
        isOpen={isOpen}
        onOpenChange={open => setOpen(open)}
        onOpenChangeKeys={['Escape']}
        toggle={toggleRef => (
          <S.StyledMenuToggle ref={toggleRef} onClick={onToggle} isExpanded={isOpen}>
            {selected}
          </S.StyledMenuToggle>
        )}
        ref={menuRef}
        id="context-selector"
        isScrollable
        zIndex="11"
      >
        <MenuSearch>
          <MenuSearchInput>
            <InputGroup>
              <InputGroupItem isFill>
                <SearchInput
                  value={searchValue}
                  onChange={(_event, value) => onSearchInputChange(value)}
                  aria-labelledby="pf-v5-context-selector-search-button-id-1"
                  placeholder={t('components.filter.search')}
                />
              </InputGroupItem>
            </InputGroup>
          </MenuSearchInput>
        </MenuSearch>
        <Divider />
        <DropdownList>{optionsData}</DropdownList>
      </S.StyledDropdown>
      <div className="position-relative">
        {isLoading && isOpen && (
          <S.SpinnerWrapper>
            <LoadingIndicator small />
          </S.SpinnerWrapper>
        )}
      </div>
    </div>
  );
}

export default ContextSelectorOptions;
