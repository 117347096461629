import { generatePath, matchPath, useLocation } from 'react-router';
import paths from 'constants/paths';

function useSystemPathGenerate(routePath) {
  const location = useLocation();
  const match = matchPath(location.pathname, { path: paths.routePaths.system });

  return generatePath(`${paths.routePaths.system}${routePath}`, match?.params);
}

export default useSystemPathGenerate;
