import { useAlert } from 'react-alert';
import http, { httpAudit, httpKeycloak } from 'api/config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiErrorMessage } from 'utils';
import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';

const minStatusToShow = 400;
const maxAlerts = 6;
const maxUnknownErrorAlerts = 1;

const useErrorAlert = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();
  let alertsCount = 0;

  const interceptorReject = async error => {
    const status = error?.response?.status;
    const isNetworkError = !status;
    const isErrorIgnored =
      !error ||
      error.response?.config?.headers?.errorIsIgnored ||
      error?.response?.data?.violations?.every(e => e.property);

    if (status === 403) {
      history.replace(paths.routePaths.accessError);
    }

    if (
      !isErrorIgnored &&
      ((isNetworkError && alertsCount < maxUnknownErrorAlerts) ||
        (Number(status) >= minStatusToShow && alertsCount < maxAlerts))
    ) {
      const message = isNetworkError
        ? t('api.serverUnavailable')
        : getApiErrorMessage(error, t, t('api.unknownError'), true);

      alert.error(message, {
        status,
        onOpen: () => {
          alertsCount += 1;
        },
        onClose: () => {
          alertsCount -= 1;
        },
      });
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    http.interceptors.response.use(response => response, interceptorReject);
    httpAudit.interceptors.response.use(response => response, interceptorReject);
    httpKeycloak.interceptors.response.use(response => response, interceptorReject);
  }, []);
};

export default useErrorAlert;
