import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useAvailableResources from 'hooks/useAvailableResources';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { DeleteButton } from 'components/UI/DeleteButton';
import { getTierIsDevInfoMessage } from 'constants/tooltips';
import paths from 'constants/paths';
import {
  getDefaultNumberValidateMessage,
  getSystemTierNameValidation,
  quoteValidation,
  getTierNameErrorMessage,
} from 'constants/vaidations';
import { InputBlockWrapper } from 'components/UI/View';
import { Line } from 'pages/SystemRouter/SystemTiersRouter/QuotePage/styles';
import ContextSelectorDropdown from 'components/ContextSelectorDropdown';
// import ClusterParams from 'pages/AdminRouter/ServerTiersRouter/ServerTiersPage/ClusterTable/ClusterParams';
import { DynamicFormWrapper } from 'pages/AddNewSystemRouter/AddSystemPage/styles';
import {
  cpuUnitKeys,
  serverTierStatusKeys,
  unitMemoryDropdownOptions,
  unitMemoryNames,
  getLimitsLabels,
} from 'constants';
import { declOfNum, getConvertibleCpuQuantities } from 'utils';

import TierHosts from './TierHosts';
import { stepsKeys } from '../../keys';

// import * as S from './styles';

const examples = ['prod', 'test', 'stage', 'dev'];
const dynamicParentField = stepsKeys.tiers;
// const sortKeys = [limitsKeys.CPU, limitsKeys.MEMORY, limitsKeys.STORAGE];

// const LIMITDATA = [
//   {
//     code: 'CPU',
//     total: '528',
//     used: '33.06376879740421',
//     limits: '371.6159999999985',
//     usageChart: [],
//     requestsChart: [],
//   },
//   {
//     code: 'MEMORY',
//     total: '2458988638208',
//     used: '557032591360',
//     limits: '910916504064',
//     usageChart: [],
//     requestsChart: [],
//   },
//   {
//     code: 'STORAGE',
//     total: '167046264422400',
//     used: '12734902145024',
//     limits: '4520575493144',
//     usageChart: [],
//     requestsChart: [],
//   },
// ];

function TierForm({
  fieldUuid,
  deleteField,
  index,
  activeStepStorage,
  setIsValidForm,
  fieldUuids,
}) {
  const { t, i18n } = useTranslation();
  const convertibleCpuQuantities = getConvertibleCpuQuantities();
  const tierIsDevInfoMessage = getTierIsDevInfoMessage();
  const fieldsDescriptionMap = {
    name: getTierNameErrorMessage(),
    cpu: t('validation.maxLength', [6]),
    ram: t('validation.maxLength', [6]),
    diskSpace: t('validation.maxLength', [6]),
  };
  const limitsLabels = getLimitsLabels();
  const {
    params: { systemId, tierId, action },
  } = useRouteMatch();

  const { watch, setValue, trigger, clearErrors, setError, formState } = useFormContext();
  const [tierCodeError, setTierCodeError] = useState(false);
  const { checkedPermissions } = useUserPermissions();
  const getServerTiersOptions = useAsync();
  const getTiersOptions = useAsync();

  const inputsName = fieldUuid ? `${dynamicParentField}[${index}].` : '';
  const tierCode = watch(`${inputsName}code`);
  const serverTierUuid = watch(`${inputsName}serverTier.uuid`);
  const serverTierName = watch(`${inputsName}serverTier.name`);
  const cpuValue = watch(`${inputsName}CPU`);
  const storageValue = watch(`${inputsName}STORAGE`);
  const cpuUnit = watch(`${inputsName}cpuUnit`);
  const memoryUnit = watch(`${inputsName}memoryUnit`);
  const storageUnit = watch(`${inputsName}storageUnit`);
  const tierHaveAlerts = watch(`${inputsName}tierHaveAlerts`);
  const tiers = watch(`${dynamicParentField}`);
  const isRestoreAction = action === paths.systemTiersAction.restore;

  const systemTierNameValidation = getSystemTierNameValidation(
    systemId,
    null,
    tierId,
    fieldUuids?.map((uuid, indexUuid) => ({ ...tiers[indexUuid], fieldUuid: uuid })),
    fieldUuid
  );

  useEffect(() => {
    if (tierCode && !systemId) {
      if (formState?.errors?.tiers?.find(item => item?.code)) {
        setTierCodeError(true);
        if (tierCodeError) {
          trigger('tiers');
        }
      } else {
        setTierCodeError(false);
      }
    }
  }, [tierCode, formState?.errors?.tiers, tierCodeError]);

  const formattedServerTiersOptions = options =>
    generateSelectOptions(options, null, null, null, {
      getLabel: serverTier => serverTier.name,
      getValue: serverTier => serverTier.uuid,
      getDescription: serverTier => {
        if (serverTier?.clusterApiUrl) {
          return (
            <div className="font-12">
              {`${t('pages.adminAddServerTier.clusterApiUrl')}: ${serverTier.clusterApiUrl}`}
            </div>
          );
        }
      },
    });

  const serverTiersOptions = useMemo(
    () => formattedServerTiersOptions(getServerTiersOptions.data?.data),
    [getServerTiersOptions.data?.data]
  );

  const { inputsBlock, warningText, availableResources } = useAvailableResources(
    { watch, setValue },
    { uuid: serverTierUuid, name: serverTierName },
    inputsName,
    storageValue
  );

  useEffect(() => {
    if (
      (!!availableResources && warningText && !tierHaveAlerts) ||
      (tierHaveAlerts && !warningText)
    ) {
      setValue(`${inputsName}tierHaveAlerts`, !!warningText);
      if (warningText) {
        setError(`tiers[${inputsName}].tierHaveAlerts`, !!warningText);
      } else {
        clearErrors(`tiers[${inputsName}].tierHaveAlerts`);
      }
    }
  }, [warningText, availableResources]);

  function requestTiersFunction(params) {
    if (systemId) {
      getTiersOptions.run(
        api.tiers.getTiersOptions({
          system: systemId,
          ...params,
        })
      );
    }
  }

  const isCanCreatOnlyDev = checkedPermissions(scopes.tier.tierCreate) === false;
  const availableStorage =
    availableResources?.STORAGE &&
    availableResources.STORAGE?.total - availableResources.STORAGE?.limits > 0
      ? (availableResources.STORAGE?.total - availableResources.STORAGE?.limits).toFixed(3)
      : 0;
  const optionsRequestParams = useMemo(
    () => ({
      system: systemId,
      ascending: true,
      orderBy: 'name',
      limit: 10,
    }),
    [systemId]
  );

  const searchFilter = async q => {
    try {
      const filterOptions = await getServerTiersOptions.run(
        api.serverTiers.getServerTiersOptions({
          ...optionsRequestParams,
          nameQuery: q,
        })
      );

      return formattedServerTiersOptions(filterOptions.data);
    } catch (e) {
      return [];
    }
  };

  function requestOptionsFunction(params) {
    getServerTiersOptions.run(
      api.serverTiers.getServerTiersOptions({
        ...optionsRequestParams,
        ...params,
        status: [serverTierStatusKeys.ACTIVE],
      })
    );
  }

  useEffect(() => {
    requestTiersFunction();
    setValue(`${inputsName}cpuUnit`, cpuUnit || cpuUnitKeys.cores);
    setValue(`${inputsName}memoryUnit`, memoryUnit || unitMemoryNames.gib);
    setValue(`${inputsName}storageUnit`, storageUnit || unitMemoryNames.gib);
  }, []);

  useEffect(() => {
    if (isCanCreatOnlyDev) {
      setValue(`${inputsName}devTier`, true);
    }
  }, [isCanCreatOnlyDev]);

  useEffect(() => {
    if (activeStepStorage?.storageData >= 5 && !systemId) {
      trigger([`${inputsName}CPU`, `${inputsName}MEMORY`]);
    }
  }, [availableStorage]);

  useEffect(() => {
    if (availableResources && !availableStorage) {
      setValue(`${inputsName}STORAGE`, 0);
    }
  }, [availableStorage]);

  useEffect(() => {
    if (setIsValidForm) {
      setIsValidForm(!warningText);
    }
  }, [warningText]);

  useEffect(() => {
    if (serverTierUuid && tierCode) {
      trigger(`${inputsName}code`);
    }
  }, [serverTierUuid]);

  useEffect(() => {
    if (!systemId && serverTierUuid && getServerTiersOptions.data?.data?.length) {
      setValue(
        `${inputsName}serverTier.name`,
        getServerTiersOptions.data?.data.find(item => item.uuid === serverTierUuid)?.name
      );
    }
  }, [serverTierUuid, getServerTiersOptions.data?.data]);

  const onChangeUnitValue = (value, name) => {
    if (value) {
      setValue(`${inputsName}${name}`, value);
    }
  };

  const cpuOptions = useMemo(
    () => [
      {
        id: cpuUnitKeys.millicores,
        name: declOfNum(
          cpuValue,
          convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.millicores).unit,
          false,
          true
        ),
        units: convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.millicores).unit,
      },
      {
        id: cpuUnitKeys.cores,
        name: declOfNum(
          cpuValue,
          convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.cores).unit,
          false,
          true
        ),
        units: convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.cores).unit,
      },
    ],
    [cpuValue, i18n.language]
  );

  const currentExamples = !systemId
    ? examples.filter(item => !tiers?.find(option => option?.code === item))
    : examples.filter(item => !getTiersOptions.data?.data.find(option => option.name === item));

  const addTierForm = (
    <>
      <InputComponent
        label={t('common.name')}
        name={`${inputsName}code`}
        isRequired
        validationRules={systemTierNameValidation}
        maxLength={30}
        description={fieldsDescriptionMap.name}
        examples={getTiersOptions.isLoading || isRestoreAction ? [] : currentExamples || []}
        disabled={isRestoreAction}
      />
      <InputComponent
        label={t('common.serverTier')}
        name={`${inputsName}serverTier.uuid`}
        component={inputComponents.newSelect}
        checkedValue={{ value: serverTierUuid, label: serverTierName }}
        newSelectVariant="typeahead"
        isLoading={getServerTiersOptions.isLoading}
        searchFilter={searchFilter}
        options={serverTiersOptions}
        withInfiniteScroll={{
          total: getServerTiersOptions.data?.meta?.total,
          offset: 10,
          loadMore: requestOptionsFunction,
        }}
        placeholder={t('common.selectFromList')}
        isRequired
        withoutClearButton
        withHoverDescription
      />

      {inputsBlock && (
        <div className="d-sm-flex flex-column gap-4">
          <div>
            <h3 className="font-14 font-weight-bold mb-3">
              {t('pages.addNewSystemPage.availableSources')}
            </h3>
            <InputBlockWrapper columnsCount={3}>{inputsBlock}</InputBlockWrapper>
          </div>
          <div>
            <div className="position-relative">
              <Line $width={!systemId && '699px'} />
            </div>
            <h3 className="font-14 font-weight-bold mb-3 pt-4">
              {t('pages.addNewSystemPage.requestedQuota')}
            </h3>
            <InputBlockWrapper columnsCount={3}>
              <InputComponent
                label={limitsLabels.CPU}
                name={`${inputsName}CPU`}
                component={inputComponents.numberInput}
                isRequired
                validationRules={quoteValidation}
                intNumber
                dropdown={
                  <ContextSelectorDropdown
                    optionData={cpuOptions}
                    onFilterChange={e => onChangeUnitValue(e, 'cpuUnit')}
                    value={cpuUnit}
                    checkedValue={cpuValue}
                    width="75%"
                  />
                }
                errorText={t('validation.fillRequiredField')}
                description={getDefaultNumberValidateMessage()}
              />

              <InputComponent
                label={limitsLabels.MEMORY}
                name={`${inputsName}MEMORY`}
                component={inputComponents.numberInput}
                isRequired
                validationRules={quoteValidation}
                intNumber
                dropdown={
                  <ContextSelectorDropdown
                    optionData={unitMemoryDropdownOptions}
                    onFilterChange={e => onChangeUnitValue(e, 'memoryUnit')}
                    value={memoryUnit}
                    width="40%"
                  />
                }
                errorText={t('validation.fillRequiredField')}
                description={getDefaultNumberValidateMessage()}
              />

              <InputComponent
                label={limitsLabels.STORAGE}
                name={`${inputsName}STORAGE`}
                component={inputComponents.numberInput}
                isRequired={false}
                disabled={availableStorage < 1}
                intNumber
                description={getDefaultNumberValidateMessage()}
                dropdown={
                  <ContextSelectorDropdown
                    optionData={unitMemoryDropdownOptions}
                    onFilterChange={e => onChangeUnitValue(e, 'storageUnit')}
                    value={storageUnit}
                    width="40%"
                  />
                }
              />
            </InputBlockWrapper>
            {!!availableResources && warningText}
          </div>
        </div>
      )}

      <div className="d-flex flex-row align-items-baseline">
        <InputComponent
          name={`${inputsName}devTier`}
          switchLabel={t('pages.addNewSystemPage.devTier')}
          withoutLabel
          component={inputComponents.switchInput}
          isDisabled={systemId && isCanCreatOnlyDev}
          tooltipMessage={tierIsDevInfoMessage}
        />
      </div>

      <div className="d-flex flex-row align-items-baseline">
        <TierHosts inputsName={`${inputsName}hosts`} />
      </div>
    </>
  );

  return (
    <>
      {fieldUuid ? (
        <DynamicFormWrapper key={fieldUuid}>
          <div className="d-flex flex-row justify-content-between">
            {t('common.tier')} {index + 1}
            <DeleteButton
              onClick={() => {
                deleteField(fieldUuid, index);
                clearErrors();
                trigger();
              }}
            >
              {t('pages.addNewSystemPage.deleteTier')}
            </DeleteButton>
          </div>
          {addTierForm}
        </DynamicFormWrapper>
      ) : (
        <div key={fieldUuid} className="d-sm-flex flex-column gap-4">
          {addTierForm}
        </div>
      )}
    </>
  );
}

export default TierForm;
