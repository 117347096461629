import styled from 'styled-components';

export const NameWrapper = styled.div`
  font-weight: 600;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const ServiceEndpointTiersCounter = styled.span.attrs({
  className: 'font-weight-normal font-14 ml-3',
})`
  text-decoration-line: underline;
  vertical-align: text-bottom;
`;

export const ServiceEndpointToWrapper = styled.div`
  line-height: 23px;
  position: relative;
`;

export const VectorWrapper = styled.div`
  padding-right: 25px;
`;

export const RowButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0 20px;
`;
