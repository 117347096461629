import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useFormError from 'hooks/useFormError';
import SectionHeader from 'components/Common/SectionHeader';
import { CloseButton } from 'components/UI/CloseButton';
import { InputsBlockWrapper, SmallModalForm, ButtonsWrapper } from 'components/UI/View';
import { BlueButton, BlueTextButton } from 'components/UI/Button';
import InputComponent from 'components/UI/InputComponent';
import DataBlock from 'components/Common/DataBlock';

import * as S from './styles';

function MergeForm({
  isModalVisible,
  toggleModal,
  attributes = [],
  checkedUuids = [],
  setCheckedUuids,
}) {
  const { t } = useTranslation();
  const formMethods = useForm();
  const { run, isLoading, isSuccess, errorMessage } = useAsync();
  const { errorComponent, setErrorText } = useFormError(errorMessage);

  const [checkedAttributes, setcheckedAttributes] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      toggleModal();
      formMethods.reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    setcheckedAttributes(attributes.filter(item => checkedUuids.includes(item.id)));
  }, [checkedUuids]);

  const removeItem = removedItem => {
    setCheckedUuids(prevState => prevState.filter(item => item !== Number(removedItem.id)));
  };

  const onSubmit = ({ name }) => {
    if (!name) {
      return setErrorText(t('pages.adminDictionaries.valueIsRequired'));
    }
    checkedAttributes.map(item => run(api.dictionaries.editDictionary(item.id)));
  };

  return (
    <ReactModal isOpen={isModalVisible} ariaHideApp={false} onRequestClose={toggleModal}>
      <SectionHeader title={t('pages.adminDictionaries.combining')}>
        <CloseButton onClick={toggleModal} />
      </SectionHeader>

      <FormProvider {...formMethods}>
        <SmallModalForm onSubmit={formMethods.handleSubmit(onSubmit)}>
          <S.BlockTitle>{t('pages.adminDictionaries.selectedValue')}</S.BlockTitle>
          <S.DataBlockWrapper>
            <DataBlock data={checkedAttributes} removeItem={removeItem} />
          </S.DataBlockWrapper>
          <InputsBlockWrapper>
            <InputComponent
              label={t('pages.adminDictionaries.referenceValue')}
              name="name"
              isRequired
            />
          </InputsBlockWrapper>

          <ButtonsWrapper>
            <BlueButton isDisabled={isLoading} onClick={formMethods.handleSubmit(onSubmit)}>
              {t('pages.adminDictionaries.combine')}
            </BlueButton>
            <BlueTextButton $marginleft onClick={toggleModal}>
              {t('common.cancel')}
            </BlueTextButton>
          </ButtonsWrapper>
          {errorComponent}
        </SmallModalForm>
      </FormProvider>
    </ReactModal>
  );
}

export default MergeForm;
