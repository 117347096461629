import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { Tab, TabTitleText } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getReleasesAvailableStatuses } from 'components/Common/StatusComponent';
import SystemDeploymentsTable from 'pages/SystemRouter/SystemDeploymentsPage/SystemDeploymentsTable/index';
import DocumentsTable from 'pages/SystemRouter/SystemDocumentsRouter/DocumentsPage/DocumentsTable';
import ServiceVersionsTable from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';
import paths from 'constants/paths';
import { socketActionKeys } from 'constants';

import ReleaseInfo from './ReleaseInfo';
import ReleaseTasks from './ReleaseTasks';
import DeclineReleaseForm from './DeclineReleaseForm';
import DeployForm from './DeployForm';
import ReleaseActions from '../ReleasesPage/ReleaseActions';
import { featureFlags } from '../../../../constants/features';

function CurrentReleasesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, systemType, releaseId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs();
  const { checkedPermissions } = useUserPermissions();

  const { data, run, setData, isLoading, isError } = useAsync();
  const changeRelease = useAsync();
  const approveRelease = useAsync();

  const deleteRelease = useAsync();
  const releasesAvailableStatuses = getReleasesAvailableStatuses();
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isShowServiceVertionsFilters, setIsShowServiceVertionsFilters] = useState(false);

  const declineReleaseModal = useModal();
  const deployReleaseModal = useModal();

  const redirectFunction = () =>
    history.push(
      generatePath(paths.systemFullPaths.releases, {
        systemId,
        systemType,
      })
    );

  useSocketAsync({
    topic: 'releases',
    filterFn: ({ object }) => releaseId === object?.uuid,
    onMessage: async ({ action }) => {
      if (action === socketActionKeys.DELETED) {
        return redirectFunction();
      }
      try {
        if (releaseId) {
          const newData = await api.releases.getRelease(releaseId);
          setData(newData.data);
          return;
        }
      } catch (e) {
        if (e) {
          return redirectFunction();
        }
      }
    },
  });

  useEffect(() => {
    if (releaseId) {
      run(api.releases.getRelease(releaseId));
    }
  }, [releaseId]);

  useEffect(() => {
    if (deleteRelease.isSuccess) {
      history.goBack();
    }
  }, [deleteRelease.isSuccess]);

  const leftSideContent = (
    <div className="mx-4">
      <ReleaseActions
        release={data}
        deployReleaseModal={deployReleaseModal}
        declineReleaseModal={declineReleaseModal}
        changeRelease={changeRelease}
        isBlueButtonDropdown
      />
    </div>
  );

  const title = (
    <div className="d-flex">
      {data?.name}
      <div className="d-flex ml-2 title-line-height">
        <StatusComponent statusId={data?.status} statuses={releasesAvailableStatuses} />
      </div>
    </div>
  );

  return (
    <>
      <PageLayout
        title={title}
        leftSideContent={leftSideContent}
        withoutSidePadding
        withSystemSelector
        isError={isError}
        isWaitLoading={{ isLoading }}
      >
        <div className="h-100">
          <TabsStyled
            className="px-4"
            activeKey={currentTab}
            onSelect={onSelectTab}
            aria-label={t('common.authorizationService')}
          >
            <Tab eventKey={0} title={<TabTitleText>{t('common.description')}</TabTitleText>}>
              <ReleaseInfo releaseData={data} />
            </Tab>
            {featureFlags.isServiceVersionsFeatureEnabled && (
              <Tab
                eventKey={1}
                title={
                  <TabTitleText className="d-flex">
                    {t('common.serviceVersions')}
                    <TotalSummaryBadge
                      total={data?.totalSummary?.serviceVersionTotal.total}
                      isSelect={currentTab === 1}
                    />
                  </TabTitleText>
                }
              >
                <div className="mt-4">
                  <ServiceVersionsTable
                    releaseId={releaseId}
                    isShowFilters={isShowServiceVertionsFilters}
                    setIsShowFilters={setIsShowServiceVertionsFilters}
                    currentTotal={data?.uuid && data?.totalSummary?.serviceVersionTotal.total}
                  />
                </div>
              </Tab>
            )}
            <Tab
              eventKey={2}
              title={
                <TabTitleText className="d-flex">
                  {t('common.deployments')}
                  <TotalSummaryBadge
                    total={data?.totalSummary?.tierReleaseTotal.total}
                    isSelect={currentTab === 2}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <SystemDeploymentsTable
                  systemId={systemId}
                  releaseId={releaseId}
                  currentTotal={data?.totalSummary?.tierReleaseTotal.total}
                />
              </div>
            </Tab>
            {featureFlags.isIssuesFeatureEnabled &&
              checkedPermissions(scopes.release.releaseViewIssues) && (
                <Tab
                  eventKey={3}
                  title={
                    <TabTitleText className="d-flex">
                      {t('common.issues')}
                      <TotalSummaryBadge
                        total={data?.totalSummary?.releaseIssueTotal.total}
                        isSelect={currentTab === 3}
                      />
                    </TabTitleText>
                  }
                >
                  <div className="mt-4">
                    <ReleaseTasks
                      data={data}
                      run={run}
                      approveRelease={approveRelease}
                      changeRelease={changeRelease}
                    />
                  </div>
                </Tab>
              )}
            <Tab
              eventKey={4}
              title={
                <TabTitleText className="d-flex">
                  {t('common.documents')}
                  <TotalSummaryBadge
                    total={data?.totalSummary?.documentTotal.total}
                    isSelect={currentTab === 4}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <DocumentsTable
                  setIsShowFilters={setIsShowFilters}
                  isShowFilters={isShowFilters}
                  currentTotal={data?.uuid && data?.totalSummary?.documentTotal?.total}
                />
              </div>
            </Tab>
          </TabsStyled>
        </div>
      </PageLayout>

      <DeclineReleaseForm
        {...declineReleaseModal}
        callback={() => run(api.releases.getRelease(releaseId))}
      />

      <DeployForm
        {...deployReleaseModal}
        isDevTier={deployReleaseModal.modalData?.deployToDev}
        onSelectTab={onSelectTab}
      />
    </>
  );
}

export default CurrentReleasesPage;
