import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import useFormDynamicFields from 'hooks/useFormDynamicFields';

import AddButton from 'components/UI/AddButton';
import ContactsForm from './ContactsForm';

import { stepsKeys } from '../keys';
import { SystemBlockWrapper } from '../../styles';

const dynamicParentField = stepsKeys.contactPersons;

function ContactPersons() {
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('step', 2);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.contacts')}</div>

      {fieldUuids.map((fieldUuid, index) => (
        <ContactsForm
          key={fieldUuid}
          dynamicParentField={dynamicParentField}
          fieldUuids={fieldUuids}
          fieldUuid={fieldUuid}
          index={index}
          deleteField={deleteField}
        />
      ))}
      <div className="d-flex mb-3">
        <AddButton onClick={addField}>{t('pages.addNewSystemPage.addContact')}</AddButton>
      </div>
    </SystemBlockWrapper>
  );
}

export default ContactPersons;
