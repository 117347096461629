import React, { useMemo } from 'react';
import { Tooltip } from '@patternfly/react-core';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import { declOfNum } from 'utils';
import paths from 'constants/paths';
import { issueStatusKeys } from 'constants';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

import * as S from './styles';

const issuesTotalModel = {
  new: 0,
  inProgress: 0,
  implemented: 0,
  total: 0,
  done: 0,
  overdue: 0,
};

function MilestoneProgress({ issuesTotal = issuesTotalModel, name, uuid, system }) {
  const { t } = useTranslation();
  const declensionTasks = [
    t('pages.systemAddMilestonePage.plural.issues1'),
    t('pages.systemAddMilestonePage.plural.issues2'),
    t('pages.systemAddMilestonePage.plural.issues3'),
  ];
  const declensionTasksOpenStatus = [
    t('pages.systemAddMilestonePage.plural.open1'),
    t('pages.systemAddMilestonePage.plural.open2'),
    t('pages.systemAddMilestonePage.plural.open3'),
  ];
  const declensionTasksCloseStatus = [
    t('pages.systemAddMilestonePage.plural.resolved1'),
    t('pages.systemAddMilestonePage.plural.resolved2'),
    t('pages.systemAddMilestonePage.plural.resolved3'),
  ];
  const declensionOverdueTasksStatus = [
    t('pages.systemAddMilestonePage.plural.overdue1'),
    t('pages.systemAddMilestonePage.plural.overdue2'),
    t('pages.systemAddMilestonePage.plural.overdue3'),
  ];
  const declensionNewTasks = [
    t('pages.systemAddMilestonePage.plural.new1'),
    t('pages.systemAddMilestonePage.plural.new2'),
    t('pages.systemAddMilestonePage.plural.new3'),
  ];
  const declensionImplementedTasks = [
    t('pages.systemAddMilestonePage.plural.implemented1'),
    t('pages.systemAddMilestonePage.plural.implemented2'),
    t('pages.systemAddMilestonePage.plural.implemented3'),
  ];
  const declensionDoneTasks = [
    t('pages.systemAddMilestonePage.plural.finished1'),
    t('pages.systemAddMilestonePage.plural.finished2'),
    t('pages.systemAddMilestonePage.plural.finished3'),
  ];
  const percentageCompletion = useMemo(() => {
    if (!issuesTotal.total) {
      return 0;
    }

    return Math.round((issuesTotal.done * 100) / issuesTotal.total);
  }, [issuesTotal]);

  const onClickGeneratePath = (milestoneUuid, tab, statuses) => ({
    pathname: generatePath(paths.routePaths.system + paths.systemMilestonesFullPaths.milestone, {
      systemId: system.uuid,
      systemType: system.type,
      milestoneId: milestoneUuid,
      actions: 'test',
    }),
    state: {
      currentTab: tab,
      tasksStatus: statuses,
    },
  });

  const newIssues = issuesTotal.new + issuesTotal.inProgress + issuesTotal.implemented;
  const withoutNewIssues = issuesTotal.total
    ? t('pages.systemAddMilestonePage.noOpenIssues')
    : t('pages.systemAddMilestonePage.noIssues');
  const issuesTotalMessage = (
    <>
      {declOfNum(issuesTotal.new, declensionNewTasks, false)} - {issuesTotal.new}
      <br />
      {t('pages.systemMilestonePage.inProgress')} - {issuesTotal.inProgress}
      <br />
      {declOfNum(issuesTotal.implemented, declensionImplementedTasks, false)} -{' '}
      {issuesTotal.implemented}
      <br /> {declOfNum(issuesTotal.done, declensionDoneTasks, false)} - {issuesTotal.done}
    </>
  );

  const percentageOverdueTasks = useMemo(() => {
    if (!issuesTotal.total) {
      return 0;
    }

    return Math.round((issuesTotal.overdue * 100) / issuesTotal.total);
  }, [issuesTotal]);

  return (
    <div className="d-sm-flex flex-column w-100">
      <div className="d-flex flex-row">
        <S.TitleProgressBlock>
          <S.TitleProgressBar progress={percentageCompletion} />
          <S.TitleProgressOverdueBar progress={percentageOverdueTasks} />
        </S.TitleProgressBlock>
        <div className="font-12 ml-2">{percentageCompletion}%</div>
      </div>
      <div className="d-flex flex-row font-12 text-blu">
        {newIssues ? (
          <span className="text-nowrap">
            <LinkWithPermission
              system={system}
              scope={scopes.milestone.milestoneViewForm}
              to={onClickGeneratePath(uuid, 1)}
              isTableLink
              font="12px"
            >
              <span id={`taskFor_${name}`}>{declOfNum(issuesTotal.total, declensionTasks)}</span>
            </LinkWithPermission>{' '}
            <Tooltip content={issuesTotalMessage} exitDelay={150} animationDuration={150}>
              <span aria-labelledby={`taskFor${uuid}`}>
                <LinkWithPermission
                  system={system}
                  scope={scopes.milestone.milestoneViewForm}
                  to={onClickGeneratePath(uuid, 1, [issueStatusKeys.DONE])}
                  isTableLink
                  font="12px"
                >
                  ({declOfNum(issuesTotal.total - newIssues, declensionTasksCloseStatus)}
                </LinkWithPermission>{' '}
                -{' '}
                <LinkWithPermission
                  system={system}
                  scope={scopes.milestone.milestoneViewForm}
                  to={onClickGeneratePath(uuid, 1, [
                    issueStatusKeys.IN_PROGRESS,
                    issueStatusKeys.IMPLEMENTED,
                    issueStatusKeys.NEW,
                  ])}
                  isTableLink
                  font="12px"
                >
                  {declOfNum(newIssues, declensionTasksOpenStatus)})
                </LinkWithPermission>
              </span>
            </Tooltip>
            {!!issuesTotal.overdue && (
              <span className="text-red ml-1">
                {declOfNum(issuesTotal.overdue, declensionOverdueTasksStatus)}
              </span>
            )}
          </span>
        ) : (
          <LinkWithPermission
            system={system}
            scope={scopes.milestone.milestoneViewForm}
            to={onClickGeneratePath(uuid, 1)}
            isTableLink
            font="12px"
          >
            {withoutNewIssues}
          </LinkWithPermission>
        )}
      </div>
    </div>
  );
}

export default MilestoneProgress;
