import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@patternfly/react-core';
import { checkedTierTotal, checkedUserTotal, getUserDescription } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { usersSelector } from 'store/users/selectors';
import { fetchUserById } from 'store/users/actions';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { userStatusKeys, roleStatusKeys } from 'constants';
import TextBlock from 'components/UI/TextBlock';
import { getUserValidation } from 'constants/vaidations';

function UserSelect({
  fieldName = 'user',
  disabled = false,
  initialOptions,
  checkedValue,
  tooltip = undefined,
  setIsUserHaveAlerts,
  selectedSystem,
  isUserSelected,
  isModalForm,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { systemId, userId },
  } = useRouteMatch();
  const { setValue, watch, formState } = useFormContext();
  const { run, data, isLoading } = useAsync();
  const getUser = useAsync();

  const fieldValue = watch(fieldName);
  const fullName = watch('fullName');
  const responsibleUserOption = watch('responsibleUserOption');
  const userOption = watch('userOption');
  const { selectedUser } = useSelector(usersSelector);
  const [currentSearch, setCurrentSearch] = useState(fullName || '');

  const isSearchLongEnough = currentSearch?.trim().length >= 3;
  const currentUserId = fieldValue || checkedValue || userId;
  const currentUserData = selectedUser || getUser.data;
  const isHaveProblemRole = currentUserData?.roles?.find(
    item => item.status !== roleStatusKeys.ACTIVE
  );
  const userDescription = useMemo(
    () => getUserDescription(selectedUser),
    [selectedUser, i18n.language]
  );

  useSocketAsync({
    topic: 'users',
    debounceTime: 600,
    filterFn: ({ object }) => currentUserId === object?.uuid,
    onMessage: async () => {
      if (currentUserData?.uuid === currentUserId) {
        dispatch(fetchUserById(currentUserId));
      }
    },
  });

  const focusCallback = isFocus => {
    if (!isFocus && !currentSearch?.length && fieldValue?.length) {
      if (initialOptions?.login) {
        setValue('userOption', initialOptions);
        setValue('responsibleUserOption', [initialOptions]);
      }
    }

    if (!isFocus && fieldValue?.length && userOption?.fullName) {
      setCurrentSearch(userOption?.fullName);
      setValue('fullName', userOption?.fullName);
      return;
    }

    if (!isFocus && !fieldValue?.length && currentSearch?.length) {
      setCurrentSearch('');
      setValue('fullName', '');
    }
  };

  useEffect(() => {
    if (systemId && initialOptions?.login && !data?.data.length && !responsibleUserOption?.length) {
      setCurrentSearch(initialOptions?.login);
    }

    if (fieldValue) {
      getUser.run(api.users.getUserById(fieldValue));
    }
  }, []);

  useEffect(() => {
    if (fieldValue && data?.data.length) {
      setValue(
        'userOption',
        [...(data?.data || []), ...(responsibleUserOption || [])]?.find(
          item => item.uuid === fieldValue
        )
      );
    }

    if (data?.data) {
      setValue('responsibleUserOption', data?.data);
    }
  }, [data?.data, fieldValue]);

  useEffect(() => {
    if (checkedValue && data?.data?.length && !currentSearch) {
      setCurrentSearch(
        [...data.data, userOption].find(item => item.uuid === checkedValue)?.fullName
      );
    }
  }, [checkedValue, data?.data]);

  useEffect(() => {
    if (isSearchLongEnough || (!data?.data.length && isSearchLongEnough && fieldValue)) {
      run(api.users.getKeycloakUsers({ q: currentSearch }));
    }

    setValue('fullName', currentSearch);
  }, [currentSearch]);

  const formatedOptions = options =>
    generateSelectOptions(options, null, null, null, {
      getLabel: user => user.fullName || user.name || user.login,
      getValue: user => user.uuid,
      getDescription: user => getUserDescription(user),
      getMeta: user => user,
    });

  const getUsersOptions = useMemo(() => {
    const output = formatedOptions(
      responsibleUserOption?.filter(option => option?.enabled && option?.login !== 'hyperadmin')
    );
    return output;
  }, [responsibleUserOption, i18n.language]);

  const selectCallback = (_, uuidValue) => {
    const currentOption = [
      ...getUsersOptions,
      ...(userOption?.uuid ? formatedOptions([userOption]) : []),
    ]?.find(item => item.value === uuidValue);

    setCurrentSearch(currentOption?.label || '');
  };

  const validationRules = useMemo(
    () => getUserValidation(getUsersOptions, selectedSystem?.code),
    [getUsersOptions, selectedSystem?.code]
  );

  const noResultsText = isSearchLongEnough
    ? t('common.noResults')
    : t('common.insertSearchCriteria');

  const selectedSystemMessage = useMemo(
    () =>
      checkedUserTotal(
        selectedSystem?.totalSummary?.userTotal,
        t('pages.adminUserCreatePage.roleRefreshingError')
      ) || checkedTierTotal(selectedSystem?.totalSummary?.tierTotal),
    [selectedSystem, i18n.language]
  );

  const warningAlertTitle = useMemo(() => {
    if (!setIsUserHaveAlerts) {
      return;
    }

    if (formState.errors[fieldName] && fieldValue) {
      setIsUserHaveAlerts(true);

      if (formState.errors[fieldName].message !== t('validation.requiredMessage')) {
        return formState.errors[fieldName].message;
      }
    }

    if (currentUserData?.uuid && !currentUserData?.enabled) {
      setIsUserHaveAlerts(true);
      return t('pages.adminUserCreatePage.inactiveUserEditingError');
    }

    if (selectedSystemMessage) {
      setIsUserHaveAlerts(true);
      return selectedSystemMessage;
    }

    if (
      (getUser.data?.uuid === fieldValue && getUser.data?.admin) ||
      (userOption?.uuid && userOption?.admin)
    ) {
      setIsUserHaveAlerts(true);
      return t('pages.adminUserCreatePage.unableToAddAdminError');
    }

    if (
      currentUserData &&
      currentUserData.uuid === currentUserId &&
      (currentUserData.status !== userStatusKeys.UPDATED || isHaveProblemRole)
    ) {
      setIsUserHaveAlerts(true);

      if (currentUserData.status === userStatusKeys.UPDATING_IN_PROGRESS) {
        return t('pages.adminUserCreatePage.accessLevelRefreshingError');
      }

      if (currentUserData.status === userStatusKeys.ERROR) {
        return t('pages.adminUserCreatePage.accessLeveError');
      }

      if (
        currentUserData.status !== userStatusKeys.UPDATING_IN_PROGRESS &&
        currentUserData.status !== userStatusKeys.ERROR
      ) {
        if (currentUserData.roles.find(item => item.status === roleStatusKeys.ERROR)) {
          return t('pages.adminUserCreatePage.roleError');
        }

        if (isHaveProblemRole) {
          return t('pages.adminUserCreatePage.incorrectRoleStatusError');
        }
      }
    }

    setIsUserHaveAlerts(false);
  }, [
    currentUserData,
    currentUserId,
    getUser.data?.uuid,
    fieldValue,
    selectedSystemMessage,
    setIsUserHaveAlerts,
    formState.errors[fieldName],
    i18n.language,
    userOption,
  ]);

  return (
    <>
      {isUserSelected ? (
        <TextBlock greyColor title={t('common.user')} isForm>
          <div className="pre-line medium-line-height font-16">
            {selectedUser?.fullName || selectedUser?.login}
            {!!userDescription?.length && (
              <div className="font-12 font-weight-normal text-gray mt-1">{userDescription}</div>
            )}
          </div>
        </TextBlock>
      ) : (
        <InputComponent
          label={t('common.user')}
          name={fieldName}
          component={inputComponents.newSelect}
          isLoading={isLoading}
          newSelectVariant="typeahead"
          onTypeaheadInputChanged={setCurrentSearch}
          noResultsText={noResultsText}
          placeholder={t('pages.adminUserCreatePage.searchUserPlaceholder')}
          isRequired
          options={getUsersOptions}
          disabled={disabled}
          checkedValue={userOption?.uuid ? formatedOptions([userOption]) : checkedValue}
          tooltipMessage={tooltip}
          descriptionMaxLength={100}
          validationRules={validationRules}
          focusCallback={focusCallback}
          withoutErrorsMessage={fieldValue}
          withoutBottomMargin
          isModalForm={isModalForm}
          currentSearch={currentSearch}
          withTypeaheadValue
          selectCallback={selectCallback}
        />
      )}
      {currentUserId && !!warningAlertTitle?.trim()?.length && (
        <Alert
          variant="warning"
          isInline
          title={warningAlertTitle}
          className="font-weight-normal"
        />
      )}
    </>
  );
}

export default UserSelect;
