import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';
import { systemTypesKeys } from 'constants';

import TierComponentBackupPage from './TierComponentBackupPage';
import QuotePage from './QuotePage';
import AddTierPage from './AddTierPage';
import SystemTiersPage from './SystemTiersPage';
import CurrentTierPage from './CurrentTierPage';

const SystemTiersRouter = ({ match: { path, params } }) => (
  <Switch>
    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemTierPaths.addTier}`}
      component={AddTierPage}
      scope={
        params.systemType === systemTypesKeys.EXTERNAL
          ? scopes.platform.externalSystemCreateTier
          : scopes.tier.tierCreate
      }
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemTierPaths.restore}`}
      component={AddTierPage}
      scope={
        params.systemType === systemTypesKeys.EXTERNAL
          ? scopes.platform.externalSystemRestoreTier
          : scopes.tier.tierRestore
      }
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemTierPaths.tierId}`}
      component={CurrentTierPage}
      scope={scopes.tier.tierViewForm}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemTierPaths.quoteId}`}
      component={QuotePage}
      scope={scopes.tier.tierViewForm}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemTierPaths.componentId}`}
      component={TierComponentBackupPage}
      scope={scopes.tier.tierViewBackupComponent}
    />

    <Route exact path={path} component={SystemTiersPage} />
  </Switch>
);

export default SystemTiersRouter;
