import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import { releaseOptions, releaseStatusKeys, tierStatusKeys } from 'constants/index';
import Dropdown from 'components/Dropdown';
import WarningModal from 'components/Common/WarningModal';
import paths from 'constants/paths';
import { currentSystemSelector } from 'store/systems/selectors';
import { useTranslation } from 'react-i18next';

function ReleaseActions({
  release,
  deployReleaseModal,
  declineReleaseModal,
  changeRelease,
  isBlueButtonDropdown,
}) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const currentSystem = useSelector(currentSystemSelector);
  const {
    notActiveSystemMessage,
    notActiveOrErrorSystemMessage,
    checkedPermissions,
    optionsFilterByScopes,
  } = useUserPermissions(release?.system);
  const deployTierDevMessage = t('pages.systemReleasesPage.devTierMissedMessage');
  const deployMessage = t('pages.systemReleasesPage.tierMissedMessage');
  const declineMessage = t('pages.systemReleasesPage.declineUnavailableMessage');
  const { data, run } = useAsync();
  const getActiveDevTiers = useAsync();
  const deleteReleaseModal = useModal();
  const isDeployToDev =
    release?.status === releaseStatusKeys.NEW || release?.status === releaseStatusKeys.READY;
  const isDeployToProd = release?.status === releaseStatusKeys.APPROVED;
  const isDisabledDecline =
    release?.status !== releaseStatusKeys.NEW && release?.status !== releaseStatusKeys.READY;
  const cantSubmitForApprovalMessage = currentSystem?.totalSummary.releaseTotal.requireApproval
    ? t('pages.systemReleasesPage.submitForApprovalUnavailable')
    : null;

  const requestParams = useMemo(
    () => ({
      system: release?.system?.uuid,
      status: [tierStatusKeys.ACTIVE],
      limit: 1,
    }),
    [isDeployToDev, release?.system, checkedPermissions]
  );

  const getActiveTier = () => {
    if (isDeployToProd) {
      setTimeout(() => {
        run(api.tiers.getTiersOptions({ ...requestParams, isDevTier: false }));
      }, 250);
    }

    if (isDeployToProd || isDeployToDev)
      getActiveDevTiers.run(api.tiers.getTiersOptions({ ...requestParams, isDevTier: true }));
  };

  const onFilterChange = id => {
    if (id === releaseOptions.edit && release?.uuid) {
      return history.push(
        generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.edit, {
          systemId: release.system.uuid,
          systemType: release.system.type,
          releaseId: release.uuid,
          action: releaseOptions.edit,
        })
      );
    }

    if (id === releaseOptions.sendToApproval) {
      return changeRelease.run(api.releases.sendToApprovalRelease(release?.uuid));
    }

    if (id === releaseOptions.approve) {
      return changeRelease.run(api.releases.approveRelease(release?.uuid));
    }

    if (id === releaseOptions.deploy) {
      deployReleaseModal.setModalData({ release });
      return deployReleaseModal.toggleModal();
    }

    if (id === releaseOptions.deployToDev) {
      deployReleaseModal.setModalData({ release, deployToDev: true });
      return deployReleaseModal.toggleModal();
    }

    if (id === releaseOptions.delete) {
      deleteReleaseModal.setModalData(release);
      return deleteReleaseModal.toggleModal();
    }

    declineReleaseModal.setModalData(release);
    declineReleaseModal.toggleModal();
  };
  const deleteRelease = () => changeRelease.run(api.releases.deleteRelease(release?.uuid));
  const optionData = useMemo(() => {
    const declineOption = {
      id: releaseOptions.decline,
      name: t('common.cancelAction'),
      isDisabled: notActiveSystemMessage || isDisabledDecline,
      tooltip: notActiveSystemMessage || (isDisabledDecline && declineMessage),
      scope: scopes.release.releaseCancel,
    };
    const deployToDevOption = {
      id: releaseOptions.deployToDev,
      name: t('common.deployToDevTier'),
      isDisabled: notActiveSystemMessage || !getActiveDevTiers.data?.data.length,
      tooltip:
        notActiveSystemMessage || (!getActiveDevTiers.data?.data.length && deployTierDevMessage),
      scope: scopes.deployment.deploymentCreateToDev,
    };
    const deleteOption = {
      id: releaseOptions.delete,
      name: t('common.delete'),
      isDisabled: notActiveOrErrorSystemMessage || release?.totalSummary.tierReleaseTotal.total,
      tooltip: notActiveOrErrorSystemMessage || t('pages.systemReleasesPage.unableToCancelRelease'),
      scope: scopes.release.releaseDelete,
    };

    if (release?.status === releaseStatusKeys.CANCELLED) {
      return optionsFilterByScopes([deleteOption]);
    }

    if (release?.status === releaseStatusKeys.NEW) {
      return optionsFilterByScopes([
        {
          id: releaseOptions.edit,
          name: t('common.edit'),
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
          scope: scopes.release.releaseUpdate,
        },
        {
          id: releaseOptions.sendToApproval,
          name: t('pages.systemReleasesPage.sendForApproval'),
          isDisabled: notActiveSystemMessage || cantSubmitForApprovalMessage,
          tooltip: notActiveSystemMessage || cantSubmitForApprovalMessage,
          scope: scopes.release.releaseRequestApprove,
        },
        deployToDevOption,
        declineOption,
        deleteOption,
      ]);
    }

    if (release?.status === releaseStatusKeys.READY) {
      return optionsFilterByScopes([
        {
          id: releaseOptions.approve,
          name: t('common.approve'),
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
          scope: scopes.release.releaseApprove,
        },
        deployToDevOption,
        declineOption,
        deleteOption,
      ]);
    }

    const deployOption = {
      id: releaseOptions.deploy,
      name: t('common.deployToTier'),
      isDisabled: notActiveSystemMessage || !data?.data.length,
      tooltip: notActiveSystemMessage || (!data?.data.length && deployMessage),
      scope: scopes.deployment.deploymentCreate,
    };

    return optionsFilterByScopes([deployOption, deployToDevOption, declineOption, deleteOption]);
  }, [
    release,
    data?.data,
    getActiveDevTiers.data,
    isDeployToDev,
    optionsFilterByScopes,
    notActiveSystemMessage,
    notActiveOrErrorSystemMessage,
    i18n.language,
  ]);

  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={release?.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          callback={getActiveTier}
        />
      )}

      <WarningModal
        title={t('pages.systemReleasesPage.deletingRelease')}
        text={t('pages.systemReleasesPage.deleteRelease')}
        name={release?.name}
        label={t('pages.systemReleasesPage.releaseNumber')}
        isLoading={changeRelease.isLoading}
        buttenText={t('common.delete')}
        onClick={deleteRelease}
        {...deleteReleaseModal}
      />
    </div>
  );
}

export default ReleaseActions;
