import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { Button, Tooltip } from '@patternfly/react-core';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { ExclamationTriangleIcon, ModalBody } from 'components/Common/WarningModal/styles';
import InputComponent from 'components/UI/InputComponent';
import { useTranslation } from 'react-i18next';

function DeclineReleaseForm({ isModalVisible, toggleModal, modalData, callback }) {
  const { t } = useTranslation();
  const { run, isSuccess, isLoading } = useAsync();

  const formMethods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const descriptionValue = formMethods.watch('description');

  useEffect(() => {
    if (isSuccess) {
      formMethods.setValue('description', '');
      callback();
      toggleModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isModalVisible) {
      formMethods.reset();
      formMethods.clearErrors();
    }
  }, [isModalVisible]);

  const onSubmit = ({ description }) => {
    run(
      api.releases.declineRelease(modalData?.uuid, {
        reason: description,
      })
    );
  };

  const modalHeader = (
    <div className="d-flex mb-3">
      <ExclamationTriangleIcon />
      <div className="font-24 hyphens">{t('pages.systemReleasePage.cancelRelease')}</div>
    </div>
  );

  // TODO: Replace with warning modal
  return (
    <ReactModal isOpen={isModalVisible} ariaHideApp={false} onRequestClose={toggleModal}>
      <FormProvider {...formMethods}>
        <ModalBody onSubmit={formMethods.handleSubmit(onSubmit)}>
          {modalHeader}
          {t('pages.systemReleasePage.cancelTitle')} <b>{modalData?.name}</b>?
          <div className="mt-3">
            <span className="pre-line">{t('pages.systemReleasePage.cancelMessage')}</span>
            <div className=" mt-3">
              <InputComponent
                label={t('pages.systemReleasePage.cancelReleaseReason')}
                name="description"
                isRequired
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-start mt-4">
            <Tooltip
              key={modalData?.name}
              className={!descriptionValue ? undefined : 'd-none'}
              content={!descriptionValue ? t('validation.fillRequiredField') : null}
              exitDelay={150}
              animationDuration={150}
            >
              <div>
                <Button
                  onClick={onSubmit}
                  isDisabled={!descriptionValue || isLoading}
                  isLoading={isLoading}
                  className="px-3"
                >
                  {t('pages.systemReleasePage.performCancellation')}
                </Button>
              </div>
            </Tooltip>
            <Button variant="link" onClick={toggleModal}>
              {t('common.cancel')}
            </Button>
          </div>
        </ModalBody>
      </FormProvider>
    </ReactModal>
  );
}

export default DeclineReleaseForm;
