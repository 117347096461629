export const scopes = {
  platform: {
    platformDoAll: 'platform:doAll',
    platformView: 'platform:view',
    systemViewSection: 'system:viewSection',
    systemCreate: 'system:create',
    archiveSystemViewSection: 'archiveSystem:viewSection',

    externalSystemViewForm: 'externalSystem:viewForm',
    externalSystemsCreate: 'externalSystem:create',
    externalSystemsUpdate: 'externalSystem:update',
    // externalSystemsDelete: 'externalSystem:delete',
    externalSystemCreateTier: 'externalSystem:createTier',
    externalSystemDeleteTier: 'externalSystem:deleteTier',
    externalSystemArchiveTier: 'externalSystem:archiveTier',
    externalSystemRestoreTier: 'externalSystem:restoreTier',

    tierViewSection: 'tier:viewSection',
    serviceViewSection: 'service:viewSection',
    versionViewSection: 'version:viewSection',
    releaseViewSection: 'release:viewSection',
    deploymentViewSection: 'deployment:viewSection',
    milestoneViewSection: 'milestone:viewSection',
    issueViewSection: 'issue:viewSection',
    integrationViewSection: 'integration:viewSection',
    userViewSection: 'user:viewSection',
    documentViewSection: 'document:viewSection',
    clusterViewSection: 'cluster:viewSection',
    backupViewSection: 'backup:viewSection',
    auditViewSection: 'audit:viewSection',
    dictionaryViewSection: 'dictionary:viewSection',

    clusterViewForm: 'cluster:viewForm',
    clusterCreate: 'cluster:create',
    clusterUpdate: 'cluster:update',
    clusterDelete: 'cluster:delete',
    clusterViewMonitoring: 'cluster:viewMonitoring',
    clusterAddEquipment: 'cluster:addEquipment',
    clusterViewEquipment: 'cluster:viewEquipment',
    clusterUpdateEquipment: 'cluster:updateEquipment',
    clusterDeleteEquipment: 'cluster:deleteEquipment',
    // clusterAddEquipmentDocument: 'cluster:addEquipmentDocument',
    clusterViewEquipmentDocument: 'cluster:viewEquipmentDocument',
    // clusterDeleteEquipmentDocument: 'cluster:deleteEquipmentDocument',

    backupViewComponent: 'backup:viewComponent',
    backupUpdate: 'backup:update',

    userUpdate: 'user:update',
    userUpdateRole: 'user:updateRole',
    userViewForm: 'user:viewForm',

    // dictionaryCreate: 'dictionary:create',
    // dictionaryUpdate: 'dictionary:update',
    // dictionaryDelete: 'dictionary:delete',

    // helpAdd: 'help:add',
    // helpDelete: 'help:delete',
  },
  system: {
    systemViewForm: 'system:viewForm',
    systemUpdateResponsible: 'system:updateResponsible',
    systemUpdate: 'system:update',
    // systemDelete: 'system:delete',
    // systemUpdateArchive: 'system:updateArchive',
    systemArchive: 'system:archive',
    systemRestore: 'system:restore',
    // systemReceiveNotification: 'system:receiveNotification',
    // systemReceiveNotificationAdmin: 'system:receiveNotificationAdmin',
    // systemReceiveNotificationDev: 'system:receiveNotificationDev',
  },
  service: {
    serviceViewSection: 'service:viewSection',
    serviceCreate: 'service:create',
    serviceViewForm: 'service:viewForm',
    serviceUpdate: 'service:update',
    serviceDelete: 'service:delete',
    serviceArchive: 'service:archive',
    serviceRestore: 'service:restore',
    serviceImport: 'service:import',
  },
  serviceVersion: {
    versionViewSection: 'version:viewSection',
    versionCreate: 'version:create',
    versionViewForm: 'version:viewForm',
    versionDelete: 'version:delete',
  },
  release: {
    releaseViewSection: 'release:viewSection',
    releaseCreate: 'release:create',
    releaseViewForm: 'release:viewForm',
    releaseUpdate: 'release:update',
    releaseDelete: 'release:delete',
    releaseRequestApprove: 'release:requestApprove',
    releaseApprove: 'release:approve',
    releaseViewIssues: 'release:viewIssues',
    releaseUpdateIssues: 'release:updateIssues',
    releaseCancel: 'release:cancel',
  },
  deployment: {
    deploymentViewSection: 'deployment:viewSection',
    deploymentCreate: 'deployment:create',
    deploymentCreateToDev: 'deployment:createToDev',
  },
  tier: {
    tierViewSection: 'tier:viewSection',
    tierCreate: 'tier:create',
    tierCreateDev: 'tier:createDev',
    tierViewForm: 'tier:viewForm',
    tierDelete: 'tier:delete',
    tierArchive: 'tier:archive',
    tierRestore: 'tier:restore',

    tierRequestQuota: 'tier:requestQuota',
    tierChangeQuota: 'tier:changeQuota',
    tierApproveQuota: 'tier:approveQuota',
    tierRejectQuota: 'tier:rejectQuota',

    tierViewMonitoring: 'tier:viewMonitoring',

    tierUpdateBackupPeriod: 'tier:updateBackupPeriod',
    tierViewBackup: 'tier:viewBackup',
    tierViewBackupComponent: 'tier:viewBackupComponent',
  },
  integration: {
    integrationViewSection: 'integration:viewSection',
    integrationCreate: 'integration:create',
    integrationUpdate: 'integration:update',
    integrationDelete: 'integration:delete',
    integrationViewForm: 'integration:viewForm',
  },
  milestone: {
    milestoneViewSection: 'milestone:viewSection',
    milestoneCreate: 'milestone:create',
    milestoneUpdate: 'milestone:update',
    milestoneDelete: 'milestone:delete',
    milestoneClose: 'milestone:close',
    milestoneViewForm: 'milestone:viewForm',
  },
  issue: {
    issueViewSection: 'issue:viewSection',
    issueCreate: 'issue:create',
    issueDelete: 'issue:delete',
    issueUpdate: 'issue:update',
    issueViewForm: 'issue:viewForm',
    issueUpdateStatus: 'issue:updateStatus',
  },
  user: {
    userViewSection: 'user:viewSection',
    userAddToSystem: 'user:addToSystem',
    userRevoke: 'user:revoke',
    userUpdateRole: 'user:updateRole',
  },
  document: {
    documentAdd: 'document:add',
    documentDelete: 'document:delete',
    documentViewSection: 'document:viewSection',
  },
};
