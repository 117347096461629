import styled, { css } from 'styled-components';

import SortArrowSVG from 'assets/images/table/sort-arrow.svg';

export const TableWrapper = styled.table`
  width: 100%;
  margin: 0;

  ${({ withSidesMargin }) =>
    withSidesMargin &&
    `
      width: auto;
      margin: 16px;
      padding-bottom: 8px;
  `}
`;

export const TableHeadWrapper = styled.tr`
  display: ${({ grid }) => (grid ? 'grid' : 'table-row')};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  padding: 0 16px;
  background: ${({ headerColor }) => headerColor ?? 'rgba(240, 240, 240, 0.5)'};
`;

export const TableCell = styled.th`
  font-size: 14px;
  min-height: 37px;
`;

export const TableHeaderCell = styled(TableCell)`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4d5258;
  padding: ${({ grid }) => (grid ? '0 4px' : '10px 15px 10px 5px')};
  display: ${({ grid }) => (grid ? 'flex' : 'table-cell')};
  width: ${({ grid, width }) => (!grid ? width || 'inherit' : 'inherit')};
  align-items: center;
  cursor: pointer;

  ${({ withCheckboxes }) =>
    withCheckboxes &&
    `
      width: 20px;
  `};

  ${({ labelCenter }) =>
    labelCenter &&
    `
      display: flex;
      justify-content: center;
  `};
`;

export const TableHeaderCellButton = styled.button`
  border: none;
  text-align: left;
  padding: 0;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4d5258;
  white-space: nowrap;

  background: ${({ headerColor }) => headerColor ?? 'rgba(240, 240, 240, 0.5)'};
`;

const sortStyles = css`
  display: inline-block;
  -webkit-mask-image: url(${SortArrowSVG});
  mask-image: url(${SortArrowSVG});
  background: #bbb;
  height: 14px;
  width: 7px;
  border: 0;
  padding: 0;

  ${({ $isActive }) =>
    $isActive &&
    `
      background: #0F69C9;
  `};
`;

export const UpSortArrowIcon = styled.span`
  margin-left: 8px;
  ${sortStyles}
`;

export const DownSortArrowIcon = styled.span`
  transform: rotate(180deg);
  ${sortStyles}
`;

export const ContentWrapperBlock = styled.tr`
  display: ${({ grid }) => (grid ? 'grid' : 'table-row')};
  grid-template-columns: 100%;
  padding: 0;
  margin-left: 0;
  border-bottom: 1px solid #d2d2d2;
  border-left: solid 3px transparent;
  border-left-color: #0f69c9;

  ${({ withoutBorder }) => withoutBorder && `border-bottom: none;`}
`;

export const DescriptionWrapper = styled.div`
  margin-top: -1px;
  background-color: #ffffff;
  padding: 10px 0 16px 68px;
  font-size: 14px;
  line-height: 21px;
`;

export const NoContentText = styled.td.attrs(({ colSpan }) => colSpan && { colSpan })`
  padding: 20px 0;
  margin-top: -1px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`;
