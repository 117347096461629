import React from 'react';

import SystemHeader from './SystemHeader';
import SystemTotals from './SystemTotals';

import * as S from './styles';

function SystemComponent({ system }) {
  return (
    <div className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-3">
      <S.Wrapper className="d-sm-flex flex-column justify-content-between">
        <SystemHeader system={system} isSystemList />
        <div style={{ height: '186px' }}>{system && <SystemTotals system={system} />}</div>
      </S.Wrapper>
    </div>
  );
}

export default SystemComponent;
