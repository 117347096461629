import http from 'api/config';

const getQuotaRequests = ({ tier, ...params }) =>
  http.get(`/tiers/${tier}/quota-requests`, { params });

const getQuota = ({ tierId, data }) => http.get(`/tiers/${tierId}/quota`, data);

const addTierQuota = body => http.post(`/tiers/${body.tier.uuid}/quota-requests`, body);

const rejectTierQuota = (tierUuid, quotaRequestUuid) =>
  http.post(`tiers/${tierUuid}/quota-requests/${quotaRequestUuid}/reject`);

const approveTierQuota = (tierUuid, quotaRequestUuid) =>
  http.post(`tiers/${tierUuid}/quota-requests/${quotaRequestUuid}/approve`);

export default {
  addTierQuota,
  rejectTierQuota,
  approveTierQuota,
  getQuotaRequests,
  getQuota,
};
