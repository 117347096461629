import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { useTranslation } from 'react-i18next';

import { dateConverter, dateIsExceeded, isDueDateWasExceeded } from 'utils';

function IssueDates({ startDate, dueDate, closedAt, milestoneEndDate, rightContent }) {
  const { t } = useTranslation();
  const styles = `d-flex flex-row text-nowrap mr-1${rightContent ? ' float-right' : ''}`;
  const messageDueDateExceeded = t('pages.systemIssuesPage.expiredEndDate');
  const messageDueDateAfterMilestone = t(
    'pages.systemIssuesPage.issueFinishedAfterMilestoneEndDat'
  );

  return (
    <div className={styles}>
      {dateIsExceeded(dueDate) && !closedAt && isDueDateWasExceeded(dueDate, startDate) ? (
        <>
          <span className="text-red">{dateConverter(dueDate)}</span>
          <Tooltip content={messageDueDateExceeded} exitDelay={150} animationDuration={150}>
            <ExclamationCircleIcon className="ml-2 text-red" />
          </Tooltip>
        </>
      ) : (
        <>
          <>{dateConverter(dueDate)}</>
          {dueDate &&
            milestoneEndDate &&
            !closedAt &&
            isDueDateWasExceeded(dueDate, milestoneEndDate) && (
              <Tooltip
                content={messageDueDateAfterMilestone}
                exitDelay={150}
                animationDuration={150}
              >
                <ExclamationCircleIcon className="ml-2 text-blu" />
              </Tooltip>
            )}
        </>
      )}
    </div>
  );
}

export default IssueDates;
