import styled from 'styled-components';

export const BlockWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columnsCount }) => columnsCount || 1}, 1fr);
  grid-column-gap: 16px;
  max-width: 800px;
  margin-bottom: 19px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 64px;
`;
