import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/Dropdown';

function RepositoryActions({ repository, deleteRepositoryModal, editRepositoryModal }) {
  const { t, i18n } = useTranslation();

  const onFilterChange = id => {
    if (id === 'edit') {
      editRepositoryModal.setModalData(repository);
      return editRepositoryModal.toggleModal();
    }

    deleteRepositoryModal.setModalData(repository);
    deleteRepositoryModal.toggleModal();
  };

  const optionData = useMemo(
    () => [
      {
        id: 'edit',
        name: t('common.edit'),
      },
      {
        id: 'delete',
        name: t('common.delete'),
      },
    ],
    [repository, i18n.language]
  );

  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={repository?.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding
          buttonLabel={`${t('pages.systemServicePage.servicesImport', [repository?.name])}`}
        />
      )}
    </div>
  );
}

export default RepositoryActions;
