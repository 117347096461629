export const releaseActions = {
  new: 'new',
  edit: 'edit',
  approve: 'approve',
  deploy: 'deploy',
  deployToDev: 'deploy-to-dev',
  view: 'view',
};

export const systemReleasesPaths = {
  new: `/:action(${releaseActions.new})`,
  edit: `/:releaseId/:action(${releaseActions.edit})`,
  change: `/:releaseId/:action(${releaseActions.approve}|${releaseActions.deploy}|${releaseActions.deployToDev}|${releaseActions.view})`,
  releasesDeployed: `/:releaseId/release-deployed`,
};
