import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .pf-v5-c-tab-content {
    flex: 1;
  }
`;
