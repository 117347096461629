import styled from 'styled-components';
import { Dropdown, DropdownItem, MenuToggle } from '@patternfly/react-core';

export const DropdownStyled = styled(Dropdown)`
  .pf-v5-c-menu__content {
    min-width: 255px !important;
  }

  ${({ $withoutMarginLeft }) =>
    $withoutMarginLeft &&
    `
    margin-left: 0;
  `}
`;

export const StyledMenuToggle = styled(MenuToggle)`
  margin-left: 16px;

  ${({ $withoutPadding }) => $withoutPadding && 'padding: 0;'}

  ${({ $isBlueButtonDropdown }) =>
    $isBlueButtonDropdown &&
    `
    color: #fff;
    min-width: 140px;
    justify-content: flex-start;
    height: 36px !important;
  `}

  ${({ isDisabled, $isBlueButtonDropdown }) =>
    isDisabled &&
    $isBlueButtonDropdown &&
    `
    background: #d2d2d2 !important;
    border-color: #d2d2d2 !important;
    color: #6A6E73 !important;
    pointer-events: none;
  `}

  ${({ $withoutMarginLeft }) =>
    $withoutMarginLeft &&
    `
    width: auto;
    margin: 0;
  `}
`;

export const DropdownItemStyled = styled(DropdownItem)`
  .pf-v5-c-menu__item-text {
    white-space: normal;
  }
`;
