import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const staticAnalysisResultsSlice = createSlice({
  name: 'staticAnalysisResults',
  initialState: {
    staticAnalysisIssues: { data: [], total: 0, isLoading: true, isError: null },
    staticAnalysisHotspots: { data: [], total: 0, isLoading: true, isError: null },
  },
  reducers: {
    clearStateAnalysisIssues: state => {
      state.staticAnalysisIssues.data = [];
      state.staticAnalysisIssues.total = 0;
      state.staticAnalysisIssues.isLoading = true;
      state.staticAnalysisIssues.isError = null;
    },
    clearStateAnalysisHotspots: state => {
      state.staticAnalysisHotspots.data = [];
      state.staticAnalysisHotspots.total = 0;
      state.staticAnalysisHotspots.isLoading = true;
      state.staticAnalysisHotspots.isError = null;
    },
  },
  extraReducers: {
    [actions.fetchStaticAnalysisResultsIssues.pending]: state => {
      state.staticAnalysisIssues.isLoading = true;
      state.staticAnalysisIssues.isError = null;
    },
    [actions.fetchStaticAnalysisResultsIssues.fulfilled]: (state, { payload: { data, meta } }) => {
      state.staticAnalysisIssues.data = data;
      state.staticAnalysisIssues.isLoading = false;
      state.staticAnalysisIssues.total = meta?.total;
      state.staticAnalysisIssues.isError = null;
    },
    [actions.fetchStaticAnalysisResultsIssues.rejected]: (state, { error }) => {
      state.staticAnalysisIssues.isLoading = false;

      if (checkedServerError(error.message)) {
        state.staticAnalysisIssues.data = [];
        state.staticAnalysisIssues.total = 0;
        state.staticAnalysisIssues.isError = true;
      }
    },
    [actions.fetchStaticAnalysisResultsHotspots.pending]: state => {
      state.staticAnalysisHotspots.isLoading = true;
      state.staticAnalysisHotspots.isError = null;
    },
    [actions.fetchStaticAnalysisResultsHotspots.fulfilled]: (
      state,
      { payload: { data, meta } }
    ) => {
      state.staticAnalysisHotspots.data = data;
      state.staticAnalysisHotspots.isLoading = false;
      state.staticAnalysisHotspots.total = meta?.total;
      state.staticAnalysisHotspots.isError = null;
    },
    [actions.fetchStaticAnalysisResultsHotspots.rejected]: (state, { error }) => {
      state.staticAnalysisHotspots.isLoading = false;

      if (checkedServerError(error.message)) {
        state.staticAnalysisHotspots.data = [];
        state.staticAnalysisHotspots.total = 0;
        state.staticAnalysisHotspots.isError = true;
      }
    },
  },
});

export const { clearStateAnalysisIssues, clearStateAnalysisHotspots } =
  staticAnalysisResultsSlice.actions;

export default staticAnalysisResultsSlice.reducer;
