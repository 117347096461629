import React from 'react';
import { generatePath } from 'react-router';
import { Label, Tooltip } from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import { useTranslation } from 'react-i18next';

import StatusComponent, { getSystemStatuses } from 'components/Common/StatusComponent';
import paths from 'constants/paths';
import { systemTypesKeys, getSystemTypesNames } from 'constants';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

function SystemHeader({ system, isSystemList }) {
  const { t } = useTranslation();
  const systemStatuses = getSystemStatuses();
  const systemTypesNames = getSystemTypesNames();
  const style = `d-flex align-items-start ${!isSystemList ? 'w-50' : 'justify-content-between'}`;
  const isExternal = system?.type === systemTypesKeys.EXTERNAL;
  const content = <span className="font-12">{system?.name}</span>;

  return (
    <div className={style}>
      <div className="d-flex">
        {(isSystemList && (
          <LinkWithPermission
            system={system}
            scope={isExternal && scopes.platform.externalSystemViewForm}
            to={
              system
                ? generatePath(paths.routePaths.system, {
                    systemId: system.uuid,
                    systemType: system.type,
                  })
                : undefined
            }
            isTableLink
            isPlatformScope
          >
            <Tooltip content={content} exitDelay={1} animationDuration={1}>
              <div className="font-weight-semiBold font-16">{system?.shortName}</div>
            </Tooltip>
          </LinkWithPermission>
        )) || <span className="font-weight-medium font-24 hyphens">{system?.shortName}</span>}
      </div>

      <div className={`ml-2 ${!isSystemList && 'mt-1'}`} style={{ minHeight: '36px' }}>
        {isExternal ? (
          <Tooltip content={t('pages.home.externalTooltip')} exitDelay={1} animationDuration={1}>
            <Label color="purple" icon={<InfoCircleIcon />}>
              {systemTypesNames.EXTERNAL}
            </Label>
          </Tooltip>
        ) : (
          <StatusComponent
            statusId={system?.status}
            statuses={systemStatuses}
            objectUuid={system?.uuid}
            objectType="SYSTEM"
            isSystemList={isSystemList}
            isSystemDetails={!isSystemList}
          />
        )}
      </div>
    </div>
  );
}

export default SystemHeader;
