import React, { useMemo, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import { getMilestoneStatusNames } from 'constants';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';
import paths from 'constants/paths';
import { getNotHaveContratsMessage, getProgressSystemMessage } from 'constants/tooltips';

import MilestonesTable from './MilestonesTable';

function MilestonePage({ withSystemHeader = true }) {
  const { t, i18n } = useTranslation();
  const notHaveContratsMessage = getNotHaveContratsMessage();
  const milestoneStatusNames = getMilestoneStatusNames();
  const { checkedPermissions } = useUserPermissions();
  const {
    params: { systemId },
  } = useRouteMatch();

  const currentSystem = useSelector(currentSystemSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const isCanChangeForm = checkedPermissions(scopes.milestone.milestoneCreate) && systemId;

  const contractsOptions = currentSystem?.contracts
    ? currentSystem?.contracts.map(contract => ({
        id: contract.uuid,
        name: `№${contract.number}`,
        group: t('common.contract'),
      }))
    : [];

  const filterData = useMemo(
    () => [
      {
        id: 'q',
        name: t('common.name'),
      },
      {
        id: 'contract',
        name: t('common.contract'),
        type: 'multiSelect',
        options: contractsOptions,
        isHidden: !contractsOptions.length,
      },
      {
        id: 'status',
        name: t('common.status'),
        type: 'multiSelect',
        options: formatFilterOptions(milestoneStatusNames),
      },
      {
        id: 'date',
        name: t('common.updatedAt'),
        type: 'date',
      },
    ],
    [contractsOptions]
  );

  const milestonesTableFilters = useMultiSelectFilterSection(filterData, 'milestones');

  const disabledMessage = useMemo(
    () => notActiveSystemMessage || (!currentSystem?.contracts?.length && notHaveContratsMessage),
    [systemId, notActiveSystemMessage, currentSystem?.contracts, i18n.language]
  );

  const addMilestoneButton = isCanChangeForm && (
    <Tooltip
      className={disabledMessage ? undefined : 'd-none'}
      content={disabledMessage}
      exitDelay={150}
      animationDuration={150}
    >
      <div>
        <BlueButton
          variant="primary"
          isDisabled={disabledMessage}
          component={props => (
            <Link {...props} to={generatePath(paths.systemMilestonesFullPaths.new)} />
          )}
        >
          {t('common.add')}
        </BlueButton>
      </div>
    </Tooltip>
  );
  const leftSideContent = isShowFilters && <>{addMilestoneButton}</>;

  return (
    <PageLayout
      systemLayout
      withSystemHeader={withSystemHeader}
      withSystemSelector
      title={t('common.milestones')}
      leftSideContent={leftSideContent}
    >
      <MilestonesTable
        addButton={addMilestoneButton}
        tableFilters={milestonesTableFilters}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        currentTotal={currentSystem?.uuid && currentSystem?.totalSummary?.milestoneTotal?.total}
      />
    </PageLayout>
  );
}

export default MilestonePage;
