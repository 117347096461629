import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabTitleText } from '@patternfly/react-core';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useCardTabs from 'hooks/useCardTabs';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { getBackupStatusNames } from 'constants';
import PageLayout from 'components/UI/PageLayout';
import EditFrequency from 'pages/SystemRouter/SystemTiersRouter/CurrentTierPage/TierBackups/BackupTable/EditFrequency';
import TierComponentBackupTable from 'pages/SystemRouter/SystemTiersRouter/TierComponentBackupPage/TierComponentBackupTable';

const tableName = 'administrationComponent';

function ComponentBackupPage() {
  const { t } = useTranslation();
  const backupStatusNames = getBackupStatusNames();
  const history = useHistory();
  const {
    params: { componentName, componentId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs({ numberOfTabs: 1 });
  const { run, data, isLoading, isSuccess, isRejected, isError } = useAsync();
  const currentComponent = useAsync();
  const filterData = [
    {
      id: 'comment',
      name: t('common.comment'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(backupStatusNames),
    },
    {
      id: 'date',
      name: t('common.date'),
      type: 'date',
    },
  ];
  const { state } = history.location;
  const [historyState] = useState(state);

  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    tableName,
    historyState?.date ? historyState : null
  );

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'backupEndDateTime',
      ascending: false,
      componentName,
      dateTimeFrom: historyState?.date?.startDate || filterParams?.date?.startDate,
      dateTimeTo: historyState?.date?.endDate || filterParams?.date?.endDate,
      date: undefined,
    }),
    [componentName, filterParams, historyState]
  );

  const requestFunction = params => run(api.backups.getAllBackups(params));
  const requestComponentFunction = () =>
    currentComponent.run(api.backups.getBackupComponent(componentId));

  useEffect(() => {
    requestComponentFunction();
  }, []);

  useEffect(() => {
    if (historyState?.date) {
      history.replace({ state: { date: null } });
    }
  }, [historyState?.date]);

  const leftSideContent = (
    <div className="d-flex flex-row px-4 pb-3">
      <span className="mr-1">{t('common.frequency')}</span>
      <EditFrequency
        {...currentComponent.data?.frequency}
        componentUuid={currentComponent.data?.uuid}
        requestFunction={requestComponentFunction}
        isAdministration
      />
    </div>
  );

  return (
    <PageLayout
      title={`${t('common.component')} ${componentName}`}
      withoutSidePadding
      isError={currentComponent.isError}
    >
      {leftSideContent}
      <div className="h-100">
        <Tabs
          className="px-4"
          activeKey={currentTab}
          onSelect={onSelectTab}
          aria-label={`${t('common.component')} ${componentName}`}
        >
          <Tab eventKey={0} title={<TabTitleText>{t('common.review')}</TabTitleText>}>
            <div className="d-sm-flex flex-row mx-4 mt-4">{FilterComponent}</div>
            <TierComponentBackupTable
              requestParams={requestParams}
              data={data}
              requestFunction={requestFunction}
              isLoading={isSuccess || isRejected ? isLoading : true}
              hasSelectedFilters={hasSelectedFilters}
              tableName={tableName}
              isError={isError}
            />
          </Tab>
        </Tabs>
      </div>
    </PageLayout>
  );
}

export default ComponentBackupPage;
