import styled from 'styled-components';
import WordSVG from 'assets/images/document/word.svg';
import ExcelSVG from 'assets/images/document/excel.svg';
import PdfSVG from 'assets/images/document/icon-pdf.svg';
import DocumentSVG from 'assets/images/document/document.svg';

const imageExtension = ({ extension }) => {
  switch (extension) {
    case 'docx':
    case 'doc':
      return `${WordSVG}`;
    case 'xlsx':
    case 'xls':
      return `${ExcelSVG}`;
    case 'pdf':
      return `${PdfSVG}`;
    default:
      return `${DocumentSVG}`;
  }
};

export const DocumentIcon = styled.div`
  background: url(${imageExtension}) no-repeat;
  background-size: contain;
  min-width: 21px;
  min-height: 21px;
  margin-right: 8px;
  align-self: center;
`;
