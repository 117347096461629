import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  MenuSearch,
  MenuSearchInput,
  Divider,
  InputGroup,
  InputGroupItem,
  SearchInput,
  DropdownList,
  DropdownItem,
  Truncate,
  Tooltip,
} from '@patternfly/react-core';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import { isBoolean } from 'lodash';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useStorage from 'hooks/useStorage';
import useURLQueryParams from 'hooks/Location/useURLQueryParams';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchSystemsOptions } from 'store/systems/actions';
import {
  currentSystemLoadingSelector,
  currentSystemSelector,
  systemsOptionsIsLoadingSelector,
  systemsOptionsSelector,
} from 'store/systems/selectors';
import { changeSystemsFilter, resetCurrentSystem } from 'store/systems/reducers';
import { systemTypesKeys, systemStatusKeys, socketActionKeys } from 'constants';
import paths from 'constants/paths';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import LoadingIndicator from 'components/UI/LoadingIndicator';

import StatusComponent, { getSystemStatuses } from '../StatusComponent';
import useInfinitePagination from '../DataTable/hooks/useInfinitePagination';

import * as S from './styles';

const systemPathKeys = [
  paths.systemPaths.tiers,
  paths.systemPaths.integrations,
  paths.systemPaths.serviceVersions,
  paths.systemPaths.services,
  paths.systemPaths.releases,
  paths.systemPaths.deployments,
  paths.systemPaths.milestones,
  paths.systemPaths.issues,
  paths.systemPaths.users,
  paths.systemPaths.documents,
];

const observerPlaceOffset = 4;

function ContextSelectorSystem() {
  const { t, i18n } = useTranslation();
  const systemStatuses = getSystemStatuses();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    params: {
      systemType,
      systemId,
      serviceId,
      userId,
      tierId,
      releaseId,
      issuesId,
      milestoneId,
      integrationId,
      serviceVersionId,
    },
  } = useRouteMatch();
  const { clearState } = useURLQueryParams();
  const { checkedPermissions } = useUserPermissions();
  const menuRef = useRef(null);

  const currentSystem = useSelector(currentSystemSelector);
  const systemOptions = useSelector(systemsOptionsSelector);
  const isLoadingSystem = useSelector(currentSystemLoadingSelector);
  const systemsOptionsIsLoading = useSelector(systemsOptionsIsLoadingSelector);
  const systemFilterOption = useStorage('systemFilterOption');
  const { setToStorage } = useStorage('currentSystem');

  const [isOpen, setOpen] = useState(false);
  const [isActual, setIsActual] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedOption, setSelectedOption] = useState();
  const [isOptions, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(systemFilterOption.storageData?.q || '');
  const [isActive, setIsActive] = useState(
    isBoolean(systemOptions.onlyWithPermission)
      ? systemOptions.onlyWithPermission
      : systemFilterOption.storageData?.defaultSwitch
  );

  const allSystems = isActive ? t('components.systemSelect.my') : t('components.systemSelect.all');
  const path = location.pathname;
  const isAddPath =
    path.includes(`/${paths.releaseActions.new}`) ||
    path.includes(`/${paths.releaseActions.edit}`) ||
    path.includes(`${paths.systemServicesPaths.importServices}`);
  const systemPath = systemPathKeys.find(item => path.includes(item));
  const total = systemOptions?.total;
  const rowsCount = isOptions?.length;
  const isCantSeeAllSystems = !checkedPermissions(scopes.platform.systemViewSection, true);

  const requestParams = useMemo(
    () => ({
      type: isCantSeeAllSystems && isActive ? systemTypesKeys.INTERNAL_WITH_PERMISSION : undefined,
      ascending: true,
      orderBy: 'shortName',
      status: isCantSeeAllSystems
        ? [
            systemStatusKeys.ACTIVE,
            systemStatusKeys.NEW,
            systemStatusKeys.UPDATING_IN_PROGRESS,
            systemStatusKeys.ERROR,
          ]
        : undefined,
      q: searchValue,
      limit: 10,
      isConcatData: false,
    }),
    [isActive, searchValue, isCantSeeAllSystems]
  );
  const requestFunction = requestParameters => {
    setIsActual(true);
    dispatch(fetchSystemsOptions(requestParameters));
  };

  const { ref: paginationRef } = useInfinitePagination({
    rowsCount,
    withInfinitePagination: rowsCount > 0,
    loadMore: () => {
      requestFunction({ ...requestParams, offset: rowsCount, isConcatData: true });
    },
    total,
  });

  const allSystemOptions = useMemo(
    () =>
      generateSelectOptions(systemOptions?.data, null, null, null, {
        getLabel: item => item.shortName,
        getValue: item => item.uuid,
        getStatus: item => item.status,
        getDescription: item => item.type,
        getDisabled: item =>
          item.type === systemTypesKeys.EXTERNAL &&
          !checkedPermissions(scopes.platform.externalSystemViewForm, true),
      }),
    [systemOptions?.data, systemId]
  );

  useSocketAsync({
    topic: 'systems',
    debounceTime: 600,
    options: { callBackThrottleTime: 2000 },
    filterFn: ({ object, action }) =>
      action === socketActionKeys.CREATED ||
      systemOptions.data?.find(system => system.uuid === object?.uuid),
    onMessage: () => {
      if (isOpen) {
        requestFunction({ ...requestParams, limit: rowsCount });
      } else {
        setIsActual(false);
      }
    },
  });

  useEffect(() => {
    if (systemType === systemTypesKeys.EXTERNAL) {
      setIsActive(false);
      systemFilterOption.setToStorage({ defaultSwitch: false, q: searchValue });
    }
  }, [systemType]);

  useEffect(() => {
    setOptions(allSystemOptions);
  }, [allSystemOptions]);

  useEffect(() => {
    if (isOpen) {
      requestFunction(requestParams);
      systemFilterOption.setToStorage({ defaultSwitch: isActive, q: searchValue });
    }
  }, [searchValue, currentSystem]);

  const selectorItem = option =>
    option?.status ? (
      <StatusComponent
        statusId={
          option.type === systemTypesKeys.EXTERNAL ||
          option.description === systemTypesKeys.EXTERNAL
            ? systemTypesKeys.EXTERNAL
            : option.status
        }
        statusWithTooltip
        statuses={systemStatuses}
      >
        <S.ShortNameWrapper>{option?.label || option?.shortName}</S.ShortNameWrapper>
      </StatusComponent>
    ) : (
      <span className="font-16 mr-3">{allSystems}</span>
    );

  useEffect(() => {
    if (
      !selected ||
      selected?.props.children === allSystems ||
      (currentSystem?.uuid !== systemId && !isLoadingSystem)
    ) {
      if (systemId && systemId === currentSystem?.uuid) {
        setSelected(selectorItem(currentSystem));
      } else if (!systemId) {
        setSelected(selectorItem());
      }
    }
  }, [isLoadingSystem, currentSystem, allSystems, systemId, i18n.language]);

  useEffect(() => {
    if (selected && currentSystem?.status && currentSystem?.uuid === systemId && !isLoadingSystem) {
      setSelected(selectorItem(currentSystem));
    }
  }, [currentSystem?.status, i18n.language]);

  const onToggle = open => {
    setOpen(!open);
    if ((!systemOptions.data.length || !isActual) && !open) {
      requestFunction(requestParams);
    }
  };

  useEffect(() => {
    if (selected && !selected?.props?.statusId) {
      setSelected(selectorItem(selectedOption));
    }
  }, [i18n.language]);

  const onSetSelected = option => {
    if (option) {
      setSelected(selectorItem(option));
    }

    if (!option) {
      const selectItem = selectorItem();
      setSelected(selectItem);
    }
  };

  const onSelect = option => {
    setSelectedOption(option);
    onSetSelected(option);
    setOpen(false);
  };
  const onSearchInputChange = value => {
    setSearchValue(value);
  };

  const generateCurrentPath = (route, id, type) =>
    generatePath(route, {
      systemId: id,
      systemType: type,
    });

  const onToggleIsActive = value => {
    dispatch(changeSystemsFilter(!value));
    systemFilterOption.setToStorage({ defaultSwitch: !value, q: searchValue });
    requestFunction({
      ...requestParams,
      type: !value ? systemTypesKeys.INTERNAL_WITH_PERMISSION : undefined,
    });
    setIsActive(!value);
  };

  useEffect(() => {
    if (isCantSeeAllSystems && isActive === undefined) {
      onToggleIsActive(!isCantSeeAllSystems);
    }
  }, []);

  const generateLink = option => {
    if (option?.value && !option.disabled) {
      if (option.description === systemTypesKeys.INTERNAL && systemId) {
        if (location.pathname.includes('documents/new')) {
          return generateCurrentPath(
            paths.systemFullPaths.documents,
            option.value,
            option.description
          );
        }

        if (integrationId) {
          return generateCurrentPath(
            paths.systemFullPaths.integrations,
            option.value,
            option.description
          );
        }
        if (issuesId) {
          return generateCurrentPath(
            paths.systemFullPaths.issues,
            option.value,
            option.description
          );
        }
        if (milestoneId) {
          return generateCurrentPath(
            paths.systemFullPaths.milestones,
            option.value,
            option.description
          );
        }
        if (releaseId) {
          return generateCurrentPath(
            paths.systemFullPaths.releases,
            option.value,
            option.description
          );
        }
        if (tierId) {
          return generateCurrentPath(paths.systemFullPaths.tiers, option.value, option.description);
        }
        if (userId) {
          return generateCurrentPath(paths.systemFullPaths.users, option.value, option.description);
        }
        if (serviceId) {
          return generateCurrentPath(
            paths.systemFullPaths.services,
            option.value,
            option.description
          );
        }
        if (isAddPath) {
          return generatePath(paths.routePaths.system, {
            systemId: option.value,
            systemType: option.description,
          });
        }
        if (serviceVersionId) {
          return generateCurrentPath(
            paths.systemFullPaths.serviceVersions,
            option.value,
            option.description
          );
        }

        return location.pathname.replace(systemId, option.value);
      }

      if (option.description === systemTypesKeys.EXTERNAL && systemId) {
        return generatePath(paths.routePaths.system, {
          systemId: option.value,
          systemType: option.description,
        });
      }

      if (!systemId && option.value) {
        const systemPathKey = Object.keys(paths.allSystemFullPaths).find(key =>
          paths.allSystemFullPaths[key].includes(systemPath)
        );

        return generatePath(
          systemPathKey ? paths.systemFullPaths[systemPathKey] : paths.routePaths.system,
          {
            systemId: option.value,
            systemType: option.description,
          }
        );
      }
    }

    return undefined;
  };

  const optionSelect = (e, option) => {
    if (option?.value && !option.disabled) {
      if (e.ctrlKey || e.metaKey) {
        return;
      }
      onSelect(option);
      setToStorage(option);
    }

    if (!option) {
      onSelect();
      setToStorage({
        shortName: '',
        type: paths.systemTypes.internal,
        uuid: '',
      });
      const toMainPage = systemPath === undefined;
      const systemPathKey = Object.keys(paths.allSystemFullPaths).find(key =>
        paths.allSystemFullPaths[key].includes(systemPath)
      );

      dispatch(resetCurrentSystem());

      history.push(
        generateCurrentPath(
          systemPathKey && !toMainPage
            ? paths.allSystemFullPaths[systemPathKey]
            : paths.routePaths.main
        )
      );
    }

    clearState();
  };

  const switchDefalultSystem = (
    <S.StyledSwitch
      id="devTier"
      label={t('components.systemSelect.shomMy')}
      labelOff={t('components.systemSelect.shomAll')}
      isChecked={isActive}
      isReversed
      onClick={() => onToggleIsActive(isActive)}
    />
  );

  const optionsData = useMemo(() => {
    const data = (
      <>
        {isOptions.map(option => (
          <>
            {option.disabled ? (
              <div className="d-flex flex-column align-items-start w-100 cursor-default py-2 px-3">
                <StatusComponent
                  statusId={
                    option.description === systemTypesKeys.EXTERNAL
                      ? systemTypesKeys.EXTERNAL
                      : option.status
                  }
                  statusWithTooltip
                  statuses={systemStatuses}
                >
                  <div className="d-flex flex-column text-word-break-wrap">
                    {option?.label?.length >= 25 ? (
                      <Truncate className="font-16" content={option?.label} />
                    ) : (
                      <span className="font-16 mr-3">{option?.label}</span>
                    )}
                  </div>
                </StatusComponent>
              </div>
            ) : (
              <div key={option?.value || uuid()}>
                <Link className="w-100" key={option?.value} to={() => generateLink(option)}>
                  <DropdownItem key={option?.value} onClick={e => optionSelect(e, option)}>
                    <div className="d-flex flex-column align-items-start w-100">
                      <div className="d-flex flex-row justify-content-between text-blu w-100">
                        <StatusComponent
                          statusId={
                            option.description === systemTypesKeys.EXTERNAL
                              ? systemTypesKeys.EXTERNAL
                              : option.status
                          }
                          statusWithTooltip
                          statuses={systemStatuses}
                        >
                          <div className="d-flex flex-column text-word-break-wrap">
                            {option?.label?.length >= 25 ? (
                              <Truncate className="font-16" content={option?.label} />
                            ) : (
                              <span className="font-16 mr-3">{option?.label}</span>
                            )}
                          </div>
                        </StatusComponent>
                        {systemId === option?.value && currentSystem?.uuid && (
                          <div>
                            <CheckIcon className="font-12 text-blu" />
                          </div>
                        )}
                      </div>
                    </div>
                  </DropdownItem>
                </Link>
              </div>
            )}
          </>
        ))}
        {rowsCount > observerPlaceOffset && <div ref={paginationRef} />}
      </>
    );

    return (
      <>
        {isCantSeeAllSystems && switchDefalultSystem}
        <DropdownItem className="font-16" onClick={e => optionSelect(e, null)}>
          <div className="d-flex flex-row justify-content-between w-100 ">
            {allSystems}
            {!systemId && !currentSystem?.uuid && (
              <div>
                <CheckIcon className="font-12 text-blu" />
              </div>
            )}
          </div>
        </DropdownItem>
        {!isOptions?.length && searchValue ? (
          <div className="w-100 py-2 px-3">
            <DropdownItem className="font-16" isDisabled>
              {t('common.noResults')}
            </DropdownItem>
          </div>
        ) : (
          data
        )}
      </>
    );
  }, [isOptions, searchValue, isActive, currentSystem, i18n.language]);

  const isTooltip = select => select && select.length >= 25;

  return (
    <div className="d-flex flex-row">
      {t('common.system')}:
      <Tooltip
        key={currentSystem?.uuid}
        content={isTooltip(currentSystem?.shortName) ? currentSystem?.shortName : null}
        className={isTooltip(currentSystem?.shortName) ? undefined : 'd-none'}
        position="right"
        exitDelay={150}
        animationDuration={150}
      >
        <S.StyledDropdown
          isOpen={isOpen}
          onOpenChange={open => setOpen(open)}
          onOpenChangeKeys={['Escape']}
          toggle={toggleRef => (
            <S.StyledMenuToggle
              ref={toggleRef}
              variant="plainText"
              onClick={() => onToggle(isOpen)}
              isExpanded={isOpen}
            >
              {selected}
            </S.StyledMenuToggle>
          )}
          ref={menuRef}
          id="context-selector"
          isScrollable
          zIndex="2"
        >
          <MenuSearch>
            <MenuSearchInput>
              <InputGroup>
                <InputGroupItem isFill>
                  <SearchInput
                    value={searchValue}
                    onChange={(_event, value) => onSearchInputChange(value)}
                    aria-labelledby="pf-v5-context-selector-search-button-id-1"
                    placeholder={t('components.filter.search')}
                  />
                </InputGroupItem>
              </InputGroup>
            </MenuSearchInput>
          </MenuSearch>
          <Divider />
          <DropdownList>{optionsData}</DropdownList>
        </S.StyledDropdown>
      </Tooltip>
      <div className="position-relative">
        {systemsOptionsIsLoading && (
          <S.SpinnerWrapper>
            <LoadingIndicator small />
          </S.SpinnerWrapper>
        )}
      </div>
    </div>
  );
}

export default ContextSelectorSystem;
