import { generateFullPaths } from 'utils';

import { adminDictionariesPaths } from './adminDictionariesPaths';
import { adminUsersPaths, usersActions } from './adminUsersPaths';
import { adminPaths } from './adminPaths';
import { newSystemPaths, systemTypes } from './newSystemPaths';
import { routePaths } from './routePaths';
import { serverTiersActions, serverTiersPaths } from './serverTiersPaths';
import { systemIntegrationsPaths, integrationsAction } from './systemIntegrationsPaths';
import { systemPaths } from './systemPaths';
import { releaseActions, systemReleasesPaths } from './systemReleasesPaths';
import { systemServicesPaths } from './systemServicesPaths';
import { systemIssuesPaths, issuesAction } from './systemIssuesPaths';
import { systemTierPaths, systemTiersAction } from './systemTierPaths';
import { tierComponentsPaths } from './tierComponentsPaths';
import { systemMilestonesPaths, milestonesAction } from './systemMilestonesPaths';
import { systemServiceVersionsPaths } from './systemServiceVersionsPaths';
import { systemDocumentsPaths } from './systemDocumentsPaths';

const systemFullPaths = generateFullPaths(systemPaths, routePaths.system);

const allSystemFullPaths = generateFullPaths(systemPaths, routePaths.systems);

export default {
  routePaths,

  newSystemPaths,
  newSystemFullPaths: generateFullPaths(newSystemPaths, routePaths.newSystem),

  adminPaths,
  adminFullPaths: generateFullPaths(adminPaths, routePaths.admin),

  serverTiersActions,
  serverTiersPaths,

  systemTypes,
  systemPaths,
  systemFullPaths,
  allSystemFullPaths,

  systemServicesPaths,
  systemServicesFullPaths: generateFullPaths(systemServicesPaths, systemPaths.services),

  releaseActions,
  systemReleasesPaths,
  systemReleasesFullPaths: generateFullPaths(systemReleasesPaths, systemPaths.releases),

  systemServiceVersionsPaths,
  systemServiceVersionsFullPaths: generateFullPaths(
    systemServiceVersionsPaths,
    systemFullPaths.serviceVersions
  ),

  systemTiersAction,
  systemTierPaths,
  systemTierFullPaths: generateFullPaths(systemTierPaths, systemFullPaths.tier),
  systemTiersFullPaths: generateFullPaths(systemTierPaths, systemFullPaths.tiers),

  integrationsAction,
  systemIntegrationsPaths,
  systemIntegrationsFullPaths: generateFullPaths(
    systemIntegrationsPaths,
    systemFullPaths.integrations
  ),

  adminDictionariesPaths,
  usersActions,
  adminUsersPaths,

  tierComponentsPaths,

  milestonesAction,
  systemMilestonesPaths,
  systemMilestonesFullPaths: generateFullPaths(systemMilestonesPaths, systemPaths.milestones),
  systemMilestoneFullPaths: generateFullPaths(systemMilestonesPaths, systemPaths.milestone),

  issuesAction,
  systemIssuesPaths,
  systemIssuesFullPaths: generateFullPaths(systemIssuesPaths, systemPaths.issues),
  systemIssueFullPaths: generateFullPaths(systemIssuesPaths, systemPaths.issue),

  systemDocumentsPaths,
  systemDocumentsFullPaths: generateFullPaths(systemDocumentsPaths, systemPaths.documents),
};
