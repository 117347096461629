import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';
import paths from 'constants/paths';

import DocumentsTable from './DocumentsTable';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

function DocumentsPage({ withSystemHeader = true }) {
  const { t, i18n } = useTranslation();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentSystem = useSelector(currentSystemSelector);
  const isCanChangeForm = systemId && checkedPermissions(scopes.document.documentAdd);

  const [isShowFilters, setIsShowFilters] = useState(false);

  const addDocumentsButton = (
    <>
      {isCanChangeForm && (
        <Tooltip
          className={notActiveSystemMessage ? undefined : 'd-none'}
          content={notActiveSystemMessage}
          exitDelay={150}
          animationDuration={150}
        >
          <div>
            <BlueButton
              variant="primary"
              $marginleftv
              isDisabled={notActiveSystemMessage}
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemDocumentsFullPaths.new, {
                    systemId,
                    systemType,
                  })}
                />
              )}
            >
              {t('common.add')}
            </BlueButton>
          </div>
        </Tooltip>
      )}
    </>
  );

  const leftSideContent = isShowFilters && <>{addDocumentsButton}</>;

  return (
    <PageLayout
      title={t('common.documents')}
      leftSideContent={leftSideContent}
      systemLayout
      withSystemHeader={withSystemHeader}
      withSystemSelector
    >
      <DocumentsTable
        addButton={addDocumentsButton}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        currentTotal={currentSystem?.uuid && currentSystem?.totalSummary?.documentTotal?.total}
      />
    </PageLayout>
  );
}

export default DocumentsPage;
