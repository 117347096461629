import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/Dropdown';
import { MenuToggleActionStyled } from 'pages/SystemRouter/SystemReleasesRouter/AddReleasePage/styles';

function RepositoriesMultipleActions({ massEditChange, isDisabled }) {
  const { t, i18n } = useTranslation();

  const onFilterChange = () => massEditChange();

  const optionData = useMemo(
    () => [
      {
        id: 'delete',
        name: t('common.massDelete'),
      },
    ],
    [i18n.language]
  );

  return (
    <div className="float-right align-content-end mb-3 ml-3" style={{ height: '45px' }}>
      <Dropdown
        optionData={optionData}
        onFilterChange={onFilterChange}
        isBlueButtonDropdown
        positionRight
        withoutMarginLeft
        isDisabled={isDisabled}
        splitButtonOptions={
          <MenuToggleActionStyled
            key="split-action-primary"
            onClick={() => massEditChange(true)}
            isDisabled={isDisabled}
            className="px-3 text-wrap-nowrap"
          >
            {t('pages.systemServicesPage.massEdit')}
          </MenuToggleActionStyled>
        }
      />
    </div>
  );
}

export default RepositoriesMultipleActions;
