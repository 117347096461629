import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUserDescription } from 'utils';
import api from 'api';
import useAsync from 'hooks/useAsync';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import uuid from 'react-uuid';

function ContactUserSelect({
  fieldName = 'user',
  disabled = false,
  inputDescription,
  dynamicParentField,
  index,
  label,
}) {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { setValue, watch, clearErrors } = useFormContext();
  const { run, data } = useAsync();
  const getUser = useAsync();

  const fieldValue = watch(fieldName);
  const systemContactPerson = watch(`systemContactPersons[${index}]`);
  const isContactValue = watch(`${dynamicParentField}[${index}]`);
  const fullName = watch(`${dynamicParentField}[${index}].fullName`);
  const phone = watch(`${dynamicParentField}[${index}].phone`);
  const contactsValue = watch(`${dynamicParentField}`);
  const selectedOption = watch(`${dynamicParentField}[${index}].selectedOption`);
  const userOptions = watch(`${dynamicParentField}[${index}].userOptions`);
  const [currentSearch, setCurrentSearch] = useState(isContactValue?.fullName || '');

  const isSearchLongEnough = currentSearch?.length && currentSearch?.trim().length >= 3;

  useEffect(() => {
    if (systemId && systemContactPerson) {
      setValue(`${dynamicParentField}[${index}].uuid`, systemContactPerson.uuid);
      setCurrentSearch(systemContactPerson.fullName);

      setValue(`systemContactPersons[${index}]`, undefined);
    }
  }, []);

  useEffect(() => {
    const isClearErrors = contactsValue.find(contact => contact?.phone !== 18 || !fullName);
    if (isClearErrors) {
      clearErrors();
    }
  }, [phone, fullName]);

  useEffect(() => {
    if (getUser.data) {
      setValue(
        `${dynamicParentField}[${index}].userOptions`,
        userOptions?.length ? userOptions : [getUser.data]
      );
    }
  }, [getUser.data]);

  useEffect(() => {
    setValue(`${dynamicParentField}[${index}].userOptions`, data?.data);
  }, [data?.data, fieldValue]);

  useEffect(() => {
    if (fieldValue && data?.data?.length && !currentSearch) {
      setCurrentSearch(data.data.find(item => item.uuid === selectedOption?.uuid)?.fullName);
    }
  }, [fieldValue, data?.data]);

  useEffect(() => {
    if (selectedOption?.uuid) {
      setCurrentSearch(selectedOption?.fullName);
    }
  }, [selectedOption?.uuid]);

  useEffect(() => {
    if (isSearchLongEnough || (isSearchLongEnough && fieldValue?.length)) {
      run(api.users.getKeycloakUsers({ q: currentSearch }));
    }

    if (!fieldValue && currentSearch) {
      setValue(fieldName, currentSearch + uuid());
    }

    setValue(`${dynamicParentField}[${index}].fullName`, currentSearch);

    if (selectedOption?.uuid && currentSearch !== selectedOption?.fullName) {
      setValue(`${dynamicParentField}[${index}].selectedOption`, {});
      setValue(fieldName, currentSearch + uuid());
    }
  }, [currentSearch]);

  const selectCallback = (_, uuidValue) => {
    const currentOption = userOptions?.find(item => item.uuid === uuidValue);

    if (currentOption) {
      setValue(`${dynamicParentField}[${index}]`, {
        ...isContactValue,
        fullName: currentOption?.fullName,
        email: currentOption?.email || '',
        phone: currentOption?.phone || '',
        workplace: currentOption?.workplace || '',
      });

      setValue(`${dynamicParentField}[${index}].selectedOption`, currentOption);
    }

    setCurrentSearch(currentOption?.fullName || '');

    if (!uuidValue) {
      setValue(`${dynamicParentField}[${index}].selectedOption`, {});
    }
  };

  const getUsersOptions = useMemo(() => {
    const optionsData = data?.data?.filter(option => option?.enabled);
    const output = generateSelectOptions(optionsData, null, null, null, {
      getLabel: user => user.fullName || user.name || user.login,
      getValue: user => user.uuid || user.userUuid,
      getDescription: user => getUserDescription(user),
    });

    return output;
  }, [data?.data]);

  const noResultsText = isSearchLongEnough
    ? t('common.noResults')
    : t('common.insertSearchCriteria');

  const onTypeaheadInputChanged = (q, withRefreshSearch) => {
    if (withRefreshSearch && isSearchLongEnough) {
      run(api.users.getKeycloakUsers({ q }));
    }

    setCurrentSearch(q);
  };

  return (
    <InputComponent
      label={label || t('common.fio')}
      name={fieldName}
      component={inputComponents.newSelect}
      isLoading={getUser.isLoading}
      newSelectVariant="typeahead"
      onTypeaheadInputChanged={onTypeaheadInputChanged}
      noResultsText={noResultsText}
      placeholder={t('pages.addNewSystemPage.insertUserInfo')}
      isRequired
      options={getUsersOptions}
      disabled={disabled}
      checkedValue={isContactValue?.userUuid}
      descriptionMaxLength={100}
      inputDescription={inputDescription}
      currentSearch={currentSearch}
      selectCallback={selectCallback}
    />
  );
}

export default ContactUserSelect;
