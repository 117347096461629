import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useComponentVisible from 'hooks/useComponentVisible';
import * as rdrLocales from 'react-date-range/dist/locale';
import { getTimeRanges } from './utils';

import * as S from './styles';

const timeRange = getTimeRanges();

const AuditRangePicker = ({ saveDate, cancelDate, pickerDate, filterStyles, minDate, label }) => {
  const { i18n } = useTranslation();
  const { ref, isComponentVisible } = useComponentVisible(true);
  const [range, setRange] = useState([
    {
      startDate: 0,
      endDate: 0,
      key: 'selection',
    },
  ]);

  const [activeTimeIndex, setActiveTimeIndex] = useState();
  const [isChangedRange, setIsChangedRange] = useState(false);
  const locale = i18n.language.slice(0, 2) === 'ru' ? rdrLocales.ru : rdrLocales.enUS;
  const onChangeDate = ({ selection }) => {
    const date =
      new Date(range[0].startDate).getTime() === new Date(selection.startDate).getTime()
        ? selection.endDate
        : selection.startDate;

    setIsChangedRange(true);

    setRange([
      {
        startDate: date,
        endDate: date,
        key: 'selection',
      },
    ]);
  };

  useEffect(() => {
    setRange([
      {
        startDate: new Date(pickerDate),
        endDate: new Date(pickerDate),
        key: 'selection',
      },
    ]);
  }, []);

  useEffect(() => {
    if (range[0].startDate) {
      if (activeTimeIndex || activeTimeIndex === 0) {
        saveDate(
          moment(range[0].startDate.getTime())
            .startOf('day')
            .add(activeTimeIndex * 30, 'minutes')
        );
      }
    }
  }, [range[0].startDate, activeTimeIndex]);

  useEffect(() => {
    if (isChangedRange && activeTimeIndex === undefined) {
      setActiveTimeIndex(0);
    }
  }, [isChangedRange]);

  useEffect(() => {
    if (!isComponentVisible) {
      cancelDate();
      setIsChangedRange(false);
    }
  }, [isComponentVisible]);

  return (
    <S.OutsideHeaderWrapper ref={ref} filterStyles={filterStyles}>
      <S.OutsideHeader onClick={cancelDate} />
      <S.DatePickerWrapper>
        <S.AuditDatePicker
          locale={locale}
          onChange={onChangeDate}
          date={new Date()}
          maxDate={new Date()}
          minDate={minDate}
          ranges={range}
          months={1}
          showMonthAndYearPickers={false}
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="LLLL yyyy"
          direction="horizontal"
          rangeColors={['#0F69C9', '#cccccc', '#00ff00']}
          displayMode="date"
        />
        <S.TimesBlock>
          {timeRange.map((time, index) => (
            <S.Time
              key={time}
              onClick={() => setActiveTimeIndex(index, true)}
              active={
                activeTimeIndex === index ||
                (label && timeRange[index] === label?.split(',')[1]?.trim())
              }
            >
              {time}
            </S.Time>
          ))}
        </S.TimesBlock>
      </S.DatePickerWrapper>
    </S.OutsideHeaderWrapper>
  );
};

export default AuditRangePicker;
