import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import AngleRightIcon from '@patternfly/react-icons/dist/esm/icons/angle-right-icon';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchStaticAnalysisResultsHotspots } from 'store/staticAnalysisResults/actions';
import { serviceVersionsStaticAnalysisSelector } from 'store/serviceVersionsBuildResults/selectors';
import {
  buildResultTypeKeys,
  getBuildResultTypeNames,
  getVulnerabilityProbabilityStatusNames,
} from 'constants';
import paths from 'constants/paths';

import StaticAnalysisUnsafeAreasTable from './StaticAnalysisUnsafeAreasTable';
import StaticAnalysisBuildResults from '../StaticAnalysisBuildResults';

const tableName = 'staticAnalysisUnsafeAreas';

function StaticAnalysisUnsafeAreas() {
  const { t } = useTranslation();
  const buildResultTypeNames = getBuildResultTypeNames();
  const vulnerabilityProbabilityStatusNames = getVulnerabilityProbabilityStatusNames();
  const {
    params: { systemId, systemType, staticAnalysisId, serviceVersionId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { data } = useSelector(serviceVersionsStaticAnalysisSelector);
  const filterData = [
    {
      id: 'description',
      name: t('common.description'),
    },
    {
      id: 'vulnerabilityProbability',
      name: t('pages.systemServiceVersionPage.staticAnalysisProblems.vulnerabilityProbability'),
      type: 'multiSelect',
      options: formatFilterOptions(vulnerabilityProbabilityStatusNames),
    },
    {
      id: 'component',
      name: t('common.component'),
    },
  ];
  const { filterParams, hasSelectedFilters, FilterComponent } = useMultiSelectFilterSection(
    filterData,
    tableName
  );

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'vulnerabilityProbability',
      ascending: true,
      staticAnalysisResultUuid: data?.uuid,
    }),
    [filterParams, data?.uuid]
  );
  const requestFunction = params => {
    if (params?.staticAnalysisResultUuid) {
      dispatch(fetchStaticAnalysisResultsHotspots(params));
    }
  };

  return (
    <>
      <div className="px-4 pt-4 pb-3">
        <h2 className="font-20 font-weight-medium mb-4">
          <Link
            to={generatePath(paths.systemServiceVersionsFullPaths.staticAnalysisId, {
              systemId,
              systemType,
              serviceVersionId,
              staticAnalysisId,
            })}
          >
            {buildResultTypeNames[buildResultTypeKeys.STATIC_ANALYSIS]}
          </Link>
          <AngleRightIcon className="font-16 font-weight-normal text-gray-medium mx-2" />
          {t('pages.systemServiceVersionPage.unsafeAreas')}
        </h2>
        <div className="d-flex flex-row">
          <div className="mr-4 pr-4">
            <StaticAnalysisBuildResults isStaticAnalysisUnsafeAreas />
          </div>
        </div>
      </div>

      <div className="ml-4">{FilterComponent}</div>
      <StaticAnalysisUnsafeAreasTable
        requestParams={requestParams}
        requestFunction={requestFunction}
        hasSelectedFilters={hasSelectedFilters}
        tableName={tableName}
      />
    </>
  );
}

export default StaticAnalysisUnsafeAreas;
