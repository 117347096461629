import documents from './documents';
import integrations from './integrations';
import entry from './entry';
import monitoring from './monitoring';
import notifications from './notifications';
import releases from './releases';
import serverTiers from './serverTiers';
import services from './services';
import serviceVersion from './serviceVersion';
import systems from './systems';
import tiers from './tiers';
import users from './users';
import dictionaries from './dictionaries';
import audit from './audit';
import serviceEndpoints from './serviceEndpoints';
import backups from './backups';
import issues from './issues';
import milestones from './milestones';
import pipelines from './pipelines';
import quotas from './quotas';
import contactPersons from './contactPersons';
import repositories from './repositories';

export default {
  backups,
  documents,
  entry,
  monitoring,
  notifications,
  releases,
  serverTiers,
  services,
  serviceVersion,
  systems,
  tiers,
  users,
  integrations,
  dictionaries,
  serviceEndpoints,
  audit,
  issues,
  milestones,
  pipelines,
  quotas,
  contactPersons,
  repositories,
};
