import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/UI/TextBlock';
import LinkComponent from 'components/UI/LinkComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';

import PipelineStages from '../../ServiceVersionsPage/ServiceVersionsTable/PipelineStages';
import ServiceVersionCommit from '../../ServiceVersionsPage/ServiceVersionCommit';

function ServiceVersionInfo({ serviceVersion }) {
  const { t } = useTranslation();

  return (
    <div className="ml-4">
      <div className="row font-14">
        <div className="col-12 col-md-6 px-0">
          {serviceVersion?.description && (
            <div className="my-4">
              <TextBlock greyColor title={t('common.description')}>
                {serviceVersion.description}
              </TextBlock>
            </div>
          )}
          <div className="my-4 w-content">
            <TextBlock greyColor title={t('common.commit')}>
              {serviceVersion.commit?.url ? (
                <ServiceVersionCommit commit={serviceVersion.commit} truncateMaxLines={2} />
              ) : (
                '-'
              )}
            </TextBlock>
          </div>
          {serviceVersion.addedViaPortal && (
            <div className="my-4 w-content">
              <TextBlock greyColor title={t('common.branch')}>
                {serviceVersion?.branch ? serviceVersion.branch : '-'}
              </TextBlock>
            </div>
          )}
          {serviceVersion?.pipeline && (
            <div className="my-4">
              <TextBlock greyColor title={t('common.stages')}>
                <div className="d-flex flex-row pt-2 gap-2">
                  <PipelineStages
                    pipeline={serviceVersion.pipeline}
                    system={serviceVersion?.system}
                    service={serviceVersion?.service}
                    uuid={serviceVersion?.uuid}
                  />
                </div>
              </TextBlock>
            </div>
          )}
          {!!serviceVersion.pipeline?.duration && (
            <div className="my-4">
              <TextBlock greyColor title={t('pages.systemServiceVersionPage.duration')}>
                <div className="d-flex">
                  <span className="d-flex align-self-flex-end">
                    {moment.utc(serviceVersion.pipeline?.duration * 1000).format('HH:mm:ss')}
                  </span>
                </div>
              </TextBlock>
            </div>
          )}
          {serviceVersion?.lastUpdatedBy && (
            <div className="my-4">
              <TextBlock greyColor title={t('common.updatedBy')}>
                <LastUpdatedStatusWithLink
                  lastUpdatedBy={serviceVersion.lastUpdatedBy}
                  lastUpdatedAt={serviceVersion.lastUpdatedAt}
                />
              </TextBlock>
            </div>
          )}
        </div>
        <div className="col-12 col-md-6 fix-left-p">
          {(serviceVersion.pipeline?.webUrl || serviceVersion?.sonarProjectUrl) && (
            <div className="my-4">
              <TextBlock greyColor title={t('common.links')}>
                <div className="d-sm-flex flex-column text-wrap-nowrap">
                  <LinkComponent
                    href={serviceVersion.pipeline.webUrl}
                    text={t('pages.systemTierPage.openInGithub')}
                  />
                  <LinkComponent
                    href={serviceVersion.sonarProjectUrl}
                    text={t('pages.systemTierPage.openInSonar')}
                  />
                </div>
              </TextBlock>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceVersionInfo;
