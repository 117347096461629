import { quoteStatusKeys } from 'constants';
import { createSelector } from 'reselect';

const tierQuotesState = state => state.tierQuotes;

export const tierQuotesSelector = createSelector([tierQuotesState], tierQuotes => tierQuotes);

export const tierQuoteRequestSelector = createSelector([tierQuotesState], ({ quoteRequest }) =>
  quoteRequest?.find(quot => quot.status === quoteStatusKeys.NEW)
);

export const tierQuoteRequestIsLoadingSelector = createSelector(
  [tierQuotesState],
  ({ quoteRequestIsLoading }) => quoteRequestIsLoading
);
