import React from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NewDataTable, { modifierKeys } from 'components/Common/NewDataTable';
import paths from 'constants/paths';
import Frequency from 'components/Common/Frequency';
import ClipboardMenu from 'components/Common/ClipboardMenu';
import { TextButton } from 'components/UI/Button';
import { EditPencilIcon } from 'components/UI/EditPencilIcon';

import { ReactComponent as LinkSvg } from 'assets/images/integrations/Link.svg';

import * as S from './styles';

function IntegrationDeployWrapper({
  integrationTiers,
  integrationSystemTo,
  integrationSystemFrom,
  integrationUuid,
}) {
  const { t } = useTranslation();
  const headerData = [
    {
      key: 'tierFrom',
      label: t('pages.systemIntegrationRelationsPage.sendingDataTier'),
    },
    {
      key: 'serviceEndpointTierTo.tier',
      label: t('pages.systemIntegrationRelationsPage.receivingDataTier'),
    },
    {
      key: 'tierFrequency',
      label: t('pages.systemIntegrationRelationsPage.exchangeFrequency'),
      modifierKeys: modifierKeys.fitContent,
      width: 30,
    },
    {
      key: 'editButton',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];
  const {
    params: { systemId, systemType },
  } = useRouteMatch();

  const formattedBodyData = integrationTiers.map(item => ({
    id: item.uuid,
    data: [
      {
        key: 'tierFrom',
        content: (
          <S.TierLinkWrapper id={`tierFrom${item.tierFrom.uuid}`}>
            <TextButton
              variant="link"
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemFullPaths.tier, {
                    systemId: integrationSystemFrom?.uuid,
                    tierId: item.tierFrom.uuid,
                    systemType: paths.systemTypes.internal,
                  })}
                />
              )}
              tableLink
            >
              {item.tierFrom?.name}
            </TextButton>
            <div aria-labelledby={`tierFrom${item.tierFrom.uuid}`}>
              {integrationSystemFrom?.name}
            </div>
          </S.TierLinkWrapper>
        ),
      },
      {
        key: 'serviceEndpointTierTo.tier',
        content: (
          <S.TierLinkWrapper>
            <S.NameLinkWrapper id={`tierTo${item.serviceEndpointTierTo.tier.uuid}`}>
              <TextButton
                variant="link"
                component={props => (
                  <Link
                    {...props}
                    to={generatePath(paths.systemFullPaths.tier, {
                      systemId: integrationSystemTo?.uuid,
                      tierId: item.serviceEndpointTierTo.tier.uuid,
                      systemType: paths.systemTypes.internal,
                    })}
                  />
                )}
                tableLink
              >
                {item.serviceEndpointTierTo.tier.name}
              </TextButton>
              <ClipboardMenu
                url={item.serviceEndpointTierTo.uri}
                label={t('pages.systemIntegrationRelationsPage.linkToTier')}
              >
                <S.LinkIcon>
                  <TextButton variant="link" key={item.uuid} icon={<LinkSvg />} />
                </S.LinkIcon>
              </ClipboardMenu>
            </S.NameLinkWrapper>
            <div aria-labelledby={`tierTo${item.serviceEndpointTierTo.tier.uuid}`}>
              {integrationSystemTo?.name}
            </div>
          </S.TierLinkWrapper>
        ),
      },
      {
        key: 'tierFrequency',
        content:
          item.tierFrequency === null ? (
            t('pages.systemIntegrationRelationsPage.byEvent')
          ) : (
            <Frequency unit={item.tierFrequency?.unit} value={item.tierFrequency?.value} />
          ),
      },
      {
        key: 'editButton',
        content: integrationUuid && systemType && (
          <TextButton
            variant="link"
            key={item.uuid}
            tableIcon
            icon={<EditPencilIcon withoutMargin />}
            component={props => (
              <Link
                {...props}
                to={generatePath(paths.systemIntegrationsFullPaths.editTiersBound, {
                  systemId,
                  systemType,
                  integrationId: integrationUuid,
                  action: paths.integrationsAction.editTiersBound,
                })}
              />
            )}
          />
        ),
      },
    ],
  }));

  return (
    <S.DeployWrapper>
      <NewDataTable headerData={headerData} bodyData={formattedBodyData} smallVariant />
    </S.DeployWrapper>
  );
}

export default IntegrationDeployWrapper;
