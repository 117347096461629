import styled from 'styled-components';
import { ClipboardCopy, Truncate } from '@patternfly/react-core';

export const ClipboardCopyStyled = styled(ClipboardCopy)`
  width: fit-content;
  background-color: #fff !important;

  .pf-v5-c-clipboard-copy__text {
    margin-right: 8px;
  }

  .pf-v5-c-button {
    padding-left: 0;

    svg {
      color: #0066cc;
    }
  }
  .pf-v5-c-clipboard-copy__actions-item {
    button {
      padding-right: 0;
      margin-right: 8px;
    }
  }

  &:hover {
    .pf-v5-c-button {
      svg {
        color: #004080;
      }
    }
  }
`;

export const TruncateStyled = styled(Truncate)`
  line-height: 24px;
`;
