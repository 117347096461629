import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { scopes } from 'hooks/useUserPermissions';
import { platformUsersSelector, usersSelector } from 'store/users/selectors';
import { fetchSystemUsers, fetchUsers } from 'store/users/actions';
import { currentSystemSelector } from 'store/systems/selectors';
import { clearUsersState } from 'store/users/reducers';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';

import UserActions from '../../CurrentUserPage/UserActions';
import UserStatusLabel from './UserStatusLabel';
import UserRolesLabel from './UserRolesLabel';

function UsersTable({
  isAdministration,
  addButton,
  tableFilters,
  setIsShowFilters,
  isShowFilters,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { data, total, isLoading, isError } = useSelector(
    isAdministration ? platformUsersSelector : usersSelector
  );

  const currentSystem = useSelector(currentSystemSelector);
  const deleteRole = useAsync();
  const headerData = [
    {
      key: 'fullName',
      label: t('common.fio'),
      withSort: true,
      filterable: true,
    },
    {
      key: 'login',
      label: t('common.login'),
      withSort: true,
      filterable: true,
      minWidth: '200px',
    },
    {
      key: 'email',
      label: t('common.email'),
      withSort: true,
      filterable: true,
      minWidth: '200px',
    },
    {
      key: 'phone',
      label: t('common.phone'),
      withSort: true,
      minWidth: '200px',
    },
    {
      key: 'workplace',
      label: t('common.work'),
      withSort: true,
      filterable: true,
    },
    {
      key: 'role',
      label: t('common.role'),
      withSort: true,
      minWidth: '120px',
      isHidden: !systemId,
    },
    {
      key: 'admin',
      label: t('pages.adminUsersPage.accessLevel'),
      withSort: true,
      isHidden: !isAdministration,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
      minWidth: '130px',
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const refreshTableData = () => tableRef.current?.requestDataWithParams();
  const requestFunction = requestParameters =>
    dispatch(
      isAdministration ? fetchUsers(requestParameters) : fetchSystemUsers(requestParameters)
    );
  const defaultParams = useMemo(
    () => ({
      fieldToFilter: 'fullName',
      orderBy: 'fullName',
      system: systemId,
      ascending: true,
      admin: tableFilters.filterParams?.admin,
      ...tableFilters.filterParams,
      ...tableFilters.filterParams?.date,
      date: undefined,
    }),
    [systemId, tableFilters.filterParams]
  );

  useSocketAsync({
    topic: 'users',
    debounceTime: 600,
    filterFn: ({ object }) => data.find(item => item.uuid === object?.uuid),
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (!isShowFilters || (currentSystem?.uuid && !currentSystem?.totalSummary?.userTotal?.total)) {
      setIsShowFilters(
        tableFilters?.hasSelectedFilters ||
          Boolean(total) ||
          Boolean(currentSystem?.totalSummary?.userTotal.total)
      );
    }
  }, [tableFilters?.hasSelectedFilters, total, currentSystem?.totalSummary?.userTotal.total]);

  useEffect(() => () => dispatch(clearUsersState()), []);

  const formattedBodyData = data?.map(user => ({
    id: user.uuid,
    data: [
      {
        key: 'fullName',
        content: user?.fullName ? <span className="text-nowrap">{user.fullName}</span> : '-',
      },
      {
        key: 'login',
        content: isAdministration ? (
          <LinkWithPermission
            system={currentSystem}
            scope={scopes.platform.userViewForm}
            isPlatformScope={isAdministration}
            to={
              user.uuid
                ? generatePath(
                    systemId ? paths.systemPaths.userDetails : paths.adminUsersPaths.details,
                    {
                      userId: user.uuid,
                    }
                  )
                : undefined
            }
            isTableLink
          >
            <span className="mr-2">{user.login}</span>
          </LinkWithPermission>
        ) : (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={user}
            system={currentSystem}
            scope={scopes.platform.userViewForm}
            isTableLink
            linkTo={
              user.uuid
                ? generatePath(
                    systemId
                      ? paths.systemPaths.userDetails
                      : paths.adminFullPaths.users + paths.adminUsersPaths.details,
                    {
                      systemId,
                      userId: user.uuid,
                    }
                  )
                : undefined
            }
          />
        ),
      },
      {
        key: 'email',
        content: user.email && (
          <a href={`mailto:${user.email}`} className="word-break-break-all">
            {user.email}
          </a>
        ),
      },
      {
        key: 'phone',
        content: !!user?.phone && <a href={`tel:${user.phone}`}>{user.phone}</a>,
      },
      {
        key: 'workplace',
        content: user?.workplace,
      },
      {
        key: 'role',
        content: !isAdministration && (
          <UserRolesLabel currentSystem={currentSystem} user={user} userId={user.uuid} />
        ),
      },
      {
        key: 'admin',
        content: <UserStatusLabel user={user} />,
      },
      {
        key: 'lastUpdatedBy',
        content: user.lastUpdatedBy && (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={user.lastUpdatedBy}
            system={currentSystem}
            scope={scopes.platform.userViewForm}
            isTableLink
            linkTo={
              user.lastUpdatedBy?.uuid && systemId
                ? generatePath(
                    !isAdministration
                      ? paths.routePaths.system + paths.systemPaths.userDetails
                      : paths.adminFullPaths.users + paths.adminUsersPaths.details,
                    {
                      systemId,
                      systemType,
                      userId: user.lastUpdatedBy.uuid,
                    }
                  )
                : undefined
            }
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: user.lastUpdatedAt,
      },
      {
        key: 'actions',
        content: (
          <UserActions
            user={user}
            userUuid={user.uuid}
            roleId={user.userRoleUuid}
            deleteRoles={deleteRole}
            callback={refreshTableData}
            isAdministration={isAdministration}
            currentSystem={currentSystem}
          />
        ),
      },
    ],
  }));

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{tableFilters?.FilterComponent}</div>}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        total={total}
        isLoading={isLoading}
        tableName="users"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={defaultParams}
        hasSelectedFilters={tableFilters?.hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />
    </>
  );
}

export default UsersTable;
