import React from 'react';
import C from 'constants/C';
import * as styles from './styles';

const NotificationTypeIco = ({ icon }) => {
  switch (icon) {
    case C.common.system.ico.ERROR:
      return <styles.ErrorIco />;
    case C.common.system.ico.WARNING:
      return <styles.WarningIco />;
    default:
      return <styles.StatusOkIco />;
  }
};

export default NotificationTypeIco;
