import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
  background-color: #fff;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow-x: hidden;

  ${({ withoutSidePadding }) =>
    withoutSidePadding &&
    `
    padding: 0;
    min-width: auto;
  `};

  ${({ withoutFlex }) =>
    withoutFlex &&
    `
    flex: auto;
    overflow-x: visible;
  `};

  ${({ blockSet }) =>
    blockSet &&
    `
    padding: 0 24px;
    margin-bottom: 8px;
  `};

  ${({ withSystemHeader }) =>
    withSystemHeader &&
    `
    padding: 0;
    background: #f1f2f3;
  `};

  ${({ fullHeight }) =>
    fullHeight &&
    `
    min-height: 100%;
  `};
`;

export const SystemWrapper = styled.div`
  min-width: 1000px;
  flex: 1;
  background: #ffffff;
`;

export const TierBlockWrapper = styled(SystemWrapper)`
  padding: -11.5px 0;
  background: #f1f2f3;
`;

export const MainPageWrapper = styled(SystemWrapper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f1f2f3;
`;

export const DocumentPageWrapper = styled(SystemWrapper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 1000px;
  background: #f1f2f3;
  padding: 0 24px 24px 24px;
`;

export const SystemContent = styled.div`
  background-color: #ffffff;

  ${({ isNoTitle }) =>
    isNoTitle &&
    `
    background: #f1f2f3;
  `};
`;

export const TierBlockContent = styled.div`
  background-color: #ffffff;
  padding: 0 24px 0;
`;

export const SectionHeaderWrapper = styled.h1`
  margin: 4px;
  box-shadow: 0px -1px 0px #e8e8e8;
`;

export const MainPageLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h1.attrs({
  className: 'font-24 text-black mt-4 mb-3 mr-3 w-100 font-weight-medium',
})`
  ${({ withoutSidePadding }) => withoutSidePadding && 'margin-left: 24px;'}

  ${({ alignSelfCenterTitle }) => alignSelfCenterTitle && 'align-self: center;'}
`;

export const TitleNoMargin = styled.h1.attrs({
  className: 'font-24 text-black mt-4 mb-0 mr-3 w-100 font-weight-medium',
})`
  ${({ withoutSidePadding }) => withoutSidePadding && 'margin-left: 24px;'}
`;

export const LeftSideContent = styled.div.attrs({
  className: 'd-flex flex-row align-items-start',
})`
  padding-top: 40px;
`;

export const SystemsLoaderWrapper = styled.div.attrs({
  className: 'd-flex position-absolute justify-content-center align-items-center',
})`
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TableSpinner = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center p-5',
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.35);
  z-index: 1;
  min-height: 265px;
`;
