import styled from 'styled-components';

export const chartWidth = 1000;

export const ResourcesBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100px;
`;

export const ResourcesTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #151515;
`;

export const Avialable = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #6a6e73;
  align-self: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};
`;

export const Using = styled(Avialable)`
  color: #0f69c9;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 56px;
  padding: 0 14px 0 24px;
  align-items: center;
  border-bottom: 1px solid #15151515;

  ${({ isLastItem }) =>
    isLastItem &&
    `
    border-bottom: 0px;
  `};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResourcesLeft = styled.div`
  display: flex;
  width: 240px;
  justify-content: space-between;
  padding-right: 16px;
  border-right: 1px solid #15151515;
`;

export const ResourcesRight = styled.div`
  display: flex;
  flex: 1;
  padding-left: 8px;
  ${props =>
    props.justifyContent === 'end' &&
    `
    justify-content: flex-end
  `};
`;

export const ResourcesText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #3c3f42;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-height: 450px;
`;

export const MonitoringText = styled.div`
  display: flex;
  width: 100%;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #3c3f42;
`;
