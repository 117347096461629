import http from 'api/config';

const getDictionaryContent = path => http.get(`entry/${path}`);

const getProtocols = () => http.get(`entry/protocol`);

const getAuthTypes = () => http.get(`entry/auth-type`);

const getBuildTemplate = params => http.get(`entry/build-template`, { params });

export default { getDictionaryContent, getProtocols, getAuthTypes, getBuildTemplate };
