import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { isBoolean } from 'lodash';

import useSocketAsync from 'hooks/useSocketAsync';
import useModal from 'hooks/useModal';
import useStorage from 'hooks/useStorage';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { tiersSelector } from 'store/tiers/selectors';
import { fetchTiers } from 'store/tiers/actions';
import { currentSystemSelector, systemsOptionsSelector } from 'store/systems/selectors';
import { clearTiersState } from 'store/tiers/reducers';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import paths from 'constants/paths';
import StatusComponent, {
  getServerTierAvailableStatuses,
  getSystemStatuses,
  getTierAvailableStatuses,
} from 'components/Common/StatusComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import {} from 'components/Common/StatusComponent/statuses';
import { systemTypesKeys } from 'constants';
import { useTranslation } from 'react-i18next';

import LastReleaseName from '../Tier/LastReleaseName';
import TierActions from '../TierActions';
import DeployReleaseForm from '../DeployReleaseForm';
import TierName from './TierName';
import DeleteTierModal from './DeleteTierModal';

function TiersTable({ addButton, tableFilters, setIsShowFilters, isShowFilters }) {
  const { t } = useTranslation();
  const serverTierAvailableStatuses = getServerTierAvailableStatuses();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const systemStatuses = getSystemStatuses();
  const { hasPermissions, platformPermissions } = useUserPermissions();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();

  const systemFilterOption = useStorage('systemFilterOption');
  const { total, data, isLoading, isError } = useSelector(tiersSelector);
  const systemOptions = useSelector(systemsOptionsSelector);
  const deployFormModal = useModal();
  const deleteTierModal = useModal();
  const currentSystem = useSelector(currentSystemSelector);
  const isExternalTiers = systemType === systemTypesKeys.EXTERNAL || (systemId && !hasPermissions);
  const defaultPermission = isBoolean(systemOptions.onlyWithPermission)
    ? systemOptions.onlyWithPermission
    : systemFilterOption.storageData?.defaultSwitch;

  const requestParams = useMemo(
    () => ({
      system: systemId,
      orderBy: 'code',
      ascending: true,
      status: tableFilters?.filterParams.status,
      isDevTier: tableFilters?.filterParams?.tierStatus,
      type: tableFilters?.filterParams?.type,
      q: tableFilters?.filterParams?.q,
      currentRelease: tableFilters?.filterParams?.currentRelease,
      serverTierNameQuery: tableFilters?.filterParams?.serverTierNameQuery,
      ...tableFilters.filterParams?.date,
      date: undefined,
      onlyWithPermission: systemId ? undefined : defaultPermission,
    }),
    [systemId, tableFilters?.filterParams, defaultPermission]
  );

  const requestFunction = requestParameters =>
    dispatch(
      fetchTiers({
        system: systemId,
        ...requestParameters,
        isDevTier:
          requestParameters.isDevTier?.length === 1 ? requestParameters.isDevTier : undefined,
      })
    );

  useSocketAsync({
    topic: 'tiers',
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (!isShowFilters || (currentSystem?.uuid && !currentSystem?.totalSummary?.tierTotal?.total)) {
      setIsShowFilters?.(
        tableFilters?.hasSelectedFilters ||
          Boolean(currentSystem?.totalSummary?.tierTotal?.total) ||
          Boolean(total)
      );
    }
  }, [tableFilters?.hasSelectedFilters, total, currentSystem?.totalSummary?.tierTotal?.total]);

  useEffect(() => () => dispatch(clearTiersState()), []);

  const externalHeaderData = [
    {
      key: 'code',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      isHidden: systemId,
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updateDate'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const headerData = [
    {
      key: 'code',
      label: t('common.name'),
      withSort: true,
      modifier: modifierKeys.nowrap,
    },
    {
      key: 'currentRelease',
      label: t('common.currentRelease'),
      withSort: true,
    },
    {
      key: 'serverTier.name',
      label: t('common.serverTier'),
      withSort: true,
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      isHidden: systemId,
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updateDate'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = data?.map(tier => {
    if (isExternalTiers) {
      return {
        id: tier.uuid,
        data: [
          {
            key: 'code',
            content: <TierName tier={tier} />,
          },
          {
            key: 'system.shortName',
            content: (
              <LinkWithPermission
                system={tier?.system}
                scope={
                  tier.system.type === systemTypesKeys.EXTERNAL
                    ? scopes.system.systemViewForm
                    : undefined
                }
                to={generatePath(paths.routePaths.system, {
                  systemId: tier.system.uuid,
                  systemType: tier.system.type,
                })}
                isPlatformScope={tier.system.type === systemTypesKeys.EXTERNAL}
                isTableLink
              >
                <StatusComponent
                  statusId={
                    tier.system.type === systemTypesKeys.EXTERNAL
                      ? systemTypesKeys.EXTERNAL
                      : tier.system.status
                  }
                  statusWithTooltip
                  statuses={systemStatuses}
                  truncate
                >
                  {tier.system.shortName}
                </StatusComponent>
              </LinkWithPermission>
            ),
          },
          {
            key: 'status',
            content: (
              <StatusComponent
                statusId={tier?.status}
                statuses={tierAvailableStatuses}
                objectType="TIER"
                objectUuid={tier?.uuid}
              />
            ),
          },
          {
            key: 'lastUpdatedBy',
            content: (
              <LastUpdatedStatusWithLink
                lastUpdatedBy={tier?.lastUpdatedBy}
                system={tier?.system}
                isTableLink
                hideDateTime
              />
            ),
          },
          {
            key: 'lastUpdatedAt',
            type: types.relativeTime,
            content: tier.lastUpdatedAt,
          },
          {
            key: 'actions',
            content: (
              <TierActions
                currentTier={tier}
                deleteTierModal={deleteTierModal}
                deployFormModal={deployFormModal}
              />
            ),
          },
        ],
      };
    }

    return {
      id: tier.uuid,
      data: [
        {
          key: 'code',
          content: <TierName tier={tier} />,
        },
        {
          key: 'currentRelease',
          content: (
            <LastReleaseName
              tier={tier}
              systemId={tier.system.uuid}
              systemType={tier.system.type}
            />
          ),
        },
        {
          key: 'serverTier.name',
          content: platformPermissions.includes(scopes.platform.clusterViewForm, true) ? (
            <StatusComponent
              statusId={tier.serverTier?.status}
              statusWithTooltip
              statuses={serverTierAvailableStatuses}
            >
              {tier.serverTier?.name || '-'}
            </StatusComponent>
          ) : (
            tier.serverTier?.name || '-'
          ),
          type: platformPermissions.includes(scopes.platform.clusterViewForm, true) && types.link,
          to: tier.serverTier?.uuid
            ? generatePath(paths.adminFullPaths.serverTiers + paths.serverTiersPaths.show, {
                serverTierId: tier.serverTier?.uuid,
              })
            : undefined,
        },
        {
          key: 'system.shortName',
          content: (
            <LinkWithPermission
              system={tier?.system}
              scope={
                tier.system.type === systemTypesKeys.EXTERNAL
                  ? scopes.system.systemViewForm
                  : undefined
              }
              to={generatePath(paths.routePaths.system, {
                systemId: tier.system.uuid,
                systemType: tier.system.type,
              })}
              isPlatformScope={tier.system.type === systemTypesKeys.EXTERNAL}
              isTableLink
            >
              <StatusComponent
                statusId={
                  tier.system.type === systemTypesKeys.EXTERNAL
                    ? systemTypesKeys.EXTERNAL
                    : tier.system.status
                }
                statusWithTooltip
                statuses={systemStatuses}
                truncate
              >
                {tier.system.shortName}
              </StatusComponent>
            </LinkWithPermission>
          ),
        },
        {
          key: 'status',
          content: (
            <StatusComponent
              statusId={tier?.status}
              statuses={tierAvailableStatuses}
              objectUuid={tier.uuid}
              objectType="TIER"
            />
          ),
        },
        {
          key: 'lastUpdatedBy',
          content: (
            <LastUpdatedStatusWithLink
              lastUpdatedBy={tier?.lastUpdatedBy}
              system={tier?.system}
              isTableLink
              linkTo={
                tier?.lastUpdatedBy.uuid
                  ? generatePath(paths.routePaths.system + paths.systemPaths.userDetails, {
                      systemId: tier.system.uuid,
                      systemType: tier.system.type,
                      userId: tier.lastUpdatedBy.uuid,
                    })
                  : undefined
              }
            />
          ),
        },
        {
          key: 'lastUpdatedAt',
          type: types.relativeTime,
          content: tier.lastUpdatedAt,
        },
        {
          key: 'actions',
          content: (
            <TierActions
              currentTier={tier}
              deleteTierModal={deleteTierModal}
              deployFormModal={deployFormModal}
            />
          ),
        },
      ],
    };
  });

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{tableFilters?.FilterComponent}</div>}
      <NewDataTable
        ref={tableRef}
        headerData={isExternalTiers ? externalHeaderData : headerData}
        bodyData={formattedBodyData}
        isLoading={isLoading}
        total={total}
        tableName="tiers"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={requestParams}
        hasSelectedFilters={tableFilters?.hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />

      <DeployReleaseForm
        {...deployFormModal}
        isDeployToDevAction={deployFormModal.modalData?.isTierDev}
      />

      <DeleteTierModal
        {...deleteTierModal}
        requestFunction={tableRef.current?.requestDataWithParams}
      />
    </>
  );
}

export default TiersTable;
