import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';
import { serviceVersionsPipelineKeys, serviceVersionsStatusKeys } from 'constants';
import { getProgressSystemMessage } from 'constants/tooltips';

import ReleasesTable from './ReleasesTable';

function ReleasesPage({ withSystemHeader = true }) {
  const { t, i18n } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const { data, run } = useAsync();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentSystem = useSelector(currentSystemSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const serviceVersionsMessage =
    !data?.meta.total && t('pages.systemReleasesPage.noActiveServiceVersions');
  const disabledMessage = useMemo(
    () => notActiveSystemMessage || serviceVersionsMessage,
    [notActiveSystemMessage, serviceVersionsMessage, i18n.language]
  );

  useEffect(() => {
    if (systemId && checkedPermissions(scopes.serviceVersion.versionViewSection)) {
      run(
        api.serviceVersion.getServicesVersions({
          pipelineStatus: Object.keys(serviceVersionsPipelineKeys),
          status: [serviceVersionsStatusKeys.PROBLEM_FOUND],
          system: systemId,
          limit: 1,
        })
      );
    }
  }, [checkedPermissions(scopes.serviceVersion.versionViewSection)]);

  const addReleasesButton = (
    <>
      {checkedPermissions(scopes.release.releaseCreate) && (
        <Tooltip
          className={disabledMessage ? undefined : 'd-none'}
          content={disabledMessage}
          exitDelay={150}
          animationDuration={150}
        >
          <div>
            <BlueButton
              variant="primary"
              isDisabled={disabledMessage}
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemReleasesFullPaths.new, {
                    action: paths.releaseActions.new,
                  })}
                />
              )}
            >
              {t('common.add')}
            </BlueButton>
          </div>
        </Tooltip>
      )}
    </>
  );

  const leftSideContent = (
    <div className="d-flex flex-row align-items-baseline mt-4">
      {isShowFilters && addReleasesButton}
    </div>
  );

  return (
    <PageLayout
      title={t('common.releases')}
      leftSideContent={leftSideContent}
      systemLayout
      withSystemHeader={withSystemHeader}
      withSystemSelector
    >
      <ReleasesTable
        addButton={addReleasesButton}
        setIsShowFilters={setIsShowFilters}
        isShowFilters={isShowFilters}
        currentTotal={currentSystem?.uuid && currentSystem?.totalSummary?.releaseTotal.total}
      />
    </PageLayout>
  );
}

export default ReleasesPage;
