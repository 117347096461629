import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { getIssueNewStatusNames, issueStatusKeys } from 'constants';
import CheckBoxSelector from 'pages/SystemRouter/SystemReleasesRouter/AddReleasePage/CheckBoxSelector';

import TasksTable from './TasksTable';

import { ServicesSelectionWrapper } from '../styles';

const defaultFilterParams = {
  status: [issueStatusKeys.NEW, issueStatusKeys.IN_PROGRESS],
};

function TasksSelection({ allCheckBoxTasksData = [] }) {
  const { t } = useTranslation();
  const issueNewStatusNames = getIssueNewStatusNames();
  const hint = `${t('pages.systemAddReleasesPage.selectIssuesHint')}`;
  const {
    params: { releaseId },
  } = useRouteMatch();
  const { watch, setValue } = useFormContext();
  const allIssuesInRelease = useAsync();
  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedTasksUuids, setcheckedTasksUuids] = useState([]);
  const [checkedFlagResolved, setCheckedFlagResolved] = useState([]);
  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'milestoneQuery',
      name: t('common.milestone'),
    },
    {
      id: 'status',
      type: 'multiSelect',
      name: t('common.status'),
      options: formatFilterOptions(issueNewStatusNames),
    },
  ];
  const releaseIssuesValue = watch('releaseIssues');

  const { FilterComponent, filterParams } = useMultiSelectFilterSection(
    filterData,
    'tasksInRelease',
    defaultFilterParams
  );

  function releaseIssuesRequestFunction(params) {
    allIssuesInRelease.run(api.releases.getReleaseIssues(releaseId, params));
  }

  const releaseIssuesRequestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'dueDate',
      ascending: false,
    }),
    [filterParams]
  );

  useEffect(() => {
    if (!allIssuesInRelease.data?.data && releaseId) {
      releaseIssuesRequestFunction(releaseIssuesRequestParams);
    }

    setValue('step', 3);
  }, []);

  useEffect(() => {
    if (releaseIssuesValue) {
      const currentReleaseIssues = releaseIssuesValue.map(item => item.issue.uuid);
      const allResolvedIssues = releaseIssuesValue?.filter(item => item?.implemented === true);

      setcheckedTasksUuids(currentReleaseIssues);
      setCheckedFlagResolved(allResolvedIssues?.map(item => item?.issue?.uuid));
    }
  }, []);

  const setTaskUuid = () =>
    setValue(
      `releaseIssues`,
      checkedTasksUuids.map(item => ({
        issue: {
          uuid: item,
        },
        implemented: checkedFlagResolved?.includes(item),
      }))
    );

  useEffect(() => {
    setTaskUuid();
  }, [checkedTasksUuids, checkedFlagResolved]);

  return (
    <ServicesSelectionWrapper>
      <div>
        <div className="font-20 font-weight-medium mt-4 ml-4">{t('common.issues')}</div>
        <div className="font-16 text-gray-light pre-line mt-2 ml-4 mb-3">{hint}</div>
        {!!allCheckBoxTasksData.length && (
          <div className="d-flex align-items-start ml-4">
            <div className="align-self-flex-end mb-3">
              <CheckBoxSelector
                checkedUuids={checkedTasksUuids}
                setCheckedUuids={setcheckedTasksUuids}
                allRenderUuids={allRenderUuids}
                allCheckBoxData={allCheckBoxTasksData.map(item => item.uuid)}
                callback={() => setTaskUuid()}
                declensionItems={[
                  t('plural.selected1'),
                  t('plural.selected2'),
                  t('plural.selected3'),
                ]}
                dropdownText={t('pages.systemAddReleasesPage.issues')}
              />
            </div>
            <div className="d-sm-flex flex-row mr-4">{FilterComponent}</div>
          </div>
        )}
        <TasksTable
          checkedTasksUuids={checkedTasksUuids}
          setcheckedTasksUuids={setcheckedTasksUuids}
          setCheckedFlagResolved={setCheckedFlagResolved}
          setAllRenderUuids={setAllRenderUuids}
          checkedFlagResolved={checkedFlagResolved}
          filterParams={filterParams}
          hasSelectedFilters={allCheckBoxTasksData.length}
        />
      </div>
    </ServicesSelectionWrapper>
  );
}

export default TasksSelection;
