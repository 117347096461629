import styled from 'styled-components';

import { InputBlockWrapper } from 'components/UI/View';

export const HostsBlockWrapper = styled(InputBlockWrapper)`
  width: 700px;
`;

export const DeleteButtonAbsoluteWrapper = styled.div`
  position: absolute;
  right: -37px;
  top: ${({ withLabel }) => (withLabel ? '8px' : '31px')};
`;
