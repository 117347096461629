import styled from 'styled-components';

import { Select } from '@patternfly/react-core/deprecated';

export const StyledMultiSelect = styled(Select)`
  .pf-v5-c-chip {
    margin-right: 2px;
  }

  .pf-v5-m-selected {
    background-color: #fff !important;
  }

  .pf-v5-c-select__toggle-typeahead {
    ::placeholder {
      color: #a6aaad;
      opacity: 1;
    }
  }
`;

export const SelectStyled = styled(Select)`
  ${({ $height }) => $height && `height: ${$height};`}

  .pf-v5-c-select__menu {
    ${({ $isDate }) => $isDate && ' left: -78px;'}
  }

  .pf-v5-c-select__toggle::before {
    height: 37px !important;
  }

  .pf-v5-m-selected {
    background-color: #fff !important;
  }

  .pf-v5-c-select__toggle-text {
    ${({ isPlaceholder }) => isPlaceholder && 'color: #a6aaad;'}
  }
`;
