import React from 'react';
import { Button } from '@patternfly/react-core';
import { ReactComponent as CubesSvg } from 'assets/images/table/cubes.svg';
import { useTranslation } from 'react-i18next';

import PageLayout from 'components/UI/PageLayout';

import * as S from './styles';

const ENV = window._env_;
const garfanaUrl = ENV.GRAFANA_URL;

function MonitoringPage() {
  const { t } = useTranslation();

  return (
    <PageLayout
      fullHeightPage
      title={<span className="font-24 font-weight-medium">{t('common.monitoring')}</span>}
    >
      <S.BlockWrapper>
        <div className="text-center">
          <CubesSvg className="m-4" />
        </div>
        <h2 className="font-24 font-weight-medium text-center my-3">
          {t('pages.monitoringPage.inProgress')}
        </h2>
        <div className="font-16 font-weight-normal text-center mb-3">
          {t('pages.monitoringPage.inProgressDescription')}
        </div>
        <div className="text-center">
          <Button
            component={props => (
              <a {...props} href={garfanaUrl} target="_blank" rel="noreferrer">
                {t('pages.monitoringPage.openPanel')}
              </a>
            )}
          />
        </div>
      </S.BlockWrapper>
    </PageLayout>
  );
}

export default MonitoringPage;
