import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import ImportServicesPage from './ImportServicesPage';
import AddServiceVersionsPage from '../SystemServiceVersionsRouter/AddServiceVersionsPage';
import CurrentServicePage from './CurrentServicePage';
import ServicesPage from './ServicesPage';
import AddServicePage from './AddServicePage';

const SystemServicesRouter = ({ match: { path } }) => (
  <Switch>
    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemServicesPaths.importServices}`}
      component={ImportServicesPage}
      scope={scopes.service.serviceImport}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemServicesPaths.addServices}`}
      component={AddServicePage}
      scope={scopes.service.serviceCreate}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemServicesPaths.editService}`}
      component={AddServicePage}
      scope={scopes.service.serviceUpdate}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemServicesPaths.addServiceVercion}`}
      component={AddServiceVersionsPage}
      scope={scopes.serviceVersion.versionCreate}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemServicesPaths.service}`}
      component={CurrentServicePage}
      scope={scopes.service.serviceViewForm}
    />

    <Route exact path={path} component={ServicesPage} />

    <Redirect to={path} />
  </Switch>
);

export default SystemServicesRouter;
