import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@patternfly/react-core';
import { ReactComponent as ErrorsSVG } from 'assets/images/service_version/static-analysis-errors.svg';
import { ReactComponent as VulnerabilitiesSVG } from 'assets/images/service_version/static-analysis-vulnerabilities.svg';
import { ReactComponent as DefectsSVG } from 'assets/images/service_version/static-analysis-defects.svg';
import { ReactComponent as PotentialVulnerabilitiesSVG } from 'assets/images/service_version/static-analysis-potential-vulnerabilities.svg';
import { declOfNum } from 'utils';

import { fetchServiceVersionsStaticAnalysis } from 'store/serviceVersionsBuildResults/actions';
import { serviceVersionsStaticAnalysisSelector } from 'store/serviceVersionsBuildResults/selectors';
import TextBlock from 'components/UI/TextBlock';
import { staticAnalysisTotalKeys } from 'constants';

import { StaticAnalysisIcon } from '../../BuildResults/styles';

function StaticAnalysisBuildResults({ isStaticAnalysisProblems, isStaticAnalysisUnsafeAreas }) {
  const { t } = useTranslation();
  const grades = {
    [staticAnalysisTotalKeys.bugs]: t(
      'pages.systemServiceVersionPage.staticAnalysisBuildResults.gradesBugs'
    ),
    [staticAnalysisTotalKeys.vulnerabilities]: t(
      'pages.systemServiceVersionPage.staticAnalysisBuildResults.gradesVulnerabilities'
    ),
    [staticAnalysisTotalKeys.securityHotspots]: t(
      'pages.systemServiceVersionPage.staticAnalysisBuildResults.gradesSecurityHotspots'
    ),
    [staticAnalysisTotalKeys.codeSmells]: t(
      'pages.systemServiceVersionPage.staticAnalysisBuildResults.gradesCodeSmells'
    ),
  };

  const tooltipMessages = {
    [staticAnalysisTotalKeys.bugs]: [
      t('pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.bugs.0'),
      t('pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.bugs.1'),
    ],
    [staticAnalysisTotalKeys.vulnerabilities]: [
      t(
        'pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.vulnerabilities.0'
      ),
      t(
        'pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.vulnerabilities.1'
      ),
    ],
    [staticAnalysisTotalKeys.securityHotspots]: [
      t(
        'pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.securityHotspots.0'
      ),
      t(
        'pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.securityHotspots.1'
      ),
    ],
    [staticAnalysisTotalKeys.codeSmells]: [
      t('pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.codeSmells.0'),
      t('pages.systemServiceVersionPage.staticAnalysisBuildResults.tooltipMessages.codeSmells.1'),
    ],
  };
  const declensionBugs = [
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.errors1'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.errors2'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.errors3'),
  ];
  const declensionVulnerabilities = [
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.vulnerabilities1'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.vulnerabilities2'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.vulnerabilities3'),
  ];
  const declensionSecurityHotspots = [
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.hotSpots1'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.hotSpots2'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.hotSpots3'),
  ];
  const declensionCodeSmells = [
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.codeSmells1'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.codeSmells2'),
    t('pages.systemServiceVersionPage.staticAnalysisBuildResults.plural.codeSmells3'),
  ];
  const tooltipBlockMessage = (
    <>
      <div className="mb-1">
        {t('pages.systemServiceVersionPage.staticAnalysisBuildResults.markTitle')}
      </div>
      <span>{t('pages.systemServiceVersionPage.staticAnalysisBuildResults.markMessage')}</span>
    </>
  );
  const {
    params: { staticAnalysisId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { data } = useSelector(serviceVersionsStaticAnalysisSelector);

  useEffect(() => {
    if (!data?.uuid) {
      dispatch(
        fetchServiceVersionsStaticAnalysis({
          buildResultUuid: staticAnalysisId,
        })
      );
    }
  }, []);

  const formedData = useMemo(
    () => [
      {
        id: staticAnalysisTotalKeys.bugs,
        icon: <ErrorsSVG />,
        text: declOfNum(data?.bugs, declensionBugs, false, true),
        value: data?.bugs || 0,
        grade: grades.bugs,
        estimate: data?.reliabilityRating || 0,
      },
      {
        id: staticAnalysisTotalKeys.vulnerabilities,
        icon: <VulnerabilitiesSVG />,
        text: declOfNum(data?.vulnerabilities, declensionVulnerabilities, false, true),
        value: data?.vulnerabilities || 0,
        grade: grades.vulnerabilities,
        estimate: data?.securityRating || 0,
      },
      ...(!isStaticAnalysisProblems
        ? [
            {
              id: staticAnalysisTotalKeys.securityHotspots,
              icon: <DefectsSVG />,
              text: declOfNum(data?.securityHotspots, declensionSecurityHotspots, false, true),
              value: data?.securityHotspots || 0,
              grade: grades.securityHotspots,
              estimate: data?.securityReviewRating || 0,
            },
          ]
        : []),
      {
        id: staticAnalysisTotalKeys.codeSmells,
        icon: <PotentialVulnerabilitiesSVG />,
        text: declOfNum(data?.codeSmells, declensionCodeSmells, false, true),
        value: data?.codeSmells || 0,
        grade: grades.codeSmells,
        estimate: data?.sqaleRating || 0,
      },
    ],
    [data]
  );

  const bugs = formedData?.filter(
    item =>
      item.id === staticAnalysisTotalKeys.bugs || item.id === staticAnalysisTotalKeys.codeSmells
  );
  const security = formedData?.filter(
    item =>
      item.id === staticAnalysisTotalKeys.vulnerabilities ||
      item.id === staticAnalysisTotalKeys.securityHotspots
  );
  const securityHotspots = formedData.find(
    item => item.id === staticAnalysisTotalKeys.securityHotspots
  );

  const currentInfo = item => (
    <div key={item.id} className="d-flex mr-3">
      <Tooltip
        key={item.id}
        style={{ maxWidth: '380px' }}
        exitDelay={150}
        animationDuration={150}
        content={
          <div className="font-12">
            <div className="mb-1">{tooltipMessages[item.id][0]}</div>
            <span>{tooltipMessages[item.id][1]}</span>
          </div>
        }
      >
        <StaticAnalysisIcon extension={item.id} />
      </Tooltip>
      <span className="text-wrap-nowrap">{`${item.grade}: ${item.estimate} (${item.value} ${item.text})`}</span>
    </div>
  );

  return (
    <TextBlock
      greyColor
      title={t('pages.systemServiceVersionPage.staticAnalysisBuildResults.qualityMark')}
      tooltipMessage={tooltipBlockMessage}
    >
      {!isStaticAnalysisProblems && !isStaticAnalysisUnsafeAreas && (
        <div className="d-flex felx-row gap-6 mb-4">
          <div>{bugs.map(item => currentInfo(item))}</div>
          <div>{security.map(item => currentInfo(item))}</div>
        </div>
      )}
      {isStaticAnalysisProblems && (
        <div className="d-flex">{formedData.map(item => currentInfo(item))}</div>
      )}
      {isStaticAnalysisUnsafeAreas && <div className="d-flex">{currentInfo(securityHotspots)}</div>}
    </TextBlock>
  );
}

export default StaticAnalysisBuildResults;
