import styled from 'styled-components';

import { Pagination } from '@patternfly/react-core';

export const StyledPagination = styled(Pagination)`
  justify-content: flex-end !important;
  padding: 8px;

  .pf-v5-c-options-menu__menu {
    background-color: #ffffff;
    padding: 5px 0;
  }

  .pf-v5-c-options-menu__menu-item {
    padding: 9px 18px 9px 16px;
    font-size: 14px;
  }

  .pf-v5-c-options-menu__menu-item-icon {
    margin: 4px 0 0 8px;
  }

  .pf-v5-c-pagination__nav-control {
    align-self: center;
  }

  .pf-v5-c-form-control {
    padding: 0 6px;
    border: 1px solid #f0f0f0;
    border-bottom-color: #8a8d90;
  }
`;
