import React, { useMemo } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useModal from 'hooks/useModal';
import { issueOptions, issueStatusKeys } from 'constants/index';
import Dropdown from 'components/Dropdown';
import WarningModal from 'components/Common/WarningModal';
import paths from 'constants/paths';
import { useTranslation } from 'react-i18next';

function IssueActions({
  issue,
  changeAllIssuesClick,
  deleteIssues,
  setcheckedIssuesUuids,
  checkedIssuesUuids,
  isBlueButtonDropdown,
}) {
  const { t, i18n } = useTranslation();
  const deleteMessage = t('pages.systemIssuesPage.issueIncludedInRelease');
  const history = useHistory();
  const deleteIssueModal = useModal();
  const {
    params: { milestoneId },
  } = useRouteMatch();
  const { notActiveSystemMessage, notActiveOrErrorSystemMessage, optionsFilterByScopes } =
    useUserPermissions(issue.system);

  const canIssueBeMarkedAsDone =
    issue.status === issueStatusKeys.IMPLEMENTED && issue.totalSummary.tierReleaseTotal?.prod;

  // const canIssueBeMarkedAsRefused =
  //   ((issue.status === issueStatusKeys.IMPLEMENTED || issue.status === issueStatusKeys.DONE) &&
  //     issue.totalSummary.tierReleaseTotal?.dev) ||
  //   issue.totalSummary.tierReleaseTotal?.prod;

  const disabledMessage = useMemo(() => {
    if (notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (!canIssueBeMarkedAsDone && issue.status !== issueStatusKeys.IMPLEMENTED) {
      return t('pages.systemIssuesPage.issueIsNotResolved');
    }

    if (!canIssueBeMarkedAsDone && !issue.totalSummary.tierReleaseTotal?.prod) {
      return t('pages.systemIssuesPage.noReleaseOnProdTier');
    }
  }, [canIssueBeMarkedAsDone, i18n.language]);

  const deleteDisableMessage = useMemo(() => {
    if (notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (issue.status !== issueStatusKeys.NEW) {
      return t('pages.systemIssuesPage.incorectIssueStatus');
    }

    if (issue.releases.length || issue.totalSummary.releaseTotal.total) {
      return deleteMessage;
    }
  }, [notActiveSystemMessage, issue, i18n.language]);

  const optionData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: issueOptions.taskSolved,
          name: t('pages.systemIssuesPage.markAsResolved'),
          isDisabled: disabledMessage,
          tooltip: disabledMessage,
          scope: scopes.issue.issueUpdateStatus,
        },
        // {
        //   id: issueOptions.sendForRevision,
        //   name: 'Отправить в доработку',
        //   isDisabled: !canIssueBeMarkedAsRefused,
        //   scope: scopes.issue.issueUpdateStatus,
        // },
        {
          id: issueOptions.edit,
          name: t('common.edit'),
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
          scope: scopes.issue.issueUpdate,
        },
        {
          id: issueOptions.delete,
          name: t('common.delete'),
          isDisabled: deleteDisableMessage,
          tooltip: deleteDisableMessage,
          scope: scopes.issue.issueDelete,
        },
      ]),
    [
      deleteDisableMessage,
      notActiveOrErrorSystemMessage,
      notActiveSystemMessage,
      disabledMessage,
      optionsFilterByScopes,
      i18n.language,
    ]
  );

  const onFilterChange = id => {
    if (id === issueOptions.taskSolved) {
      return changeAllIssuesClick(issueStatusKeys.DONE, [issue?.uuid]);
    }
    if (id === issueOptions.sendForRevision) {
      return changeAllIssuesClick(issueStatusKeys.IN_PROGRESS, [issue?.uuid]);
    }
    if (id === issueOptions.delete) {
      deleteIssueModal.setModalData(issue);
      return deleteIssueModal.toggleModal();
    }

    return history.push(
      generatePath(
        milestoneId
          ? paths.systemMilestoneFullPaths.milestoneIssuesEdit
          : paths.routePaths.system + paths.systemIssuesFullPaths.editIssue,
        {
          systemId: issue.system.uuid,
          systemType: issue.system.type,
          milestoneId,
          issuesId: issue?.uuid,
          action: paths.issuesAction.edit,
        }
      )
    );
  };

  const deleteIssue = () => {
    deleteIssues.run(
      api.issues.deleteIssues({
        issueIds: [issue?.uuid],
        milestone: { uuid: milestoneId || issue.milestone?.uuid },
      })
    );
    if (setcheckedIssuesUuids && checkedIssuesUuids) {
      return setcheckedIssuesUuids(checkedIssuesUuids.filter(item => item !== issue.uuid));
    }
  };
  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={issue.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          buttonLabel={t('pages.systemIssuesPage.editIssue', [issue?.name])}
        />
      )}

      <WarningModal
        title={t('pages.systemIssuesPage.deletingIssue')}
        text={t('pages.systemIssuesPage.deleteIssue')}
        name={issue?.name}
        label={t('pages.systemIssuesPage.issueName')}
        isLoading={deleteIssues.isLoading}
        buttenText={t('common.delete')}
        onClick={deleteIssue}
        {...deleteIssueModal}
      />
    </div>
  );
}

export default IssueActions;
