import styled from 'styled-components';
import { Wizard, Alert } from '@patternfly/react-core';

import { InputsBlockWrapper } from 'components/UI/View';

export const WizardStyled = styled(Wizard)`
  border-top: 1px solid #d2d2d2;
  height: auto;
  min-height: 100%;

  .pf-v5-c-wizard__main {
    z-index: auto;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
  }

  .pf-v5-c-wizard__outer-wrap {
    z-index: 1 !important;
  }

  .pf-v5-c-wizard__toggle {
    z-index: 2 !important;
  }

  .pf-v5-c-wizard__nav {
    background-color: #ffffff;
  }

  .pf-v5-c-wizard__nav-link {
    padding: 0;
  }

  .pf-v5-c-wizard__nav-link:before {
    font-size: 16px !important;
  }

  .pf-v5-c-wizard__footer {
    z-index: 10;
    padding: 24px 16px 16px 24px !important;
    flex-direction: row-reverse;
    align-self: baseline;

    .pf-m-primary {
      ${({ $isLastStep }) => $isLastStep && 'display: none !important;'}
    }

    .pf-v5-c-wizard__footer-cancel {
      display: none;
    }

    button {
      margin-right: 8px;
      margin-bottom: 16px;
    }
  }
`;

export const AlertStyled = styled(Alert)`
  height: 76px;

  .pf-v5-c-alert__title {
    font-weight: 500;
  }

  .pf-v5-c-alert__icon {
    margin-top: 2px;
  }
`;

export const SaveButtonWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: -84px;
  right: 24px;
`;

export const CanselButtonWrapper = styled.div`
  position: absolute;
  z-index: 11;
  bottom: -60px;
  margin-left: 185px;

  ${({ $isBackHidden }) => $isBackHidden && 'margin-left: 100px;'}
  ${({ $isLastStep }) => $isLastStep && 'margin-left: 90px;'}
`;

export const RepositoryBlockWrapper = styled.div`
  width: 650px;
`;

export const ServicesSelectionWrapper = styled.div`
  margin: -24px;
`;

export const EditLastVersionWrapper = styled.div`
  min-width: 250px;
  min-height: 36px;
`;

export const BlockWrapper = styled(InputsBlockWrapper)`
  max-width: 1000px;
`;
