import styled from 'styled-components';
import { Select } from '@patternfly/react-core/deprecated';

export const ResetAllButton = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 24px;
  background: #ffffff;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 50px;
  width: 200px;
  align-items: center;
  font-size: 14px;

  &:hover {
    background: #262a3310;
  }
`;

export const StyledSelector = styled(Select)`
  .pf-v5-c-select__menu {
    z-index: 5;

    .pf-v5-c-select__menu-item {
      white-space: normal;
      align-items: flex-start;

      .pf-v5-c-check__input {
        margin-top: 2.5px;
      }
    }
  }

  .pf-v5-c-select__menu-item {
    align-items: center;
  }

  .pf-v5-c-select__toggle {
    width: 210px;
    height: 37px !important;
  }

  .pf-v5-m-read {
    background-color: rgb(237, 237, 237);
  }
`;
