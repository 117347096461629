import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const serviceEndpointsSlice = createSlice({
  name: 'serviceEndpoints',
  initialState: { data: [], isLoading: false, total: 0 },
  extraReducers: {
    [actions.fetchServiceEndpoints.pending]: state => {
      state.isLoading = true;
    },
    [actions.fetchServiceEndpoints.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData } }
    ) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
    },
    [actions.fetchServiceEndpoints.rejected]: state => {
      state.isLoading = false;
    },
  },
});

export default serviceEndpointsSlice.reducer;
