import React, { useMemo } from 'react';
import { generatePath } from 'react-router';

import { scopes } from 'hooks/useUserPermissions';
import {
  documentsTypeKeys,
  releaseOptions,
  tierEventKeys,
  getDocumentsTypeNames,
} from 'constants/index';
import paths from 'constants/paths';
import LinkWithPermission from 'components/Common/LinkWithPermission';

function DocumentObjectType({ type, objectDescription, objectUuid, system }) {
  const documentsTypeNames = getDocumentsTypeNames();
  const onClickGeneratePath = () => ({
    pathname: generatePath(paths.routePaths.system + paths.systemPaths.tier, {
      systemId: system.uuid,
      systemType: system.type,
      tierId: objectUuid,
    }),
    state: {
      currentTab: tierEventKeys.quotesKey,
    },
  });

  const objectTypes = useMemo(
    () => ({
      [documentsTypeKeys.ISSUE]: {
        name: documentsTypeNames.ISSUE,
        scope: scopes.issue.issueViewForm,
        to: objectUuid
          ? generatePath(paths.routePaths.system + paths.systemIssuesFullPaths.issues, {
              systemId: system.uuid,
              systemType: system.type,
              issuesId: objectUuid,
            })
          : undefined,
      },
      [documentsTypeKeys.QUOTA_REQUEST]: {
        name: documentsTypeNames.QUOTA_REQUEST,
        scope: scopes.tier.tierViewForm,
        to: objectUuid ? onClickGeneratePath : undefined,
      },
      [documentsTypeKeys.INTEGRATION]: {
        name: documentsTypeNames.INTEGRATION,
        scope: scopes.integration.integrationViewForm,
      },
      [documentsTypeKeys.RELEASE]: {
        name: documentsTypeNames.RELEASE,
        scope: scopes.release.releaseViewForm,
        to: objectUuid
          ? generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
              systemId: system.uuid,
              systemType: system.type,
              releaseId: objectUuid,
              action: releaseOptions.view,
            })
          : undefined,
      },
      [documentsTypeKeys.SYSTEM]: {
        name: documentsTypeNames.SYSTEM,
      },
    }),
    [objectUuid]
  );

  if (!type) {
    return null;
  }

  return (
    <div className="">
      {type === documentsTypeKeys.SYSTEM ? (
        objectTypes[type].name
      ) : (
        <>
          <div>{objectTypes[type].name}</div>
          <LinkWithPermission scope={objectTypes[type].scope} to={objectTypes[type].to}>
            <div className="font-12">{objectDescription}</div>
          </LinkWithPermission>
        </>
      )}
    </div>
  );
}

export default DocumentObjectType;
