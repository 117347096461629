import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: 76px;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #d2d2d2;

  ${({ withoutBorder }) =>
    withoutBorder &&
    `
     border-top: 0px;
  `};
`;

export const Title = styled.h1`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #3c3f42;

  ${({ infoFont }) =>
    infoFont &&
    `
    font-size: 20px;
    font-weight: 400;
  `};
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`;
