import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  flex: 1;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 76px;
  background: #f1f2f3;
  transition: 0.5s;

  ${props =>
    props.showLeftMenu &&
    `
    margin-left: 320px;
  `};
`;
