import React from 'react';
import { Tooltip, Label, Truncate } from '@patternfly/react-core';

import {
  getBackupStatuses,
  getMilestoneStatus,
  getQuotesStatuses,
  getReleasesAvailableStatuses,
  getServerTierAvailableStatuses,
  getServiceAvailableStatuses,
  getServiceVersionsPipelineStatuses,
  getServiceVersionsStatuses,
  getSystemStatuses,
  getTasksStatuses,
  getTierAvailableStatuses,
  getUnitTestingStatuses,
  getVulnerabilitiesStatuses,
} from './statuses';
import ObjectComponentsStatus from './ObjectComponentsStatus';

import * as S from './styles';

function StatusComponent({
  iconStatus,
  statusId,
  statusText,
  smallText,
  statusWithTooltip,
  statuses,
  textProp = 'text',
  withoutStatusText = '',
  children,
  baselineAlign,
  truncate,
  objectUuid,
  objectType,
  isSystemList,
  isSystemDetails,
  tooltipText,
  withPointerCursor,
  alignItems = 'center',
}) {
  if ((statusId === null || statusId === undefined) && !children) {
    return <S.StatusWithoutIconWrapper>{withoutStatusText}</S.StatusWithoutIconWrapper>;
  }

  const status = statuses?.[statusId];

  if (statusWithTooltip) {
    if (objectUuid) {
      return (
        <ObjectComponentsStatus
          objectUuid={objectUuid}
          status={status}
          statusId={statusId}
          statusText={statusText}
          objectType={objectType}
          isSystemList={isSystemList}
          isSystemDetails={isSystemDetails}
          statusWithTooltip={statusWithTooltip}
          smallText={smallText}
          currentChildren={children}
          alignItems={alignItems}
        />
      );
    }

    return (
      <span className={`d-flex flex-row align-items-${alignItems}`}>
        {status && (
          <Tooltip content={status.text} exitDelay={1} animationDuration={1}>
            <S.StatusTooltipWrapper baselineAlign={baselineAlign}>
              <S.StatusWithTooltip background={status.background}>
                {smallText || status.smallText}
              </S.StatusWithTooltip>
            </S.StatusTooltipWrapper>
          </Tooltip>
        )}
        {truncate && children.length > 20 ? (
          <Truncate className="font-16 line-height-normal mr-2" content={children} />
        ) : (
          <>{children && <span className="line-height-normal mr-2">{children}</span>}</>
        )}
      </span>
    );
  }

  if (iconStatus && status) {
    const contentIcon = iconStatus !== true ? `${iconStatus} - ` : '';

    return (
      <S.IconStatusWrapper $withPointerCursor={withPointerCursor}>
        <Tooltip content={`${contentIcon}${status.text}`} exitDelay={1} animationDuration={1}>
          {status.icon}
        </Tooltip>
      </S.IconStatusWrapper>
    );
  }

  if (objectUuid && !statusWithTooltip) {
    return (
      <ObjectComponentsStatus
        objectUuid={objectUuid}
        status={status}
        statusId={statusId}
        statusText={statusText}
        objectType={objectType}
        isSystemList={isSystemList}
        isSystemDetails={isSystemDetails}
      />
    );
  }

  if (tooltipText) {
    return (
      <Tooltip content={tooltipText} exitDelay={1} animationDuration={1}>
        <Label className="cursor-default" icon={status.icon} color={status.color}>
          <S.StatusWrapper>{statusText || status[textProp]}</S.StatusWrapper>
        </Label>
      </Tooltip>
    );
  }

  return (
    <Label className="cursor-default" icon={status.icon} color={status.color}>
      <S.StatusWrapper>{statusText || status[textProp]}</S.StatusWrapper>
    </Label>
  );
}

export {
  getBackupStatuses,
  getMilestoneStatus,
  getQuotesStatuses,
  getReleasesAvailableStatuses,
  getServerTierAvailableStatuses,
  getServiceAvailableStatuses,
  getServiceVersionsPipelineStatuses,
  getServiceVersionsStatuses,
  getSystemStatuses,
  getTasksStatuses,
  getTierAvailableStatuses,
  getUnitTestingStatuses,
  getVulnerabilitiesStatuses,
};

export default StatusComponent;
