import { protocolNames } from 'constants';
import { getApiErrorMessage, validateUrlValue } from 'utils';
import api from '../api';
import i18n from '../i18n';
import { userStatusKeys } from './index';

const { t } = i18n;

const getNameAlreadyInUseErrorMessage = () => t('validation.nameAlreadyInUse');
const getCodeAlreadyInUseErrorMessage = () => t('validation.codeAlreadyInUse');
const conflictSystemCode = 'platform';

export const getTierNameErrorMessage = () => t('validation.invalidTierName');

export const conflictName = 'Hyperion';

export const conflictCode = 'hyperion';

export const getMessageForRepositoryCode = () => t('validation.serviceCodeAlreadyInUse');

export const getMessageForRepositoryName = () => t('validation.serviceNameAlreadyInUse');

export const phoneNumberValidation = number => number?.replace(/[^0-9+]/g, '');

export const getPhoneMessage = () => t('validation.phoneLength');

export const getDefaultMaxMessage = () => t('validation.maxLength', [150]);

export const numberInputValueRegex = new RegExp(`^\\d+(\\.{1})?(\\d+)?$`);

export const wholeNumbersInputValueRegex = new RegExp(`^\\d+(\\d+)?$`);

const generalInfoNameRegex = new RegExp(`[a-zA-Z0-9а-яА-ЯёЁ_][a-zA-Z0-9а-яА-ЯёЁ _\\-\\.+]+`, 'g');

export const systemCodeRegex = new RegExp(`[a-z0-9][a-z0-9\\-\\.]+`, 'g');

const phoneRegex = new RegExp(`[0-9]`, 'g');

const emailRegex = new RegExp(
  "^[A-Za-z0-9!#$%&'*\\-+/=?^_`{|}~\\.]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
  'g'
);

export const getContactsDescriptionMap = () => ({
  name: getDefaultMaxMessage(),
  site: getDefaultMaxMessage(),
  phone: getPhoneMessage(),
  email: t('validation.invalidContactEmail'),
  address: getDefaultMaxMessage(),
  comment: t('validation.maxLength', [500]),
});

export const systemAndTierCodeRegex = new RegExp(
  `[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*`,
  'g'
);

const getDefaultValidateMessage = () => t('validation.defaultValidateMessage');

export const getDefaultNumberValidateMessage = () => t('validation.defaultNumberValidateMessage');

export const serverTiersFieldsValidateMessage = {
  name: t('validation.invalidServerTierName'),
  location: getDefaultMaxMessage(),
};

export const getSystemFieldsValidateMessage = () => ({
  name: `${t('validation.invalidSystemName')} ${getDefaultValidateMessage()}`,
  shortName: t('validation.invalidSystemShortName'),
  code: t('validation.invalidSystemCode'),
  codeWarningMessage: `${t('validation.maxLength', [30])} ${getDefaultValidateMessage()}`,
  description: t('validation.maxLength', [250]),
  database: t('pages.addNewSystemPage.databasesTooltip'),
  language: t('pages.addNewSystemPage.languagesTooltip'),
  os: t('pages.addNewSystemPage.osesTooltip'),
});

export const tooltipFormDisableMessage = () => t('validation.formDisabledTooltip');

export const getSystemNameValidation = systemId => ({
  validate: async value => {
    if (value.match(generalInfoNameRegex)?.[0]?.length !== value.length) {
      return getSystemFieldsValidateMessage().name;
    }

    const itemsWithSameParams = await api.systems.getSystems({
      offset: 0,
      limit: 1,
      name: value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!systemId || itemsWithSameParams.data.data[0].uuid !== systemId)
      ? getNameAlreadyInUseErrorMessage()
      : null;
  },
});

export const getSystemCodeValidation = systemId => ({
  validate: async value => {
    if (value.match(systemCodeRegex)?.[0]?.length !== value.length) {
      return getSystemFieldsValidateMessage().code;
    }

    if (!value.replace(/[0-9 \-.]/g, '').length) {
      return t('validation.atLeastOneLetter');
    }

    if (value.toLowerCase() === conflictSystemCode) {
      return getCodeAlreadyInUseErrorMessage();
    }

    const itemsWithSameParams = await api.systems.getSystems({
      offset: 0,
      limit: 1,
      code: value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!systemId || itemsWithSameParams.data.data[0].uuid !== systemId)
      ? getCodeAlreadyInUseErrorMessage()
      : null;
  },
});

export const deletedNnameValidation = name => ({
  validate: value => (value?.trim() !== name ? t('validation.general') : true),
});

export const getServiceFieldsValidateMessage = () => ({
  name: `${getDefaultMaxMessage()} ${getDefaultValidateMessage()}`,
  code: `${t('validation.maxLength', [255])} ${t('validation.invalidCode')}`,
  description: t('validation.maxLength', [255]),
  path: t('validation.invalidDomain'),
});

export const getUserValidation = (userOptions, selectedSystemCode) => ({
  validate: async value => {
    if (!value) {
      return null;
    }

    const selectedOption = userOptions?.find(option => option.value === value);

    if (!selectedOption) {
      return null;
    }

    if (!selectedOption.meta?.email) {
      return t('pages.adminUserCreatePage.noEmailError');
    }

    if (selectedOption.meta?.status === userStatusKeys.NEW) {
      return t('pages.adminUserCreatePage.invalidUserStatus');
    }

    try {
      const userData = await api.users.actualizeUserById(value, { code: selectedSystemCode });

      if (!userData.data?.email) {
        return t('pages.adminUserCreatePage.noEmailError');
      }

      if (userData.data?.admin) {
        return t('pages.adminUserCreatePage.unableToAddAdminError');
      }

      if (userData.data?.status === userStatusKeys.NEW) {
        return t('pages.adminUserCreatePage.invalidUserStatus');
      }
    } catch (e) {
      return getApiErrorMessage(e, t);
    }

    return null;
  },
});

export const getServiceNameValidation = (systemId, serviceId, groupUuid) => ({
  validate: async value => {
    const serviceNameRegex = new RegExp(`[a-zA-Z0-9а-яА-ЯёЁ_][a-zA-Z0-9а-яА-ЯёЁ _\\-\\.+]*`, 'g');

    if (value.match(serviceNameRegex)?.[0]?.length !== value.length) {
      return getServiceFieldsValidateMessage().name;
    }

    const requestData = {
      system: systemId,
      offset: 0,
      limit: 1,
      name: value,
      groupUuid,
    };
    const itemsWithSameParamsRepositories = requestData?.groupUuid
      ? await api.repositories.getRepositories(requestData)
      : null;
    const checkResponse =
      itemsWithSameParamsRepositories &&
      itemsWithSameParamsRepositories.data?.data?.length &&
      (!serviceId || itemsWithSameParamsRepositories.data.data[0].uuid !== serviceId)
        ? getNameAlreadyInUseErrorMessage()
        : null;
    const itemsWithSameParams = await api.services.getAllServices(requestData);

    return value.toLowerCase() === conflictCode ||
      (itemsWithSameParams.data?.data?.length &&
        (!serviceId ||
          itemsWithSameParams.data.data[0].uuid !== serviceId ||
          value === conflictName))
      ? getNameAlreadyInUseErrorMessage()
      : checkResponse || null;
  },
});

export const getServiceCodeValidation = (systemId, serviceId, groupUuid) => ({
  validate: async value => {
    const serviceCodeRegex = new RegExp(`[a-z0-9_\\.][a-z0-9_\\-\\.+]*`, 'g');

    if (value.match(serviceCodeRegex)?.[0]?.length !== value.length) {
      return getServiceFieldsValidateMessage().code;
    }

    const requestData = {
      system: systemId,
      offset: 0,
      limit: 1,
      code: value,
      groupUuid,
    };
    const itemsWithSameParamsRepositories = requestData?.groupUuid
      ? await api.repositories.getRepositories(requestData)
      : null;
    const checkResponse =
      itemsWithSameParamsRepositories &&
      itemsWithSameParamsRepositories.data?.data?.length &&
      (!serviceId ||
        value === conflictCode ||
        itemsWithSameParamsRepositories.data.data[0].uuid !== serviceId)
        ? getCodeAlreadyInUseErrorMessage()
        : null;
    const itemsWithSameParams = await api.services.getAllServices(requestData);

    return value.toLowerCase() === conflictCode ||
      (itemsWithSameParams.data?.data?.length &&
        (!serviceId || itemsWithSameParams.data.data[0].uuid !== serviceId))
      ? getCodeAlreadyInUseErrorMessage()
      : checkResponse || null;
  },
});

export const getReleaseNameValidation = (systemId, releaseId) => ({
  validate: async value => {
    const itemsWithSameParams = await api.releases.getAllReleases({
      system: systemId,
      offset: 0,
      limit: 1,
      name: value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!releaseId || itemsWithSameParams.data.data[0].uuid !== releaseId)
      ? getNameAlreadyInUseErrorMessage()
      : null;
  },
});

export const getMilestoneNameValidation = (systemId, milestoneId) => ({
  validate: async value => {
    const itemsWithSameParams = await api.milestones.getAllMilestones({
      system: systemId,
      offset: 0,
      limit: 1,
      name: value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!milestoneId || itemsWithSameParams.data?.data[0].uuid !== milestoneId)
      ? getNameAlreadyInUseErrorMessage()
      : null;
  },
});

export const getSystemTierNameValidation = (systemId, isExternal, tierId, tiers, fieldUuid) => ({
  validate: async value => {
    if (isExternal) {
      return !value || value.match(systemAndTierCodeRegex)?.[0]?.length === value.length
        ? null
        : getTierNameErrorMessage();
    }

    if (value.match(systemAndTierCodeRegex)?.[0]?.length !== value.length) {
      return getTierNameErrorMessage();
    }

    if (
      tiers?.length &&
      tiers?.filter(tier => tier?.fieldUuid !== fieldUuid)?.find(({ code }) => code === value)
    ) {
      return t('validation.uniqueTierNameMessage');
    }

    if (!systemId) {
      return null;
    }

    const itemsWithSameParams = await api.tiers.getTiers({
      system: systemId,
      offset: 0,
      limit: 1,
      code: value,
    });

    if (tierId && itemsWithSameParams.data?.data.find(item => item.uuid === tierId)) {
      return null;
    }

    return itemsWithSameParams.data?.data?.length ? getNameAlreadyInUseErrorMessage() : null;
  },
});

export const getServerTierNameValidation = serverTierId => ({
  validate: async value => {
    if (value.match(systemAndTierCodeRegex)?.[0]?.length !== value.length) {
      return getTierNameErrorMessage();
    }

    const itemsWithSameParams = await api.serverTiers.getAllServerTiers({
      offset: 0,
      limit: 1,
      name: value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!serverTierId || itemsWithSameParams.data.data[0].uuid !== serverTierId)
      ? getNameAlreadyInUseErrorMessage()
      : null;
  },
});

export const getServerTierClusterUrlValidation = serverTierId => ({
  validate: async value => {
    const urlMessage = validateUrlValue(value, true);

    if (urlMessage?.length) {
      return urlMessage;
    }

    const itemsWithSameParams = await api.serverTiers.getAllServerTiers({
      offset: 0,
      limit: 1,
      clusterApiUrl: value.trim().at(-1) === '/' ? value.trim().slice(0, -1) : value,
    });

    return itemsWithSameParams.data?.data?.length &&
      (!serverTierId || itemsWithSameParams.data.data[0].uuid !== serverTierId)
      ? t('validation.clusterUrlAlreadyInUse')
      : null;
  },
});

export const dictionaryValueErrorMessage = t('validation.duplicated');

export const notificationNameValue = new RegExp(`[a-zA-Z0-9_][a-zA-Z0-9 _\\-\\.]*`, 'g');

export const quoteValidation = {
  validate: value => (!parseFloat(value) ? t('validation.required') : true),
};

export const phoneValidation = {
  validate: value => {
    const valueLength = value?.match(phoneRegex)?.join('').length;

    if (valueLength === 1 || !value) {
      return;
    }

    if (valueLength !== 11) {
      return getPhoneMessage();
    }
  },
};

export const emailValidation = {
  validate: value => {
    const valueLength = value?.match(emailRegex)?.join('');

    if (!value) {
      return;
    }

    if (valueLength?.length !== value.length) {
      return t('validation.invalidEmail');
    }
  },
};

export const getValidUrlValidation = {
  validate: value => validateUrlValue(value, true),
};

export const getTierHostUrlValidation = (hostUrls, indexNumber) => ({
  validate: async value => {
    if (!value?.trim().length) {
      return;
    }

    const urlValue = value.replace(protocolNames.http, '').replace(protocolNames.https, '');

    const urlMessage = validateUrlValue(value, true);

    if (urlMessage?.length) {
      return urlMessage;
    }

    const DNSlabels = urlValue.split('.');

    if (DNSlabels.find(element => element.length > 63)) {
      return t('validation.dnsMaxLength');
    }

    const hostUrlsValues = hostUrls
      ?.filter((item, index) => index !== indexNumber)
      ?.map(item => item?.value);

    return urlValue && hostUrlsValues?.length && hostUrlsValues.includes(value)
      ? t('validation.urlInUse')
      : null;
  },
});
