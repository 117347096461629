import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchServerTierDevices = createAsyncThunk(
  'serverTiers/fetchServerTierDevices',
  async params => {
    const { data } = await api.serverTiers.getServerTierDevices(params);
    return data;
  }
);

export const fetchServerTierDevice = createAsyncThunk(
  'systems/fetchServerTierDevice',
  async params => {
    const { data } = await api.serverTiers.getServerTierDevice(params);
    return data;
  }
);
