import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '@patternfly/react-core/dist/styles/base.css';
import moment from 'moment';

import useComponentVisible from 'hooks/useComponentVisible';

import * as S from './styles';

const DatePickerComponent = React.memo(({ field, disabled, minDate, maxDate, label }) => {
  const { i18n } = useTranslation();
  const [popoverClassName, setPopoverClassName] = useState('hiddenDatePicker');
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

  const onChange = date => {
    field.onChange(moment(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (isComponentVisible) {
      setTimeout(() => {
        setIsComponentVisible(!isComponentVisible);
      }, 50);
      setTimeout(() => {
        setPopoverClassName(null);
      }, 300);
    }
  }, []);

  const onClick = () => {
    if (!disabled) {
      setIsComponentVisible(!isComponentVisible);
    }
  };

  const rangeValidator = date => {
    if (minDate && date < new Date(new Date(minDate) - 86400000)) {
      return true;
    }

    if (maxDate && date > new Date(maxDate)) {
      return true;
    }
  };

  return (
    <div ref={ref}>
      <S.StyledDatePicker
        value={field.value || undefined}
        locale={i18n.language.slice(0, 2)}
        data-datepicker-for={label}
        isDisabled={disabled}
        validators={minDate ? [rangeValidator] : []}
        popoverProps={{
          enableFlip: false,
          className: popoverClassName,
          hideOnOutsideClick: true,
          animationDuration: 0,
        }}
        onChange={(e, str) => onChange(str)}
        onClick={onClick}
        weekStart={1}
      />
    </div>
  );
});

const DatePickerController = ({ name, control, rules, label, ...inputProps }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={field => <DatePickerComponent {...field} {...inputProps} label={label} />}
  />
);

export default DatePickerController;
