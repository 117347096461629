import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';

import { getUserPermissions, getUserUuid } from 'hooks/useKeycloakAuth';
import { getNewSystem } from 'store/systems/actions';
import { changeCurrentSystem, resetCurrentSystem } from 'store/systems/reducers';
import { currentSystemLoadingSelector, currentSystemSelector } from 'store/systems/selectors';
import { userPermissionsSelector } from 'store/user/selectors';
import { socketActionKeys } from 'constants';
import paths from 'constants/paths';

import useSocketAsync from '../../hooks/useSocketAsync';
import { addNotificationsCount } from '../../store/notificationsCount/reducers';

const AppSocketWrapper = props => {
  const {
    params: { systemId },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentSystem = useSelector(currentSystemSelector);
  const currentSystemLoading = useSelector(currentSystemLoadingSelector);
  const userPermissions = useSelector(userPermissionsSelector);
  const systemUuid = systemId || currentSystem?.uuid;

  useSocketAsync({
    topic: 'users',
    debounceTime: 600,
    filterFn: ({ object, action }) =>
      object?.uuid === getUserUuid() && action === 'TOKEN_REFRESHED',
    onMessage: () => {
      getUserPermissions(dispatch).then(async () => {
        if (systemUuid) {
          const newSystem = await getNewSystem(systemUuid, userPermissions);
          dispatch(changeCurrentSystem(newSystem));
        }
      });
    },
  });

  useSocketAsync({
    topic: 'notifications',
    onMessage: data => {
      dispatch(addNotificationsCount({ uuid: data.object.system?.uuid }));
    },
  });

  const resetSystem = () => {
    dispatch(resetCurrentSystem());
    return history.push(generatePath(paths.routePaths.main));
  };

  useSocketAsync({
    topic: 'systems',
    debounceTime: 600,
    filterFn: ({ object }) => object?.uuid === systemUuid,
    onMessage: async ({ action }) => {
      const isBlocked =
        (history.location.pathname && history.location.pathname.split('/')[4] === 'edit') ||
        history.location.pathname.includes('users/new');
      if (isBlocked) {
        return;
      }

      try {
        if (action === 'DELETED') {
          resetSystem();
        }

        if (systemUuid) {
          const newSystem = await getNewSystem(systemUuid, userPermissions);
          dispatch(changeCurrentSystem(newSystem));
        }
      } catch (e) {
        if (e && systemUuid && !currentSystemLoading) {
          resetSystem();
        }
      }
    },
  });

  useSocketAsync({
    topic: 'tiers/releases',
    filterBySystemUuid: systemUuid,
    options: { callBackThrottleTime: 1300 },
    onMessage: async ({ action }) => {
      if (systemUuid && action !== socketActionKeys.MODIFIED) {
        const newSystem = await getNewSystem(systemUuid, userPermissions);
        dispatch(changeCurrentSystem(newSystem));
      }
    },
  });

  return props.children;
};

export default AppSocketWrapper;
