import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const contactPersonsSlice = createSlice({
  name: 'contactPersons',
  initialState: { data: [], total: 0, isLoading: true, isError: null },
  extraReducers: {
    [actions.fetchContactPersons.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchContactPersons.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.total = meta.total;
      state.isLoading = false;
      state.isError = null;
    },
    [actions.fetchContactPersons.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
  },
});

export default contactPersonsSlice.reducer;
