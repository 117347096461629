export const issuesAction = {
  edit: 'edit',
};

export const systemIssuesPaths = {
  newIssue: `/new`,
  editIssue: `/:issuesId/:action(${issuesAction.edit})`,
  edit: `/:action(${issuesAction.edit})`,
  issues: `/:issuesId`,
};
