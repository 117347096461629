import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { issuesSelector } from 'store/issues/selectors';
import { issueStatusKeys, issueOptions, getIssueStatusNames } from 'constants';
import CheckBoxSelector from 'components/Common/CheckBoxSelector';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';

import IssuesTable from './IssuesTable';

function Issues({ addIssueButton, isShowFilters, setIsShowFilters, currentTotal }) {
  const { t } = useTranslation();
  const history = useHistory();
  const issueStatusNames = getIssueStatusNames();
  const {
    params: { systemId, milestoneId },
  } = useRouteMatch();
  const { state } = history.location;
  const defaultFilterParams = state?.tasksStatus
    ? {
        status: state.tasksStatus,
      }
    : undefined;

  const { notActiveSystemMessage, notActiveOrErrorSystemMessage, optionsFilterByScopes } =
    useUserPermissions();
  const { data, total, isLoading, isError } = useSelector(issuesSelector);
  const changeAllIssues = useAsync();
  const deleteIssues = useAsync();

  const deleteMessage = t('pages.systemIssuesPage.issueIncludedInRelease');

  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'milestoneQuery',
      name: t('common.milestone'),
    },
    {
      id: 'status',
      type: 'multiSelect',
      name: t('common.status'),
      options: formatFilterOptions(issueStatusNames),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];

  const [isMassChecked, setIsMassChecked] = useState(false);
  const [excludedIds, setExcludedIssueIds] = useState([]);

  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedIssuesUuids, setcheckedIssuesUuids] = useState([]);

  const isCantMassEdit = !systemId;
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'issues',
    defaultFilterParams
  );

  const changeAllIssuesClick = (status, uuid = []) => {
    changeAllIssues.run(
      api.issues.changeAllIssues(
        !isMassChecked
          ? {
              issueIds: uuid.length ? uuid : checkedIssuesUuids,
              status,
              milestone: { uuid: milestoneId },
            }
          : { excludedIssueIds: excludedIds, status, milestone: { uuid: milestoneId } }
      )
    );
  };

  useEffect(() => {
    if (changeAllIssues.isSuccess || deleteIssues.isSuccess) {
      if (isMassChecked) {
        setExcludedIssueIds([]);
      }
    }
  }, [changeAllIssues.isSuccess, deleteIssues.isSuccess]);

  useEffect(() => {
    if (systemId) {
      setIsMassChecked(false);
      setExcludedIssueIds([]);
      setcheckedIssuesUuids([]);
      setAllRenderUuids([]);
    }
  }, [systemId]);

  const allCheckedIssues = data?.filter(item => checkedIssuesUuids.includes(item.uuid));

  const buttonMenuOptions = useMemo(() => {
    const checkedStatus = status =>
      allCheckedIssues?.length !== allCheckedIssues?.filter(item => item.status === status)?.length;
    const checkedReleases = () =>
      allCheckedIssues?.length !== allCheckedIssues?.filter(item => !item.releases.length)?.length;

    const taskSolvedOptionDisabled =
      checkedStatus(issueStatusKeys.IMPLEMENTED) &&
      allCheckedIssues?.some(issue => !issue.totalSummary.tierReleaseTotal?.prod);

    const taskSolvedOption = {
      id: issueOptions.taskSolved,
      name: t('pages.systemIssuesPage.markAsResolved'),
      isDisabled: notActiveSystemMessage || taskSolvedOptionDisabled,
      tooltip:
        notActiveSystemMessage ||
        (checkedStatus(issueStatusKeys.IMPLEMENTED)
          ? t('pages.systemIssuesPage.issueIsNotResolved')
          : t('pages.systemIssuesPage.noReleaseOnProdTier')),
      scope: scopes.issue.issueUpdateStatus,
    };

    // const sendForRevisionOption = {
    //   id: issueOptions.sendForRevision,
    //   name: 'В доработку',
    //   isDisabled: sendForRevisionOptionDisabled,
    // };

    const deleteOption = {
      id: issueOptions.delete,
      name: t('common.delete'),
      isDisabled:
        notActiveOrErrorSystemMessage || (checkedStatus(issueStatusKeys.NEW) && checkedReleases()),
      tooltip: notActiveOrErrorSystemMessage || deleteMessage,
      scope: scopes.issue.issueDelete,
    };

    return optionsFilterByScopes([
      taskSolvedOption,
      // sendForRevisionOption,
      {
        id: issueOptions.edit,
        name: t('common.edit'),
        isHidden: checkedIssuesUuids.length > 1,
        isDisabled: notActiveSystemMessage,
        tooltip: notActiveSystemMessage,
        scope: scopes.issue.issueUpdate,
      },
      deleteOption,
    ]);
  }, [data, checkedIssuesUuids, notActiveSystemMessage, notActiveOrErrorSystemMessage]);

  const buttonFilterChange = id => {
    if (id === issueOptions.taskSolved) {
      return changeAllIssuesClick(issueStatusKeys.DONE);
    }

    if (id === issueOptions.sendForRevision) {
      return changeAllIssuesClick(issueStatusKeys.IN_PROGRESS);
    }

    if (id === issueOptions.delete) {
      return deleteIssues.run(
        api.issues.deleteIssues(
          !isMassChecked
            ? { issueIds: checkedIssuesUuids, milestone: { uuid: milestoneId } }
            : { excludedIssueIds: excludedIds, milestone: { uuid: milestoneId } }
        )
      );
    }

    return history.push(
      generatePath(
        milestoneId
          ? paths.systemMilestoneFullPaths.milestoneIssuesEdit
          : paths.systemIssuesFullPaths.editIssue,
        {
          milestoneId: milestoneId || allCheckedIssues[0]?.milestone?.uuid,
          action: paths.issuesAction.edit,
          issuesId: checkedIssuesUuids[0],
        }
      )
    );
  };

  return (
    <>
      {isShowFilters && (
        <div className="d-sm-flex flex-row align-items-end mx-4">
          {!isCantMassEdit && (
            <div className="mb-3">
              <CheckBoxSelector
                checkedUuids={checkedIssuesUuids}
                setCheckedUuids={setcheckedIssuesUuids}
                allRenderUuids={allRenderUuids}
                total={allRenderUuids.length}
                massTotal={currentTotal}
                withoutExcludedIds
                isMassChecked={isMassChecked}
                setIsMassChecked={setIsMassChecked}
                excludedIds={excludedIds}
                declensionItems={[
                  t('plural.selected1'),
                  t('plural.selected2'),
                  t('plural.selected3'),
                ]}
                dropdownText={t('pages.systemIssuesPage.issues')}
              />
            </div>
          )}
          {FilterComponent}

          {(isMassChecked || !!checkedIssuesUuids.length) && !isCantMassEdit && (
            <div className=" mb-3">
              <Dropdown
                id={systemId}
                optionData={buttonMenuOptions}
                onFilterChange={buttonFilterChange}
                isBlueButtonDropdown
                positionLeft
              />
            </div>
          )}
        </div>
      )}
      <IssuesTable
        filterParams={filterParams}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        currentTotal={currentTotal}
        withCheckboxes={!isCantMassEdit}
        checkedIssuesUuids={checkedIssuesUuids}
        setcheckedIssuesUuids={setcheckedIssuesUuids}
        setAllRenderUuids={setAllRenderUuids}
        changeAllIssuesClick={changeAllIssuesClick}
        isMassChecked={isMassChecked}
        setExcludedIssueIds={setExcludedIssueIds}
        excludedIds={excludedIds}
        data={data}
        total={total}
        isLoading={isLoading}
        deleteIssues={deleteIssues}
        changeAllIssues={changeAllIssues}
        hasSelectedFilters={hasSelectedFilters}
        addButton={addIssueButton}
        isError={isError}
      />
    </>
  );
}

export default Issues;
