import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import { serverTiersSelector } from 'store/serverTiers/selectors';
import { fetchServerTiers } from 'store/serverTiers/actions';
import { clearServerTiersState } from 'store/serverTiers/reducers';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent, { getServerTierAvailableStatuses } from 'components/Common/StatusComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { getTierStatusNames, limitsKeys } from 'constants';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

import ModalStatistic from './ModalStatistic';
import ClusterActions from './ClusterActions';
import ClusterParams from './ClusterParams';

import * as S from './styles';

function ClusterTable({
  filterParams,
  hasSelectedFilters,
  addButton,
  isShowFilters,
  setIsShowFilters,
}) {
  const { t } = useTranslation();
  const serverTierAvailableStatuses = getServerTierAvailableStatuses();
  const tierStatusNames = getTierStatusNames();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const { isModalVisible, toggleModal, modalData } = useModal();
  const { data, total, isLoading, isError } = useSelector(serverTiersSelector);
  const totalSummaryTierStatusKeys = {
    active: t('pages.systemTierPage.tiersAvailable'),
    archiveInProgress: tierStatusNames.ARCHIVE_IN_PROGRESS,
    error: tierStatusNames.ERROR,
    inProgress: tierStatusNames.NEW,
    pending: tierStatusNames.PENDING,
    removalInProgress: tierStatusNames.REMOVAL_IN_PROGRESS,
    total: t('pages.systemTierPage.tiersTotal'),
  };

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'location',
      label: t('pages.adminServerTiers.location'),
      withSort: true,
    },
    {
      key: 'tiers',
      label: t('common.tiers'),
      minWidth: '100px',
    },
    {
      key: 'resources',
      label: t('pages.adminServerTiers.resourcesConsuming'),
      colSpan: '3',
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
      minWidth: '120px',
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const tooltipMessage = tierTotal => {
    const keys = Object.keys(tierTotal).filter(key => tierTotal[key]);

    return (
      <span className="pre-line">
        {keys.map(key => `${totalSummaryTierStatusKeys[key]} - ${tierTotal[key]} \n`)}
      </span>
    );
  };

  const onClickGeneratePath = (tab, uuid) => ({
    pathname: generatePath(paths.serverTiersPaths.show, {
      serverTierId: uuid,
    }),
    state: {
      currentTab: tab,
    },
  });

  const requestFunction = params => dispatch(fetchServerTiers(params));

  useSocketAsync({
    topic: 'server-tiers',
    filterFn: ({ object }) => data.find(item => item.uuid === object?.uuid),
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'lastUpdatedAt',
      ascending: false,
      ...filterParams?.date,
      date: undefined,
    }),
    [filterParams]
  );

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(total));
    }
  }, [total, hasSelectedFilters]);

  useEffect(() => () => dispatch(clearServerTiersState()), []);

  const resourcesContent = (clusterResources, type) => (
    <ClusterParams
      resource={clusterResources.find(resource => resource.code === type)}
      type={type}
      checkedUnit={type === limitsKeys.CPU}
    />
  );

  const formattedBodyData = data?.map((cluster, index) => ({
    id: `${cluster.uuid}${index}`,
    data: [
      {
        key: 'name',
        content: cluster.name && (
          <LinkWithPermission
            scope={scopes.platform.clusterViewForm}
            isPlatformScope
            to={generatePath(paths.serverTiersPaths.show, { serverTierId: cluster.uuid })}
            isTableLink
          >
            {cluster.name}
          </LinkWithPermission>
        ),
      },
      {
        key: 'location',
        content: cluster.location,
      },
      {
        key: 'tiers',
        content: (
          <div className="w-content">
            <Tooltip
              content={tooltipMessage(cluster?.totalSummary?.tierTotal)}
              className={cluster?.totalSummary?.tierTotal.total > 0 ? undefined : 'd-none'}
              exitDelay={150}
              animationDuration={150}
            >
              <Link to={onClickGeneratePath(2, cluster.uuid)}>
                <div className="d-flex">
                  <S.EnterpriseIco className="mr-2" />
                  {cluster?.totalSummary?.tierTotal.total || 0}
                </div>
              </Link>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'resources',
        content: resourcesContent(cluster.clusterResources, limitsKeys.CPU),
        rowIndex: 1,
      },
      {
        key: 'resources',
        content: resourcesContent(cluster.clusterResources, limitsKeys.MEMORY),
        rowIndex: 2,
      },
      {
        key: 'resources',
        content: resourcesContent(cluster.clusterResources, limitsKeys.STORAGE),
        rowIndex: 3,
      },
      {
        key: 'status',
        content: (
          <StatusComponent
            statusId={cluster.status}
            statuses={serverTierAvailableStatuses}
            objectUuid={cluster.uuid}
            objectType="SERVER_TIER"
          />
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={cluster?.lastUpdatedBy}
            scope={scopes.platform.userViewForm}
            isTableLink
            linkTo={
              cluster.lastUpdatedBy?.uuid
                ? generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.details, {
                    userId: cluster.lastUpdatedBy.uuid,
                  })
                : undefined
            }
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: cluster.lastUpdatedAt,
      },
      {
        key: 'actions',
        content: (
          <ClusterActions
            cluster={cluster}
            requestFunction={tableRef.current?.requestDataWithParams}
          />
        ),
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        tableName="clusters"
        total={total}
        isLoading={isLoading}
        withStandartPagination
        hasSelectedFilters={hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />

      <ModalStatistic
        toggleModal={toggleModal}
        isModalVisible={isModalVisible}
        serverTierUuid={modalData}
      />
    </>
  );
}

export default ClusterTable;
