import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, WizardStep } from '@patternfly/react-core';

import useStorage from 'hooks/useStorage';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';

const defaultUnfilledStagesData = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
};

const useWizardForm = ({
  formMethods,
  isLoading,
  unfilledStorageKey,
  formStorageKey,
  activeStepStorageKey,
  closeForm,
  onSubmit,
  isValidForm,
  steps,
  currentUuid,
  withBackButton,
  isEditMode,
  saveLabel = 'common.save',
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const unfilledStages = useStorage(unfilledStorageKey);
  const { storageData, setToStorage, clearStorageData } = useStorage(formStorageKey);
  const activeStepStorage = useStorage(activeStepStorageKey);
  const [unfilledStagesData, setUnfilledStagesData] = useState([]);
  const [currentActiveStep, setCurrentActiveStep] = useState(activeStepStorage.storageData || 1);

  const clearData = () => {
    setUnfilledStagesData(defaultUnfilledStagesData);
    unfilledStages.clearStorageData();
    clearStorageData();
    activeStepStorage.clearStorageData();
  };

  const errors = Object.keys(formMethods.formState.errors) || [];
  const isStepIndex = formMethods.watch('step') || 1;
  const uuid = isEditMode && (storageData?.uuid || formMethods.watch('uuid'));
  const currentStep = steps[isStepIndex - 1];
  const isFirstStep = isEditMode ? isStepIndex === 1 : !withBackButton && isStepIndex === 1;
  const isLastStep = isStepIndex === steps?.length;
  const isValid =
    !Object.values(unfilledStagesData)?.find(item => item.join('')?.length) &&
    !errors.length &&
    isValidForm;
  const isDirty =
    !!Object.keys(formMethods.formState.dirtyFields).length ||
    !!Object.values(unfilledStagesData)?.find(item => item.join('')?.length);
  const isCanValidate = isEditMode ? (currentUuid || uuid) && !isLoading : !isLoading;

  useEffect(() => {
    if (isDirty && isCanValidate) {
      setToStorage(formMethods.getValues());
      const subscription = formMethods.watch(setToStorage);

      return () => subscription?.unsubscribe();
    }
  }, [formMethods.watch, isDirty]);

  useEffect(() => {
    if (storageData?.uuid) {
      setUnfilledStagesData(unfilledStages.storageData || defaultUnfilledStagesData);
    } else {
      setUnfilledStagesData({
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
      });
    }
  }, []);

  useEffect(() => {
    if (!isDirty && currentUuid) {
      setUnfilledStagesData(defaultUnfilledStagesData);
      unfilledStages.setToStorage(defaultUnfilledStagesData);
      clearStorageData();
    }
  }, [isDirty]);

  useEffect(() => {
    if (!uuid && isStepIndex > currentActiveStep) {
      setCurrentActiveStep(isStepIndex);
      activeStepStorage.setToStorage(isStepIndex);
    }
    if (uuid) {
      setCurrentActiveStep(steps?.length);
      if (isDirty) {
        activeStepStorage.setToStorage(steps?.length);
      }
    }
  }, [uuid, isStepIndex, currentActiveStep]);

  const checkedformData = (step, stepIndex, isValidate) => {
    if (!step || !isValidate) {
      return;
    }

    if (step?.fieldsToValidate?.length) {
      step.fieldsToValidate.forEach((field, index) => {
        if (!uuid || isDirty) {
          setUnfilledStagesData((prev = {}) => {
            if (step?.fieldsNotRequired?.includes(field)) {
              prev[stepIndex][index] = errors.includes(field) ? field : '';
              unfilledStages.setToStorage(prev);

              return prev;
            }

            prev[stepIndex][index] =
              !formMethods.getValues(field) || errors.includes(field) ? field : '';
            unfilledStages.setToStorage(prev);

            return prev;
          });
        }
      });
    }
  };

  const valudedStep = async step => {
    if (isCanValidate) {
      await formMethods.trigger();
    }

    checkedformData(currentStep, step, isCanValidate);
  };

  useEffect(() => {
    if (
      isStepIndex &&
      (currentActiveStep > isStepIndex || unfilledStagesData[isStepIndex]?.length)
    ) {
      valudedStep(isStepIndex);
    }
  }, [isStepIndex]);

  useEffect(() => {
    if (
      isStepIndex &&
      (currentActiveStep > isStepIndex || unfilledStagesData[isStepIndex]?.length)
    ) {
      if (
        isCanValidate &&
        (unfilledStagesData[isStepIndex]?.length || errors.length) &&
        currentStep?.fieldsToValidate?.length
      ) {
        currentStep.fieldsToValidate.forEach(field => formMethods.trigger(`${field}`));
      }

      checkedformData(currentStep, isStepIndex, isCanValidate);
    }
  }, [errors.length]);

  const onChangeStep = (e, activeStep) => {
    if (activeStep === 1 && e.target?.innerHTML === t('common.back')) {
      if (withBackButton && e.target?.innerHTML === t('common.back') && activeStep === 1) {
        activeStepStorage.clearStorageData();
      }

      return history.goBack();
    }

    if (!isLoading) {
      if (
        e.target?.innerHTML === t('common.forward') ||
        e.target?.id === 'navItem' ||
        (activeStep !== 1 &&
          e.target?.innerHTML === t('common.back') &&
          activeStep <= currentActiveStep)
      ) {
        checkedformData(currentStep, isStepIndex, isCanValidate);
      }
    }
  };

  const saveButton = (
    <div className="mb-4">
      <Button
        onClick={e => formMethods.handleSubmit(onSubmit)(e)}
        isDisabled={!isValid || isLoading}
        isLoading={isLoading}
      >
        {t(saveLabel)}
      </Button>
    </div>
  );

  const WizardComponent = (
    <div className="h-100">
      <S.WizardStyled $isLastStep={isLastStep} onClick={e => onChangeStep(e, isStepIndex)}>
        {steps.map(step => (
          <WizardStep
            id={step.id}
            key={step.key}
            status={
              unfilledStagesData[step.id]?.length &&
              [...step.fieldsToValidate, ...(step.id === 4 ? ['developers'] : [])].includes(
                unfilledStagesData[step.id].filter(item => item?.length)[0]
              )
                ? 'error'
                : 'default'
            }
            name={
              <S.NavItem id="navItem" onClick={e => onChangeStep(e, isStepIndex)}>
                {step.getName()}&nbsp;
              </S.NavItem>
            }
            footer={{
              nextButtonText: t('common.forward'),
              backButtonText: t('common.back'),
              ...(withBackButton && step.id === 1 ? { isBackDisabled: false } : {}),
              isBackHidden: withBackButton ? !withBackButton : isFirstStep,
            }}
          >
            <form onSubmit={e => formMethods.handleSubmit(onSubmit)(e)} className="h-100">
              <S.WizardBlockWrapper $fullWidth={step?.fullWidth}>
                {step.component}
              </S.WizardBlockWrapper>
              <S.CanselButtonWrapper
                $isBackHidden={withBackButton ? !withBackButton : isFirstStep}
                $isLastStep={isLastStep}
              >
                <Button
                  variant="link"
                  onClick={() => {
                    closeForm?.();
                    setTimeout(() => {
                      clearData();
                    }, 100);
                  }}
                >
                  {t('common.cancel')}
                </Button>
              </S.CanselButtonWrapper>
              <S.SaveButtonWrapper>
                {!isValid ? (
                  <Tooltip
                    content={!isLoading && tooltipFormDisableMessage()}
                    className={!isLoading ? undefined : 'd-none'}
                    exitDelay={150}
                    animationDuration={150}
                  >
                    {saveButton}
                  </Tooltip>
                ) : (
                  saveButton
                )}
              </S.SaveButtonWrapper>
            </form>
          </WizardStep>
        ))}
      </S.WizardStyled>
    </div>
  );

  return {
    WizardComponent,
    clearData,
  };
};

export default useWizardForm;
