import React from 'react';
import { useTranslation } from 'react-i18next';

import PageLayout from 'components/UI/PageLayout';
import AuditTable from './AuditTable';

function AuditPage() {
  const { t } = useTranslation();
  const title = (
    <div>
      <div>{t('common.audit')}</div>
      <div className="m-0 mt-2 font-14 font-weight-bold">
        {t('pages.adminAuditPage.eventsHistoryPeriod', [window._env_.AUDIT_PERIOD_DAYS])}
      </div>
    </div>
  );

  return (
    <PageLayout title={title} fullHeightPage withoutSidePadding withSystemSelector>
      <AuditTable />
    </PageLayout>
  );
}

export default AuditPage;
