import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import paths from 'constants/paths';
import StatusComponent, { getBackupStatuses } from 'components/Common/StatusComponent';
import NewDataTable, { types } from 'components/Common/NewDataTable';

import EditFrequency from './EditFrequency';

function BackupTable({
  filterParams,
  setIsShowFilters,
  hasSelectedFilters,
  setTierBackupsTotal,
  isAdministration,
  isShowFilters,
  tableName = 'backupComponents',
}) {
  const { t } = useTranslation();
  const backupStatuses = getBackupStatuses();
  const tableRef = useRef();
  const history = useHistory();
  const {
    params: { systemId, systemType, tierId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const { run, data, isLoading, isSuccess, isRejected, isError } = useAsync();

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      tierUuid: tierId,
      orderBy: 'lastBackup.backupEndDateTime',
      ascending: false,
      ...filterParams?.date,
      date: undefined,
    }),
    [filterParams, tierId]
  );

  const requestFunction = params => run(api.backups.getBackups(params));
  const refreshTableData = () => tableRef.current?.requestDataWithParams({ isSilentMode: true });

  const headerData = [
    {
      key: 'name',
      label: t('common.component'),
      withSort: true,
      width: '200px',
    },
    {
      key: 'componentFrequency',
      label: t('common.frequency'),
      width: '600px',
    },
    {
      key: 'lastBackup.status',
      label: t('pages.systemTierPage.copyStatus'),
      withSort: true,
    },
    {
      key: 'lastBackup.backupEndDateTime',
      label: t('pages.systemTierPage.copyDate'),
      withSort: true,
      width: '200px',
    },
  ];

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(data?.meta?.total));
    }
  }, [data?.meta?.total, hasSelectedFilters]);

  useEffect(() => {
    if (setTierBackupsTotal) {
      setTierBackupsTotal(data?.meta?.total);
    }
  }, [data?.meta?.total]);

  const onNameClick = item => {
    history.push({
      pathname: systemType
        ? generatePath(paths.systemTiersFullPaths.componentId, {
            systemId,
            systemType,
            tierId,
            componentName: item?.name,
            componentId: item?.uuid,
          })
        : generatePath(paths.adminFullPaths.backups + paths.tierComponentsPaths.component, {
            componentName: item?.name,
            componentId: item?.uuid,
          }),
      state: {
        date: filterParams?.date,
      },
    });
  };

  const formattedBodyData = data?.data.map(item => ({
    id: item?.uuid,
    data: [
      {
        key: 'name',
        content: item?.name,
        type:
          checkedPermissions(
            isAdministration
              ? scopes.platform.backupViewComponent
              : scopes.tier.tierViewBackupComponent,
            isAdministration
          ) && types.textLink,
        onClick: () => onNameClick(item),
      },
      {
        key: 'componentFrequency',
        content: (
          <EditFrequency
            {...item?.frequency}
            componentUuid={item?.uuid}
            tierUuid={item?.tierUuid}
            requestFunction={refreshTableData}
            isAdministration={isAdministration}
            withStatus
          />
        ),
      },
      {
        key: 'lastBackup.status',
        content: <StatusComponent statusId={item?.lastBackup.status} statuses={backupStatuses} />,
      },
      {
        key: 'lastBackup.backupEndDateTime',
        type: types.relativeTime,
        content: item?.lastBackup.backupEndDateTime,
      },
    ],
  }));

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      requestFunction={requestFunction}
      requestParams={requestParams}
      isLoading={isSuccess || isRejected ? isLoading : true}
      tableName={tableName}
      bodyData={formattedBodyData}
      total={data?.meta?.total}
      withStandartPagination
      hasSelectedFilters={hasSelectedFilters}
      showNoDataPerFiltersMessage={isShowFilters}
      isError={isError}
    />
  );
}

export default BackupTable;
