import React, { useState } from 'react';
import { formatFilterOptions } from 'utils';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { getBackupStatusNames } from 'constants';
import { useTranslation } from 'react-i18next';

import BackupTable from './BackupTable';

function TierBackups({ setTierBackupsTotal, isAdministration, tableName }) {
  const { t } = useTranslation();
  const backupStatusNames = getBackupStatusNames();

  const filterData = [
    {
      id: 'q',
      name: t('common.component'),
    },
    {
      id: 'status',
      name: t('pages.systemTierPage.copyStatus'),
      type: 'multiSelect',
      options: formatFilterOptions(backupStatusNames),
    },
    {
      id: 'date',
      name: t('pages.systemTierPage.copyDate'),
      type: 'date',
    },
  ];
  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    tableName
  );

  const [isShowFilters, setIsShowFilters] = useState(false);

  return (
    <>
      {isShowFilters && <div className="d-sm-flex flex-row mx-4">{FilterComponent}</div>}
      <BackupTable
        filterParams={filterParams}
        setIsShowFilters={setIsShowFilters}
        isShowFilters={isShowFilters}
        hasSelectedFilters={hasSelectedFilters}
        setTierBackupsTotal={setTierBackupsTotal}
        isAdministration={isAdministration}
        tableName={tableName}
      />
    </>
  );
}

export default TierBackups;
