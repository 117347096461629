import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChipGroup, Chip } from '@patternfly/react-core';
import { SelectOption, SelectVariant, SelectDirection } from '@patternfly/react-core/deprecated';

import { StyledMultiSelect } from './styles';

const titleIdTypeahead = 'typeahead-select-id-1';
const titleIdMulti = 'multi-typeahead-select-id-1';

function SelectComponent({
  options = [],
  placeholder = ' ',
  field,
  disabled,
  withAddButton,
  isMulti,
  label,
}) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isOptions, setOptions] = useState(options);

  const [selected, setSelected] = useState([]);
  const toggleAriaLabel = t('components.select.optionsMenu');
  useEffect(() => {
    if (options.length && isMulti && !isOpen) {
      setOptions(options);
    }
  }, [options.length]);

  useEffect(() => {
    setSelected(field.value);
  }, [field]);

  const onToggle = () => setOpen(!isOpen);

  const onSelect = (event, selection) => {
    if (selection) {
      if (withAddButton && isMulti) {
        if (selected?.includes(selection)) {
          setSelected(selected.filter(item => item !== selection));
        } else {
          setSelected(prevState => [...(prevState || []), selection]);
        }
      }

      const formattedValue = selected?.includes(selection)
        ? selected.filter(item => item !== selection)
        : [...(selected || []), selection];

      field.onChange(formattedValue);
    }
  };

  const onCreateOption = newValue => {
    setOptions(prevState => [...prevState, { value: newValue, label: newValue }]);

    onToggle();
  };

  const clearSelection = () => {
    setSelected(withAddButton && isMulti ? [] : '');
    field.onChange(isMulti ? [] : '');

    onToggle();
  };

  const deleteItem = id => {
    if (isMulti) {
      if (selected?.includes(id)) {
        const filteredCopy = selected.filter(chip => chip !== id);

        setSelected(filteredCopy);
        field.onChange(filteredCopy);
      }
    } else {
      setSelected('');
      field.onChange('');
    }
  };

  const optionsData = isOptions.map(option => (
    <SelectOption key={option.value} value={option.label} />
  ));

  return (
    <div className="d-flex">
      <StyledMultiSelect
        variant={SelectVariant.typeaheadMulti}
        aria-label={label || 'Select Input'}
        typeAheadAriaLabel="Select a state"
        onToggle={onToggle}
        onSelect={onSelect}
        onClear={clearSelection}
        selections={selected}
        isOpen={isOpen}
        aria-labelledby={isMulti ? titleIdMulti : titleIdTypeahead}
        direction={SelectDirection.down}
        placeholderText={(!selected?.length && placeholder) || undefined}
        isDisabled={disabled}
        isCreatable={withAddButton}
        onCreateOption={(withAddButton && onCreateOption) || undefined}
        shouldResetOnSelect={withAddButton}
        clearSelectionsAriaLabel="{t('components.select.clearAll')}"
        toggleAriaLabel={toggleAriaLabel}
        removeSelectionAriaLabel={t('common.delete')}
        createText={t('common.add')}
        noResultsFoundText={t('common.noResults')}
        loadingVariant="SelectViewMoreObject"
        chipGroupComponent={
          <ChipGroup
            collapsedText={selected?.length > 3 ? `${selected.length - 3} ${t('common.more')}` : ''}
            expandedText={t('common.hide')}
          >
            {selected?.map(currentChip => (
              <Chip key={currentChip} onClick={() => deleteItem(currentChip)}>
                {currentChip}
              </Chip>
            ))}
          </ChipGroup>
        }
      >
        {optionsData}
      </StyledMultiSelect>
    </div>
  );
}

const MultiSelectController = ({ name, control, defaultValue, rules, ...selectProps }) => {
  const selectDefaultValue = defaultValue || selectProps.isMulti ? [] : '';

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={selectDefaultValue}
      render={({ field }) => <SelectComponent field={field} {...selectProps} />}
    />
  );
};
export default MultiSelectController;
