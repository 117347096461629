import i18n from '../i18n';

const { t } = i18n;

export const systemStatusKeys = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  UPDATING_IN_PROGRESS: 'UPDATING_IN_PROGRESS',
  ERROR: 'ERROR',
  ARCHIVE: 'ARCHIVE',
  ARCHIVE_IN_PROGRESS: 'ARCHIVE_IN_PROGRESS',
  RESTORING_IN_PROGRESS: 'RESTORING_IN_PROGRESS',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
};

export const getSystemStatusNames = () => ({
  [systemStatusKeys.NEW]: t('constants.systemStatusNames.NEW'),
  [systemStatusKeys.ACTIVE]: t('constants.systemStatusNames.ACTIVE'),
  [systemStatusKeys.UPDATING_IN_PROGRESS]: t('constants.systemStatusNames.UPDATING_IN_PROGRESS'),
  [systemStatusKeys.ARCHIVE_IN_PROGRESS]: t('constants.systemStatusNames.ARCHIVE_IN_PROGRESS'),
  [systemStatusKeys.ARCHIVE]: t('constants.systemStatusNames.ARCHIVE'),
  [systemStatusKeys.RESTORING_IN_PROGRESS]: t('constants.systemStatusNames.RESTORING_IN_PROGRESS'),
  [systemStatusKeys.REMOVAL_IN_PROGRESS]: t('constants.systemStatusNames.REMOVAL_IN_PROGRESS'),
  [systemStatusKeys.ERROR]: t('constants.systemStatusNames.ERROR'),
});

export const systemTypesKeys = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  INTERNAL_WITH_PERMISSION: 'INTERNAL_WITH_PERMISSION',
};

export const getSystemTypesNames = () => ({
  [systemTypesKeys.INTERNAL]: t('constants.systemTypesNames.INTERNAL'),
  [systemTypesKeys.EXTERNAL]: t('constants.systemTypesNames.EXTERNAL'),
  [systemTypesKeys.INTERNAL_WITH_PERMISSION]: t(
    'constants.systemTypesNames.INTERNAL_WITH_PERMISSION'
  ),
});

export const getSystemTypeFilterOptions = () => [
  {
    id: systemTypesKeys.INTERNAL_WITH_PERMISSION,
    name: getSystemTypesNames().INTERNAL_WITH_PERMISSION,
    group: ' ',
  },
  {
    id: systemTypesKeys.INTERNAL,
    name: getSystemTypesNames().INTERNAL,
    group: t('common.type'),
  },
  {
    id: systemTypesKeys.EXTERNAL,
    name: getSystemTypesNames().EXTERNAL,
    group: t('common.type'),
  },
];

export const tierStatusKeys = {
  PENDING: 'PENDING',
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  ARCHIVE: 'ARCHIVE',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
  ARCHIVE_IN_PROGRESS: 'ARCHIVE_IN_PROGRESS',
  RESTORING_IN_PROGRESS: 'RESTORING_IN_PROGRESS',
  ERROR: 'ERROR',
};

export const getTierStatusNames = () => ({
  [tierStatusKeys.PENDING]: t('constants.tierStatusNames.PENDING'),
  [tierStatusKeys.ACTIVE]: t('constants.tierStatusNames.ACTIVE'),
  [tierStatusKeys.NEW]: t('constants.tierStatusNames.NEW'),
  [tierStatusKeys.ERROR]: t('constants.tierStatusNames.ERROR'),
  [tierStatusKeys.REMOVAL_IN_PROGRESS]: t('constants.tierStatusNames.REMOVAL_IN_PROGRESS'),
  [tierStatusKeys.ARCHIVE_IN_PROGRESS]: t('constants.tierStatusNames.ARCHIVE_IN_PROGRESS'),
  [tierStatusKeys.ARCHIVE]: t('constants.tierStatusNames.ARCHIVE'),
  [tierStatusKeys.RESTORING_IN_PROGRESS]: t('constants.tierStatusNames.RESTORING_IN_PROGRESS'),
});

export const getTierTypeNames = () => ({
  dev: t('constants.tierTypeNames.dev'),
  prod: t('constants.tierTypeNames.prod'),
});

export const quoteStatusKeys = {
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
  NEW: 'NEW',
  SET: 'SET',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const getQuoteStatusNames = () => ({
  [quoteStatusKeys.REJECTED]: t('constants.quoteStatusNames.REJECTED'),
  [quoteStatusKeys.ERROR]: t('constants.quoteStatusNames.ERROR'),
  [quoteStatusKeys.NEW]: t('constants.quoteStatusNames.NEW'),
  [quoteStatusKeys.SET]: t('constants.quoteStatusNames.SET'),
  [quoteStatusKeys.IN_PROGRESS]: t('constants.quoteStatusNames.IN_PROGRESS'),
});

export const serviceStatusKeys = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  UPDATING_IN_PROGRESS: 'UPDATING_IN_PROGRESS',
  ERROR: 'ERROR',
  ARCHIVE: 'ARCHIVE',
  ARCHIVE_IN_PROGRESS: 'ARCHIVE_IN_PROGRESS',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
  RESTORING_IN_PROGRESS: 'RESTORING_IN_PROGRESS',
};

export const getServiceStatusNames = () => ({
  [serviceStatusKeys.NEW]: t('constants.serviceStatusNames.NEW'),
  [serviceStatusKeys.ACTIVE]: t('constants.serviceStatusNames.ACTIVE'),
  [serviceStatusKeys.UPDATING_IN_PROGRESS]: t('constants.serviceStatusNames.UPDATING_IN_PROGRESS'),
  [serviceStatusKeys.ARCHIVE_IN_PROGRESS]: t('constants.serviceStatusNames.ARCHIVE_IN_PROGRESS'),
  [serviceStatusKeys.ARCHIVE]: t('constants.serviceStatusNames.ARCHIVE'),
  [serviceStatusKeys.RESTORING_IN_PROGRESS]: t(
    'constants.serviceStatusNames.RESTORING_IN_PROGRESS'
  ),
  [serviceStatusKeys.ERROR]: t('constants.serviceStatusNames.ERROR'),
  [serviceStatusKeys.REMOVAL_IN_PROGRESS]: t('constants.serviceStatusNames.REMOVAL_IN_PROGRESS'),
});

export const repositoryStatusKeys = {
  NEW: 'NEW',
  READY: 'READY',
};

export const getRepositoryStatusNames = () => ({
  [repositoryStatusKeys.NEW]: t('constants.repositoryStatusNames.NEW'),
  [repositoryStatusKeys.READY]: t('constants.repositoryStatusNames.READY'),
});

export const repositoryTemplateNames = {
  WITHOUT_TEMPLATE: 'WITHOUT_TEMPLATE',
  java_gradle_11: 'Java 11, Gradle',
  java_maven_11: 'Java 11, Maven',
  java_gradle_17: 'Java 17, Gradle',
  java_maven_17: 'Java 17, Maven',
  java_gradle_8: 'Java 8, Gradle',
  java_maven_8: 'Java 8, Maven',
  'dotnet_5.0': '.Net 5.0',
  'dotnet_6.0': '.Net 6.0',
  'dotnet_3.1': 'Net Core 3.1',
  nginx_static: 'Nginx Static-файлы',
  nodejs_14: 'Node.js v14',
  nodejs_16: 'Node.js v16',
  nodejs_18: 'Node.js v18',
  nodejs_19: 'Node.js v19',
};

export const serviceTypeKeys = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
  LIBRARY: 'LIBRARY',
  //
  INTEGRATION: 'INTEGRATION',
};

export const getServiceTypeNames = () => ({
  [serviceTypeKeys.INTERNAL]: t('constants.serviceTypeNames.INTERNAL'),
  [serviceTypeKeys.EXTERNAL]: t('constants.serviceTypeNames.EXTERNAL'),
  [serviceTypeKeys.LIBRARY]: t('constants.serviceTypeNames.LIBRARY'),
  [serviceTypeKeys.INTEGRATION]: t('constants.serviceTypeNames.INTEGRATION'),
});

export const serviceImportTypeKeys = {
  WITHOUT_TYPE: 'WITHOUT_TYPE',
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  LIBRARY: 'LIBRARY',
  INTEGRATION: 'INTEGRATION',
};

export const getServiceImportTypeNames = () => ({
  [serviceImportTypeKeys.WITHOUT_TYPE]: t('common.notAssigned'),
  [serviceImportTypeKeys.INTERNAL]: t('constants.serviceTypeNames.INTERNAL'),
  [serviceImportTypeKeys.EXTERNAL]: t('constants.serviceTypeNames.EXTERNAL'),
  [serviceImportTypeKeys.LIBRARY]: t('constants.serviceTypeNames.LIBRARY'),
  [serviceImportTypeKeys.INTEGRATION]: t('constants.serviceTypeNames.INTEGRATION'),
});

export const serviceStageStatusKeys = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
  RUNNING: 'RUNNING',
  MANUAL: 'MANUAL',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  SKIPPED: 'SKIPPED',
};

export const getServiceStageStatusNames = () => ({
  [serviceStageStatusKeys.CREATED]: t('constants.serviceStageStatusNames.CREATED'),
  [serviceStageStatusKeys.PENDING]: t('constants.serviceStageStatusNames.PENDING'),
  [serviceStageStatusKeys.SCHEDULED]: t('constants.serviceStageStatusNames.SCHEDULED'),
  [serviceStageStatusKeys.RUNNING]: t('constants.serviceStageStatusNames.RUNNING'),
  [serviceStageStatusKeys.MANUAL]: t('constants.serviceStageStatusNames.MANUAL'),
  [serviceStageStatusKeys.SUCCESS]: t('constants.serviceStageStatusNames.SUCCESS'),
  [serviceStageStatusKeys.FAILED]: t('constants.serviceStageStatusNames.FAILED'),
  [serviceStageStatusKeys.CANCELLED]: t('constants.serviceStageStatusNames.CANCELLED'),
  [serviceStageStatusKeys.SKIPPED]: t('constants.serviceStageStatusNames.SKIPPED'),
});

export const serviceJobStatusKeys = {
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  WARNING: 'WARNING',
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
  RUNNING: 'RUNNING',
  MANUAL: 'MANUAL',
  SUCCESS: 'SUCCESS',
  CREATED: 'CREATED',
  SKIPPED: 'SKIPPED',
};

export const getServiceJobStatusNames = () => ({
  [serviceJobStatusKeys.PENDING]: t('constants.serviceJobStatusNames.PENDING'),
  [serviceJobStatusKeys.SCHEDULED]: t('constants.serviceJobStatusNames.SCHEDULED'),
  [serviceJobStatusKeys.CREATED]: t('constants.serviceJobStatusNames.CREATED'),
  [serviceJobStatusKeys.RUNNING]: t('constants.serviceJobStatusNames.RUNNING'),
  [serviceJobStatusKeys.CANCELLED]: t('constants.serviceJobStatusNames.CANCELLED'),
  [serviceJobStatusKeys.SKIPPED]: t('constants.serviceJobStatusNames.SKIPPED'),
  [serviceJobStatusKeys.MANUAL]: t('constants.serviceJobStatusNames.MANUAL'),
  [serviceJobStatusKeys.FAILED]: t('constants.serviceJobStatusNames.FAILED'),
  [serviceJobStatusKeys.WARNING]: t('constants.serviceJobStatusNames.WARNING'),
  [serviceJobStatusKeys.SUCCESS]: t('constants.serviceJobStatusNames.SUCCESS'),
});

export const serviceVersionsStatusKeys = {
  NEW: 'NEW',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
  ERROR: 'ERROR',
  ACTIVE: 'ACTIVE',
  PROBLEM_FOUND: 'PROBLEM_FOUND',
};

export const getServiceVersionsStatusNames = () => ({
  [serviceVersionsStatusKeys.NEW]: t('constants.serviceVersionsStatusNames.NEW'),
  [serviceVersionsStatusKeys.ACTIVE]: t('constants.serviceVersionsStatusNames.ACTIVE'),
  [serviceVersionsStatusKeys.REMOVAL_IN_PROGRESS]: t(
    'constants.serviceVersionsStatusNames.REMOVAL_IN_PROGRESS'
  ),
  [serviceVersionsStatusKeys.ERROR]: t('constants.serviceVersionsStatusNames.ERROR'),
  [serviceVersionsStatusKeys.PROBLEM_FOUND]: t(
    'constants.serviceVersionsStatusNames.PROBLEM_FOUND'
  ),
  [serviceVersionsStatusKeys.PENDING]: t('constants.serviceVersionsStatusNames.PENDING'),
  [serviceVersionsStatusKeys.PREPARING]: t('constants.serviceVersionsStatusNames.PREPARING'),
  [serviceVersionsStatusKeys.RUNNING]: t('constants.serviceVersionsStatusNames.RUNNING'),
  [serviceVersionsStatusKeys.SUCCESS]: t('constants.serviceVersionsStatusNames.SUCCESS'),
  [serviceVersionsStatusKeys.FAILED]: t('constants.serviceVersionsStatusNames.FAILED'),
  [serviceVersionsStatusKeys.CANCELED]: t('constants.serviceVersionsStatusNames.CANCELED'),
  [serviceVersionsStatusKeys.SKIPPED]: t('constants.serviceVersionsStatusNames.SKIPPED'),
  [serviceVersionsStatusKeys.CREATED]: t('constants.serviceVersionsStatusNames.CREATED'),
  [serviceVersionsStatusKeys.SCHEDULED]: t('constants.serviceVersionsStatusNames.SCHEDULED'),
  [serviceVersionsStatusKeys.WAITING_FOR_RESOURCE]: t(
    'constants.serviceVersionsStatusNames.WAITING_FOR_RESOURCE'
  ),
  [serviceVersionsStatusKeys.MANUAL]: t('constants.serviceVersionsStatusNames.MANUAL'),
});

export const serviceVersionsPipelineKeys = {
  WAITING_FOR_RESOURCE: 'WAITING_FOR_RESOURCE',
  CREATED: 'CREATED',
  SCHEDULED: 'SCHEDULED',
  MANUAL: 'MANUAL',
  //
  PREPARING: 'PREPARING',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  //
  SUCCESS: 'SUCCESS',
  //
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
  SKIPPED: 'SKIPPED',
};

export const getServiceVersionsPipelineNames = () => ({
  [serviceVersionsPipelineKeys.WAITING_FOR_RESOURCE]: t(
    'constants.serviceVersionsPipelineNames.WAITING_FOR_RESOURCE'
  ),
  [serviceVersionsPipelineKeys.CREATED]: t('constants.serviceVersionsPipelineNames.CREATED'),
  [serviceVersionsPipelineKeys.SCHEDULED]: t('constants.serviceVersionsPipelineNames.SCHEDULED'),
  [serviceVersionsPipelineKeys.MANUAL]: t('constants.serviceVersionsPipelineNames.MANUAL'),
  [serviceVersionsPipelineKeys.PREPARING]: t('constants.serviceVersionsPipelineNames.PREPARING'),
  [serviceVersionsPipelineKeys.PENDING]: t('constants.serviceVersionsPipelineNames.PENDING'),
  [serviceVersionsPipelineKeys.RUNNING]: t('constants.serviceVersionsPipelineNames.RUNNING'),
  [serviceVersionsPipelineKeys.SUCCESS]: t('constants.serviceVersionsPipelineNames.SUCCESS'),
  [serviceVersionsPipelineKeys.FAILED]: t('constants.serviceVersionsPipelineNames.FAILED'),
  [serviceVersionsPipelineKeys.CANCELED]: t('constants.serviceVersionsPipelineNames.CANCELED'),
  [serviceVersionsPipelineKeys.SKIPPED]: t('constants.serviceVersionsPipelineNames.SKIPPED'),
});

export const vulnerabilitySeverityKeys = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  CRITICAL: 'CRITICAL',
};

export const getVulnerabilitySeverityNames = () => ({
  [vulnerabilitySeverityKeys.LOW]: t('constants.vulnerabilitySeverityNames.LOW'),
  [vulnerabilitySeverityKeys.MEDIUM]: t('constants.vulnerabilitySeverityNames.MEDIUM'),
  [vulnerabilitySeverityKeys.HIGH]: t('constants.vulnerabilitySeverityNames.HIGH'),
  [vulnerabilitySeverityKeys.CRITICAL]: t('constants.vulnerabilitySeverityNames.CRITICAL'),
});

export const unitTestingStatusKeys = {
  PASSED: 'PASSED',
  FAILURE: 'FAILURE',
  ERROR: 'ERROR',
  SKIPPED: 'SKIPPED',
};

export const getUnitTestingStatusNames = () => ({
  [unitTestingStatusKeys.PASSED]: t('constants.unitTestingStatusNames.PASSED'),
  [unitTestingStatusKeys.FAILURE]: t('constants.unitTestingStatusNames.FAILURE'),
  [unitTestingStatusKeys.ERROR]: t('constants.unitTestingStatusNames.ERROR'),
  [unitTestingStatusKeys.SKIPPED]: t('constants.unitTestingStatusNames.SKIPPED'),
});

export const releaseStatusKeys = {
  NEW: 'NEW',
  READY: 'READY',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
};

export const getReleaseStatusNames = () => ({
  [releaseStatusKeys.READY]: t('constants.releaseStatusNames.READY'),
  [releaseStatusKeys.NEW]: t('constants.releaseStatusNames.NEW'),
  [releaseStatusKeys.APPROVED]: t('constants.releaseStatusNames.APPROVED'),
  [releaseStatusKeys.CANCELLED]: t('constants.releaseStatusNames.CANCELLED'),
});

export const releaseTypeKeys = {
  RELEASE: 'RELEASE',
  HOTFIX: 'HOTFIX',
};

export const getReleaseTypeNames = () => ({
  [releaseTypeKeys.RELEASE]: t('constants.releaseTypeNames.RELEASE'),
  [releaseTypeKeys.HOTFIX]: t('constants.releaseTypeNames.HOTFIX'),
});

export const releasesDeployStatusKeys = {
  DEPLOYED: 'DEPLOYED',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const getReleasesDeployStatusNames = () => ({
  [releasesDeployStatusKeys.IN_PROGRESS]: t('constants.releasesDeployStatusNames.IN_PROGRESS'),
  [releasesDeployStatusKeys.DEPLOYED]: t('constants.releasesDeployStatusNames.DEPLOYED'),
  [releasesDeployStatusKeys.ERROR]: t('constants.releasesDeployStatusNames.ERROR'),
});

export const milestoneStatusKeys = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  OVERDUE: 'OVERDUE',
};

export const getMilestoneStatusNames = () => ({
  [milestoneStatusKeys.OPEN]: t('constants.milestoneStatusNames.OPEN'),
  [milestoneStatusKeys.OVERDUE]: t('constants.milestoneStatusNames.OVERDUE'),
  [milestoneStatusKeys.CLOSED]: t('constants.milestoneStatusNames.CLOSED'),
});

export const issueStatusKeys = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  IMPLEMENTED: 'IMPLEMENTED',
  DONE: 'DONE',
};

export const getIssueStatusNames = () => ({
  [issueStatusKeys.NEW]: t('constants.issueStatusNames.NEW'),
  [issueStatusKeys.IN_PROGRESS]: t('constants.issueStatusNames.IN_PROGRESS'),
  [issueStatusKeys.IMPLEMENTED]: t('constants.issueStatusNames.IMPLEMENTED'),
  [issueStatusKeys.DONE]: t('constants.issueStatusNames.DONE'),
});

export const getIssueNewStatusNames = () => ({
  [issueStatusKeys.NEW]: t('constants.issueStatusNames.NEW'),
  [issueStatusKeys.IN_PROGRESS]: t('constants.issueStatusNames.IN_PROGRESS'),
});

export const serverTierStatusKeys = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  UPDATING_IN_PROGRESS: 'UPDATING_IN_PROGRESS',
  ERROR: 'ERROR',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
  //
  ARCHIVE: 'ARCHIVE',
  ARCHIVE_IN_PROGRESS: 'ARCHIVE_IN_PROGRESS',
  RESTORING_IN_PROGRESS: 'RESTORING_IN_PROGRESS',
};

export const getServerTierStatusNames = () => ({
  [serverTierStatusKeys.NEW]: t('constants.serverTierStatusNames.NEW'),
  [serverTierStatusKeys.ACTIVE]: t('constants.serverTierStatusNames.ACTIVE'),
  [serverTierStatusKeys.UPDATING_IN_PROGRESS]: t(
    'constants.serverTierStatusNames.UPDATING_IN_PROGRESS'
  ),
  [serverTierStatusKeys.ERROR]: t('constants.serverTierStatusNames.ERROR'),
  [serverTierStatusKeys.ARCHIVE]: t('constants.serverTierStatusNames.ARCHIVE'),
  [serverTierStatusKeys.REMOVAL_IN_PROGRESS]: t(
    'constants.serverTierStatusNames.REMOVAL_IN_PROGRESS'
  ),
  [serverTierStatusKeys.RESTORING_IN_PROGRESS]: t(
    'constants.serverTierStatusNames.RESTORING_IN_PROGRESS'
  ),
});

export const backupStatusKeys = {
  OVERDUE: 'OVERDUE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const getBackupStatusNames = () => ({
  [backupStatusKeys.SUCCESS]: t('constants.backupStatusNames.SUCCESS'),
  [backupStatusKeys.OVERDUE]: t('constants.backupStatusNames.OVERDUE'),
  [backupStatusKeys.ERROR]: t('constants.backupStatusNames.ERROR'),
});

export const intervalMethodKeys = {
  AUTOMATICALLY: 'AUTOMATICALLY',
  MANUALLY: 'MANUALLY',
};

export const getIntervalMethodNames = () => ({
  [intervalMethodKeys.AUTOMATICALLY]: t('constants.intervalMethodNames.AUTOMATICALLY'),
  [intervalMethodKeys.MANUALLY]: t('constants.intervalMethodNames.MANUALLY'),
});

const userAdminKeys = {
  admin: 'admin',
  user: 'user',
};

export const getUserAdminNames = () => ({
  [userAdminKeys.admin]: t('constants.userAdminNames.admin'),
  [userAdminKeys.user]: t('constants.userAdminNames.user'),
});

export const userStatusKeys = {
  ACTIVE: true,
  INACTIVE: false,
  UPDATING_IN_PROGRESS: 'UPDATING_IN_PROGRESS',
  ERROR: 'ERROR',
  UPDATED: 'UPDATED',
  NEW: 'NEW',
};

export const getUserStatusNames = () => ({
  ACTIVE: t('constants.userStatusNames.ACTIVE'),
  INACTIVE: t('constants.userStatusNames.INACTIVE'),
  [userStatusKeys.UPDATING_IN_PROGRESS]: t('constants.userStatusNames.UPDATING_IN_PROGRESS'),
  [userStatusKeys.ERROR]: t('constants.userStatusNames.ERROR'),
  [userStatusKeys.NEW]: t('constants.userStatusNames.NEW'),
});

export const getUserPlatfornmStatusNames = () => ({
  ACTIVE: t('constants.userPlatfornmStatusNames.ACTIVE'),
  INACTIVE: t('constants.userPlatfornmStatusNames.INACTIVE'),
});

export const roleStatusKeys = {
  PENDING: 'PENDING',
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  UPDATING_IN_PROGRESS: 'UPDATING_IN_PROGRESS',
  REMOVAL_IN_PROGRESS: 'REMOVAL_IN_PROGRESS',
  ERROR: 'ERROR',
};

export const getRoleStatusNames = () => ({
  [roleStatusKeys.PENDING]: t('constants.roleStatusNames.PENDING'),
  [roleStatusKeys.NEW]: t('constants.roleStatusNames.NEW'),
  [roleStatusKeys.ACTIVE]: t('constants.roleStatusNames.ACTIVE'),
  [roleStatusKeys.UPDATING_IN_PROGRESS]: t('constants.roleStatusNames.UPDATING_IN_PROGRESS'),
  [roleStatusKeys.REMOVAL_IN_PROGRESS]: t('constants.roleStatusNames.REMOVAL_IN_PROGRESS'),
  [roleStatusKeys.ERROR]: t('constants.roleStatusNames.ERROR'),
});

export const staticAnalysisProblemTypeKeys = {
  BUG: 'BUG',
  VULNERABILITY: 'VULNERABILITY',
  CODE_SMELL: 'CODE_SMELL',
};

export const getStaticAnalysisProblemTypeNames = () => ({
  [staticAnalysisProblemTypeKeys.BUG]: t('constants.staticAnalysisProblemTypeNames.BUG'),
  [staticAnalysisProblemTypeKeys.VULNERABILITY]: t(
    'constants.staticAnalysisProblemTypeNames.VULNERABILITY'
  ),
  [staticAnalysisProblemTypeKeys.CODE_SMELL]: t(
    'constants.staticAnalysisProblemTypeNames.CODE_SMELL'
  ),
});

const staticAnalysisResolutionKeys = {
  FALSE_POSITIVE: 'FALSE_POSITIVE',
  WONTFIX: 'WONTFIX',
  FIXED: 'FIXED',
  REMOVED: 'REMOVED',
};

export const getStaticAnalysisResolutionNames = () => ({
  [staticAnalysisResolutionKeys.FALSE_POSITIVE]: t(
    'constants.staticAnalysisResolutionNames.FALSE_POSITIVE'
  ),
  [staticAnalysisResolutionKeys.WONTFIX]: t('constants.staticAnalysisResolutionNames.WONTFIX'),
  [staticAnalysisResolutionKeys.FIXED]: t('constants.staticAnalysisResolutionNames.FIXED'),
  [staticAnalysisResolutionKeys.REMOVED]: t('constants.staticAnalysisResolutionNames.REMOVED'),
});

export const staticAnalysisSeverityKeys = {
  BLOCKER: 'BLOCKER',
  CRITICAL: 'CRITICAL',
  MAJOR: 'MAJOR',
  MINOR: 'MINOR',
  INFO: 'INFO',
};

export const getStaticAnalysisSeverityNames = () => ({
  [staticAnalysisSeverityKeys.BLOCKER]: t('constants.staticAnalysisSeverityNames.BLOCKER'),
  [staticAnalysisSeverityKeys.CRITICAL]: t('constants.staticAnalysisSeverityNames.CRITICAL'),
  [staticAnalysisSeverityKeys.MAJOR]: t('constants.staticAnalysisSeverityNames.MAJOR'),
  [staticAnalysisSeverityKeys.MINOR]: t('constants.staticAnalysisSeverityNames.MINOR'),
  [staticAnalysisSeverityKeys.INFO]: t('constants.staticAnalysisSeverityNames.INFO'),
});

export const staticAnalysisStatusKeys = {
  OPEN: 'OPEN',
  CONFIRMED: 'CONFIRMED',
  REOPENED: 'REOPENED',
  RESOLVED: 'RESOLVED',
  CLOSED: 'CLOSED',
};

export const getStaticAnalysisStatusNames = () => ({
  [staticAnalysisStatusKeys.OPEN]: t('constants.staticAnalysisStatusNames.OPEN'),
  [staticAnalysisStatusKeys.CONFIRMED]: t('constants.staticAnalysisStatusNames.CONFIRMED'),
  [staticAnalysisStatusKeys.REOPENED]: t('constants.staticAnalysisStatusNames.REOPENED'),
  [staticAnalysisStatusKeys.RESOLVED]: t('constants.staticAnalysisStatusNames.RESOLVED'),
  [staticAnalysisStatusKeys.CLOSED]: t('constants.staticAnalysisStatusNames.CLOSED'),
});

export const vulnerabilityProbabilityKeys = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const getVulnerabilityProbabilityStatusNames = () => ({
  [vulnerabilityProbabilityKeys.HIGH]: t('constants.vulnerabilityProbabilityStatusNames.HIGH'),
  [vulnerabilityProbabilityKeys.MEDIUM]: t('constants.vulnerabilityProbabilityStatusNames.MEDIUM'),
  [vulnerabilityProbabilityKeys.LOW]: t('constants.vulnerabilityProbabilityStatusNames.LOW'),
});

export const unsafeAreasStatusKeys = {
  TO_REVIEW: 'TO_REVIEW',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  FIXED: 'FIXED',
  SAFE: 'SAFE',
};

export const getUnsafeAreasStatusNames = () => ({
  [unsafeAreasStatusKeys.TO_REVIEW]: t('constants.unsafeAreasStatusNames.TO_REVIEW'),
  [unsafeAreasStatusKeys.ACKNOWLEDGED]: t('constants.unsafeAreasStatusNames.ACKNOWLEDGED'),
  [unsafeAreasStatusKeys.FIXED]: t('constants.unsafeAreasStatusNames.FIXED'),
  [unsafeAreasStatusKeys.SAFE]: t('constants.unsafeAreasStatusNames.SAFE'),
});

export const buildResultTypeKeys = {
  VULNERABILITY_ANALYSIS: 'VULNERABILITY_ANALYSIS',
  UNIT_TESTING: 'UNIT_TESTING',
  STATIC_ANALYSIS: 'STATIC_ANALYSIS',
};

export const getBuildResultTypeNames = () => ({
  [buildResultTypeKeys.VULNERABILITY_ANALYSIS]: t(
    'constants.buildResultTypeNames.VULNERABILITY_ANALYSIS'
  ),
  [buildResultTypeKeys.UNIT_TESTING]: t('constants.buildResultTypeNames.UNIT_TESTING'),
  [buildResultTypeKeys.STATIC_ANALYSIS]: t('constants.buildResultTypeNames.STATIC_ANALYSIS'),
});

export const buildResultTotalSummaryKeys = {
  VULNERABILITY_ANALYSIS: 'vulnerabilityTotal',
  UNIT_TESTING: 'unitTestTotal',
  STATIC_ANALYSIS: 'staticAnalysisTotal',
};

export const vulnerabilityTotalKeys = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  critical: 'critical',
};

export const unitTestTotalKeys = {
  passedTest: 'passedTest',
  failureTest: 'failureTest',
  errorTest: 'errorTest',
  skippedTest: 'skippedTest',
};

export const staticAnalysisTotalKeys = {
  bugs: 'bugs',
  vulnerabilities: 'vulnerabilities',
  codeSmells: 'codeSmells',
  securityHotspots: 'securityHotspots',
};

export const getBuildResultsTooltipContent = () => ({
  [buildResultTypeKeys.VULNERABILITY_ANALYSIS]: {
    [vulnerabilityTotalKeys.critical]: t(
      'constants.buildResultsTooltipContent.VULNERABILITY_ANALYSIS.critical'
    ),
    [vulnerabilityTotalKeys.high]: t(
      'constants.buildResultsTooltipContent.VULNERABILITY_ANALYSIS.high'
    ),
    [vulnerabilityTotalKeys.medium]: t(
      'constants.buildResultsTooltipContent.VULNERABILITY_ANALYSIS.medium'
    ),
    [vulnerabilityTotalKeys.low]: t(
      'constants.buildResultsTooltipContent.VULNERABILITY_ANALYSIS.low'
    ),
  },
  [buildResultTypeKeys.UNIT_TESTING]: {
    [unitTestTotalKeys.passedTest]: t(
      'constants.buildResultsTooltipContent.UNIT_TESTING.passedTest'
    ),
    [unitTestTotalKeys.failureTest]: t(
      'constants.buildResultsTooltipContent.UNIT_TESTING.failureTest'
    ),
    [unitTestTotalKeys.errorTest]: t('constants.buildResultsTooltipContent.UNIT_TESTING.errorTest'),
    [unitTestTotalKeys.skippedTest]: t(
      'constants.buildResultsTooltipContent.UNIT_TESTING.skippedTest'
    ),
  },
  [buildResultTypeKeys.STATIC_ANALYSIS]: {
    [staticAnalysisTotalKeys.bugs]: t('constants.buildResultsTooltipContent.STATIC_ANALYSIS.bugs'),
    [staticAnalysisTotalKeys.vulnerabilities]: t(
      'constants.buildResultsTooltipContent.STATIC_ANALYSIS.vulnerabilities'
    ),
    [staticAnalysisTotalKeys.securityHotspots]: t(
      'constants.buildResultsTooltipContent.STATIC_ANALYSIS.securityHotspots'
    ),
    [staticAnalysisTotalKeys.codeSmells]: t(
      'constants.buildResultsTooltipContent.STATIC_ANALYSIS.codeSmells'
    ),
  },
});

export const documentsTypeKeys = {
  SYSTEM: 'SYSTEM',
  RELEASE: 'RELEASE',
  ISSUE: 'ISSUE',
  INTEGRATION: 'INTEGRATION',
  QUOTA_REQUEST: 'QUOTA_REQUEST',
  EQUIPMENT: 'EQUIPMENT',
};

export const getDocumentsTypeNames = () => ({
  [documentsTypeKeys.ISSUE]: t('constants.documentsTypeNames.ISSUE'),
  [documentsTypeKeys.QUOTA_REQUEST]: t('constants.documentsTypeNames.QUOTA_REQUEST'),
  [documentsTypeKeys.INTEGRATION]: t('constants.documentsTypeNames.INTEGRATION'),
  [documentsTypeKeys.RELEASE]: t('constants.documentsTypeNames.RELEASE'),
  [documentsTypeKeys.SYSTEM]: t('constants.documentsTypeNames.SYSTEM'),
});

export const componentsStatusKeys = {
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const getComponentsNames = () => ({
  KEYCLOAK: t('constants.componentsNames.KEYCLOAK'),
  GIT: t('constants.componentsNames.GIT'),
  ARGO_CD: t('constants.componentsNames.ARGO_CD'),
  SONAR: t('constants.componentsNames.SONAR'),
  OPENSHIFT: t('constants.componentsNames.OPENSHIFT'),
});

export const qualityGateKeys = {
  OK: 'OK',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

export const getQualityGateNames = () => ({
  OK: t('constants.qualityGateNames.OK'),
  WARN: t('constants.qualityGateNames.WARN'),
  ERROR: t('constants.qualityGateNames.ERROR'),
});

export const getTotalSummaryNames = () => ({
  NEW: t('constants.totalSummaryNames.NEW'),
  READY: t('constants.totalSummaryNames.READY'),
  ERROR: t('constants.totalSummaryNames.ERROR'),
});

export const getServicepipelineStatusOptions = () => [
  {
    id: serviceVersionsStatusKeys.NEW,
    name: getServiceVersionsStatusNames().NEW,
    group: t('constants.servicepipelineStatusOptions.NEW'),
  },
  {
    id: serviceVersionsStatusKeys.REMOVAL_IN_PROGRESS,
    name: getServiceVersionsStatusNames().REMOVAL_IN_PROGRESS,
    group: t('constants.servicepipelineStatusOptions.REMOVAL_IN_PROGRESS'),
  },
  {
    id: serviceVersionsPipelineKeys.PENDING,
    name: getServiceVersionsPipelineNames().PENDING,
    group: t('constants.servicepipelineStatusOptions.PENDING'),
  },
  {
    id: serviceVersionsPipelineKeys.PREPARING,
    name: getServiceVersionsPipelineNames().PREPARING,
    group: t('constants.servicepipelineStatusOptions.PREPARING'),
  },
  {
    id: serviceVersionsPipelineKeys.RUNNING,
    name: getServiceVersionsPipelineNames().RUNNING,
    group: t('constants.servicepipelineStatusOptions.RUNNING'),
  },
  {
    id: serviceVersionsPipelineKeys.SUCCESS,
    name: getServiceVersionsPipelineNames().SUCCESS,
    group: t('constants.servicepipelineStatusOptions.SUCCESS'),
  },
  {
    id: serviceVersionsStatusKeys.ERROR,
    name: getServiceVersionsStatusNames().ERROR,
    group: t('constants.servicepipelineStatusOptions.ERROR'),
  },
  {
    id: serviceVersionsPipelineKeys.FAILED,
    name: getServiceVersionsPipelineNames().FAILED,
    group: t('constants.servicepipelineStatusOptions.FAILED'),
  },
  {
    id: serviceVersionsStatusKeys.PROBLEM_FOUND,
    name: getServiceVersionsStatusNames().PROBLEM_FOUND,
    group: t('constants.servicepipelineStatusOptions.PROBLEM_FOUND'),
  },
  {
    id: serviceVersionsPipelineKeys.CANCELED,
    name: getServiceVersionsPipelineNames().CANCELED,
    group: t('constants.servicepipelineStatusOptions.CANCELED'),
  },
  {
    id: serviceVersionsPipelineKeys.SKIPPED,
    name: getServiceVersionsPipelineNames().SKIPPED,
    group: t('constants.servicepipelineStatusOptions.SKIPPED'),
  },
  {
    id: serviceVersionsPipelineKeys.CREATED,
    name: getServiceVersionsPipelineNames().CREATED,
    group: t('constants.servicepipelineStatusOptions.CREATED'),
  },
  {
    id: serviceVersionsPipelineKeys.SCHEDULED,
    name: getServiceVersionsPipelineNames().SCHEDULED,
    group: t('constants.servicepipelineStatusOptions.SCHEDULED'),
  },
  {
    id: serviceVersionsPipelineKeys.WAITING_FOR_RESOURCE,
    name: getServiceVersionsPipelineNames().WAITING_FOR_RESOURCE,
    group: t('constants.servicepipelineStatusOptions.WAITING_FOR_RESOURCE'),
  },
  {
    id: serviceVersionsPipelineKeys.MANUAL,
    name: getServiceVersionsPipelineNames().MANUAL,
    group: t('constants.servicepipelineStatusOptions.MANUAL'),
  },
];

export const getQuoteOptions = () => ({
  request: {
    id: 1,
    name: t('constants.quoteOptions.1'),
  },
  check: {
    id: 2,
    name: t('constants.quoteOptions.2'),
  },
  accept: {
    id: 3,
    name: t('constants.quoteOptions.3'),
  },
  change: {
    id: 4,
    name: t('constants.quoteOptions.4'),
  },
  delete: {
    id: 5,
    name: t('constants.quoteOptions.5'),
  },
  deploy: {
    id: 6,
    name: t('constants.quoteOptions.6'),
  },
  archive: {
    id: 7,
    name: t('constants.quoteOptions.7'),
  },
  restore: {
    id: 8,
    name: t('constants.quoteOptions.8'),
  },
});

export const getBackupFrequencyUnit = () => ({
  MINUTES: [
    t('constants.backupFrequencyUnit.MINUTES.0'),
    t('constants.backupFrequencyUnit.MINUTES.1'),
    t('constants.backupFrequencyUnit.MINUTES.2'),
  ],
  HOURS: [
    t('constants.backupFrequencyUnit.HOURS.0'),
    t('constants.backupFrequencyUnit.HOURS.1'),
    t('constants.backupFrequencyUnit.HOURS.2'),
  ],
  DAYS: [
    t('constants.backupFrequencyUnit.DAYS.0'),
    t('constants.backupFrequencyUnit.DAYS.1'),
    t('constants.backupFrequencyUnit.DAYS.2'),
  ],
  MONTHS: [
    t('constants.backupFrequencyUnit.MONTHS.0'),
    t('constants.backupFrequencyUnit.MONTHS.1'),
    t('constants.backupFrequencyUnit.MONTHS.2'),
  ],
  YEARS: [
    t('constants.backupFrequencyUnit.YEARS.0'),
    t('constants.backupFrequencyUnit.YEARS.1'),
    t('constants.backupFrequencyUnit.YEARS.2'),
  ],
});

export const NotificationEventKeys = {
  // to release page
  RELEASE_CANCELLATION: 'RELEASE_CANCELLATION',
  RELEASE_APPROVAL: 'RELEASE_APPROVAL',
  RELEASE_APPROVAL_REQUEST: 'RELEASE_APPROVAL_REQUEST',
  // to release status
  RELEASE_DEPLOYMENT: 'RELEASE_DEPLOYMENT',
  RELEASE_DEPLOYMENT_STATUS: 'RELEASE_DEPLOYMENT_STATUS',
  // to tier
  TIER_CREATION_REQUEST: 'TIER_CREATION_REQUEST',
  TIER_CREATION_RESPONSE: 'TIER_CREATION_RESPONSE',
  TIER_RESOURCE_LIMIT_WARNING: 'TIER_RESOURCE_LIMIT_WARNING',
  TIER_RESOURCE_LIMIT_ERROR: 'TIER_RESOURCE_LIMIT_ERROR',
  SERVER_TIER_RESOURCE_LIMIT_WARNING: 'SERVER_TIER_RESOURCE_LIMIT_WARNING',
  SERVER_TIER_RESOURCE_LIMIT_ERROR: 'SERVER_TIER_RESOURCE_LIMIT_ERROR',
  // to tier quotas
  TIER_QUOTA_CREATION: 'TIER_QUOTA_CREATION',
  TIER_QUOTA_DECLINE: 'TIER_QUOTA_DECLINE',
  TIER_QUOTA_APPROVAL: 'TIER_QUOTA_APPROVAL',
  TIER_QUOTA_RESPONSE: 'TIER_QUOTA_RESPONSE',
  // to tier backup
  TIER_COMPONENT_BACKUP_OVERDUE: 'TIER_COMPONENT_BACKUP_OVERDUE',
  TIER_COMPONENT_BACKUP_ERROR: 'TIER_COMPONENT_BACKUP_ERROR',
  // to service status
  SERVICE_VERSION_BUILD_RESULT: 'SERVICE_VERSION_BUILD_RESULT',
  SERVICE_VERSION_ANALYSIS_RESULT: 'SERVICE_VERSION_ANALYSIS_RESULT',
};

export const checkBoxKeys = {
  NULL: 'NULL',
  ALL_IN_PAGE: 'ALL_IN_PAGE',
  ALL: 'ALL',
};

export const issueOptions = {
  taskSolved: 'taskSolved',
  sendForRevision: 'sendForRevision',
  edit: 'edit',
  delete: 'delete',
};

export const usersOptions = {
  edit: 'edit',
  revert: 'revert',
  assign: 'assign',
  editRole: 'editRole',
};

export const usersAccessOptions = {
  edit: 'edit',
  revert: 'revert',
};

export const releaseOptions = {
  sendToApproval: 'sendToApproval',
  decline: 'decline',
  new: 'new',
  edit: 'edit',
  approve: 'approve',
  deploy: 'deploy',
  deployToDev: 'deploy-to-dev',
  view: 'view',
  delete: 'delete',
};

export const iconTypeKeys = {
  contacts: 'contacts',
  ops: 'ops',
  dev: 'dev',
};

export const getServiceTypeOptions = () => [
  {
    value: serviceTypeKeys.INTERNAL,
    label: t('constants.serviceTypeOptions.INTERNAL'),
  },
  {
    value: serviceTypeKeys.EXTERNAL,
    label: t('constants.serviceTypeOptions.EXTERNAL'),
  },
  {
    value: serviceTypeKeys.LIBRARY,
    label: t('constants.serviceTypeOptions.LIBRARY'),
  },
];

export const serverTierTypeKeys = {
  KUBERNETES: 'KUBERNETES',
  OPENSHIFT: 'OPENSHIFT',
  OKD: 'OKD',
  RANCHER: 'RANCHER',
};

export const socketActionKeys = {
  MODIFIED: 'MODIFIED',
  CREATED: 'CREATED',
  DELETED: 'DELETED',
};

export const unitMemoryKeys = {
  mib: 'MI_B',
  gib: 'GI_B',
};

export const unitMemoryNames = {
  mib: 'MiB',
  gib: 'GiB',
};

export const unitMemoryOptions = [
  {
    value: unitMemoryKeys.mib,
    label: unitMemoryNames.mib,
    units: unitMemoryKeys.mib,
  },
  {
    value: unitMemoryKeys.gib,
    label: unitMemoryNames.gib,
    units: unitMemoryKeys.gib,
  },
];

export const unitMemoryDropdownOptions = [
  {
    id: unitMemoryNames.mib,
    name: unitMemoryNames.mib,
    unit: unitMemoryKeys.mib,
  },
  {
    id: unitMemoryNames.gib,
    name: unitMemoryNames.gib,
    unit: unitMemoryKeys.gib,
  },
];

export const cpuUnitKeys = {
  millicores: 'MILLI_CORES',
  cores: 'CORES',
};

export const equipmentCpuOptions = [
  {
    id: cpuUnitKeys.cores,
  },
];

export const getEmptyTableInfo = () => [
  {
    id: 'systems',
    data: t('constants.emptyTableInfo.systems'),
  },
  {
    id: 'tiers',
    data: t('constants.emptyTableInfo.tiers'),
  },
  {
    id: 'services',
    data: t('constants.emptyTableInfo.services'),
  },
  {
    id: 'repositoriesWithVersions',
    data: t('constants.emptyTableInfo.repositoriesWithVersions'),
  },
  {
    id: 'systemServiceVersions',
    data: t('constants.emptyTableInfo.systemServiceVersions'),
  },
  {
    id: 'serviceVersions',
    data: t('constants.emptyTableInfo.serviceVersions'),
  },
  {
    id: 'releases',
    data: t('constants.emptyTableInfo.releases'),
  },
  {
    id: 'deployments',
    data: t('constants.emptyTableInfo.deployments'),
  },
  {
    id: 'milestones',
    data: t('constants.emptyTableInfo.milestones'),
  },
  {
    id: 'issues',
    data: t('constants.emptyTableInfo.issues'),
  },
  {
    id: 'releaseTasks',
    data: t('constants.emptyTableInfo.releaseTasks'),
  },
  {
    id: 'tasksInRelease',
    data: t('constants.emptyTableInfo.tasksInRelease'),
  },
  {
    id: 'adminUserRoles',
    data: t('constants.emptyTableInfo.adminUserRoles'),
  },
  {
    id: 'integrationsSystems',
    data: t('constants.emptyTableInfo.integrationsSystems'),
  },
  {
    id: 'users',
    data: t('constants.emptyTableInfo.users'),
  },
  {
    id: 'documents',
    data: t('constants.emptyTableInfo.documents'),
  },
  {
    id: 'clusters',
    data: t('constants.emptyTableInfo.clusters'),
  },
  {
    id: 'audit',
    data: t('constants.emptyTableInfo.audit'),
  },
  {
    id: 'quotes',
    data: t('constants.emptyTableInfo.quotes'),
  },
  {
    id: 'backupComponents',
    data: t('constants.emptyTableInfo.backupComponents'),
  },
  {
    id: 'serviceVersionArtifacts',
    data: t('constants.emptyTableInfo.serviceVersionArtifacts'),
  },
  {
    id: 'equipment',
    data: t('constants.emptyTableInfo.equipment'),
  },
  {
    id: 'repositories',
    data: t('constants.emptyTableInfo.repositories'),
  },
  {
    id: 'serviceVersionBuildResults',
    data: t('constants.emptyTableInfo.serviceVersionBuildResults'),
  },
  {
    id: 'contactPersons',
    data: t('constants.emptyTableInfo.contactPersons'),
  },
];

export const limitsKeys = {
  CPU: 'CPU',
  MEMORY: 'MEMORY',
  STORAGE: 'STORAGE',
};

export const getLimitsLabels = () => ({
  CPU: t('constants.limitsLabels.CPU'),
  MEMORY: t('constants.limitsLabels.MEMORY'),
  STORAGE: t('constants.limitsLabels.STORAGE'),
});

export const tierEventKeys = {
  descriptionKey: 0,
  deploymentsKey: 1,
  quotesKey: 2,
  backupsKey: 3,
  documentsKey: 4,
};

export const protocolNames = {
  http: 'http://',
  https: 'https://',
  wss: 'wss://',
};

export const getServiceFormTooltip = () => ({
  name: t('pages.systemAddServicePage.tooltips.name'),
  code: t('pages.systemAddServicePage.tooltips.code'),
  description: t('pages.systemAddServicePage.tooltips.description'),
  buildTemplate: t('pages.systemAddServicePage.tooltips.buildTemplate'),
  type: t('pages.systemAddServicePage.tooltips.type'),
  path: t('pages.systemAddServicePage.tooltips.path'),
  integration: t('pages.systemAddServicePage.tooltips.integration'),
});

export const availableLanguages = [
  {
    label: 'En',
    key: 'en',
  },
  {
    label: 'Ru',
    key: 'ru',
  },
];

export const userEventTypesKeys = {
  LOGIN: 'LOGIN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  HYPERION_DOWNLOAD_DOCUMENT: 'HYPERION_DOWNLOAD_DOCUMENT',
  HYPERION_GROUP_MEMBERSHIP_ADDED: 'HYPERION_GROUP_MEMBERSHIP_ADDED',
  HYPERION_GROUP_MEMBERSHIP_REMOVED: 'HYPERION_GROUP_MEMBERSHIP_REMOVED',
  HYPERION_ENABLE_USER: 'HYPERION_ENABLE_USER',
  HYPERION_DISABLE_USER: 'HYPERION_DISABLE_USER',
  HYPERION_UPDATE_RESPONSIBLE: 'HYPERION_UPDATE_RESPONSIBLE',
  HYPERION_CHANGE_ACCESS_LEVEL: 'HYPERION_CHANGE_ACCESS_LEVEL',
  HYPERION_CHANGE_USER_ROLE: 'HYPERION_CHANGE_USER_ROLE',
};

export const getUserEventTypesNames = () => ({
  [userEventTypesKeys.LOGIN]: t('constants.userEventTypesNames.LOGIN'),
  [userEventTypesKeys.LOGIN_ERROR]: t('constants.userEventTypesNames.LOGIN_ERROR'),
  [userEventTypesKeys.LOGOUT]: t('constants.userEventTypesNames.LOGOUT'),
  [userEventTypesKeys.HYPERION_DOWNLOAD_DOCUMENT]: t(
    'constants.userEventTypesNames.HYPERION_DOWNLOAD_DOCUMENT'
  ),
  [userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_ADDED]: t(
    'constants.userEventTypesNames.HYPERION_GROUP_MEMBERSHIP_ADDED'
  ),
  [userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_REMOVED]: t(
    'constants.userEventTypesNames.HYPERION_GROUP_MEMBERSHIP_REMOVED'
  ),
  [userEventTypesKeys.HYPERION_ENABLE_USER]: t(
    'constants.userEventTypesNames.HYPERION_ENABLE_USER'
  ),
  [userEventTypesKeys.HYPERION_DISABLE_USER]: t(
    'constants.userEventTypesNames.HYPERION_DISABLE_USER'
  ),
  [userEventTypesKeys.HYPERION_UPDATE_RESPONSIBLE]: t(
    'constants.userEventTypesNames.HYPERION_UPDATE_RESPONSIBLE'
  ),
  [userEventTypesKeys.HYPERION_CHANGE_ACCESS_LEVEL]: t(
    'constants.userEventTypesNames.HYPERION_CHANGE_ACCESS_LEVEL'
  ),
  [userEventTypesKeys.HYPERION_CHANGE_USER_ROLE]: t(
    'constants.userEventTypesNames.HYPERION_CHANGE_USER_ROLE'
  ),
});

export const getUserEventTypeOptions = () => {
  const userEventTypesNames = getUserEventTypesNames();

  return [
    {
      id: userEventTypesKeys.LOGIN,
      name: userEventTypesNames.LOGIN,
    },
    {
      id: userEventTypesKeys.LOGIN_ERROR,
      name: userEventTypesNames.LOGIN_ERROR,
    },
    {
      id: userEventTypesKeys.LOGOUT,
      name: userEventTypesNames.LOGOUT,
    },
    {
      id: userEventTypesKeys.HYPERION_DOWNLOAD_DOCUMENT,
      name: userEventTypesNames.HYPERION_DOWNLOAD_DOCUMENT,
    },
    {
      id: userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_ADDED,
      name: userEventTypesNames.HYPERION_GROUP_MEMBERSHIP_ADDED,
    },
    {
      id: userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_REMOVED,
      name: userEventTypesNames.HYPERION_GROUP_MEMBERSHIP_REMOVED,
    },
    {
      id: userEventTypesKeys.HYPERION_ENABLE_USER,
      name: userEventTypesNames.HYPERION_ENABLE_USER,
    },
    {
      id: userEventTypesKeys.HYPERION_DISABLE_USER,
      name: userEventTypesNames.HYPERION_DISABLE_USER,
    },
    {
      id: userEventTypesKeys.HYPERION_UPDATE_RESPONSIBLE,
      name: userEventTypesNames.HYPERION_UPDATE_RESPONSIBLE,
    },
    {
      id: userEventTypesKeys.HYPERION_CHANGE_ACCESS_LEVEL,
      name: userEventTypesNames.HYPERION_CHANGE_ACCESS_LEVEL,
    },
    {
      id: userEventTypesKeys.HYPERION_CHANGE_USER_ROLE,
      name: userEventTypesNames.HYPERION_CHANGE_USER_ROLE,
    },
  ];
};
