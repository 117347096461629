import React from 'react';
import { ReactComponent as QuestionCircleSVG } from 'assets/images/services/question-circle.svg';

import * as S from './styles';

function TextBlock({ title, text, isRow, greyColor, children, id, font, tooltipMessage, isForm }) {
  const fontStyle = font ? `font-${font}` : 'font-14';

  return (
    <S.Wrapper row={isRow}>
      {!!title && (
        <S.TitleText greyColor={greyColor} row={isRow} isForm={isForm} id={id}>
          {title}
          {tooltipMessage && (
            <S.TooltipStyled content={tooltipMessage} position="right">
              <QuestionCircleSVG className="ml-1 vertical-align-middle" />
            </S.TooltipStyled>
          )}
        </S.TitleText>
      )}
      {text && <S.Text greyColor={greyColor}>{text}</S.Text>}
      <div className={`${fontStyle} hyphens-break-word`} aria-labelledby={id}>
        {children}
      </div>
    </S.Wrapper>
  );
}

export default TextBlock;
