import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function useInfinitePagination({ rowsCount, withInfinitePagination, loadMore, total }) {
  const { ref, inView, entry } = useInView();

  // Intersection pagination
  useEffect(() => {
    if (withInfinitePagination && rowsCount < total && inView) {
      loadMore?.();
    }
  }, [entry?.intersectionRect?.top, inView, withInfinitePagination]);

  return { ref };
}

export default useInfinitePagination;
