import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useSocketAsync from 'hooks/useSocketAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import useUserPermissions from 'hooks/useUserPermissions';
import { usersSelector } from 'store/users/selectors';
import { fetchUserRoles } from 'store/users/actions';
import { clearSelectedUserState } from 'store/users/reducers';
import paths from 'constants/paths';
import { systemRole, getSystemRoleLabels } from 'constants/roles';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent, { getSystemStatuses } from 'components/Common/StatusComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { BlueButton } from 'components/UI/Button';
import { scopes } from 'constants/scopes';
import { systemTypesKeys } from 'constants';

import UserAccessActions from './UserAccessActions';
import UserRolesLabel from '../../UsersPage/UsersTable/UserRolesLabel';

function UserAccessRules({ userId, isSuccessDelete, currentTotal, tableName, setRoleTotal }) {
  const { t } = useTranslation();
  const { checkedPermissions } = useUserPermissions();
  const systemRoleLabels = getSystemRoleLabels();
  const systemStatuses = getSystemStatuses();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  const {
    params: { systemId },
  } = useRouteMatch();
  const filterData = [
    {
      id: 'system.shortName',
      name: t('common.system'),
      isHidden: systemId,
    },
    {
      id: 'system.name',
      name: t('common.fullName'),
      isHidden: systemId,
    },
    {
      id: 'role',
      name: t('common.role'),
      type: 'multiSelect',
      options: [
        {
          id: 'isResponsible',
          name: t('pages.systemDetailsPage.responsible'),
          group: ' ',
        },
        { id: systemRole.OPS, name: systemRoleLabels[systemRole.OPS], group: t('common.role') },
        { id: systemRole.DEV, name: systemRoleLabels[systemRole.DEV], group: t('common.role') },
      ],
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const {
    selectedUserRoles,
    selectedUserRolesTotal,
    selectedUserRolesIsLoading,
    selectedUserRolesError,
  } = useSelector(usersSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const headerData = [
    {
      key: 'system.shortName',
      label: t('common.system'),
      withSort: true,
      minWidth: '150px',
    },
    {
      key: 'system.name',
      label: t('common.fullName'),
      withSort: true,
      width: 25,
    },
    {
      key: 'role',
      label: t('common.role'),
      withSort: true,
      width: 20,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    tableName || 'adminUserRoles'
  );
  const requestFunction = requestParameters => dispatch(fetchUserRoles(requestParameters));
  const defaultParams = useMemo(
    () => ({
      ...filterParams,
      id: userId,
      system: systemId,
      orderBy: 'lastUpdatedAt',
      ascending: false,
      ...filterParams?.date,
      date: undefined,
    }),
    [filterParams, userId]
  );

  const refreshTableData = () => tableRef.current?.requestDataWithParams({ isSilentMode: true });

  useSocketAsync({
    topic: 'user-roles',
    debounceTime: 600,
    filterFn: ({ object: { user } }) => userId === user?.uuid,
    onMessage: () => refreshTableData(),
  });

  const deleteCallback = () => {
    if (systemId) {
      history.goBack();
    } else {
      refreshTableData();
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      deleteCallback();
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (!isShowFilters || !currentTotal) {
      setIsShowFilters(
        hasSelectedFilters || Boolean(currentTotal) || Boolean(selectedUserRolesTotal)
      );
    }
    setRoleTotal?.(selectedUserRolesTotal);
  }, [selectedUserRolesTotal, currentTotal, hasSelectedFilters]);

  useEffect(() => () => dispatch(clearSelectedUserState()), [userId]);

  const onClickGeneratePath = (system, uuid) => ({
    pathname: systemId
      ? generatePath(paths.routePaths.system + paths.systemPaths.userDetails, {
          systemId: system?.uuid,
          systemType: system?.type,
          userId: uuid,
        })
      : generatePath(paths.routePaths.admin + paths.adminUsersPaths.userDetails, {
          userId: uuid,
        }),
    state: {
      currentTab: 0,
    },
  });

  const formattedBodyData = selectedUserRoles?.map(role => ({
    id: role.uuid,
    data: [
      {
        key: 'system.shortName',
        content: role?.system?.shortName && (
          <LinkWithPermission
            system={role?.system}
            scope={scopes.system.systemViewForm}
            to={generatePath(paths.routePaths.system, {
              systemId: role.system.uuid,
              systemType: role.system.type,
            })}
            isTableLink
          >
            <StatusComponent
              statusId={
                role.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : role.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {role.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'system.name',
        content: role.system?.name && (
          <span className="hyphens-break-word">{role.system.name}</span>
        ),
      },
      {
        key: 'role',
        content: <UserRolesLabel currentSystem={role?.system} systemUser={role} userId={userId} />,
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={role?.lastUpdatedBy}
            system={role.system}
            scope={scopes.platform.userViewForm}
            isTableLink
            linkTo={
              role?.lastUpdatedBy?.uuid &&
              onClickGeneratePath(role.system, role?.lastUpdatedBy?.uuid)
            }
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: role.lastUpdatedAt,
      },
      {
        key: 'actions',
        content: (
          <UserAccessActions
            userAccess={role}
            callback={refreshTableData}
            isAdministration={!systemId}
          />
        ),
      },
    ],
  }));

  const addUsersButton = checkedPermissions(scopes.user.userAddToSystem, true) && (
    <BlueButton
      component={props => (
        <Link
          {...props}
          to={generatePath(
            systemId
              ? paths.adminUsersPaths.assignUsers
              : paths.adminFullPaths.users + paths.adminUsersPaths.assignUsers,
            {
              userId,
            }
          )}
        />
      )}
    >
      {t('pages.adminUserPage.assignToSystem')}
    </BlueButton>
  );

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{FilterComponent}</div>}
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        isLoading={selectedUserRolesIsLoading}
        total={selectedUserRolesTotal}
        tableName={tableName || 'adminUserRoles'}
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={defaultParams}
        hasSelectedFilters={hasSelectedFilters}
        isError={selectedUserRolesError}
        addButton={addUsersButton}
        showNoDataPerFiltersMessage={isShowFilters}
        emptyTableMessage={
          <div className="text-center font-20 font-weight-medium">
            <p>{t('pages.adminUserPage.tableNoDataMessage')}</p>
          </div>
        }
      />
    </>
  );
}

export default UserAccessRules;
