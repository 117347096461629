import { CloseButton } from 'components/UI/CloseButton';
import React from 'react';

function ModalHeader({ title, toggleModal }) {
  return (
    <div className="d-flex flex-row justify-content-sm-between w-100 p-4">
      <span className="font-24 font-weight-medium">{title}</span>
      <CloseButton onClick={toggleModal} />
    </div>
  );
}

export default ModalHeader;
