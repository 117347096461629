import { useState } from 'react';

const useStorage = key => {
  const [storageData, setStorageData] = useState(JSON.parse(window.localStorage.getItem(key)));

  const setToStorage = formdata => {
    window.localStorage.setItem(key, JSON.stringify(formdata));
  };

  const clearStorageData = () => {
    window.localStorage.removeItem(key);
    setStorageData(null);
  };

  return {
    storageData,
    setToStorage,
    clearStorageData,
  };
};

export default useStorage;
