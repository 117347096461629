export const systemTiersAction = {
  restore: 'restore',
  new: 'new',
};

export const systemTierPaths = {
  quote: '/quote',
  quoteId: '/:tierId/quote',
  component: `/components/:componentName/:componentId`,
  componentId: `/:tierId/components/:componentName/:componentId`,
  quotesHistory: 'quotes-history',
  deployedReleases: 'deployed-releases',
  integrations: 'integrations',
  notifications: 'notifications',
  addTier: `/${systemTiersAction.new}`,
  restore: `/:tierId/:action(${systemTiersAction.restore})`,
  tierId: '/:tierId',
};
