import styled from 'styled-components';
import { Th } from '@patternfly/react-table';

import SearchSVG from 'assets/images/basic/search.svg';

export const TableExternalWrapper = styled.div`
  position: relative;

  ${({ isEmpty }) => isEmpty && 'min-height: 265px;'}
`;

export const SearchIco = styled.div`
  width: 50px;
  height: 50px;
  background: url(${SearchSVG}) no-repeat 0 0;
  background-size: cover;
  opacity: 0.7;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 0;

  ${({ withSidesMargin }) =>
    withSidesMargin &&
    `
      width: auto;
      margin: 16px;
      padding-bottom: 8px;
  `}
`;

export const TableSpinner = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center p-5',
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.35);
  z-index: 1;
  min-height: 265px;
`;

export const ThStyled = styled(Th)`
  max-width: fit-content !important;
  white-space: normal !important;
  font-weight: 600 !important;
  background-color: transparent !important;

  ${({ $right }) =>
    $right &&
    `
    .pf-v5-c-table__button-content {
      float: right !important;
    }

    .pf-v5-c-table__button {
      float: right !important;
      padding-right: 0 !important;
    }
  `}

  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth} !important;`}

  .pf-v5-c-table__text {
    overflow: visible !important;
    white-space: normal !important;
  }

  .pf-v5-c-table__sort-indicator {
    margin-left: 0;
  }
`;

export const ThCheckboxesStyled = styled(Th)`
  max-width: fit-content !important;
  width: 20px !important;
`;

export const RightSide = styled.div`
  justify-content: flex-end;
`;
