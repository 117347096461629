import styled from 'styled-components';
import { Wizard } from '@patternfly/react-core';

export const WizardStyled = styled(Wizard).attrs({
  className: 'border-top',
})`
  .pf-v5-c-wizard__main {
    z-index: auto;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
  }

  .pf-v5-c-wizard__outer-wrap {
    z-index: 1 !important;
    min-height: auto;
  }

  .pf-v5-c-wizard__toggle {
    z-index: 2 !important;
  }

  .pf-v5-c-wizard__footer {
    z-index: 10;
    padding: 24px 16px 16px 24px !important;
    flex-direction: row-reverse;
    align-self: baseline;

    .pf-m-primary {
      ${({ $isLastStep }) => $isLastStep && 'display: none !important;'}
    }

    .pf-v5-c-wizard__footer-cancel {
      display: none;
    }

    button {
      margin-right: 8px;
      margin-bottom: 16px;
    }
  }

  .pf-v5-c-wizard__nav {
    width: 260px;
  }

  .pf-v5-c-wizard__outer-wrap {
    padding-left: 260px;
  }
`;

export const SaveButtonWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: -84px;
  right: 24px;
`;

export const CanselButtonWrapper = styled.div`
  position: absolute;
  z-index: 11;
  bottom: -60px;
  margin-left: 185px;

  ${({ $isBackHidden }) => $isBackHidden && 'margin-left: 100px;'}
  ${({ $isLastStep }) => $isLastStep && 'margin-left: 90px;'}
`;

export const NavItem = styled.span``;

export const SystemBlockWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-column gap-4',
})`
  width: 700px;
`;

export const DeleteButtonAbsoluteWrapper = styled.div`
  position: absolute;
  right: -37px;
  top: 33px;
`;

export const DynamicFormWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-column gap-4 py-4 pl-4 border',
})`
  padding-right: 48px;
  box-sizing: border-box;
  max-width: 1000px;
`;
