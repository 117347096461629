import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ReactModalStyled = styled(ReactModal)`
  position: absolute;
  left: 37%;
  top: 25%;
  border: 1px solid #cccccc;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
`;

export const DeployBlockWrapper = styled.div.attrs({
  className: 'd-flex flex-column px-4 pb-2 gap-3',
})`
  min-width: 590px;
`;
