import styled from 'styled-components';

export const Wrapper = styled.div.attrs({
  className: 'flex-column',
})`
  width: 440px;
`;

export const DataItem = styled.span.attrs({
  className: 'd-inline-block font-weight-normal font-12 border py-1 px-2 mb-2 mr-2',
})`
  width: fit-content;
  height: auto;
  word-break: break-word;
  hyphens: auto;
`;
