import React, { useEffect } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { Tooltip } from '@patternfly/react-core';
import { useDispatch } from 'react-redux';

import useModal from 'hooks/useModal';
import { clearServiceVersionsOptions } from 'store/serviceVersions/reducers';
import RelativeDate from 'components/Common/RelativeDate';
import { EditPencilIcon } from 'components/UI/EditPencilIcon';
import ServiceVersionsStatus from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable/ServiceVersionsStatus';
import InlineEditButtons from 'components/UI/InlineEditButtons';
import paths from 'constants/paths';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import ServiceVersionsName from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable/ServiceVersionsName';

import ContextSelectorServiceVersions from './ContextSelectorServiceVersions';

import * as S from './styles';

function EditLastVersion({ label, checkedVersion, setCustomVersions, serviceUuid, infoTooltip }) {
  const dispatch = useDispatch();

  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal(false);

  useEffect(() => {
    if (modalData) {
      setCustomVersions(prevState => ({ ...prevState, [serviceUuid]: modalData }));
    }
  }, [modalData]);

  const cancelClick = () => {
    toggleModal();
    dispatch(clearServiceVersionsOptions());
  };

  const onEditIconClick = () => {
    if (modalData) {
      setCustomVersions(prevState => ({ ...prevState, [serviceUuid]: modalData }));
      toggleModal();
    }
  };

  const onClickGeneratePath = (item, tab) => ({
    pathname: generatePath(paths.systemServiceVersionsFullPaths.details, {
      systemId,
      systemType,
      serviceVersionId: item.uuid,
    }),
    state: {
      currentTab: tab,
    },
  });

  const version = {
    ...(checkedVersion || {}),
    status: checkedVersion?.serviceVersionStatus,
    pipeline: { status: checkedVersion?.pipelineStatus },
  };

  return (
    <S.EditLastVersionWrapper>
      {!isModalVisible && (
        <div className="d-sm-flex flex-column">
          <div className="d-flex position-relative">
            {!!checkedVersion && (
              <LinkWithPermission
                scope={scopes.serviceVersion.versionViewForm}
                to={onClickGeneratePath(checkedVersion, 0)}
                isTableLink
                target="_blank"
              >
                <ServiceVersionsStatus version={version} statusWithTooltip>
                  <ServiceVersionsName version={version} />
                </ServiceVersionsStatus>
              </LinkWithPermission>
            )}

            {checkedVersion?.tag && (
              <Tooltip
                className={infoTooltip ? undefined : 'd-none'}
                content={infoTooltip}
                exitDelay={150}
                animationDuration={150}
              >
                <div label={label}>
                  <EditPencilIcon label={label} className="ml-2" onClick={toggleModal} />
                </div>
              </Tooltip>
            )}
          </div>
          {checkedVersion?.lastUpdatedAt && (
            <S.DescriptionWrapper className="mt-1">
              <RelativeDate date={checkedVersion?.lastUpdatedAt} position="bottom" />
            </S.DescriptionWrapper>
          )}
        </div>
      )}

      {isModalVisible && (
        <div className="d-flex position-relative">
          <ContextSelectorServiceVersions
            serviceUuid={serviceUuid}
            setCustomVersions={setCustomVersions}
            setModalData={setModalData}
            tag={checkedVersion?.tag}
            version={version}
            onEditClick={onEditIconClick}
            toggleModal={toggleModal}
            isModalVisible={isModalVisible}
          />
          <InlineEditButtons onCancelClick={cancelClick} />
        </div>
      )}
    </S.EditLastVersionWrapper>
  );
}

export default EditLastVersion;
