import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import AdminRoute from 'routes/AdminRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import DictionariesRouter from './DictionariesRouter';
import ServerTiersRouter from './ServerTiersRouter';
import UsersRouter from './UsersRouter';
import BackupsRouter from './BackupsRouter';
import AuditRouter from './AuditRouter';

const AdminRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute
      path={`${path}${paths.adminPaths.serverTiers}`}
      scope={scopes.platform.clusterViewSection}
      component={ServerTiersRouter}
    />

    <AdminRoute
      path={`${path}${paths.adminPaths.backups}`}
      scope={scopes.platform.backupViewSection}
      component={BackupsRouter}
    />

    <AdminRoute
      path={`${path}${paths.adminPaths.audit}`}
      scope={scopes.platform.auditViewSection}
      component={AuditRouter}
    />

    <Route
      path={`${path}${paths.adminPaths.users}`}
      scope={scopes.platform.userViewSection}
      component={UsersRouter}
    />

    <AdminRoute
      path={`${path}${paths.adminPaths.dictionaries}`}
      scope={scopes.platform.dictionaryViewSection}
      component={DictionariesRouter}
    />

    <Redirect to={path} />
  </Switch>
);

export default AdminRouter;
