import styled from 'styled-components';
import CriticalSVG from 'assets/images/service_version/analysis-critical.svg';
import HighSVG from 'assets/images/service_version/analysis-high.svg';
import MediumSVG from 'assets/images/service_version/analysis-medium.svg';
import LowSVG from 'assets/images/service_version/analysis-low.svg';
import ErrorsSVG from 'assets/images/service_version/static-analysis-errors.svg';
import VulnerabilitiesSVG from 'assets/images/service_version/static-analysis-vulnerabilities.svg';
import DefectsSVG from 'assets/images/service_version/static-analysis-defects.svg';
import PotentialVulnerabilitiesSVG from 'assets/images/service_version/static-analysis-potential-vulnerabilities.svg';

import { vulnerabilityTotalKeys, staticAnalysisTotalKeys } from 'constants';

const analysisImageExtension = ({ extension }) => {
  switch (extension) {
    case vulnerabilityTotalKeys.critical:
      return `${CriticalSVG}`;
    case vulnerabilityTotalKeys.high:
      return `${HighSVG}`;
    case vulnerabilityTotalKeys.medium:
      return `${MediumSVG}`;
    default:
      return `${LowSVG}`;
  }
};

export const AnalysisIcon = styled.div`
  background: url(${analysisImageExtension}) no-repeat;
  background-size: contain;
  min-width: 24px;
  min-height: 24px;
  align-self: center;
`;

const staticAnalysisImageExtension = ({ extension }) => {
  switch (extension) {
    case staticAnalysisTotalKeys.bugs:
      return `${ErrorsSVG}`;
    case staticAnalysisTotalKeys.vulnerabilities:
      return `${VulnerabilitiesSVG}`;
    case staticAnalysisTotalKeys.codeSmells:
      return `${DefectsSVG}`;
    default:
      return `${PotentialVulnerabilitiesSVG}`;
  }
};

export const StaticAnalysisIcon = styled.div`
  background: url(${staticAnalysisImageExtension}) no-repeat;
  background-size: contain;
  min-width: 24px;
  min-height: 24px;
  align-self: center;
`;
