import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

ReactModal.defaultStyles.content = {
  ...ReactModal.defaultStyles.content,
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  padding: 0,
  maxHeight: '90vh',
};

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: 'rgba(0,0,0,0.3)',
  zIndex: 101,
};

const useModal = (defaultState = false, defaultStyles) => {
  const [isModalVisible, setIsModalVisible] = useState(defaultState);
  const [modalData, setModalData] = useState(null);

  function toggleModal() {
    setIsModalVisible(prevState => !prevState);
  }

  useEffect(() => {
    if (defaultStyles) {
      ReactModal.defaultStyles.content = {
        ...ReactModal.defaultStyles.content,
        ...defaultStyles,
      };
    }
  }, [defaultStyles]);

  useEffect(() => {
    if (!isModalVisible) {
      setModalData(null);
    }
  }, [isModalVisible]);

  return {
    isModalVisible,
    setIsModalVisible,
    toggleModal,
    modalData,
    setModalData,
  };
};

export default useModal;
