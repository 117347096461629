import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { checkedUrlValue } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { notActiveSystemSelector, systemUserInProgressSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import InternalTierForm from 'pages/AddNewSystemRouter/AddSystemPage/InternalSystem/Tiers/TierForm';
import ExternalTierForm from 'pages/AddNewSystemRouter/AddSystemPage/ExternalSystem/Tiers/ExternalTierForm';
import { limitsKeys, unitMemoryDropdownOptions, cpuUnitKeys, unitMemoryNames } from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';
import {
  getProgressSystemMessage,
  getSystemHaveUpdetedUserMessage,
} from '../../../../constants/tooltips';

const defaultValues = { code: '', CPU: '', MEMORY: '' };

function AddTierPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, systemType, tierId, action },
  } = useRouteMatch();
  const { run, data } = useAsync();

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [isValidForm, setIsValidForm] = useState(true);
  const saveTier = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const systemUserInProgress = useSelector(systemUserInProgressSelector);
  const serverTierUuid = formMethods.watch('serverTier.uuid');
  const isSystemExternal = systemType === paths.systemTypes.external;
  const isRestoreAction = action === paths.systemTiersAction.restore;
  const title = isRestoreAction ? t('common.restoring') : t('pages.systemAddTierPage.create');
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const systemUserInProgressMessage = useMemo(
    () => (systemUserInProgress ? getSystemHaveUpdetedUserMessage() : null),
    [systemUserInProgress, i18n.language]
  );
  function requestFunction() {
    run(api.tiers.getTier({ systemId, tierId }));
  }

  useEffect(() => {
    if (isRestoreAction) {
      requestFunction();
    }
  }, []);

  useEffect(() => {
    if (data) {
      formMethods.reset({
        ...data,
        cpuUnit: cpuUnitKeys.cores,
        memoryUnit: unitMemoryNames.gib,
        storageUnit: unitMemoryNames.gib,
      });
    }
  }, [data]);

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  useEffect(() => {
    if (saveTier.isSuccess) {
      closeForm();
    }
  }, [saveTier.isSuccess]);

  const onSubmit = async ({
    availableCpu,
    availableMemory,
    availableStorage,
    CPU,
    MEMORY,
    STORAGE,
    devTier,
    cpuUnit,
    memoryUnit,
    storageUnit,
    ...formData
  }) => {
    const requestInternalFunction = isRestoreAction ? api.tiers.restoreTier : api.tiers.addTier;
    const currentFunction = isSystemExternal ? api.tiers.addExternalTier : requestInternalFunction;
    const formatedData = {
      tierId,
      devTier,
      ...(isRestoreAction ? {} : { system: { uuid: systemId }, code: formData.code }),
      serverTier: {
        uuid: formData.serverTier?.uuid,
      },
      hosts:
        !isSystemExternal && formData?.hosts
          ? formData.hosts.filter(item => checkedUrlValue(item.value))
          : [],
      limits: isSystemExternal
        ? undefined
        : [
            { type: limitsKeys.CPU, value: CPU, unitType: cpuUnit },
            {
              type: limitsKeys.MEMORY,
              value: MEMORY,
              unitType: unitMemoryDropdownOptions.find(item => item.id === memoryUnit).unit,
            },
            {
              type: limitsKeys.STORAGE,
              value: STORAGE,
              unitType: unitMemoryDropdownOptions.find(item => item.id === storageUnit).unit,
            },
          ],
    };

    saveTier.run(currentFunction(formatedData));
  };

  const disabledMessage = useMemo(
    () => notActiveSystemMessage || systemUserInProgressMessage,
    [systemId, notActiveSystemMessage, systemUserInProgressMessage, i18n.language]
  );
  const isDisabled =
    disabledMessage ||
    (!isSystemExternal && !serverTierUuid) ||
    Object.keys(isSystemExternal ? { code: '' } : defaultValues).find(
      item => !formMethods.getValues(item)
    ) ||
    !!Object.keys(formMethods.formState.errors).length ||
    (!isValidForm && !isSystemExternal);

  return (
    <PageLayout title={title} withoutSidePadding withSystemSelector>
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)} isExternal={isSystemExternal}>
          {isSystemExternal ? (
            <ExternalTierForm />
          ) : (
            <InternalTierForm
              isValidating={formMethods.formState.isValidating}
              setIsValidForm={setIsValidForm}
            />
          )}

          <ActionListGroup className="pt-4 mt-4">
            <Tooltip
              className={isDisabled ? undefined : 'd-none'}
              content={disabledMessage || (isDisabled && tooltipFormDisableMessage())}
              exitDelay={150}
              animationDuration={150}
            >
              <ActionListItem>
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  isDisabled={saveTier.isLoading || isDisabled}
                  isLoading={saveTier.isLoading}
                >
                  {t(isRestoreAction ? 'common.restoreShort' : 'common.add')}
                </Button>
              </ActionListItem>
            </Tooltip>
            <ActionListItem>
              <Button variant="link" id="cancel-button" className="ml-3" onClick={closeForm}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </PageLayout>
  );
}

export default AddTierPage;
