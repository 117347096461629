import styled, { css } from 'styled-components';

import { Button } from '@patternfly/react-core';

const withMargin = css`
  cursor: pointer;
  margin-right: ${({ $marginRight }) => ($marginRight ? '16px' : 0)};
  margin-left: ${({ $marginleft }) => ($marginleft ? '16px' : 0)};
`;

const BlueButtonCss = css`
  font-size: 14px;
  display: flex;
  color: #fff;
  padding: 7px 16px;
  background: #0f69c9;
  border: 1px solid #0f69c9;
  border-radius: 2px;
  white-space: nowrap;

  ${({ disabled }) =>
    disabled &&
    `
    background: #F0F0F0 !important;
    border-color: #F0F0F0 !important;
    color: #6A6E73 !important;
    pointer-events: none;
  `};
`;

const BlueButtonOld = styled.button`
  ${BlueButtonCss};
  ${withMargin};
`;

export const BlueButton = styled(Button).attrs(
  ({ isLoading }) =>
    isLoading && {
      spinnerAriaValueText: 'Downloading',
      isLoading: true,
    }
)`
  ${withMargin};

  font-size: 16px !important;

  ${({ $withoutBorderRight }) =>
    $withoutBorderRight &&
    `
    border-radius: 4px 0px 0px 4px;
  `}
`;

export const TextButton = styled(Button).attrs(
  ({ href }) =>
    href && {
      target: '_blank',
      component: 'a',
    }
)`
  margin: 0;
  padding: 0;
  user-select: text;
  width: fit-content;
  height: fit-content;
  font-size: 16px;

  ${withMargin};

  ${({ $tableLink }) =>
    $tableLink &&
    `
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
    text-align: left;
    font-size: 14px;
  `}

  ${({ tableIcon }) =>
    tableIcon &&
    `
    font-size: 14px;
    .pf-v5-c-button__icon {
      display: flex;
    }
  `}

  ${({ font }) => font && `font-size: ${font};`}
`;

export const BlueButtonBlock = styled.button`
  ${BlueButtonCss};
  ${withMargin};
`;

export const BlueButtonTextCss = css`
  display: block;
  height: 34px;
  line-height: 36px;
  font-size: 12px;
  font-weight: 400;
  color: #0f69c9;
  padding: 0 10px;
  background-color: transparent;
  white-space: nowrap;
`;

export const BlueTextButton = styled.button`
  ${BlueButtonTextCss};
  ${withMargin};

  border: 0;
  padding: 0 5px;
  width: ${({ width }) => width || 'auto'};
`;

export const RedTextWhiteButton = styled(BlueTextButton)`
  color: #c9190b;
  background: #fff;
  border: 1px solid #d2d2d2;
  padding: 4px 16px;
  font-size: 14px;
  line-height: 20px;
`;

export const WhiteButton = styled(BlueButtonOld)`
  background: #fff;
  color: #0f69c9;

  &:hover {
    border: 1px solid #06427e;
    color: #06427e;
  }
`;

export const GreenButton = styled(BlueButton)`
  width: ${({ width }) => width || 'auto'};

  &:hover {
    background-color: #016060 !important;
  }

  background-color: ${({ isDisabled }) => (isDisabled ? '#d2d2d2' : '#009596')} !important;
`;

export const LightGreenButton = styled(BlueButton)`
  &:hover {
    background-color: #346e2f !important;
  }

  ${({ isDisabled }) => !isDisabled && 'background-color: #50b047 !important;'}
`;
