import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { isBoolean, size, trim } from 'lodash';

import { numberInputValueRegex, wholeNumbersInputValueRegex } from 'constants/vaidations';
import { quoteStatusesKeys, showIconByStatus } from 'components/Common/QuoteLimits';

import {
  NumberInput,
  NumberWithTextInput,
  NumberInputWithDropdown,
  IconByStatus,
  ExampleWrapper,
  ExampleButton,
} from './styles';

const standartMask = '999999';

const NumberInputController = ({
  name,
  control,
  rules,
  intNumber,
  possibleRange,
  withValueText,
  addText,
  checledValue,
  label,
  setIsFocus,
  isFocus,
  dropdown,
  isGreaterStatus,
  examples,
  ...inputProps
}) => {
  const [mask, setMask] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue=""
      render={({ field }) => {
        const onChange = e => {
          if (!e.target.value) {
            return field.onChange('');
          }

          if (
            (possibleRange && e.target.value < possibleRange[0]) ||
            e.target.value > possibleRange?.[1]
          ) {
            return;
          }

          if (
            !e.target.value.match(intNumber ? wholeNumbersInputValueRegex : numberInputValueRegex)
          ) {
            return;
          }

          field.onChange(e);
        };

        const onExampleChange = e => {
          const value = Number(field.value) + Number(e);

          if (value >= 0) {
            field.onChange(value);
          }
        };

        if (addText) {
          const inputValue = `${field.value || 0} ${addText}`;
          if (inputProps?.disabled) {
            return <div className="mt-2">{inputValue}</div>;
          }

          return (
            <NumberInput
              data-input-for={label}
              value={inputValue}
              {...inputProps}
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={() => {
                setIsFocus(false);
              }}
            />
          );
        }

        if (withValueText) {
          const inputValue = `${field.value || 0} ${withValueText}`;
          if (inputProps?.disabled) {
            return (
              <div className="mt-2">
                {inputValue}
                {!!checledValue &&
                  checledValue !== field.value &&
                  showIconByStatus(
                    checledValue < field.value ? quoteStatusesKeys.GREATER : quoteStatusesKeys.LESS
                  )}
              </div>
            );
          }

          if (!isFocus) {
            setMask(`${standartMask.slice(0, String(field.value).length)} ${withValueText}`);
          }

          return (
            <div className="position-relative w-100">
              <NumberWithTextInput
                data-input-for={label}
                {...field}
                {...inputProps}
                mask={mask}
                maskChar=""
                placeholder={`0 ${withValueText}`}
                onBlur={e => {
                  const valueLength = size(trim(e.target.value, ' '));
                  if (valueLength) {
                    setMask(`${standartMask.slice(0, valueLength)} ${withValueText}`);
                  }
                  setIsFocus(false);
                }}
                onFocus={() => {
                  setMask(standartMask);
                  if (!isFocus) {
                    setIsFocus(true);
                  }
                }}
              />
              <IconByStatus>
                {!!checledValue &&
                  !!parseFloat(field.value) &&
                  checledValue !== parseFloat(field.value) &&
                  showIconByStatus(
                    checledValue < parseFloat(field.value)
                      ? quoteStatusesKeys.GREATER
                      : quoteStatusesKeys.LESS
                  )}
              </IconByStatus>
            </div>
          );
        }

        if (dropdown) {
          if (inputProps?.disabled) {
            return (
              <div className="mt-2">
                {`${field.value || 0}`}
                {!!checledValue &&
                  checledValue !== field.value &&
                  showIconByStatus(
                    checledValue < field.value ? quoteStatusesKeys.GREATER : quoteStatusesKeys.LESS
                  )}
              </div>
            );
          }

          return (
            <div className="position-relative">
              <div className="d-sm-flex flex-nowrap align-items-end gap-1">
                <div className="position-relative">
                  <NumberInputWithDropdown
                    data-input-for={label}
                    {...field}
                    {...inputProps}
                    onChange={onChange}
                    placeholder="0"
                    withRigthPadding={checledValue}
                    onFocus={() => {
                      setIsFocus(true);
                    }}
                    onBlur={() => {
                      setIsFocus(false);
                    }}
                  />
                  <IconByStatus $right="10px">
                    {isBoolean(isGreaterStatus) &&
                      showIconByStatus(
                        isGreaterStatus ? quoteStatusesKeys.GREATER : quoteStatusesKeys.LESS
                      )}
                  </IconByStatus>
                </div>
                {dropdown}
              </div>
              {!!examples.length && (
                <ExampleWrapper>
                  {examples.map(example => (
                    <ExampleButton key={name + example} onClick={() => onExampleChange(example)}>
                      {example}
                    </ExampleButton>
                  ))}
                </ExampleWrapper>
              )}
            </div>
          );
        }

        if (inputProps?.disabled) {
          return <div className="mt-2">{field.value}</div>;
        }

        return (
          <NumberInput
            data-input-for={label}
            {...field}
            {...inputProps}
            onChange={onChange}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
            }}
          />
        );
      }}
    />
  );
};

export default NumberInputController;
