import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import useModal from 'hooks/useModal';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchServiceVersions } from 'store/serviceVersions/actions';
import { serviceVersionsSelector } from 'store/serviceVersions/selectors';
import { clearStateServiceVersions } from 'store/serviceVersions/reducers';
import paths from 'constants/paths';
import StatusComponent, {
  getServiceAvailableStatuses,
  getSystemStatuses,
} from 'components/Common/StatusComponent';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import TotalSummary from 'components/Common/TotalSummary';
import { getServicepipelineStatusOptions, systemTypesKeys } from 'constants';

import ServiceVersionCommit from '../ServiceVersionCommit';
import DeleteServiceVertionForm from '../../CurrentServiceVersionPage/DeleteServiceVertionForm';
import ServiceVersionActions from '../ServiceVersionActions';
import ServiceVersionsStatus from './ServiceVersionsStatus';
import { tableFiltersToQueryParams } from './formatters';
import PipelineStages from './PipelineStages';
import ServiceVersionsName from './ServiceVersionsName';

function ServiceVersionsTable({
  releaseId,
  tableName,
  addButton,
  isShowFilters,
  setIsShowFilters,
  currentTotal,
}) {
  const { t } = useTranslation();
  const serviceAvailableStatuses = getServiceAvailableStatuses();
  const systemStatuses = getSystemStatuses();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, serviceId },
  } = useRouteMatch();
  const deleteServiceModal = useModal();

  const restartJobs = useAsync();
  const cancelJobs = useAsync();
  const deleteServiceVersion = useAsync();
  const { data, total, isLoading, isError } = useSelector(serviceVersionsSelector);
  const filterData = [
    {
      id: 'tagQuery',
      name: t('common.version'),
    },
    {
      id: 'branch',
      name: t('common.branch'),
    },
    {
      id: 'serviceQuery',
      name: t('common.service'),
      isHidden: serviceId,
    },
    {
      id: 'pipelineStatus',
      name: t('common.status'),
      type: 'multiSelect',
      options: getServicepipelineStatusOptions(),
    },
    {
      id: 'lastUpdatedBy',
      name: t('pages.systemServiceVersionsPage.initiator'),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const tableFilters = useMultiSelectFilterSection(filterData, tableName || 'serviceVersions');

  const headerData = [
    {
      key: 'tag',
      label: t('common.version'),
      withSort: true,
    },
    {
      key: 'commit',
      label: t('common.commit'),
      minWidth: '150px',
    },
    {
      key: 'service',
      label: t('common.service'),
      minWidth: '150px',
      withSort: true,
      isHidden: serviceId,
    },
    {
      key: 'releaseTotal',
      label: t('common.releases'),
    },
    {
      key: 'pipeline',
      label: t('common.stages'),
      minWidth: '150px',
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      withSort: true,
      isHidden: systemId,
    },
    {
      key: 'pipeline.status',
      label: t('common.status'),
      minWidth: '230px',
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('pages.systemServiceVersionsPage.initiator'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'createdAt',
      label: t('common.updatedAt'),
      withSort: true,
      minWidth: '100px',
    },
    {
      key: 'dropdownMenu',
      label: '',
      width: 1,
      modifier: modifierKeys.fitContent,
    },
  ];

  useSocketAsync({
    topic: 'serviceVersions',
    filterBySystemUuid: systemId,
    debounceTime: 600,
    filterFn: ({ object }) =>
      serviceId
        ? object.service?.uuid === serviceId
        : systemId
        ? object.system?.uuid === systemId
        : true,
    options: { callBackThrottleTime: 2000 },
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  const requestParams = useMemo(
    () => ({
      ...tableFilters?.filterParams,
      service: serviceId,
      release: releaseId,
      system: systemId,
      orderBy: 'createdAt',
      ascending: false,
      ...tableFilters.filterParams?.date,
      date: undefined,
    }),
    [systemId, serviceId, tableFilters?.filterParams]
  );

  const requestFunction = params =>
    dispatch(fetchServiceVersions(tableFiltersToQueryParams(params)));

  useEffect(() => () => dispatch(clearStateServiceVersions()), []);

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters?.(
        tableFilters?.hasSelectedFilters || Boolean(total) || Boolean(currentTotal)
      );
    }
  }, [tableFilters?.hasSelectedFilters, total, currentTotal]);

  const onClickGeneratePath = (item, tab) => ({
    pathname: generatePath(paths.systemServiceVersionsFullPaths.details, {
      systemId: item.system.uuid,
      systemType: item.system.type,
      serviceVersionId: item.uuid,
    }),
    state: {
      currentTab: tab,
    },
  });

  const formattedBodyData = data?.map(version => ({
    id: version.uuid,
    data: [
      {
        key: 'tag',
        content: (
          <LinkWithPermission
            system={version?.system}
            scope={scopes.serviceVersion.versionViewForm}
            to={onClickGeneratePath(version, 0)}
            isTableLink
          >
            <ServiceVersionsName version={version} />
          </LinkWithPermission>
        ),
      },
      {
        key: 'commit',
        content: <ServiceVersionCommit commit={version.commit} truncateMaxLines={2} />,
      },
      {
        key: 'service',
        content: (
          <LinkWithPermission
            system={version?.system}
            scope={scopes.service.serviceViewForm}
            to={generatePath(paths.systemFullPaths.services + paths.systemServicesPaths.service, {
              systemId: version.service.system.uuid,
              systemType: version.service.system.type,
              serviceId: version.service.uuid,
            })}
            isTableLink
          >
            {version.service?.status ? (
              <StatusComponent
                statusId={version.service.status}
                statusWithTooltip
                statuses={serviceAvailableStatuses}
                objectType="SERVICE"
              >
                {version.service.name}
              </StatusComponent>
            ) : (
              version.service.name
            )}
          </LinkWithPermission>
        ),
      },
      {
        key: 'releaseTotal',
        content: (
          <LinkWithPermission
            system={version?.system}
            scope={scopes.serviceVersion.versionViewForm}
            to={onClickGeneratePath(version, 3)}
            isTableLink
          >
            <TotalSummary
              total={version.totalSummary?.releaseTotal.total}
              requireApproval={version.totalSummary?.releaseTotal.requireApproval}
              isReleaseTotal
            />
          </LinkWithPermission>
        ),
      },
      {
        key: 'pipeline',
        content: version.pipeline && (
          <div className="d-flex flex-row gap-2 mr-2">
            <PipelineStages
              pipeline={version.pipeline}
              uuid={version.uuid}
              system={version?.system}
              service={version?.service}
            />
          </div>
        ),
      },
      {
        key: 'system.shortName',
        content: version?.system?.shortName && (
          <LinkWithPermission
            system={version?.system}
            scope={scopes.system.systemViewForm}
            to={generatePath(paths.routePaths.system, {
              systemId: version.system.uuid,
              systemType: version.system.type,
            })}
            isTableLink
          >
            <StatusComponent
              statusId={
                version.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : version.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {version.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'pipeline.status',
        content: <ServiceVersionsStatus version={version} />,
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={version?.lastUpdatedBy}
            system={version?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'createdAt',
        type: types.relativeTime,
        content: version.pipeline?.createdAt,
      },
      {
        key: 'dropdownMenu',
        content: (
          <ServiceVersionActions
            version={version}
            restartJobs={restartJobs}
            deleteServiceVersion={deleteServiceVersion}
            deleteServiceModal={deleteServiceModal}
            cancelJobs={cancelJobs}
          />
        ),
      },
    ],
  }));

  return (
    <div>
      <div className="d-flex align-items-start mx-4">
        {isShowFilters && tableFilters?.FilterComponent}
      </div>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        isLoading={isLoading}
        withStandartPagination
        requestParams={requestParams}
        requestFunction={requestFunction}
        tableName={tableName || 'serviceVersions'}
        total={total}
        headerColor="#ffffff"
        hasSelectedFilters={tableFilters?.hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />
      <DeleteServiceVertionForm
        {...deleteServiceModal}
        deleteServiceVersion={deleteServiceVersion}
      />
    </div>
  );
}

export default ServiceVersionsTable;
