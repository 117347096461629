import styled from 'styled-components';
import { InputsBlockWrapper } from 'components/UI/View';

export const FormBlocksWrapper = styled(InputsBlockWrapper)`
  gap: 20px;
`;

export const FormWrapper = styled.form.attrs({
  className: 'pl-4 mb-4',
})`
  width: 566px;
`;
