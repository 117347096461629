import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchServiceVersionsBuildResults = createAsyncThunk(
  'serviceVersionsBuildResults/fetchServiceVersionsBuildResults',
  async params => {
    const { data } = await api.serviceVersion.getServiceVersionBuildResults(params);

    return data;
  }
);

export const fetchServiceVersionsVulnerabilities = createAsyncThunk(
  'serviceVersionsBuildResults/fetchServiceVersionsVulnerabilities',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServiceVersionVulnerabilities(params);

    return data;
  }
);

export const fetchServiceVersionsStaticAnalysis = createAsyncThunk(
  'serviceVersionsBuildResults/fetchServiceVersionsStaticAnalysis',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServiceVersionStaticAnalysis(params);

    return data;
  }
);

export const fetchServiceVersionsUnitTesting = createAsyncThunk(
  'serviceVersionsBuildResults/fetchServiceVersionsUnitTesting',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServiceVersionUnitTesting(params);

    return data;
  }
);
