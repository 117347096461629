import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchIssues } from 'store/issues/actions';
import { issuesSelector } from 'store/issues/selectors';
import { issueOptions, issueStatusKeys } from 'constants';
import { dateConverter } from 'utils';
import NewDataTable from 'components/Common/NewDataTable';
import StatusComponent, {
  getMilestoneStatus,
  getTasksStatuses,
} from 'components/Common/StatusComponent';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import paths from 'constants/paths';

import CompleteButton from './CompleteButton';

function TasksTable({
  filterParams,
  checkedTasksUuids,
  setcheckedTasksUuids,
  setAllRenderUuids,
  setCheckedFlagResolved,
  checkedFlagResolved,
  hasSelectedFilters,
}) {
  const { t } = useTranslation();
  const tasksStatuses = getTasksStatuses();
  const milestoneStatus = getMilestoneStatus();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, action },
  } = useRouteMatch();
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'milestone.name',
      label: t('common.milestone'),
      minWidth: '100px',
      withSort: true,
    },
    {
      key: 'startDate',
      label: t('common.startDate'),
      right: true,
      minWidth: '130px',
      withSort: true,
    },
    {
      key: 'dueDate',
      label: t('common.plannedEndDate'),
      right: true,
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'doCompleted',
      label: '',
      minWidth: '200px',
    },
  ];
  const { data, total, isLoading, isError } = useSelector(issuesSelector);

  function requestFunction(requestParameters) {
    dispatch(fetchIssues(requestParameters));
  }

  useSocketAsync({
    topic: 'issues',
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  const issuesRequestParams = useMemo(
    () => ({
      ...filterParams,
      status: filterParams.status?.length
        ? filterParams?.status
        : [issueStatusKeys.NEW, issueStatusKeys.IN_PROGRESS],
      ascending: true,
      orderBy: 'startDate',
      system: systemId,
    }),
    [filterParams, action]
  );

  const onCompleteButtonClick = uuid => {
    setCheckedFlagResolved(prevState =>
      prevState.includes(uuid) ? prevState?.filter(id => id !== uuid) : [...prevState, uuid]
    );
  };

  const showNoDataPerFiltersMessage = useMemo(
    () => Object.keys(filterParams || {}).length,
    [total]
  );

  const formattedBodyData = data?.map(issue => {
    const menuOptions = [];

    if (issue.totalSummary.tierReleaseTotal?.prod) {
      menuOptions.push({
        id: issueOptions.taskSolved,
        name: t('pages.systemAddReleasesPage.markAsResolved'),
        isDisabled: issue.status === issueStatusKeys.DONE,
      });
    }

    if (issue.totalSummary.tierReleaseTotal?.dev && !issue.totalSummary.tierReleaseTotal?.prod) {
      menuOptions.push({
        id: issueOptions.sendForRevision,
        name: t('pages.systemAddReleasesPage.markForRework'),
      });
    }

    return {
      id: issue.uuid,
      data: [
        {
          key: 'name',
          content: (
            <LinkWithPermission
              system={issue?.system}
              scope={scopes.issue.issueViewForm}
              to={generatePath(paths.routePaths.system + paths.systemIssuesFullPaths.issues, {
                systemId: issue.system.uuid,
                systemType: issue.system.type,
                issuesId: issue.uuid,
              })}
              isTableLink
              target="_blank"
            >
              {issue.name}
            </LinkWithPermission>
          ),
        },
        {
          key: 'milestone.name',
          content: (
            <LinkWithPermission
              system={issue?.system}
              scope={scopes.milestone.milestoneViewForm}
              to={generatePath(
                paths.routePaths.system + paths.systemMilestonesFullPaths.milestone,
                {
                  systemId: issue.system.uuid,
                  systemType: issue.system.type,
                  milestoneId: issue.milestone.uuid,
                }
              )}
              isTableLink
              target="_blank"
            >
              {issue.milestone?.status ? (
                <StatusComponent
                  statusId={issue.milestone?.status}
                  statusWithTooltip
                  statuses={milestoneStatus}
                >
                  {issue.milestone.name}
                </StatusComponent>
              ) : (
                issue.milestone?.name
              )}
            </LinkWithPermission>
          ),
        },
        {
          key: 'startDate',
          content: <div className="float-right">{dateConverter(issue.startDate)}</div>,
        },
        {
          key: 'dueDate',
          content: <div className="float-right">{dateConverter(issue.dueDate)}</div>,
        },
        {
          key: 'status',
          content: issue?.status && (
            <StatusComponent statusId={issue.status} statuses={tasksStatuses} />
          ),
        },
        {
          key: 'doCompleted',
          content: issue && (
            <CompleteButton
              issue={issue}
              onCompleteButtonClick={onCompleteButtonClick}
              checkedTasksUuids={checkedTasksUuids}
              checkedFlagResolved={checkedFlagResolved}
              setCheckedFlagResolved={setCheckedFlagResolved}
            />
          ),
        },
      ],
    };
  });

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      requestFunction={requestFunction}
      requestParams={issuesRequestParams}
      withStandartPagination
      total={total}
      withCheckboxes
      checkedUuids={checkedTasksUuids}
      setCheckedUuids={setcheckedTasksUuids}
      setAllRenderUuids={setAllRenderUuids}
      tableName="tasksInRelease"
      isLoading={isLoading}
      hasSelectedFilters={hasSelectedFilters}
      showNoDataPerFiltersMessage={showNoDataPerFiltersMessage}
      isError={isError}
    />
  );
}

export default TasksTable;
