import React from 'react';
import { ExpandableSection, ExpandableSectionVariant, Tooltip } from '@patternfly/react-core';

function TruncateWithTooltipText({ content, icon, withoutExpandButton = 2 }) {
  if (!content) {
    return '-';
  }

  return (
    <Tooltip content={content} exitDelay={150} animationDuration={150}>
      <ExpandableSection
        variant={ExpandableSectionVariant.truncate}
        isExpanded={false}
        truncateMaxLines={withoutExpandButton}
        className="word-break-break-all w-content"
      >
        {content}
        {icon}
      </ExpandableSection>
    </Tooltip>
  );
}

export default TruncateWithTooltipText;
