import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import useModal from 'hooks/useModal';
import useAsync from 'hooks/useAsync';
import { fetchDictionaries } from 'store/dictionaries/actions';
import { dictionariesSelector } from 'store/dictionaries/selectors';
import paths from 'constants/paths';
import DataTable, { types } from 'components/Common/DataTable';
import WarningModal from 'components/Common/WarningModal';
import { BlueButton } from 'components/UI/Button';
import { SmallButtonsWrapperLeftMargin } from 'components/UI/View';
import { useTranslation } from 'react-i18next';

function DictionariesTable({ filterParams }) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const toDeleteDictionary = useAsync();
  const deleteDictionaryWarning = useModal();
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      width: '200px',
    },
    {
      key: 'id',
      label: 'ID',
      width: '90px',
      withSort: true,
    },
    {
      key: 'date',
      label: t('common.updatedAt'),
      width: '150px',
      withSort: true,
    },
    {
      key: 'parentName',
      label: t('pages.adminDictionaries.author'),
      width: '200px',
    },
    {
      key: 'dropdownMenu',
      label: '',
      width: '30px',
    },
  ];
  function requestFunction(requestParameters) {
    dispatch(fetchDictionaries(requestParameters));
  }

  const defaultParams = useMemo(
    () => ({
      ...filterParams,
      limit: 10,
      ascending: false,
    }),
    [filterParams]
  );

  const { data, total } = useSelector(dictionariesSelector);

  useEffect(() => {
    if (!data) {
      tableRef.current?.requestDataWithParams();
    }
  }, []);

  useEffect(() => {
    if (toDeleteDictionary.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [toDeleteDictionary]);

  const formattedBodyData = data.map(item => ({
    id: item.id,
    data: [
      {
        key: 'name',
        content: item.name,
        type: types.link,
        to: generatePath(paths.adminDictionariesPaths.edit, {
          dictionariesId: item.id,
        }),
      },
      {
        key: 'id',
        content: item.id,
      },
      {
        key: 'date',
        content: item.version,
      },
      {
        key: 'parentName',
        content: item?.parent?.name,
      },
      {
        key: 'dropdownMenu',
        content: '',
      },
    ],
  }));

  return (
    <>
      <DataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        total={total}
        tableName="dictionaries"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={defaultParams}
      />
      <WarningModal
        title={t('pages.adminDictionaries.unableToDeleteDictionary')}
        warningColor="#f0ab00"
        {...deleteDictionaryWarning}
      >
        <SmallButtonsWrapperLeftMargin>
          <BlueButton onClick={deleteDictionaryWarning.toggleModal}>
            {t('pages.adminDictionaries.understood')}
          </BlueButton>
        </SmallButtonsWrapperLeftMargin>
      </WarningModal>
    </>
  );
}

export default DictionariesTable;
