import { t } from 'i18next';

const common = {
  authorization: {
    KEYCLOAK_INFO: 'keycloak_info',
    TOKEN_TIMEOUT: 30,
  },
  system: {
    defaultLength: 10,
    defaultDebounceScroll: 100,
    ico: {
      INFO: 'INFO',
      WARNING: 'WARNING',
      ERROR: 'ERROR',
      ARCHIVE: 'ARCHIVE',
    },
    status: {
      ALL: 'ALL',
      ACTIVE: 'ONLY_ACTIVE',
      ARCHIVE: 'ONLY_ARCHIVE',
    },
  },
  stand: {
    resourcesType: {
      CPU: 'CPU',
      RAM: 'MEMORY',
      DISK: 'STORAGE',
      ZERO: '–',
    },
    status: {
      ALL: 'ALL',
      SUCCESS: 'SUCCESS',
      FAILED: 'FAILED',
    },
  },
  notifications: {
    status: {
      ID: 'status',
      ALL: 'ALL',
      READED: 'READ_ONLY',
      NOTREADED: 'NOT_READ',
    },
    type: {
      ID: 'type',
      ALL: 'ALL',
      ERROR: 'ERROR',
      WARNING: 'WARNING',
      INFO: 'INFO',
    },
    stand: {
      ID: 'stand',
      ALL: 'all',
    },
    section: {
      SYSTEM: 'system',
      TIER: 'tier',
    },
    period: {
      ID: 'period',
      TODAY: 'today',
      ALLTIME: 'alltime',
      CUSTOM: 'custom',
    },
    calendarSettings: {
      TYPE: 'horizontal',
      COLORS: ['#0F69C9', '#cccccc', '#00ff00'],
      DEFAULT_RANGE: 7,
      KEY_RANGE: 'selection',
      COUNT_MONTHS_IN_CALENDAR: 2,
    },
  },
  main: {
    countBlockStandInSystem: 3,
  },
  dateFormat: {
    DD_MM_YYYY: 'dd.MM.yyyy',
    DD_MMMM_YYYY: 'dd MMMM yyyy',
    WEEK_DAY: 'EEEEEE',
    HHMM: 'HH:mm',
    FULL_MONTH_WITH_YEAR: 'LLLL yyyy',
  },
  phone: {
    MASK: '+7 9999999999',
    PLACEHOLDER: '+7          ',
    MASK_CHAR: ' ',
  },
  quotas: {
    GB: 'GiB',
    GB_PLACEHOLDER: '0 GiB',
    LEN: 7,
  },
  paging: [
    {
      id: 5,
      name: t('constants.paging.5'),
    },
    {
      id: 10,
      name: t('constants.paging.10'),
    },
    {
      id: 20,
      name: t('constants.paging.20'),
    },
    {
      id: 50,
      name: t('constants.paging.50'),
    },
    {
      id: 100,
      name: t('constants.paging.100'),
    },
  ],
};

export default common;
