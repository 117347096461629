import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import { fetchMilestonesForSystemEdit } from 'store/milestones/actions';
import { milestonesForSystemEditSelector } from 'store/milestones/selectors';

import AddButton from 'components/UI/AddButton';

import ContractsForm from './ContractsForm';
import { SystemBlockWrapper } from '../../styles';

const dynamicParentField = 'contracts';

function Contracts() {
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField);
  const { watch, setValue } = useFormContext();
  const dispatch = useDispatch();

  const data = watch(`${dynamicParentField}`);
  const {
    params: { systemId },
  } = useRouteMatch();
  const milestones = useSelector(milestonesForSystemEditSelector);

  useEffect(() => {
    if (!milestones.data?.length && systemId) {
      dispatch(fetchMilestonesForSystemEdit({ system: systemId }));
    }

    setValue('step', 3);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.contracts')}</div>

      {fieldUuids.map((fieldUuid, index) => {
        const isDisabledDelete = milestones.data?.filter(
          milestone => milestone.contract.uuid === data[index]?.uuid
        );

        return (
          <ContractsForm
            key={fieldUuid}
            fieldUuids={fieldUuids}
            fieldUuid={fieldUuid}
            index={index}
            deleteField={deleteField}
            milestones={isDisabledDelete}
          />
        );
      })}
      <div className="d-flex mb-3">
        <AddButton onClick={addField}>{t('pages.addNewSystemPage.addContract')}</AddButton>
      </div>
    </SystemBlockWrapper>
  );
}

export default Contracts;
