import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import qs from 'qs';
import Keycloak from 'keycloak-js';

import { setUserPermissions } from 'store/user/reducers';
import C from 'constants/C';

const ENV = window._env_;

const configSso = {
  url: ENV.KEYCLOAK_URL,
  realm: ENV.KEYCLOAK_REALM,
  clientId: ENV.KEYCLOAK_CLIENT_ID,
};
const urlErrorPage = `${ENV.HYPERION_API_BASE_URL}/error`;
const keycloak = new Keycloak(configSso);

const { KEYCLOAK_INFO, TOKEN_TIMEOUT } = C.common.authorization;
const { localStorage } = window;

const setNewTokensToStorage = () => {
  if (keycloak) {
    localStorage.setItem(
      KEYCLOAK_INFO,
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      })
    );
  }
};

const getTokensFromStorage = () => JSON?.parse(localStorage.getItem(KEYCLOAK_INFO));

const clearTokens = () => localStorage.clear(KEYCLOAK_INFO);

export const logout = options => {
  clearTokens();
  keycloak.logout(options);
};

const login = async () => {
  try {
    clearTokens();
    localStorage.setItem('historyLength', window.history.length + 1);
    await keycloak.login();
  } catch (e) {
    if (e && window.location.href !== urlErrorPage) {
      window.location.href = urlErrorPage;
    }
  }
};

export const getUserName = () => keycloak?.tokenParsed?.preferred_username || '';

export const getUserUuid = () => keycloak?.subject;

export const getAccessToken = () => keycloak.token;

export const updateToken = async () => {
  try {
    const isSuccess = await keycloak.updateToken(TOKEN_TIMEOUT);
    if (!isSuccess) {
      throw new Error();
    }

    setNewTokensToStorage();
  } catch {
    await login();
  }

  return keycloak.token;
};

export const getUserPermissions = async (dispatch, setIsAppInitialize, setIsUserAuth) => {
  try {
    const info = await axios.post(
      `${ENV.KEYCLOAK_URL}/realms/hyperion/protocol/openid-connect/token`,
      qs.stringify({
        grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
        audience: 'hyperion-authorization',
        response_mode: 'permissions',
      }),
      {
        headers: {
          Authorization: `bearer ${getAccessToken()}`,
        },
      }
    );

    dispatch(setUserPermissions(info?.data));
    if (setIsAppInitialize) {
      setIsAppInitialize(true);
    }

    return info?.data;
  } catch (error) {
    if (error?.message.includes('status code 403')) {
      setNewTokensToStorage();
      setIsUserAuth(false);
      logout({ redirectUri: window.location.origin });
      return;
    }

    await updateToken();
    getUserPermissions();
  }
};

function useKeycloakAuth() {
  const dispatch = useDispatch();
  const [isUserAuth, setIsUserAuth] = useState(false);
  const [isAppInitialize, setIsAppInitialize] = useState(false);

  useEffect(() => {
    const keycloackInit = async () => {
      const tokens = getTokensFromStorage();

      try {
        // init keycloak with tokens
        await keycloak.init(tokens || {});

        if (!keycloak.authenticated) {
          await login();
        }

        setNewTokensToStorage();
        setIsUserAuth(true);
      } catch (e) {
        await login();
      }
    };

    keycloackInit();
  }, []);

  const setRoles = async () => {
    await getUserPermissions(dispatch, setIsAppInitialize, setIsUserAuth);
  };

  useEffect(async () => {
    if (isUserAuth) {
      setRoles();
    }
  }, [isUserAuth]);

  return {
    isAppInitialize,
  };
}

export default useKeycloakAuth;
