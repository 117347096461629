import styled from 'styled-components';

import BackArrowSVG from 'assets/images/basic/back-arrow.svg';

export const BackButton = styled.button`
  border: none;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  width: 177px;
  color: #0f69c9;
  cursor: pointer;
  background: url(${BackArrowSVG}) no-repeat;
  align-items: center;
  height: 16px;
  padding-bottom: 4px;
  padding-left: 20px;

  ${({ withMarginBottom }) =>
    withMarginBottom &&
    `
    margin-bottom: 24px;
  `};

  ${({ withoutSidePadding }) =>
    withoutSidePadding &&
    `
    margin-left: 24px;
  `};
`;
