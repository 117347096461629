import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useStorage from 'hooks/useStorage';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import AddButton from 'components/UI/AddButton';

import TierForm from './TierForm';
import { stepsKeys } from '../keys';

import { SystemBlockWrapper } from '../../styles';

const dynamicParentField = stepsKeys.tiers;

function Tiers() {
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField, true);
  const { setValue } = useFormContext();
  const { checkedPermissions } = useUserPermissions();
  const activeStepStorage = useStorage('internalSystemFormActiveStep');

  useEffect(() => {
    setValue('step', 5);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.tiers')}</div>

      {fieldUuids.map((fieldUuid, index) => (
        <TierForm
          key={fieldUuid}
          fieldUuid={fieldUuid}
          index={index}
          deleteField={deleteField}
          activeStepStorage={activeStepStorage}
          fieldUuids={fieldUuids}
        />
      ))}
      <div className="d-flex mb-3">
        <AddButton
          onClick={addField}
          disabled={
            !checkedPermissions(scopes.tier.tierCreate) ||
            !checkedPermissions(scopes.tier.tierCreateDev)
          }
        >
          {t('common.add')}
        </AddButton>
      </div>
    </SystemBlockWrapper>
  );
}

export default Tiers;
