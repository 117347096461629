import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ResponsibleUserSVG } from 'assets/images/users/responsibleUser.svg';

import StatusComponent from 'components/Common/StatusComponent';
import { roleStatusKeys } from 'constants';
import { getRoleStatus } from 'components/Common/StatusComponent/statuses';
import { getSystemRoleLabels } from 'constants/roles';

function UserRolesLabel({ currentSystem, systemUser, user, userId }) {
  const { t } = useTranslation();
  const systemRoleLabels = getSystemRoleLabels();
  const status = user ? user.userRoleStatus : systemUser.status;
  const objectUuid = user ? user.userRoleUuid : systemUser.uuid;
  const roleStatus = getRoleStatus();

  const getTooltipText = () => {
    if (status === roleStatusKeys.ACTIVE) {
      return t('pages.adminUsersPage.activatedRoleTooltip');
    }
  };

  return (
    <div className="d-flex flex-nowrap">
      <StatusComponent
        statusId={status}
        statusText={
          status === roleStatusKeys.ACTIVE && systemRoleLabels[systemUser?.name || user?.name]
        }
        objectUuid={
          status !== roleStatusKeys.ACTIVE && status !== roleStatusKeys.PENDING && objectUuid
        }
        objectType="USER_ROLE"
        tooltipText={getTooltipText()}
        statuses={roleStatus}
      />
      {currentSystem?.responsible.uuid === userId && (
        <Tooltip
          content={t('pages.adminUsersPage.isResponsibleTooltip')}
          exitDelay={150}
          animationDuration={150}
        >
          <ResponsibleUserSVG className="font-16 ml-2" />
        </Tooltip>
      )}
    </div>
  );
}

export default UserRolesLabel;
