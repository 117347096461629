import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'constants/paths';

import AddDictionaryPage from './AddDictionaryPage';
import DictionariesPage from './DictionariesPage';
import EditDictionariesPage from './EditDictionariesPage';

const DictionariesRouter = ({ match: { path } }) => (
  <Switch>
    <Route
      exact
      path={`${path}${paths.adminDictionariesPaths.new}`}
      component={AddDictionaryPage}
    />

    <Route
      exact
      path={`${path}${paths.adminDictionariesPaths.edit}`}
      component={EditDictionariesPage}
    />

    <Route exact path={path} component={DictionariesPage} />

    <Redirect to={path} />
  </Switch>
);

export default DictionariesRouter;
