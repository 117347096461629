import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const tierQuotesSlice = createSlice({
  name: 'tierQuotes',
  initialState: {
    data: [],
    isLoading: true,
    total: 0,
    quoteRequest: [],
    quoteRequestIsLoading: true,
  },
  reducers: {
    clearTierQuotesState: state => {
      state.data = [];
      state.isLoading = true;
      state.total = 0;
      state.quoteRequestIsLoading = true;
      state.quoteRequest = [];
    },
    clearQuotesState: state => {
      state.quoteRequestIsLoading = true;
      state.quoteRequest = [];
    },
  },
  extraReducers: {
    [actions.fetchTierQuotes.pending]: state => {
      state.isLoading = true;
    },
    [actions.fetchTierQuotes.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.isLoading = false;
      state.total = meta.total;
    },
    [actions.fetchTierQuotes.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.data = [];
        state.total = 0;
        state.isError = true;
      }
    },
    [actions.fetchQuoteRequest.pending]: state => {
      state.quoteRequestIsLoading = true;
    },
    [actions.fetchQuoteRequest.fulfilled]: (state, { payload: { data } }) => {
      state.quoteRequestIsLoading = false;
      state.quoteRequest = data;
    },
    [actions.fetchQuoteRequest.rejected]: state => {
      state.quoteRequestIsLoading = false;
    },
  },
});

export const { clearTierQuotesState, clearQuotesState } = tierQuotesSlice.actions;

export default tierQuotesSlice.reducer;
