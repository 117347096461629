import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useModal from 'hooks/useModal';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector } from 'store/systems/selectors';
import { usersSelector } from 'store/users/selectors';
import WarningModal from 'components/Common/WarningModal';
import Dropdown from 'components/Dropdown';
import { usersAccessOptions, roleStatusKeys, userStatusKeys } from 'constants';
import { systemRole, getSystemRoleLabels } from 'constants/roles';
import { checkedTierTotal } from 'utils';

function UserAccessActions({ userAccess, isBlueButtonDropdown, isAdministration }) {
  const { t } = useTranslation();
  const systemRoleLabels = getSystemRoleLabels();
  const history = useHistory();
  const {
    params: { systemId, userId },
  } = useRouteMatch();
  const { notActiveSystemMessage, optionsFilterByScopes } = useUserPermissions(userAccess.system);
  const currentSystem = useSelector(currentSystemSelector);
  const { selectedUser } = useSelector(usersSelector);
  const revertRolesWarning = useModal();
  const changeRoleWarning = useModal();
  const deleteRole = useAsync();
  const updateRole = useAsync();
  const getCurrentSystem = useAsync();

  const system = systemId ? currentSystem : getCurrentSystem.data;
  const isHaveProblemRole = !!(
    selectedUser?.totalSummary.userRoleTotal.error ||
    selectedUser?.totalSummary.userRoleTotal.inProgress
  );

  const notActiveMessage = useMemo(
    () => notActiveSystemMessage || checkedTierTotal(system?.totalSummary?.tierTotal),
    [notActiveSystemMessage, system?.totalSummary?.tierTotal]
  );

  const isResponsible = userAccess.system.responsible?.uuid === userId;

  const getTooltipMassage = () => {
    if (notActiveMessage) {
      return notActiveMessage;
    }

    if (selectedUser?.status === userStatusKeys.UPDATING_IN_PROGRESS) {
      return t('pages.adminUserPage.refreshingAccessLevelError');
    }

    if (selectedUser?.status === userStatusKeys.ERROR) {
      return t('pages.adminUserPage.accessLevelError');
    }

    if (selectedUser?.status === userStatusKeys.UPDATED) {
      if (isResponsible) {
        return t('pages.adminUserPage.isResponsibleError');
      }

      if (!isResponsible && userAccess?.status === roleStatusKeys.ERROR) {
        return t('pages.adminUserPage.roleError');
      }

      if (!isResponsible && (userAccess?.status !== roleStatusKeys.ACTIVE || isHaveProblemRole)) {
        return t('pages.adminUserPage.refreshingRoleError');
      }
    }
  };

  useEffect(() => {
    if (deleteRole.isSuccess && revertRolesWarning.isModalVisible) {
      revertRolesWarning.setIsModalVisible(false);
      if (systemId) {
        history.goBack();
      }
    }
  }, [deleteRole.isSuccess]);

  useEffect(() => {
    if (updateRole.isSuccess && changeRoleWarning.isModalVisible) {
      changeRoleWarning.setModalData();
      changeRoleWarning.toggleModal();
    }
  }, [updateRole.isSuccess]);

  const onDeleteRoleConfirm = () => {
    deleteRole.run(api.users.deleteUserRole(userId, userAccess.uuid));
  };

  const onUpdateRoleConfirm = () => {
    updateRole.run(
      api.users.updateUserRole(userId, userAccess.uuid, {
        uuid: userAccess.uuid,
        system: { uuid: userAccess.system.uuid },
        name: changeRoleWarning.modalData,
      })
    );
  };

  const onFilterChange = id => {
    if (id === usersAccessOptions.revert) {
      return revertRolesWarning.toggleModal();
    }

    changeRoleWarning.setModalData(
      userAccess.name === systemRole.DEV ? systemRole.OPS : systemRole.DEV
    );
    changeRoleWarning.toggleModal();
  };

  const optionsData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: usersAccessOptions.edit,
          name: t('pages.adminUserPage.changeRole'),
          tooltip: getTooltipMassage(),
          isDisabled: getTooltipMassage(),
          scope: scopes.user.userUpdateRole,
          isPlatformScope: isAdministration,
        },
        {
          id: usersAccessOptions.revert,
          name: t('pages.adminUserPage.revokeAccess'),
          tooltip: getTooltipMassage(),
          isDisabled: getTooltipMassage(),
          scope: scopes.user.userRevoke,
          isPlatformScope: isAdministration,
        },
      ]),
    [
      optionsFilterByScopes,
      selectedUser?.status,
      userAccess?.status,
      isResponsible,
      notActiveMessage,
      isHaveProblemRole,
    ]
  );

  const callback = () => {
    if (!systemId) {
      getCurrentSystem.run(api.systems.getSystem(userAccess.system?.uuid));
    }
  };

  return (
    <>
      {!!optionsData.length && (
        <Dropdown
          id={userId}
          onFilterChange={onFilterChange}
          optionData={optionsData}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRigh={isBlueButtonDropdown}
          callback={callback}
        />
      )}

      <WarningModal
        title={t('pages.adminUserPage.changingRole')}
        text={t('pages.adminUserPage.changeRole')}
        isLoading={updateRole.isLoading}
        onClick={onUpdateRoleConfirm}
        isModalVisible={changeRoleWarning.isModalVisible}
        toggleModal={changeRoleWarning.toggleModal}
        buttenText={t('pages.adminUserPage.change')}
        warningColor="#f0ab00"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t('pages.adminUserPage.changeRoleConfirm', [
              selectedUser?.login,
              userAccess.system.shortName,
              systemRoleLabels[changeRoleWarning.modalData],
            ]),
          }}
        />
      </WarningModal>

      <WarningModal
        title={t('pages.adminUserPage.revokingAccess')}
        text={t('pages.adminUserPage.revokeAccess')}
        isLoading={deleteRole.isLoading}
        onClick={onDeleteRoleConfirm}
        isModalVisible={revertRolesWarning.isModalVisible}
        toggleModal={revertRolesWarning.toggleModal}
        buttenText={t('pages.adminUserPage.revoke')}
        warningColor="#f0ab00"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t('pages.adminUserPage.revokeAccessConfirm', [
              selectedUser?.login,
              userAccess.system.shortName,
            ]),
          }}
        />
      </WarningModal>
    </>
  );
}

export default UserAccessActions;
