import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { checkedUrlValue } from 'utils';
import TextBlock from 'components/UI/TextBlock';
import { phoneNumberValidation } from 'constants/vaidations';
import { InputBlockWrapper } from 'components/UI/View';

import * as S from 'components/Common/SystemHeader/ContactsInfoModal/styles';

function SystemDeveloper({ name, phone, email, site, address, representatives }) {
  const { t } = useTranslation();

  return (
    <div className="d-sm-flex flex-column gap-3 pt-3 border-top">
      <TextBlock
        greyColor
        title={<span className="font-12 font-weight-semiBold">{t('common.name')}</span>}
        font="20"
      >
        {name ? <span className="font-weight-medium">{name}</span> : '-'}
      </TextBlock>

      <InputBlockWrapper columnsCount={3}>
        <TextBlock
          greyColor
          title={<span className="font-12 font-weight-semiBold">{t('common.phone')}</span>}
        >
          {phone ? (
            <S.LinkWrapper
              className="text-wrap-nowrap"
              href={`tel:${phoneNumberValidation(phone)}`}
            >
              {phone}
            </S.LinkWrapper>
          ) : (
            '-'
          )}
        </TextBlock>
        <TextBlock
          greyColor
          title={<span className="font-12 font-weight-semiBold">{t('common.email')}</span>}
        >
          {email ? <S.LinkWrapper href={`mailto:${email}`}>{email}</S.LinkWrapper> : '-'}
        </TextBlock>
        <TextBlock
          greyColor
          title={<span className="font-12 font-weight-semiBold">{t('common.site')}</span>}
        >
          {site ? (
            <>
              {checkedUrlValue(site) ? (
                <a href={site} target="_blank" rel="noreferrer">
                  {site}
                </a>
              ) : (
                <Link to={`//${site}`} target="_blank">
                  {site}
                </Link>
              )}
            </>
          ) : (
            '-'
          )}
        </TextBlock>
      </InputBlockWrapper>

      <TextBlock
        greyColor
        title={<span className="font-12 font-weight-semiBold">{t('common.realAddress')}</span>}
      >
        {address || '-'}
      </TextBlock>

      {!!representatives?.length && (
        <>
          <h3 className="pb-2 font-14 font-weight-normal border-bottom">
            {t('common.representatives')}
          </h3>
          <S.WindowRespEmployeeInfo>
            <S.InformationBlockHead>
              <InputBlockWrapper columnsCount={3} className="pt-2 px-3">
                <TextBlock
                  title={<span className="font-14 font-weight-semiBold">{t('common.fio')}</span>}
                />
                <TextBlock
                  title={<span className="font-14 font-weight-semiBold">{t('common.email')}</span>}
                />
                <TextBlock
                  title={<span className="font-14 font-weight-semiBold">{t('common.phone')}</span>}
                />
              </InputBlockWrapper>
            </S.InformationBlockHead>
            {representatives.map(representative => (
              <InputBlockWrapper
                columnsCount={3}
                key={representative.uuid}
                className="border-top py-2 px-3"
              >
                <TextBlock font="14">
                  <S.NameWrapper>{representative.name || '-'}</S.NameWrapper>
                </TextBlock>
                <TextBlock font="14">
                  {representative.email ? (
                    <S.LinkWrapper href={`mailto:${representative.email}`}>
                      {representative.email}
                    </S.LinkWrapper>
                  ) : (
                    '-'
                  )}
                </TextBlock>
                <TextBlock font="14">
                  {representative.phone ? (
                    <S.LinkWrapper
                      href={`tel:${phoneNumberValidation(representative.phone)}`}
                      className="text-wrap-nowrap"
                    >
                      {representative.phone}
                    </S.LinkWrapper>
                  ) : (
                    '-'
                  )}
                </TextBlock>
              </InputBlockWrapper>
            ))}
          </S.WindowRespEmployeeInfo>
        </>
      )}
    </div>
  );
}

export default SystemDeveloper;
