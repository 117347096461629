import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { Tab, TabTitleText } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchServiceVersionById } from 'store/serviceVersions/actions';
import { clearStateBuildResults } from 'store/serviceVersionsBuildResults/reducers';
import {
  currentServiceVersionSelector,
  currentServiceVersionIsLoadingSelector,
  currentServiceVersionByIdErrorSelector,
} from 'store/serviceVersions/selectors';
import { changeCurrentServiceVersions } from 'store/serviceVersions/reducers';
import { serviceVersionsBuildResultsSelector } from 'store/serviceVersionsBuildResults/selectors';
import { fetchServiceVersionsBuildResults } from 'store/serviceVersionsBuildResults/actions';
import PageLayout from 'components/UI/PageLayout';
import { socketActionKeys } from 'constants';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import { staticAnalysisAction } from 'constants/paths/systemServiceVersionsPaths';
import paths from 'constants/paths';

import ServiceVersionInfo from './ServiceVersionInfo';
import ServiceVersionArtifacts from './ServiceVersionArtifacts';
import DeleteServiceVertionForm from './DeleteServiceVertionForm';
import ServiceVersionBuildResults from './ServiceVersionBuildResults';
import ServiceVersionsVulnerabilitysPage from './ServiceVersionBuildResults/ServiceVersionsVulnerabilitysPage';
import ServiceVersionsStaticAnalysis from './ServiceVersionBuildResults/ServiceVersionsStaticAnalysis';
import ServiceVersionHeader from './ServiceVersionInfo/ServiceVersionHeader';
import ServiceVersionsUnitTesting from './ServiceVersionBuildResults/ServiceVersionsUnitTesting';
import ServiceVersionActions from '../ServiceVersionsPage/ServiceVersionActions';
import ReleasesTable from '../../SystemReleasesRouter/ReleasesPage/ReleasesTable';
import StaticAnalysisProblems from './ServiceVersionBuildResults/ServiceVersionsStaticAnalysis/StaticAnalysisProblems';
import StaticAnalysisUnsafeAreas from './ServiceVersionBuildResults/ServiceVersionsStaticAnalysis/StaticAnalysisUnsafeAreas';

const CurrentServiceVersionPage = () => {
  const { t } = useTranslation();
  const {
    params: {
      systemId,
      systemType,
      serviceVersionId,
      vulnerabilityId,
      staticAnalysisId,
      unitTestingId,
      action,
    },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentTab, onSelectTab } = useCardTabs();

  const serviceVersion = useSelector(currentServiceVersionSelector);
  const serviceVersionIsLoading = useSelector(currentServiceVersionIsLoadingSelector);
  const currentServiceVersionByIdError = useSelector(currentServiceVersionByIdErrorSelector);
  const buildResults = useSelector(serviceVersionsBuildResultsSelector);

  const deleteServiceModal = useModal();
  const restartJobs = useAsync();
  const cancelJobs = useAsync();
  const deleteServiceVersion = useAsync();
  const [isShowReleasesFilters, setIsShowReleasesFilters] = useState(true);

  const isProblems = action === staticAnalysisAction.problems;
  const isUnsafeAreas = action === staticAnalysisAction.unsafeAreas;

  const redirectFunction = () =>
    history.push(
      generatePath(paths.systemFullPaths.serviceVersions, {
        systemId,
        systemType,
      })
    );

  const requestFunction = () => dispatch(fetchServiceVersionById(serviceVersionId));

  useSocketAsync({
    topic: 'serviceVersions',
    debounceTime: 600,
    filterFn: ({ object }) => serviceVersionId === object?.uuid,
    onMessage: async message => {
      if (message.action === socketActionKeys.DELETED) {
        return redirectFunction();
      }

      try {
        if (serviceVersionId) {
          const { data } = await api.serviceVersion.getServiceVersionByUuid(serviceVersionId);
          return dispatch(changeCurrentServiceVersions(data));
        }
      } catch (e) {
        if (e) {
          dispatch(changeCurrentServiceVersions(null));
          return redirectFunction();
        }
      }
    },
  });

  useEffect(() => {
    if (vulnerabilityId || staticAnalysisId || unitTestingId) {
      onSelectTab(null, 1);

      if (!buildResults.data?.length) {
        dispatch(
          fetchServiceVersionsBuildResults({
            serviceVersionId,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (serviceVersionId) {
      requestFunction();
    }
  }, [serviceVersionId]);

  useEffect(() => {
    if (deleteServiceVersion.isSuccess) {
      history.goBack();
    }
  }, [deleteServiceVersion.isSuccess]);

  const replacePath =
    serviceVersion?.uuid &&
    generatePath(paths.systemServiceVersionsFullPaths.details, {
      systemId: serviceVersion.system.uuid,
      systemType: serviceVersion.system.type,
      serviceVersionId: serviceVersion.uuid,
    });

  useEffect(() => {
    if (
      currentTab &&
      serviceVersion?.tag &&
      !serviceVersionIsLoading &&
      (vulnerabilityId || staticAnalysisId || unitTestingId)
    ) {
      history.goBack(-2);
      history.replace(replacePath);
    }
  }, [currentTab]);

  useEffect(() => () => dispatch(clearStateBuildResults()), []);

  const onResultsClick = e => {
    if (
      serviceVersion?.tag &&
      !serviceVersionIsLoading &&
      (vulnerabilityId || staticAnalysisId || unitTestingId) &&
      currentTab === 1
    ) {
      history.replace(replacePath);
    }
    onSelectTab(e, 1);
  };

  const buildResultPage = useMemo(
    () => (
      <>
        {staticAnalysisId && (
          <>
            {!isProblems && !isUnsafeAreas && <ServiceVersionsStaticAnalysis />}
            {isProblems && <StaticAnalysisProblems />}
            {isUnsafeAreas && <StaticAnalysisUnsafeAreas />}
          </>
        )}
        {vulnerabilityId && <ServiceVersionsVulnerabilitysPage />}
        {unitTestingId && <ServiceVersionsUnitTesting />}
        {!vulnerabilityId && !staticAnalysisId && !unitTestingId && <ServiceVersionBuildResults />}
      </>
    ),
    [vulnerabilityId, unitTestingId, staticAnalysisId, isProblems, isUnsafeAreas]
  );

  const leftSideContent = (
    <div className="mr-4 mt-4">
      <ServiceVersionActions
        version={serviceVersion}
        restartJobs={restartJobs}
        deleteServiceVersion={deleteServiceVersion}
        cancelJobs={cancelJobs}
        deleteServiceModal={deleteServiceModal}
        isBlueButtonDropdown
      />
    </div>
  );
  const title = (
    <div className="title-line-height">
      <ServiceVersionHeader serviceVersion={serviceVersion} />
    </div>
  );

  return (
    <PageLayout
      title={title}
      leftSideContent={leftSideContent}
      withoutSidePadding
      withSystemSelector
      alignSelfCenterTitle
      isError={currentServiceVersionByIdError}
      isWaitLoading={{ isLoading: serviceVersionIsLoading }}
    >
      <div className="h-100">
        <TabsStyled
          activeKey={currentTab}
          onSelect={onSelectTab}
          aria-label={t('common.serviceVersions')}
          className="bg-white"
        >
          <Tab eventKey={0} title={<TabTitleText>{t('common.description')}</TabTitleText>}>
            <ServiceVersionInfo serviceVersion={serviceVersion} />
          </Tab>
          <Tab
            eventKey={1}
            title={
              <TabTitleText className="d-flex">
                {t('pages.systemAddServiceVersionPage.buildResult')}
                <TotalSummaryBadge
                  total={serviceVersion?.totalSummary?.buildResultTotal?.total}
                  isSelect={currentTab === 1}
                />
              </TabTitleText>
            }
            onClick={onResultsClick}
          >
            {buildResultPage}
          </Tab>
          <Tab
            eventKey={2}
            title={
              <TabTitleText className="d-flex">
                {t('pages.systemAddServiceVersionPage.artifacts')}
                <TotalSummaryBadge
                  total={serviceVersion?.totalSummary?.artifactTotal?.total}
                  isSelect={currentTab === 2}
                />
              </TabTitleText>
            }
          >
            <div className="mt-4">
              <ServiceVersionArtifacts serviceVersionId={serviceVersionId} />
            </div>
          </Tab>
          <Tab
            eventKey={3}
            title={
              <TabTitleText className="d-flex">
                {t('common.releases')}
                <TotalSummaryBadge
                  total={serviceVersion?.totalSummary?.releaseTotal?.total}
                  isSelect={currentTab === 3}
                />
              </TabTitleText>
            }
          >
            <div className="mt-4">
              <ReleasesTable
                serviceVersionId={serviceVersionId}
                isShowFilters={isShowReleasesFilters}
                setIsShowFilters={setIsShowReleasesFilters}
                currentTotal={
                  serviceVersion?.uuid && serviceVersion?.totalSummary?.releaseTotal?.total
                }
              />
            </div>
          </Tab>
        </TabsStyled>
      </div>

      <DeleteServiceVertionForm
        {...deleteServiceModal}
        deleteServiceVersion={deleteServiceVersion}
      />
    </PageLayout>
  );
};

export default CurrentServiceVersionPage;
