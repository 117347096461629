import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchDeployedReleases = createAsyncThunk(
  'deployedReleases/fetchDeployedReleases',
  async ({ isConcatData, ...params }) => {
    const response = await api.tiers.getDeployedRelease(params);
    return {
      ...response.data,
      isConcatData,
    };
  }
);
