import styled from 'styled-components';

export const TabContent = styled.div.attrs({
  className: 'bg-main pl-4 pr-1 pt-4',
})`
  padding-left: 14px !important;
`;

export const BlocksWrapper = styled.div`
  ${({ isEnternal }) => (!isEnternal ? 'padding-right: 10px;' : 'margin-left: -10px;')}
`;

export const ContactsWrapper = styled.div.attrs({
  className: 'col-lg-12 mb-3',
})`
  padding-right: 20px;
`;
