import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatFilterOptions } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { getBackupStatusNames } from 'constants';
import PageLayout from 'components/UI/PageLayout';
import EditFrequency from 'pages/SystemRouter/SystemTiersRouter/CurrentTierPage/TierBackups/BackupTable/EditFrequency';

import TierComponentBackupTable from './TierComponentBackupTable';

const tableName = 'tierComponent';

function TierComponentBackupPage() {
  const { t } = useTranslation();
  const backupStatusNames = getBackupStatusNames();
  const filterData = [
    {
      id: 'comment',
      name: t('common.comment'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(backupStatusNames),
    },
    {
      id: 'date',
      name: t('common.date'),
      type: 'date',
    },
  ];
  const history = useHistory();
  const {
    params: { tierId, componentName, componentId },
  } = useRouteMatch();
  const { state } = history.location;
  const [historyState] = useState(state);

  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    tableName,
    historyState?.date ? historyState : null
  );

  const { run, data, isLoading, isSuccess, isRejected, isError } = useAsync();
  const currentComponent = useAsync();

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'backupEndDateTime',
      ascending: false,
      componentName,
      dateTimeFrom: historyState?.date?.startDate || filterParams?.date?.startDate,
      dateTimeTo: historyState?.date?.endDate || filterParams?.date?.endDate,
      date: undefined,
    }),
    [componentName, filterParams, historyState]
  );

  const requestFunction = params => run(api.backups.getBackups(params));
  const requestComponentFunction = () =>
    currentComponent.run(api.backups.getBackupComponent(componentId));

  useEffect(() => {
    requestComponentFunction();
  }, []);

  useEffect(() => {
    if (historyState?.date) {
      history.replace({ state: { date: null } });
    }
  }, [historyState?.date]);

  return (
    <PageLayout
      title={`${t('common.component')} ${componentName}`}
      withoutSidePadding
      withSystemSelector={tierId}
    >
      <div className="d-sm-flex flex-column">
        <div className="d-flex flex-row border-bottom px-4 pb-3">
          <span className="mr-1">{t('common.frequency')}</span>
          <EditFrequency
            {...currentComponent.data?.frequency}
            componentUuid={currentComponent.data?.uuid}
            tierUuid={tierId}
            requestFunction={requestComponentFunction}
          />
        </div>
        <div className="d-sm-flex flex-row mx-4">{FilterComponent}</div>
      </div>
      <TierComponentBackupTable
        requestParams={requestParams}
        data={data}
        requestFunction={requestFunction}
        isLoading={isSuccess || isRejected ? isLoading : true}
        hasSelectedFilters={hasSelectedFilters}
        tableName={tableName}
        isError={isError}
      />
    </PageLayout>
  );
}

export default TierComponentBackupPage;
