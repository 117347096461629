import React from 'react';

import { CloseButton } from 'components/UI/CloseButton';

import * as S from './styles';

function DataBlock({ data, removeItem, name }) {
  return (
    <S.DataBlock>
      {data.map((item, index) => (
        <S.DataItem key={`${item.name || item + index}`}>
          {item.name || item}
          <CloseButton onClick={() => removeItem(name || item, item)} />
        </S.DataItem>
      ))}
    </S.DataBlock>
  );
}

export default DataBlock;
