import React from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { scopes } from 'hooks/useUserPermissions';
import StatusComponent from 'components/Common/StatusComponent';
import paths from 'constants/paths';
import { userStatusKeys } from 'constants';

import RelativeDate from '../RelativeDate';
import LinkWithPermission from '../LinkWithPermission';
import { getUserStatus } from '../StatusComponent/statuses';

const ENV = window._env_;
const hyperionAdminUser = ENV.HYPERION_ADMIN_USER;

function LastUpdatedStatusWithLink({
  lastUpdatedBy,
  lastUpdatedAt,
  hideDateTime,
  isTableLink,
  linkTo,
  system,
  scope = scopes.platform.userViewForm,
  isPlatformScope = true,
  font = 'font-14',
}) {
  const { t } = useTranslation();
  const userStatus = getUserStatus();
  const {
    params: { systemType, systemId },
  } = useRouteMatch();
  const adminUserMessage = t('components.lastUpdate.adminHint');
  const userMessage = (
    <div className="font-12">
      {lastUpdatedBy?.fullName && (
        <span className="hyphens-break-word">
          {lastUpdatedBy.fullName} <br />
        </span>
      )}
      {lastUpdatedBy?.email && (
        <span className="word-break-break-all">
          {lastUpdatedBy.email} <br />{' '}
        </span>
      )}
      {lastUpdatedBy?.workplace && (
        <span className="hyphens-break-word">
          {lastUpdatedBy.workplace} <br />{' '}
        </span>
      )}
    </div>
  );

  if (!lastUpdatedBy) {
    return '-';
  }

  return (
    <div className={`d-flex flex-row ${font}`}>
      <LinkWithPermission
        system={system}
        scope={scope}
        isPlatformScope={isPlatformScope}
        isTableLink={isTableLink}
        to={
          lastUpdatedBy?.uuid
            ? linkTo ||
              (systemId && systemType
                ? generatePath(paths.routePaths.system + paths.systemPaths.userDetails, {
                    systemId: system?.uuid || systemId,
                    systemType: system?.type || systemType,
                    userId: lastUpdatedBy.uuid,
                  })
                : generatePath(paths.routePaths.admin + paths.adminUsersPaths.userDetails, {
                    userId: lastUpdatedBy?.uuid,
                  }))
            : undefined
        }
      >
        {lastUpdatedBy.login !== hyperionAdminUser ? (
          <StatusComponent
            statusWithTooltip
            statusId={
              lastUpdatedBy?.status === userStatusKeys.UPDATED
                ? lastUpdatedBy?.enabled
                : lastUpdatedBy?.status
            }
            objectUuid={lastUpdatedBy?.status !== userStatusKeys.UPDATED && lastUpdatedBy.uuid}
            objectType="USER"
            tooltipText={
              lastUpdatedBy?.status === userStatusKeys.UPDATED &&
              userStatus[lastUpdatedBy?.enabled]?.smallText
            }
            statuses={userStatus}
          >
            <Tooltip
              exitDelay={150}
              animationDuration={150}
              content={lastUpdatedBy ? userMessage : null}
            >
              <div className={font}>{lastUpdatedBy.login}</div>
            </Tooltip>
          </StatusComponent>
        ) : (
          <Tooltip exitDelay={150} animationDuration={150} content={adminUserMessage}>
            <div className="mr-1">{hyperionAdminUser}</div>
          </Tooltip>
        )}
      </LinkWithPermission>
      {lastUpdatedAt ? (
        <RelativeDate date={lastUpdatedAt} withSeconds font={font} />
      ) : !hideDateTime && !isTableLink ? (
        '-'
      ) : (
        ''
      )}
    </div>
  );
}

export default LastUpdatedStatusWithLink;
