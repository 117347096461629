import React from 'react';

import { limitsKeys } from 'constants';
import { convertLimits, convertValueToBytes, convertValueToMillicores } from 'utils';

import * as S from './styles';

const sortKeys = [limitsKeys.CPU, limitsKeys.MEMORY, limitsKeys.STORAGE];

export const quoteStatusesKeys = {
  LESS: 'LESS',
  GREATER: 'GREATER',
};

export const showIconByStatus = status => {
  if (status === quoteStatusesKeys.LESS) {
    return <S.DownArrowIcon />;
  }

  if (status === quoteStatusesKeys.GREATER) {
    return <S.UpArrowIcon />;
  }

  return null;
};

function QuoteLimits({ limits }) {
  const sortedLimits = sortKeys.map(key => limits.find(({ type }) => type === key));

  return (
    <>
      {sortedLimits.map(({ value, status, type, unitType }, index) => {
        const formatValue =
          type === limitsKeys.CPU
            ? convertValueToMillicores(value, unitType)
            : convertValueToBytes(value, unitType);

        return (
          <S.QuoteValue key={type}>
            {convertLimits(formatValue, type, 1, true)}
            {showIconByStatus(status)}
            {index !== sortedLimits.length - 1 && ' /'}
          </S.QuoteValue>
        );
      })}
    </>
  );
}

export default QuoteLimits;
