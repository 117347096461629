import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import BanIcon from '@patternfly/react-icons/dist/esm/icons/ban-icon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnitTestFailureSVG } from 'assets/images/service_version/unitTest-failure.svg';

import {
  vulnerabilityTotalKeys,
  buildResultTypeKeys,
  buildResultTotalSummaryKeys,
  unitTestTotalKeys,
  staticAnalysisTotalKeys,
  getVulnerabilitySeverityNames,
  getBuildResultsTooltipContent,
} from 'constants';

import * as S from './styles';

function BuildResults({
  totalSummary,
  type,
  withoutTooltip,
  staticAnalysis,
  isStaticAnalysisProblems,
}) {
  const { t } = useTranslation();
  const buildResultsTooltipContent = getBuildResultsTooltipContent();
  const vulnerabilitySeverityNames = getVulnerabilitySeverityNames();
  const textContent = {
    [buildResultTypeKeys.VULNERABILITY_ANALYSIS]: {
      [vulnerabilityTotalKeys.critical]: vulnerabilitySeverityNames.CRITICAL,
      [vulnerabilityTotalKeys.high]: vulnerabilitySeverityNames.HIGH,
      [vulnerabilityTotalKeys.medium]: vulnerabilitySeverityNames.MEDIUM,
      [vulnerabilityTotalKeys.low]: vulnerabilitySeverityNames.LOW,
    },
  };

  const resultsData = {
    [buildResultTotalSummaryKeys.VULNERABILITY_ANALYSIS]: Object.keys(vulnerabilityTotalKeys),
    [buildResultTotalSummaryKeys.STATIC_ANALYSIS]: Object.keys(staticAnalysisTotalKeys),
    [buildResultTotalSummaryKeys.UNIT_TESTING]: Object.keys(unitTestTotalKeys),
  };

  const unitTestImageExtension = extension => {
    switch (extension) {
      case unitTestTotalKeys.skippedTest:
        return <BanIcon className="w-icon-4 font-12" />;
      case unitTestTotalKeys.errorTest:
        return <UnitTestFailureSVG className="w-icon-4" />;
      case unitTestTotalKeys.failureTest:
        return <ExclamationCircleIcon className="text-warning-light w-icon-4 font-12" />;
      default:
        return <CheckCircleIcon className="text-gren-light w-icon-4 font-12" />;
    }
  };
  if (!totalSummary) {
    return t('pages.systemServiceVersionPage.noVulnerabilities');
  }

  const resultExtensionType = extension => {
    switch (type) {
      case buildResultTypeKeys.VULNERABILITY_ANALYSIS:
        return <S.AnalysisIcon extension={extension} />;
      case buildResultTypeKeys.UNIT_TESTING:
        return unitTestImageExtension(extension);
      default:
        return <S.StaticAnalysisIcon extension={extension} />;
    }
  };

  const currentTotals = totalSummary[buildResultTotalSummaryKeys[type]];
  const total = Object.values(currentTotals).reduce((acc, number) => acc + number, 0);

  return (
    <div className="d-flex">
      {withoutTooltip && !staticAnalysis && !isStaticAnalysisProblems && (
        <div className="text-wrap-nowrap mr-3">
          {t('pages.systemTierPage.tiersTotal')}: {total}
        </div>
      )}
      {!staticAnalysis &&
        resultsData[buildResultTotalSummaryKeys[type]].map(item => (
          <div key={item} className="d-flex mr-3">
            <Tooltip
              key={item}
              content={buildResultsTooltipContent[type][item]}
              exitDelay={150}
              animationDuration={150}
            >
              {resultExtensionType(item)}
            </Tooltip>
            {withoutTooltip && (
              <span className="text-wrap-nowrap">
                {type === buildResultTypeKeys.VULNERABILITY_ANALYSIS
                  ? textContent[type][item]
                  : buildResultsTooltipContent[type][item]}
                :&nbsp;
              </span>
            )}
            <span>{currentTotals[item]}</span>
          </div>
        ))}
    </div>
  );
}

export default BuildResults;
