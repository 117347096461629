import { createSlice } from '@reduxjs/toolkit';

const urlParamsSlice = createSlice({
  name: 'urlParams',
  initialState: { currentParams: {} },
  reducers: {
    changeUrlParams: (state, { payload }) => {
      state.currentParams = {
        ...state.currentParams,
        ...payload,
      };
    },
    clearParamByName: (state, { payload }) => {
      delete state.currentParams[payload];
    },
  },
});

export const { changeUrlParams, clearParamByName } = urlParamsSlice.actions;

export default urlParamsSlice.reducer;
