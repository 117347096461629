import React, { useMemo } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userPermissionsSelector } from 'store/user/selectors';
import { currentSystemSelector } from 'store/systems/selectors';
import { scopes } from 'constants/scopes';
import paths from 'constants/paths';
import { checkedSystemPermission } from 'utils';
import { getProgressSystemMessage } from 'constants/tooltips';
import { systemStatusKeys } from '../constants';

const ErrorRedirectComponent = () => <Redirect to={paths.routePaths.accessError} />;

function useUserPermissions(system) {
  const { i18n } = useTranslation();
  const currentSystem = useSelector(currentSystemSelector);
  const userPermissions = useSelector(userPermissionsSelector);
  const checkedSystem = system || currentSystem;
  const systemCode = system?.code || currentSystem?.code;
  const progressSystemMessage = useMemo(() => getProgressSystemMessage(), [i18n.language]);

  const checkedPermissions = (scope, isPlatformScope) => {
    if (isPlatformScope) {
      return checkedSystemPermission(userPermissions, scope);
    }

    if (systemCode) {
      return checkedSystemPermission(userPermissions, scope, systemCode);
    }
  };

  const optionsFilterByScopes = options =>
    options
      ?.filter(option => !option.isHidden)
      ?.filter(option =>
        option?.scope ? checkedPermissions(option.scope, option.isPlatformScope) : option
      );

  const hasPermissions = useMemo(
    () => userPermissions && systemCode && checkedPermissions(scopes.system.systemViewForm),
    [userPermissions, systemCode]
  );

  const platformPermissions = useMemo(
    () => userPermissions?.find(item => item.rsname === 'platform')?.scopes,
    [userPermissions]
  );

  const notActiveSystemMessage = useMemo(
    () =>
      checkedSystem?.uuid &&
      checkedSystem?.status !== systemStatusKeys.ACTIVE &&
      progressSystemMessage,
    [checkedSystem, i18n.language]
  );

  const notActiveOrErrorSystemMessage = useMemo(
    () =>
      checkedSystem?.uuid &&
      checkedSystem?.status !== systemStatusKeys.ACTIVE &&
      checkedSystem?.status !== systemStatusKeys.ERROR &&
      progressSystemMessage,
    [checkedSystem, i18n.language]
  );

  return {
    platformPermissions,
    hasPermissions,
    notActiveSystemMessage,
    notActiveOrErrorSystemMessage,
    checkedPermissions,
    optionsFilterByScopes,
    ErrorRedirectComponent,
  };
}

export { scopes };

export default useUserPermissions;
