import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAsync from 'hooks/useAsync';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { TableSpinner } from 'components/Common/NewDataTable/styles';
import SectionHeader from 'components/Common/SectionHeader';
import ContextSelectorDropdown from 'components/ContextSelectorDropdown';

import LimitSection from './LimitSection';

import * as S from './styles';

const firstItemIndex = 1;

function LimitsMonitoring({ requestFunction, uuid, itemName, numbersAfterComma }) {
  const { t } = useTranslation();
  const timeDropdownOptions = [
    {
      id: 300,
      name: t('pages.systemTierPage.5minutes'),
    },
    {
      id: 3600,
      name: t('pages.systemTierPage.1hour'),
    },
    {
      id: 21600,
      name: t('pages.systemTierPage.6hours'),
    },
    {
      id: 43200,
      name: t('pages.systemTierPage.12hours'),
    },
    {
      id: 86400,
      name: t('pages.systemTierPage.24hours'),
    },
  ];
  const [duration, setDuration] = useState(timeDropdownOptions[firstItemIndex].id);
  const { run, data, isLoading, isSuccess } = useAsync();

  const getMonitoring = (choosenTime = timeDropdownOptions[firstItemIndex].id) => {
    if (choosenTime) {
      setDuration(choosenTime);
    }

    if (requestFunction) {
      run(
        requestFunction({
          uuid,
          start: Math.round(Date.now() / 1000) - choosenTime,
          duration: choosenTime,
        })
      );
    }
  };

  useEffect(() => {
    if (uuid) {
      getMonitoring();
      const interval = setInterval(() => {
        getMonitoring();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [uuid]);

  return (
    <S.Body>
      <SectionHeader
        withoutBorder
        title={
          <S.ResourcesLeft>
            <S.ResourcesText>{t('pages.systemTierPage.resources')}</S.ResourcesText>
            <S.ResourcesText>{t('pages.systemTierPage.inUseUpper')}</S.ResourcesText>
          </S.ResourcesLeft>
        }
      >
        <ContextSelectorDropdown
          optionData={timeDropdownOptions}
          onFilterChange={getMonitoring}
          checkedValue={timeDropdownOptions[firstItemIndex].id}
          mini
          firstItemIndex={firstItemIndex}
        />
      </SectionHeader>
      {isLoading && !isSuccess && (
        <TableSpinner>
          <LoadingIndicator />
        </TableSpinner>
      )}
      {data?.map((item, index) => (
        <LimitSection
          key={item.code}
          {...item}
          duration={duration}
          itemName={itemName}
          numbersAfterComma={numbersAfterComma}
          isLastItem={index === data?.length - 1}
        />
      ))}
      {!isLoading && !data?.length && isSuccess && (
        <S.MonitoringText>{t('pages.systemTierPage.monitoringUnavailable')}</S.MonitoringText>
      )}
    </S.Body>
  );
}

export default React.memo(LimitsMonitoring);
