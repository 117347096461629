import http from 'api/config';

const getSystems = params => http.get(`systems`, { params });

const getSystemOptions = params => http.get(`systems/options`, { params });

const getHistory = params => http.get(`systems/views-history`, { params });

const getSystem = uuid => http.get(`systems/${uuid}`);

const getContracts = (uuid, params) => http.get(`systems/${uuid}/contracts`, { params });

const getResponsible = uuid => http.get(`systems/${uuid}/responsible`);

const getDevelopers = uuid => http.get(`systems/${uuid}/developers`);

const addSystem = data => http.post(`systems`, data);

const addExternalSystem = data => http.post(`systems/external`, data);

const restoreSystem = (uuid, data) => http.post(`systems/${uuid}/restore`, data);

const archiveSystem = uuid =>
  http.post(`systems/${uuid}/archive
`);

const editSystem = data => http.put(`systems`, data);

const editExternalSystem = data => http.put(`systems/external`, data);

const editSystemResponsible = (uuid, data) => http.put(`systems/${uuid}/responsible`, data);

const getSystemTiers = (uuid, { ...params }) => http.get(`systems/${uuid}/tiers`, params);

export default {
  getSystems,
  getHistory,
  getSystem,
  getContracts,
  getResponsible,
  getDevelopers,
  getSystemOptions,
  addSystem,
  addExternalSystem,
  editSystem,
  editExternalSystem,
  editSystemResponsible,
  archiveSystem,
  restoreSystem,
  getSystemTiers,
};
