import http from 'api/config';

const getAllServerTiers = params => http.get(`server-tiers`, { params });

const getServerTier = uuid => http.get(`server-tiers/${uuid}`);

const getServerTierDevices = ({ serverTierUuid, ...params }) =>
  http.get(`server-tiers/${serverTierUuid}/devices`, { params });

const getServerTierDevice = ({ serverTierId, equipmentId }) =>
  http.get(`server-tiers/${serverTierId}/devices/${equipmentId}`);

const testConnect = params => http.post(`server-tiers/test-connection`, params);

const deleteTier = uuid => http.delete(`server-tiers/${uuid}`);

const deleteEquipment = (serverTierUuid, equipmentUuid) =>
  http.delete(`server-tiers/${serverTierUuid}/devices/${equipmentUuid}`);

const createServerTier = serverTier => http.post(`server-tiers`, serverTier);

const updateServerTier = serverTier => http.put(`server-tiers/${serverTier.uuid}`, serverTier);

const createServerTierDevice = data => http.post(`server-tiers/${data.serverTierId}/devices`, data);

const updateServerTierDevice = data =>
  http.put(`server-tiers/${data.serverTierId}/devices/${data.uuid}`, data);

const getServerTiersOptions = params => http.get(`server-tiers/options`, { params });

const getClusterInfo = (data, params) =>
  http.post(`server-tiers/cluster-info`, data, {
    headers: params,
  });

export default {
  getAllServerTiers,
  getServerTier,
  getServerTierDevices,
  getServerTierDevice,
  testConnect,
  deleteTier,
  createServerTier,
  updateServerTier,
  createServerTierDevice,
  updateServerTierDevice,
  deleteEquipment,
  getServerTiersOptions,
  getClusterInfo,
};
