import React from 'react';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { dateConverter } from 'utils';

import MilestoneContract from '../../MilestonesPage/MilestonesTable/MilestoneContract';
import MilestoneDates from '../../MilestonesPage/MilestonesTable/MilestoneDates';

function MilestoneInfo({ milestone, isOpenMilestone }) {
  const { t } = useTranslation();

  if (!milestone) {
    return null;
  }

  return (
    <div className="m-4 w-75 font-14">
      <DescriptionList
        columnModifier={{
          default: '2Col',
        }}
      >
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.description')}</DescriptionListTerm>
          <DescriptionListDescription>{milestone.description || '-'}</DescriptionListDescription>
          <DescriptionListTerm className="mt-3">{t('common.contract')}</DescriptionListTerm>
          <DescriptionListDescription>
            <MilestoneContract contract={milestone.contract} isOpenMilestone={isOpenMilestone} />
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.startDate')}</DescriptionListTerm>
          <DescriptionListDescription>
            {`${dateConverter(milestone.startDate)}`}
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.endDate')}</DescriptionListTerm>
          <DescriptionListDescription>
            <MilestoneDates endDate={milestone.endDate} isOpenMilestone={isOpenMilestone} />
          </DescriptionListDescription>
        </DescriptionListGroup>
        <DescriptionListGroup />
        <DescriptionListGroup>
          <DescriptionListTerm>{t('common.updatedBy')}</DescriptionListTerm>
          <DescriptionListDescription>
            <LastUpdatedStatusWithLink
              lastUpdatedBy={milestone.lastUpdatedBy}
              lastUpdatedAt={milestone.lastUpdatedAt}
            />
          </DescriptionListDescription>
        </DescriptionListGroup>
      </DescriptionList>
    </div>
  );
}

export default MilestoneInfo;
