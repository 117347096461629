import styled from 'styled-components';

export const SystemsWrapper = styled.main.attrs({
  className: 'row',
})`
  height: calc(100vh - 230px);
  overflow-y: auto;
  align-content: flex-start;
`;

export const SystemsLoaderWrapper = styled.div.attrs({
  className: 'd-flex position-absolute justify-content-center align-items-center',
})`
  left: 0;
  right: 0;
  top: 15px;
  bottom: 0;
`;

export const MainTitle = styled.h2.attrs({
  className: 'd-flex justify-content-between font-24',
})`
  margin-bottom: 12px;
`;
