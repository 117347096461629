import React from 'react';
import { ExpandableSection, ExpandableSectionVariant } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';

import { relativeTimeConverter } from 'utils';

import * as S from './styles';

function ServiceVersionCommit({ commit, truncateMaxLines }) {
  const { t } = useTranslation();

  if (!commit?.message) {
    return '-';
  }

  const commitMessage = (
    <div className="font-12">
      <span>
        {t('common.commit')}: {commit?.sha}
      </span>
      <div className="my-2">
        {t('pages.systemServiceVersionPage.message')}: {commit?.message}
      </div>
      <div>
        {t('common.user')}: {commit?.authorName}
        {commit?.authorEmail && <span className="nowrap">, {commit?.authorEmail}</span>}
      </div>
      {commit?.createdAt && (
        <div className="mt-2 text-gray-tooltip">
          {t('pages.systemServiceVersionPage.message')} {relativeTimeConverter(commit.createdAt)}
        </div>
      )}
    </div>
  );

  return (
    <ExpandableSection
      variant={ExpandableSectionVariant.truncate}
      truncateMaxLines={truncateMaxLines}
      toggleContent={<></>}
      className="text-blu mt-1"
    >
      <S.CommitTooltipStyled content={commitMessage}>
        {commit?.message && (
          <a href={commit?.url} target="blank">
            <span className="mr-2">{commit.message}</span>
            <ExternalLinkAltIcon className="font-14" />
          </a>
        )}
      </S.CommitTooltipStyled>
    </ExpandableSection>
  );
}

export default ServiceVersionCommit;
