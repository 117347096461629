import styled from 'styled-components';

import { DatePicker } from 'components/Common/RangePicker/styles';

export const AuditDatePicker = styled(DatePicker)`
  .rdrDayInPreview {
    color: transparent !important;
  }

  .rdrDayHovered {
    position: relative;
    ::before {
      content: ' ';
      border: 1px solid rgb(61, 145, 255);
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 88%;
      position: absolute;
      top: 6%;
      left: 0;
    }
  }

  .rdrDayStartPreview {
    color: transparent !important;
  }

  .rdrDayEndPreview {
    color: transparent !important;
  }

  width: 300px;
  * {
    border-radius: 0 !important;
  }
`;

export const DatePickerWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: 432px;
  position: relative;
`;

export const OutsideHeaderWrapper = styled.div`
  position: absolute;
  margin-left: 549px;
  border-radius: 2px;
  width: 496px;
  z-index: 1000;

  ${({ filterStyles }) =>
    filterStyles &&
    `
    margin: 0;
    top: 40px;
    right: -64px;
  `};
`;

export const OutsideHeader = styled.div`
  height: 0;
`;

export const TimesBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 40px;
  width: 128px;
  height: calc(100% - 31px);
  top: 20px;
  overflow-y: scroll;
  align-items: center;
  margin-right: -30px;
`;

export const Time = styled.button`
  height: 24px;
  line-height: 20px;
  margin: 2px 0;
  width: 56px;
  color: #151515;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;

  &:hover {
    border-color: #0066cc;
  }

  ${({ active }) =>
    active &&
    `
    background-color: #0066cc;
    color: #fff;
  `};
`;
