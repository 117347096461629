import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { checkedSystemPermission, checkedTierTotal, checkedUrlValue, isValidUrl } from 'utils';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useStorage from 'hooks/useStorage';
import useWizardForm from 'hooks/useWizardForm';
import useSocketAsync from 'hooks/useSocketAsync';
import { currentSystemLoadingSelector, currentSystemSelector } from 'store/systems/selectors';
import { clearMilestonesForSystemEditState } from 'store/milestones/reducers';
import { fetchMilestonesForSystemEdit } from 'store/milestones/actions';
import { userPermissionsSelector } from 'store/user/selectors';
import { fetchSystemById } from 'store/systems/actions';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import { limitsKeys, unitMemoryDropdownOptions, protocolNames } from 'constants';
import { scopes } from 'constants/scopes';

import internalSystem from './InternalSystem';
import externalSystem from './ExternalSystem';

const systems = {
  INTERNAL: internalSystem,
  EXTERNAL: externalSystem,
};

function AddSystemPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { run, data, isSuccess, isLoading } = useAsync();
  const getContactPersons = useAsync();
  const getSystem = useAsync();
  const currentSystem = useSelector(currentSystemSelector);
  const loadingSystem = useSelector(currentSystemLoadingSelector);
  const userPermissions = useSelector(userPermissionsSelector);

  const {
    stepsForEdit,
    allSteps,
    systemModel,
    editSystemStorageKey,
    addSystemStorageKey,
    editSystemUnfilledStages,
    addSystemUnfilledStages,
    systemActiveStepStorageKey,
    systemEditActiveStepStorageKey,
  } = systems[systemType] ?? systems.INTERNAL;
  const formStorageKey = systemId
    ? `${editSystemStorageKey}${systemType}${systemId}`
    : `${addSystemStorageKey}${systemType}`;
  const { storageData } = useStorage(formStorageKey);

  const formMethods = useForm({
    defaultValues: storageData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getValues = formMethods.getValues();
  const uuidValues = formMethods.watch('uuid');
  const isStepIndex = formMethods.watch('step') || 1;
  const developersValue = formMethods.watch('developers');
  const isUserHaveAlertsValue = formMethods.watch('isUserHaveAlerts');
  const responsibleValue = formMethods.watch('responsible');
  const contactPersons = formMethods.watch('contactPersons');
  const systemContactPersons = formMethods.watch('systemContactPersons');

  const isUserHaveAlerts =
    systemId && currentSystem?.responsible?.uuid === responsibleValue
      ? false
      : isUserHaveAlertsValue;
  const steps = systemId ? stepsForEdit : allSteps;
  const newSystemRedirectPath = isSuccess
    ? generatePath(paths.routePaths.system, { systemId: data.uuid, systemType })
    : paths.routePaths.newSystem;
  const redirectPath = systemId
    ? generatePath(paths.routePaths.system, { systemId, systemType })
    : newSystemRedirectPath;
  const isExternal = systemType === paths.systemTypes.external;
  const titleMessage = systemId
    ? t('pages.addNewSystemPage.edit')
    : t('pages.addNewSystemPage.create');
  const currentStep = steps[isStepIndex - 1];

  useSocketAsync({
    topic: 'milestones',
    filterBySystemUuid: systemId,
    onMessage: () => dispatch(fetchMilestonesForSystemEdit({ system: systemId })),
  });

  useEffect(async () => {
    if (
      !isExternal &&
      systemId &&
      currentSystem?.uuid &&
      (!storageData?.uuid || systemId !== storageData?.uuid)
    ) {
      formMethods.reset(currentSystem);

      const systemResponse = await getSystem.run(api.systems.getSystem(systemId));

      if (
        checkedSystemPermission(userPermissions, scopes.system.systemViewForm, systemResponse?.code)
      ) {
        formMethods.reset({
          ...currentSystem,
          ...systemResponse,
          responsible: currentSystem?.responsible?.uuid,
        });
      } else {
        formMethods.reset(systemResponse);
      }
    }
  }, [currentSystem?.uuid]);

  useEffect(async () => {
    if (
      isExternal &&
      systemId &&
      currentSystem?.uuid &&
      (!storageData?.uuid || systemId !== storageData?.uuid)
    ) {
      formMethods.reset(currentSystem);

      const systemResponse = await getSystem.run(api.systems.getSystem(systemId));
      const contactPersonsData = await getContactPersons.run(
        api.contactPersons.getAllContactPersons(systemId)
      );
      const newContactPersons = contactPersons?.find(item => item?.userUuid?.length)
        ? contactPersons
        : contactPersonsData;
      const newSystemContactPersons = systemContactPersons?.find(item => item?.userUuid?.length);

      formMethods.reset(
        {
          ...systemResponse,
          contactPersons: newContactPersons?.map(item => ({
            ...item,
            userUuid: item?.uuid,
          })),
          systemContactPersons: newSystemContactPersons?.map(item => ({
            ...item,
            userUuid: item?.uuid,
          })),
        },
        { keepDefaultValues: true }
      );
    }
  }, [currentSystem]);

  const closeForm = () => {
    if (systemId) {
      history.push(
        generatePath(paths.routePaths.system, {
          systemId,
          systemType,
        })
      );
    } else {
      history.push(generatePath(paths.routePaths.main));
    }
  };

  const isValidFormContactPersons =
    getValues?.contactPersons?.length &&
    getValues?.contactPersons[0]?.fullName &&
    !getValues?.contactPersons.find(
      item =>
        !item.fullName?.length ||
        !item.email?.length ||
        !item.workplace?.length ||
        item.phone?.length !== 18
    );

  const isValidFormTiers =
    !getValues?.tiers?.length ||
    !getValues?.tiers.find(item =>
      isExternal
        ? !item.code?.length
        : !item.code?.length ||
          !item.serverTier.uuid?.length ||
          !item.CPU?.length ||
          !item.MEMORY?.length ||
          item.tierHaveAlerts
    );

  const checkedSiteValue = value =>
    value?.trim().replace(protocolNames.http, '').replace(protocolNames.https, '')?.length;
  const isValidSite =
    isExternal ||
    (developersValue &&
      (!checkedSiteValue(developersValue[0]?.site) || isValidUrl(developersValue[0]?.site)));

  const isValidForm =
    isValidFormTiers &&
    !isUserHaveAlerts &&
    (!developersValue || isValidSite) &&
    (isExternal
      ? isValidFormContactPersons
      : getValues.responsible && !checkedTierTotal(currentSystem?.totalSummary?.tierTotal));

  const onSubmit = async (formData, e) => {
    const isSaveAction =
      e.target.innerText === t('common.saveAndExit') ||
      e.target.innerText === t(systemId ? 'common.saveChanges' : 'common.add');

    if (isSaveAction && isValidForm) {
      const currentFunction = isExternal ? api.systems.addExternalSystem : api.systems.addSystem;
      const responsible = isExternal
        ? {
            contactPersons: formData.contactPersons?.map(
              ({ fullName, phone, email, comment, workplace, ...contactData }) => ({
                fullName,
                phone,
                email,
                comment,
                workplace,
                uuid: systemId ? contactData?.uuid : undefined,
              })
            ),
            removedContactPersons: formData?.removedContactPersons,
          }
        : { responsible: { uuid: formData.responsible } };
      const documents = (formData.documents || []).map(file => ({
        ...file,
        description: formData.documentsDescription,
      }));

      const site =
        formData?.developers && checkedSiteValue(formData.developers[0]?.site)
          ? formData.developers[0]?.site
          : null;
      const representatives =
        formData?.developers &&
        formData.developers[0]?.representatives.reduce((total, amount) => {
          if (Object.values(amount).find(item => item?.length)) {
            total.push({
              name: amount.name?.length ? amount.name : null,
              phone: amount.phone?.length ? amount.phone : null,
              email: amount.email?.length ? amount.email : null,
            });
          }
          return total;
        }, []);
      const formatDeveloper = formData?.developers && {
        ...formData.developers[0],
        representatives,
        site,
      };
      const developerData =
        formatDeveloper &&
        Object.keys(formatDeveloper).reduce(
          (acc, key) => ({
            ...acc,
            [key]: formatDeveloper[key]?.length ? formatDeveloper[key] : null,
          }),
          {}
        );
      const developers =
        developerData && Object.values(developerData).find(item => item) ? [developerData] : [];

      const formatedData = {
        ...formData,
        ...responsible,
        uuid: formData.uuid,
        developers,
        contracts: formData.contracts?.filter(contract => contract?.number?.trim()),
        tiers: formData.tiers
          ?.filter(tier => tier?.code)
          .map(
            ({
              availableCpu,
              availableMemory,
              availableStorage,
              CPU,
              MEMORY,
              STORAGE,
              cpuUnit,
              memoryUnit,
              storageUnit,
              ...tier
            }) => ({
              ...tier,
              name: tier.code,
              system: { uuid: formData.uuid },
              hosts: tier?.hosts ? tier.hosts.filter(item => checkedUrlValue(item.value)) : [],
              limits:
                systemType === paths.systemTypes.internal
                  ? [
                      { type: limitsKeys.CPU, value: CPU, unitType: cpuUnit },
                      {
                        type: limitsKeys.MEMORY,
                        value: parseFloat(MEMORY),
                        unitType: unitMemoryDropdownOptions.find(item => item.id === memoryUnit)
                          .unit,
                      },
                      {
                        type: limitsKeys.STORAGE,
                        value: parseFloat(STORAGE) || 0,
                        unitType: unitMemoryDropdownOptions.find(item => item.id === storageUnit)
                          .unit,
                      },
                    ]
                  : undefined,
            })
          ),
        documents,
        documentsDescription: undefined,
        systemContactPersons: undefined,
        type: undefined,
        gitlabGroupUrl: undefined,
        totalSummary: undefined,
        overlapContractDates: undefined,
        userOption: undefined,
        isUserHaveAlerts: undefined,
        responsibleUserOption: undefined,
        step: undefined,
      };

      if (systemId) {
        const currentEditFunction = isExternal
          ? api.systems.editExternalSystem
          : api.systems.editSystem;
        return run(currentEditFunction(formatedData));
      }
      run(currentFunction(formatedData));
    }
  };

  const { WizardComponent, clearData } = useWizardForm({
    defaultModal: systemModel,
    formMethods,
    onSubmit,
    closeForm,
    withBackButton: !systemId && currentStep?.id === 1,
    activeStepStorageKey: systemId
      ? `${systemEditActiveStepStorageKey}${systemType}${systemId}`
      : `${systemActiveStepStorageKey}${systemType}`,
    formStorageKey,
    unfilledStorageKey: systemId
      ? `${editSystemUnfilledStages}${systemType}${systemId}`
      : `${addSystemUnfilledStages}${systemType}`,
    isValidForm,
    steps,
    currentUuid: getSystem.data?.uuid,
    isLoading: isLoading || (systemId && loadingSystem) || getSystem.isLoading,
    isEditMode: systemId,
    saveLabel: systemId ? 'common.saveChanges' : 'common.add',
  });

  useEffect(() => {
    if (!systemId && !uuidValues) {
      formMethods.setValue('uuid', uuid());
    }
  }, [systemId, storageData]);

  useEffect(() => {
    if (isSuccess) {
      history.replace(redirectPath);

      if (systemId) {
        dispatch(
          fetchSystemById({
            id: systemId,
          })
        );
      }

      setTimeout(() => {
        clearData();
      }, 0);
    }
  }, [isSuccess]);

  useEffect(
    () => () => !isExternal && systemId && dispatch(clearMilestonesForSystemEditState()),
    []
  );

  return (
    <PageLayout fullHeight title={titleMessage} withoutSidePadding>
      <FormProvider {...formMethods}>{WizardComponent}</FormProvider>
    </PageLayout>
  );
}

export default AddSystemPage;
