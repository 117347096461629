import React from 'react';

import EquipmentTable from './EquipmentTable';

function ServerTierEquipment({ currentTotal }) {
  return (
    <div className="mt-4">
      <EquipmentTable currentTotal={currentTotal} />
    </div>
  );
}

export default ServerTierEquipment;
