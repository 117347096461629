import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useStorage from 'hooks/useStorage';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { isActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';

import SystemSelection from './SystemSelection';
import ServiceSelection from './ServiceSelection';

import * as S from './styles';
import IntegrationTiers from './IntegrationTiers';

const addIntegrationStorageKey = 'addNewIntegrationForm';
const integrationCurrentStepStorageKey = 'integrationFormCurrentStep';

const integrationModel = {
  systemUuid: '',
  inbound: false,
  systemFrom: { uuid: '', name: '' },
  systemTo: { uuid: '', name: '' },
  serviceEndpointTo: {
    async: null,
    authType: '',
    description: '',
    documents: [],
    name: '',
    protocol: '',
    requestData: [],
    responseData: [],
  },
  integrationTiers: [
    {
      dataExchange: true,
      tierFrom: { uuid: '' },
      serviceEndpointTierTo: {
        tier: { uuid: '' },
        uri: '',
      },
      tierFrequency: { value: '', unit: '' },
    },
  ],
};

function AddEditPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, systemType, integrationId, action },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const title = t('pages.systemIntegrationRelationsPage.integrationMode');
  const isActiveSystem = useSelector(isActiveSystemSelector);
  const { run, isSuccess, isLoading, setError } = useAsync();
  const getIntegration = useAsync();
  const editService = useAsync();
  const editTiersBounds = useAsync();
  const [isNotValidate, setIsNotValidate] = useState(true);

  const { storageData, setToStorage, clearStorageData } = useStorage(addIntegrationStorageKey);
  const currentStepStorage = useStorage(integrationCurrentStepStorageKey);

  const formMethods = useForm({
    defaultValues: storageData,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const formValue = formMethods.watch();

  const isCantChangeForm =
    checkedPermissions(scopes.integration.integrationUpdate) || !isActiveSystem;
  const redirectPath = generatePath(paths.systemFullPaths.integrations, { systemId, systemType });
  const isEditService = action === paths.integrationsAction.editService;
  const isEditTiersBound = action === paths.integrationsAction.editTiersBound;
  const isEditTitle = isEditService
    ? t('pages.systemIntegrationRelationsPage.serviceEditing')
    : t('pages.systemIntegrationRelationsPage.relationsEditing');
  const titleMessage =
    isEditService || isEditTiersBound
      ? isEditTitle
      : t('pages.systemIntegrationRelationsPage.addIntegration');
  const editStep = isEditService ? 2 : 3;

  const [currentStepIdReached, setStepIdReached] = useState({
    stepIdReached: isEditService || isEditTiersBound ? editStep : currentStepStorage.storageData,
  });
  const [currentStepId, setCurrentStepId] = useState(
    isEditService || isEditTiersBound ? editStep : currentStepStorage.storageData
  );

  useEffect(() => {
    if (isEditService || isEditTiersBound) {
      getIntegration.run(api.integrations.getIntegrationById(integrationId));
    }
  }, []);

  useEffect(() => {
    if (getIntegration.data && (isEditService || isEditTiersBound)) {
      formMethods.reset(getIntegration.data);
      formMethods.setValue(
        'systemUuid',
        getIntegration.data?.isInbound
          ? getIntegration.data?.systemFrom.uuid
          : getIntegration.data?.systemTo.uuid
      );
    }
  }, [getIntegration.data]);

  useEffect(() => {
    if (storageData && !isEditService && !isEditTiersBound) {
      formMethods.reset(storageData, { keepDefaultValues: true });
    }
  }, [storageData]);

  useEffect(() => {
    if (currentStepId) {
      currentStepStorage.setToStorage(currentStepId);
    }
  }, [currentStepId]);

  useEffect(() => {
    if (currentStepId === 3 && (!formValue?.serviceEndpointTo?.name || !formValue?.systemUuid)) {
      setCurrentStepId(1);
      currentStepStorage.setToStorage(1);
    }
  }, [currentStepId]);

  useEffect(() => {
    setError('');
    const subscription = formMethods.watch(setToStorage);
    return () => subscription.unsubscribe();
  }, [formMethods.watch]);

  useEffect(() => {
    if (isSuccess || editService.isSuccess || editTiersBounds.isSuccess) {
      clearStorageData();
      currentStepStorage.clearStorageData();
      formMethods.reset(integrationModel);
      setCurrentStepId();
      return history.replace(redirectPath);
    }
  }, [isSuccess, editService.isSuccess, editTiersBounds.isSuccess]);

  useEffect(() => {
    if (
      formValue.systemFrom?.uuid &&
      formValue.systemTo?.uuid &&
      formValue.systemFrom?.uuid !== systemId &&
      formValue.systemTo?.uuid !== systemId
    ) {
      clearStorageData();
      currentStepStorage.clearStorageData();
      formMethods.reset(integrationModel);
      setCurrentStepId();
    }
  }, [formValue.systemFrom?.uuid, formValue.systemTo?.uuid]);

  useEffect(
    () => () => {
      if (isEditService || isEditTiersBound) {
        clearStorageData();
        currentStepStorage.clearStorageData();
        formMethods.reset(integrationModel);
        setCurrentStepId();
      }
    },
    []
  );

  const steps = [
    {
      id: 1,
      name: t('common.informationalSystem'),
      component: (
        <SystemSelection
          isEditAction={isEditService || isEditTiersBound}
          setCurrentStepId={setCurrentStepId}
        />
      ),
      enableNext: formValue?.systemUuid,
    },
    {
      id: 2,
      name: t('pages.systemAddServicePage.isIntegrationService'),
      component: (
        <ServiceSelection
          isEditService={isEditService}
          setCurrentStepId={setCurrentStepId}
          editDocuments={getIntegration.data?.serviceEndpointTo.documents}
        />
      ),
      canJumpTo: currentStepIdReached.stepIdReached >= 2,
      enableNext: formValue?.serviceEndpointTo?.name,
    },
    {
      id: 3,
      name: t('common.informationalSystem'),
      component: (
        <IntegrationTiers
          isCantChangeForm={isCantChangeForm}
          setCurrentStepId={setCurrentStepId}
          isNotValidate={isNotValidate}
        />
      ),
      canJumpTo: currentStepIdReached.stepIdReached >= 3,
    },
  ];
  const isEditSteps = isEditService ? [steps[1]] : [steps[2]];
  const isSteps = isEditService || isEditTiersBound ? isEditSteps : steps;

  const onSubmit = async (formData, e) => {
    if (isLoading) {
      return;
    }

    if (
      e.nativeEvent.submitter.innerText === t(isEditService ? 'common.saveChanges' : 'çommon.add')
    ) {
      if (currentStepId === 3 && !isEditService) {
        const formatedData = {
          ...formData,
          systemUuid: undefined,
          system: { uuid: systemId },
          serviceEndpointTo: {
            async: formData.serviceEndpointTo?.async,
            authType: formData.serviceEndpointTo?.authType,
            description: formData.serviceEndpointTo?.description,
            documents: formData.serviceEndpointTo?.documents,
            name: formData.serviceEndpointTo?.name,
            protocol: formData.serviceEndpointTo?.protocol,
            requestData: formData.serviceEndpointTo?.requestData.length
              ? formData.serviceEndpointTo?.requestData
              : undefined,
            responseData: formData.serviceEndpointTo?.responseData.length
              ? formData.serviceEndpointTo?.responseData
              : undefined,
            uuid: formData.serviceEndpointTo?.uuid || undefined,
          },
          integrationTiers: formData.integrationTiers?.map(
            ({
              dataExchange,
              tierFrequency,
              serviceEndpointTierTo,
              tierFrom,
              ...integrationTiersData
            }) => ({
              ...integrationTiersData,
              ...(tierFrequency.unit
                ? {
                    tierFrequency: {
                      unit: tierFrequency.unit,
                      value: tierFrequency.value,
                    },
                  }
                : {}),
              serviceEndpointTierTo: {
                tier: {
                  uuid: serviceEndpointTierTo.tier.uuid,
                },
                uri: serviceEndpointTierTo.uri,
              },
              tierFrom: { uuid: tierFrom.uuid },
            })
          ),
        };

        if (isEditTiersBound) {
          return editTiersBounds.run(api.integrations.changeIntegration(formatedData));
        }

        run(api.integrations.addNewIntegration(formatedData));
      }

      if (isEditService) {
        const formatedData = {
          ...formData.serviceEndpointTo,
        };

        editService.run(api.serviceEndpoints.updateServiceEndpoints(systemId, formatedData));
      }
    }
  };

  const onNext = ({ id }) => {
    if (!isEditService && !isEditTiersBound) {
      setStepIdReached({
        stepIdReached:
          currentStepIdReached.stepIdReached < id ? id : currentStepIdReached.stepIdReached,
      });

      setCurrentStepId(id);
    }
  };

  const onBack = ({ id }) => {
    setCurrentStepId(id);
  };

  const closeWizard = e => {
    setIsNotValidate(false);

    if (e?.target.innerText === t('common.cancel')) {
      clearStorageData();
      currentStepStorage.clearStorageData();
      formMethods.reset(integrationModel);
      setCurrentStepId();
      return history.replace(redirectPath);
    }
  };

  return (
    <PageLayout fullHeight title={titleMessage} withoutSidePadding withSystemSelector>
      <FormProvider {...formMethods}>
        <form onSubmit={e => formMethods.handleSubmit(onSubmit)(e)} className="h-100">
          <S.WizardStyled
            navAriaLabel={`${title}-steps`}
            mainAriaLabel={`${title}-data`}
            onClose={e => closeWizard(e)}
            steps={isSteps}
            onNext={onNext}
            onBack={onBack}
            startAtStep={currentStepId}
            nextButtonText={
              currentStepId === 3
                ? t(isEditService ? 'common.saveChanges' : 'çommon.add')
                : t('common.forward')
            }
            backButtonText={t('common.back')}
            cancelButtonText={t('common.cancel')}
            closeButtonAriaLabel={t('common.close')}
          />
        </form>
      </FormProvider>
    </PageLayout>
  );
}

export default AddEditPage;
