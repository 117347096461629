import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { serviceJobStatusKeys, serviceStatusKeys, serviceVersionsStatusKeys } from 'constants';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';
import { getProgressServiceMessage, getProgressServiceVersionMessage } from 'constants/tooltips';
import { useTranslation } from 'react-i18next';

function ServiceVersionActions({
  version,
  restartJobs,
  deleteServiceVersion,
  cancelJobs,
  isBlueButtonDropdown,
  deleteServiceModal,
}) {
  const { t, i18n } = useTranslation();
  const progressServiceVersionMessage = getProgressServiceVersionMessage();
  const progressServiceMessage = getProgressServiceMessage();
  const history = useHistory();
  const { notActiveSystemMessage, notActiveOrErrorSystemMessage, optionsFilterByScopes } =
    useUserPermissions(version?.system);

  const notActiveServiceMessage =
    version?.service?.uuid &&
    version.service.status !== serviceStatusKeys.ACTIVE &&
    progressServiceMessage;
  const notActiveOrErrorServiceMessage =
    version?.service?.uuid &&
    version.service.status !== serviceStatusKeys.ACTIVE &&
    version.service.status !== serviceStatusKeys.ERROR &&
    progressServiceMessage;
  const isWithoutPipelineMessage = !version?.pipeline?.status && progressServiceVersionMessage;
  const releaseTotalServiceVersionMessage =
    !!version?.totalSummary?.releaseTotal.total &&
    t('pages.systemServiceVersionsPage.unableToDeleteServiceVersion');

  const notActiveMessage = useMemo(
    () => notActiveSystemMessage || notActiveServiceMessage,
    [notActiveSystemMessage, notActiveServiceMessage, i18n.language]
  );

  const addReleaseDisabledMessage = useMemo(
    () => notActiveMessage || isWithoutPipelineMessage,
    [notActiveMessage, isWithoutPipelineMessage, i18n.language]
  );

  const deleteDisabledMessage = useMemo(
    () =>
      notActiveOrErrorSystemMessage ||
      notActiveOrErrorServiceMessage ||
      releaseTotalServiceVersionMessage,
    [
      notActiveOrErrorSystemMessage,
      notActiveOrErrorServiceMessage,
      releaseTotalServiceVersionMessage,
      i18n.language,
    ]
  );

  const onFilterChange = id => {
    if (id === 'restart') {
      return restartJobs.run(api.pipelines.restartUnsuccessful(version.pipeline?.uuid));
    }

    if (id === 'addOnRelease') {
      return history.push({
        pathname: generatePath(paths.systemFullPaths.releases + paths.systemReleasesPaths.new, {
          systemId: version.system.uuid,
          systemType: version.system.type,
          action: paths.releaseActions.new,
        }),
        state: {
          isServiceVersion: version,
        },
      });
    }

    if (id === 'deleteServiceVersion') {
      if (deleteServiceModal) {
        deleteServiceModal.setModalData(version);
        deleteServiceModal.toggleModal();

        return;
      }

      return deleteServiceVersion.run(api.serviceVersion.deleteServiceVersion(version.uuid));
    }

    return cancelJobs.run(api.pipelines.cancelPipeline(version.pipeline?.uuid));
  };

  const isCanselDisabled =
    notActiveMessage ||
    isWithoutPipelineMessage ||
    !version.pipeline?.jobs.find(
      item =>
        item.status === serviceJobStatusKeys.RUNNING ||
        item.status === serviceJobStatusKeys.SCHEDULED ||
        item.status === serviceJobStatusKeys.CREATED ||
        item.status === serviceJobStatusKeys.PENDING
    );

  const isRestartDisabled =
    notActiveMessage ||
    isWithoutPipelineMessage ||
    !version.pipeline?.jobs.find(
      item =>
        item.status === serviceJobStatusKeys.FAILED ||
        item.status === serviceJobStatusKeys.WARNING ||
        item.status === serviceJobStatusKeys.CANCELLED
    );

  const optionData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'addOnRelease',
          name: t('pages.systemServiceVersionsPage.addToRelease'),
          isDisabled: addReleaseDisabledMessage,
          tooltip: addReleaseDisabledMessage,
          scope: scopes.release.releaseCreate,
        },
        {
          id: 'cancel',
          name: t('common.cancelAction'),
          isDisabled: isCanselDisabled,
          tooltip:
            notActiveMessage ||
            isWithoutPipelineMessage ||
            t('pages.systemServiceVersionsPage.unableToCancelServiceVersion'),
        },
        {
          id: 'restart',
          name: t('pages.systemServiceVersionsPage.reRunFailedBuilds'),
          isDisabled: isRestartDisabled,
          tooltip:
            notActiveMessage ||
            isWithoutPipelineMessage ||
            t('pages.systemServiceVersionsPage.unableReRunServiceVersion'),
        },
        {
          id: 'deleteServiceVersion',
          name: t('common.delete'),
          isDisabled: deleteDisabledMessage,
          tooltip: deleteDisabledMessage,
          scope: scopes.serviceVersion.versionDelete,
          isHidden: version?.status === serviceVersionsStatusKeys.REMOVAL_IN_PROGRESS,
        },
      ]),
    [
      version,
      optionsFilterByScopes,
      notActiveMessage,
      deleteDisabledMessage,
      addReleaseDisabledMessage,
      isRestartDisabled,
      isCanselDisabled,
      isWithoutPipelineMessage,
      version.pipeline?.jobs,
    ]
  );

  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={version.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          buttonLabel={t('pages.systemServiceVersionsPage.changeServiceVersion', [version.name])}
        />
      )}
    </div>
  );
}

export default ServiceVersionActions;
