import React from 'react';
import { Route } from 'react-router';

import useUserPermissions from 'hooks/useUserPermissions';

const AdminRoute = ({ path, scope, component: Component }) => {
  const { checkedPermissions, ErrorRedirectComponent } = useUserPermissions();

  return (
    <Route
      path={path}
      component={checkedPermissions(scope, true) ? Component : ErrorRedirectComponent}
    />
  );
};

export default AdminRoute;
