import React, { useEffect } from 'react';
import moment from 'moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';

import RootRouter from 'pages/RootRouter';
import { alertOptions } from 'hooks/useErrorAlert/alertOptions';
import useKeycloakAuth from 'hooks/useKeycloakAuth';
import MenuLayout from 'components/MenuLayout';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import './styles/app.css';
import { useTranslation } from 'react-i18next';

function App() {
  const { isAppInitialize } = useKeycloakAuth();
  const { i18n } = useTranslation();

  useEffect(() => moment.locale(i18n.language), []);

  if (!isAppInitialize) {
    return null;
  }

  return (
    <Router>
      <AlertProvider {...alertOptions}>
        <MenuLayout>
          <RootRouter />
        </MenuLayout>
      </AlertProvider>
    </Router>
  );
}

export default App;
