import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import WarningModal from 'components/Common/WarningModal';

function ArchiveServiceForm({
  requestFunction,
  isModalVisible,
  toggleModal,
  modalData,
  setModalData,
}) {
  const { t } = useTranslation();
  const archiveService = useAsync();

  useEffect(() => {
    if (archiveService.isSuccess) {
      if (requestFunction) {
        requestFunction();
      }
      setModalData('');
      toggleModal();
    }
  }, [archiveService.isSuccess]);

  return (
    <WarningModal
      title={t('pages.systemServicesPage.archivingService')}
      text={t('pages.systemServicesPage.archiveService')}
      isLoading={archiveService.isLoading}
      onClick={() => archiveService.run(api.services.archiveService(modalData.uuid))}
      name={modalData?.name}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
      buttenText={t('pages.systemServicesPage.archive')}
      warningColor="#f0ab00"
    />
  );
}

export default ArchiveServiceForm;
