import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import ReleasesPage from './ReleasesPage';
import AddReleasePage from './AddReleasePage';
import CurrentReleasesPage from './CurrentReleasesPage';

const SystemReleasesRouter = ({ match: { path } }) => (
  <Switch>
    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemReleasesPaths.new}`}
      component={AddReleasePage}
      scope={scopes.release.releaseCreate}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemReleasesPaths.edit}`}
      component={AddReleasePage}
      scope={scopes.release.releaseUpdate}
    />

    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemReleasesPaths.change}`}
      component={CurrentReleasesPage}
      scope={scopes.release.releaseViewForm}
    />

    <Route exact path={path} component={ReleasesPage} />

    <Redirect to={path} />
  </Switch>
);

export default SystemReleasesRouter;
