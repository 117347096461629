import styled from 'styled-components';

import ArrowRightSVG from 'assets/images/notifications/arrowright.svg';
import IcoEmptyPNG from 'assets/images/notifications/ico-empty-notification.png';

export const Wrapper = styled.div`
  display: flex;
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  flex: 1;
  overflow-y: auto;
  position: relative;
  min-height: 50px;

  ${({ $mainblockHeight }) => $mainblockHeight && `max-height: ${$mainblockHeight - 56}px;`}
`;

export const NotificationDateBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const NotificationDate = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const NotificationEmptyIco = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  background: url(${IcoEmptyPNG}) no-repeat;
  background-size: cover;
`;

export const NotificationEmptyText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 16px;
  color: #000000;
`;

export const NotificationExpanded = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2px 0 2px 0;
`;

export const NotificationRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const NotificationBlock = styled.div`
  display: flex;
  padding: 8px 0;
  width: 100%;
  min-height: 37px;
  align-items: center;
  cursor: pointer;
`;

export const NotificationTime = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #8b8d8f;
  width: 40px;
  margin-right: 16px;
`;

export const NotificationText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 24px;
`;

export const NotificationArrow = styled.div`
  display: flex;
  width: 7px;
  height: 10px;
  background: url(${ArrowRightSVG}) no-repeat;
  background-size: cover;

  ${props =>
    props.down &&
    `
    transform: rotate(90deg);
  `};
`;

export const SystemsLoaderWrapper = styled.div.attrs({
  className: 'd-flex position-absolute justify-content-center align-items-center',
})`
  left: 0;
  right: 0;
  bottom: 0;
`;
