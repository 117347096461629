import { releaseActions } from './systemReleasesPaths';
import { usersActions } from './adminUsersPaths';

export const systemPaths = {
  editSystem: 'edit',
  notifications: 'notifications',
  services: 'services',
  serviceDetails: 'services/:serviceId',
  releases: 'releases',
  releaseEdit: `releases/:releaseId/:action(${releaseActions.edit})`,
  releaseId: `releases/:releaseId/:action(${releaseActions.approve}|${releaseActions.deploy}|${releaseActions.deployToDev}|${releaseActions.view})`,
  tiers: 'tiers',
  tier: 'tiers/:tierId',
  tierId: ':tierId',
  integrations: 'integrations',
  deployments: 'deployments',
  serviceVersions: 'service-versions',
  serviceVersion: 'service-versions/:serviceVersionId',
  milestones: 'milestones',
  milestone: ':milestoneId',
  issues: 'issues',
  issue: ':issuesId',
  issuesId: 'issues/:issuesId',
  documents: 'documents',
  users: 'users',
  userDetails: 'users/:userId',
  relativeUserDetails: ':userId',
  assignUsers: 'users/:userId/assign',
  newUser: `users/:action(${usersActions.new})`,
};
