import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import * as S from './styles';

export const placementKeys = {
  left: 'left',
  right: 'right',
  bottom: 'bottom',
  top: 'top',
};

function TooltipComponent({ message, children, placement }) {
  if (!message) {
    return children;
  }

  return (
    <Tooltip
      trigger="hover"
      overlay={<S.TooltipText>{message}</S.TooltipText>}
      placement={placement ?? placementKeys.right}
      destroyTooltipOnHide
      exitDelay={150}
      animationDuration={150}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipComponent;
