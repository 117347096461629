import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const releasesSlice = createSlice({
  name: 'releases',
  initialState: { data: [], total: 0, isLoading: true, isError: null },
  reducers: {
    clearReleasesState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
    },
  },
  extraReducers: {
    [actions.fetchReleases.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchReleases.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.total = meta.total;
      state.isLoading = false;
      state.isError = null;
    },
    [actions.fetchReleases.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
  },
});

export const { clearReleasesState } = releasesSlice.actions;

export default releasesSlice.reducer;
