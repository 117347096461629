import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { Tab, TabTitleText } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import paths from 'constants/paths';
import { featureFlags } from 'constants/features';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getServiceAvailableStatuses } from 'components/Common/StatusComponent';
import SystemDeploymentsTable from 'pages/SystemRouter/SystemDeploymentsPage/SystemDeploymentsTable';
import ServiceVersionsTable from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';
import { socketActionKeys } from 'constants';

import ReleasesTable from 'pages/SystemRouter/SystemReleasesRouter/ReleasesPage/ReleasesTable';
import ServiceInfo from './ServiceInfo';
import DeleteServiceForm from '../ServicesPage/DeleteServiceForm';
import ArchiveServiceForm from '../ServicesPage/ArchiveServiceForm';
import ServiceActions from '../ServicesPage/ServiceActions';

import * as S from './styles';

function CurrentServicePage() {
  const { t } = useTranslation();
  const serviceAvailableStatuses = getServiceAvailableStatuses();
  const history = useHistory();
  const {
    params: { systemId, systemType, serviceId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs();

  const { run, data, setData, isLoading, isError } = useAsync();
  const archiveService = useAsync();
  const [isShowReleasesFilters, setIsShowReleasesFilters] = useState(false);
  const [isShowServiceVertionsFilters, setIsShowServiceVertionsFilters] = useState(false);

  const deleteServiceModal = useModal();
  const archiveServiceModal = useModal();

  const requestFunction = () => run(api.services.getService(serviceId));

  useEffect(() => {
    requestFunction();
  }, []);

  const redirectFunction = () =>
    history.push(
      generatePath(paths.systemFullPaths.services, {
        systemId,
        systemType,
      })
    );

  useSocketAsync({
    topic: 'services',
    debounceTime: 600,
    filterFn: ({ object }) => serviceId === object?.uuid,
    onMessage: async ({ action }) => {
      if (action === socketActionKeys.DELETED) {
        return redirectFunction();
      }
      try {
        if (serviceId) {
          const newData = await api.services.getService(serviceId);
          setData(newData.data);
          return;
        }
      } catch (e) {
        if (e) {
          return redirectFunction();
        }
      }
    },
  });

  useEffect(() => {
    if (archiveService.isSuccess) {
      requestFunction();
      archiveServiceModal.setModalData('');
    }
  }, [archiveService.isSuccess]);

  const leftSideContent = (
    <div className="mx-4">
      <ServiceActions
        service={data}
        deleteServiceModal={deleteServiceModal}
        archiveServiceModal={archiveServiceModal}
        archiveService={archiveService}
        isBlueButtonDropdown
      />
    </div>
  );

  const title = (
    <div className="d-flex hyphens-break-word">
      {data?.name}
      <div className="d-flex ml-2 title-line-height">
        <StatusComponent
          statusId={data?.status}
          statuses={serviceAvailableStatuses}
          objectUuid={data?.uuid}
          objectType="SERVICE"
        />
      </div>
    </div>
  );

  return (
    <>
      <PageLayout
        title={title}
        leftSideContent={leftSideContent}
        withoutSidePadding
        withSystemSelector
        isError={isError}
        isWaitLoading={{ isLoading }}
      >
        <S.TabsWrapper>
          <S.TabsStyled
            activeKey={currentTab}
            onSelect={onSelectTab}
            aria-label={t('common.authorizationService')}
          >
            <Tab eventKey={0} title={<TabTitleText>{t('common.description')}</TabTitleText>}>
              <ServiceInfo serviceData={data} />
            </Tab>
            {featureFlags.isServiceVersionsFeatureEnabled && (
              <Tab
                eventKey={1}
                title={
                  <TabTitleText className="d-flex">
                    {t('common.serviceVersions')}
                    <TotalSummaryBadge
                      total={data?.totalSummary.serviceVersionTotal?.total}
                      isSelect={currentTab === 1}
                    />
                  </TabTitleText>
                }
              >
                <div className="mt-4">
                  <ServiceVersionsTable
                    isShowFilters={isShowServiceVertionsFilters}
                    setIsShowFilters={setIsShowServiceVertionsFilters}
                    currentTotal={data?.uuid && data?.totalSummary.serviceVersionTotal?.total}
                  />
                </div>
              </Tab>
            )}
            <Tab
              eventKey={2}
              title={
                <TabTitleText className="d-flex">
                  {t('common.releases')}
                  <TotalSummaryBadge
                    total={data?.totalSummary.releaseTotal?.total}
                    isSelect={currentTab === 2}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <ReleasesTable
                  service={data}
                  isShowFilters={isShowReleasesFilters}
                  setIsShowFilters={setIsShowReleasesFilters}
                  currentTotal={data?.uuid && data?.totalSummary?.releaseTotal?.total}
                />
              </div>
            </Tab>
            <Tab
              eventKey={3}
              title={
                <TabTitleText className="d-flex">
                  {t('common.deployments')}
                  <TotalSummaryBadge
                    total={data?.totalSummary.tierReleaseTotal?.total}
                    isSelect={currentTab === 3}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <SystemDeploymentsTable
                  serviceId={serviceId}
                  currentTotal={data?.totalSummary.tierReleaseTotal?.total}
                />
              </div>
            </Tab>
          </S.TabsStyled>
        </S.TabsWrapper>
      </PageLayout>

      <DeleteServiceForm
        requestFunction={() =>
          history.push(
            generatePath(paths.routePaths.system + paths.systemPaths.services, {
              systemId,
              systemType,
            })
          )
        }
        {...deleteServiceModal}
      />

      <ArchiveServiceForm requestFunction={requestFunction} {...archiveServiceModal} />
    </>
  );
}

export default CurrentServicePage;
