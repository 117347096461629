import styled from 'styled-components';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

export const EditPencilIcon = styled(PencilAltIcon).attrs({
  className: 'd-sm-flex cursor-pointer border-none font-16 text-gray-light EditPencilIcon',
})`
  &:hover {
    color: #000 !important;
  }

  margin: ${({ withoutMargin }) => (withoutMargin ? 'margin: 0' : 'margin: 0 8px')};
`;
