import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import ArrowRightSVG from 'assets/images/notifications/arrowright.svg';

export const DatePicker = styled(DateRange)`
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonthName {
    text-align: center;
    color: #000000;
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .rdrWeekDay {
    color: #4f5255;
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .rdrMonth {
    margin-top: -40px;
    margin-bottom: -10px;
    width: 360px;
  }

  .rdrInRange {
    background: rgba(141, 194, 245, 0.2);
  }

  .rdrDayNumber span {
    color: #000000 !important;
  }

  .rdrStartEdge ~ span span,
  .rdrEndEdge ~ span span {
    font-weight: 400;
    color: #ffffff !important;
    &:after {
      display: none;
    }
  }

  .rdrDayPassive span span {
    font-weight: 400;
    color: #bbbbbb !important;
  }

  .rdrMonthAndYearWrapper {
    z-index: 10;
    cursor: auto;
  }

  .rdrNextPrevButton {
    background: #ffffff;
  }

  .rdrNextPrevButton i {
    display: none;
  }

  .rdrPprevButton {
    width: 15px;
    height: 15px;
    background: url(${ArrowRightSVG}) no-repeat;
    transform: rotate(180deg);
    background-size: contain;
  }

  .rdrNextButton {
    width: 15px;
    height: 15px;
    background: url(${ArrowRightSVG}) no-repeat;
    background-size: contain;
  }

  width: 496px;
  * {
    border-radius: 0 !important;
  }
`;

export const DatePickerWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: 496px;
`;

export const OutsideHeaderWrapper = styled.div`
  position: absolute;
  margin-left: 563px;
  border-radius: 2px;
  width: 496px;
  left: 327px;

  ${({ filterStyles }) =>
    filterStyles &&
    `
    margin: 0;
    top: 0;
    left: -134px;
  `};
`;

export const OutsideHeader = styled.div`
  height: 40px;
`;

export const Buttons = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid #d2d2d2;
  margin-top: 16px;
  padding: 16px;
  align-items: center;
`;

export const ApplyButton = styled.div`
  display: flex;
  width: 113px;
  height: 36px;
  background: #0066cc;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 23px;
`;

export const CancelButton = styled.div`
  display: flex;
  color: #0066cc;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;
