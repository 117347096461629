import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NotificationText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 24px;
`;

export const NotificationLink = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  width: max-content;
`;
