import React, { useMemo } from 'react';
import { transitions, positions } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { CloseButton } from 'components/UI/CloseButton';
import SectionHeader from 'components/Common/SectionHeader';
import * as S from './styles';

const AlertErrorTemplate = ({ style, options, message, close }) => {
  const { t, i18n } = useTranslation();
  const isError = options?.type === 'error';
  const isSuccess = options?.type === 'success';

  const titleMessage = useMemo(() => {
    if (isSuccess) {
      return options?.title;
    }

    if (!options?.status) {
      return options?.title || t('api.errorOccurred');
    }

    return `${options?.status < 500 ? t('api.badRequestError') : t('api.serverError')} (${
      options?.status
    })`;
  }, [options?.status, isSuccess, message, i18n.language]);

  const title = (
    <>
      <S.Icon isError={isError} />
      <S.ErrorTitle isError={isError}>{titleMessage}</S.ErrorTitle>
    </>
  );

  return (
    <div style={style}>
      <S.AlertWrapper isError={isError}>
        <SectionHeader title={title}>
          <CloseButton onClick={close} />
        </SectionHeader>
        {!!message && <S.Message>{message}</S.Message>}
      </S.AlertWrapper>
    </div>
  );
};

export const alertOptions = {
  template: AlertErrorTemplate,
  position: positions.BOTTOM_RIGHT,
  timeout: 6000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1000,
  },
};
