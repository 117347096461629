import React from 'react';
import i18n from '../../../i18n';

const { t } = i18n;

export const getVersionDescription = (version, withoutDescription) => (
  <>
    {!withoutDescription && version.description && (
      <div>{version.description?.description || version.description}</div>
    )}
    {!version.addedViaPortal && (
      <div className="mt-1">{t('pages.systemServiceVersionsPage.addedViaGitTag')}</div>
    )}
    {version.usedDefaultBranch === false && (
      <div className="mt-1">{t('pages.systemServiceVersionsPage.notDefaultBranchUsed')}</div>
    )}
  </>
);
