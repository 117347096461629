import React from 'react';
import { Button } from '@patternfly/react-core';
import PlusCircleIcon from '@patternfly/react-icons/dist/esm/icons/plus-circle-icon';

const AddButton = ({ onClick, children, disabled }) => (
  <Button
    disabled={disabled}
    className="p-0"
    variant="link"
    icon={<PlusCircleIcon />}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default AddButton;
