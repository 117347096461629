import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { tierQuoteRequestSelector } from 'store/tierQuotes/selectors';
import { convertValueToMillicores, convertValueToBytes } from 'utils';

import LimitsBlock, { getAvailableResources } from './LimitsBlock';
import {
  cpuUnitKeys,
  limitsKeys,
  getLimitsLabels,
  unitMemoryDropdownOptions,
  unitMemoryKeys,
} from '../../constants';

import { StyledAlert } from './styles';

function useAvailableResources(
  { watch, setValue },
  { uuid, name = '' },
  inputKey = '',
  storageValue
) {
  const limitsLabels = getLimitsLabels();
  const { t } = useTranslation();
  const { checkedPermissions } = useUserPermissions();
  const quoteRequest = useSelector(tierQuoteRequestSelector);
  const { run, data, isLoading } = useAsync();
  const availableResourcesData = watch?.(`${inputKey}availableResourcesData`);
  const labels = {
    CPU: t('components.sources.valueOf.CPU'),
    MEMORY: t('components.sources.valueOf.MEMORY'),
    STORAGE: t('components.sources.valueOf.STORAGE'),
  };
  const CPU = watch
    ? convertValueToMillicores(watch(`${inputKey}CPU`), watch(`${inputKey}cpuUnit`))
    : convertValueToMillicores(
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.CPU).value,
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.CPU).unitType
      );
  const MEMORY = watch
    ? convertValueToBytes(
        watch(`${inputKey}MEMORY`),
        unitMemoryDropdownOptions.find(option => option.id === watch(`${inputKey}memoryUnit`))?.unit
      )
    : convertValueToBytes(
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.MEMORY).value,
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.MEMORY).unitType
      );
  const STORAGE = watch
    ? convertValueToBytes(
        watch(`${inputKey}STORAGE`),
        unitMemoryDropdownOptions.find(option => option.id === watch(`${inputKey}storageUnit`))
          ?.unit
      )
    : convertValueToBytes(
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.STORAGE).value,
        quoteRequest?.limits.find(({ type }) => type === limitsKeys.STORAGE).unitType
      );
  const resources = { CPU, MEMORY, STORAGE };

  const availableResources = (
    data?.length ? data : availableResourcesData?.length ? availableResourcesData : []
  )?.reduce(
    (acc, { code, total, limits, used }) => ({
      ...acc,
      [code]: {
        limits,
        used,
        total,
      },
    }),
    {}
  );

  useEffect(() => {
    if (uuid) {
      run(api.monitoring.getServerTierMonitoring({ uuid }));
    }
  }, [uuid]);

  useEffect(() => {
    if (data?.length) {
      setValue?.(`${inputKey}availableResourcesData`, data);
    }
  }, [data]);

  useEffect(() => {
    if (availableResources && setValue && !isLoading) {
      const availableCpu = parseFloat(
        getAvailableResources(availableResources?.CPU || {}).toFixed(3)
      );
      const availableMemory = availableResources?.MEMORY
        ? getAvailableResources(availableResources?.MEMORY || {}).toFixed(3)
        : 0;
      setValue(`${inputKey}availableCpu`, availableCpu);
      setValue(`${inputKey}availableMemory`, availableMemory);
      const availableStorage =
        availableResources?.STORAGE &&
        getAvailableResources(availableResources?.STORAGE || {}).toFixed(3);
      setValue(`${inputKey}availableStorage`, availableStorage);
    }
  }, [availableResources, uuid, inputKey]);

  const tooltipWarningText = useMemo(() => {
    if (!uuid || !data?.length) {
      return null;
    }

    const values = Object.keys(resources)
      .filter(key => {
        const value = convertValueToMillicores(
          getAvailableResources(availableResources?.[key] || {}),
          key === limitsKeys.CPU ? cpuUnitKeys.cores : unitMemoryKeys.gib
        );

        return resources?.[key] > (value > 0 ? value : 0);
      })
      .map(key => labels[key]);

    if (!values.length || (values[0] === limitsLabels.STORAGE && storageValue === 0)) {
      return null;
    }

    return `${
      values.length > 1 ? t('components.sources.values') : t('components.sources.value')
    } ${values.join(', ')} ${
      values.length > 1 ? t('components.sources.exceed') : t('components.sources.exceeds')
    }.`;
  }, [availableResources, resources, storageValue]);

  const warningText = useMemo(() => {
    if (!tooltipWarningText) {
      return null;
    }

    return <StyledAlert className="mt-4" variant="warning" isInline title={tooltipWarningText} />;
  }, [tooltipWarningText]);

  const tooltipServerTiersWarningText = useMemo(() => {
    if (!isLoading && checkedPermissions(scopes.tier.tierChangeQuota) && !data?.length) {
      return t('components.sources.serverTierProblem', [name]);
    }
  }, [isLoading]);

  const serverTiersWarningText = useMemo(() => {
    if (tooltipServerTiersWarningText) {
      return (
        <StyledAlert
          className="my-4"
          variant="warning"
          isInline
          title={tooltipServerTiersWarningText}
        />
      );
    }
  }, [tooltipServerTiersWarningText]);

  return {
    inputsBlock: uuid && <LimitsBlock availableResources={availableResources} />,
    warningText,
    tooltipWarningText,
    serverTiersWarningText,
    tooltipServerTiersWarningText,
    loading: isLoading || quoteRequest?.isLoading,
    availableResources,
  };
}

export default useAvailableResources;
