import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationVariant } from '@patternfly/react-core';

import useTableExpandedItems from 'components/Common/DataTable/hooks/useTableExpandedItems';

import * as S from './styles';

const defaultPageNumber = '1';

const defaultPerPageOptions = [
  {
    title: '5',
    value: 5,
  },
  {
    title: '10',
    value: 10,
  },
  {
    title: '20',
    value: 20,
  },
  {
    title: '50',
    value: 50,
  },
  {
    title: '100',
    value: 100,
  },
];

function Pagination({ total, paginationParams, changePaginationParams, expandedKey, tableName }) {
  const { t } = useTranslation();
  const { clearExpandedUuidByKey } = useTableExpandedItems();
  const titles = {
    itemsPerPage: t('components.table.titles.itemsPerPage'),
    perPageSuffix: t('components.table.titles.perPageSuffix'),
    toFirstPage: t('components.table.titles.toFirstPage'),
    toPreviousPage: t('components.table.titles.toPreviousPage'),
    toLastPage: t('components.table.titles.toLastPage'),
    toNextPage: t('components.table.titles.toNextPage'),
    optionsToggle: '',
    currPage: t('components.table.titles.currPage'),
    paginationTitle: t('components.table.titles.paginationTitle'),
    items: '',
    page: '',
    pages: '',
    ofWord: t('components.table.titles.ofWord'),
  };
  const [pageInputValue, setPageInputValue] = useState(defaultPageNumber);

  useEffect(() => {
    if (total <= paginationParams?.offset) {
      changePaginationParams({
        offset: paginationParams?.offset - paginationParams?.limit || 0,
        limit: paginationParams?.limit,
      });
      setPageInputValue(pageInputValue - 1);
    } else {
      setPageInputValue(Math.ceil(paginationParams?.offset / paginationParams?.limit) + 1);
    }
  }, [paginationParams, total]);

  const onSetPage = (_event, pageNumber) => {
    _event.preventDefault();
    clearExpandedUuidByKey(expandedKey);

    setPageInputValue(pageNumber);
    changePaginationParams({
      offset: (pageNumber - 1) * paginationParams?.limit,
      limit: paginationParams?.limit,
    });
  };

  const onPerPageSelect = (_event, perPage) => {
    _event.preventDefault();
    clearExpandedUuidByKey(expandedKey);

    const maxPage = Math.ceil(total / perPage);
    if (pageInputValue < maxPage) {
      return changePaginationParams({
        limit: perPage,
        offset: (pageInputValue - 1) * perPage,
      });
    }

    setPageInputValue(maxPage);
    changePaginationParams({ offset: (maxPage - 1) * perPage, limit: perPage });
  };

  return (
    <S.StyledPagination
      perPageComponent="button"
      itemCount={total}
      widgetId={expandedKey + tableName}
      perPage={paginationParams?.limit}
      page={pageInputValue}
      variant={PaginationVariant.bottom}
      onSetPage={onSetPage}
      onPerPageSelect={onPerPageSelect}
      perPageOptions={defaultPerPageOptions}
      titles={titles}
    />
  );
}

export default Pagination;
