import http from 'api/config';
// uncommit to test https://rmine.net/issues/156774
// import { getDictionariesMock } from './mock';

const getDictionaries = params => http.get(`dictionary`, { params });

const getDictionary = params => http.get(`dictionary`, { params });

const deleteDictionary = id => http.delete(`dictionary/${id}`);

const addDictionary = data => http.post(`dictionary`, data);

const editDictionary = data => http.put(`dictionary`, data);

export default {
  // getDictionariesMock,
  getDictionaries,
  getDictionary,
  deleteDictionary,
  addDictionary,
  editDictionary,
};
