import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import CodeIcon from '@patternfly/react-icons/dist/esm/icons/code-icon';
import InProgressIcon from '@patternfly/react-icons/dist/esm/icons/in-progress-icon';
import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon';
import ProcessAutomationIcon from '@patternfly/react-icons/dist/esm/icons/process-automation-icon';
import ClipboardCheckIcon from '@patternfly/react-icons/dist/esm/icons/clipboard-check-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import AttentionBellIcon from '@patternfly/react-icons/dist/esm/icons/attention-bell-icon';

import { getTotalSummaryNames } from 'constants';
import { useTranslation } from 'react-i18next';

function TotalSummary({
  total = 0,
  inProgress = 0,
  error = 0,
  requireApproval = 0,
  isServiceVersionTotal,
  isReleaseTotal,
  tierReleaseTotal,
  releaseIssueTotal,
  errorMessage,
  inProgressMessage,
}) {
  const { t } = useTranslation();
  const totalSummaryNames = getTotalSummaryNames();
  return (
    <div className="d-flex flex-row">
      <>
        {isServiceVersionTotal && (
          <div className="text-nowrap">
            <Tooltip content={t('common.serviceVersions')}>
              <ProcessAutomationIcon className="text-black" />
            </Tooltip>
            <span className="text-nowrap ml-2">{total}</span>
          </div>
        )}

        {isReleaseTotal && (
          <div className="text-nowrap">
            <Tooltip content={t('common.releases')}>
              <CodeIcon className="text-black" />
            </Tooltip>
            <span className="ml-2 text-nowrap">{total}</span>
          </div>
        )}

        {tierReleaseTotal && (
          <div className="text-nowrap">
            <Tooltip content={t('common.deployments')}>
              <CubeIcon className="text-black" />
            </Tooltip>
            <span className="ml-2 text-nowrap">{total}</span>
          </div>
        )}

        {releaseIssueTotal && (
          <div className="text-nowrap">
            <Tooltip content={t('common.issues')}>
              <ClipboardCheckIcon className="text-black" />
            </Tooltip>
            <span className="ml-2 text-nowrap">{total}</span>
          </div>
        )}
      </>

      {error > 0 && (
        <div className="text-nowrap">
          <Tooltip content={errorMessage || totalSummaryNames.ERROR}>
            <ExclamationCircleIcon className="font-14 mx-2 text-red" />
          </Tooltip>
          <span className="text-nowrap">{error}</span>
        </div>
      )}

      {inProgress > 0 && (
        <div className="text-nowrap">
          <Tooltip content={inProgressMessage || totalSummaryNames.NEW}>
            <InProgressIcon className="font-14 text-gren mx-2" />
          </Tooltip>
          <span className="text-nowrap">{inProgress}</span>
        </div>
      )}

      {requireApproval > 0 && (
        <div className="text-nowrap">
          <Tooltip content={totalSummaryNames.READY}>
            <AttentionBellIcon className="font-14 text-red mx-2" />
          </Tooltip>
          <span className="text-nowrap">{requireApproval}</span>
        </div>
      )}
    </div>
  );
}

export default TotalSummary;
