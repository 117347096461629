import React, { useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import { fetchImportService, fetchSelectedRepositories } from 'store/services/actions';
import { selectedRepositoriesSelector } from 'store/services/selectors';
import { clearRepositoriesState, clearSelectedRepositoriesState } from 'store/services/reducers';
import NewDataTable, { modifierKeys } from 'components/Common/NewDataTable';
import StatusComponent from 'components/Common/StatusComponent';
import { getRepositoryAvailableStatuses } from 'components/Common/StatusComponent/statuses';
import MultipleEditModal from 'pages/SystemRouter/SystemServicesRouter/ServicesPage/ServicesTable/MultipleEditModal';
import ServicesType from 'pages/SystemRouter/SystemServicesRouter/ServicesPage/ServicesTable/ServicesType';
import {
  conflictCode,
  conflictName,
  getMessageForRepositoryCode,
  getMessageForRepositoryName,
} from 'constants/vaidations';

import EditRepositoryModal from './EditRepositoryModal';
import { tableFiltersToQueryParams } from '../formatters';
import RepositoryActions from './RepositoryActions';
import DeleteRepositoryForm from './DeleteRepositoryForm';

function RepositoriesTable({
  filterParams,
  checkedRepositoriesUuids,
  setCheckedRepositoriesUuids,
  setAllRenderUuids,
  isMassChecked,
  setExcludedIds,
  excludedIds,
  multipleEditRepositoryModal,
  multipleDeleteRepositoryModal,
  hasSelectedFilters,
  tableName,
  checkedRepositoriesFormatedData,
}) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { watch, setValue } = useFormContext();
  const repositoryAvailableStatuses = getRepositoryAvailableStatuses();

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'code',
      label: t('common.repositoryCode'),
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'languageName',
      label: t('common.programmingLanguage'),
      withSort: true,
    },
    {
      key: 'buildTemplate',
      label: t('pages.systemServicePage.buildTemplate'),
      withSort: true,
    },
    {
      key: 'type',
      label: t('common.serviceType'),
      withSort: true,
    },
    {
      key: 'editAction',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];
  const editRepositoryModal = useModal();
  const deleteRepositoryModal = useModal();

  const { data, total, isLoading, isError } = useSelector(selectedRepositoriesSelector);

  const groupUuidValue = watch('groupUuid');
  const checkedSelectedRepositoriesValue = watch('checkedSelectedRepositories');
  const connectionValue = watch('connection');

  const requestFunction = requestParameters => {
    if (groupUuidValue?.length && requestParameters.groupUuid) {
      dispatch(fetchImportService(groupUuidValue));
      dispatch(fetchSelectedRepositories(tableFiltersToQueryParams(requestParameters)));
    }
  };

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      ascending: true,
      orderBy: 'name',
      system: systemId,
      groupUuid: groupUuidValue,
      selected: true,
    }),
    [filterParams, groupUuidValue]
  );

  useEffect(() => {
    if (!connectionValue) {
      dispatch(clearRepositoriesState());
      dispatch(clearSelectedRepositoriesState());
      setValue('checkedSelectedRepositories', []);
    }
  }, []);

  useEffect(() => () => setValue('checkedSelectedRepositories', []), []);

  const formattedBodyData = data?.map(repository => ({
    id: repository.uuid,
    data: [
      {
        key: 'name',
        content: repository.name && (
          <div>
            <span className="mr-2">{repository.name}</span>
            {(repository.service?.name === repository.name || repository.name === conflictName) && (
              <span>
                <Tooltip
                  content={getMessageForRepositoryName()}
                  exitDelay={150}
                  animationDuration={150}
                >
                  <ExclamationCircleIcon className="text-red" />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
      {
        key: 'code',
        content: repository.code && (
          <div>
            <span className="mr-2">{repository.code}</span>
            {(repository.service?.code.toLowerCase() === repository.code.toLowerCase() ||
              repository.code.toLowerCase() === conflictCode) && (
              <span>
                <Tooltip
                  content={getMessageForRepositoryCode()}
                  exitDelay={150}
                  animationDuration={150}
                >
                  <ExclamationCircleIcon className="text-red" />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
      {
        key: 'status',
        content: (
          <StatusComponent statusId={repository?.status} statuses={repositoryAvailableStatuses} />
        ),
      },
      {
        key: 'languageName',
        content:
          repository?.languageName &&
          `${repository?.languageName} (${repository?.languagePercentage}%)`,
      },
      {
        key: 'buildTemplate',
        content: repository.buildTemplate?.name,
      },
      {
        key: 'type',
        content: <ServicesType type={repository.type} integration={repository.integration} />,
      },
      {
        key: 'editAction',
        content: (
          <RepositoryActions
            repository={repository}
            deleteRepositoryModal={deleteRepositoryModal}
            editRepositoryModal={editRepositoryModal}
          />
        ),
      },
    ],
  }));

  const callback = () => tableRef.current?.requestDataWithParams();
  const changeRequest = ({ uuid, repositories }) => {
    if (uuid) {
      setCheckedRepositoriesUuids(prevState => prevState.filter(item => item !== uuid));
      setAllRenderUuids(prevState => prevState.filter(item => item !== uuid));
    } else if (repositories?.length) {
      repositories.forEach(repository => {
        setCheckedRepositoriesUuids(prevState =>
          prevState.filter(item => item !== repository?.uuid)
        );
        setAllRenderUuids(prevState => prevState.filter(item => item !== repository?.uuid));
      });
    }
    tableRef.current?.requestDataWithParams();
  };

  return (
    <>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        withStandartPagination
        total={total}
        withCheckboxes
        tableName={tableName}
        isLoading={isLoading}
        setAllRenderUuids={setAllRenderUuids}
        checkedUuids={checkedRepositoriesUuids}
        setCheckedUuids={setCheckedRepositoriesUuids}
        isMassChecked={isMassChecked}
        setExcludedIds={setExcludedIds}
        excludedIds={excludedIds}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />

      <EditRepositoryModal
        {...editRepositoryModal}
        groupUuidValue={groupUuidValue}
        callback={callback}
      />
      <MultipleEditModal
        {...multipleEditRepositoryModal}
        groupUuidValue={groupUuidValue}
        callback={callback}
        isMassChecked={isMassChecked}
        checkedUuids={checkedSelectedRepositoriesValue}
        excludedIds={excludedIds}
        checkedRepositoriesFormatedData={checkedRepositoriesFormatedData}
      />
      <DeleteRepositoryForm
        requestFunction={changeRequest}
        groupUuidValue={groupUuidValue}
        {...deleteRepositoryModal}
      />
      <DeleteRepositoryForm
        requestFunction={changeRequest}
        groupUuidValue={groupUuidValue}
        {...multipleDeleteRepositoryModal}
      />
    </>
  );
}

export default RepositoriesTable;
