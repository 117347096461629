import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { formatFilterOptions } from 'utils';

import api from 'api';
import useSocketAsync from 'hooks/useSocketAsync';
import useAsync from 'hooks/useAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchDeployedReleases } from 'store/deployedReleases/actions';
import { deployedReleasesSelector } from 'store/deployedReleases/selectors';
import { clearDeployedReleasesState } from 'store/deployedReleases/reducers';
import paths from 'constants/paths';
import { releaseOptions, systemTypesKeys, getReleasesDeployStatusNames } from 'constants';
import { scopes } from 'constants/scopes';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import StatusComponent, {
  getReleasesAvailableStatuses,
  getSystemStatuses,
  getTierAvailableStatuses,
} from 'components/Common/StatusComponent';
import ServiceVersionCommit from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionCommit';
import DevTiersLabel from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/TiersTable/DevTiersLabel';
import { getReleasesDeployStatus } from 'components/Common/StatusComponent/statuses';
import { useTranslation } from 'react-i18next';

function SystemDeploymentsTable({ releaseId, serviceId, tierId, currentTotal }) {
  const {
    params: { systemId },
  } = useRouteMatch();
  const { t, i18n } = useTranslation();
  const releasesDeployStatus = getReleasesDeployStatus();
  const releasesAvailableStatuses = getReleasesAvailableStatuses();
  const releasesDeployStatusNames = getReleasesDeployStatusNames();
  const systemStatuses = getSystemStatuses();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const history = useHistory();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const deployments = useAsync();
  const { data, total, isLoading, isError } = useSelector(deployedReleasesSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const { state } = history.location;
  const deploymentId = state?.deploymentId;
  const isDeployedFoEentity = releaseId || serviceId;
  const currentData = isDeployedFoEentity ? deployments.data?.data : data;

  const filterData = useMemo(
    () => [
      {
        id: 'releaseName',
        name: t('common.release'),
        isHidden: releaseId,
      },
      {
        id: 'tierCode',
        name: t('common.tier'),
        isHidden: tierId,
      },
      {
        id: 'status',
        name: t('common.status'),
        type: 'multiSelect',
        options: formatFilterOptions(releasesDeployStatusNames),
      },
      {
        id: 'uuid',
        name: t('pages.systemDeploymentsPage.id'),
        initialValue: deploymentId,
        isHidden: !deploymentId,
      },
      {
        id: 'date',
        name: t('pages.systemDeploymentsPage.deployDate'),
        type: 'date',
      },
    ],
    [deploymentId, i18n.language]
  );
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'deployments'
  );

  const requestParams = useMemo(
    () => ({
      release: releaseId,
      tier: tierId,
      ...filterParams,
      system: systemId,
      service: serviceId,
      ascending: false,
      orderBy: 'lastUpdatedAt',
      ...filterParams?.date,
      date: undefined,
    }),
    [filterParams, releaseId, tierId, serviceId, systemId]
  );

  const requestFunction = requestParameters => {
    if (isDeployedFoEentity) {
      return deployments.run(api.tiers.getDeployedRelease(requestParameters));
    }

    dispatch(fetchDeployedReleases(requestParameters));
  };

  useSocketAsync({
    topic: 'tiers/releases',
    filterBySystemUuid: systemId,
    options: { callBackThrottleTime: 1300 },
    onMessage: async () => {
      tableRef.current?.requestDataWithParams({ isSilentMode: true });
    },
  });

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters(hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, hasSelectedFilters]);

  useEffect(() => () => dispatch(clearDeployedReleasesState()), []);

  const headerData = [
    {
      key: 'release.name',
      label: t('common.release'),
      isHidden: releaseId,
      withSort: true,
    },
    {
      key: 'tier.code',
      label: t('common.tier'),
      isHidden: tierId,
      withSort: true,
    },
    {
      key: 'commit',
      label: t('common.commit'),
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      isHidden: systemId || isDeployedFoEentity,
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('pages.systemDeploymentsPage.deployDate'),
      withSort: true,
    },
  ];

  const formattedBodyData = currentData?.map(item => ({
    id: item?.uuid,
    data: [
      {
        key: 'release.name',
        content: item?.release?.name && (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.release.releaseViewForm}
            to={
              item.system.uuid
                ? generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
                    systemId: item.system.uuid,
                    systemType: item.system.type,
                    releaseId: item.release.uuid,
                    action: releaseOptions.view,
                  })
                : undefined
            }
            isTableLink
          >
            <StatusComponent
              statusId={item.release.status}
              statusWithTooltip
              statuses={releasesAvailableStatuses}
            >
              {item.release.name}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'tier.code',
        content: item?.tier?.name && (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.tier.tierViewForm}
            to={generatePath(paths.systemFullPaths.tier, {
              systemId: item.system.uuid,
              systemType: item.system.type,
              tierId: item.tier?.uuid,
            })}
            isTableLink
          >
            <div className="d-flex">
              <StatusComponent
                statusId={item.tier.status}
                statusWithTooltip
                statuses={tierAvailableStatuses}
              >
                {item.tier.name}
              </StatusComponent>
              {item.tier?.devTier && <DevTiersLabel />}
            </div>
          </LinkWithPermission>
        ),
      },
      {
        key: 'commit',
        content: item?.commit && <ServiceVersionCommit commit={item.commit} truncateMaxLines={2} />,
      },
      {
        key: 'system.shortName',
        content: item?.system?.shortName && (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.system.systemViewForm}
            to={
              item.system?.type
                ? generatePath(paths.routePaths.system, {
                    systemId: item.system.uuid,
                    systemType: item.system.type,
                  })
                : undefined
            }
            isTableLink
          >
            <StatusComponent
              statusId={
                item.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : item.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {item.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'status',
        content: item?.status && (
          <StatusComponent
            statusId={item?.status}
            statuses={releasesDeployStatus}
            objectUuid={item.uuid}
            objectType="TIER_RELEASE"
          />
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={item?.lastUpdatedBy}
            system={item?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        content: item.lastUpdatedAt,
        type: types.relativeTime,
      },
    ],
  }));

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{FilterComponent}</div>}
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        isLoading={deployments.data?.data ? deployments.isLoading : isLoading}
        tableName="deployments"
        requestParams={requestParams}
        requestFunction={requestFunction}
        total={deployments.data?.meta.total || total}
        withStandartPagination
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError || deployments.isError}
      />
    </>
  );
}

export default SystemDeploymentsTable;
