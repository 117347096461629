import React, { useEffect, useState } from 'react';
import CopyIcon from '@patternfly/react-icons/dist/esm/icons/copy-icon';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useAsync from 'hooks/useAsync';
import PageLayout from 'components/UI/PageLayout';
import { downloadFile } from 'utils';

import * as S from './styles';

function DocumentsPage() {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(null);
  const { run, data } = useAsync();
  const documents = [
    {
      name: t('pages.documentsPage.functionalDescription'),
      uuid: '0ee1f886-0805-4183-a6b6-ddb5a83d07c6',
    },
    {
      name: t('pages.documentsPage.setupDesctiption'),
      uuid: 'ac8ddba4-4f2e-4ad9-98ef-1fe602fd96fd',
    },
  ];

  useEffect(() => {
    if (data) {
      downloadFile(data, `${fileName}.docx`);
    }
  }, [data]);

  const baseApiUrl = window.location.hostname;

  return (
    <PageLayout title={t('pages.documentsPage.documentation')} documentPage>
      <div className="d-flex flex-column align-items-baseline">
        <div className="d-flex flex-row">
          {documents.map(({ name, uuid }) => (
            <S.DocumentLink
              key={name}
              onClick={() => {
                setFileName(name);
                run(api.documents.download(uuid));
              }}
            >
              <CopyIcon className="mr-2" />
              {name}
            </S.DocumentLink>
          ))}
        </div>

        <S.DocumentLink href={`https://${baseApiUrl}/api/portal/q/swagger-ui/#/`} target="_blank">
          <CopyIcon className="mr-2" />
          {t('pages.documentsPage.swaggerDesctiption')}
        </S.DocumentLink>
      </div>
    </PageLayout>
  );
}

export default DocumentsPage;
