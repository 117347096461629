import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const milestonesSlice = createSlice({
  name: 'milestones',
  initialState: {
    data: [],
    total: 0,
    isLoading: true,
    isError: null,
    milestonesForSystemEdit: { data: [], total: 0, isLoading: true, isError: null },
  },
  reducers: {
    clearMilestonesState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
    },
    clearMilestonesForSystemEditState: state => {
      state.milestonesForSystemEdit.data = [];
      state.milestonesForSystemEdit.total = 0;
      state.milestonesForSystemEdit.isLoading = true;
      state.milestonesForSystemEdit.isError = false;
    },
  },
  extraReducers: {
    [actions.fetchMilestones.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchMilestones.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.total = meta.total;
      state.isLoading = false;
      state.isError = null;
    },
    [actions.fetchMilestones.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },

    [actions.fetchMilestonesForSystemEdit.pending]: state => {
      state.milestonesForSystemEdit.isLoading = true;
      state.milestonesForSystemEdit.isError = null;
    },
    [actions.fetchMilestonesForSystemEdit.fulfilled]: (state, { payload: { data, meta } }) => {
      state.milestonesForSystemEdit.data = data;
      state.milestonesForSystemEdit.total = meta.total;
      state.milestonesForSystemEdit.isLoading = false;
      state.milestonesForSystemEdit.isError = null;
    },
    [actions.fetchMilestonesForSystemEdit.rejected]: (state, { error }) => {
      state.milestonesForSystemEdit.isLoading = false;

      if (checkedServerError(error.message)) {
        state.milestonesForSystemEdit.isError = true;
      }
    },
  },
});

export const { clearMilestonesState, clearMilestonesForSystemEditState } = milestonesSlice.actions;

export default milestonesSlice.reducer;
