import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import lodashGet from 'lodash.get';
import { Tooltip } from '@patternfly/react-core';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { ReactComponent as QuestionCircleSVG } from 'assets/images/services/question-circle.svg';

import InputController from './InputController';
import TextareaController from './TextareaController';
import PhoneInputController from './PhoneInputController';
import NumberInputController from './NumberInputController';
import DatePickerController from './DatePickerController';
import CheckboxController from './CheckboxController';
import RadioInputController from './RadioInputController';
import NewSelectController from './NewSelectController';
import MultiSelectController from './MultiSelectController';
import SwitchInputController from './SwitchInputController';

import * as S from './styles';

export const inputComponents = {
  textarea: 'textarea',
  select: 'select',
  phoneInput: 'phoneInput',
  numberInput: 'numberInput',
  inputWithButton: 'inputWithButton',
  hiddenInput: 'hiddenInput',
  checkbox: 'checkbox',
  radioInput: 'radioInput',
  dateInput: 'dateInput',
  newSelect: 'newSelect',
  multiSelect: 'multiSelect',
  switchInput: 'switchInput',
};

const InputComponent = ({
  label,
  withoutLabel,
  disabled,
  name,
  component,
  defaultValue,
  placeholder,
  isRequired,
  validationRules,
  withoutErrorsMessage,
  tooltipMessage,
  intNumber,
  possibleRange,
  withValueText,
  addText,
  maxLength,
  minLength,
  examples = [],
  minDate,
  maxDate,
  checledValue,
  height,
  searchFilter,
  onBlurAction,
  onFocusAction,
  onlyTextValue,
  warningMessage,
  switchLabel,
  withuotRequireIcon,
  optionWithoutValue,
  withIconByStatus,
  labelStyle,
  isTextInput,
  dropdown,
  errorText,
  withoutBottomMargin,
  description,
  dynamicDescription,
  inputDescription,
  descriptionMaxLength,
  descriptionNoWrap,
  isGreaterStatus,
  focusCallback,
  // select
  options,
  noOptionsMessage,
  isMulti,
  withAddButton,
  isLoading,
  withoutOptions,
  checkedValue,
  withoutClearButton,
  onlySelect,
  direction,
  withInfiniteScroll,
  valueForNameDeclension,
  withHoverDescription,
  disabledWithoutAngelIcon,
  currentSearch,
  selectCallback,
  isModalForm,
  withTypeaheadValue,
  // radio
  rowDirection,
  newSelectVariant,
  newSelectOnFilter,
  onTypeaheadInputChanged,
  noResultsText,
}) => {
  const { t, i18n } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext();
  const [isFocus, setIsFocus] = useState(false);
  const isFieldValue = component === inputComponents.phoneInput ? undefined : watch(name);
  const maxValuesErrorMessage = maxLengthValue => t('validation.maxLength', [maxLengthValue]);
  const minValuesErrorMessage = minLengthValue => t('validation.minLength', [minLengthValue]);
  const error = lodashGet(errors, name);
  const descriptionClassName = `font-12 text-black ${
    component === inputComponents.radioInput ? 'mt-2' : ''
  }`;
  const dynamicDescriptionClassName = `font-12 font-weight-normal text-black`;
  const rules = {
    required:
      isRequired && (withoutErrorsMessage ? ' ' : errorText || t('validation.requiredMessage')),
    maxLength: { value: maxLength, message: maxValuesErrorMessage(maxLength) },
    minLength: { value: minLength, message: minValuesErrorMessage(minLength) },
    ...validationRules,
  };

  useEffect(() => {
    if (focusCallback) {
      focusCallback(isFocus);
    }
  }, [isFocus]);

  useEffect(() => {
    if (error) {
      trigger(name);
    }
  }, [i18n.language]);

  const defaultProps = {
    disabled,
    name,
    control,
    defaultValue,
    rules,
    placeholder,
    $isError: error,
    $isFocus: isFocus,
    examples,
  };

  const renderedComponent = () => {
    switch (component) {
      case inputComponents.multiSelect: {
        return (
          <MultiSelectController
            {...defaultProps}
            placeholder={placeholder}
            options={options}
            noOptionsMessage={noOptionsMessage}
            isMulti={isMulti}
            withAddButton={withAddButton}
            noResultsText={noResultsText}
            label={label}
          />
        );
      }
      case inputComponents.newSelect: {
        return (
          <NewSelectController
            {...defaultProps}
            placeholder={placeholder}
            options={isLoading ? [] : options}
            noOptionsMessage={noOptionsMessage}
            withAddButton={withAddButton}
            withoutOptions={withoutOptions}
            maxLength={maxLength}
            variant={newSelectVariant}
            onTypeaheadInputChanged={onTypeaheadInputChanged}
            onFilter={newSelectOnFilter}
            noResultsText={noResultsText}
            label={label}
            checkedValue={checkedValue}
            withoutClearButton={withoutClearButton}
            onlySelect={onlySelect}
            height={height}
            direction={direction}
            searchFilter={searchFilter}
            descriptionMaxLength={descriptionMaxLength}
            onlyTextValue={onlyTextValue}
            withInfiniteScroll={withInfiniteScroll}
            optionWithoutValue={optionWithoutValue}
            valueForNameDeclension={valueForNameDeclension}
            withHoverDescription={withHoverDescription}
            disabledWithoutAngelIcon={disabledWithoutAngelIcon}
            inputDescription={inputDescription}
            currentSearch={currentSearch}
            setIsFocus={setIsFocus}
            selectCallback={selectCallback}
            withTypeaheadValue={withTypeaheadValue}
          />
        );
      }
      case inputComponents.textarea: {
        return (
          <TextareaController
            {...defaultProps}
            label={label}
            setIsFocus={setIsFocus}
            onBlurAction={onBlurAction}
          />
        );
      }
      case inputComponents.checkbox: {
        return <CheckboxController {...defaultProps} label={label} />;
      }
      case inputComponents.radioInput: {
        return (
          <RadioInputController
            {...defaultProps}
            options={options}
            rowDirection={rowDirection}
            disabled={disabled}
          />
        );
      }
      case inputComponents.hiddenInput: {
        return <InputController {...defaultProps} type="hidden" />;
      }
      case inputComponents.numberInput: {
        return (
          <NumberInputController
            label={label}
            intNumber={intNumber}
            possibleRange={possibleRange}
            withValueText={withValueText}
            addText={addText}
            checledValue={checledValue}
            setIsFocus={setIsFocus}
            isFocus={isFocus}
            dropdown={dropdown}
            isGreaterStatus={isGreaterStatus}
            {...defaultProps}
          />
        );
      }
      case inputComponents.phoneInput: {
        return <PhoneInputController {...defaultProps} label={label} setIsFocus={setIsFocus} />;
      }
      case inputComponents.dateInput: {
        return (
          <DatePickerController
            {...defaultProps}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      }
      case inputComponents.switchInput: {
        return (
          <SwitchInputController
            {...defaultProps}
            label={switchLabel}
            tooltipMessage={tooltipMessage}
          />
        );
      }

      default: {
        return (
          <InputController
            {...defaultProps}
            label={label}
            onBlurAction={onBlurAction}
            onFocusAction={onFocusAction}
            onlyTextValue={onlyTextValue}
            setIsFocus={setIsFocus}
            withIconByStatus={withIconByStatus}
            isFocus={isFocus}
            isFieldValue={isFieldValue}
            isTextInput={isTextInput}
          />
        );
      }
    }
  };

  return (
    <S.Wrapper
      $descriptionNoWrap={descriptionNoWrap}
      $disabledWithoutAngelIcon={disabledWithoutAngelIcon && disabled}
    >
      <S.RowWrapper
        $descriptionNoWrap={descriptionNoWrap}
        withoutOptions={withoutOptions}
        isCheckbox={component === inputComponents.checkbox}
        dropdown={dropdown}
      >
        {label && !withoutLabel && (
          <S.Label
            $descriptionNoWrap={descriptionNoWrap}
            data-input-label={label}
            style={labelStyle || {}}
          >
            {label}
            {isRequired && !withuotRequireIcon && <S.RequireIcon>*</S.RequireIcon>}
            {tooltipMessage && (
              <Tooltip
                content={tooltipMessage}
                position="right"
                exitDelay={150}
                animationDuration={150}
              >
                <QuestionCircleSVG className="ml-1 vertical-align-middle" />
              </Tooltip>
            )}
            {warningMessage && (
              <div className="d-inline pl-1">
                <Tooltip content={warningMessage} exitDelay={150} animationDuration={150}>
                  <ExclamationCircleIcon className="text-red font-13" />
                </Tooltip>
              </div>
            )}
          </S.Label>
        )}
        {renderedComponent()}
        {isLoading && (
          <S.StyledLoader isModalForm={isModalForm}>
            <Loader
              type="TailSpin"
              color="#048E94"
              height={20}
              width={20}
              style={{ AlignSelf: 'center' }}
            />
          </S.StyledLoader>
        )}
      </S.RowWrapper>
      {error && !withoutErrorsMessage && (
        <>
          {error.type === 'required' && (isFocus || isFieldValue) ? (
            <></>
          ) : (
            <S.RedText
              $descriptionNoWrap={descriptionNoWrap}
              $withoutBottomMargin={withoutBottomMargin}
              data-input-error-for={label}
            >
              {error.message}
            </S.RedText>
          )}
        </>
      )}

      {description && isFocus && (!error || error.type === 'required') && (
        <S.DynamicText className={descriptionClassName} $descriptionNoWrap={descriptionNoWrap}>
          {description}
        </S.DynamicText>
      )}

      {dynamicDescription && (
        <div
          className={dynamicDescriptionClassName}
          style={{ marginTop: isFocus || error ? '16px' : '1px' }}
        >
          {dynamicDescription}
        </div>
      )}
    </S.Wrapper>
  );
};
export default InputComponent;
