import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useModal from 'hooks/useModal';
import { staticAnalysisIssuesSelector } from 'store/staticAnalysisResults/selectors';
import { clearStateAnalysisIssues } from 'store/staticAnalysisResults/reducers';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import StatusComponent from 'components/Common/StatusComponent';
import ModalInfo from 'components/Common/ModalInfo';
import ReadMoreBlock from 'components/Common/ReadMoreBlock';
import { getSeverityStatuses } from 'components/Common/StatusComponent/statuses';
import { getStaticAnalysisProblemTypeNames, staticAnalysisProblemTypeKeys } from 'constants';
import TruncateWithTooltipText from 'components/Common/TruncateWithTooltipText';
import { useTranslation } from 'react-i18next';

function StaticAnalysisProblemsTable({
  requestParams,
  requestFunction,
  hasSelectedFilters,
  tableName,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const severityStatuses = getSeverityStatuses();
  const staticAnalysisProblemTypeNames = getStaticAnalysisProblemTypeNames();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal();
  const { data, isLoading, total, isError } = useSelector(staticAnalysisIssuesSelector);
  const problemTitles = {
    [staticAnalysisProblemTypeKeys.BUG]: t(
      'pages.systemServiceVersionPage.staticAnalysisProblems.problemTitles.BUG'
    ),
    [staticAnalysisProblemTypeKeys.VULNERABILITY]: t(
      'pages.systemServiceVersionPage.staticAnalysisProblems.problemTitles.VULNERABILITY'
    ),
    [staticAnalysisProblemTypeKeys.CODE_SMELL]: t(
      'pages.systemServiceVersionPage.staticAnalysisProblems.problemTitles.CODE_SMELL'
    ),
  };

  const headerData = [
    {
      key: 'type',
      label: t('common.type'),
      withSort: true,
    },
    {
      key: 'description',
      label: t('common.description'),
      withSort: true,
    },
    {
      key: 'severity',
      label: t('common.severityLevel'),
      withSort: true,
    },
    {
      key: 'component',
      label: t('common.component'),
      withSort: true,
    },
    {
      key: 'line',
      label: t('pages.systemServiceVersionPage.staticAnalysisProblems.row'),
    },
  ];
  useEffect(() => () => dispatch(clearStateAnalysisIssues()), []);

  const onInfoClick = item => {
    setModalData(item);
    toggleModal();
  };

  const formattedBodyData = data?.map((item, index) => ({
    id: `${item?.uuid}${index}`,
    data: [
      {
        key: 'type',
        content: (
          <span className="text-wrap-nowrap">
            {staticAnalysisProblemTypeNames[item.type] || '-'}
          </span>
        ),
      },
      {
        key: 'description',
        content: <ReadMoreBlock content={item.description} withoutExpandButton />,
        type: types.textLink,
        onClick: () => onInfoClick(item),
      },
      {
        key: 'severity',
        content: item?.status && (
          <StatusComponent statusId={item.severity} statuses={severityStatuses} />
        ),
      },
      {
        key: 'component',
        content: <TruncateWithTooltipText content={item.component} />,
      },
      {
        key: 'line',
        content: item?.line,
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName={tableName}
        withStandartPagination
        total={total}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />
      <ModalInfo
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        title={problemTitles[modalData?.type]}
      >
        <div className="pre-line">{modalData?.description}</div>
      </ModalInfo>
    </>
  );
}

export default StaticAnalysisProblemsTable;
