import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import { dateTimeConverter, isDueDateWasExceeded } from 'utils';
import { useTranslation } from 'react-i18next';

function IssueClosedAtDate({ dueDate, closedAt, rightContent }) {
  const { t } = useTranslation();

  if (!closedAt) {
    return null;
  }

  const styles = `d-flex flex-row text-nowrap mr-1${rightContent ? ' float-right' : ''}`;
  const messageDueDateWasExceeded = t('pages.systemIssuesPage.issueFinishedAfterMilestoneError');

  return (
    <div className={styles}>
      <>{dateTimeConverter(closedAt)}</>{' '}
      {!!closedAt && !!dueDate && isDueDateWasExceeded(closedAt, dueDate) && (
        <Tooltip content={messageDueDateWasExceeded} exitDelay={150} animationDuration={150}>
          <ExclamationCircleIcon className="ml-2 text-warning-light" />
        </Tooltip>
      )}
    </div>
  );
}

export default IssueClosedAtDate;
