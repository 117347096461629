import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';

import { getVersionDescription } from 'pages/SystemRouter/SystemServiceVersionsRouter/utils';

function ServiceVersionsName({ version, option }) {
  const currentVersion = version || option?.description;

  const iconClassName = `font-16 ${
    !currentVersion?.addedViaPortal || currentVersion.usedDefaultBranch === false
      ? 'text-danger'
      : 'text-blu'
  }`;

  return (
    <>
      <span className="font-14 mr-2 text-word-break-wrap">{currentVersion?.tag}</span>
      <Tooltip content={getVersionDescription(currentVersion) || ''} exitDelay={150}>
        <InfoCircleIcon className={iconClassName} />
      </Tooltip>
    </>
  );
}

export default ServiceVersionsName;
