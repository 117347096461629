import React from 'react';
import { useHistory, generatePath } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import paths from 'constants/paths';
import PageLayout from 'components/UI/PageLayout';
import { getSystemTypesNames } from 'constants';

import * as S from './styles';

function SystemStatus() {
  const { t } = useTranslation();
  const systemTypesNames = getSystemTypesNames();
  const formMethods = useForm();
  const history = useHistory();
  const { localStorage } = window;
  const { checkedPermissions } = useUserPermissions();
  const options = [
    {
      value: paths.systemTypes.internal,
      label: systemTypesNames.INTERNAL,
      description: t('pages.addNewSystemPage.internalSystemDescription'),
    },
    {
      value: paths.systemTypes.external,
      label: systemTypesNames.EXTERNAL,
      description: t('pages.addNewSystemPage.externalSystemDescription'),
    },
  ];
  const title = t('pages.addNewSystemPage.create');

  const closeForm = () => {
    localStorage.clear('addNewExternalSystemForm');
    localStorage.clear('addNewInternalSystemForm');
    history.goBack();
  };

  const onSubmit = ({ systemType }) => {
    history.push(generatePath(paths.newSystemFullPaths.add, { systemType }));
  };

  return (
    <PageLayout title={title} backLink={paths.routePaths.main} fullHeight withoutSidePadding>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} className="p-4 border-top">
          <div className="font-20 font-weight-medium mb-3">
            {t('pages.addNewSystemPage.systemType')}
          </div>
          <S.BlockWrapper>
            <InputComponent
              name="systemType"
              component={inputComponents.radioInput}
              defaultValue={options[0].value}
              options={options}
              disabled={!checkedPermissions(scopes.platform.externalSystemsCreate, true)}
            />
          </S.BlockWrapper>
          <Button onClick={formMethods.handleSubmit(onSubmit)}>{t('common.forward')}</Button>
          <Button onClick={formMethods.handleSubmit(closeForm)} variant="link">
            {t('common.cancel')}
          </Button>
        </form>
      </FormProvider>
    </PageLayout>
  );
}

export default SystemStatus;
