import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchServiceVersionsVulnerabilities } from 'store/serviceVersionsBuildResults/actions';
import { serviceVersionsBuildResultsSelector } from 'store/serviceVersionsBuildResults/selectors';
import TextBlock from 'components/UI/TextBlock';
import {
  buildResultTypeKeys,
  getBuildResultTypeNames,
  getVulnerabilitySeverityNames,
} from 'constants';

import ServiceVersionsVulnerabilitysTable from './ServiceVersionsVulnerabilitysTable';
import BuildResults from '../BuildResults';

function ServiceVersionsVulnerabilitysPage() {
  const { t } = useTranslation();
  const vulnerabilitySeverityNames = getVulnerabilitySeverityNames();
  const buildResultTypeNames = getBuildResultTypeNames();
  const {
    params: { vulnerabilityId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { data } = useSelector(serviceVersionsBuildResultsSelector);
  const vulnerabilityAnalysis = data.find(
    item => item.type === buildResultTypeKeys.VULNERABILITY_ANALYSIS
  );
  const filterData = [
    {
      id: 'pkgName',
      name: t('pages.systemServiceVersionPage.package'),
    },
    {
      id: 'vulnerabilityId',
      name: t('pages.systemServiceVersionPage.cve'),
    },
    {
      id: 'severity',
      name: t('common.severityLevel'),
      type: 'multiSelect',
      options: formatFilterOptions(vulnerabilitySeverityNames),
    },
    {
      id: 'title',
      name: t('common.name'),
    },
  ];
  const { filterParams, hasSelectedFilters, FilterComponent } = useMultiSelectFilterSection(
    filterData,
    'serviceVersionsVulnerabilitys'
  );

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'severity',
      ascending: false,
      buildResultUuid: vulnerabilityId,
    }),
    [filterParams]
  );
  const requestFunction = params => dispatch(fetchServiceVersionsVulnerabilities(params));

  return (
    <div className="mt-4">
      <h2 className="font-20 font-weight-medium mt-4 mx-4 mb-4">
        {buildResultTypeNames[buildResultTypeKeys.VULNERABILITY_ANALYSIS]}
      </h2>
      <div className="d-flex flex-wrap pb-3">
        <div className="d-flex flex-row mx-4 mb-3 gap-6 align-items-start">
          <TextBlock greyColor title={t('pages.systemServiceVersionPage.artifactName')}>
            {vulnerabilityAnalysis?.artifactName || '-'}
          </TextBlock>
          <div className="mr-3">
            <TextBlock greyColor title={t('pages.systemServiceVersionPage.artifactType')}>
              {vulnerabilityAnalysis?.artifactType || '-'}
            </TextBlock>
          </div>
        </div>
        <div className="pl-4 align-self-flex-start">
          <TextBlock greyColor title={t('pages.systemServiceVersionPage.result')}>
            <BuildResults
              withoutTooltip
              totalSummary={vulnerabilityAnalysis?.totalSummary}
              type={vulnerabilityAnalysis?.type}
            />
          </TextBlock>
        </div>
      </div>

      <div className="ml-4">{FilterComponent}</div>
      <ServiceVersionsVulnerabilitysTable
        requestParams={requestParams}
        requestFunction={requestFunction}
        hasSelectedFilters={hasSelectedFilters}
      />
    </div>
  );
}

export default ServiceVersionsVulnerabilitysPage;
