import styled from 'styled-components';

export const DocumentWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  height: 28px;
  background: #f0f0f0;
  color: #0f69c9;
  border-width: 0px;
  border-radius: 12px;
  padding: 5px 9px;

  & :hover {
    border-color: #06c;
  }
`;
