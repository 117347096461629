import { useDispatch, useSelector } from 'react-redux';
import { changeExpandedItems } from 'store/expandedItems/reducers';
import { expandedItemsSelector } from 'store/expandedItems/selectors';

export const expandedKeys = {
  serviceVersions: 'serviceVersions',
  releases: 'releases',
  backups: 'backups',
  auditEvents: 'auditEvents',
  clusterSystems: 'clusterSystems',
  integrations: 'integrations',
  integrationsSystems: 'integrationsSystems',
  milestones: 'milestones',
  tasksInRelease: 'tasksInRelease',
  services: 'services',
  issues: 'issues',
};

function useTableExpandedItems() {
  const dispatch = useDispatch();
  const expandedItems = useSelector(expandedItemsSelector);

  const toggleExpandedUuid = (key, uuid) => {
    if (!key) {
      return;
    }

    dispatch(
      changeExpandedItems({
        ...expandedItems,
        [key]: expandedItems[key]?.includes(uuid)
          ? expandedItems[key].filter(id => uuid !== id)
          : [...(expandedItems[key] ?? []), uuid],
      })
    );
  };

  const setExpandedUuid = (key, uuids) => {
    if (!key) {
      return;
    }

    dispatch(
      changeExpandedItems({
        ...expandedItems,
        [key]: uuids,
      })
    );
  };

  const clearExpandedUuidByKey = key => {
    if (!key) {
      return;
    }

    dispatch(
      changeExpandedItems({
        ...expandedItems,
        [key]: [],
      })
    );
  };

  const getExpandedUuidsBykey = key => expandedItems[key] ?? [];

  return { getExpandedUuidsBykey, toggleExpandedUuid, setExpandedUuid, clearExpandedUuidByKey };
}

export default useTableExpandedItems;
