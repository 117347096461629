import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const integrationSystemsSlice = createSlice({
  name: 'integrationSystems',
  initialState: { data: [], isLoading: true, total: 0 },
  extraReducers: {
    [actions.fetchIntegrationSystems.pending]: state => {
      state.isLoading = true;
    },
    [actions.fetchIntegrationSystems.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.isLoading = false;
      state.total = meta.total;
    },
    [actions.fetchIntegrationSystems.rejected]: state => {
      state.isLoading = false;
    },
  },
});

export default integrationSystemsSlice.reducer;
