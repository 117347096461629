import React from 'react';
import { Link } from 'react-router-dom';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { TextButton } from 'components/UI/Button';
import { systemTypesKeys } from 'constants';

function LinkWithPermission({
  children,
  to,
  system,
  scope,
  isTableLink,
  font,
  target,
  linkStyles,
  isPlatformScope,
}) {
  const { checkedPermissions } = useUserPermissions(system);

  if (!children) {
    return '-';
  }

  if (
    system?.type === systemTypesKeys.EXTERNAL &&
    !checkedPermissions(scopes.platform.externalSystemViewForm, true)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      {(!scope || checkedPermissions(scope, isPlatformScope)) && to ? (
        <TextButton
          variant="link"
          component={props => <Link {...props} to={to} />}
          $tableLink={isTableLink}
          font={font}
          target={target}
          className={linkStyles}
        >
          {children}
        </TextButton>
      ) : (
        children
      )}
    </>
  );
}

export default LinkWithPermission;
