import React from 'react';
import { useFormContext } from 'react-hook-form';
import PlusCircleIcon from '@patternfly/react-icons/dist/esm/icons/plus-circle-icon';
import { useTranslation } from 'react-i18next';

import useFormDynamicFields from 'hooks/useFormDynamicFields';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { DeleteButton } from 'components/UI/DeleteButton';
import { TextButton } from 'components/UI/Button';

import { PortsInputsBlock, ProtocolInputWrapper, DeleteButtonAbsoluteWrapper } from '../../styles';

const portOptions = [
  { label: 'TCP', value: 'TCP' },
  { label: 'UDP', value: 'UDP' },
];

const parentField = 'ports';

function ServicePorts({ isNotRequired, placeholder, isModalForm }) {
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(parentField);
  const { watch } = useFormContext();

  return (
    <div>
      <div>
        {fieldUuids.map((fieldUuid, index) => {
          const numberValue = watch(`${parentField}[${index}].number`);
          const protocolValue = watch(`${parentField}[${index}].transportProtocol`);
          const isRequiredProtocol = !protocolValue && numberValue;
          const isRequiredNumber = protocolValue && !numberValue;
          const isDisabled = isNotRequired && !numberValue && !protocolValue;

          return (
            <PortsInputsBlock key={fieldUuid}>
              <div className="position-relative">
                <div className="d-flex flex-row align-items-start">
                  <div className="w-75">
                    <InputComponent
                      label={index > 0 ? '' : t('pages.systemAddServicePage.port')}
                      name={`${parentField}[${index}].number`}
                      component={inputComponents.numberInput}
                      possibleRange={[0, 65535]}
                      isRequired={isNotRequired ? isRequiredNumber : true}
                      placeholder={placeholder}
                      withuotRequireIcon={isNotRequired}
                      withoutErrorsMessage={isDisabled}
                    />
                  </div>
                  <ProtocolInputWrapper>
                    <InputComponent
                      label={index > 0 ? '' : t('pages.systemAddServicePage.protocol')}
                      name={`${parentField}[${index}].transportProtocol`}
                      component={inputComponents.newSelect}
                      options={portOptions}
                      isRequired={isNotRequired ? isRequiredProtocol : true}
                      placeholder={isDisabled ? placeholder : ' '}
                      withoutClearButton={!isNotRequired}
                      withuotRequireIcon={isNotRequired}
                      withoutErrorsMessage={isDisabled}
                      errorText={t('validation.required')}
                      disabled={isDisabled}
                    />
                  </ProtocolInputWrapper>
                </div>
                {fieldUuids.length > 1 && (
                  <DeleteButtonAbsoluteWrapper withLabel={index > 0} isModalForm={isModalForm}>
                    <DeleteButton onClick={() => deleteField(fieldUuid, index)} />
                  </DeleteButtonAbsoluteWrapper>
                )}
              </div>
            </PortsInputsBlock>
          );
        })}
      </div>

      <div className="d-flex">
        <TextButton variant="link" icon={<PlusCircleIcon className="font-16" />} onClick={addField}>
          <span className="font-16">{t('pages.systemAddServicePage.addPort')}</span>
        </TextButton>
      </div>
    </div>
  );
}

export default ServicePorts;
