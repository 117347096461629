import styled from 'styled-components';

export const TabsWrapper = styled.div`
  height: 100% !important;
`;

export const ActualMilestone = styled.span.attrs({
  className: 'ml-3 py-1 px-2 text-white font-14 text-nowrap',
})`
  background: #009596;
  border-radius: 13px;
`;
