import React from 'react';
import { clipboardCopyFunc } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

function ClipboardCopyComponent({ content, withTruncate, href }) {
  const { t } = useTranslation();
  const styles = `pl-0 ${withTruncate ? 'd-sm-flex' : ''}`;

  const currentContent = href ? (
    <a href={href} target="_blank" rel="noreferrer">
      {content}
    </a>
  ) : (
    content
  );

  return (
    <S.ClipboardCopyStyled
      className={styles}
      hoverTip={t('components.clipboard.copy')}
      clickTip={t('components.clipboard.copied')}
      variant="inline-compact"
      onClick={e => clipboardCopyFunc(e, content)}
    >
      {withTruncate ? <S.TruncateStyled content={currentContent} /> : currentContent}
    </S.ClipboardCopyStyled>
  );
}

export default ClipboardCopyComponent;
