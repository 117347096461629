import styled from 'styled-components';
import { Tabs } from '@patternfly/react-core';

export const TabsWrapper = styled.div`
  height: 100% !important;
`;

export const TabsStyled = styled(Tabs)`
  padding: 0 24px;

  .pf-v5-c-badge {
    min-width: 24px;
    font-weight: 500;
    vertical-align: middle;
  }

  .pf-v5-m-read {
    background: #f0f0f0;
  }

  .pf-v5-m-current {
    .pf-v5-m-read {
      color: #ffffff;
      background-color: #0066cc;
    }
  }
`;
