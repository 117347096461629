import http, { httpKeycloak } from 'api/config';

const getUsers = params => http.get(`/users`, { params });
const getSystemUsers = params => http.get(`/systems/users`, { params });
const getKeycloakUsers = params => httpKeycloak.get(`/users`, { params });
const getUserById = id => http.get(`/users/${id}`);
const getUserRoles = (id, params) => http.get(`/users/${id}/roles`, { params });
const updateUserRole = (userId, roleId, payload) =>
  http.put(`/users/${userId}/roles/${roleId}`, payload);
const deleteUserRole = (userId, roleId) => http.delete(`/users/${userId}/roles/${roleId}`);
const deleteAllUserRoles = id => http.delete(`/users/${id}/roles`);
const createUser = payload => http.post(`/users`, payload);
const createUserRole = (id, payload) => http.post(`/users/${id}/roles`, payload);
const updateUser = (id, payload) => http.patch(`/users/${id}`, payload);
const actualizeUserById = (id, params) =>
  http.post(`/users/${id}/actualize`, params, { headers: { errorIsIgnored: true } });

export default {
  getUsers,
  getSystemUsers,
  getKeycloakUsers,
  getUserById,
  getUserRoles,
  createUser,
  createUserRole,
  updateUser,
  updateUserRole,
  deleteUserRole,
  deleteAllUserRoles,
  actualizeUserById,
};
