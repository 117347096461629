import React from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { userEventTypesKeys } from 'constants';
import { scopes } from 'constants/scopes';
import paths from 'constants/paths';
import LinkWithPermission from 'components/Common/LinkWithPermission';

function AuditDescription({ event }) {
  const { t } = useTranslation();
  const getLink = (user = event.user) =>
    user?.uuid ? (
      <LinkWithPermission
        scope={scopes.platform.userViewForm}
        linkStyles="mx-1"
        to={generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.details, {
          userId: user.uuid,
        })}
        isPlatformScope
        isTableLink
      >
        {user.login}
      </LinkWithPermission>
    ) : (
      <span>«{user?.login}»</span>
    );
  const properties = event.descriptionProperties;
  const eventsDescriptionMap = {
    [userEventTypesKeys.LOGIN]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.authorized')}
      </>
    ),
    [userEventTypesKeys.LOGIN_ERROR]: () => (
      <>
        {t('pages.adminAuditPage.messages.userWithLogin')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.triedToLogin')}
      </>
    ),
    [userEventTypesKeys.LOGOUT]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.loggedOut')}
      </>
    ),
    [userEventTypesKeys.HYPERION_DOWNLOAD_DOCUMENT]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink()}{' '}
        {properties?.serverTierName &&
          t('pages.adminAuditPage.messages.downloadedServerTierDocument', [
            properties?.fileName,
            properties?.serverTierName,
            properties?.fileSize,
          ])}
        {properties?.systemName &&
          t('pages.adminAuditPage.messages.downloadedSystemDocument', [
            properties?.fileName,
            properties?.systemName,
            properties?.fileSize,
          ])}
      </>
    ),
    [userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_ADDED]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.addedUser')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.inSystemWithRole', [
          properties?.systemName,
          t(`constants.systemRoleLabels.${properties?.currentRole}`),
        ])}
      </>
    ),
    [userEventTypesKeys.HYPERION_GROUP_MEMBERSHIP_REMOVED]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.revokedAccess')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.toSystem', [properties?.systemName])}
      </>
    ),
    [userEventTypesKeys.HYPERION_ENABLE_USER]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.activatedAccount')} {getLink()}
      </>
    ),
    [userEventTypesKeys.HYPERION_DISABLE_USER]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.deactivatedAccount')} {getLink()}
      </>
    ),
    [userEventTypesKeys.HYPERION_UPDATE_RESPONSIBLE]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.addedUser')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.asResponsibleForSystem', [properties?.systemName])}
      </>
    ),
    [userEventTypesKeys.HYPERION_CHANGE_ACCESS_LEVEL]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.changedAccessLevel')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.fromTo', [
          t(`constants.userAdminNames.${properties?.previousAccessLevel?.toLowerCase()}`),
          t(`constants.userAdminNames.${properties?.currentAccessLevel?.toLowerCase()}`),
        ])}
      </>
    ),
    [userEventTypesKeys.HYPERION_CHANGE_USER_ROLE]: () => (
      <>
        {t('pages.adminAuditPage.messages.user')} {getLink(event.createdBy)}{' '}
        {t('pages.adminAuditPage.messages.changedRole')} {getLink()}{' '}
        {t('pages.adminAuditPage.messages.fromTo', [
          t(`constants.systemRoleLabels.${properties?.previousRole}`),
          t(`constants.systemRoleLabels.${properties?.currentRole}`),
        ])}{' '}
        {t('pages.adminAuditPage.messages.inSystem', [properties?.systemName])}
      </>
    ),
  };

  return eventsDescriptionMap[event.eventType]();
}

export default AuditDescription;
