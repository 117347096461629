import http from 'api/config';

const restartUnsuccessful = uuid => http.post(`pipelines/${uuid}/retry`);

const cancelPipeline = uuid => http.post(`pipelines/${uuid}/cancel`);

const retryJob = (uuid, id) => http.post(`pipelines/${uuid}/jobs/${id}/retry`);

export default {
  restartUnsuccessful,
  cancelPipeline,
  retryJob,
};
