import styled from 'styled-components';

import TrashIconSVG from 'assets/images/document/deleteBig.svg';

export const TrashIcon = styled.button`
  cursor: pointer;
  -webkit-mask-image: url(${TrashIconSVG});
  mask-image: url(${TrashIconSVG});
  background-color: #8b8d8f;
  border: none;
  &:hover {
    background-color: #c71c19;
  }
  width: 13px;
  height: 14px;
`;

export const Wrapper = styled.div.attrs({
  className: 'd-flex flex-row cursor-pointer border-none py-0',
})`
  background: transparent;
`;
