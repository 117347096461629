import React from 'react';
import { Controller } from 'react-hook-form';

import { Textarea } from './styles';

const TextareaController = ({
  name,
  control,
  rules,
  label,
  setIsFocus,
  onBlurAction,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue=""
    render={({ field }) => {
      if (inputProps?.disabled) {
        return <div className="mt-2">{field.value}</div>;
      }

      return (
        <div className="d-flex align-content-end">
          <Textarea
            data-input-for={label}
            {...field}
            {...inputProps}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
              field.onChange(field.value?.trim());
              if (onBlurAction) {
                onBlurAction();
              }
            }}
          />
        </div>
      );
    }}
  />
);

export default TextareaController;
