import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { scopes } from 'constants/scopes';
import { checkedSystemPermission } from 'utils';
import { systemStatusKeys, systemTypesKeys } from 'constants';

export const fetchSystems = createAsyncThunk(
  'systems/fetchSystems',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.systems.getSystems({
      orderBy: 'name',
      ascending: true,
      ...params,
    });

    return {
      ...data,
      isConcatData,
      search: params.q,
    };
  }
);

export const getNewSystem = async (id, getState) => {
  const systemResponse = await api.systems.getSystem(id);
  const systemData = systemResponse.data;
  const userPermissions = Array.isArray(getState) ? getState : getState().user.permissions;

  if (systemData?.type === systemTypesKeys.EXTERNAL) {
    return systemData;
  }

  try {
    if (checkedSystemPermission(userPermissions, scopes.system.systemViewForm, systemData?.code)) {
      const response = await Promise.all([
        api.systems.getContracts(id),
        ...(systemData?.status !== systemStatusKeys.ARCHIVE
          ? [api.systems.getResponsible(id)]
          : []),
        api.systems.getDevelopers(id),
      ]);

      const data = response.map(item => item.data);

      return {
        ...systemData,
        contracts: data[0]?.data,
        responsible: data[1],
        developers: data[2],
      };
    }
  } catch (error) {
    if (error) {
      return systemData;
    }
  }

  return systemData;
};

export const fetchSystemById = createAsyncThunk(
  'systems/fetchSystemById',
  async ({ id, callback }, { getState }) => {
    try {
      if (id) {
        const newSystem = await getNewSystem(id, getState);
        return newSystem;
      }
    } catch (e) {
      if (e && callback) {
        callback(e);
        return null;
      }

      return null;
    }
  }
);

export const fetchSystemsOptions = createAsyncThunk(
  'systems/fetchSystemsOptions',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.systems.getSystemOptions(params);

    return {
      ...data,
      isConcatData,
    };
  }
);
