import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import { fetchServiceByActiveStatus } from 'store/services/actions';
import { serviceByActiveStatusSelector } from 'store/services/selectors';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import { BlueButton } from 'components/UI/Button';
import { getNotActiveServiceMessage, getProgressSystemMessage } from 'constants/tooltips';

import ServiceVersionsTable from './ServiceVersionsTable';

function ServiceVersionsPage() {
  const { t, i18n } = useTranslation();
  const notActiveServiceMessage = getNotActiveServiceMessage();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { checkedPermissions } = useUserPermissions();

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const currentSystem = useSelector(currentSystemSelector);
  const activeServiceVersion = useSelector(serviceByActiveStatusSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const disabledMessage = useMemo(
    () => notActiveSystemMessage || (!activeServiceVersion?.uuid && notActiveServiceMessage),
    [systemId, notActiveSystemMessage, activeServiceVersion?.uuid, i18n.language]
  );

  useSocketAsync({
    topic: 'services',
    debounceTime: 600,
    filterBySystemUuid: systemId,
    onMessage: () =>
      systemId &&
      !activeServiceVersion?.uuid &&
      dispatch(
        fetchServiceByActiveStatus({
          system: systemId,
        })
      ),
  });

  useEffect(() => {
    if (
      systemId &&
      (!activeServiceVersion?.uuid || activeServiceVersion?.system.uuid !== systemId)
    ) {
      dispatch(
        fetchServiceByActiveStatus({
          system: systemId,
        })
      );
    }
  }, [systemId]);

  const addServiceVersionsButton = (
    <>
      {systemId && checkedPermissions(scopes.serviceVersion.versionCreate) && (
        <Tooltip
          className={disabledMessage ? undefined : 'd-none'}
          content={disabledMessage}
          exitDelay={150}
          animationDuration={150}
        >
          <div>
            <BlueButton
              isDisabled={disabledMessage}
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemServiceVersionsFullPaths.new, {
                    systemId,
                    systemType,
                  })}
                />
              )}
            >
              {t('common.add')}
            </BlueButton>
          </div>
        </Tooltip>
      )}
    </>
  );

  const leftSideContent = isShowFilters && <>{addServiceVersionsButton}</>;

  return (
    <PageLayout
      title={t('common.serviceVersions')}
      systemLayout
      withSystemHeader
      withSystemSelector
      leftSideContent={leftSideContent}
    >
      <ServiceVersionsTable
        tableName="systemServiceVersions"
        addButton={addServiceVersionsButton}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        currentTotal={currentSystem?.uuid && currentSystem?.totalSummary?.serviceVersionTotal.total}
      />
    </PageLayout>
  );
}

export default ServiceVersionsPage;
