import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { contactPersonsSelector } from 'store/contactPersons/selectors';
import { fetchContactPersons } from 'store/contactPersons/actions';
import NewDataTable from 'components/Common/NewDataTable';

function ExternalSystemContactsTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const filterData = [
    {
      id: 'fullName',
      name: t('common.fio'),
    },
    {
      id: 'email',
      name: t('common.email'),
    },
    {
      id: 'phone',
      name: t('common.phone'),
    },
    {
      id: 'workplace',
      name: t('common.work'),
    },
    {
      id: 'comment',
      name: t('common.comment'),
    },
  ];

  const headerData = [
    {
      key: 'fullName',
      label: t('common.fio'),
      withSort: true,
      filterable: true,
    },
    {
      key: 'email',
      label: t('common.email'),
      withSort: true,
      filterable: true,
    },

    {
      key: 'phone',
      label: t('common.phone'),
      withSort: true,
    },
    {
      key: 'workplace',
      label: t('common.work'),
      withSort: true,
      filterable: true,
    },
    {
      key: 'comment',
      label: t('common.comment'),
      minWidth: '130px',
    },
  ];
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'contactPersons'
  );

  const { data, total, isLoading, isError } = useSelector(contactPersonsSelector);

  const requestFunction = params => dispatch(fetchContactPersons(params));

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      systemId,
      ascending: true,
      orderBy: 'fullName',
    }),
    [systemId, filterParams]
  );

  const formattedBodyData = data?.map((item, index) => ({
    id: `${document.uuid}${index}`,
    data: [
      {
        key: 'fullName',
        content: item?.fullName,
      },
      {
        key: 'email',
        content: item?.email && (
          <a href={`mailto:${item.email}`} className="word-break-break-all">
            {item.email}
          </a>
        ),
      },
      {
        key: 'phone',
        content: item?.phone && <a href={`tel:${item.phone}`}>{item.phone}</a>,
      },
      {
        key: 'workplace',
        content: item?.workplace,
      },
      {
        key: 'comment',
        content: item?.comment,
      },
    ],
  }));

  return (
    <div className="bg-white">
      <h3 className="font-weight-semiBold font-14 mx-4 pt-4">
        {t('pages.systemDetailsPage.contacts')}
      </h3>

      <div className="mx-4">{FilterComponent}</div>

      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        tableName="contactPersons"
        requestParams={requestParams}
        total={total}
        withStandartPagination
        isLoading={isLoading}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />
    </div>
  );
}

export default ExternalSystemContactsTable;
