import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from 'api';

import { useHistory } from 'react-router';
import useAsync from 'hooks/useAsync';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';
import InputComponent from 'components/UI/InputComponent';
import { getTooltipMessage } from 'constants/tooltips';
import DictionaryForm from './DictionaryForm';

import * as S from './styles';

const dictionariesModel = {
  attributes: [''],
};

function AddDictionaryPage() {
  const { t } = useTranslation();
  const tooltipMessage = getTooltipMessage();
  const history = useHistory();
  const { run, isSuccess, isLoading } = useAsync();

  const formMethods = useForm({
    defaultValues: dictionariesModel,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const title = t('pages.adminDictionaries.newDictionary');

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
  }, [isSuccess]);

  const onSubmit = data => {
    const formatedData = {
      name: data.name,
      attributes: data.values.map(item => ({
        name: item,
      })),
    };

    run(api.dictionaries.addDictionary(formatedData));
  };

  return (
    <PageLayout title={title} fullHeight>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <S.BlockWrapper>
            <InputComponent
              label={t('common.name')}
              name="name"
              isRequired
              tooltipMessage={tooltipMessage.requiredField}
            />
          </S.BlockWrapper>
          <DictionaryForm />
          <S.ButtonWrapper>
            <BlueButton $marginRight isDisabled={isLoading} onClick={onSubmit}>
              {t('common.add')}
            </BlueButton>
          </S.ButtonWrapper>
        </form>
      </FormProvider>
    </PageLayout>
  );
}
export default AddDictionaryPage;
