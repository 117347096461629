import i18n from '../i18n';

const { t } = i18n;

export const systemRole = {
  DEV: 'DEV',
  OPS: 'OPS',
};

export const getSystemRoleLabels = () => ({
  [systemRole.OPS]: t('constants.systemRoleLabels.OPS'),
  [systemRole.DEV]: t('constants.systemRoleLabels.DEV'),
});
