import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import Loader from 'react-loader-spinner';
import maxBy from 'lodash/maxBy';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { useWindowSize } from 'hooks/useWindowSize';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchSystems } from 'store/systems/actions';
import { resetSystems, updateSystems } from 'store/systems/reducers';
import {
  systemsSelector,
  systemsIsLoadingSelector,
  systemsMetaSelector,
  systemsErrorSelector,
} from 'store/systems/selectors';
import paths from 'constants/paths';
import {
  getSystemTypeFilterOptions,
  socketActionKeys,
  systemStatusKeys,
  getSystemStatusNames,
  systemTypesKeys,
} from 'constants/index';
import { BlueButton } from 'components/UI/Button';
import useInfinitePagination from 'components/Common/DataTable/hooks/useInfinitePagination';
import PageLayout from 'components/UI/PageLayout';

import EmptyTable from '../../components/Common/NewDataTable/EmptyTable';
import SystemComponent from './SystemComponent';

import * as S from './styles';

function MainPage() {
  const { t } = useTranslation();
  const systemStatusNames = getSystemStatusNames();
  const systemStatusFilterOptions = [
    {
      id: systemStatusKeys.NEW,
      name: systemStatusNames.NEW,
    },
    {
      id: systemStatusKeys.ACTIVE,
      name: systemStatusNames.ACTIVE,
    },
    {
      id: systemStatusKeys.UPDATING_IN_PROGRESS,
      name: systemStatusNames.UPDATING_IN_PROGRESS,
    },
    {
      id: systemStatusKeys.ARCHIVE_IN_PROGRESS,
      name: systemStatusNames.ARCHIVE_IN_PROGRESS,
      scope: scopes.platform.archiveSystemViewSection,
      isPlatformScope: true,
    },
    {
      id: systemStatusKeys.ARCHIVE,
      name: systemStatusNames.ARCHIVE,
      scope: scopes.platform.archiveSystemViewSection,
      isPlatformScope: true,
    },
    {
      id: systemStatusKeys.RESTORING_IN_PROGRESS,
      name: systemStatusNames.RESTORING_IN_PROGRESS,
      scope: scopes.platform.archiveSystemViewSection,
      isPlatformScope: true,
    },
    {
      id: systemStatusKeys.ERROR,
      name: systemStatusNames.ERROR,
    },
  ];
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const systems = useSelector(systemsSelector);
  const systemsError = useSelector(systemsErrorSelector);
  const systemsMeta = useSelector(systemsMetaSelector);
  const isLoadingSystems = useSelector(systemsIsLoadingSelector);
  const windowSize = useWindowSize();
  const [isShowFilters, setIsShowFilters] = useState(false);

  const { checkedPermissions, optionsFilterByScopes } = useUserPermissions();
  const canSystemsCreate = checkedPermissions(scopes.platform.systemCreate, true);
  const canSystemsView = checkedPermissions(scopes.platform.systemViewSection, true);

  const filterData = [
    {
      id: 'q',
      name: t('common.name'),
    },
    {
      id: 'type',
      name: t('common.type'),
      type: 'multiSelect',
      options: getSystemTypeFilterOptions(),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: optionsFilterByScopes(systemStatusFilterOptions),
    },
  ];

  const defaultFilterParams = {
    status: canSystemsView ? undefined : [systemStatusKeys.ACTIVE],
    type: canSystemsView ? undefined : [systemTypesKeys.INTERNAL_WITH_PERMISSION],
  };

  const clearSystems = useCallback(() => dispatch(resetSystems([])));

  const { filterParams, hasSelectedFilters, FilterComponent } = useMultiSelectFilterSection(
    filterData,
    'systems',
    defaultFilterParams,
    clearSystems
  );

  const requestFunction = useCallback(
    debounce(requestParameters => {
      dispatch(fetchSystems(requestParameters));
    }, 300),
    []
  );

  const requestParams = useMemo(() => {
    const { filter, ...params } = filterParams;

    return {
      ...params,
      system: systemId,
      orderBy: 'shortName',
      ascending: true,
      limit: window.screen.availHeight >= 1400 ? 20 : window.screen.availHeight >= 1000 ? 15 : 10,
    };
  }, [systemId, filterParams]);

  const rowsCount = systems?.length || 0;

  const { ref: paginationRef } = useInfinitePagination({
    rowsCount,
    withInfinitePagination: rowsCount > 0,
    loadMore: () => {
      dispatch(fetchSystems({ ...requestParams, offset: rowsCount, isConcatData: true }));
    },
    total: systemsMeta?.total,
  });

  useSocketAsync({
    topic: 'systems',
    debounceTime: 600,
    filterFn: ({ object, action }) =>
      action === socketActionKeys.CREATED || systems?.find(system => system.uuid === object?.uuid),
    onMessage: async ({ object, action }) => {
      if (!systemsMeta?.total || !systems?.length || isLoadingSystems) {
        return null;
      }

      if (action === socketActionKeys.CREATED || action === socketActionKeys.DELETED) {
        const newSystems = await api.systems.getSystems({
          ...requestParams,
          limit:
            systemsMeta?.paging.offset > 0 ? systemsMeta?.paging.offset : systemsMeta?.paging.limit,
        });

        return dispatch(resetSystems(newSystems.data.data));
      }

      try {
        if (object?.uuid) {
          const newSystem = await api.systems.getSystem(object?.uuid);

          return dispatch(updateSystems(newSystem.data));
        }
      } catch (e) {
        if (e) {
          return requestFunction({
            ...requestParams,
            limit: systemsMeta?.paging.offset,
          });
        }
      }
    },
  });

  const path = checkedPermissions(scopes.platform.externalSystemsCreate, true)
    ? paths.routePaths.newSystem
    : generatePath(paths.newSystemFullPaths.add, { systemType: paths.systemTypes.internal });

  const addSystemButton = (
    <BlueButton $marginleft component={props => <Link {...props} to={path} />}>
      {t('common.add')}
    </BlueButton>
  );

  const rightSideContent = canSystemsCreate && isShowFilters && <>{addSystemButton}</>;

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(systemsMeta?.total));
    }
  }, [systemsMeta?.total, hasSelectedFilters]);

  useEffect(() => {
    requestFunction(requestParams);
  }, [requestParams]);

  useEffect(() => {
    if (!systems?.length) {
      return;
    }

    const systemCards = document.querySelectorAll('.system-card');
    const maxHeight = maxBy(systemCards, 'clientHeight')?.clientHeight || 300;

    systemCards.forEach(systemCard => {
      systemCard.style.minHeight = `${maxHeight}px`;
    });
  }, [systems, windowSize]);

  return (
    <PageLayout mainPage isError={systemsError}>
      <header className="bg-white mb-3 px-4 pt-4">
        <S.MainTitle>
          <span className="font-weight-medium">{t('pages.home.title')}</span>
          {rightSideContent}
        </S.MainTitle>
        {isShowFilters && <div className="d-flex align-items-start">{FilterComponent}</div>}
      </header>

      <main className="position-relative px-4 height-webkit-fill-available">
        <>
          {!!systems?.length && (
            <S.SystemsWrapper>
              {systems.map(system => (
                <SystemComponent key={system.uuid} system={system} />
              ))}
              {rowsCount > 4 && <div ref={paginationRef} />}
            </S.SystemsWrapper>
          )}
          {!systems?.length && !isLoadingSystems && (
            <EmptyTable
              showNoDataPerFiltersMessage={isShowFilters}
              tableName="systems"
              addButton={addSystemButton}
            />
          )}
          {isLoadingSystems && (
            <S.SystemsLoaderWrapper>
              <Loader
                type="TailSpin"
                color="#048E94"
                height={40}
                width={40}
                style={{ AlignSelf: 'center' }}
              />
            </S.SystemsLoaderWrapper>
          )}
        </>
      </main>
    </PageLayout>
  );
}

export default MainPage;
