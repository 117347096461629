import React from 'react';
import { Controller } from 'react-hook-form';
import { Switch, Tooltip } from '@patternfly/react-core';
import { ReactComponent as QuestionCircleSVG } from 'assets/images/services/question-circle.svg';

function SwitchComponent({ field, name, label, disabled, tooltipMessage }) {
  const onChange = (_event, newIsActive) => {
    field.onChange(newIsActive);
  };

  return (
    <div className="align-items-baseline">
      <Switch
        id={name}
        key={name}
        data-input-for={label}
        label={label}
        labelOff={label}
        isChecked={Boolean(field.value)}
        onChange={onChange}
        isDisabled={disabled}
      />
      {tooltipMessage && (
        <Tooltip content={tooltipMessage} exitDelay={150} animationDuration={150}>
          <QuestionCircleSVG className="ml-1 vertical-align-middle" />
        </Tooltip>
      )}
    </div>
  );
}

const SwitchInputController = ({ name, control, rules, defaultValue, ...selectProps }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field }) => <SwitchComponent field={field} name={name} {...selectProps} />}
  />
);

export default SwitchInputController;
