import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  Badge,
  Panel,
  PanelMain,
  PanelMainBody,
  MenuContainer,
  TreeView,
  Chip,
  MenuSearch,
  MenuSearchInput,
  SearchInput,
  MenuContent,
  MenuList,
  Select,
  SelectOption,
  SelectList,
  MenuToggle,
} from '@patternfly/react-core';
import { FilterIcon } from '@patternfly/react-icons';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import { currentSystemSelector } from 'store/systems/selectors';

import useURLQueryParams from '../Location/useURLQueryParams';
import { hasSelectedFilters } from '../../utils';

import { ChipGroupStyled } from './styles';

function useTreeViewMultiSelectFilterSection(filterOptions = [], name, defaultFilterParams) {
  const { t, i18n } = useTranslation();
  const toggleRef = useRef(null);
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [chipGroups, setChipGroups] = useState({});
  const [inputValue, setInputValue] = useState(null);
  const [checkedItemsCounter, setCheckedItemsCounter] = useState(0);
  const options = filterOptions.filter(option => !option.isHidden && option.id);
  const searchOptions = filterOptions.find(({ type }) => type === 'select')?.options;

  const {
    params: { systemId },
  } = useRouteMatch();
  const { setQueryParams, getQueryByName, clearParam } = useURLQueryParams(name);

  const [selectedFiltersValues, setSelectedFilterValues] = useState({});
  const currentSystem = useSelector(currentSystemSelector);

  const hasSelectedFilterValues = useMemo(
    () => hasSelectedFilters(selectedFiltersValues),
    [selectedFiltersValues]
  );

  const treeOptions = options
    ?.filter(option => option.type === 'treeMultiSelect' && option.children?.length)
    ?.map(option => ({
      ...option,
      children: option.children.map(child => ({
        ...child,
        checkProps: {
          checked: false,
        },
        customBadgeContent: child.number,
      })),
      badgeProps: {
        className: 'd-none',
      },
    }));

  const isChecked = dataItem => checkedItems.some(item => item.id === dataItem.id);
  const areAllDescendantsChecked = dataItem =>
    dataItem.children
      ? dataItem.children.every(child => areAllDescendantsChecked(child))
      : isChecked(dataItem);
  const areSomeDescendantsChecked = dataItem =>
    dataItem.children
      ? dataItem.children.some(child => areSomeDescendantsChecked(child))
      : isChecked(dataItem);
  const flattenTree = tree => {
    let result = [];
    tree.forEach(item => {
      result.push(item);
      if (item.children) {
        result = result.concat(flattenTree(item.children));
      }
    });
    return result;
  };
  const mapTree = item => {
    const hasCheck = areAllDescendantsChecked(item);
    item.checkProps = item.checkProps || {};
    item.checkProps.checked = false;
    if (hasCheck) {
      item.checkProps.checked = true;
    } else {
      const hasPartialCheck = areSomeDescendantsChecked(item);
      if (hasPartialCheck) {
        item.checkProps.checked = null;
      }
    }
    if (item.children) {
      return {
        ...item,
        children: item.children.map(mapTree),
      };
    }
    return item;
  };
  const filterItems = (item, checkedItem) => {
    if (item.id === checkedItem.id) {
      return true;
    }

    if (item.children) {
      item.children = item.children
        .map(opt => {
          const elem = {};

          return Object.assign(elem, opt);
        })
        .filter(child => filterItems(child, checkedItem));

      return item.children.length > 0;
    }
  };
  const onCheck = (evt, treeViewItem) => {
    const { checked } = evt.target;

    let newOptions = [];
    newOptions = treeOptions;

    const checkedItemTree = newOptions
      .map(opt => {
        const elem = {};

        return Object.assign(elem, opt);
      })
      .filter(item => filterItems(item, treeViewItem));
    const flatCheckedItems = flattenTree(checkedItemTree);
    setCheckedItems(prevCheckedItems => {
      const newSelectedFiltersValues = checked
        ? prevCheckedItems.concat(
            flatCheckedItems.filter(item => !prevCheckedItems.some(i => i.id === item.id))
          )
        : prevCheckedItems.filter(item => !flatCheckedItems.some(i => i.id === item.id));

      const newItems = newSelectedFiltersValues?.filter(
        option => option.type !== 'treeMultiSelect'
      );

      setSelectedFilterValues(prev => ({
        ...prev,
        [treeViewItem.parentId || treeViewItem.id]: newItems
          .filter(
            ({ parentId }) => parentId === treeViewItem.parentId || parentId === treeViewItem.id
          )
          .map(children => children.id),
      }));

      setChipGroups(prev => ({
        ...prev,
        [treeViewItem.parentId || treeViewItem.id]: newItems
          .filter(
            ({ parentId }) => parentId === treeViewItem.parentId || parentId === treeViewItem.id
          )
          .map(item => item.name),
      }));

      return newSelectedFiltersValues;
    });
  };

  const handleTextInputChange = value => {
    setInputValue(value);
  };

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };
  const toggle = (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isOpen}
      style={{
        width: '370px',
      }}
    >
      <>
        <FilterIcon className="ml-2" />
        <span className="ml-3">{t('common.filter')}</span>
      </>
      {checkedItemsCounter > 0 && (
        <Badge isRead className="mx-2">
          {checkedItemsCounter}
        </Badge>
      )}
    </MenuToggle>
  );

  const formattedOptions = treeOptions.map(mapTree);
  const filteredOptions = childrens =>
    childrens.filter(
      elem => elem.name?.length && elem.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );

  const menuListItems = useMemo(() => {
    if (inputValue !== null) {
      const newOptions = formattedOptions.filter(
        option => filteredOptions(option.children)?.length
      );

      return newOptions?.length ? (
        newOptions.map(option => ({
          ...option,
          children: filteredOptions(option.children),
        }))
      ) : (
        <SelectOption isDisabled key="no result">
          {t('common.noResultsFound')}
        </SelectOption>
      );
    }

    return formattedOptions;
  }, [formattedOptions, inputValue]);

  const menu = (
    <Panel
      ref={menuRef}
      variant="raised"
      style={{
        width: '340px',
      }}
    >
      <MenuSearch className="px-3 pt-3">
        <MenuSearchInput>
          <SearchInput
            value={inputValue}
            aria-label="Filter menu items"
            onChange={(_event, value) => handleTextInputChange(value)}
          />
        </MenuSearchInput>
      </MenuSearch>

      {menuListItems?.length ? (
        <PanelMain>
          <section>
            <PanelMainBody
              style={{
                padding: 0,
              }}
            >
              <TreeView
                data={menuListItems}
                hasBadges
                hasCheckboxes
                onCheck={(event, item) => onCheck(event, item)}
              />
            </PanelMainBody>
          </section>
        </PanelMain>
      ) : (
        <MenuContent className="w-100 p-3 text-gray-medium">
          <MenuList>{t('common.noResultsFound')}</MenuList>
        </MenuContent>
      )}
    </Panel>
  );

  const deleteItem = (chip, group, id) => {
    setCheckedItems(prev => prev.filter(item => item.id !== chip && item.id !== id));
    setChipGroups(prev => ({ ...prev, [group]: prev[group]?.filter(item => item !== chip) }));
    setSelectedFilterValues(prev => ({
      ...prev,
      [group]: prev[group]?.filter(item => item !== chip && item !== id),
    }));
  };
  const deleteCategory = group => {
    setCheckedItems(prev => prev.filter(item => item.parentId !== group));
    setChipGroups(prev => ({ ...prev, [group]: undefined }));
    setSelectedFilterValues(prev => ({
      ...prev,
      [group]: undefined,
    }));
  };

  useEffect(() => {
    if (Object.keys(chipGroups)?.length) {
      const paramsFromUrl = getQueryByName(name);

      if (paramsFromUrl) {
        const parrents = Object.keys(paramsFromUrl)
          ?.map(key => options.find(({ id }) => id && id === key))
          .filter(item => item);

        if (parrents?.length) {
          const childrens =
            parrents.map(({ id, children }) =>
              children.filter(item => paramsFromUrl[id].includes(item.id))
            ) || [];

          setChipGroups(
            parrents.reduce(
              (acc, { id }) => ({
                ...acc,
                [id]: childrens
                  .flat()
                  .filter(({ parentId }) => parentId === id)
                  .map(item => item.name),
              }),
              {}
            )
          );
        }
      }
    }
  }, [i18n.language]);

  const ChipGroupComponent = (
    <div className="d-flex align-items-center mb-4 flex-row gap-3 flex-wrap">
      {Object.keys(chipGroups).map(group => (
        <ChipGroupStyled
          categoryName={options.find(({ id }) => id === group)?.name}
          isClosable
          onClick={() => deleteCategory(group)}
          collapsedText={
            chipGroups[group]?.length > 2
              ? `${chipGroups[group]?.length - 2} ${t('common.more')}`
              : ''
          }
          expandedText={t('common.hide')}
        >
          {chipGroups[group]?.map(chip => (
            <Chip
              key={chip}
              onClick={() =>
                deleteItem(chip, group, checkedItems.find(item => item.name === chip)?.id)
              }
            >
              {chip}
            </Chip>
          ))}
        </ChipGroupStyled>
      ))}
    </div>
  );

  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const defaultParamsFromUrl = getQueryByName(name);
  const defaultSearchKey =
    defaultParamsFromUrl &&
    Object.keys(defaultParamsFromUrl).find(key =>
      searchOptions?.find(option => option?.id === key)
    );
  const [selected, setSelected] = useState(
    defaultSearchKey
      ? searchOptions.find(({ id }) => id === defaultSearchKey)?.id
      : searchOptions?.[0]?.id
  );
  const [selectInputValue, setSelectInputValue] = useState(
    name && defaultParamsFromUrl && defaultSearchKey ? defaultParamsFromUrl[defaultSearchKey] : ''
  );

  const onSelectToggleClick = () => {
    setSelectIsOpen(!selectIsOpen);
  };
  const onSelect = (_event, value) => {
    setSelected(searchOptions.find(option => option.name === value)?.id);
    setSelectIsOpen(false);
  };
  const handleTextSelectInputChange = value => {
    setSelectInputValue(value);
  };

  const selectToggle = selectToggleRef => (
    <div className="d-flex">
      <MenuToggle
        ref={selectToggleRef}
        onClick={onSelectToggleClick}
        isExpanded={selectIsOpen}
        className="ml-3"
      >
        {searchOptions.find(({ id }) => id === selected)?.name}
      </MenuToggle>
      <MenuSearchInput style={{ width: '306px' }}>
        <SearchInput
          value={selectInputValue}
          aria-label="Filter menu items"
          onChange={(_event, value) => handleTextSelectInputChange(value)}
        />
      </MenuSearchInput>
    </div>
  );

  const FilterComponent = (
    <div className="d-flex align-items-end bg-white mb-3" style={{ height: '45px' }}>
      <div style={{ minWidth: '210px', height: '37px' }} className="d-flex flex-row ">
        <span id={name} hidden>
          {t('components.filters.tableFilters')}
        </span>
        <MenuContainer
          isOpen={isOpen}
          onOpenChange={isOpenValue => setIsOpen(isOpenValue)}
          onOpenChangeKeys={['Escape']}
          menu={menu}
          menuRef={menuRef}
          toggle={toggle}
          toggleRef={toggleRef}
        />

        <Select
          id="single-select"
          isOpen={selectIsOpen}
          selected={selected}
          onSelect={onSelect}
          onOpenChange={isOpenValue => setSelectIsOpen(isOpenValue)}
          toggle={selectToggle}
          shouldFocusToggleOnSelect
        >
          <SelectList>
            {searchOptions.map(option => (
              <SelectOption key={option?.id} value={option?.name}>
                {option?.name}
              </SelectOption>
            ))}
          </SelectList>
        </Select>
      </div>
    </div>
  );

  const searchFilter = value => {
    setSelectedFilterValues(prev => {
      const key = searchOptions.find(option => option?.id === selected)?.id;
      const keyForClear = searchOptions.find(option => option?.id !== selected)?.id;

      return {
        ...prev,
        [keyForClear]: undefined,
        [key]: value?.length ? value : undefined,
      };
    });
  };

  useEffect(() => {
    searchFilter(selectInputValue);
  }, [selectInputValue]);

  useEffect(() => {
    if (selected && selectInputValue?.length) {
      searchFilter(selectInputValue);
    }
  }, [selected]);

  useEffect(() => {
    setCheckedItemsCounter(checkedItems.filter(item => !item.type)?.length);
  }, [checkedItems]);

  useEffect(() => {
    setQueryParams(selectedFiltersValues);
  }, [selectedFiltersValues]);

  useEffect(() => {
    if (
      (currentSystem?.uuid && systemId && currentSystem?.uuid !== systemId) ||
      (systemId && !currentSystem?.uuid)
    ) {
      if (defaultFilterParams && Object.values(defaultFilterParams).find(item => item)) {
        options.forEach(option => {
          option.initialValue = defaultFilterParams[option?.id];
        });
        setSelectedFilterValues(defaultFilterParams);
      }
    }
  }, [systemId, currentSystem?.uuid]);

  useEffect(() => {
    const restoreParamsFromUrl = () => {
      if (!name) {
        return;
      }

      const paramsFromUrl = getQueryByName(name);

      if (
        !paramsFromUrl &&
        defaultFilterParams &&
        Object.values(defaultFilterParams).find(item => item)
      ) {
        options.forEach(option => {
          option.initialValue = defaultFilterParams[option?.id];
        });
        setSelectedFilterValues(defaultFilterParams);
      }

      if (paramsFromUrl) {
        options.forEach(option => {
          if (!isUndefined(paramsFromUrl[option?.id])) {
            option.initialValue = paramsFromUrl[option?.id];
          }
        });

        const parrents = Object.keys(paramsFromUrl)
          ?.map(key => options.find(({ id }) => id && id === key))
          .filter(item => item);

        if (parrents?.length) {
          const childrens =
            parrents.map(({ id, children }) =>
              children.filter(item => paramsFromUrl[id].includes(item.id))
            ) || [];

          setCheckedItems([...parrents, ...childrens.flat()]);
          setChipGroups(
            parrents.reduce(
              (acc, { id }) => ({
                ...acc,
                [id]: childrens
                  .flat()
                  .filter(({ parentId }) => parentId === id)
                  .map(item => item.name),
              }),
              {}
            )
          );
        }
      }
    };
    const checkInitialValues = () => {
      const initialFilterValues = {};
      filterOptions
        .filter(option => !isNil(option?.initialValue))
        .forEach(option => {
          initialFilterValues[option?.id] = option?.initialValue;
        });

      if (Object.keys(initialFilterValues).length) {
        setSelectedFilterValues(initialFilterValues);
      }
    };

    restoreParamsFromUrl();
    checkInitialValues();

    return () => {
      clearParam(name);
    };
  }, []);

  return {
    FilterComponent,
    ChipGroupComponent,
    filterParams: selectedFiltersValues,
    hasSelectedFilters: hasSelectedFilterValues,
  };
}

export default useTreeViewMultiSelectFilterSection;
