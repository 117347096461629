import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, generatePath, useHistory } from 'react-router';
import { Button } from '@patternfly/react-core';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import DocumentsNameButton from 'hooks/useUploadButton/DocumentsNameButton';
import useModal from 'hooks/useModal';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchServerTierDevices } from 'store/serverTierDevice/actions';
import { serverTierDevicesSelector } from 'store/serverTierDevice/selectors';
import {
  notActiveOrErrorServerTierSelector,
  notActiveServerTierSelector,
} from 'store/serverTiers/selectors';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import Dropdown from 'components/Dropdown';
import { DocumentIcon } from 'components/UI/DocumentIcon';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import PopoverButton from 'components/Common/PopoverButton';
import { DownloadButtonIcon } from 'components/UI/DownloadButtonIcon';
import { downloadFile } from 'utils';
import { limitsKeys } from 'constants';
import { useTranslation } from 'react-i18next';
import LimitsParamet from './LimitsParamet';
import DeleteEquipmentModal from '../DeleteEquipmentModal';
import { getProgressServerTierMessage } from '../../../../../../constants/tooltips';

function EquipmentTable({ currentTotal }) {
  const { t, i18n } = useTranslation();
  const tableRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { serverTierId },
  } = useRouteMatch();

  const { optionsFilterByScopes, checkedPermissions } = useUserPermissions();
  const deleteEquipment = useModal();
  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'description',
      name: t('pages.systemTierPage.additionalInfo'),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'description',
      label: t('pages.systemTierPage.additionalInfo'),
      withSort: true,
    },
    {
      key: 'limits',
      label: t('pages.systemTierPage.characteristics'),
      colSpan: '3',
    },
    {
      key: 'documents',
      label: t('common.documents'),
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
      minWidth: '130px',
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];
  const { data, total, isLoading, isError } = useSelector(serverTierDevicesSelector);
  const notActiveServerTier = useSelector(notActiveServerTierSelector);
  const notActiveServerTierMessage = useMemo(
    () => notActiveServerTier && getProgressServerTierMessage(),
    [notActiveServerTier, i18n.language]
  );
  const notActiveOrErrorServerTier = useSelector(notActiveOrErrorServerTierSelector);
  const notActiveOrErrorServerTierMessage = useMemo(
    () => notActiveOrErrorServerTier && getProgressServerTierMessage(),
    [notActiveOrErrorServerTier, i18n.language]
  );
  const [isShowFilters, setIsShowFilters] = useState(false);

  const tableFilters = useMultiSelectFilterSection(filterData, 'equipments');

  const requestFunction = params => dispatch(fetchServerTierDevices(params));
  const refreshTableData = () => tableRef.current?.requestDataWithParams();

  const onFilterChange = (value, device) => {
    if (value === 'edit') {
      return history.push(
        generatePath(paths.adminFullPaths.serverTiers + paths.serverTiersPaths.editEquipment, {
          serverTierId,
          equipmentId: device?.uuid,
          action: paths.serverTiersActions.edit,
        })
      );
    }

    deleteEquipment.setModalData({
      name: device?.name,
      serverTierUuid: serverTierId,
      equipmentUuid: device?.uuid,
    });
    deleteEquipment.toggleModal();
  };

  useEffect(() => {
    if (!isShowFilters || !currentTotal) {
      setIsShowFilters(tableFilters?.hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, tableFilters?.hasSelectedFilters]);

  const requestParams = useMemo(
    () => ({
      ...tableFilters.filterParams,
      serverTierUuid: serverTierId,
      orderBy: 'lastUpdatedAt',
      ascending: false,
      ...tableFilters.filterParams?.date,
      date: undefined,
    }),
    [serverTierId, tableFilters?.filterParams]
  );

  const optionData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'edit',
          name: t('common.edit'),
          isDisabled: notActiveServerTierMessage,
          tooltip: notActiveServerTierMessage,
          scope: scopes.platform.clusterUpdateEquipment,
          isPlatformScope: true,
        },
        {
          id: 'delete',
          name: t('common.delete'),
          isDisabled: notActiveOrErrorServerTierMessage,
          tooltip: notActiveOrErrorServerTierMessage,
          scope: scopes.platform.clusterDeleteEquipment,
          isPlatformScope: true,
        },
      ]),
    [notActiveServerTierMessage, notActiveOrErrorServerTierMessage]
  );

  const limitsContent = (resources, limitType) => (
    <LimitsParamet
      resource={resources.find(({ type }) => type === limitType)}
      limitType={limitType}
    />
  );

  const download = (uuid, name, extension) => {
    api.documents.download(uuid).then(item => {
      downloadFile(item.data, name, extension);
    });
  };

  const formattedBodyData = data?.map((device, index) => {
    const downloadAll = () => {
      device.documents.forEach(document => {
        api.documents.download(document.uuid).then(item => {
          downloadFile(item.data, document.name, document.fileName.split('.').pop());
        });
      });
    };

    const bodyContent = (
      <div className="mb-4" id="target">
        {device.documents?.map(document => (
          <div key={document?.uuid} className="d-flex align-items-start justify-content-between">
            <DocumentsNameButton id="target" file={document} isReadOnly />
            <Button
              variant="link"
              icon={<DownloadButtonIcon className="font-16 line-height-normal" />}
              className="pr-0"
              iconPosition="right"
              onClick={() => {
                download(document.uuid, document.name, document.fileName.split('.').pop());
              }}
            />
          </div>
        ))}
      </div>
    );

    const footerContent = (
      <Button variant="secondary" onClick={downloadAll}>
        {t('pages.adminServerTiers.downloadAll')}
      </Button>
    );

    const bocumentsButton = checkedPermissions(
      scopes.platform.clusterViewEquipmentDocument,
      true
    ) ? (
      <div>
        <PopoverButton
          headerContent={t('common.documents')}
          bodyContent={bodyContent}
          footerContent={footerContent}
        >
          <Button className="px-0" variant="link">
            <DocumentIcon className="mr-2" />
            <span className="font-14">{device.documents.length}</span>
          </Button>
        </PopoverButton>
      </div>
    ) : (
      <>
        <DocumentIcon className="mr-2" /> {device.documents.length}
      </>
    );

    return {
      id: `${device.uuid}${index}`,
      data: [
        {
          key: 'name',
          content: device?.name,
        },
        {
          key: 'description',
          content: device?.description || '-',
        },
        {
          key: 'limits',
          content: limitsContent(device?.limits, limitsKeys.CPU),
          rowIndex: 1,
        },
        {
          key: 'limits',
          content: limitsContent(device?.limits, limitsKeys.MEMORY),
          rowIndex: 2,
        },
        {
          key: 'limits',
          content: limitsContent(device?.limits, limitsKeys.STORAGE),
          rowIndex: 3,
        },
        {
          key: 'documents',
          content: device?.documents?.length > 0 && bocumentsButton,
        },
        {
          key: 'lastUpdatedBy',
          content: (
            <LastUpdatedStatusWithLink
              lastUpdatedBy={device.lastUpdatedBy}
              system={device.system}
              linkTo={
                device.lastUpdatedBy?.uuid
                  ? generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.details, {
                      userId: device.lastUpdatedBy.uuid,
                    })
                  : undefined
              }
              isTableLink
            />
          ),
        },
        {
          key: 'lastUpdatedAt',
          type: types.relativeTime,
          content: device.lastUpdatedAt,
        },
        ...(optionData.length
          ? [
              {
                key: 'actions',
                content: (
                  <Dropdown
                    id={`${device.uuid}${index}`}
                    onFilterChange={value => onFilterChange(value, device)}
                    optionData={optionData}
                  />
                ),
              },
            ]
          : []),
      ],
    };
  });

  return (
    <>
      {isShowFilters && (
        <div className="d-flex flex-row align-items-baseline ml-4">
          {tableFilters?.FilterComponent}
        </div>
      )}
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        tableName="equipment"
        total={total}
        withStandartPagination
        isLoading={isLoading}
        isError={isError}
        hasSelectedFilters={tableFilters?.hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
      />

      <DeleteEquipmentModal {...deleteEquipment} refreshTableData={refreshTableData} />
    </>
  );
}

export default EquipmentTable;
