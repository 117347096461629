import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownList } from '@patternfly/react-core';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';

import { declOfNum } from 'utils';

import * as S from './styles';

function ContextSelectorDropdown({
  optionData = [],
  onFilterChange,
  value,
  mini,
  width,
  setFilterSearchKey,
  checkedValue,
  firstItemIndex = 0,
}) {
  const { i18n } = useTranslation();
  const items = optionData.map(item => ({
    text: item.name,
    id: item.id,
    isDisabled: item.isDisabled,
  }));
  const firstItemText = value ? '' : items[firstItemIndex].text;
  const firstItemId = value ? '' : items[firstItemIndex].id;
  const [isOpen, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(firstItemId);
  const [selected, setSelected] = useState(firstItemText);

  useEffect(() => {
    if (value || selectedId) {
      const selectedOptionId = value || selectedId;
      const selectedItem = optionData?.find(item => item.id === selectedOptionId);
      if (selectedItem?.name) {
        setSelected(selectedItem?.name);
        setSelectedId(selectedItem?.id);

        if (setFilterSearchKey) {
          setFilterSearchKey(value);
        }
      }
    }
  }, [value, i18n.language, optionData, selectedId]);

  useEffect(() => {
    const units = optionData.find(item => item.id === value)?.units;
    if (checkedValue !== undefined && units) {
      setSelected(declOfNum(checkedValue, units, false, true));
    }
  }, [checkedValue]);

  const onToggle = open => {
    setOpen(!open);
  };

  const onSelect = valueItem => {
    const option = optionData?.find(item => item.id === valueItem);
    setSelected(option?.name);
    setSelectedId(option?.id);
    setOpen(!isOpen);
  };

  const selectOption = (newValue, isDisabled) => {
    if (isDisabled) {
      return null;
    }
    onSelect(newValue);
    onToggle(isOpen);
    onFilterChange(newValue);
  };

  return (
    <S.StyledDropdown
      $mini={mini}
      $width={width}
      isOpen={isOpen}
      onOpenChange={open => setOpen(open)}
      onOpenChangeKeys={['Escape']}
      toggle={toggleRef => (
        <S.StyledMenuToggle
          ref={toggleRef}
          onClick={() => onToggle(isOpen)}
          isExpanded={isOpen}
          $mini={mini}
          $width={width}
        >
          {selected}
        </S.StyledMenuToggle>
      )}
      id="context-selector"
      isScrollable
      zIndex="1"
    >
      <DropdownList>
        {items.map((item, index) => {
          const [text = null, href = null, isDisabled, id] = [
            item.text,
            item.href,
            item.isDisabled,
            item.id,
          ];
          return (
            <DropdownItem
              key={[index]}
              href={href}
              isDisabled={isDisabled}
              onClick={() => selectOption(id, isDisabled)}
            >
              <div className="d-flex flex-row justify-content-between w-100">
                {text}
                {(selected === text || (selected?.props?.children[0] === text && isOpen)) && (
                  <CheckIcon className="font-12 text-blu ml-2" />
                )}
              </div>
            </DropdownItem>
          );
        })}
      </DropdownList>
    </S.StyledDropdown>
  );
}

export default ContextSelectorDropdown;
