import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const serviceVersionsSlice = createSlice({
  name: 'serviceVersions',
  initialState: {
    data: [],
    total: 0,
    isLoading: true,
    isError: null,
    serviceVersionById: {},
    serviceVersionByIdError: null,
    serviceVersionByIdisLoading: true,
    serviceVersionsOptions: { data: [], onlyWithPermission: null, total: 0 },
    serviceVersionsOptionsIsLoading: false,
  },
  reducers: {
    changeCurrentServiceVersions: (state, { payload }) => {
      state.serviceVersionById = payload;
    },
    clearStateServiceVersions: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
    },
    clearServiceVersionsOptions: state => {
      state.serviceVersionsOptions.data = [];
      state.serviceVersionsOptions.total = 0;
      state.serviceVersionsOptionsIsLoading = true;
    },
  },
  extraReducers: {
    [actions.fetchServiceVersions.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchServiceVersions.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData } }
    ) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchServiceVersions.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = false;
        state.data = [];
        state.total = 0;
      }
    },
    [actions.fetchServiceVersionById.pending]: state => {
      state.serviceVersionById = {};
      state.serviceVersionByIdisLoading = true;
      state.serviceVersionByIdError = null;
    },
    [actions.fetchServiceVersionById.fulfilled]: (state, { payload }) => {
      state.serviceVersionById = payload;
      state.serviceVersionByIdisLoading = false;
      state.serviceVersionByIdError = null;
    },
    [actions.fetchServiceVersionById.rejected]: (state, { error }) => {
      state.serviceVersionByIdisLoading = false;
      state.serviceVersionByIdError = error.message;
    },
    [actions.fetchServiceVersionOptions.pending]: state => {
      state.serviceVersionsOptionsIsLoading = true;
    },
    [actions.fetchServiceVersionOptions.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData } }
    ) => {
      state.serviceVersionsOptions.data = isConcatData
        ? [...state.serviceVersionsOptions.data, ...data]
        : data;
      state.serviceVersionsOptions.total = meta.total;
      state.serviceVersionsOptionsIsLoading = false;
    },
    [actions.fetchServiceVersionOptions.rejected]: state => {
      state.serviceVersionsOptionsIsLoading = false;
    },
  },
});

export const {
  clearStateServiceVersions,
  clearServiceVersionsOptions,
  changeCurrentServiceVersions,
} = serviceVersionsSlice.actions;

export default serviceVersionsSlice.reducer;
