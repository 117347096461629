import React from 'react';
import { Button } from '@patternfly/react-core';
import MinusCircleIcon from '@patternfly/react-icons/dist/esm/icons/minus-circle-icon';

import { Wrapper } from './styles';

const DeleteButton = ({ onClick, children, isDisabled }) => {
  const BittonData = (
    <>
      {children ? (
        <Button className="p-0" variant="link" icon={<MinusCircleIcon />}>
          {children}
        </Button>
      ) : (
        <Button className="p-0" variant="plain">
          <MinusCircleIcon />
        </Button>
      )}
    </>
  );
  if (isDisabled) {
    return <Wrapper>{BittonData}</Wrapper>;
  }

  return <Wrapper onClick={onClick}>{BittonData}</Wrapper>;
};

export default DeleteButton;
