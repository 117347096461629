import styled from 'styled-components';
import ErrorIcoSVG from 'assets/images/statuses/failed.svg';
import CheckOkSVG from 'assets/images/admin/check-ok.svg';

export const AlertWrapper = styled.div`
  background: ${({ isError }) => (isError ? '#faeae8' : '#F3FAF2')};
  border-radius: 4px;
  line-height: 1.43;
  min-width: 300px;
  max-width: 450px;
  border-top: 3px solid ${({ isError }) => (isError ? '#c9190b' : '#50B047')};
`;

export const Message = styled.div`
  padding: 16px;
  font-size: 13px;
  color: #151515;
  word-break: break-all;
`;

export const Icon = styled.div`
  background: url(${({ isError }) => (isError ? ErrorIcoSVG : CheckOkSVG)}) no-repeat;
  background-size: contain;
  width: 14px;
  height: ${({ isError }) => (isError ? 14 : 10)}px;
  align-self: center;
  margin-right: 10px;
`;

export const ErrorTitle = styled.div`
  color: ${({ isError }) => (isError ? '#7d1007' : '#3E8635')};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
