import http from 'api/config';

const getAllFiles = params => http.get(`documents/search`, { params });

const download = uuid => http.get(`documents/${uuid}/download`, { responseType: 'blob' });

const deleteFile = (uuid, params) => http.delete(`documents/${uuid}`, { params });

const changeFile = (uuid, data) => http.patch(`documents/${uuid}`, data);

const upload = (file, fileName, parent, objectType, comment) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('parent', parent);
  formData.append('name', fileName.slice(0, fileName.lastIndexOf('.')));
  formData.append('objectType', objectType);
  if (comment) formData.append('comment', comment);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return http.post(`documents/upload`, formData, config);
};

export default { download, upload, deleteFile, getAllFiles, changeFile };
