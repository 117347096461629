import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, TabTitleText } from '@patternfly/react-core';

import api from 'api';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchServerTier } from 'store/serverTiers/actions';
import {
  currentServerTierErrorSelector,
  currentServerTierLoadingSelector,
  currentServerTierSelector,
} from 'store/serverTiers/selectors';
import { changeCurrentServerTier, resetCurrentServerTier } from 'store/serverTiers/reducers';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getServerTierAvailableStatuses } from 'components/Common/StatusComponent';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';
import paths from 'constants/paths';
import { socketActionKeys } from 'constants';

import ServerTierReview from './ServerTierReview';
import ServerTierEquipment from './ServerTierEquipment';
import ServerTierStands from './ServerTierStands';
import ClusterActions from '../ServerTiersPage/ClusterTable/ClusterActions';

import * as S from './styles';

function ServerTierPage({ match: { params } }) {
  const { t } = useTranslation();
  const serverTierAvailableStatuses = getServerTierAvailableStatuses();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentTab, onSelectTab } = useCardTabs();
  const { checkedPermissions } = useUserPermissions();
  const currentServerTier = useSelector(currentServerTierSelector);
  const currentServerTierLoading = useSelector(currentServerTierLoadingSelector);
  const currentServerTierError = useSelector(currentServerTierErrorSelector);

  const requestFunction = () => {
    history.push(generatePath(paths.adminFullPaths.serverTiers));
  };

  useSocketAsync({
    topic: 'server-tiers',
    filterFn: ({ object }) => currentServerTier?.uuid === object?.uuid,
    onMessage: async ({ action }) => {
      if (action === socketActionKeys.DELETED) {
        return requestFunction();
      }
      try {
        if (params.serverTierId) {
          const newServerTiers = await api.serverTiers.getServerTier(params.serverTierId);
          dispatch(changeCurrentServerTier(newServerTiers.data));
        }
      } catch (e) {
        if (e) {
          dispatch(resetCurrentServerTier());
          return requestFunction();
        }
      }
    },
  });
  const onDelete = () => history.goBack();

  useEffect(() => {
    dispatch(fetchServerTier(params.serverTierId));
  }, []);

  const leftSideContent = (
    <div className="d-flex flex-row mx-4">
      <ClusterActions cluster={currentServerTier} requestFunction={onDelete} isBlueButtonDropdown />
    </div>
  );

  const title = (
    <div className="d-flex">
      {currentServerTier?.name}
      <div className="d-flex flex-row ml-3 title-line-height">
        <StatusComponent
          statusId={currentServerTier?.status}
          statuses={serverTierAvailableStatuses}
          objectUuid={currentServerTier?.uuid}
          objectType="SERVER_TIER"
        />
      </div>
    </div>
  );

  return (
    <PageLayout
      title={title}
      withoutSidePadding
      alignSelfCenter
      isError={currentServerTierError}
      leftSideContent={leftSideContent}
      isWaitLoading={currentServerTierLoading}
    >
      <S.TabsWrapper>
        <TabsStyled activeKey={currentTab} onSelect={onSelectTab}>
          <Tab eventKey={0} title={<TabTitleText>{t('common.review')}</TabTitleText>}>
            <ServerTierReview currentServerTier={currentServerTier} />
          </Tab>
          {checkedPermissions(scopes.platform.clusterViewEquipment, true) && (
            <Tab
              eventKey={1}
              title={
                <TabTitleText className="d-flex">
                  {t('pages.adminServerTiers.equipment')}
                  <TotalSummaryBadge
                    total={currentServerTier?.totalSummary?.deviceTotal?.total}
                    isSelect={currentTab === 1}
                  />
                </TabTitleText>
              }
            >
              <ServerTierEquipment
                currentTotal={
                  currentServerTier?.uuid && currentServerTier?.totalSummary?.deviceTotal?.total
                }
              />
            </Tab>
          )}
          <Tab
            eventKey={2}
            title={
              <TabTitleText className="d-flex">
                {t('common.tiers')}
                <TotalSummaryBadge
                  total={currentServerTier?.totalSummary?.tierTotal?.total}
                  isSelect={currentTab === 2}
                />
              </TabTitleText>
            }
          >
            <ServerTierStands
              currentTotal={
                currentServerTier?.uuid && currentServerTier?.totalSummary?.tierTotal?.total
              }
            />
          </Tab>
        </TabsStyled>
      </S.TabsWrapper>
    </PageLayout>
  );
}

export default ServerTierPage;
