import React, { useEffect } from 'react';
import { generatePath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, systemsSelector } from 'store/systems/selectors';
import { fetchSystemById } from 'store/systems/actions';
import { NotificationEventKeys } from 'constants';
import { notificationNameValue } from 'constants/vaidations';
import paths from 'constants/paths';

import * as S from './styles';

const NotificationEventCheckTier = [
  NotificationEventKeys.TIER_CREATION_REQUEST,
  NotificationEventKeys.TIER_CREATION_RESPONSE,
  NotificationEventKeys.TIER_RESOURCE_LIMIT_WARNING,
  NotificationEventKeys.TIER_RESOURCE_LIMIT_ERROR,
  NotificationEventKeys.TIER_RESOURCE_LIMIT_ERROR,
  NotificationEventKeys.SERVER_TIER_RESOURCE_LIMIT_WARNING,
  NotificationEventKeys.SERVER_TIER_RESOURCE_LIMIT_ERROR,
];

const NotificationEventCheckQuotas = [
  NotificationEventKeys.TIER_QUOTA_CREATION,
  NotificationEventKeys.TIER_QUOTA_DECLINE,
  NotificationEventKeys.TIER_QUOTA_APPROVAL,
  NotificationEventKeys.TIER_QUOTA_RESPONSE,
];

const NotificationEventCheckBackup = [
  NotificationEventKeys.TIER_COMPONENT_BACKUP_ERROR,
  NotificationEventKeys.TIER_COMPONENT_BACKUP_OVERDUE,
];

function NotificationItem({ notification, systemId, toggleModal, label }) {
  const dispatch = useDispatch();
  const { hasPermissions, checkedPermissions } = useUserPermissions();
  const systems = useSelector(systemsSelector);
  const currentSystem = useSelector(currentSystemSelector);

  const notificationSystem = systems?.length ? systems : [currentSystem];

  useEffect(() => {
    if (!notificationSystem && hasPermissions) {
      dispatch(fetchSystemById({ id: systemId }));
    }
  }, []);

  const generetePathToReleases = action =>
    generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
      systemId: notification.systemId,
      releaseId: notification.linkId,
      systemType: paths.systemTypes.internal,
      action,
    });

  const CheckCurrentEvent = event => (NotificationEventCheckQuotas.includes(event) ? 0 : 1);

  const checkNotification = () => {
    if (NotificationEventCheckTier.includes(notification.event)) {
      const system = notificationSystem?.find(item => item?.uuid === notification.systemId);

      return system?.tiers?.find(tier => tier?.uuid === notification.linkId);
    }

    if (
      NotificationEventCheckQuotas.includes(notification.event) ||
      NotificationEventCheckBackup.includes(notification.event)
    ) {
      return notificationSystem.find(item =>
        item?.tiers?.find(
          tier =>
            tier?.code ===
            notification.text.match(notificationNameValue)?.[CheckCurrentEvent(notification.event)]
        )
      );
    }

    return true;
  };

  const onClickGeneratePath = () => {
    if (notification.event === NotificationEventKeys.RELEASE_CANCELLATION) {
      return generetePathToReleases(paths.releaseActions.view);
    }

    if (notification.event === NotificationEventKeys.RELEASE_APPROVAL) {
      return generetePathToReleases(
        checkedPermissions(scopes.deployment.deploymentCreateToDev)
          ? paths.releaseActions.deployToDev
          : paths.releaseActions.deploy
      );
    }

    if (notification.event === NotificationEventKeys.RELEASE_APPROVAL_REQUEST) {
      return generetePathToReleases(paths.releaseActions.approve);
    }

    if (NotificationEventCheckTier.includes(notification.event)) {
      return generatePath(paths.systemFullPaths.tier, {
        systemId: notification.systemId,
        tierId: notification.linkId,
        systemType: paths.systemTypes.internal,
      });
    }

    if (
      NotificationEventCheckQuotas.includes(notification.event) ||
      NotificationEventCheckBackup.includes(notification.event)
    ) {
      const system = notificationSystem.find(item =>
        item?.tiers.find(
          tier =>
            tier.code ===
            notification.text.match(notificationNameValue)?.[CheckCurrentEvent(notification.event)]
        )
      );

      const currentTier = system?.tiers?.find(
        tier =>
          tier.code ===
          notification.text.match(notificationNameValue)?.[CheckCurrentEvent(notification.event)]
      );

      return {
        pathname: generatePath(paths.systemFullPaths.tier, {
          systemId: notification.systemId,
          systemType: paths.systemTypes.internal,
          tierId: currentTier?.uuid,
        }),
        state: NotificationEventCheckQuotas.includes(notification.event)
          ? {
              isScrollToQuotas: true,
            }
          : {
              isScrollToBackup: true,
            },
      };
    }

    if (
      notification.event === NotificationEventKeys.RELEASE_DEPLOYMENT ||
      notification.event === NotificationEventKeys.RELEASE_DEPLOYMENT_STATUS
    ) {
      const system = notificationSystem.find(item => item?.uuid === notification.systemId);
      const currentTier = system?.tiers?.find(
        tier => tier.code === notification.text.match(notificationNameValue)?.[1]
      );

      return {
        pathname: generatePath(paths.systemPaths.deployments, {
          systemId: notification?.systemId,
          systemType: paths.systemTypes.internal,
        }),
        state: {
          deploymentId: currentTier?.uuid || notification.linkId,
        },
      };
    }

    if (notification.event === NotificationEventKeys.SERVICE_VERSION_BUILD_RESULT) {
      return {
        pathname: generatePath(paths.systemFullPaths.services, {
          systemId: notification.systemId,
          systemType: paths.systemTypes.internal,
        }),
        state: {
          isScrollToServices: true,
          searchQueryParams: notification.text.match(notificationNameValue)?.[1],
        },
      };
    }

    if (notification.event === NotificationEventKeys.SERVICE_VERSION_ANALYSIS_RESULT) {
      return {
        pathname: generatePath(paths.systemFullPaths.services, {
          systemId: notification.systemId,
          systemType: paths.systemTypes.internal,
        }),
        state: {
          isScrollToServices: true,
          searchQueryParams: notification.text.match(notificationNameValue)?.[2],
        },
      };
    }
  };

  return (
    <>
      {checkNotification() ? (
        <S.NotificationLink
          aria-labelledby={label}
          to={onClickGeneratePath()}
          onClick={toggleModal}
        >
          {notification.text}
        </S.NotificationLink>
      ) : (
        <S.NotificationText>{notification.text}</S.NotificationText>
      )}
    </>
  );
}

export default NotificationItem;
