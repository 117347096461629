import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const serverTierDeviceSlice = createSlice({
  name: 'serverTierDevice',
  currentServerTierDevice: 'null',
  initialState: { data: [], isLoading: true, total: 0, isError: null },
  extraReducers: {
    [actions.fetchServerTierDevices.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchServerTierDevices.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData } }
    ) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchServerTierDevices.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
    [actions.fetchServerTierDevice.pending]: state => {
      state.currentServerTierDevice = null;
    },
    [actions.fetchServerTierDevice.fulfilled]: (state, { payload }) => {
      state.currentServerTierDevice = payload;
    },
  },
});

export default serverTierDeviceSlice.reducer;
