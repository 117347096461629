import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const issuesSlice = createSlice({
  name: 'issues',
  initialState: { data: [], isLoading: true, total: 0, limit: null, isError: null },
  reducers: {
    clearIssuesState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.limit = null;
    },
  },
  extraReducers: {
    [actions.fetchIssues.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchIssues.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.limit = meta?.paging?.limit;
      state.isError = null;
    },
    [actions.fetchIssues.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
  },
});

export const { clearIssuesState } = issuesSlice.actions;

export default issuesSlice.reducer;
