import { createSlice } from '@reduxjs/toolkit';

const notificationsCountSlice = createSlice({
  name: 'notificationsCount',
  initialState: { data: {} },
  reducers: {
    addNotificationsCount: (state, { payload }) => {
      const newCount = Object.prototype.hasOwnProperty.call(payload, 'notificationsCount')
        ? payload.notificationsCount
        : state.data[payload.uuid] + 1;

      state.data = { ...state.data, [payload.uuid]: newCount };
    },
  },
});

export const { addNotificationsCount } = notificationsCountSlice.actions;

export default notificationsCountSlice.reducer;
