import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { declOfNum } from 'utils';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { getBackupFrequencyUnit } from 'constants';

import * as S from './styles';

function IntegarationFrequency({ parentName, withHint }) {
  const { t } = useTranslation();
  const formMethods = useFormContext();
  const frequencyValues = formMethods.watch(`${parentName}.value`);
  const backupFrequencyUnit = getBackupFrequencyUnit();
  const frequencyConvert = item => backupFrequencyUnit[item];
  const timeUnit = ['MINUTES', 'HOURS', 'DAYS', 'MONTHS', 'YEARS'];
  const timeFrequencies = timeUnit.map(item => ({
    id: item,
    name: declOfNum(frequencyValues, frequencyConvert(item), false),
  }));

  return (
    <>
      <S.EditVersionWrapper>
        <span className="mt-3">{t('pages.systemIntegrationRelationsPage.oneTimeIn')}</span>
        <S.InputComponentWrapper withHint={withHint}>
          <InputComponent
            name={`${parentName}.value`}
            component={inputComponents.numberInput}
            intNumber
            withoutErrorsMessage
          />
          {withHint && <span>{t('common.frequencyTime')}</span>}
        </S.InputComponentWrapper>
        <S.InputSelectWrapper withHint={withHint}>
          <InputComponent
            name={`${parentName}.unit`}
            component={inputComponents.newSelect}
            withoutErrorsMessage
            options={generateSelectOptions(timeFrequencies, 'id', 'name')}
          />
          {withHint && <span>{t('common.timeUnit')}</span>}
        </S.InputSelectWrapper>
      </S.EditVersionWrapper>
    </>
  );
}

export default IntegarationFrequency;
