import React from 'react';
import { Link, generatePath } from 'react-router-dom';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';

import paths from 'constants/paths';

import DevTiersLabel from '../DevTiersLabel';

function TierName({ tier }) {
  const { checkedPermissions } = useUserPermissions(tier?.system);

  const content = (
    <>
      <span className="mr-2">{tier.code}</span>
      {tier.devTier && <DevTiersLabel />}
    </>
  );

  if (!checkedPermissions(scopes.tier.tierViewForm)) {
    return content;
  }

  return (
    <Link
      to={generatePath(paths.routePaths.system + paths.systemPaths.tier, {
        systemId: tier.system.uuid,
        systemType: tier.system.type,
        tierId: tier.uuid,
      })}
    >
      {content}
    </Link>
  );
}

export default React.memo(TierName);
