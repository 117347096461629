import React from 'react';
import { Route } from 'react-router';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';

const ProtectedRoute = ({ exact, path, component: Component }) => {
  const { checkedPermissions, ErrorRedirectComponent } = useUserPermissions();

  return (
    <Route
      exact={exact}
      path={path}
      component={
        checkedPermissions(scopes.platform.platformView, true) ? Component : ErrorRedirectComponent
      }
    />
  );
};

export default ProtectedRoute;
