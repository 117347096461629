import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AdminRoute from 'routes/AdminRoute';

import { scopes } from 'constants/scopes';

import AuditPage from './AuditPage';

const AuditRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute path={path} component={AuditPage} scope={scopes.platform.auditViewSection} />

    <Redirect to={path} />
  </Switch>
);

export default AuditRouter;
