import styled, { css } from 'styled-components';

export const BlockWrapper = css`
  display: grid;
  grid-template-columns: repeat(${({ columnsCount }) => columnsCount || 1}, 1fr);
  grid-column-gap: 15px;
  position: relative;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${({ alignSelfCenter }) =>
    alignSelfCenter &&
    `  
    align-self: center !important;
    align-self: stretch;
    `}
`;

export const SpaceBeetweenRowView = styled(RowView)`
  justify-content: space-between;

  ${({ baselineTop }) => baselineTop && 'align-items: baseline;'}
`;

export const InformationBlockWrapper = styled.div`
  ${BlockWrapper}
  margin-top: 16px;
  width: 100%;
`;

export const InputBlockWrapper = styled.div`
  ${BlockWrapper}
`;

export const InputsBlockWrapper = styled.div`
  ${BlockWrapper}
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled(RowView)`
  margin: 50px 0 20px;
  gap: 16px;
`;

export const SmallButtonsWrapperLeftMargin = styled(RowView)`
  margin: 32px 0 20px 58px;
`;

export const SmallModalForm = styled.form`
  width: 440px;
  padding: 24px 24px 10px;
  margin: 0;
`;

export const ModalBlockTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
