import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useModal from 'hooks/useModal';
import { serviceVersionsUnitTestingSelector } from 'store/serviceVersionsBuildResults/selectors';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import StatusComponent, { getUnitTestingStatuses } from 'components/Common/StatusComponent';
import TruncateWithTooltipText from 'components/Common/TruncateWithTooltipText';
import ModalInfo from 'components/Common/ModalInfo';
import TextBlock from 'components/UI/TextBlock';
import { unitTestingStatusKeys } from 'constants';
import { useTranslation } from 'react-i18next';

function ServiceVersionsUnitTestingTable({
  requestParams,
  requestFunction,
  hasSelectedFilters,
  tableName,
}) {
  const { t } = useTranslation();
  const unitTestingStatuses = getUnitTestingStatuses();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal();
  const { data, isLoading, total, isError } = useSelector(serviceVersionsUnitTestingSelector);
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'className',
      label: t('pages.systemServiceVersionPage.class'),
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'description',
      label: t('common.description'),
    },
    {
      key: 'time',
      label: t('pages.systemServiceVersionPage.executionTime'),
    },
  ];
  const onInfoClick = item => {
    setModalData(item);
    toggleModal();
  };

  const formattedBodyData = data?.map((item, index) => ({
    id: `${item?.uuid}${index}`,
    data: [
      {
        key: 'name',
        content: item.name,
      },
      {
        key: 'className',
        content: <TruncateWithTooltipText content={item.className} />,
      },
      {
        key: 'status',
        content: item?.status && (
          <StatusComponent statusId={item.status} statuses={unitTestingStatuses} />
        ),
      },
      {
        key: 'description',
        content:
          item?.status === unitTestingStatusKeys.ERROR
            ? item?.type || '-'
            : (item?.status !== unitTestingStatusKeys.SKIPPED && item?.message) || '-',
        type: (item?.message || item?.type) && item?.description && types.textLink,
        onClick: () => onInfoClick(item),
      },
      {
        key: 'time',
        content: moment
          .utc(moment.duration(item.time, 'seconds').asMilliseconds())
          .format('HH:mm:ss:SSS'),
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName={tableName}
        withStandartPagination
        total={total}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />
      <ModalInfo isModalVisible={isModalVisible} toggleModal={toggleModal} title={modalData?.name}>
        <TextBlock
          greyColor
          title={
            modalData?.status === unitTestingStatusKeys.ERROR
              ? t('common.error')
              : t('pages.systemServiceVersionPage.message')
          }
        >
          {modalData?.status === unitTestingStatusKeys.ERROR
            ? modalData?.type || '-'
            : modalData?.message || '-'}
        </TextBlock>
        <TextBlock greyColor title={t('common.description')}>
          <div className="pre-line">{modalData?.description}</div>
        </TextBlock>
      </ModalInfo>
    </>
  );
}

export default ServiceVersionsUnitTestingTable;
