import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { currentSystemSelector } from 'store/systems/selectors';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { getFilteredOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VectorLeftSvg } from 'assets/images/integrations/VectorLeft.svg';
import { ReactComponent as VectorRightSvg } from 'assets/images/integrations/VectorRight.svg';

import * as S from '../styles';

function SystemSelection({ setCurrentStepId, isEditAction }) {
  const { t } = useTranslation();
  const {
    params: { systemId, integrationId },
  } = useRouteMatch();
  const titleMessage = t('pages.systemIntegrationRelationsPage.chooseSystem');
  const systemOptions = useAsync();
  const currentSystem = useSelector(currentSystemSelector);
  const [currentSearch, setCurrentSearch] = useState(null);

  const { setValue, watch } = useFormContext();

  const isInbound = watch(`inbound`);
  const isSystemUuid = watch(`systemUuid`);
  const checkedSystem = systemOptions.data?.data.find(item => item.uuid === isSystemUuid);

  const isSearchLongEnough = currentSearch?.length > 0 || (isSystemUuid && !currentSearch);

  const getUsersOptions = useMemo(
    () =>
      generateSelectOptions(systemOptions.data?.data, null, null, null, {
        getLabel: system => system.shortName || system.name,
        getValue: system => system.uuid,
        getDescription: system => system.name,
      }),
    [systemOptions.data?.data, currentSearch]
  );

  useEffect(() => {
    if (isSystemUuid && systemOptions?.data?.data?.length) {
      setCurrentSearch(systemOptions.data.data.find(item => item.uuid === isSystemUuid).shortName);
    }
  }, [isSystemUuid, systemOptions.data?.data, currentSearch]);

  useEffect(() => {
    if (isSearchLongEnough) {
      systemOptions.run(
        api.systems.getSystemOptions({
          ascending: true,
          orderBy: 'shortName',
          excludeSystem: systemId,
          q: currentSearch,
        })
      );
    }
  }, [isSearchLongEnough]);

  useEffect(() => {
    setValue(`inbound`, isInbound || false);
    setCurrentStepId(1);
  }, []);

  useEffect(() => {
    if (!isInbound && isSystemUuid) {
      if (currentSystem) {
        setValue(`systemFrom`, { uuid: currentSystem.uuid, name: currentSystem.shortName });
      }

      setValue(`systemTo`, { uuid: isSystemUuid || undefined, name: checkedSystem?.shortName });
    }

    if (isInbound && checkedSystem) {
      setValue(`systemFrom`, {
        uuid: isSystemUuid || undefined,
        name: checkedSystem?.shortName,
      });

      if (currentSystem) {
        setValue(`systemTo`, { uuid: currentSystem.uuid, name: currentSystem?.shortName });
      }
    }
  }, [isInbound, isSystemUuid, currentSystem, checkedSystem]);

  const onButtonLeftClick = e => {
    if (isEditAction) {
      return;
    }

    e.preventDefault();
    setValue(`inbound`, false);
  };

  const onButtonRightClick = e => {
    if (isEditAction) {
      return;
    }

    e.preventDefault();
    setValue(`inbound`, true);
  };

  const noResultsText = isSearchLongEnough
    ? t('common.noResults')
    : t('common.insertSearchCriteria');

  return (
    <>
      <div className="font-20 font-weight-medium">{t('common.informationalSystem')}</div>

      <S.MessageWrapper>{titleMessage}</S.MessageWrapper>

      <div className="mt-4">
        <InputComponent
          label={t('pages.systemIntegrationRelationsPage.systemList')}
          name="systemUuid"
          component={inputComponents.newSelect}
          isLoading={systemOptions.isLoading}
          newSelectVariant="typeahead"
          newSelectOnFilter={getFilteredOptions}
          onTypeaheadInputChanged={setCurrentSearch}
          noResultsText={noResultsText}
          placeholder={t('common.searchByNameAndDescription')}
          isRequired
          options={getUsersOptions}
          disabled={isEditAction}
          checkedValue={isSystemUuid}
        />
      </div>

      <InputComponent
        name="uuid"
        component={inputComponents.hiddenInput}
        defaultValue={integrationId || uuid()}
      />

      <div>
        <S.SectionTitle>
          {t('pages.systemIntegrationRelationsPage.directionOfIntegration')}
        </S.SectionTitle>
        <S.DirectionButtonWrapper>
          <S.DirectionButton
            isActive={!isInbound}
            isDisabled={isEditAction}
            onClick={onButtonLeftClick}
          >
            <VectorRightSvg />
            {t('pages.systemIntegrationRelationsPage.outcome')}
          </S.DirectionButton>
          <S.DirectionButton
            isActive={isInbound}
            isDisabled={isEditAction}
            onClick={onButtonRightClick}
          >
            <VectorLeftSvg />
            {t('pages.systemIntegrationRelationsPage.income')}
          </S.DirectionButton>
        </S.DirectionButtonWrapper>
      </div>
    </>
  );
}

export default SystemSelection;
