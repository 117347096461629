import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import AdminRoute from 'routes/AdminRoute';
import { scopes } from 'constants/scopes';

import ServerTiersPage from './ServerTiersPage';
import ServerTierPage from './ServerTierPage';
import ChangeServerTierPage from './ChangeServerTierPage';
import ChangeEquipmentPage from './ChangeEquipmentPage';

const ServerTiersRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute
      exact
      path={`${path}${paths.serverTiersPaths.new}`}
      component={ChangeServerTierPage}
      scope={scopes.platform.clusterCreate}
    />

    <AdminRoute
      exact
      path={`${path}${paths.serverTiersPaths.show}`}
      component={ServerTierPage}
      scope={scopes.platform.clusterViewForm}
    />

    <AdminRoute
      exact
      path={`${path}${paths.serverTiersPaths.edit}`}
      component={ChangeServerTierPage}
      scope={scopes.platform.clusterUpdate}
    />

    <AdminRoute
      exact
      path={`${path}${paths.serverTiersPaths.newEquipment}`}
      component={ChangeEquipmentPage}
      scope={scopes.platform.clusterAddEquipment}
    />

    <AdminRoute
      exact
      path={`${path}${paths.serverTiersPaths.editEquipment}`}
      component={ChangeEquipmentPage}
      scope={scopes.platform.clusterUpdateEquipment}
    />

    <Route exact path={path} component={ServerTiersPage} />

    <Redirect to={path} />
  </Switch>
);

export default ServerTiersRouter;
