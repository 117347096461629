import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionList, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import {
  dateTimeConverter,
  declOfNum,
  convertValueToBytes,
  convertValueToMillicores,
  getConvertibleCpuQuantities,
} from 'utils';
import uuid from 'react-uuid';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useAvailableResources from 'hooks/useAvailableResources';
import useUploadButton from 'hooks/useUploadButton';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import LimitsBlock from 'hooks/useAvailableResources/LimitsBlock';
import useSocketAsync from 'hooks/useSocketAsync';
import { notActiveSystemSelector } from 'store/systems/selectors';
import { fetchTierById } from 'store/tiers/actions';
import { currentTierIsLoadingSelector, currentTierSelector } from 'store/tiers/selectors';
import { clearCurrentTier } from 'store/tiers/reducers';
import { fetchQuoteRequest } from 'store/tierQuotes/actions';
import { clearQuotesState, clearTierQuotesState } from 'store/tierQuotes/reducers';
import {
  tierQuoteRequestIsLoadingSelector,
  tierQuoteRequestSelector,
} from 'store/tierQuotes/selectors';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton, TextButton } from 'components/UI/Button';
import { ButtonsWrapper, InputsBlockWrapper } from 'components/UI/View';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import StatusComponent, {
  getServerTierAvailableStatuses,
  getTierAvailableStatuses,
} from 'components/Common/StatusComponent';
import ContextSelectorDropdown from 'components/ContextSelectorDropdown';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import TextBlock from 'components/UI/TextBlock';
import {
  getDefaultNumberValidateMessage,
  quoteValidation,
  tooltipFormDisableMessage,
} from 'constants/vaidations';
import paths from 'constants/paths';
import {
  cpuUnitKeys,
  unitMemoryDropdownOptions,
  limitsKeys,
  documentsTypeKeys,
  tierStatusKeys,
  socketActionKeys,
  systemTypesKeys,
  serverTierStatusKeys,
  getLimitsLabels,
  getQuoteOptions,
  unitMemoryKeys,
} from 'constants';
import {
  getQuotaInProgressMessage,
  getProgressTierMessage,
  getRequireApprovalMessage,
  getSubmitForQuotaApprovalUnavailable,
  getServerTierWarningMessage,
  getProgressSystemMessage,
} from 'constants/tooltips';
import { useTranslation } from 'react-i18next';
import DevTiersLabel from '../SystemTiersPage/TiersTable/DevTiersLabel';

import * as S from './styles';

const defaultValues = {
  currentCpu: {},
  currentMemory: {},
  currentStorage: {},
  CPU: '',
  MEMORY: '',
  STORAGE: '',
  description: '',
  documents: [],
};
const examples = ['-10', '-1', '+1', '+10'];

function QuotePage() {
  const { t, i18n } = useTranslation();
  const convertibleCpuQuantities = getConvertibleCpuQuantities();
  const quotaInProgressMessage = getQuotaInProgressMessage();
  const progressTierMessage = getProgressTierMessage();
  const requireApprovalMessage = getRequireApprovalMessage();
  const submitForQuotaApprovalUnavailable = getSubmitForQuotaApprovalUnavailable();
  const serverTierWarningMessage = getServerTierWarningMessage();
  const quoteOptions = getQuoteOptions();
  const serverTierAvailableStatuses = getServerTierAvailableStatuses();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const limitsLabels = getLimitsLabels();
  const history = useHistory();
  const dispatch = useDispatch();
  const { checkedPermissions } = useUserPermissions();
  const {
    params: { systemId, tierId },
  } = useRouteMatch();
  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getQuota = useAsync();
  const { run, isLoading, isSuccess } = useAsync();
  const isQuotaInProgress = useAsync();
  const currentTier = useSelector(currentTierSelector);
  const currentTierIsLoading = useSelector(currentTierIsLoadingSelector);
  const quoteRequestData = useSelector(tierQuoteRequestSelector);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const tierQuoteRequestIsLoading = useSelector(tierQuoteRequestIsLoadingSelector);

  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const [isClearFormState, setIsClearFormState] = useState(null);
  const [isQuoteRequest, setIsQuoteRequest] = useState(false);

  const quoteRequest = currentTier?.totalSummary?.quotaTotal?.requireApproval
    ? quoteRequestData
    : null;
  const isEditableForm = currentTier?.uuid && !quoteRequest;
  const isArchiveTier = currentTier?.status === tierStatusKeys.ARCHIVE;
  const isActiveTier = currentTier?.status === tierStatusKeys.ACTIVE;
  const isHaveQuotaApproval =
    !!currentTier?.totalSummary?.quotaTotal?.requireApproval && submitForQuotaApprovalUnavailable;
  const buttonText = checkedPermissions(scopes.tier.tierChangeQuota)
    ? quoteOptions.change.name
    : checkedPermissions(scopes.tier.tierRequestQuota) && quoteOptions.request.name;
  const getLimitValue = key => quoteRequest?.limits.find(({ type }) => type === key);
  const newQuoteParams = { limit: 1, tier: tierId, ascending: false, status: 'NEW' };
  const progressQuoteParams = {
    limit: 1,
    tier: tierId,
    ascending: false,
    status: 'IN_PROGRESS',
  };

  const formValue = formMethods.getValues();
  const currentCpu = formMethods.watch('currentCpu');
  const currentMemory = formMethods.watch('currentMemory');
  const currentStorage = formMethods.watch('currentStorage');
  const cpuValue = formMethods.watch('CPU');
  const memoryValue = formMethods.watch('MEMORY');
  const storageValue = formMethods.watch('STORAGE');
  const cpuUnit = formMethods.watch('cpuUnit');
  const memoryUnit = formMethods.watch('memoryUnit');
  const storageUnit = formMethods.watch('storageUnit');
  const uuidValue = formMethods.watch('uuid');

  const { UploadButtonComponent, setFilesToShow } = useUploadButton({
    isReadOnly: !isEditableForm,
    objectType: documentsTypeKeys.QUOTA_REQUEST,
    setValue: formMethods.setValue,
    filesToShow: quoteRequest?.documents || [],
    isAddDocumentPage: true,
    descriptionMaxLength: 500,
  });

  function requestFunction() {
    dispatch(fetchTierById({ systemId, tierId }));
  }

  const {
    inputsBlock,
    warningText,
    serverTiersWarningText,
    loading,
    availableResources,
    tooltipWarningText,
  } = useAvailableResources(
    formMethods,
    {
      uuid: currentTier?.serverTier?.uuid,
      name: currentTier?.serverTier?.name,
    },
    '',
    storageValue
  );
  const availableStorage = availableResources?.STORAGE?.total || 0;
  const serverTierDisabledMessage = useMemo(
    () =>
      currentTier?.system.type === systemTypesKeys.INTERNAL &&
      currentTier?.serverTier?.status !== serverTierStatusKeys.ACTIVE
        ? t('validation.incorrectServerTierStatus')
        : undefined,
    [currentTier, i18n.language]
  );

  const goBack = () => history.goBack();

  const getQuotaInProgress = () =>
    isQuotaInProgress.run(api.quotas.getQuotaRequests(progressQuoteParams));

  useSocketAsync({
    topic: 'tiers',
    filterFn: ({ object }) => tierId === object?.uuid,
    onMessage: async message => {
      if (message.action === socketActionKeys.DELETED) {
        return goBack();
      }

      const newTier = await api.tiers.getTier({
        systemId,
        tierId,
      });

      return dispatch(changeCurrentTier(newTier.data));
    },
  });

  useSocketAsync({
    topic: 'tiers/quotas',
    filterBySystemUuid: systemId,
    filterFn: ({ object }) => object.tier?.uuid === tierId,
    options: { callBackThrottleTime: 1000 },
    onMessage: async ({ object }) => {
      if (quoteRequest && quoteRequestData?.uuid === object?.uuid) {
        dispatch(fetchQuoteRequest(newQuoteParams));
      }

      getQuotaInProgress();

      const response = await api.quotas.getQuotaRequests(newQuoteParams);

      setIsQuoteRequest(!!response.data?.meta?.total);
    },
  });

  useEffect(() => {
    dispatch(fetchQuoteRequest(newQuoteParams)).then(() => {});

    if (!currentTier?.uuid) {
      requestFunction();
    }

    if (tierId) {
      getQuotaInProgress();
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(clearQuotesState());
      dispatch(clearCurrentTier());
      dispatch(clearTierQuotesState());
    },
    []
  );

  useEffect(() => {
    if (quoteRequest?.uuid && isClearFormState === null) {
      setFilesToShow(quoteRequest.documents, true);
      setIsClearFormState(false);
    }
    if (!quoteRequest?.uuid && isClearFormState === false) {
      setIsClearFormState(true);
    }
  }, [buttonText, quoteRequest]);

  useEffect(() => {
    if (isClearFormState) {
      formMethods.reset({
        CPU: getLimitValue('CPU') || cpuValue?.value || cpuValue,
        MEMORY: getLimitValue('MEMORY') || memoryValue?.value || memoryValue,
        STORAGE: getLimitValue('STORAGE') || storageValue?.value === 0 ? 0 : storageValue,
        uuid: uuid(),
        createdAt: '',
        createdBy: '',
        documents: [],
        description: '',
        documentsDescription: '',
      });
      setFilesToShow([], true);
      setIsClearFormState(null);
    }
  }, [isClearFormState]);

  useEffect(() => {
    if (currentTier?.uuid) {
      getQuota.run(api.quotas.getQuota({ systemId, tierId: currentTier.uuid }));
      isQuotaInProgress.run(api.quotas.getQuotaRequests(progressQuoteParams));
    }
  }, [currentTier?.uuid]);

  useEffect(() => {
    if (currentTier) {
      formMethods.reset({
        ...formValue,
        CPU: getLimitValue('CPU') || cpuValue?.value || cpuValue,
        MEMORY: getLimitValue('MEMORY') || memoryValue?.value || memoryValue,
        STORAGE: getLimitValue('STORAGE') || storageValue?.value === 0 ? 0 : storageValue,
        uuid: uuidValue || uuid(),
        createdAt: dateTimeConverter(quoteRequest?.createdAt),
        createdBy: quoteRequest?.createdBy.login || '',
        documents: quoteRequest?.documents || formValue?.documents || [],
        description: quoteRequest?.description || formValue?.description || '',
        documentsDescription:
          quoteRequest?.documents?.find(item => item.description)?.description ||
          formValue?.documentsDescription ||
          '',
      });
    }
  }, [currentTier, quoteRequest]);

  useEffect(() => {
    if (getQuota.data?.limits) {
      const currentQuotaCpu = getQuota.data?.limits.find(({ type }) => type === limitsKeys.CPU);
      const currentQuotaMemory = getQuota.data?.limits.find(
        ({ type }) => type === limitsKeys.MEMORY
      );
      const currentQuotaStorage = getQuota.data?.limits.find(
        ({ type }) => type === limitsKeys.STORAGE
      );

      formMethods.reset({
        ...formValue,
        currentCpu: currentQuotaCpu,
        currentMemory: currentQuotaMemory,
        currentStorage: currentQuotaStorage,
        CPU:
          cpuValue?.value ||
          cpuValue ||
          getQuota.data?.limits.find(({ type }) => type === limitsKeys.CPU)?.value,
        MEMORY:
          memoryValue?.value ||
          memoryValue ||
          getQuota.data?.limits.find(({ type }) => type === limitsKeys.MEMORY)?.value,
        STORAGE:
          storageValue?.value === 0
            ? 0
            : getQuota.data?.limits.find(({ type }) => type === limitsKeys.STORAGE)?.value,
        cpuUnit: cpuUnit || currentQuotaCpu?.unitType,
        memoryUnit:
          memoryUnit ||
          unitMemoryDropdownOptions.find(item => item.unit === currentQuotaMemory?.unitType)?.name,
        storageUnit:
          storageUnit ||
          unitMemoryDropdownOptions.find(item => item.unit === currentQuotaStorage?.unitType)?.name,
      });
    }
  }, [getQuota.data?.limits]);

  const updateFunction = () => {
    if (currentTier?.uuid) {
      history.push({
        pathname: generatePath(paths.routePaths.system + paths.systemPaths.tier, {
          systemId: currentTier.system.uuid,
          systemType: currentTier.system.type,
          tierId: currentTier.uuid,
        }),
        state: {
          currentTab: 2,
        },
      });
    }
  };

  const rejectQuote = async e => {
    e.preventDefault();
    await api.quotas.rejectTierQuota(currentTier.uuid, quoteRequest.uuid);
    formMethods.reset();
    updateFunction();
  };

  const acceptQuote = async e => {
    e.preventDefault();
    await api.quotas.approveTierQuota(currentTier.uuid, quoteRequest.uuid);
    formMethods.reset();
    updateFunction();
  };

  useEffect(() => {
    if (isSuccess) {
      updateFunction();
    }
  }, [isSuccess]);

  const title = !isEditableForm
    ? t('pages.systemTierPage.resourcesQuota')
    : checkedPermissions(scopes.tier.tierChangeQuota)
    ? t('pages.systemTierPage.quotaChange')
    : t('pages.systemTierPage.quotaRequest');

  const onChangeUnitValue = (value, name) => {
    if (value) {
      formMethods.setValue(`${name}`, value);
    }
  };

  const cpuOptions = useMemo(
    () => [
      {
        id: cpuUnitKeys.millicores,
        name: declOfNum(
          cpuValue,
          convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.millicores).unit,
          false,
          true
        ),
        units: convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.millicores).unit,
      },
      {
        id: cpuUnitKeys.cores,
        name: declOfNum(
          cpuValue,
          convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.cores).unit,
          false,
          true
        ),
        units: convertibleCpuQuantities.find(item => item.id === cpuUnitKeys.cores).unit,
      },
    ],
    [cpuValue, i18n.language]
  );

  const valueComparison = (item, value, unit) => {
    const checledValue =
      item?.type === limitsKeys.CPU
        ? convertValueToMillicores(item?.value, item?.unitType)
        : convertValueToBytes(item?.value, item?.unitType);
    const fonmatValue =
      item?.type === limitsKeys.CPU
        ? convertValueToMillicores(value, unit)
        : convertValueToBytes(
            parseFloat(value),
            unitMemoryDropdownOptions.find(option => option.id === unit)?.unit
          );

    if (!checledValue || (!value && value !== '0') || checledValue === fonmatValue) {
      return null;
    }

    if (fonmatValue >= 0) {
      return checledValue < fonmatValue;
    }
  };

  const changeDisabledMessage = useMemo(() => {
    if (notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (serverTierDisabledMessage) {
      return serverTierDisabledMessage;
    }

    if (!isActiveTier) {
      return progressTierMessage;
    }

    if (isQuotaInProgress?.data?.meta.total > 0 && buttonText !== quoteOptions.request.name) {
      return quotaInProgressMessage;
    }

    if (serverTiersWarningText) {
      return serverTierWarningMessage;
    }

    if (checkedPermissions(scopes.tier.tierChangeQuota) && (tooltipWarningText || loading)) {
      return tooltipWarningText;
    }

    if (isQuoteRequest) {
      return requireApprovalMessage;
    }

    if (isHaveQuotaApproval && isEditableForm) {
      return isHaveQuotaApproval;
    }
  }, [
    isQuoteRequest,
    notActiveSystemMessage,
    isActiveTier,
    serverTiersWarningText,
    isQuotaInProgress?.data?.meta.total,
    buttonText,
    tooltipWarningText,
    isHaveQuotaApproval,
    isEditableForm,
    i18n.language,
  ]);
  const isDisabledForm =
    changeDisabledMessage ||
    !formMethods.formState.isValid ||
    serverTiersWarningText ||
    (warningText && buttonText !== quoteOptions.request.name);

  const onSubmit = ({ description, documentsDescription, documents = [], ...data }) => {
    if (isDisabledForm) {
      return;
    }

    const formatedDocuments = documents.map(file => ({
      ...file,
      description: documentsDescription,
    }));

    const formatedData = {
      documents: formatedDocuments,
      description,
      limits: [
        { type: limitsKeys.CPU, value: data.CPU, unitType: data.cpuUnit },
        {
          type: limitsKeys.MEMORY,
          value: data.MEMORY,
          unitType: unitMemoryDropdownOptions.find(item => item.id === data.memoryUnit)?.unit,
        },
        {
          type: limitsKeys.STORAGE,
          value: data?.STORAGE || 0,
          unitType:
            unitMemoryDropdownOptions.find(item => item.id === data.storageUnit)?.unit ||
            unitMemoryKeys.gib,
        },
      ],
      tier: {
        uuid: currentTier.uuid,
      },
      uuid: data.uuid,
    };

    run(api.quotas.addTierQuota(formatedData));
  };

  return (
    <PageLayout
      title={title}
      withoutSidePadding
      withSystemSelector
      isWaitLoading={
        currentTierIsLoading ||
        getQuota.isLoading ||
        tierQuoteRequestIsLoading ||
        !currentTier?.uuid
      }
      isLoading={currentTierIsLoading || (!currentTierIsLoading && !currentTier?.uuid)}
    >
      <S.Content>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className="mb-4">
              <TextBlock greyColor title={t('common.serverTier')} isForm>
                <LinkWithPermission
                  system={currentTier?.system}
                  scope={scopes.platform.clusterViewForm}
                  isPlatformScope
                  to={
                    currentTier?.serverTier.uuid
                      ? generatePath(
                          paths.adminFullPaths.serverTiers + paths.serverTiersPaths.show,
                          {
                            serverTierId: currentTier.serverTier.uuid,
                          }
                        )
                      : undefined
                  }
                >
                  <StatusComponent
                    statusId={currentTier?.serverTier.status}
                    statusWithTooltip
                    statuses={serverTierAvailableStatuses}
                  >
                    {currentTier?.serverTier.name}
                  </StatusComponent>
                </LinkWithPermission>
              </TextBlock>
            </div>

            {checkedPermissions(scopes.tier.tierChangeQuota) && (
              // TO DO: change scope after task 248556
              <>
                <h3 className="font-14 font-weight-bold mb-3">
                  {t('pages.systemTierPage.availableResources')}
                </h3>
                {currentTier?.uuid && serverTiersWarningText ? (
                  <>{serverTiersWarningText}</>
                ) : (
                  <InputsBlockWrapper columnsCount={3}>{inputsBlock}</InputsBlockWrapper>
                )}
              </>
            )}

            <div className="position-relative">
              <S.Line />
            </div>

            <div className="pt-4">
              <TextBlock greyColor title={t('common.tier')} isForm>
                <TextButton
                  variant="link"
                  component={props => (
                    <Link
                      {...props}
                      to={
                        currentTier?.uuid
                          ? generatePath(paths.systemFullPaths.tier, {
                              systemId: currentTier.system.uuid,
                              systemType: currentTier.system.type,
                              tierId: currentTier.uuid,
                            })
                          : undefined
                      }
                    />
                  )}
                >
                  <div className="d-flex">
                    <StatusComponent
                      statusId={currentTier?.status}
                      statusWithTooltip
                      statuses={tierAvailableStatuses}
                    >
                      {currentTier?.code}
                    </StatusComponent>
                    {currentTier?.devTier && <DevTiersLabel />}
                  </div>
                </TextButton>
              </TextBlock>
            </div>

            <h3 className="font-14 font-weight-bold mb-3 pt-4">
              {t('pages.systemTierPage.quota')}
            </h3>
            <InputsBlockWrapper columnsCount={3}>
              {isEditableForm ? (
                <>
                  <InputComponent
                    label={limitsLabels.CPU}
                    name="CPU"
                    component={inputComponents.numberInput}
                    isRequired={isEditableForm}
                    disabled={!isEditableForm}
                    validationRules={
                      checkedPermissions(scopes.tier.tierChangeQuota) && quoteValidation
                    }
                    isGreaterStatus={valueComparison(currentCpu, cpuValue, cpuUnit)}
                    intNumber
                    dropdown={
                      <ContextSelectorDropdown
                        optionData={cpuOptions}
                        onFilterChange={e => onChangeUnitValue(e, 'cpuUnit')}
                        value={cpuUnit}
                        checkedValue={cpuValue}
                        width="65%"
                      />
                    }
                    errorText={t('validation.fillRequiredField')}
                    description={getDefaultNumberValidateMessage()}
                    examples={examples}
                  />

                  <InputComponent
                    label={limitsLabels.MEMORY}
                    name="MEMORY"
                    component={inputComponents.numberInput}
                    isRequired={isEditableForm}
                    intNumber
                    disabled={!isEditableForm}
                    validationRules={
                      checkedPermissions(scopes.tier.tierChangeQuota) && quoteValidation
                    }
                    isGreaterStatus={valueComparison(currentMemory, memoryValue, memoryUnit)}
                    dropdown={
                      <ContextSelectorDropdown
                        optionData={unitMemoryDropdownOptions}
                        onFilterChange={e => onChangeUnitValue(e, 'memoryUnit')}
                        value={memoryUnit}
                        width="40%"
                      />
                    }
                    errorText={t('validation.fillRequiredField')}
                    description={getDefaultNumberValidateMessage()}
                    examples={examples}
                  />

                  <InputComponent
                    label={limitsLabels.STORAGE}
                    name="STORAGE"
                    component={inputComponents.numberInput}
                    isRequired={(storageValue !== 0 && availableStorage > 1) || !isEditableForm}
                    disabled={
                      availableStorage < 1 &&
                      checkedPermissions(scopes.platform.clusterViewMonitoring, true)
                    }
                    isGreaterStatus={valueComparison(currentStorage, storageValue, storageUnit)}
                    intNumber
                    dropdown={
                      <ContextSelectorDropdown
                        optionData={unitMemoryDropdownOptions}
                        onFilterChange={e => onChangeUnitValue(e, 'storageUnit')}
                        value={storageUnit}
                        width="40%"
                      />
                    }
                    errorText={t('validation.fillRequiredField')}
                    description={getDefaultNumberValidateMessage()}
                    examples={examples}
                  />
                </>
              ) : (
                <LimitsBlock
                  limits={{
                    CPU: getLimitValue('CPU'),
                    MEMORY: getLimitValue('MEMORY'),
                    STORAGE: getLimitValue('STORAGE'),
                  }}
                  numbersAfterComma={1}
                />
              )}
            </InputsBlockWrapper>
            {checkedPermissions(scopes.tier.tierChangeQuota) && warningText}

            {!isEditableForm && (
              <div className="my-4">
                <div className="font-14 font-weight-medium mb-2">
                  {t('pages.systemTierPage.quotaRequestedBy')}
                </div>
                <LastUpdatedStatusWithLink
                  lastUpdatedBy={quoteRequest?.createdBy}
                  lastUpdatedAt={quoteRequest?.createdAt}
                  font="font-16"
                />
              </div>
            )}

            <div className="my-4">
              <InputComponent
                label={t('pages.systemTierPage.quotaRequestReason')}
                name="description"
                component={inputComponents.textarea}
                isRequired={isEditableForm}
                disabled={!isEditableForm}
                maxLength={1000}
                description={t('validation.maxLength', [1000])}
              />
            </div>

            {(quoteRequest?.documents?.length !== 0 || isEditableForm) && UploadButtonComponent}

            {!isArchiveTier && (
              <ButtonsWrapper>
                {isEditableForm ? (
                  <ActionList className="mr-3">
                    {buttonText && (
                      <ActionListItem>
                        <Tooltip
                          className={isDisabledForm ? undefined : 'd-none'}
                          content={changeDisabledMessage || tooltipFormDisableMessage()}
                          exitDelay={150}
                          animationDuration={150}
                        >
                          <div>
                            <BlueButton
                              onClick={formMethods.handleSubmit(onSubmit)}
                              isDisabled={isLoading || isDisabledForm}
                              isLoading={isLoading}
                            >
                              {buttonText}
                            </BlueButton>
                          </div>
                        </Tooltip>
                      </ActionListItem>
                    )}
                  </ActionList>
                ) : (
                  checkedPermissions(scopes.tier.tierApproveQuota) && (
                    <ActionList className="mr-3">
                      <ActionListItem>
                        <Tooltip
                          className={changeDisabledMessage ? undefined : 'd-none'}
                          content={changeDisabledMessage}
                          exitDelay={150}
                          animationDuration={150}
                        >
                          <div>
                            <BlueButton
                              onClick={acceptQuote}
                              isDisabled={loading || changeDisabledMessage}
                              isLoading={isLoading}
                            >
                              {t('common.approve')}
                            </BlueButton>
                          </div>
                        </Tooltip>
                      </ActionListItem>
                      {checkedPermissions(scopes.tier.tierRejectQuota) && (
                        <ActionListItem>
                          <Tooltip
                            className={
                              notActiveSystemMessage || serverTierDisabledMessage || !isActiveTier
                                ? undefined
                                : 'd-none'
                            }
                            content={
                              notActiveSystemMessage ||
                              serverTierDisabledMessage ||
                              progressTierMessage
                            }
                            exitDelay={150}
                            animationDuration={150}
                          >
                            <div>
                              <Button
                                variant="secondary"
                                onClick={rejectQuote}
                                isDisabled={
                                  isLoading ||
                                  notActiveSystemMessage ||
                                  serverTierDisabledMessage ||
                                  !isActiveTier
                                }
                                isLoading={isLoading}
                              >
                                {t('common.reject')}
                              </Button>
                            </div>
                          </Tooltip>
                        </ActionListItem>
                      )}
                    </ActionList>
                  )
                )}
                <ActionListItem>
                  <TextButton variant="link" onClick={history.goBack}>
                    {t('common.cancel')}
                  </TextButton>
                </ActionListItem>
              </ButtonsWrapper>
            )}
          </form>
        </FormProvider>
      </S.Content>
    </PageLayout>
  );
}

export default QuotePage;
