import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';
import { useTranslation } from 'react-i18next';

import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchServices } from 'store/services/actions';
import { servicesSelector } from 'store/services/selectors';
import { currentSystemSelector } from 'store/systems/selectors';
import { clearServicesState } from 'store/services/reducers';
import paths from 'constants/paths';
import {
  getServiceAvailableStatuses,
  getServiceVersionsPipelineStatuses,
  getSystemStatuses,
} from 'components/Common/StatusComponent/statuses';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent from 'components/Common/StatusComponent';
import TotalSummary from 'components/Common/TotalSummary';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { serviceStatusKeys, systemTypesKeys } from 'constants';
import { getTooltipMessage } from 'constants/tooltips';
import { scopes } from 'constants/scopes';

import { tableFiltersToQueryParams } from '../formatters';
import DeleteServiceForm from '../DeleteServiceForm';
import ArchiveServiceForm from '../ArchiveServiceForm';
import ServiceActions from '../ServiceActions';
import ServicesType from './ServicesType';
import MultipleEditModal from './MultipleEditModal';

import * as S from './styles';

function ServicesTable({
  filterParams,
  setIsShowFilters,
  hasSelectedFilters,
  checkedUuids,
  setCheckedUuids,
  setAllRenderUuids,
  isMassChecked,
  setIsMassChecked,
  setExcludedIds,
  excludedIds,
  editServicesModal,
  isCantMassEdit,
  addButton,
  isShowFilters,
}) {
  const systemStatuses = getSystemStatuses();
  const { t } = useTranslation();
  const tooltipMessage = getTooltipMessage();
  const serviceAvailableStatuses = getServiceAvailableStatuses();
  const serviceVersionsPipelineStatuses = getServiceVersionsPipelineStatuses();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();

  const deleteServiceModal = useModal();
  const archiveServiceModal = useModal();

  const archiveService = useAsync();

  const { data, total, isLoading, isError } = useSelector(servicesSelector);
  const currentSystem = useSelector(currentSystemSelector);

  function requestFunction(requestParams) {
    dispatch(fetchServices(tableFiltersToQueryParams(requestParams)));
  }

  const changeRequest = service => {
    setCheckedUuids(prevState => prevState.filter(item => item !== service?.uuid));
    setAllRenderUuids(prevState => prevState.filter(item => item !== service?.uuid));
  };

  const multipleEditCallback = () => {
    setExcludedIds?.([]);
    setCheckedUuids([]);
    setIsMassChecked(false);
  };

  const requestParams = useMemo(
    () => ({
      ascending: true,
      orderBy: 'name',
      ...filterParams,
      system: systemId,
      ...filterParams?.date,
      date: undefined,
    }),
    [systemId, filterParams]
  );

  const checkedRepositoriesFormatedData = !isMassChecked
    ? { services: checkedUuids?.map(item => ({ uuid: item })) }
    : {
        excludeServices: excludedIds?.map(item => ({ uuid: item })),
      };

  useSocketAsync({
    topic: 'services',
    debounceTime: 600,
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (!isShowFilters || (currentSystem?.uuid && !currentSystem?.totalSummary?.tierTotal?.total)) {
      setIsShowFilters(
        hasSelectedFilters ||
          Boolean(currentSystem?.totalSummary?.serviceTotal.total) ||
          Boolean(total)
      );
    }
  }, [total, currentSystem?.totalSummary?.serviceTotal.total, hasSelectedFilters]);

  useEffect(() => {
    if (archiveService.isSuccess) {
      archiveServiceModal.setModalData('');
    }
  }, [archiveService.isSuccess]);

  useEffect(() => () => dispatch(clearServicesState()), []);

  const onClickGeneratePath = (item, tab) => ({
    pathname: generatePath(paths.routePaths.system + paths.systemServicesFullPaths.service, {
      systemId: item.system.uuid,
      systemType: item.system.type,
      serviceId: item.uuid,
    }),
    state: {
      currentTab: tab,
    },
  });

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'code',
      label: t('common.repositoryCode'),
      withSort: true,
    },
    {
      key: 'type',
      label: t('common.serviceType'),
      withSort: true,
    },
    {
      key: 'serviceVersionTotal',
      label: t('common.serviceVersions'),
    },
    {
      key: 'releaseTotal',
      label: t('common.releases'),
    },
    {
      key: 'tierReleaseTotal',
      label: t('pages.systemAddServicePage.deployments'),
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      withSort: true,
      isHidden: systemId,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = data.map(service => ({
    id: service.uuid,
    isCheckboxDisabled: service?.status !== serviceStatusKeys.ACTIVE,
    isCheckboxDisabledMessage: t('pages.systemServicesPage.unableToEditService'),
    data: [
      {
        key: 'name',
        content: (
          <LinkWithPermission
            system={service?.system}
            scope={scopes.service.serviceViewForm}
            to={generatePath(paths.routePaths.system + paths.systemServicesFullPaths.service, {
              systemId: service.system.uuid,
              systemType: service.system.type,
              serviceId: service.uuid,
            })}
            isTableLink
          >
            {service.name}
          </LinkWithPermission>
        ),
      },
      {
        key: 'code',
        content: (
          <span className="hyphens-break-word">
            {service.gitlabProjectUrl ? (
              <>
                {service.code}
                <ExternalLinkAltIcon className="ml-1" />
              </>
            ) : (
              service.code
            )}
          </span>
        ),
        type: service.gitlabProjectUrl && types.externalLink,
        href: service.gitlabProjectUrl,
      },
      {
        key: 'type',
        content: <ServicesType type={service.type} integration={service.integration} />,
      },
      {
        key: 'serviceVersionTotal',
        content: (
          <LinkWithPermission
            system={service?.system}
            scope={scopes.service.serviceViewForm}
            to={onClickGeneratePath(service, 1)}
            isTableLink
          >
            <TotalSummary
              total={service.totalSummary?.serviceVersionTotal.total}
              inProgress={service.totalSummary?.serviceVersionTotal.inProgress}
              inProgressMessage={serviceVersionsPipelineStatuses.RUNNING.text}
              error={service.totalSummary?.serviceVersionTotal.error}
              isServiceVersionTotal
            />
          </LinkWithPermission>
        ),
      },
      {
        key: 'releaseTotal',
        content: (
          <LinkWithPermission
            system={service?.system}
            scope={scopes.service.serviceViewForm}
            to={onClickGeneratePath(service, 2)}
            isTableLink
          >
            <TotalSummary
              total={service.totalSummary?.releaseTotal.total}
              requireApproval={service.totalSummary?.releaseTotal.requireApproval}
              isReleaseTotal
            />
          </LinkWithPermission>
        ),
      },
      {
        key: 'tierReleaseTotal',
        content: (
          <LinkWithPermission
            system={service?.system}
            scope={scopes.service.serviceViewForm}
            to={onClickGeneratePath(service, 3)}
            isTableLink
          >
            <TotalSummary
              total={service.totalSummary?.tierReleaseTotal.total}
              inProgress={service.totalSummary?.tierReleaseTotal.inProgress}
              error={service.totalSummary?.tierReleaseTotal.error}
              inProgressMessage={tooltipMessage.inProgressMessage}
              tierReleaseTotal
            />
          </LinkWithPermission>
        ),
      },
      ...(!systemId
        ? [
            {
              key: 'system.shortName',
              content: (
                <LinkWithPermission
                  system={service?.system}
                  scope={scopes.system.systemViewForm}
                  to={generatePath(paths.routePaths.system, {
                    systemId: service.system.uuid,
                    systemType: service.system.type,
                  })}
                  isTableLink
                >
                  <StatusComponent
                    statusId={
                      service.system.type === systemTypesKeys.EXTERNAL
                        ? systemTypesKeys.EXTERNAL
                        : service.system.status
                    }
                    statusWithTooltip
                    statuses={systemStatuses}
                    truncate
                  >
                    {service.system.shortName}
                  </StatusComponent>
                </LinkWithPermission>
              ),
            },
          ]
        : []),
      {
        key: 'status',
        content: (
          <S.StatusWrapper>
            <StatusComponent
              statusId={service.status}
              statuses={serviceAvailableStatuses}
              objectUuid={service.uuid}
              objectType="SERVICE"
            />
          </S.StatusWrapper>
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={service?.lastUpdatedBy}
            system={service?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: service.lastUpdatedAt,
      },
      {
        key: 'dropdownMenu',
        content: (
          <ServiceActions
            service={service}
            deleteServiceModal={deleteServiceModal}
            archiveServiceModal={archiveServiceModal}
            archiveService={archiveService}
            checkedUuids={checkedUuids}
          />
        ),
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName="services"
        requestFunction={requestFunction}
        withStandartPagination
        compactVariant
        total={total}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
        withCheckboxes={!isCantMassEdit}
        setAllRenderUuids={setAllRenderUuids}
        checkedUuids={checkedUuids}
        setCheckedUuids={setCheckedUuids}
        isMassChecked={isMassChecked}
        setExcludedIds={setExcludedIds}
        excludedIds={excludedIds}
        addButton={addButton}
        isError={isError}
      />

      <MultipleEditModal
        {...editServicesModal}
        isMassChecked={isMassChecked}
        checkedUuids={checkedUuids}
        excludedIds={excludedIds}
        callback={multipleEditCallback}
        checkedRepositoriesFormatedData={checkedRepositoriesFormatedData}
      />

      <DeleteServiceForm requestFunction={changeRequest} {...deleteServiceModal} />

      <ArchiveServiceForm requestFunction={changeRequest} {...archiveServiceModal} />
    </>
  );
}

export default ServicesTable;
