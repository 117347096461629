import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useStorage from 'hooks/useStorage';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector } from 'store/systems/selectors';
import { userPermissionsSelector } from 'store/user/selectors';
import paths from 'constants/paths';

import MenuComponent from './MenuComponent';
import { featureFlags } from '../../../constants/features';

import * as S from './styles';

function LeftMenu({ showLeftMenu }) {
  const { t } = useTranslation();
  const { setToStorage } = useStorage('currentSystem');
  const location = useLocation();
  const { checkedPermissions, optionsFilterByScopes } = useUserPermissions();
  const userPermissions = useSelector(userPermissionsSelector);
  const helpMenuChildren = [
    {
      name: t('common.documentation'),
      url: paths.routePaths.documents,
    },
  ];
  const match = matchPath(location.pathname, {
    path: paths.routePaths.systems,
  });

  const currentSystem = useSelector(currentSystemSelector);
  const systemShortName = currentSystem?.shortName ?? '';
  const systemId = currentSystem?.uuid || '';
  const systemType = currentSystem?.type || paths.systemTypes.internal;

  const generateSystemPath = route =>
    generatePath(route, {
      systemId,
      systemType,
    });

  const adminMenuChildren = [
    {
      name: t('common.serverTiers'),
      url: paths.adminFullPaths.serverTiers,
      needDivider: checkedPermissions(scopes.platform.clusterViewSection, true),
      scope: scopes.platform.clusterViewSection,
      isPlatformScope: true,
    },
    {
      name: t('common.backup'),
      url: paths.adminFullPaths.backups,
      scope: scopes.platform.backupViewSection,
      isPlatformScope: true,
    },
    {
      name: t('common.audit'),
      url: paths.adminFullPaths.audit,
      needDivider: true,
      scope: scopes.platform.auditViewSection,
      isPlatformScope: true,
    },
    {
      name: t('common.platformUsers'),
      url: paths.adminFullPaths.users,
      needDivider: true,
      scope: scopes.platform.userViewSection,
      isPlatformScope: true,
    },
    {
      name: t('common.dictionaries'),
      url: paths.adminFullPaths.dictionaries,
      scope: scopes.platform.dictionaryViewSection,
      isPlatformScope: true,
    },
  ];

  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    if (currentSystem) {
      setToStorage({
        uuid: currentSystem.uuid,
        shortName: currentSystem.shortName,
        type: currentSystem.type,
      });
    }
  }, [currentSystem]);

  const systemChildren = useMemo(() => {
    const mainInfo = {
      name: t('common.generalInformation'),
      url:
        !!systemId &&
        generatePath(paths.routePaths.system, {
          systemId,
          systemType,
        }),
      exact: true,
      needDivider: true,
    };

    if (!systemId) {
      return [
        {
          name: t('common.tiers'),
          url: generateSystemPath(paths.allSystemFullPaths.tiers),
          needDivider: true,
          scope: scopes.platform.tierViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.services'),
          url: generateSystemPath(paths.allSystemFullPaths.services),
          scope: scopes.platform.serviceViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.serviceVersions'),
          url: generateSystemPath(paths.allSystemFullPaths.serviceVersions),
          isHidden: !featureFlags.isServiceVersionsFeatureEnabled,
          scope: scopes.platform.versionViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.releases'),
          url: generateSystemPath(paths.allSystemFullPaths.releases),
          scope: scopes.platform.releaseViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.deployments'),
          url: generateSystemPath(paths.allSystemFullPaths.deployments),
          needDivider: true,
          scope: scopes.platform.deploymentViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.milestones'),
          url: generateSystemPath(paths.allSystemFullPaths.milestones),
          isHidden: !featureFlags.isIssuesFeatureEnabled,
          scope: scopes.platform.milestoneViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.issues'),
          url: generateSystemPath(paths.allSystemFullPaths.issues),
          needDivider: true,
          isHidden: !featureFlags.isIssuesFeatureEnabled,
          scope: scopes.platform.issueViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.integrations'),
          url: generateSystemPath(paths.allSystemFullPaths.integrations),
          isHidden: !featureFlags.isIntegrationsFeatureEnabled,
          scope: scopes.platform.integrationViewSection,
          isPlatformScope: true,
          needDivider: true,
        },
        {
          name: t('common.users'),
          url: generateSystemPath(paths.allSystemFullPaths.users),
          scope: scopes.user.userViewSection,
          isPlatformScope: true,
        },
        {
          name: t('common.documents'),
          url: generateSystemPath(paths.allSystemFullPaths.documents),
          scope: scopes.platform.documentViewSection,
          isPlatformScope: true,
        },
      ];
    }

    if (
      systemType === paths.systemTypes.external ||
      !checkedPermissions(scopes.system.systemViewForm) ||
      currentSystem?.totalSummary === null
    ) {
      return [
        mainInfo,
        {
          name: t('common.tiers'),
          url: generateSystemPath(paths.systemFullPaths.tiers),
          needDivider: true,
          total: currentSystem?.totalSummary?.tierTotal?.total,
        },
        {
          name: t('common.integrations'),
          url: generateSystemPath(paths.systemFullPaths.integrations),
          needDivider: true,
          isHidden: !featureFlags.isIntegrationsFeatureEnabled,
          total: currentSystem?.totalSummary?.integrationTotal?.total,
        },
      ];
    }

    if (systemId && currentSystem?.totalSummary) {
      return [
        mainInfo,
        {
          name: t('common.tiers'),
          url: generateSystemPath(paths.systemFullPaths.tiers),
          needDivider: true,
          total: currentSystem.totalSummary?.tierTotal?.total,
          scope: scopes.tier.tierViewSection,
        },
        {
          name: t('common.services'),
          url: generateSystemPath(paths.systemFullPaths.services),
          total: currentSystem.totalSummary?.serviceTotal?.total,
          scope: scopes.service.serviceViewSection,
        },
        {
          name: t('common.serviceVersions'),
          url: generateSystemPath(paths.systemFullPaths.serviceVersions),
          isHidden: !featureFlags.isServiceVersionsFeatureEnabled,
          total: currentSystem.totalSummary?.serviceVersionTotal?.total,
          scope: scopes.serviceVersion.versionViewSection,
        },
        {
          name: t('common.releases'),
          url: generateSystemPath(paths.systemFullPaths.releases),
          total: currentSystem.totalSummary?.releaseTotal?.total,
          scope: scopes.release.releaseViewSection,
        },
        {
          name: t('common.deployments'),
          url: generateSystemPath(paths.systemFullPaths.deployments),
          needDivider: true,
          total: currentSystem?.totalSummary?.tierReleaseTotal?.total,
          scope: scopes.deployment.deploymentViewSection,
        },
        {
          name: t('common.milestones'),
          url: generateSystemPath(paths.systemFullPaths.milestones),
          isHidden: !featureFlags.isIssuesFeatureEnabled,
          total: currentSystem.totalSummary?.milestoneTotal?.total,
          scope: scopes.milestone.milestoneViewSection,
        },
        {
          name: t('common.issues'),
          url: generateSystemPath(paths.systemFullPaths.issues),
          needDivider: true,
          isHidden: !featureFlags.isIssuesFeatureEnabled,
          total: currentSystem.totalSummary?.issueTotal?.total,
          scope: scopes.issue.issueViewSection,
        },
        {
          name: t('common.integrations'),
          url: generateSystemPath(paths.systemFullPaths.integrations),
          needDivider: true,
          isHidden: !featureFlags.isIntegrationsFeatureEnabled,
          total: currentSystem.totalSummary?.integrationTotal?.total,
          scope: scopes.integration.integrationViewSection,
        },
        {
          name: t('common.users'),
          url: generateSystemPath(paths.systemFullPaths.users),
          total: currentSystem?.totalSummary?.userTotal?.total,
          scope: scopes.user.userViewSection,
        },
        {
          name: t('common.documents'),
          url: generateSystemPath(paths.systemFullPaths.documents),
          total: currentSystem.totalSummary?.documentTotal?.total,
          scope: scopes.document.documentViewSection,
        },
      ];
    }
  }, [systemId, currentSystem?.totalSummary, checkedPermissions]);

  const menu = useMemo(() => {
    const adminMenu = optionsFilterByScopes(adminMenuChildren);
    const systemMenu = optionsFilterByScopes(
      systemChildren?.filter(children => !children.isHidden)
    );

    return [
      ...(checkedPermissions(scopes.platform.platformView, true)
        ? [
            {
              name: t('common.mainPage'),
              url: paths.routePaths.main,
              exact: true,
            },
            {
              name: systemShortName
                ? `${t('common.system')} (${systemShortName})`
                : t('common.system'),
              url: paths.routePaths.systems,
              exact: true,
              isCanExpand: true,
              isHidden: !systemMenu?.length,
              children: systemMenu,
            },
            {
              name: t('common.administration'),
              url: paths.routePaths.admin,
              exact: true,
              isCanExpand: true,
              children: adminMenu,
              isHidden:
                !adminMenu?.length || !checkedPermissions(scopes.platform.platformView, true),
            },
            {
              name: t('common.monitoring'),
              url: paths.routePaths.monitoring,
              exact: true,
            },
          ]
        : []),
      {
        name: t('common.help'),
        url: paths.routePaths.help,
        exact: true,
        isCanExpand: true,
        children: helpMenuChildren,
      },
    ];
  }, [match, location, systemChildren, userPermissions]);

  useEffect(() => {
    const epandedUrl = menu.find(
      ({ isCanExpand, url }) => isCanExpand && location.pathname.includes(url)
    )?.url;

    if (epandedUrl) {
      setExpandedItems([epandedUrl]);
    }
  }, []);

  useEffect(() => {
    if (systemId) {
      setExpandedItems(prevState =>
        prevState.includes(paths.routePaths.systems)
          ? prevState
          : [...prevState, paths.routePaths.systems]
      );
    }
  }, [systemId, currentSystem]);

  return (
    <S.Wrapper showLeftMenu={showLeftMenu}>
      <div className="d-sm-flex flex-column pb-4">
        <MenuComponent
          menu={menu}
          generateSystemPath={generateSystemPath}
          expandedItems={expandedItems}
          setExpandedItems={setExpandedItems}
        />
      </div>
    </S.Wrapper>
  );
}

export default LeftMenu;
