import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Progress, ProgressSize } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { fetchServiceVersionsStaticAnalysis } from 'store/serviceVersionsBuildResults/actions';
import { serviceVersionsStaticAnalysisSelector } from 'store/serviceVersionsBuildResults/selectors';
import StatusComponent from 'components/Common/StatusComponent';
import { getQualityGateStatuses } from 'components/Common/StatusComponent/statuses';
import { buildResultTypeKeys, getBuildResultTypeNames } from 'constants';
import TextBlock from 'components/UI/TextBlock';
import { declOfNum } from 'utils';
import paths from 'constants/paths';
import { staticAnalysisAction } from 'constants/paths/systemServiceVersionsPaths';

import StaticAnalysisBuildResults from './StaticAnalysisBuildResults';

function ServiceVersionsStaticAnalysis() {
  const { t } = useTranslation();
  const qualityGateStatuses = getQualityGateStatuses();
  const buildResultTypeNames = getBuildResultTypeNames();
  const {
    params: { systemId, systemType, serviceVersionId, staticAnalysisId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { data } = useSelector(serviceVersionsStaticAnalysisSelector);

  useEffect(() => {
    if (!data?.uuid) {
      dispatch(
        fetchServiceVersionsStaticAnalysis({
          buildResultUuid: staticAnalysisId,
        })
      );
    }
  }, []);

  const progressText = useMemo(
    () => (
      <>
        {declOfNum(
          data?.duplicatedBlocks || 0,
          [
            t('pages.systemServiceVersionPage.plural.blocks1'),
            t('pages.systemServiceVersionPage.plural.blocks2'),
            t('pages.systemServiceVersionPage.plural.blocks3'),
          ],
          true,
          true
        )}{' '}
        {t('pages.systemServiceVersionPage.on')}{' '}
        {declOfNum(
          data?.linesOfCode || 0,
          [
            t('pages.systemServiceVersionPage.plural.lines1'),
            t('pages.systemServiceVersionPage.plural.lines2'),
            t('pages.systemServiceVersionPage.plural.lines3'),
          ],
          true,
          true
        )}
      </>
    ),
    [data]
  );

  const isHaveProblems = !!(data.bugs + data.codeSmells + data.vulnerabilities);
  const isHaveUnsafeAreas = !!data.securityHotspots;

  return (
    <div className="px-4 pt-4 pb-2 bg-white">
      <h2 className="font-20 font-weight-medium mb-4">
        {buildResultTypeNames[buildResultTypeKeys.STATIC_ANALYSIS]}
      </h2>
      <TextBlock
        greyColor
        title={t('pages.systemServiceVersionPage.qualityGateTitle')}
        tooltipMessage={t('pages.systemServiceVersionPage.qualityGateDescription')}
      >
        <div className="mb-4">
          <StatusComponent statusId={data?.qualityGateStatus} statuses={qualityGateStatuses} />
        </div>
      </TextBlock>
      <StaticAnalysisBuildResults />
      <TextBlock
        greyColor
        title={t('pages.systemServiceVersionPage.dublicateTitle')}
        tooltipMessage={t('pages.systemServiceVersionPage.dublicateDescription')}
      >
        <div style={{ width: '340px' }} className="mt-1 mb-4">
          <Progress
            value={data.duplicatedLinesDensity || 0}
            title={`${data.duplicatedLinesDensity || 0}%`}
            label={progressText}
            size={ProgressSize.sm}
            className="gap-1"
          />
        </div>
      </TextBlock>

      {(isHaveProblems || isHaveUnsafeAreas) && (
        <TextBlock greyColor title={t('pages.systemServiceVersionPage.sections')}>
          {isHaveProblems && (
            <Link
              className="font-14 w-content medium-line-height d-lg-block"
              to={generatePath(paths.systemServiceVersionsFullPaths.problems, {
                systemId,
                systemType,
                serviceVersionId,
                staticAnalysisId,
                action: staticAnalysisAction.problems,
              })}
            >
              {t('pages.systemServiceVersionPage.problems')}
            </Link>
          )}
          {isHaveUnsafeAreas && (
            <Link
              className="font-14 w-content medium-line-height d-lg-block"
              to={generatePath(paths.systemServiceVersionsFullPaths.unsafeAreas, {
                systemId,
                systemType,
                serviceVersionId,
                staticAnalysisId,
                action: staticAnalysisAction.unsafeAreas,
              })}
            >
              {t('pages.systemServiceVersionPage.unsafeAreas')}
            </Link>
          )}
        </TextBlock>
      )}
    </div>
  );
}

export default ServiceVersionsStaticAnalysis;
