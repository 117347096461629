export const tableFiltersToQueryParams = filters => {
  const { multiFilters, ...params } = filters;
  const inbound = multiFilters?.filter(filter => typeof filter === 'boolean');
  const tier = multiFilters?.filter(filter => typeof filter === 'string');

  return {
    ...params,
    tier,
    inbound: inbound?.length === 1 ? inbound[0] : undefined,
  };
};
