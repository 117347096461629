import styled from 'styled-components';

export const ClusterParamsUse = styled.div.attrs({
  className: 'd-flex font-14 text-wrap-nowrap',
})`
  color: #6a6e73;
  justify-self: flex-end;

  > span {
    margin-right: 5px;
    color: #0f69c9;
  }
`;
