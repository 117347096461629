import styled from 'styled-components';

import StatusOkSVG from 'assets/images/notifications/notification-info.svg';
import WarningSVG from 'assets/images/notifications/notification-warning.svg';
import ErrorSVG from 'assets/images/notifications/notification-error.svg';

const StatusIco = styled.div`
  width: 17px;
  height: 17px;
  background-size: cover;
  margin-right: 10px;
  align-self: center;
`;

export const StatusOkIco = styled(StatusIco)`
  background: url(${StatusOkSVG}) no-repeat;
`;

export const WarningIco = styled(StatusIco)`
  min-width: 18px;
  height: 16px;
  background: url(${WarningSVG}) no-repeat;
`;

export const ErrorIco = styled(StatusIco)`
  background: url(${ErrorSVG}) no-repeat;
`;
