import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@patternfly/react-core';
import { SelectOption, SelectVariant, SelectDirection } from '@patternfly/react-core/deprecated';
import uniq from 'lodash/uniq';

import { declOfNum, getFilteredOptions } from 'utils';
import SystemName from 'pages/MainPage/SystemComponent/SystemName';

import { StyledSelect, TextValue } from './styles';

const titleId = 'title-id-1';
const titleIdTypeahead = 'typeahead-select-id-1';

function SelectComponent({
  options = [],
  placeholder = ' ',
  field,
  disabled,
  withAddButton,
  variant = SelectVariant.single,
  onTypeaheadInputChanged,
  noResultsText,
  label,
  maxLength,
  checkedValue,
  withoutClearButton,
  onlySelect,
  height,
  direction = SelectDirection.down,
  searchFilter,
  descriptionMaxLength,
  onlyTextValue,
  withInfiniteScroll,
  optionWithoutValue,
  valueForNameDeclension,
  withHoverDescription,
  disabledWithoutAngelIcon,
  setIsFocus,
  inputDescription,
  currentSearch,
  selectCallback,
  withTypeaheadValue,
}) {
  const { t, i18n } = useTranslation();
  const toggleAriaLabel = t('components.select.optionsMenu');
  const [isOpen, setOpen] = useState(false);
  const [isOptions, setOptions] = useState([]);
  const [isNewSelect, setIsNewSelect] = useState(false);
  const [isInfiniteOptions, setIsInfiniteOptions] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const noResultTextToRender = noResultsText || t('common.noResults');
  const [selected, setSelected] = useState('');

  const scrollHandler = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
    if (bottom && isOptions.length < withInfiniteScroll?.total) {
      withInfiniteScroll?.loadMore({
        offset: isOptions.length,
        q: searchValue?.length ? searchValue : undefined,
      });
    }
  };

  useEffect(() => {
    const currentOptionLabel = options.find(option => option?.value === field.value)?.label;
    if (
      options?.length &&
      selected !== field.value &&
      !withAddButton &&
      !currentSearch &&
      !withTypeaheadValue &&
      (!withInfiniteScroll ||
        (withInfiniteScroll &&
          isOpen &&
          currentOptionLabel?.length &&
          currentOptionLabel?.label !== selected))
    ) {
      setSelected(currentOptionLabel || selected || '');
    }

    if (field.value && withAddButton && !isOpen) {
      setSelected(field.value);
    }
  }, [options, field, isOptions?.length]);

  useEffect(() => {
    if (options && !withAddButton && !withInfiniteScroll && !withTypeaheadValue) {
      setSelected(currentSearch || options.find(item => item?.value === field.value)?.label || '');
    }

    if (
      options &&
      !withAddButton &&
      !withInfiniteScroll &&
      withTypeaheadValue &&
      currentSearch !== undefined
    ) {
      if (withTypeaheadValue && currentSearch?.length && field.value?.length) {
        setSelected(
          currentSearch ||
            [...options, ...(checkedValue || [])].find(item => item?.value === field.value)
              ?.label ||
            ''
        );
      } else if (!field.value?.length || !currentSearch?.length) {
        setSelected('');
      }
    }
  }, [currentSearch]);

  useEffect(() => {
    if (checkedValue && !selected?.length && !currentSearch && !withTypeaheadValue) {
      const newSelected = currentSearch
        ? options.find(item => item.value === checkedValue)?.value
        : options.find(item => item.value === checkedValue)?.label;
      setSelected(checkedValue.value ? checkedValue.label : newSelected);
    }
  }, [checkedValue]);

  const onToggle = () => setOpen(!isOpen);

  const onSelect = (event, selection) => {
    const currentValue = isOptions.find(
      item => item.value === selection || item.label === selection
    )?.value;

    if (!withAddButton && (selection || currentValue)) {
      setSelected(selection || currentValue);

      if (selectCallback) {
        selectCallback(selection, currentValue);
        field.onChange(currentValue);
      }
    }

    if (!selectCallback) {
      field.onChange(currentValue);
    }

    onToggle();
    setIsNewSelect(true);
  };

  const onCreateOption = newValue => {
    setOptions(prevState => [...prevState, { value: newValue, label: newValue }]);

    onToggle();
  };

  const clearSelection = () => {
    setSelected(withAddButton ? [] : '');
    field.onChange('');

    if (currentSearch) {
      selectCallback?.('', '');
    }

    if (!withAddButton) {
      setOptions(withInfiniteScroll && isInfiniteOptions.length ? isInfiniteOptions : options);
    }

    if (!isOpen) {
      onToggle();
    }
  };

  useEffect(() => {
    if (
      ((searchFilter ? options?.length : options) || onTypeaheadInputChanged) &&
      !onlySelect &&
      !withInfiniteScroll
    ) {
      setOptions(options);
    }
    if (options?.length && withInfiniteScroll) {
      setIsInfiniteOptions(prevState => [
        ...prevState.filter(item => !options.find(option => option.value === item.value)),
        ...options,
      ]);
      setOptions(prevState => [
        ...prevState.filter(item => !options.find(option => option.value === item?.value)),
        ...options,
      ]);
    }
  }, [options]);

  useEffect(() => {
    if (options?.length && checkedValue && onlySelect) {
      setOptions(options);
    }
  }, [options, checkedValue]);

  useEffect(() => {
    if (!isOpen) {
      if (onTypeaheadInputChanged && !isNewSelect) {
        if (!currentSearch && !withTypeaheadValue) {
          onTypeaheadInputChanged(undefined);
        }

        setIsNewSelect(false);
      }
      if (searchFilter) {
        setOptions([]);
      }
    }
  }, [onTypeaheadInputChanged, searchFilter, isOpen]);

  useEffect(() => {
    const option = options.find(item => item.value === field.value);
    if (option?.units && selected && field.value) {
      setSelected(
        valueForNameDeclension !== undefined
          ? declOfNum(valueForNameDeclension, option?.units, false, true)
          : option.label
      );
    }
  }, [valueForNameDeclension, checkedValue, field.value]);

  useEffect(() => {
    if (selected && field.value && !withAddButton) {
      const newSelected = options.find(item => item.value === field.value)?.label;

      if (newSelected) {
        setSelected(newSelected);
      }
    }

    if (options?.length) {
      if (withInfiniteScroll) {
        setIsInfiniteOptions(prevState => [
          ...prevState.filter(item => !options.find(option => option.value === item.value)),
          ...options,
        ]);
      }

      setOptions(prevState => [
        ...prevState.filter(item => !options.find(option => option.value === item?.value)),
        ...options,
      ]);
    }
  }, [i18n.language]);

  const optionsData = useMemo(
    () =>
      uniq(
        optionWithoutValue && !searchValue ? [...optionWithoutValue, ...isOptions] : isOptions
      ).map(option => {
        if (!option?.value && !option?.label) {
          return (
            <SelectOption
              key="no-results"
              value={noResultTextToRender}
              isDisabled
              isNoResultsOption
            />
          );
        }

        const optionDescription = descriptionMaxLength ? (
          <SystemName
            key={option.value}
            name={option.description}
            maxLength={descriptionMaxLength}
            lineClamps={2}
          />
        ) : (
          option.description
        );

        if (option.disabled && option.tooltipMessage) {
          return (
            <Tooltip
              key={option.value}
              className={!option.tooltipMessage && !option.disabled ? 'd-none' : undefined}
              content={option.disabled ? option.tooltipMessage : null}
              value={option.label}
              exitDelay={150}
              animationDuration={150}
            >
              <SelectOption
                key={option.value}
                value={currentSearch ? option.value : option.label}
                isDisabled={option.disabled}
                {...(option.description && { description: optionDescription })}
                isSelected={option.value === field.value}
              >
                {option.labelTemplate}
              </SelectOption>
            </Tooltip>
          );
        }

        return (
          <SelectOption
            key={option.value}
            value={currentSearch ? option.value : option.label}
            isDisabled={option.disabled}
            {...(option.description && {
              description: optionDescription,
            })}
            isSelected={option.value === field.value}
          >
            {option.labelTemplate || option.label}
          </SelectOption>
        );
      }),
    [isOptions, i18n.language]
  );

  const description = useMemo(() => {
    if (!selected) {
      return '';
    }

    if (inputDescription) {
      return inputDescription;
    }

    const targetOption =
      optionsData?.find(
        option => option.props?.value === selected || option.props?.value === field.value
      ) ||
      [...isOptions, ...(checkedValue?.length ? checkedValue : [])]?.find(
        option => option.value === field.value
      );

    return targetOption?.props?.description || targetOption?.description || '';
  }, [selected, i18n.language, optionsData]);

  const onCustomFilter = event => {
    const value = event?.target?.value;

    if (onTypeaheadInputChanged && (withTypeaheadValue || !currentSearch?.length)) {
      if (!isOpen && !value?.length && value !== undefined && field.value?.length) {
        setOpen(true);
      }
    }

    return getFilteredOptions(value, optionsData, searchFilter || onTypeaheadInputChanged);
  };

  const onSearchFilter = async (event = '') => {
    if (event !== null && !String(event)?.trim()?.length) {
      setSearchValue();
      if (selected && !options.find(item => item?.value === field.value)?.label) {
        if (withTypeaheadValue && !isOpen && event !== null && event !== undefined && selected) {
          setOpen(true);
        }

        return setOptions(withInfiniteScroll ? isInfiniteOptions : options);
      }
    }

    if (searchFilter) {
      setSearchValue(event);
      await searchFilter(event?.trim()).then(result => {
        setOptions(result);
      });
    }
  };

  useEffect(() => {
    setIsFocus(isOpen);

    if (isOpen) {
      onSearchFilter(null);

      if (withTypeaheadValue || currentSearch) {
        onTypeaheadInputChanged?.(
          withTypeaheadValue ? currentSearch || checkedValue?.[0]?.label : currentSearch,
          true
        );
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !isOpen &&
      !searchValue?.length &&
      searchValue !== null &&
      searchValue !== undefined &&
      selected
    ) {
      setOpen(true);

      if (!withTypeaheadValue) {
        onSearchFilter(null);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="d-flex">
        {withAddButton ? (
          <StyledSelect
            variant={SelectVariant.typeahead}
            aria-label="Select Input"
            typeAheadAriaLabel="Select a state"
            onToggle={onToggle}
            onSelect={onSelect}
            onClear={clearSelection}
            onFilter={withAddButton ? undefined : onCustomFilter}
            onTypeaheadInputChanged={onTypeaheadInputChanged}
            selections={selected}
            isOpen={isOpen}
            aria-labelledby={titleIdTypeahead}
            placeholderText={placeholder}
            $color={!selected?.length}
            disabled={disabled}
            isCreatable={withAddButton}
            onCreateOption={(withAddButton && onCreateOption) || undefined}
            shouldResetOnSelect={withAddButton}
            clearSelectionsAriaLabel="Clear all"
            toggleAriaLabel={toggleAriaLabel}
            removeSelectionAriaLabel={t('common.delete')}
            createText={t('common.add')}
            noResultsFoundText={noResultTextToRender}
          >
            {optionsData}
          </StyledSelect>
        ) : (
          <>
            {onlyTextValue ? (
              <TextValue>{selected || '-'}</TextValue>
            ) : (
              <StyledSelect
                variant={variant}
                aria-label="Select Input"
                typeAheadAriaLabel="Select a state"
                onToggle={onToggle}
                onSelect={onSelect}
                onClear={withoutClearButton ? undefined : clearSelection}
                onFilter={onCustomFilter}
                onTypeaheadInputChanged={searchFilter ? onSearchFilter : onTypeaheadInputChanged}
                selections={
                  currentSearch || withTypeaheadValue
                    ? isOptions.find(
                        item => item.value === field.value || item.label === field.value
                      )?.value || selected
                    : selected
                }
                isOpen={isOpen}
                aria-labelledby={titleId}
                placeholderText={placeholder}
                $color={!selected?.length}
                isDisabled={searchFilter && disabled ? true : !isOpen && disabled}
                toggleAriaLabel={toggleAriaLabel}
                noResultsFoundText={noResultTextToRender}
                width={maxLength}
                height={height}
                direction={direction}
                onScroll={scrollHandler}
                $withHoverDescription={withHoverDescription}
                $disabledWithoutAngelIcon={disabledWithoutAngelIcon && disabled}
              >
                {optionsData}
              </StyledSelect>
            )}
          </>
        )}
      </div>
      {description && (
        <small data-dropdown-description-for={label} className="text-gray">
          <SystemName name={description} maxLength={descriptionMaxLength} lineClamps={2} />
        </small>
      )}
    </>
  );
}

const NewSelectController = ({ name, control, defaultValue, rules, ...selectProps }) => {
  const selectDefaultValue = defaultValue ? [] : '';

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={selectDefaultValue}
      render={({ field }) => <SelectComponent field={field} {...selectProps} />}
    />
  );
};
export default NewSelectController;
