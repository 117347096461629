import { createSelector } from 'reselect';
import { checkedServerError } from 'utils';

const serviceVersionsState = state => state.serviceVersions;

export const serviceVersionsSelector = createSelector(
  [serviceVersionsState],
  serviceVersions => serviceVersions
);

export const currentServiceVersionSelector = createSelector(
  [serviceVersionsState],
  ({ serviceVersionById }) => serviceVersionById
);

export const currentServiceVersionIsLoadingSelector = createSelector(
  [serviceVersionsState],
  ({ serviceVersionByIdisLoading }) => serviceVersionByIdisLoading
);

export const currentServiceVersionByIdErrorSelector = createSelector(
  [serviceVersionsState],
  ({ serviceVersionByIdError }) => checkedServerError(serviceVersionByIdError)
);

export const serviceVersionsOptionsSelector = createSelector(
  [serviceVersionsState],
  ({ serviceVersionsOptions }) => serviceVersionsOptions
);

export const serviceVersionsOptionsIsLoadingSelector = createSelector(
  [serviceVersionsState],
  ({ serviceVersionsOptionsIsLoading }) => serviceVersionsOptionsIsLoading
);
