import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchMilestones } from 'store/milestones/actions';
import { milestonesSelector } from 'store/milestones/selectors';
import { clearMilestonesState } from 'store/milestones/reducers';
import paths from 'constants/paths';
import { milestoneStatusKeys, systemTypesKeys } from 'constants';
import { featureFlags } from 'constants/features';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent, {
  getMilestoneStatus,
  getSystemStatuses,
} from 'components/Common/StatusComponent';
import { dateConverter, isActualFilterFunction } from 'utils';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

import MilestoneActions from '../MilestoneActions';
import MilestoneDates from './MilestoneDates';
import MilestoneContract from './MilestoneContract';
import MilestoneProgress from './MilestoneProgress';

import * as S from './styles';

function MilestonesTable({
  addButton,
  tableFilters,
  isShowFilters,
  setIsShowFilters,
  currentTotal,
}) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const milestoneStatus = getMilestoneStatus();
  const systemStatuses = getSystemStatuses();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();

  const changeMilestones = useAsync();
  const deleteMilestone = useAsync();
  const { data, total, isLoading, isError } = useSelector(milestonesSelector);
  function requestFunction(params) {
    dispatch(fetchMilestones(params));
  }

  const requestParams = useMemo(
    () => ({
      ...tableFilters.filterParams,
      ascending: false,
      orderBy: 'startDate',
      system: systemId,
      ...tableFilters.filterParams?.date,
      date: undefined,
    }),
    [systemId, tableFilters?.filterParams]
  );

  useSocketAsync({
    topic: 'milestones',
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (changeMilestones.isSuccess || deleteMilestone.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [changeMilestones.isSuccess, deleteMilestone.isSuccess]);

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters(tableFilters?.hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, tableFilters?.hasSelectedFilters]);

  useEffect(() => () => dispatch(clearMilestonesState()), []);

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'startDate',
      label: t('common.startDate'),
      withSort: true,
      right: true,
      minWidth: '130px',
    },
    {
      key: 'endDate',
      label: t('common.endDate'),
      withSort: true,
      right: true,
      minWidth: '130px',
    },
    {
      key: 'contract',
      label: t('common.contract'),
      withSort: true,
      minWidth: '200px',
    },
    {
      key: 'totalSummary',
      label: t('pages.systemAddMilestonePage.progress'),
      isHidden: !featureFlags.isIssuesFeatureEnabled,
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      withSort: true,
      minWidth: '150px',
      isHidden: systemId,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
      minWidth: '150px',
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = data.map(milestone => {
    const isActual = isActualFilterFunction(milestone);
    const isOpenMilestone =
      milestone.status === milestoneStatusKeys.OPEN ||
      milestone.status === milestoneStatusKeys.OVERDUE;

    return {
      id: milestone.uuid,
      data: [
        {
          key: 'name',
          content: (
            <LinkWithPermission
              system={milestone?.system}
              scope={scopes.milestone.milestoneViewForm}
              to={generatePath(
                paths.routePaths.system + paths.systemMilestonesFullPaths.milestone,
                {
                  systemId: milestone.system.uuid,
                  systemType: milestone.system.type,
                  milestoneId: milestone.uuid,
                }
              )}
              isTableLink
            >
              <div className="d-flex flex-wrap">
                <span className="mr-2">{milestone.name}</span>
                {isActual && (
                  <S.ActualMilestone>{t('pages.systemMilestonePage.current')}</S.ActualMilestone>
                )}
              </div>
            </LinkWithPermission>
          ),
        },
        {
          key: 'startDate',
          content: dateConverter(milestone.startDate),
          type: types.rightContent,
        },
        {
          key: 'endDate',
          content: (
            <MilestoneDates
              endDate={milestone.endDate}
              isOpenMilestone={isOpenMilestone}
              rightContent
            />
          ),
        },
        {
          key: 'contract',
          content: (
            <MilestoneContract
              contract={milestone.contract}
              isOpenMilestone={isOpenMilestone}
              className="font-14"
            />
          ),
        },
        {
          key: 'totalSummary',
          content: (
            <MilestoneProgress
              issuesTotal={milestone.totalSummary.issuesTotal}
              name={milestone.name}
              uuid={milestone.uuid}
              system={milestone.system}
            />
          ),
        },
        {
          key: 'system.shortName',
          content: (
            <LinkWithPermission
              system={milestone?.system}
              scope={scopes.system.systemViewForm}
              to={generatePath(paths.routePaths.system, {
                systemId: milestone.system.uuid,
                systemType: milestone.system.type,
              })}
              isTableLink
            >
              <StatusComponent
                statusId={
                  milestone.system.type === systemTypesKeys.EXTERNAL
                    ? systemTypesKeys.EXTERNAL
                    : milestone.system.status
                }
                statusWithTooltip
                statuses={systemStatuses}
                truncate
              >
                {milestone.system.shortName}
              </StatusComponent>
            </LinkWithPermission>
          ),
        },
        {
          key: 'status',
          content: milestone.status && (
            <StatusComponent statusId={milestone.status} statuses={milestoneStatus} />
          ),
        },
        {
          key: 'lastUpdatedBy',
          content: (
            <LastUpdatedStatusWithLink
              lastUpdatedBy={milestone?.lastUpdatedBy}
              system={milestone?.system}
              isTableLink
            />
          ),
        },
        {
          key: 'lastUpdatedAt',
          content: milestone.lastUpdatedAt,
          type: types.relativeTime,
        },
        {
          key: 'dropdownMenu',
          content: (
            <MilestoneActions
              milestone={milestone}
              changeMilestones={changeMilestones}
              deleteMilestone={deleteMilestone}
            />
          ),
        },
      ],
    };
  });

  return (
    <>
      {isShowFilters && (
        <div className="d-sm-flex flex-row mx-4">{tableFilters?.FilterComponent}</div>
      )}
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName="milestones"
        requestFunction={requestFunction}
        withStandartPagination
        compactVariant
        total={total}
        hasSelectedFilters={tableFilters?.hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />
    </>
  );
}

export default MilestonesTable;
