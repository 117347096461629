import axios from 'axios';
import qs from 'qs';
import { getAccessToken, updateToken } from 'hooks/useKeycloakAuth';

const getConfigUrl = ({ url, params }) =>
  url + (params && !params.isTable ? JSON.stringify(params) : '');
const unauthorizedError = 401;

const ENV = window._env_;
const requestsAbortControllersMap = new Map();

const createAxios = (baseUrlPart = '/api/portal/') => {
  const instance = axios.create({
    baseURL: `${ENV.HYPERION_API_BASE_URL}${baseUrlPart}`,
    paramsSerializer: params => qs.stringify(params, { indices: false }),
  });

  instance.interceptors.request.use(async config => {
    const abortController = new AbortController();
    const accessToken = getAccessToken();

    config.headers.Authorization = `Bearer ${accessToken}`;
    config.signal = abortController.signal;

    const url = getConfigUrl(config);

    if (requestsAbortControllersMap.has(url)) {
      requestsAbortControllersMap.get(url).abort();
    }

    requestsAbortControllersMap.set(url, abortController);

    return config;
  });

  instance.interceptors.response.use(
    response => {
      const url = getConfigUrl(response.config);
      requestsAbortControllersMap.delete(url);

      return response;
    },
    async error => {
      if (axios.isCancel(error)) {
        return Promise.reject();
      }
      if (error.response?.status === unauthorizedError) {
        const accessToken = await updateToken();
        if (accessToken) {
          axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
          error.response.config.headers.Authorization = `Bearer ${accessToken}`;
          return axios(error.response.config);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const http = createAxios();

export const httpKeycloak = createAxios('/api/keycloak/');

export const httpAudit = createAxios('/api/audit/');

export default http;
