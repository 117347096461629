import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useRouteMatch, generatePath } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { notActiveSystemSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import TextBlock from 'components/UI/TextBlock';
import { serviceStatusKeys } from 'constants';
import {
  getVertionTagInfoMessage,
  getVertionDescriptionInfoMessage,
  getProgressServiceMessage,
  getNotActiveServiceMessage,
  getProgressSystemMessage,
} from 'constants/tooltips';
import { tooltipFormDisableMessage } from 'constants/vaidations';
import { getTagNumber, incrementVersion, isFormValid } from 'utils';

import * as S from './styles';

const ENV = window._env_;
const tagFormatRequirementsUrl = ENV.TAG_FORMAT_REQUIREMENTS_URL;

function AddServiceVersionsPage() {
  const { t, i18n } = useTranslation();
  const vertionTagInfoMessage = getVertionTagInfoMessage();
  const vertionDescriptionInfoMessage = getVertionDescriptionInfoMessage();
  const progressServiceMessage = getProgressServiceMessage();
  const notActiveServiceMessage = getNotActiveServiceMessage();
  const history = useHistory();
  const {
    params: { systemId, systemType, serviceId },
  } = useRouteMatch();
  const fieldsDescriptionMap = {
    tag: t('validation.maxLength', [50]),
    description: t('validation.maxLength', [255]),
  };
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { run, isSuccess, isLoading } = useAsync();
  const getService = useAsync();
  const getLastService = useAsync();
  const getServicesOptions = useAsync();
  const getBranchesOptions = useAsync();
  const getSearchBrancheOptions = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  const serviceValue = formMethods.watch('service');
  const tagValue = formMethods.watch('tag');
  const branchValue = formMethods.watch('branch');
  const currentService = serviceValue || serviceId;
  const currentServiceActiveMassage =
    getService.data?.status &&
    getService.data.status !== serviceStatusKeys.ACTIVE &&
    progressServiceMessage;
  const serviceOptions = useMemo(
    () => generateSelectOptions(getServicesOptions.data?.data, 'uuid', 'name'),
    [getServicesOptions.data?.data]
  );
  const brancheOptions = useMemo(
    () => generateSelectOptions(getBranchesOptions.data || [], 'name', 'name'),
    [getBranchesOptions.data]
  );
  const optionsRequestParams = useMemo(
    () => ({
      system: systemId,
      limit: 10,
      status: serviceStatusKeys.ACTIVE,
      ascending: true,
      orderBy: 'name',
    }),
    [systemId]
  );
  const isCantChangeForm =
    isLoading || !currentService || getServicesOptions.isLoading || brancheOptions.isLoading;

  const searchFilter = async q => {
    try {
      const filterOptions = await getServicesOptions.run(
        api.services.getServicesOptions({
          ...optionsRequestParams,
          q,
        })
      );

      return generateSelectOptions(filterOptions.data, 'uuid', 'name');
    } catch (e) {
      return [];
    }
  };

  const searchFilterBranch = async q => {
    if (!currentService) {
      return [];
    }

    try {
      const filterOptions = await getSearchBrancheOptions.run(
        api.services.getBranchesOptions({
          uuid: currentService,
          nameQuery: q,
        })
      );

      return generateSelectOptions(filterOptions || [], 'name', 'name');
    } catch (e) {
      return [];
    }
  };

  function servicesRequestFunction(params) {
    getServicesOptions.run(
      api.services.getServicesOptions({
        ...optionsRequestParams,
        ...params,
      })
    );
  }

  function branchesRequestFunction() {
    getBranchesOptions.run(
      api.services.getBranchesOptions({
        uuid: currentService,
        limit: 1,
      })
    );
  }

  const onClickGeneratePath = (tab, uuid) => ({
    pathname: generatePath(paths.routePaths.system + paths.systemServicesFullPaths.service, {
      systemId,
      systemType,
      serviceId: uuid,
    }),
    state: {
      currentTab: tab,
    },
  });

  const requestServiceFunction = uuid => getService.run(api.services.getService(uuid));

  const getServiceLastVersionWithIncrementTag = async (label, value) => {
    if (value?.length) {
      const response = await getLastService.run(
        api.serviceVersion.getServicesVersions({
          service: value,
          system: systemId,
          orderBy: 'createdAt',
          ascending: false,
          limit: 1,
        })
      );

      const lastVersionTag = response.data && response.data[0]?.tag;

      if (lastVersionTag) {
        incrementVersion(lastVersionTag, newTag =>
          formMethods.setValue('tag', newTag, { shouldValidate: true })
        );
      } else {
        formMethods.setValue('tag', '');
      }

      await requestServiceFunction(value);
    }
  };

  useSocketAsync({
    topic: 'services',
    debounceTime: 600,
    filterBySystemUuid: systemId,
    filterFn: ({ object }) => object?.uuid === currentService,
    onMessage: () => requestServiceFunction(currentService),
  });

  useEffect(() => {
    if (serviceId) {
      getServiceLastVersionWithIncrementTag('', serviceId);
    }
  }, []);

  useEffect(() => {
    if (currentService) {
      branchesRequestFunction();
      if (branchValue) {
        formMethods.setValue('branch', '');
      }
    }
  }, [currentService]);

  useEffect(() => {
    if (branchValue) {
      const commitMessage = getBranchesOptions.data?.find(
        item => item.name === branchValue
      )?.commitMessage;

      formMethods.setValue('description', commitMessage || branchValue);
    }
  }, [branchValue]);

  useEffect(() => {
    const defaultBranche = getBranchesOptions.data?.find(item => item.default)?.name;

    formMethods.setValue('branch', defaultBranche);
  }, [getBranchesOptions.data]);

  useEffect(() => {
    if (tagValue?.length && serviceId && getService.isSuccess) {
      formMethods.trigger('tag');
    }
  }, [getService.isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      if (serviceId) {
        formMethods.reset();
        history.push(onClickGeneratePath(1, serviceId));
      } else {
        closeForm();
      }
    }
  }, [isSuccess]);

  const onSubmit = async ({ tag, description, service, branch }) => {
    if (!branch || isCantChangeForm) {
      return;
    }

    const formattedData = {
      tag,
      description,
      serviceUuid: serviceId,
      service: { uuid: serviceId || service },
      branch,
    };
    run(api.serviceVersion.addServiceVersion(formattedData));
  };

  const versionValidation = {
    validate: async value => {
      const versionRules = [
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage1'),
          rule: new RegExp(`^(?!.*[\\.]{2}).*`, 'g'),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage2'),
          rule: new RegExp(`^(?!.*(\\@\\{)).*`, 'g'),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage3'),
          rule: new RegExp(`^(?!.*[\\/]{2}).*`, 'g'),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage4'),
          rule: new RegExp(`^(?!.*[ \\$\\~\\:\\^]).*`, 'g'),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage5'),
          rule: new RegExp(`^[^\\.].*`),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage6'),
          rule: new RegExp(`^[^\\/].*`),
        },
        {
          message: t('pages.systemAddServiceVersionPage.incorrectNameMessage7'),
          rule: new RegExp(`.*[^.\\/]$`),
        },
      ];
      const messages = versionRules
        .map(({ message, rule }) => value.match(rule)?.[0]?.length === value.length || message)
        .filter(item => item !== true);
      if (messages.length) {
        return messages.join('\n');
      }
      if (!currentService || isCantChangeForm) {
        return null;
      }

      try {
        const itemsWithSameParams = await api.serviceVersion.getServicesVersions({
          service: currentService,
          offset: 0,
          limit: 1,
          tag: value,
          system: systemId,
        });

        if (itemsWithSameParams.data?.data?.length) {
          return t('pages.systemAddServiceVersionPage.numberAlreadyInUse');
        }

        const existingServiceVersions = await api.services.getServiceVersions(currentService);

        if (existingServiceVersions.data.some(serviceVersion => serviceVersion.name === value)) {
          return t('pages.systemAddServiceVersionPage.numberAlreadyInUse2');
        }
      } catch (e) {
        if (e) {
          return null;
        }
      }

      return null;
    },
  };

  const validedFields = [...(!serviceId ? ['service'] : []), 'branch', 'tag', 'description'];
  const disabledMessage = useMemo(
    () =>
      notActiveSystemMessage ||
      (!currentService && !getServicesOptions.data?.data?.length && notActiveServiceMessage),
    [
      currentService,
      notActiveSystemMessage,
      getServicesOptions.data?.data?.length,
      getService.data,
      i18n.language,
    ]
  );
  const isDisabled =
    disabledMessage ||
    currentServiceActiveMassage ||
    validedFields.filter(field => !formMethods.getValues(field))?.length ||
    !isFormValid(formMethods.formState);
  const tooltipContent = disabledMessage || (isDisabled && tooltipFormDisableMessage());
  const button = (
    <Button
      onClick={formMethods.handleSubmit(onSubmit)}
      isLoading={formMethods.formState.isSubmitting || isLoading}
      isDisabled={isDisabled || isLoading}
    >
      {t('common.add')}
    </Button>
  );

  const dynamicDescription = useMemo(() => {
    if (tagValue?.length && !getTagNumber(tagValue)) {
      return (
        <span>
          {t('pages.systemAddServiceVersionPage.notMatchMessage')}
          <a className="" href={tagFormatRequirementsUrl} target="_blank" rel="noreferrer">
            {' '}
            {t('pages.systemAddServiceVersionPage.seeDetails')}
          </a>
        </span>
      );
    }

    if (getLastService.data?.data?.length) {
      return `${t('pages.systemAddServiceVersionPage.lastVersion')}: ${
        getLastService.data?.data?.[0]?.tag
      }`;
    }
  }, [getLastService.data, tagValue, i18n.language]);

  return (
    <PageLayout
      title={t('pages.systemAddServiceVersionPage.create')}
      withoutSidePadding
      withSystemSelector
    >
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {!serviceId ? (
            <InputComponent
              label={t('common.service')}
              name="service"
              component={inputComponents.newSelect}
              newSelectVariant="typeahead"
              searchFilter={searchFilter}
              options={serviceOptions}
              withInfiniteScroll={{
                total: getServicesOptions.data?.meta?.total,
                offset: 10,
                loadMore: servicesRequestFunction,
              }}
              isLoading={getServicesOptions.isLoading}
              placeholder={t('common.selectFromList')}
              isRequired
              withoutClearButton
              selectCallback={getServiceLastVersionWithIncrementTag}
            />
          ) : (
            <TextBlock greyColor title={t('pages.systemAddServiceVersionPage.serviceName')} isForm>
              <div className="pre-line medium-line-height font-16">{getService.data?.name}</div>
            </TextBlock>
          )}
          <InputComponent
            label={t('common.version')}
            name="tag"
            isRequired
            validationRules={versionValidation}
            maxLength={50}
            tooltipMessage={vertionTagInfoMessage}
            description={fieldsDescriptionMap.tag}
            dynamicDescription={dynamicDescription}
            isLoading={getLastService.isLoading}
          />
          <InputComponent
            label={t('common.branch')}
            name="branch"
            component={inputComponents.newSelect}
            newSelectVariant="typeahead"
            options={brancheOptions}
            isLoading={getSearchBrancheOptions.isLoading || getBranchesOptions.isLoading}
            searchFilter={searchFilterBranch}
            placeholder={t('common.selectFromList')}
            isRequired
            disabled={!currentService}
            withoutClearButton
            withoutErrorsMessage={branchValue}
          />
          <InputComponent
            component={inputComponents.textarea}
            label={t('common.description')}
            name="description"
            isRequired
            maxLength={255}
            tooltipMessage={vertionDescriptionInfoMessage}
            description={fieldsDescriptionMap.description}
            isLoading={getBranchesOptions.isLoading}
          />
          <div className="pt-4 d-flex flex-row gap-3">
            <>
              {tooltipContent?.length && !formMethods.formState.isSubmitting ? (
                <Tooltip content={tooltipContent} exitDelay={150} animationDuration={150}>
                  <div>{button}</div>
                </Tooltip>
              ) : (
                button
              )}
            </>
            <Button variant="link" id="cancel-button" onClick={closeForm}>
              {t('common.cancel')}
            </Button>
          </div>
        </S.Form>
      </FormProvider>
    </PageLayout>
  );
}

export default AddServiceVersionsPage;
