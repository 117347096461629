import React, { useEffect, useState } from 'react';
import { OutlinedCalendarAltIcon } from '@patternfly/react-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { dateTimeConverter } from 'utils';
import AuditRangePicker from 'pages/AdminRouter/AuditPage/auditComponents/AuditRangePicker';
import AuditInputComponent, {
  auditInputComponents,
} from 'pages/AdminRouter/AuditPage/auditComponents/AuditInputComponent';
import { getDateOptions } from 'pages/AdminRouter/AuditPage/auditConstants';

import * as S from './styles';

function DatePicker({ date, setDate, currentStartDate, setCurrentStartDate }) {
  const { t } = useTranslation();
  const [isShowCalendar, setIsShowCalendar] = useState(null);
  const dateTimeToDefaultText = t('components.filter.now');
  const isStartDateActive = isShowCalendar === 'dateTimeFrom';
  const dateRange = {
    dateTimeFrom: date.dateTimeFrom ? moment(date.dateTimeFrom, 'x') : moment().add(-1, 'day'),
    dateTimeTo: date.dateTimeTo ? moment(date.dateTimeTo, 'x') : null,
  };

  useEffect(() => {
    setCurrentStartDate({
      label: dateTimeConverter(dateRange.dateTimeFrom, false),
      value: dateRange.dateTimeFrom,
    });
  }, []);

  useEffect(() => {
    if (currentStartDate.value && isStartDateActive) {
      setDate(prevState => ({
        ...prevState,
        [isShowCalendar]: currentStartDate?.value.format('x'),
      }));
    }
  }, [currentStartDate.value]);

  const onChange = data => {
    setDate({
      dateTimeFrom: data.value.format('x'),
      dateTimeTo: undefined,
    });

    setCurrentStartDate({ label: dateTimeConverter(data.value, false), value: data.value });
  };

  const toggleCalendar = field => {
    if (field !== isShowCalendar) {
      setTimeout(() => setIsShowCalendar(field), 10);
    }
  };

  const saveDate = newDate => {
    if (isShowCalendar === 'dateTimeTo') {
      setDate(prevState => ({
        ...prevState,
        [isShowCalendar]: newDate.format('x'),
      }));
    }

    if (isShowCalendar === 'dateTimeFrom') {
      setCurrentStartDate({ label: dateTimeConverter(newDate, false), value: newDate });

      if (!currentStartDate) {
        setDate(prevState => ({
          ...prevState,
          [isShowCalendar]: newDate.format('x'),
        }));
      }
    }
  };

  const pickerDate = Number(
    isStartDateActive ? dateRange.dateTimeFrom.format('x') : dateRange.dateTimeTo || moment()
  );
  const label =
    isShowCalendar === 'dateTimeTo'
      ? dateTimeConverter(dateRange.dateTimeTo, false, dateTimeToDefaultText)
      : currentStartDate?.label;

  const dateTimeTo = dateTimeConverter(dateRange.dateTimeTo, false, dateTimeToDefaultText);
  const isDate = dateTimeTo !== dateTimeToDefaultText;

  return (
    <div className="d-sm-flex flex-row position-relative mr-3">
      <S.DateTextFirst
        active={isStartDateActive}
        onClick={() => toggleCalendar('dateTimeFrom')}
        aria-label="addStartDate"
        isDate={isDate}
      >
        {currentStartDate?.label}
      </S.DateTextFirst>
      <S.DateDash isDate={isDate}>–</S.DateDash>
      <S.DateText
        active={isShowCalendar === 'dateTimeTo'}
        isDate={isDate}
        onClick={() => {
          toggleCalendar('dateTimeTo');
        }}
        aria-label="addEndDate"
      >
        {dateTimeTo}
      </S.DateText>
      <AuditInputComponent
        options={getDateOptions()}
        component={auditInputComponents.select}
        isDate={<OutlinedCalendarAltIcon className="ml-1" />}
        onChange={onChange}
        value={dateRange.dateTimeFrom}
        height="37px"
      />
      {isShowCalendar && (
        <AuditRangePicker
          cancelDate={toggleCalendar}
          saveDate={saveDate}
          pickerDate={pickerDate}
          filterStyles
          label={label}
          minDate={
            isStartDateActive ? undefined : new Date(Number(dateRange.dateTimeFrom.format('x')))
          }
        />
      )}
    </div>
  );
}

export default DatePicker;
