import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import { scopes } from 'constants/scopes';

import paths from '../../../constants/paths';
import { featureFlags } from '../../../constants/features';
import ProtectedFeatureRoute from '../../../routes/ProtectedFeatureRoute';

import ServiceVersionsPage from './ServiceVersionsPage';
import CurrentServiceVersionPage from './CurrentServiceVersionPage';
import AddServiceVersionsPage from './AddServiceVersionsPage';

function SystemServiceVersionsRouter({ match: { path } }) {
  return (
    <ProtectedFeatureRoute isFeatureEnabled={featureFlags.isServiceVersionsFeatureEnabled}>
      <Switch>
        <ProtectedSystemRoute
          path={`${path}${paths.systemServiceVersionsPaths.new}`}
          component={AddServiceVersionsPage}
          scope={scopes.serviceVersion.versionCreate}
        />

        <ProtectedSystemRoute
          exact
          path={`${path}${paths.systemServiceVersionsPaths.unitTestingId}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <ProtectedSystemRoute
          exact
          path={`${path}${paths.systemServiceVersionsPaths.staticAnalysisId}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <ProtectedSystemRoute
          exact
          path={`${path}${paths.systemServiceVersionsPaths.problems}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <ProtectedSystemRoute
          exact
          path={`${path}${paths.systemServiceVersionsPaths.unsafeAreas}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <ProtectedSystemRoute
          exact
          path={`${path}${paths.systemServiceVersionsPaths.vulnerabilityId}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <ProtectedSystemRoute
          path={`${path}${paths.systemServiceVersionsPaths.details}`}
          component={CurrentServiceVersionPage}
          scope={scopes.serviceVersion.versionViewForm}
        />

        <Route exact path={path} component={ServiceVersionsPage} />

        <Redirect to={path} />
      </Switch>
    </ProtectedFeatureRoute>
  );
}

export default SystemServiceVersionsRouter;
