import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import { dateConverter, dateIsExceeded } from 'utils';
import { useTranslation } from 'react-i18next';

function MilestoneContract({ contract, isOpenMilestone }) {
  const { t } = useTranslation();
  const messageForContarctDateIsExceeded = t('pages.systemAddMilestonePage.milestoneExpired');

  return (
    <div className="d-sm-flex flex-column">
      №{contract.number}&nbsp;
      <div className="d-felx flex-row text-gray-light nowrap">
        {`${dateConverter(contract.startDate)}–`}
        {isOpenMilestone && dateIsExceeded(contract.endDate) ? (
          <>
            <span className="text-red">{`${dateConverter(contract.endDate)}`}</span>
            <Tooltip
              content={messageForContarctDateIsExceeded}
              exitDelay={150}
              animationDuration={150}
            >
              <ExclamationCircleIcon className="ml-2 text-red" />
            </Tooltip>
          </>
        ) : (
          <>{`${dateConverter(contract.endDate)}`}</>
        )}
      </div>
    </div>
  );
}

export default MilestoneContract;
