import styled from 'styled-components';

import { DownloadIcon } from '@patternfly/react-icons';

export const DownloadButtonIcon = styled(DownloadIcon).attrs({
  className: 'font-16 text-gray-medium',
})`
  &:hover {
    color: #0066cc !important;
  }
`;
