import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 0,
    isLoading: true,
    isError: null,
    selectedUser: null,
    selectedUserRoles: [],
    selectedUserRolesTotal: 0,
    selectedUserError: null,
    isRevokeAllDisabled: true,
    selectedUserIsLoading: true,
    selectedUserRolesIsLoading: true,
    selectedUserRolesError: null,
    platformUsers: { data: [], total: 0, isLoading: true, isError: null },
  },
  reducers: {
    clearUsersState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.isError = null;
      state.platformUsers.data = [];
      state.platformUsers.total = 0;
      state.platformUsers.isLoading = true;
      state.platformUsers.isError = null;
    },
    clearSelectedUserState: state => {
      state.selectedUser = null;
      state.selectedUserIsLoading = true;
      state.selectedUserError = null;
      state.selectedUserRoles = [];
      state.selectedUserRolesTotal = 0;
      state.selectedUserRolesError = null;
      state.selectedUserRolesIsLoading = true;
    },
  },
  extraReducers: {
    [actions.fetchUsers.pending]: state => {
      state.platformUsers.isLoading = true;
      state.platformUsers.isError = null;
    },
    [actions.fetchUsers.fulfilled]: (state, { payload: { data, meta } }) => {
      state.platformUsers.data = data;
      state.platformUsers.total = meta.total;
      state.platformUsers.isLoading = false;
      state.platformUsers.isError = null;
    },
    [actions.fetchUsers.rejected]: (state, { error }) => {
      state.platformUsers.isLoading = false;

      if (checkedServerError(error.message)) {
        state.platformUsers.data = [];
        state.platformUsers.total = 0;
        state.platformUsers.isError = true;
      }
    },
    [actions.fetchSystemUsers.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchSystemUsers.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.total = meta.total;
      state.isLoading = false;
      state.isError = null;
    },
    [actions.fetchSystemUsers.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.data = [];
        state.total = 0;
        state.isError = true;
      }
    },

    [actions.fetchUserById.pending]: state => {
      state.selectedUserIsLoading = true;
      state.selectedUserError = null;
    },
    [actions.fetchUserById.fulfilled]: (state, { payload }) => {
      state.selectedUser = payload;
      state.selectedUserIsLoading = false;
      state.selectedUserError = null;
    },
    [actions.fetchUserById.rejected]: (state, { error }) => {
      state.selectedUserIsLoading = false;
      state.selectedUserError = error.message;
    },
    [actions.fetchUserRoles.pending]: state => {
      state.selectedUserRolesIsLoading = true;
      state.selectedUserRolesError = null;
    },
    [actions.fetchUserRoles.fulfilled]: (state, { payload: { data, meta } }) => {
      state.selectedUserRoles = data;
      state.selectedUserRolesTotal = meta.total;
      state.isRevokeAllDisabled = !!meta.responsibleTotal;
      state.selectedUserRolesIsLoading = false;
      state.selectedUserRolesError = null;
    },
    [actions.fetchUserRoles.rejected]: (state, { error }) => {
      state.selectedUserRolesIsLoading = false;

      if (checkedServerError(error.message)) {
        state.selectedUserRoles = [];
        state.selectedUserRolesTotal = 0;
        state.selectedUserRolesError = true;
      }
    },
  },
});

export const { clearUsersState, clearSelectedUserState } = usersSlice.actions;

export default usersSlice.reducer;
