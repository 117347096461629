import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import useSocketAsync from 'hooks/useSocketAsync';
import { fetchIssues } from 'store/issues/actions';
import { clearIssuesState } from 'store/issues/reducers';
import { dateConverter } from 'utils';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import StatusComponent, {
  getMilestoneStatus,
  getSystemStatuses,
  getTasksStatuses,
} from 'components/Common/StatusComponent';
import TotalSummary from 'components/Common/TotalSummary';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import { systemTypesKeys } from 'constants';

import IssueActions from '../IssueActions';
import IssueDates from './IssueDates';
import IssueClosedAtDate from './IssueClosedAtDate';

function IssuesTable({
  filterParams,
  checkedIssuesUuids,
  setcheckedIssuesUuids,
  setAllRenderUuids,
  changeAllIssuesClick,
  isMassChecked,
  setExcludedIssueIds,
  excludedIds,
  data,
  total,
  isLoading,
  deleteIssues,
  setIsShowFilters,
  changeAllIssues,
  hasSelectedFilters,
  withCheckboxes,
  addButton,
  isError,
  isShowFilters,
  currentTotal,
}) {
  const { t } = useTranslation();
  const milestoneStatus = getMilestoneStatus();
  const systemStatuses = getSystemStatuses();
  const tasksStatuses = getTasksStatuses();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const {
    params: { systemId, action, milestoneId },
  } = useRouteMatch();

  useSocketAsync({
    topic: 'issues',
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'milestone.name',
      label: t('common.milestone'),
      withSort: true,
      minWidth: '125px',
      isHidden: milestoneId,
    },
    {
      key: 'startDate',
      label: t('common.startDate'),
      withSort: true,
      right: true,
      minWidth: '125px',
    },
    {
      key: 'dueDate',
      label: t('common.plannedEndDate'),
      withSort: true,
      right: true,
    },
    {
      key: 'closedAt',
      label: t('pages.systemIssuesPage.closingDate'),
      withSort: true,
      right: true,
    },
    {
      key: 'releases',
      label: t('common.releases'),
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      withSort: true,
      minWidth: '150px',
      isHidden: systemId,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  function requestFunction(requestParameters) {
    dispatch(fetchIssues(requestParameters));
  }

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      ascending: true,
      orderBy: 'startDate',
      system: systemId,
      ...filterParams?.date,
      date: undefined,
      ...(milestoneId
        ? {
            milestoneUuid: milestoneId,
          }
        : {}),
    }),
    [filterParams, milestoneId, action, systemId]
  );

  useEffect(() => {
    if (deleteIssues.isSuccess || changeAllIssues.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [deleteIssues.isSuccess, changeAllIssues.isSuccess]);

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters(hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, hasSelectedFilters]);

  useEffect(() => () => dispatch(clearIssuesState()), []);

  const onClickGeneratePath = (issue, tab) => ({
    pathname: generatePath(
      milestoneId
        ? paths.systemFullPaths.issuesId
        : paths.routePaths.system + paths.systemIssuesFullPaths.issues,
      {
        systemId: issue.system.uuid,
        systemType: issue.system.type,
        milestoneId,
        issuesId: issue.uuid,
      }
    ),
    state: {
      currentTab: tab,
    },
  });

  const formattedBodyData = data?.map(issue => ({
    id: issue.uuid,
    data: [
      {
        key: 'name',
        content: (
          <LinkWithPermission
            system={issue?.system}
            scope={scopes.issue.issueViewForm}
            to={generatePath(
              milestoneId
                ? paths.systemFullPaths.issuesId
                : paths.routePaths.system + paths.systemIssuesFullPaths.issues,
              {
                systemId: issue.system.uuid,
                systemType: issue.system.type,
                milestoneId,
                issuesId: issue.uuid,
              }
            )}
            isTableLink
          >
            {issue.name}
          </LinkWithPermission>
        ),
      },
      {
        key: 'milestone.name',
        content: (
          <LinkWithPermission
            system={issue?.system}
            scope={scopes.milestone.milestoneViewForm}
            to={generatePath(paths.routePaths.system + paths.systemMilestonesFullPaths.milestone, {
              systemId: issue.system.uuid,
              systemType: issue.system.type,
              milestoneId: issue.milestone.uuid,
            })}
            isTableLink
          >
            {issue.milestone?.status ? (
              <StatusComponent
                statusId={issue.milestone?.status}
                statusWithTooltip
                statuses={milestoneStatus}
              >
                {issue.milestone.name}
              </StatusComponent>
            ) : (
              issue.milestone.name
            )}
          </LinkWithPermission>
        ),
      },
      {
        key: 'startDate',
        content: dateConverter(issue.startDate),
        type: types.rightContent,
      },
      {
        key: 'dueDate',
        content: (
          <IssueDates
            startDate={issue.startDate}
            dueDate={issue.dueDate}
            closedAt={issue.closedAt}
            milestoneEndDate={issue.milestone?.endDate}
            rightContent
          />
        ),
      },
      {
        key: 'closedAt',
        content: (
          <IssueClosedAtDate dueDate={issue.dueDate} closedAt={issue.closedAt} rightContent />
        ),
      },
      {
        key: 'releases',
        content: (
          <LinkWithPermission
            system={issue?.system}
            scope={scopes.issue.issueViewForm}
            to={onClickGeneratePath(issue, 1)}
            isTableLink
          >
            <TotalSummary total={issue.totalSummary.releaseTotal.total} isReleaseTotal />
          </LinkWithPermission>
        ),
      },
      {
        key: 'system.shortName',
        content: issue?.system?.shortName && (
          <LinkWithPermission
            system={issue?.system}
            scope={scopes.system.systemViewForm}
            to={generatePath(paths.routePaths.system, {
              systemId: issue.system.uuid,
              systemType: issue.system.type,
            })}
            isTableLink
          >
            <StatusComponent
              statusId={
                issue.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : issue.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {issue.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'status',
        content: issue?.status && (
          <StatusComponent statusId={issue.status} statuses={tasksStatuses} />
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={issue?.lastUpdatedBy?.uuid}
            system={issue?.system}
            isTableLink
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: issue.lastUpdatedAt,
      },
      {
        key: 'dropdownMenu',
        content: (
          <IssueActions
            issue={issue}
            deleteIssues={deleteIssues}
            changeAllIssuesClick={changeAllIssuesClick}
            setcheckedIssuesUuids={setcheckedIssuesUuids}
            checkedUuids={checkedIssuesUuids}
          />
        ),
      },
    ],
  }));

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      requestFunction={requestFunction}
      requestParams={requestParams}
      withStandartPagination
      total={total}
      withCheckboxes={withCheckboxes}
      checkedUuids={checkedIssuesUuids}
      setCheckedUuids={setcheckedIssuesUuids}
      isMassChecked={isMassChecked}
      setExcludedIds={setExcludedIssueIds}
      excludedIds={excludedIds}
      setAllRenderUuids={setAllRenderUuids}
      tableName="issues"
      isLoading={isLoading}
      hasSelectedFilters={hasSelectedFilters}
      addButton={addButton}
      showNoDataPerFiltersMessage={isShowFilters}
      isError={isError}
    />
  );
}

export default IssuesTable;
