import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Table, Thead, Tr } from '@patternfly/react-table';

import useTableSearchParams from 'components/Common/DataTable/hooks/useTableSearchParams';

import useInfinitePagination from '../DataTable/hooks/useInfinitePagination';
import LoadingIndicator from '../../UI/LoadingIndicator';

import TablePagination from './TablePagination';
import BodyData from './BodyData';
import types from './types';
import EmptyTable from './EmptyTable';

import * as S from './styles';

export { types };

export const modifierKeys = {
  wrap: 'wrap',
  fitContent: 'fitContent',
  truncate: 'truncate',
  breakWord: 'breakWord',
  nowrap: 'nowrap',
};

const NewDataTable = React.forwardRef(
  (
    {
      headerData = [],
      bodyData = [],
      requestFunction,
      requestParams,
      isLoading,
      total,
      tableName,
      withWebsockets = false,
      // pagination
      withStandartPagination = false,
      withInfinitePagination = false,
      // style props
      withSidesMargin,
      compactVariant = 'compact',
      withoutTableHead,
      // expanded state
      expandedKey,
      expandedRowsCount,
      // checkbox
      withCheckboxes,
      checkedUuids,
      setCheckedUuids,
      setAllRenderUuids,
      isMassChecked,
      setExcludedIds,
      excludedIds,
      hasSelectedFilters,
      addButton,
      showNoDataPerFiltersMessage,
      emptyTableMessage,
      isError,
    },
    ref
  ) => {
    const headerDataToShow = headerData.filter(header => !header.isHidden);
    const bodyDataToShow = bodyData.map(item => ({
      ...item,
      data: item.data.filter(data => headerDataToShow.find(header => header.key === data.key)),
    }));
    const columnCount = headerDataToShow.length;
    const rowsCount = bodyDataToShow.length;

    useEffect(() => {
      const allIds = bodyData
        .filter(({ isCheckboxDisabled }) => !isCheckboxDisabled)
        .map(({ id }) => id);

      if (isMassChecked && !isLoading) {
        setTimeout(() => {
          if (setCheckedUuids) {
            setCheckedUuids(allIds.filter(item => !excludedIds.includes(item)));
            setAllRenderUuids(allIds);
          }
        }, 100);
      } else if (setAllRenderUuids) {
        setAllRenderUuids(allIds);
      }
    }, [bodyData.length, isMassChecked, excludedIds, isLoading]);

    const {
      params,
      paginationParams,
      sortParams,
      loadMore,
      changeSortParams,
      changePaginationParams,
    } = useTableSearchParams(
      requestFunction,
      requestParams,
      tableName,
      withInfinitePagination,
      withWebsockets
    );

    const [activeSortIndex, setActiveSortIndex] = useState(null);
    const [activeSortDirection, setActiveSortDirection] = useState(null);
    const [silentMode, setSilentMode] = useState(false);

    useEffect(() => {
      if (sortParams?.orderBy) {
        const findIndex = headerDataToShow.findIndex(el => el.key === sortParams.orderBy);
        setActiveSortIndex(findIndex >= 0 ? findIndex : null);
        setActiveSortDirection(sortParams.ascending ? 'asc' : 'desc');
        setSilentMode(false);
      }
    }, [sortParams]);

    // Intersection scroll pagination hook
    const { ref: paginationRef } = useInfinitePagination({
      rowsCount,
      withInfinitePagination,
      loadMore,
      total,
    });

    const requestDataWithParams = options => {
      if (requestFunction) {
        if (options?.isSilentMode) {
          setSilentMode(options.isSilentMode);
        }
        requestFunction({ ...params, ...paginationParams, ...sortParams });
      }
    };

    useImperativeHandle(ref, () => ({
      requestDataWithParams,
    }));

    const getSortParams = (columnIndex, key) => ({
      sortBy: {
        index: activeSortIndex,
        direction: activeSortDirection,
        defaultDirection: 'asc',
      },
      onSort: (_event, index) => {
        setActiveSortIndex(index);

        const newSort = {
          orderBy: key,
          ascending: sortParams?.orderBy === key ? !sortParams?.ascending : false,
        };

        changeSortParams?.(newSort);
      },
      columnIndex,
    });

    return (
      <S.TableExternalWrapper isEmpty={!bodyDataToShow.length}>
        {isLoading && !silentMode && (
          <S.TableSpinner>
            <LoadingIndicator />
          </S.TableSpinner>
        )}
        <S.TableWrapper withSidesMargin={withSidesMargin}>
          <Table
            aria-label={tableName}
            variant={compactVariant ? 'compact' : undefined}
            gridBreakPoint=""
          >
            <Thead>
              {(!!bodyDataToShow.length ||
                !!showNoDataPerFiltersMessage ||
                !!hasSelectedFilters) && (
                <Tr>
                  {withCheckboxes && <S.ThCheckboxesStyled />}
                  {headerDataToShow.map((item, index) => (
                    <S.ThStyled
                      modifier={item?.modifier}
                      key={item.key}
                      sort={item?.withSort && getSortParams(index, item.key)}
                      width={item?.width}
                      colSpan={item.colSpan}
                      $minWidth={item?.minWidth}
                      $right={item.right}
                      info={
                        item?.tooltip && {
                          tooltip: item.tooltip,
                        }
                      }
                    >
                      <div
                        className={`d-flex align-items-end ${item.className || ''} ${
                          item.right && !item.withSort && 'float-right'
                        }`}
                      >
                        {(!withoutTableHead && item.label) || ' '}
                      </div>
                    </S.ThStyled>
                  ))}
                </Tr>
              )}
            </Thead>

            <tbody>
              {bodyDataToShow.map((items, index) => (
                <BodyData
                  key={items.id}
                  items={items}
                  index={index}
                  ref={paginationRef}
                  columnCount={expandedRowsCount || columnCount}
                  expandedKey={expandedKey}
                  params={params}
                  withInfinitePagination={withInfinitePagination}
                  withoutTableHead={withoutTableHead}
                  // checkbox
                  withCheckboxes={withCheckboxes}
                  checkedUuids={checkedUuids}
                  setCheckedUuids={setCheckedUuids}
                  isMassChecked={isMassChecked}
                  setExcludedIds={setExcludedIds}
                  excludedIds={excludedIds}
                />
              ))}
            </tbody>
          </Table>
        </S.TableWrapper>
        {bodyDataToShow.length === 0 && !isLoading && (
          <div className="w-100 p-3">
            <EmptyTable
              showNoDataPerFiltersMessage={showNoDataPerFiltersMessage || hasSelectedFilters}
              emptyTableMessage={emptyTableMessage}
              tableName={tableName}
              addButton={addButton}
              isError={isError}
            />
          </div>
        )}
        {withStandartPagination && paginationParams && bodyDataToShow.length > 0 && (
          <TablePagination
            total={total}
            paginationParams={paginationParams}
            changePaginationParams={changePaginationParams}
            expandedKey={expandedKey}
            tableName={tableName}
          />
        )}
      </S.TableExternalWrapper>
    );
  }
);

export default React.memo(NewDataTable);
