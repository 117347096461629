import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import Notifications from 'components/Notifications';
import { currentSystemSelector } from 'store/systems/selectors';
import C from 'constants/C';
import api from 'api';
import * as NotificationHelpers from 'components/Notifications/helpers';
import { currentTierSelector } from 'store/tiers/selectors';

class NotificationsPage extends Component {
  state = {
    notifications: [],
    showCalendar: false,
    showDateForm: false,
    startDate: startOfDay(Date.now()).getTime(),
    tiers: [],
    types: undefined,
    status: C.common.notifications.status.ALL,
    stands: undefined,
    systemId: undefined,
    endDate: endOfDay(Date.now()).getTime(),
  };

  componentDidMount() {
    const { systemId, tierId } = this.props.match.params;

    this.setState(
      {
        systemId,
        stands: tierId || undefined,
      },
      () => {
        this.loadFilteredNotifications();
      }
    );
  }

  loadFilteredNotifications = () => {
    api.notifications
      .getNotifications({
        readType: this.state.status,
        reading: true,
        icons: this.state.types === 'ALL' ? undefined : this.state.types,
        tier: this.state.stands,
        system: this.state.systemId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(response => {
        this.setState({
          notifications: NotificationHelpers.compareNotificationByDate(response),
        });
      });
  };

  onFilterPeriodChange = value => {
    const startDate = startOfDay(Date.now()).getTime();
    const endDate = endOfDay(Date.now()).getTime();
    if (value === 'custom') {
      this.setState({
        startDate: new Date(),
        endDate: addDays(new Date(), C.common.notifications.calendarSettings.DEFAULT_RANGE),
        showCalendar: true,
        showDateForm: true,
      });
    } else if (value === 'today') {
      this.setState(
        {
          startDate,
          endDate,
          showCalendar: false,
          showDateForm: false,
        },
        () => {
          this.loadFilteredNotifications();
        }
      );
    } else {
      this.setState(
        {
          startDate: undefined,
          endDate: undefined,
          showCalendar: false,
          showDateForm: false,
        },
        () => {
          this.loadFilteredNotifications();
        }
      );
    }
  };

  onFilterStatusChange = value => {
    this.setState(
      {
        status: value || undefined,
      },
      () => {
        this.loadFilteredNotifications();
      }
    );
  };

  onFilterTypeChange = value => {
    this.setState(
      {
        types: value || undefined,
      },
      () => {
        this.loadFilteredNotifications();
      }
    );
  };

  onFilterStandChange = value => {
    this.setState(
      {
        stands: value || undefined,
      },
      () => {
        this.loadFilteredNotifications();
      }
    );
  };

  toggleCalendar = () => {
    this.setState(prevState => ({
      showCalendar: !prevState.showCalendar,
    }));
  };

  saveDate = range => {
    this.setState(
      {
        showCalendar: false,
        showDateForm: true,
        startDate: new Date(range[0].startDate).getTime(),
        endDate: endOfDay(new Date(range[0].endDate)).getTime(),
      },
      () => {
        this.loadFilteredNotifications();
      }
    );
  };

  cancelDate = () => {
    this.setState({
      showCalendar: false,
    });
  };

  render() {
    return (
      <Notifications
        notifications={this.state.notifications}
        onFilterPeriodChange={this.onFilterPeriodChange}
        onFilterStatusChange={this.onFilterStatusChange}
        onFilterTypeChange={this.onFilterTypeChange}
        onFilterStandChange={this.onFilterStandChange}
        history={this.props.history}
        showCalendar={this.state.showCalendar}
        showDateForm={this.state.showDateForm}
        startDate={this.state.startDate}
        tiers={this.state.tiers}
        endDate={this.state.endDate}
        saveDate={this.saveDate}
        cancelDate={this.cancelDate}
        toggleCalendar={this.toggleCalendar}
        title={this.state.stands ? this.props.currentTier?.name : this.props.currentSystem?.name}
        systemId={this.state.systemId}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentSystem: currentSystemSelector(state),
  currentTier: currentTierSelector(state),
});

export default connect(mapStateToProps, null)(NotificationsPage);
