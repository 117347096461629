import React, { useEffect, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { dateIsExceeded } from 'utils';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useModal from 'hooks/useModal';
import { milestoneStatusKeys } from 'constants/index';
import { milestonesAction } from 'constants/paths/systemMilestonesPaths';
import WarningModal from 'components/Common/WarningModal';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';
import { featureFlags } from 'constants/features';

function MilestoneActions({ milestone, changeMilestones, deleteMilestone, isBlueButtonDropdown }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const deleteMilestoneModal = useModal();
  const { notActiveSystemMessage, notActiveOrErrorSystemMessage, optionsFilterByScopes } =
    useUserPermissions(milestone.system);

  const isOpenMilestone =
    milestone.status === milestoneStatusKeys.OPEN ||
    milestone.status === milestoneStatusKeys.OVERDUE;
  const newIssues =
    milestone.totalSummary.issuesTotal.new +
    milestone.totalSummary.issuesTotal.inProgress +
    milestone.totalSummary.issuesTotal.implemented;

  const optionData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'add',
          name: t('pages.systemAddMilestonePage.addIssue'),
          isHidden: !featureFlags.isIssuesFeatureEnabled || !isOpenMilestone,
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
          scope: scopes.issue.issueCreate,
        },

        {
          id: 'edit',
          name: t('common.edit'),
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
          scope: scopes.milestone.milestoneUpdate,
        },
        {
          id: 'change',
          name: isOpenMilestone ? t('common.close') : t('common.open'),
          isDisabled: notActiveSystemMessage || (!!newIssues && isOpenMilestone),
          tooltip:
            notActiveSystemMessage || t('pages.systemAddMilestonePage.unresolvedIssuesIncluded'),
          scope: scopes.milestone.milestoneClose,
        },
        {
          id: 'delete',
          name: t('common.delete'),
          isDisabled:
            notActiveOrErrorSystemMessage || !!milestone.totalSummary.issuesTotal.released,
          tooltip:
            notActiveOrErrorSystemMessage ||
            t('pages.systemAddMilestonePage.releaseIssuesIncluded'),
          scope: scopes.milestone.milestoneDelete,
        },
      ]),
    [
      newIssues,
      isOpenMilestone,
      notActiveSystemMessage,
      notActiveOrErrorSystemMessage,
      optionsFilterByScopes,
      i18n.language,
    ]
  );

  const onChangeMilestoneClick = () => {
    const newStatus = dateIsExceeded(milestone.endDate)
      ? milestoneStatusKeys.OVERDUE
      : milestoneStatusKeys.OPEN;
    changeMilestones.run(
      api.milestones.changeMilestone(milestone.uuid, {
        status: isOpenMilestone ? milestoneStatusKeys.CLOSED : newStatus,
      })
    );
  };

  const onFilterChange = id => {
    if (id === 'add') {
      return history.push(
        generatePath(paths.routePaths.system + paths.systemMilestonesFullPaths.milestoneIssuesNew, {
          systemId: milestone.system.uuid,
          systemType: milestone.system.type,
          milestoneId: milestone.uuid,
          action: 'new',
        })
      );
    }

    if (id === 'edit') {
      return history.push(
        generatePath(paths.routePaths.system + paths.systemMilestonesFullPaths.edit, {
          systemId: milestone.system.uuid,
          systemType: milestone.system.type,
          milestoneId: milestone.uuid,
          action: milestonesAction.edit,
        })
      );
    }

    if (id === 'delete') {
      deleteMilestoneModal.setModalData(milestone);
      return deleteMilestoneModal.toggleModal();
    }

    return onChangeMilestoneClick();
  };
  const onDeleteMilestone = () =>
    deleteMilestone.run(api.milestones.deleteMilestone(milestone.uuid));

  useEffect(() => {
    if (deleteMilestone.isSuccess) {
      deleteMilestoneModal.setIsModalVisible(false);
    }
  }, [deleteMilestone.isSuccess]);

  return (
    <div className="float-right">
      {!!optionData?.length && (
        <Dropdown
          id={milestone.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          buttonLabel={t('pages.systemAddMilestonePage.changeMilestone', [milestone.name])}
        />
      )}

      <WarningModal
        title={t('pages.systemAddMilestonePage.deletingMilestone')}
        text={t('pages.systemAddMilestonePage.deleteMilestone')}
        name={milestone?.name}
        label={t('pages.systemAddMilestonePage.milestoneName')}
        isLoading={deleteMilestone.isLoading}
        buttenText={t('common.delete')}
        onClick={onDeleteMilestone}
        {...deleteMilestoneModal}
      />
    </div>
  );
}

export default MilestoneActions;
