import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { getTierTypeNames } from 'constants';

import * as S from './styles';

function DevTiersLabel() {
  const { t } = useTranslation();
  const tierTypeNames = getTierTypeNames();

  return (
    <Tooltip content={tierTypeNames.dev} exitDelay={150} animationDuration={150}>
      <S.LabelIcon>{t('pages.systemTierPage.devTierShortLabel')}</S.LabelIcon>
    </Tooltip>
  );
}

export default DevTiersLabel;
