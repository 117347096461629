import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ReactModalStyled = styled(ReactModal)`
  position: absolute;
  left: 37%;
  top: 25%;
  border: 1px solid #cccccc;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 0px;
`;

export const DeployBlockWrapper = styled.div.attrs({
  className: 'px-4 pt-1',
})`
  min-width: 590px;
  gap: 20px;
`;
