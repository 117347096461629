import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import useAsync from 'hooks/useAsync';

import { ReactComponent as VectorRightSvg } from 'assets/images/integrations/VectorRight.svg';
import { ReactComponent as VectorLeftSvg } from 'assets/images/integrations/VectorLeft.svg';
import BondTiersForm from './BondTiersForm';

import {
  TextWrapper,
  SystemsNamesText,
  VectorWrapper,
  MessageWrapper,
  SectionWithButton,
  AddNewSectionButton,
} from '../styles';

const dynamicParentField = 'integrationTiers';

function IntegrationTiers({
  withoutTitle,
  formMethods,
  isCantChangeForm,
  isNotValidate,
  setCurrentStepId,
}) {
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField);
  const { watch } = useFormContext();
  const message = `${t('pages.systemAddIntegrationPage.addRelationTooltip')}`;

  const isSystemFromTiers = useAsync();
  const isSystemToTiers = useAsync();

  const isSystemFrom = watch(`systemFrom`);
  const isSystemTo = watch(`systemTo`);
  const isInbound = watch(`inbound`);

  useEffect(() => {
    setCurrentStepId(3);
    if (isSystemFrom?.uuid && isSystemTo?.uuid) {
      isSystemFromTiers.run(
        api.integrations.allIntegrationTiersOptions({ system: isSystemFrom.uuid })
      );
      isSystemToTiers.run(api.integrations.allIntegrationTiersOptions({ system: isSystemTo.uuid }));
    }
  }, [isSystemFrom, isSystemTo]);

  const titleMessage = (
    <>
      <div className="font-20 font-weight-medium">
        {t('pages.systemAddServicePage.isIntegrationService')}
      </div>

      <SystemsNamesText>
        <TextWrapper>{t('pages.systemAddIntegrationPage.interactionBetween')}&nbsp;</TextWrapper>
        {isInbound ? isSystemTo?.name : isSystemFrom?.name}
        <VectorWrapper>{isInbound ? <VectorLeftSvg /> : <VectorRightSvg />}</VectorWrapper>
        {isInbound ? isSystemFrom?.name : isSystemTo?.name}
      </SystemsNamesText>

      <MessageWrapper>{message}</MessageWrapper>
    </>
  );

  return (
    <>
      {!withoutTitle && titleMessage}
      {fieldUuids.map((fieldUuid, index) => (
        <BondTiersForm
          key={fieldUuid}
          fieldUuids={fieldUuids}
          fieldUuid={fieldUuid}
          index={index}
          deleteField={deleteField}
          isSystemFrom={isSystemFrom}
          isSystemTo={isSystemTo}
          isSystemFromTiers={isSystemFromTiers}
          isSystemToTiers={isSystemToTiers}
          formMethods={formMethods}
          isCantChangeForm={isCantChangeForm}
          isNotValidate={isNotValidate}
        />
      ))}
      <SectionWithButton>
        <AddNewSectionButton onClick={addField}>
          {t('pages.systemAddIntegrationPage.addRelation')}
        </AddNewSectionButton>
      </SectionWithButton>
    </>
  );
}

export default IntegrationTiers;
