import styled from 'styled-components';

export const EditLastVersionWrapper = styled.div.attrs({
  className: 'd-flex',
})`
  min-height: 42px;
`;

export const DescriptionWrapper = styled.div.attrs({
  className: 'font-12 text-gray-light',
})`
  white-space: break-spaces;
  text-align: start;

  margin-left: ${({ $marginLeft }) => ($marginLeft ? '32px' : '0')};
`;
