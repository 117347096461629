import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Alert, Button, Tooltip } from '@patternfly/react-core';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUploadButton from 'hooks/useUploadButton';
import { notActiveSystemSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { dateConverter } from 'utils';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { documentsTypeKeys, milestoneStatusKeys } from 'constants';
import { InputBlockWrapper } from 'components/UI/View';
import TextBlock from 'components/UI/TextBlock';
import { getDefaultMaxMessage, tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

const issuesModel = {
  name: '',
  description: '',
  dueDate: '',
};

function EditIssuesPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { systemId, milestoneId, issuesId, action },
  } = useRouteMatch();
  const { run, data } = useAsync();
  const getMilestonesOptions = useAsync();
  const changeIssue = useAsync();
  const deleteIssue = useAsync();
  const getMilestone = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const formMethods = useForm({
    defaultValues: issuesModel,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const valueStartDate = formMethods.watch('startDate');
  const valueDueDate = formMethods.watch('dueDate');
  const valueMilestoneUuid = formMethods.watch('milestone.uuid');
  const isEditAction = action === paths.issuesAction.edit;

  const { UploadButtonComponent, changeFilesOnEditSubmit } = useUploadButton({
    objectType: documentsTypeKeys.ISSUE,
    setValue: formMethods.setValue,
    filesToShow: data?.documents,
    isEditAction,
  });

  function requestFunction() {
    run(api.issues.getIssuesById(issuesId));
  }

  const title = isEditAction
    ? t('pages.systemIssuesPage.edit')
    : t('pages.systemIssuesPage.create');
  const currentMilestone = useMemo(
    () => getMilestonesOptions.data?.data?.find(item => item.uuid === valueMilestoneUuid),
    [getMilestonesOptions.data?.data, valueMilestoneUuid, i18n.language]
  );

  const formatOption = option =>
    option?.uuid && {
      value: option.uuid,
      label: option?.name,
    };

  const checkedValue = useMemo(
    () => currentMilestone && formatOption(currentMilestone),
    [currentMilestone, i18n.language]
  );
  const milestonesOptions = useMemo(
    () => getMilestonesOptions.data?.data?.map(milestone => formatOption(milestone)),

    [getMilestonesOptions.data?.data, i18n.language]
  );

  const optionsRequestParams = useMemo(
    () => ({
      system: systemId,
      ascending: true,
      orderBy: 'name',
      status: [milestoneStatusKeys.OPEN, milestoneStatusKeys.OVERDUE],
      limit: 10,
    }),
    [systemId]
  );

  const milestoneContract = (
    <>
      {currentMilestone?.contract && (
        <span className="font-14 text-gray-light">{`${t('common.contract')}: №${
          currentMilestone.contract.number
        },  ${t('pages.systemIssuesPage.expiration')}: ${dateConverter(
          currentMilestone.contract.startDate
        )} – ${dateConverter(currentMilestone.contract.endDate)}`}</span>
      )}
    </>
  );

  const warningAlert = useMemo(() => {
    if (valueStartDate && currentMilestone?.startDate > valueStartDate) {
      return t('pages.systemIssuesPage.issueBeforeMilestoneError');
    }

    if (valueStartDate && currentMilestone?.endDate < valueStartDate) {
      return t('pages.systemIssuesPage.issueAfterMilestoneError');
    }

    if (currentMilestone?.endDate < valueDueDate) {
      return t('pages.systemIssuesPage.issueFinishAfterMilestoneError');
    }
  }, [currentMilestone, valueStartDate, valueDueDate, i18n.language]);

  const searchFilter = async q => {
    try {
      const filterOptions = await getMilestonesOptions.run(
        api.milestones.getAllMilestonesOptions({
          ...optionsRequestParams,
          q,
        })
      );

      return generateSelectOptions(filterOptions.data, 'uuid', 'name');
    } catch (e) {
      return [];
    }
  };

  function requestOptionsFunction(params) {
    getMilestonesOptions.run(
      api.milestones.getAllMilestonesOptions({
        ...optionsRequestParams,
        ...params,
      })
    );
  }

  useEffect(() => {
    if (isEditAction) {
      requestFunction();
    }
    if (milestoneId) {
      formMethods.reset({
        ...formMethods.getValues(),
        milestone: { uuid: milestoneId },
      });
    }
    requestOptionsFunction();
  }, []);

  useEffect(() => {
    if (data && isEditAction) {
      formMethods.reset({
        milestone: { uuid: milestoneId || data.milestone.uuid, name: data.milestone.name },
        name: data.name,
        description: data.description,
        startDate: data?.startDate,
        dueDate: data.dueDate,
      });
    }
  }, [data]);

  useEffect(() => {
    if (valueMilestoneUuid) {
      getMilestone.run(api.milestones.getMilestonesById(valueMilestoneUuid));
    }
  }, [valueMilestoneUuid]);

  useEffect(() => {
    if (changeIssue.isSuccess) {
      history.goBack();
    }
  }, [changeIssue.isSuccess]);

  useEffect(() => {
    if (deleteIssue.isSuccess) {
      history.goBack();
    }
  }, [deleteIssue.isSuccess]);

  useEffect(() => {
    if (valueStartDate > valueDueDate) {
      formMethods.setValue(`dueDate`, undefined);
    }
  }, [valueStartDate, valueDueDate]);

  const onSubmit = ({
    name,
    description,
    startDate,
    dueDate,
    milestone,
    documentsDescription,
    addedDocuments,
    removedDocuments,
  }) => {
    const requestSubmitFunction = isEditAction ? api.issues.editIssue : api.issues.createIssue;

    const formatedDocuments = addedDocuments?.map(file => ({
      ...file,
      description: documentsDescription,
    }));

    const formatedData = {
      addedDocuments: formatedDocuments?.length ? formatedDocuments : undefined,
      removedDocuments: removedDocuments?.length ? removedDocuments : undefined,
      name,
      description: description || undefined,
      startDate: startDate || undefined,
      dueDate: dueDate || undefined,
      milestone: { uuid: milestone.uuid || milestoneId || data.milestone.uuid },
      uuid: issuesId || uuid(),
      system: { uuid: systemId },
    };

    changeFilesOnEditSubmit(documentsDescription);

    changeIssue.run(requestSubmitFunction(formatedData, issuesId));
  };

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  const isDisabled = notActiveSystemMessage || warningAlert || !formMethods.formState.isValid;

  return (
    <FormProvider {...formMethods}>
      <PageLayout title={title} fullHeight withSystemSelector withoutSidePadding withoutFlex>
        <S.TasksForm onSubmit={formMethods.handleSubmit(onSubmit)}>
          <S.TasksBlockWrapper>
            {milestoneId ? (
              <TextBlock greyColor title={t('common.milestone')} isForm>
                <div className="pre-line medium-line-height font-16">
                  {`${currentMilestone?.name} ${t(
                    'pages.systemAddMilestonePage.term'
                  )}: ${dateConverter(currentMilestone?.startDate)}–${dateConverter(
                    currentMilestone?.endDate
                  )}`}
                  <div className="font-12 font-weight-normal text-gray mt-1">
                    {milestoneContract}
                  </div>
                </div>
              </TextBlock>
            ) : (
              <div>
                <InputComponent
                  label={t('common.milestone')}
                  name="milestone.uuid"
                  component={inputComponents.newSelect}
                  newSelectVariant="typeahead"
                  searchFilter={searchFilter}
                  options={milestonesOptions}
                  checkedValue={checkedValue}
                  withInfiniteScroll={{
                    total: getMilestonesOptions.data?.meta?.total,
                    offset: 10,
                    loadMore: requestOptionsFunction,
                  }}
                  placeholder={t('common.selectOrWriteManually')}
                  isRequired
                  isLoading={getMilestonesOptions.isLoading}
                />
                {milestoneContract}
              </div>
            )}
            <InputComponent
              label={t('common.name')}
              name="name"
              maxLength={150}
              isRequired
              description={getDefaultMaxMessage()}
            />
            <InputComponent
              label={t('common.description')}
              name="description"
              maxLength={1000}
              component={inputComponents.textarea}
              description={t('validation.maxLength', [1000])}
            />

            <InputBlockWrapper columnsCount={2}>
              <InputComponent
                label={t('common.startDate')}
                name="startDate"
                component={inputComponents.dateInput}
                minDate={valueMilestoneUuid ? currentMilestone?.contract?.startDate : undefined}
                maxDate={valueMilestoneUuid ? currentMilestone?.contract?.endDate : undefined}
                disabled={!valueMilestoneUuid}
              />
              <InputComponent
                label={t('common.plannedEndDate')}
                name="dueDate"
                component={inputComponents.dateInput}
                minDate={
                  valueMilestoneUuid
                    ? valueStartDate || currentMilestone?.contract?.startDate
                    : undefined
                }
                maxDate={valueMilestoneUuid ? currentMilestone?.contract?.endDate : undefined}
                disabled={!valueMilestoneUuid}
              />
            </InputBlockWrapper>

            {warningAlert && <Alert variant="warning" isInline title={warningAlert} />}

            {UploadButtonComponent}

            <ActionListGroup>
              <Tooltip
                className={isDisabled ? undefined : 'd-none'}
                content={notActiveSystemMessage || (isDisabled && tooltipFormDisableMessage())}
                exitDelay={150}
                animationDuration={150}
              >
                <ActionListItem>
                  <Button
                    onClick={formMethods.handleSubmit(onSubmit)}
                    isLoading={formMethods.formState.isSubmitting || changeIssue.isLoading}
                    isDisabled={
                      formMethods.formState.isSubmitting || changeIssue.isLoading || isDisabled
                    }
                  >
                    {isEditAction ? t('common.saveChanges') : t('common.add')}
                  </Button>
                </ActionListItem>
              </Tooltip>
              <ActionListItem>
                <Button variant="link" className="ml-3" onClick={closeForm}>
                  {t('common.cancel')}
                </Button>
              </ActionListItem>
            </ActionListGroup>
          </S.TasksBlockWrapper>
        </S.TasksForm>
      </PageLayout>
    </FormProvider>
  );
}

export default EditIssuesPage;
