import React from 'react';
import { Controller } from 'react-hook-form';

import { CheckboxInput } from './styles';

const CheckboxController = ({ name, control, rules, label, disabled, ...inputProps }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) => (
      <CheckboxInput
        data-checkbox-for={label}
        {...field}
        {...inputProps}
        disabled={disabled}
        checked={Boolean(field.value)}
      />
    )}
  />
);

export default CheckboxController;
