import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Alert, Button, Tooltip } from '@patternfly/react-core';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { checkedUrlValue } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { currentServerTierSelector } from 'store/serverTiers/selectors';
import { fetchServerTier } from 'store/serverTiers/actions';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { BlueButton } from 'components/UI/Button';
import PageLayout from 'components/UI/PageLayout';
import { RowView } from 'components/UI/View';
import {
  getServerTierClusterUrlValidation,
  getServerTierNameValidation,
  getValidUrlValidation,
  serverTiersFieldsValidateMessage,
  systemAndTierCodeRegex,
  tooltipFormDisableMessage,
} from 'constants/vaidations';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import {
  serverTierTypeKeys,
  socketActionKeys,
  serverTierStatusKeys,
  protocolNames,
} from 'constants';
import { getProgressServerTierMessage } from 'constants/tooltips';
import paths from 'constants/paths';

import * as S from './styles';

const typeOptions = [
  { id: serverTierTypeKeys.OPENSHIFT, name: 'Openshift' },
  { id: serverTierTypeKeys.OKD, name: 'OKD' },
  { id: serverTierTypeKeys.RANCHER, name: 'Rancher' },
  { id: serverTierTypeKeys.KUBERNETES, name: 'Kubernetes' },
];

const defaultValues = {
  devices: [],
  openShiftURL: '',
  token: '',
  enableSsl: false,
  ingressController: false,
  type: '',
  consoleUrl: '-',
};

function ChangeServerTierPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { serverTierId, action },
  } = useRouteMatch();
  const alert = useAlert();

  const { run, isSuccess, isLoading } = useAsync();
  const currentServerTier = useSelector(currentServerTierSelector);
  const [testValue, setTestValue] = useState({ connection: null });
  const [isValidConsoleUrl, setIsValidConsoleUrl] = useState(true);
  const [isValidClusterType, setIsValidClusterType] = useState(true);
  const [isActiveCluster, setIsActiveCluster] = useState(true);
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const clusterApiUrlValue = formMethods.watch('clusterApiUrl');
  const tokenValue = formMethods.watch('token');
  const typeValue = formMethods.watch('type');
  const consoleUrlValue = formMethods.watch('consoleUrl');
  const monitoringUrlValue = formMethods.watch('monitoringUrl');

  const enableSslValue = formMethods.watch('enableSsl');
  const nameValue = formMethods.watch('name');
  const serverTierCNameValidation = useMemo(
    () => getServerTierNameValidation(serverTierId),
    [serverTierId]
  );
  const serverTierClusterUrlValidation = useMemo(
    () => getServerTierClusterUrlValidation(serverTierId),
    [serverTierId]
  );
  const isNotEditAction = !serverTierId && action !== paths.serverTiersActions.edit;
  const notActiveClusterMessage = !isActiveCluster && getProgressServerTierMessage();
  const title =
    (action === paths.serverTiersActions.edit && t('pages.adminServerTiers.edit')) ||
    (serverTierId && `${currentServerTier?.name}`) ||
    t('pages.adminServerTiers.create');
  const infoAlert = `${t('pages.adminServerTiers.clusterConnectionError')} ${
    !isValidClusterType ? t('pages.adminServerTiers.aboutType') : ''
  }${!isValidClusterType && !isValidConsoleUrl ? ` ${t('pages.adminServerTiers.and')} ` : ''}${
    !isValidConsoleUrl ? t('pages.adminServerTiers.aboutConsole') : ''
  }. ${t('pages.adminServerTiers.fillFieldsManually')}`;

  useSocketAsync({
    topic: 'server-tiers',
    filterFn: ({ object }) => serverTierId === object?.uuid,
    onMessage: async message => {
      if (message.action === socketActionKeys.DELETED) {
        return history.goBack();
      }

      try {
        const response = await api.serverTiers.getServerTier(serverTierId);
        return setIsActiveCluster(response?.data.status === serverTierStatusKeys.ACTIVE);
      } catch (e) {
        if (e) {
          return history.goBack();
        }
      }
    },
  });

  useEffect(() => {
    if (!isNotEditAction) {
      dispatch(fetchServerTier(serverTierId));
    }
  }, []);

  useEffect(() => {
    if (currentServerTier?.uuid && serverTierId) {
      formMethods.reset({ enableSsl: false, ...currentServerTier });
    }
  }, [currentServerTier]);

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
  }, [isSuccess, testValue]);

  useEffect(() => {
    if (testValue.connection) {
      alert.show(t('pages.adminAddServerTier.dataCheckSuccess'), {
        title: t('pages.adminAddServerTier.success'),
        type: 'success',
      });
    }
  }, [testValue.connection]);

  useEffect(() => {
    if (testValue.connection !== null) {
      setTestValue({ connection: null });
    }
  }, [tokenValue, clusterApiUrlValue]);

  useEffect(() => {
    if (isNotEditAction) {
      const translittedName = nameValue?.match(systemAndTierCodeRegex)?.join('') || '';
      formMethods.setValue('location', translittedName ? `${translittedName}Location` : '', {
        shouldValidate: !!translittedName,
      });
    }
  }, [nameValue, isNotEditAction]);

  const clusterInfoTier = async () => {
    try {
      if (!tokenValue || !clusterApiUrlValue) {
        return;
      }

      const response = await api.serverTiers.getClusterInfo(
        {
          token: tokenValue,
          url: clusterApiUrlValue,
          enableSsl: enableSslValue,
        },
        { errorIsIgnored: true }
      );

      const type = response.data?.type;
      const consoleUrl = response.data?.consoleUrl;
      const monitoringUrl = response.data?.monitoringUrl;

      if (type) {
        formMethods.setValue('type', type);
        formMethods.trigger('type');
        setIsValidClusterType(true);
      } else if (!type) {
        setIsValidClusterType(false);
      }

      if (consoleUrl) {
        formMethods.setValue('consoleUrl', consoleUrl);
        formMethods.trigger('consoleUrl');
        setIsValidConsoleUrl(true);
      } else if (!consoleUrl) {
        formMethods.setValue('consoleUrl', '');
        setIsValidConsoleUrl(false);
      }

      if (monitoringUrl) {
        formMethods.setValue('monitoringUrl', monitoringUrl);
        formMethods.trigger('monitoringUrl');
      }

      return;
    } catch (err) {
      if (err) {
        formMethods.setValue('consoleUrl', consoleUrlValue?.length > 1 ? consoleUrlValue : '');
        formMethods.setValue('type', typeValue || '');
        formMethods.setValue(
          'monitoringUrl',
          monitoringUrlValue?.length > 1 ? monitoringUrlValue : ''
        );

        setIsValidConsoleUrl(false);
        setIsValidClusterType(false);
      }
    }
  };

  useEffect(() => {
    clusterInfoTier();
  }, [enableSslValue]);

  const testConnect = async () => {
    try {
      await api.serverTiers.testConnect({
        token: tokenValue,
        url: clusterApiUrlValue,
        enableSsl: enableSslValue,
      });

      return setTestValue({ connection: true });
    } catch (err) {
      if (err) {
        return setTestValue({ connection: false });
      }
    }
  };

  const onCancel = () => history.goBack();

  const onTestButtonClick = e => {
    e.preventDefault();
    setTestValue({ connection: null });
    testConnect();
  };

  const allTypeOptions = useMemo(() => generateSelectOptions(typeOptions, 'id', 'name'), []);

  const onSubmit = ({
    location,
    name,
    type,
    consoleUrl,
    token,
    clusterApiUrl,
    enableSsl,
    ingressController,
    monitoringUrl,
  }) => {
    const requestFunction = serverTierId
      ? api.serverTiers.updateServerTier
      : api.serverTiers.createServerTier;

    const formatedData = {
      location,
      name,
      type,
      ingressController:
        serverTierId || (!serverTierId && typeValue === serverTierTypeKeys.KUBERNETES)
          ? ingressController
          : undefined,
      consoleUrl,
      token,
      clusterApiUrl: checkedUrlValue(clusterApiUrl),
      enableSsl,
      monitoringUrl: checkedUrlValue(monitoringUrl),
      uuid: serverTierId || uuid(),
    };
    run(requestFunction(formatedData));
  };

  const validedFields = !serverTierId
    ? [
        'name',
        'location',
        'clusterApiUrl',
        'token',
        !isValidClusterType ? 'type' : undefined,
        'monitoringUrl',
      ]
    : ['location', 'type', 'monitoringUrl'];

  const isDisabled =
    notActiveClusterMessage ||
    validedFields.filter(field => !formMethods.getValues(field))?.length ||
    !!Object.keys(formMethods.formState.errors).length;

  return (
    <>
      <PageLayout title={title} fullHeight withoutSidePadding>
        <FormProvider {...formMethods}>
          <S.FormWrapper onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputComponent
              label={t('common.name')}
              name="name"
              validationRules={serverTierCNameValidation}
              maxLength={50}
              onlyTextValue={serverTierId}
              isRequired={!serverTierId}
              description={serverTiersFieldsValidateMessage.name}
            />
            <InputComponent
              label={t('pages.adminAddServerTier.location')}
              name="location"
              isRequired
              tooltipMessage={t('pages.adminAddServerTier.locationTooltip')}
              maxLength={150}
              description={serverTiersFieldsValidateMessage.location}
            />
            <InputComponent
              label={t('pages.adminAddServerTier.clusterApiUrlTooltip')}
              name="clusterApiUrl"
              tooltipMessage={t('pages.adminAddServerTier.clusterApiUrlTooltip')}
              validationRules={serverTierClusterUrlValidation}
              onBlurAction={clusterInfoTier}
              onlyTextValue={serverTierId}
              isRequired={!serverTierId}
              defaultValue={protocolNames.https}
            />
            <InputComponent
              label={
                isNotEditAction
                  ? t('pages.adminAddServerTier.token')
                  : t('pages.adminAddServerTier.newToken')
              }
              name="token"
              component={inputComponents.textarea}
              isRequired={isNotEditAction}
              tooltipMessage={t('pages.adminAddServerTier.tokenTooltip')}
              onBlurAction={clusterInfoTier}
            />
            <>
              <InputComponent
                name="enableSsl"
                switchLabel={t('pages.adminAddServerTier.sslVerification')}
                withoutLabel
                component={inputComponents.switchInput}
              />
              {(!isValidClusterType || !isValidConsoleUrl) && (
                <Alert variant="info" isInline title={infoAlert} />
              )}
            </>
            <InputComponent
              label={t('pages.adminAddServerTier.clusterType')}
              name="type"
              component={inputComponents.newSelect}
              options={allTypeOptions}
              placeholder={t('common.selectFromList')}
              isRequired={!isValidClusterType}
              onlyTextValue={isValidClusterType}
              withoutClearButton
            />
            {typeValue === serverTierTypeKeys.KUBERNETES && !serverTierId && (
              <InputComponent
                name="ingressController"
                switchLabel={t('pages.adminAddServerTier.createIngressController')}
                withoutLabel
                component={inputComponents.switchInput}
              />
            )}
            <InputComponent
              label={t('pages.adminAddServerTier.dashboard')}
              name="consoleUrl"
              validationRules={!isValidConsoleUrl ? getValidUrlValidation : null}
              onlyTextValue={isValidConsoleUrl}
              tooltipMessage={t('pages.adminAddServerTier.dashboardTooltip')}
              placeholder="https://console.example.com"
            />
            <InputComponent
              label={t('pages.adminAddServerTier.monitoringUrl')}
              name="monitoringUrl"
              validationRules={getValidUrlValidation}
              isRequired
              defaultValue={protocolNames.https}
            />
            <div className="pt-4">
              <RowView>
                <Tooltip
                  content={notActiveClusterMessage || (isDisabled && tooltipFormDisableMessage())}
                  className={isDisabled ? undefined : 'd-none'}
                  exitDelay={150}
                  animationDuration={150}
                >
                  <div>
                    <BlueButton
                      $marginRight
                      onClick={formMethods.handleSubmit(onSubmit)}
                      isLoading={formMethods.formState.isSubmitting || isLoading}
                      isDisabled={formMethods.formState.isSubmitting || isLoading || isDisabled}
                    >
                      {t(serverTierId ? 'common.saveChanges' : 'common.add')}
                    </BlueButton>
                  </div>
                </Tooltip>
                <BlueButton $marginRight variant="secondary" onClick={onTestButtonClick}>
                  {t('common.test')}
                </BlueButton>
                <Button className="px-3" variant="link" onClick={onCancel}>
                  {t('common.cancel')}
                </Button>
              </RowView>
            </div>
          </S.FormWrapper>
        </FormProvider>
      </PageLayout>
    </>
  );
}

export default ChangeServerTierPage;
