import styled, { css } from 'styled-components';

import { ReactComponent as UpArrowSVG } from 'assets/images/stand/up-arrow-quotas.svg';
import { ReactComponent as DownArrowSVG } from 'assets/images/stand/down-arrow-quotas.svg';

export const QuoteValue = styled.div`
  display: inline;
  font-size: 14px;
  font-weight: 400;
  color: #151515;
  margin-right: 4px;
  white-space: pre;
`;

const arrowIconCss = css`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-bottom: -2px;
`;

export const UpArrowIcon = styled(UpArrowSVG)`
  ${arrowIconCss}
`;

export const DownArrowIcon = styled(DownArrowSVG)`
  ${arrowIconCss}
`;
