import React, { useState } from 'react';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { getQuoteStatusNames } from 'constants';
import QuotesTable from './QuotesTable';

function TierQuotes({ currentTotal }) {
  const { t } = useTranslation();
  const quoteStatusNames = getQuoteStatusNames();
  const filterData = [
    {
      id: 'createdBy',
      name: t('pages.systemTierPage.quotaAuthor'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(quoteStatusNames),
    },
    {
      id: 'approvedBy',
      name: t('pages.systemTierPage.quotaApprover'),
    },
  ];

  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'quotes'
  );

  const [isShowFilters, setIsShowFilters] = useState(false);

  return (
    <>
      {isShowFilters && <div className="mx-4">{FilterComponent}</div>}
      <QuotesTable
        filterParams={filterParams}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        hasSelectedFilters={hasSelectedFilters}
        currentTotal={currentTotal}
      />
    </>
  );
}

export default TierQuotes;
