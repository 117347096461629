import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { serviceStatusKeys } from 'constants';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';
import { getProgressServiceMessage } from 'constants/tooltips';

function ServiceActions({
  service,
  deleteServiceModal,
  archiveServiceModal,
  archiveService,
  isBlueButtonDropdown,
}) {
  const { t, i18n } = useTranslation();
  const progressServiceMessage = getProgressServiceMessage();
  const history = useHistory();
  const { notActiveSystemMessage, notActiveOrErrorSystemMessage, optionsFilterByScopes } =
    useUserPermissions(service?.system);
  const deleteMessage = t('pages.systemServicesPage.releaseBuildsIncluded');
  const notActiveServiceMessage =
    service?.status !== serviceStatusKeys.ACTIVE && progressServiceMessage;
  const isArchiveService = service?.status === serviceStatusKeys.ARCHIVE;
  const notActiveOrErrorServiceMessage =
    service?.status &&
    service.status !== serviceStatusKeys.ACTIVE &&
    service.status !== serviceStatusKeys.ERROR;

  const disabledMessage = useMemo(
    () => notActiveSystemMessage || notActiveServiceMessage,
    [notActiveSystemMessage, notActiveServiceMessage, i18n.language]
  );

  const isNotEditableMessage = useMemo(() => {
    if (notActiveOrErrorSystemMessage) {
      return notActiveOrErrorSystemMessage;
    }

    if (notActiveOrErrorServiceMessage) {
      return progressServiceMessage;
    }
  }, [notActiveOrErrorSystemMessage, notActiveOrErrorServiceMessage, i18n.language]);

  const onFilterChange = id => {
    if (!service?.uuid) {
      return;
    }
    if (id === 'addServiceVersions') {
      history.push(
        generatePath(paths.routePaths.system + paths.systemServicesFullPaths.addServiceVercion, {
          systemId: service.system.uuid,
          systemType: service.system.type,
          serviceId: service.uuid,
        })
      );
      return;
    }

    if (id === 'editService') {
      history.push(
        generatePath(paths.routePaths.system + paths.systemServicesFullPaths.editService, {
          systemId: service.system.uuid,
          systemType: service.system.type,
          serviceId: service.uuid,
        })
      );
      return;
    }

    if (id === 'openGitlab') {
      return window.open(service.gitlabProjectUrl, '_blank');
    }

    if (id === 'openSonar') {
      return window.open(service.sonarProjectUrl, '_blank');
    }

    if (id === 'archive') {
      archiveServiceModal.setModalData(service);
      archiveServiceModal.toggleModal();

      return;
    }

    if (id === 'restore') {
      archiveServiceModal.setModalData(service);
      return archiveService.run(api.services.restoreService(service.uuid));
    }

    deleteServiceModal.setModalData(service);
    deleteServiceModal.toggleModal();
  };

  const optionData = useMemo(() => {
    const releaseTotal = service?.totalSummary?.releaseTotal?.total;

    if (isArchiveService) {
      return optionsFilterByScopes([
        {
          id: 'restore',
          name: t('common.restore'),
          scope: scopes.service.serviceRestore,
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
        },
      ]);
    }

    return optionsFilterByScopes([
      {
        id: 'addServiceVersions',
        name: t('pages.systemServicesPage.addBuild'),
        scope: scopes.serviceVersion.versionCreate,
        isDisabled: disabledMessage,
        tooltip: disabledMessage,
      },
      {
        id: 'editService',
        name: t('common.edit'),
        scope: scopes.service.serviceUpdate,
        isDisabled: disabledMessage,
        tooltip: disabledMessage,
      },
      {
        id: 'archive',
        name: t('common.archive'),
        scope: scopes.service.serviceArchive,
        isDisabled: isNotEditableMessage || releaseTotal,
        tooltip: releaseTotal ? deleteMessage : isNotEditableMessage,
      },
      {
        id: 'delete',
        name: t('common.delete'),
        scope: scopes.service.serviceDelete,
        isDisabled: isNotEditableMessage || releaseTotal,
        tooltip: releaseTotal ? deleteMessage : isNotEditableMessage,
      },
    ]);
  }, [service, optionsFilterByScopes, disabledMessage, isNotEditableMessage, i18n.language]);

  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={service?.uuid}
          optionData={optionData}
          onFilterChange={onFilterChange}
          withoutPadding={!isBlueButtonDropdown}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRight={isBlueButtonDropdown}
          buttonLabel={`${t('pages.systemServicesPage.addBuild', [service?.name])}`}
        />
      )}
    </div>
  );
}

export default ServiceActions;
