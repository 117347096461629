import { createSelector } from 'reselect';

const serverTiersState = state => state.serverTierDevice;

export const serverTierDevicesSelector = createSelector(
  [serverTiersState],
  serverTierDevices => serverTierDevices
);

export const serverTierDeviceSelector = createSelector(
  [serverTiersState],
  ({ currentServerTierDevice }) => currentServerTierDevice
);
