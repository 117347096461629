import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'user',
  initialState: { roles: null, sub: '', permissions: [] },
  reducers: {
    setUserPermissions: (state, { payload }) => {
      state.permissions = payload;
      state.sub = payload.sub;
    },
  },
});

export const { setUserPermissions } = usersSlice.actions;

export default usersSlice.reducer;
