import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import { FlexWrapper } from 'components/UI/View';

import useErrorAlert from 'hooks/useErrorAlert';
import useChangeLocationSearch from 'hooks/Location/useChangeLocationSearch';
import ProtectedRoute from 'routes/ProtectedRoute';

import SystemRouter from 'pages/SystemRouter';
import AdminRouter from 'pages/AdminRouter';
import MainPage from 'pages/MainPage';
import DocumentsPage from 'pages/DocumentsPage';
import MonitoringPage from 'pages/MonitoringPage';
import AddNewSystemRouter from 'pages/AddNewSystemRouter';
import ErrorPage from 'pages/ErrorPage';
import AccessErrorPage from 'pages/AccessErrorPage';

import AppSocketWrapper from '../routes/AppSocketWrapper';

const RootRouter = () => {
  useChangeLocationSearch();
  useErrorAlert();

  return (
    <AppSocketWrapper>
      <FlexWrapper>
        <Switch>
          <ProtectedRoute exact path={paths.routePaths.main} component={MainPage} />

          <ProtectedRoute path={paths.routePaths.admin} component={AdminRouter} />

          <Route exact path={paths.routePaths.documents} component={DocumentsPage} />

          <Route exact path={paths.routePaths.monitoring} component={MonitoringPage} />

          <ProtectedRoute path={paths.routePaths.newSystem} component={AddNewSystemRouter} />

          <ProtectedRoute path={paths.routePaths.system} component={SystemRouter} />

          <ProtectedRoute path={paths.routePaths.systems} component={SystemRouter} />

          <Route path={paths.routePaths.accessError} component={AccessErrorPage} />

          <Route path={paths.routePaths.error} component={ErrorPage} />

          <Redirect to={paths.routePaths.main} />
        </Switch>
      </FlexWrapper>
    </AppSocketWrapper>
  );
};
export default RootRouter;
