import styled from 'styled-components';

export const DataBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const DataItem = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  background: #f0f0f0;
  border-radius: 2px;
  margin: 0 4px 4px 0;
  line-height: 21px;
`;
