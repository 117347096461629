import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabTitleText } from '@patternfly/react-core';
import Dropdown from 'components/Dropdown';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useCardTabs from 'hooks/useCardTabs';
import useSystemPathGenerate from 'hooks/useSystemPathGenerate';
import useSocketAsync from 'hooks/useSocketAsync';
import useModal from 'hooks/useModal';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import {
  currentSystemErrorSelector,
  currentSystemLoadingSelector,
  currentSystemSelector,
} from 'store/systems/selectors';
import { changeCurrentSystem } from 'store/systems/reducers';
import { fetchSystemById } from 'store/systems/actions';
import paths from 'constants/paths';
import { systemStatusKeys, systemTypesKeys } from 'constants';
import NotificationList from 'components/Common/NotificationList';
import PageLayout from 'components/UI/PageLayout';
import { TableSpinner } from 'components/Common/NewDataTable/styles';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import WarningModal from 'components/Common/WarningModal';

import SystemHeader from '../../MainPage/SystemComponent/SystemHeader';
import SystemTotals from '../../MainPage/SystemComponent/SystemTotals';
import SystemDetails from './SystemDetails';
import SystemRestoreModal from './SystemRestoreModal';
import ExternalSystemContactsTable from './SystemDetails/ExternalSystemContactsTable';

import * as S from './styles';

const getLastContract = contracts =>
  contracts?.find(contract => {
    if (!contract?.startDate || !contract?.endDate) {
      return false;
    }

    return moment().isBetween(
      moment(contract.startDate, 'YYYY-MM-DD'),
      moment(contract.endDate, 'YYYY-MM-DD')
    );
  });

function SystemDetailsPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const ref = useRef();
  const { optionsFilterByScopes } = useUserPermissions();
  const { currentTab, onSelectTab } = useCardTabs({ numberOfTabs: 1 });

  const { isLoading, run, isSuccess } = useAsync();
  const currentSystem = useSelector(currentSystemSelector);
  const currentSystemLoading = useSelector(currentSystemLoadingSelector);
  const currentSystemError = useSelector(currentSystemErrorSelector);

  const [mainblockHeight, setMainblockHeight] = useState(ref.current?.clientHeight);
  const progressMessage = t('pages.systemDetailsPage.systemIncorrectStatusTooltip');
  const restoreModal = useModal();
  const archiveModal = useModal();

  const isInternalSystem = systemType !== systemTypesKeys.EXTERNAL;
  const lastContract = useMemo(
    () => (isInternalSystem ? getLastContract(currentSystem?.contracts) : null),
    [currentSystem]
  );
  const developer = useMemo(() => currentSystem?.developers?.[0], [currentSystem]);
  const editPath = useSystemPathGenerate(paths.systemPaths.editSystem);
  const isArchiveStatus = currentSystem?.status === systemStatusKeys.ARCHIVE;

  useEffect(() => {
    if (ref.current?.clientHeight !== mainblockHeight) {
      setMainblockHeight(ref.current?.clientHeight);
    }
  }, [ref.current?.clientHeight, currentSystem]);

  useEffect(() => {
    if (isSuccess) {
      if (archiveModal.isModalVisible) {
        archiveModal.toggleModal();
      }

      dispatch(
        changeCurrentSystem({
          ...currentSystem,
          status: isArchiveStatus
            ? systemStatusKeys.RESTORING_IN_PROGRESS
            : systemStatusKeys.ARCHIVE_IN_PROGRESS,
        })
      );
    }
  }, [isSuccess]);

  const optionData = useMemo(() => {
    const isProgresStatus =
      currentSystemLoading || isLoading || currentSystem?.status !== systemStatusKeys.ACTIVE;

    const disabledTooltip = isArchive => {
      if (currentSystem?.totalSummary) {
        if (
          currentSystem.totalSummary.tierTotal.total &&
          currentSystem.totalSummary.tierTotal.total > currentSystem.totalSummary.tierTotal.archive
        ) {
          return t('pages.systemDetailsPage.activeTiersTooltip');
        }

        if (
          currentSystem.totalSummary.serviceTotal.total &&
          currentSystem.totalSummary.serviceTotal.total >
            currentSystem.totalSummary.serviceTotal.archive
        ) {
          return t('pages.systemDetailsPage.activeServicesTooltip');
        }

        if (!isArchive && isProgresStatus && currentSystem?.status !== systemStatusKeys.ERROR) {
          return progressMessage;
        }
      }
    };

    return optionsFilterByScopes([
      ...(!isArchiveStatus
        ? [
            {
              id: 'edit',
              name: t('common.edit'),
              scope: isInternalSystem
                ? scopes.system.systemUpdate
                : scopes.platform.externalSystemsUpdate,
              isPlatformScope: !isInternalSystem,
              isDisabled: isProgresStatus,
              tooltip: progressMessage,
            },
          ]
        : []),
      ...(currentSystem?.type !== 'EXTERNAL'
        ? [
            {
              id: 'archive',
              name: isArchiveStatus ? t('common.restore') : t('common.archive'),
              scope: isArchiveStatus ? scopes.system.systemRestore : scopes.system.systemArchive,
              isPlatformScope: !isInternalSystem,
              isDisabled: disabledTooltip(isArchiveStatus),
              tooltip: disabledTooltip(isArchiveStatus),
            },
          ]
        : []),
    ]);
  }, [
    currentSystem,
    isArchiveStatus,
    isInternalSystem,
    isLoading,
    currentSystemLoading,
    i18n.language,
  ]);

  const onFilterChange = id => {
    if (id === 'edit' && currentSystem?.uuid) {
      return history.push(editPath);
    }

    if (id === 'archive') {
      if (isArchiveStatus) {
        if (isInternalSystem) {
          restoreModal.toggleModal();
        } else {
          run(api.systems.restoreSystem(currentSystem?.uuid, {}));
        }
      } else {
        archiveModal.setModalData(currentSystem);
        return archiveModal.toggleModal();
      }
    }
  };

  useSocketAsync({
    topic: 'notifications',
    filterBySystemUuid: systemId,
    onMessage: () =>
      dispatch(
        fetchSystemById({
          id: systemId,
        })
      ),
  });

  const detailsClass = isInternalSystem
    ? 'col-12 col-lg-5 col-xl-4 height-fit-content mb-3'
    : 'col-12 d-flex align-items-start display-block mb-3';

  const totalsClass = isInternalSystem ? '' : 'col-12 col-lg-6 height-webkit-fill-available';
  const canEditSystem = !!optionData.length;

  return (
    <PageLayout
      withoutSidePadding
      withSystemSelector
      fullHeightPage
      isError={currentSystemError}
      isWaitLoading={{ isLoading: currentSystemLoading }}
    >
      <div className="position-relative h-100" style={{ backgroundColor: '#f1f0f0' }}>
        {currentSystemLoading && !currentSystem && (
          <TableSpinner>
            <LoadingIndicator />
          </TableSpinner>
        )}

        <header className="px-4 pt-4 pb-4 bg-white">
          <div className="d-flex justify-content-between">
            <SystemHeader system={currentSystem} />
            {canEditSystem && (
              <Dropdown
                id={currentSystem?.uuid}
                optionData={optionData}
                onFilterChange={onFilterChange}
                isBlueButtonDropdown
                positionRight
                buttonLabel="Actions"
              />
            )}
          </div>
        </header>

        <Tabs className="bg-white px-4" activeKey={currentTab} onSelect={onSelectTab}>
          <Tab eventKey={0} title={<TabTitleText>{t('common.review')}</TabTitleText>}>
            {!currentSystemLoading && currentSystem && (
              <S.TabContent>
                <S.BlocksWrapper className="row-block" isEnternal={!isInternalSystem}>
                  <div className={detailsClass} ref={ref}>
                    <div className={totalsClass}>
                      <div className="bg-white p-4 mb-3 h-100">
                        <h3 className="font-weight-semiBold font-14">{t('common.details')}</h3>
                        <SystemDetails
                          system={currentSystem}
                          lastContract={lastContract}
                          isInternalSystem={isInternalSystem}
                          developer={developer}
                        />
                      </div>
                    </div>
                    <div className={totalsClass}>
                      <div className="bg-white p-4 h-100">
                        <h3 className="mb-3 font-weight-semiBold font-14">
                          {t('pages.systemDetailsPage.sections')}
                        </h3>
                        <SystemTotals system={currentSystem} />
                      </div>
                    </div>
                  </div>
                  {isInternalSystem && (
                    <div className="col-12 col-lg-7 col-xl-8 mb-3">
                      <NotificationList
                        systemId={currentSystem?.uuid}
                        name={t('pages.systemDetailsPage.events')}
                        mainblockHeight={mainblockHeight}
                      />
                    </div>
                  )}
                </S.BlocksWrapper>
                {!isInternalSystem && (
                  <S.ContactsWrapper>
                    <ExternalSystemContactsTable />
                  </S.ContactsWrapper>
                )}
              </S.TabContent>
            )}
          </Tab>
        </Tabs>

        <SystemRestoreModal {...restoreModal} />

        <WarningModal
          title={t('pages.systemDetailsPage.archiveSystemTitle')}
          text={t('pages.systemDetailsPage.archiveSystemText')}
          isLoading={isLoading || currentSystemLoading}
          onClick={() => run(api.systems.archiveSystem(archiveModal.modalData?.uuid))}
          name={archiveModal.modalData?.shortName}
          isModalVisible={archiveModal.isModalVisible}
          toggleModal={archiveModal.toggleModal}
          buttenText={t('common.archive')}
          warningColor="#f0ab00"
        />
      </div>
    </PageLayout>
  );
}

export default SystemDetailsPage;
