import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Tab, TabTitleText } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useCardTabs from 'hooks/useCardTabs';
import useSocketAsync from 'hooks/useSocketAsync';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getTasksStatuses } from 'components/Common/StatusComponent';
import DocumentsTable from 'pages/SystemRouter/SystemDocumentsRouter/DocumentsPage/DocumentsTable';
import ReleasesTable from 'pages/SystemRouter/SystemReleasesRouter/ReleasesPage/ReleasesTable';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';

import IssueActions from '../IssuesPage/Issues/IssueActions';
import IssueInfo from './IssueInfo';

import * as S from './styles';

function CurrentIssuePage() {
  const { t } = useTranslation();
  const tasksStatuses = getTasksStatuses();
  const history = useHistory();
  const {
    params: { issuesId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs();

  const { run, data, isLoading, isError } = useAsync();
  const changeAllIssues = useAsync();
  const deleteIssues = useAsync();

  const requestFunction = () => run(api.issues.getIssuesById(issuesId));

  useSocketAsync({
    topic: 'issues',
    filterByUuid: issuesId,
    onMessage: () => requestFunction(),
  });

  useEffect(() => {
    if (deleteIssues.isSuccess) {
      history.goBack();
    }
  }, [deleteIssues.isSuccess]);

  useEffect(() => {
    if (changeAllIssues.isSuccess) {
      requestFunction();
    }
  }, [changeAllIssues.isSuccess]);

  useEffect(() => {
    requestFunction();
  }, []);

  const changeAllIssuesClick = (status, id = []) => {
    changeAllIssues.run(
      api.issues.changeAllIssues({
        issueIdList: id.length && id,
        status,
      })
    );
  };

  const leftSideContent = (
    <div className="mx-4">
      {data && (
        <IssueActions
          issue={data}
          deleteIssues={deleteIssues}
          changeAllIssuesClick={changeAllIssuesClick}
          isBlueButtonDropdown
        />
      )}
    </div>
  );

  const title = (
    <div className="d-flex">
      <span className="mr-2">{data?.name}</span>
      <div className="d-flex ml-2 title-line-height">
        <StatusComponent statusId={data?.status} statuses={tasksStatuses} />
      </div>
    </div>
  );

  const [isShowDocFilters, setIsShowDocFilters] = useState(false);
  const [isShowReleasesFilters, setIsShowReleasesFilters] = useState(true);

  return (
    <PageLayout
      title={title}
      leftSideContent={leftSideContent}
      withoutSidePadding
      withSystemSelector
      isError={isError}
      isWaitLoading={{ isLoading }}
    >
      <S.TabsWrapper>
        <TabsStyled
          activeKey={currentTab}
          onSelect={onSelectTab}
          aria-label={t('common.authorizationService')}
        >
          <Tab eventKey={0} title={<TabTitleText>{t('common.description')}</TabTitleText>}>
            <IssueInfo issue={data} />
          </Tab>
          <Tab
            eventKey={1}
            title={
              <TabTitleText className="d-flex">
                {t('common.releases')}
                <TotalSummaryBadge
                  total={data?.totalSummary?.releaseTotal?.total}
                  isSelect={currentTab === 1}
                />
              </TabTitleText>
            }
          >
            <div className="mt-4">
              <ReleasesTable
                setIsShowFilters={setIsShowReleasesFilters}
                isShowFilters={isShowReleasesFilters}
                currentTotal={data?.uuid && data?.totalSummary?.releaseTotal?.total}
              />
            </div>
          </Tab>
          <Tab
            eventKey={2}
            title={
              <TabTitleText className="d-flex">
                {t('common.documents')}
                <TotalSummaryBadge
                  total={data?.totalSummary?.documentTotal?.total}
                  isSelect={currentTab === 2}
                />
              </TabTitleText>
            }
          >
            <div className="mt-4">
              <DocumentsTable
                setIsShowFilters={setIsShowDocFilters}
                isShowFilters={isShowDocFilters}
                currentTotal={data?.uuid && data?.totalSummary?.documentTotal?.total}
              />
            </div>
          </Tab>
        </TabsStyled>
      </S.TabsWrapper>
    </PageLayout>
  );
}

export default CurrentIssuePage;
