import http from 'api/config';

const getAllMilestones = params => http.get(`milestones`, { params });

const getMilestonesById = milestonesUuid => http.get(`milestones/${milestonesUuid}`);

const createMilestone = body => http.post(`milestones`, body);

const editMilestone = (body, milestoneUuid) => http.put(`milestones/${milestoneUuid}`, body);

const changeMilestone = (milestonesUuid, params) =>
  http.patch(`milestones/${milestonesUuid}`, params);

const deleteMilestone = milestonesUuid => http.delete(`milestones/${milestonesUuid}`);

const getAllMilestonesOptions = params => http.get(`milestones/options`, { params });

export default {
  getAllMilestones,
  getMilestonesById,
  createMilestone,
  editMilestone,
  changeMilestone,
  deleteMilestone,
  getAllMilestonesOptions,
};
