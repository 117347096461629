import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash/uniqBy';
import { checkedServerError } from 'utils';

import { systemStatusKeys } from 'constants';
import * as actions from './actions';

const systemsSlice = createSlice({
  name: 'systems',
  initialState: {
    data: [],
    meta: null,
    systemsError: null,
    currentSystem: null,
    currentSystemLoading: true,
    currentSystemError: null,
    search: null,
    isLoading: true,
    params: {},
    systemsOptions: { data: [], onlyWithPermission: null, total: 0 },
    systemsOptionsIsLoading: false,
  },
  reducers: {
    deleteSystem: (state, { payload }) => {
      state.data = state.data.filter(system => system.uuid === payload);
    },
    updateSystems: (state, { payload }) => {
      state.data = state.data.map(system => {
        if (system.uuid === payload?.uuid) {
          return payload;
        }
        return system;
      });
    },
    resetSystems: (state, { payload }) => {
      state.data = payload;
    },
    resetCurrentSystem: state => {
      state.currentSystem = undefined;
      state.currentSystemError = state.currentSystemError || null;
      state.currentSystemLoading = null;
    },
    changeCurrentSystem: (state, { payload }) => {
      state.currentSystem = payload;
    },
    setCurrentSystemError: (state, { payload }) => {
      state.currentSystem = null;
      state.currentSystemLoading = true;
      state.currentSystemError = checkedServerError(payload?.message);
    },
    changeSystemArchiveStatus: state => {
      state.currentSystem = {
        ...state.currentSystem,
        status:
          state.currentSystem.status === systemStatusKeys.ARCHIVE
            ? systemStatusKeys.ACTIVE
            : systemStatusKeys.ARCHIVE,
      };
    },
    resetSystemsOptions: state => {
      state.systemsOptions.data = [];
    },

    changeSystemsFilter: (state, { payload }) => {
      state.systemsOptions.onlyWithPermission = payload;
    },
  },
  extraReducers: {
    [actions.fetchSystems.pending]: (state, { meta: { arg, q } }) => {
      state.search = q;
      state.isLoading = !arg.silentMode;
      state.params = arg;
      state.systemsError = null;
    },
    [actions.fetchSystems.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData }, meta: { arg } }
    ) => {
      if (isEqual(arg, state.params)) {
        state.data = isConcatData ? uniqBy([...state.data, ...data], 'uuid') : data;
        state.meta = meta;
      }
      state.systemsError = null;
      state.isLoading = false;
    },
    [actions.fetchSystems.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.systemsError = error.message;
    },
    [actions.fetchSystemById.pending]: (state, { meta }) => {
      if (meta.arg !== state.currentSystem?.uuid) {
        state.currentSystem = null;
      }
      state.currentSystemLoading = true;
      if (meta) {
        state.currentSystemError = null;
      }
    },
    [actions.fetchSystemById.fulfilled]: (state, { payload }) => {
      state.currentSystem = payload;
      state.currentSystemLoading = false;
      if (payload) {
        state.currentSystemError = null;
      }
    },
    [actions.fetchSystemById.rejected]: (state, { error }) => {
      state.currentSystemLoading = false;
      if (error?.message) {
        state.currentSystemError = checkedServerError(error.message);
      }
    },
    [actions.fetchSystemsOptions.pending]: state => {
      state.systemsOptionsIsLoading = true;
    },
    [actions.fetchSystemsOptions.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.systemsOptions.data = isConcatData ? [...state.systemsOptions.data, ...data] : data;
      state.systemsOptions.total = meta.total;
      state.systemsOptionsIsLoading = false;
    },
    [actions.fetchSystemsOptions.rejected]: state => {
      state.systemsOptionsIsLoading = false;
    },
  },
});

export const {
  deleteSystem,
  updateSystems,
  resetSystems,
  resetCurrentSystem,
  changeCurrentSystem,
  changeSystemArchiveStatus,
  resetSystemsOptions,
  changeSystemsFilter,
  setCurrentSystemError,
} = systemsSlice.actions;

export default systemsSlice.reducer;
