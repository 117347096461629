import http from 'api/config';

const getServerTierMonitoring = ({ uuid, ...params }) =>
  http.get(`monitoring/server-tiers/${uuid}/resources-chart`, {
    params,
  });

const getTierMonitoring = ({ uuid, ...params }) =>
  http.get(`monitoring/tier/${uuid}/resources-chart`, {
    params,
  });

const getObjectStatus = params => http.get(`object-action-statuses`, { params });

// const getEnableMonitoring = params =>
//   http.get(
//     `monitoring/tier/${params.id}/enable-chart?endDate=${params.endDate}&startDate=${params.startDate}`
//   );

export default { getServerTierMonitoring, getTierMonitoring, getObjectStatus };
