import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownToggle,
  DropdownToggleCheckbox,
} from '@patternfly/react-core/deprecated';
import { useTranslation } from 'react-i18next';

import { checkBoxKeys } from 'constants';
import { declOfNum } from 'utils';
import { DropdownStyled, TextWrapper } from 'components/Common/CheckBoxSelector/styles';

function CheckBoxSelector({
  checkedUuids = [],
  setCheckedUuids,
  allRenderUuids = [],
  allCheckBoxData = [],
  callback,
  declensionItems,
  dropdownText,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen(!isOpen);

  const onFocus = () => {
    const element = document.getElementById('toggle-kebab');
    element?.focus();
  };

  const onSelect = () => {
    setIsOpen(false);
    onFocus();
  };

  const onChange = () => {
    if (checkedUuids.length) {
      if (callback) {
        callback();
      }
      return setCheckedUuids([]);
    }
    return setCheckedUuids(allRenderUuids);
  };

  const dropdownItems = [
    <DropdownItem key={checkBoxKeys.NULL} onClick={() => setCheckedUuids([])} component="button">
      {t('pages.systemAddReleasesPage.resetChoice')}
    </DropdownItem>,
    <DropdownItem
      key={checkBoxKeys.ALL + 1}
      onClick={() => setCheckedUuids(allRenderUuids)}
      component="button"
    >
      {`${t('common.selectAll')}${dropdownText ? ` ${dropdownText}` : ''} ${t(
        'pages.systemAddReleasesPage.onThisPage'
      )} (${t('common.selectAll')} ${allRenderUuids.length})`}
    </DropdownItem>,
    <DropdownItem
      key={checkBoxKeys.ALL + 2}
      onClick={() => setCheckedUuids(allCheckBoxData)}
      component="button"
    >
      {`${t('common.selectAll')}${dropdownText ? ` ${dropdownText}` : ''} (${t(
        'common.selectAll'
      )} ${allCheckBoxData.length})`}
    </DropdownItem>,
  ];

  return (
    <DropdownStyled
      onSelect={onSelect}
      toggle={
        <DropdownToggle
          splitButtonItems={
            <>
              <DropdownToggleCheckbox
                isChecked={!!checkedUuids.length}
                id="split-button-text-checkbox"
                key="split-checkbox"
                aria-label={t('common.selectAll')}
                onClick={onChange}
              />
              <TextWrapper onClick={onToggle}>
                {declOfNum(
                  checkedUuids.length,
                  declensionItems || [
                    t('plural.selected1'),
                    t('plural.selected2'),
                    t('plural.selected3'),
                  ],
                  true,
                  true
                )}
              </TextWrapper>
            </>
          }
          onToggle={onToggle}
          id="toggle-split-button-text"
        />
      }
      isOpen={isOpen}
      dropdownItems={dropdownItems}
    />
  );
}

export default CheckBoxSelector;
