import styled from 'styled-components';

export const EditVersionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export const InputComponentWrapper = styled.div`
  width: 60px;
  height: 28px;
  margin: 0 16px 0 8px;
}

  ${({ withHint }) =>
    withHint &&
    `
     span {
      display: block;
      color: #6a6e73;
      font-weight: 400;
      font-size: 12px;
    }
  `};
`;

export const InputSelectWrapper = styled.div`
  width: 150px;

  ${({ withHint }) =>
    withHint &&
    `
    span {
      display: block;
      color: #6a6e73;
      font-weight: 400;
      font-size: 12px;
     }
  `};
`;
