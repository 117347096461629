import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import useUploadButton from 'hooks/useUploadButton';

import { documentsTypeKeys } from 'constants';
import { SystemBlockWrapper } from '../../styles';

function Documents() {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const parentUuid = watch('uuid');
  const documentsValue = watch('documents');

  const { UploadButtonComponent, setFilesToShow } = useUploadButton({
    objectType: documentsTypeKeys.SYSTEM,
    setValue,
    parentUuid,
    isAddDocumentPage: true,
  });

  useEffect(() => {
    if (documentsValue?.length) {
      setFilesToShow(documentsValue);
    }
    setValue('step', 6);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.documents')}</div>

      {UploadButtonComponent}
    </SystemBlockWrapper>
  );
}

export default Documents;
