import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchIntegrationSystems = createAsyncThunk(
  'integrationSystems/fetchIntegrationSystems',
  async params => {
    const response = await api.integrations.getIntegrationSystems(params);
    return response.data;
  }
);
