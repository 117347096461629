import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@patternfly/react-core';
import FileIcon from '@patternfly/react-icons/dist/esm/icons/file-icon';
import { TimesCircleIcon } from '@patternfly/react-icons';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { isNotEditableSystemSelector } from 'store/systems/selectors';
import { downloadFile } from 'utils';

const kb = 1024;

function DocumentsNameButton({
  file,
  deleteFileCallback,
  isReadOnly,
  isAddDocumentPage,
  isFilesToShow = [],
}) {
  const { checkedPermissions } = useUserPermissions();
  const { t } = useTranslation();
  const deleteFiles = useAsync();
  const isNotEditableSystem = useSelector(isNotEditableSystemSelector);

  const downloadFileClick = async (e, fileUuid, fileName, extension) => {
    e.preventDefault();

    const responce = await api.documents.download(fileUuid);
    downloadFile(responce.data, fileName, extension);
  };

  const deleteFile = async e => {
    if (e) {
      e.preventDefault();
    }

    if (isAddDocumentPage) {
      await deleteFiles.run(api.documents.deleteFile(file.uuid, { isArchived: false }));
    }

    deleteFileCallback?.(file);
  };

  const isCanDelete =
    !isNotEditableSystem &&
    !isReadOnly &&
    (!isFilesToShow.find(item => item.uuid === file.uuid) ||
      checkedPermissions(scopes.document.documentDelete));

  return (
    <div className="d-flex justify-content-xl-between py-0" key={file.uuid}>
      <Button
        variant="link"
        icon={<FileIcon className="font-22 text-gray-medium" />}
        iconPosition="left"
        onClick={e => downloadFileClick(e, file.uuid, file.name, file.fileName.split('.').pop())}
        className="d-flex pl-0 align-items-start line-height-normal"
        aria-label={t('components.upload.downloadDocument', [
          `${file.name}.${file.fileName.split('.').pop()}`,
        ])}
      >
        <div className="align-self-center font-16 text-word-break-wrap">
          {`${file.name}.${file.fileName.split('.').pop()}`}
          <span className="font-16 text-gray-light text-wrap-nowrap mx-2">
            {file.size < kb * kb
              ? `${(file.size / kb).toFixed()} KВ`
              : `${(file.size / (kb * kb)).toFixed(1)} MВ`}
          </span>
        </div>
      </Button>
      {isCanDelete && (
        <Button
          variant="link"
          isInline
          className="p-1"
          onClick={deleteFile}
          aria-label={t('components.upload.deleteDocument', [
            `${file.name}.${file.fileName.split('.').pop()}`,
          ])}
          isDisabled={deleteFiles.isLoading}
        >
          <TimesCircleIcon className="text-gray-light" />
        </Button>
      )}
    </div>
  );
}

export default DocumentsNameButton;
