import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Alert, Button, MultipleFileUpload } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { RequireIcon } from 'components/UI/InputComponent/styles';
import { isArray } from 'lodash';

import DocumentsNameButton from './DocumentsNameButton';

import { MultipleFileUploadMainStyled } from './styles';

const defaultConfig = {
  extensions: [
    'rtf',
    'txt',
    'doc',
    'docx',
    'xls',
    'csv',
    'xlsx',
    'pdf',
    'img',
    'png',
    'jpg',
    'xsd',
    'xml',
    'json',
  ],
  hintExtensions: 'Excel, CSV, Word, TXT, Image, PDF, XSD, XML, JSON',
  fileDropExtensions: {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    xml: 'application/xml',
    json: 'application/json',
    xsd: 'application/xsd',
  },
};
const kb = 1024;
const maxAllowSize = 99 * kb * kb;

function useUploadButton({
  buttonText,
  filesToShow = [],
  isReadOnly,
  isDisabled,
  addFileCallback,
  parentUuid,
  isHorizontal,
  objectType,
  isAddDocumentPage,
  withoutDescription,
  errorComponent,
  isRequired,
  setValue,
  isEditAction,
  onlyOneDocument,
  descriptionMaxLength,
}) {
  const { t } = useTranslation();
  const fileRef = useRef();
  const {
    params: { systemId },
  } = useRouteMatch();
  const fileErrors = {
    bigSize: t('components.upload.bigSizeError'),
    invalidExtension: t('components.upload.invalidExtension'),
  };
  const { run, data, isLoading, isSuccess } = useAsync();
  const patchedFiles = useAsync();

  const [documentParentUuid] = useState(parentUuid || systemId);
  const [isFilesToShow, setIsFilesToShow] = useState(filesToShow || []);
  const [fileError, setFileError] = useState('');

  const [files, setFiles] = useState([]);
  const [addedDocuments, setAddedDocuments] = useState([]);
  const [removedDocuments, setRemovedDocuments] = useState([]);

  const hint = t('components.upload.fileValidationHint', [defaultConfig.hintExtensions]);
  const documentsDescription =
    [...isFilesToShow, ...files]?.find(item => item.description)?.description || '';

  useEffect(() => {
    if (isSuccess) {
      setFiles(prevState => [...prevState, data]);
      setAddedDocuments(prevState => [...prevState.filter(file => file.name !== data.name), data]);
      fileRef.current.value = null;
      addFileCallback?.(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!files.length && filesToShow?.length) {
      setIsFilesToShow(prevState => [...prevState, ...filesToShow]);
    }
  }, [filesToShow, setValue]);

  useEffect(() => {
    if (!files.length && setValue) {
      setFiles(isFilesToShow);
    }
  }, [isFilesToShow]);

  useEffect(() => {
    if (setValue && isAddDocumentPage) {
      setValue('documents', files);
    }
  }, [files]);

  useEffect(() => {
    if (setValue && !isAddDocumentPage) {
      setValue('addedDocuments', addedDocuments);
    }
  }, [addedDocuments]);

  useEffect(() => {
    if (setValue && !isAddDocumentPage) {
      setValue('removedDocuments', removedDocuments);
    }
  }, [removedDocuments]);

  useEffect(() => {
    if (setValue && (!withoutDescription || !isAddDocumentPage) && documentsDescription) {
      setValue('documentsDescription', documentsDescription);
    }
  }, [documentsDescription]);

  const checkExtension = filename => {
    const fileExtension = filename.split('.').pop().toLowerCase();
    return defaultConfig.extensions.includes(fileExtension);
  };

  const uploadFile = async file => {
    try {
      setFileError('');
      return run(api.documents.upload(file, file.name, documentParentUuid, objectType));
    } catch (e) {
      return null;
    }
  };

  const onChangeFiles = async ({ target: { files: filesData } }) => {
    const newFiles = isArray(filesData) ? filesData : [filesData[0]];

    if (!newFiles.length || (onlyOneDocument && files?.length)) {
      return;
    }

    newFiles.forEach((file, index) => {
      if (onlyOneDocument && index > 0) {
        return;
      }

      if (file.size > maxAllowSize) {
        return setFileError(fileErrors.bigSize);
      }

      if (!checkExtension(file.name)) {
        return setFileError(fileErrors.invalidExtension);
      }

      uploadFile(file);
    });
  };

  const handleFileDrop = droppedFiles => {
    onChangeFiles({ target: { files: droppedFiles } });
  };

  const onButtonClick = e => {
    e.preventDefault();

    if (!isLoading) {
      fileRef.current?.click();
    }
  };

  const setFilesToShow = (newfiles, isNewFiles = false) => {
    if (!isNewFiles) {
      setIsFilesToShow(prevState => [...prevState, ...newfiles]);
    } else {
      setIsFilesToShow(newfiles);
      setFiles(newfiles);
    }
  };

  const deleteFileCallback = async file => {
    if (file) {
      setFiles(prevState => prevState.filter(item => item.uuid !== file.uuid));
      setRemovedDocuments(prevState =>
        prevState.find(item => item.uuid === file.uuid) ? prevState : [...prevState, file]
      );
    }
  };

  const changeFilesOnEditSubmit = description => {
    if (isEditAction && filesToShow.length && description !== documentsDescription) {
      const currentDocuments = filesToShow.filter(
        document => !removedDocuments.find(item => item.uuid === document.uuid)
      );

      if (currentDocuments) {
        currentDocuments.forEach(file => {
          patchedFiles.run(api.documents.changeFile(file.uuid, { description }));
        });
      }
    }
  };

  const isCanUploadFiles = !isReadOnly;

  const UploadButtonComponent = (
    <div>
      {!withoutDescription && (
        <div className="mb-4">
          <InputComponent
            label={t('components.upload.description')}
            name="documentsDescription"
            component={inputComponents.textarea}
            disabled={isReadOnly}
            maxLength={descriptionMaxLength}
            description={descriptionMaxLength && t('validation.maxLength', [descriptionMaxLength])}
          />
        </div>
      )}
      <div className="font-14 font-weight-medium line-height-normal mb-1">
        {withoutDescription ? t('common.document') : t('common.documents')}
        {isRequired && <RequireIcon>*</RequireIcon>}
      </div>

      <div className="mb-4">
        <MultipleFileUpload
          className="pre-line"
          onFileDrop={(_event, droppedFiles) => handleFileDrop(droppedFiles)}
          isHorizontal={isHorizontal}
        >
          {isCanUploadFiles && (!onlyOneDocument || (onlyOneDocument && files.length === 0)) && (
            <div>
              <MultipleFileUploadMainStyled
                titleText={t('components.upload.dragDropHint')}
                titleTextSeparator={t('components.upload.dragDropHint2')}
                infoText={hint}
                isUploadButtonHidden={
                  <>
                    {!isDisabled && (
                      <div>
                        <Button
                          variant="secondary"
                          onClick={onButtonClick}
                          isLoading={isLoading}
                          isDisabled={isLoading}
                        >
                          {buttonText || t('misc.download')}
                        </Button>
                      </div>
                    )}
                  </>
                }
              />

              {Boolean(fileError) && (
                <Alert
                  variant="warning"
                  isInline
                  title={<span className="font-weight-medium">{fileError}</span>}
                  className="mt-4"
                />
              )}
            </div>
          )}

          {files.length > 0 && (
            <div>
              <div className="font-14 font-weight-medium line-height-normal mb-1">
                {onlyOneDocument || (isAddDocumentPage && isReadOnly)
                  ? ''
                  : t('components.upload.documents')}
              </div>
              {files.map(file => (
                <DocumentsNameButton
                  key={file.uuid}
                  file={file}
                  deleteFileCallback={deleteFileCallback}
                  isReadOnly={isReadOnly}
                  isAddDocumentPage={isAddDocumentPage}
                  isFilesToShow={isFilesToShow}
                />
              ))}
            </div>
          )}
        </MultipleFileUpload>

        {errorComponent}
        <input type="file" className="d-none" ref={fileRef} onChange={onChangeFiles} />
      </div>
    </div>
  );

  return {
    UploadButtonComponent,
    files,
    isFilesToShow,
    setFilesToShow,
    changeFilesOnEditSubmit,
  };
}

export default useUploadButton;
