import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  Badge,
  Button,
  SelectList,
  Tooltip,
  SelectOption,
  SelectGroup,
  Select,
} from '@patternfly/react-core';
import { FilterIcon } from '@patternfly/react-icons';
import isUndefined from 'lodash/isUndefined';
import uniq from 'lodash/uniq';
import throttle from 'lodash/throttle';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DatePicker from 'hooks/useDropdownFilterSection/DatePicker';
import { currentSystemSelector } from 'store/systems/selectors';

import useURLQueryParams from '../Location/useURLQueryParams';
import { hasSelectedFilters } from '../../utils';

import { DatePickerWrapper, StyledMenuToggle, StyledTextInput, StyledSelect } from './styles';

function TextFieldFilter({
  option,
  filterId,
  onSelect,
  selectedFiltersValues,
  clearPaginationParams,
}) {
  const [value, setValue] = useState(
    option?.initialValue || selectedFiltersValues[option?.id] || ''
  );
  const onInput = event => {
    clearPaginationParams();

    setValue(event.target.value);
    onSelect(option?.id, event.target.value?.trim() || undefined);
  };

  const onKeyPress = event => {
    if (event.charCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <label className="font-weight-medium font-12 text-wrap-nowrap" htmlFor={filterId}>
        {option?.name}
      </label>
      <StyledTextInput
        data-input-for={option?.name}
        id={filterId}
        type="text"
        onInput={onInput}
        value={value}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}

function MultiSelectFilter({
  option,
  filterId,
  onSelect,
  selectedFiltersValues,
  clearPaginationParams,
}) {
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilter] = useState(
    option?.initialValue || selectedFiltersValues[option.id] || []
  );
  const [isOpen, setIsOpen] = useState(false);
  const groups = uniq(option.options.map(({ group }) => group).filter(Boolean));
  let options;

  if (groups.length) {
    options = groups.map(group => (
      <SelectGroup label={group} key={group}>
        <SelectList>
          {option.options
            .filter(item => item.group === group)
            .map(item => (
              <SelectOption
                key={item?.id}
                value={item?.id}
                hasCheckbox
                isSelected={selectedFilters.includes(item?.id)}
              >
                {item?.name}
              </SelectOption>
            ))}
        </SelectList>
      </SelectGroup>
    ));
  } else {
    options = (
      <SelectList>
        {option.options.map(item => (
          <SelectOption
            key={item?.id}
            value={item?.id}
            hasCheckbox
            isSelected={selectedFilters.includes(item?.id)}
          >
            {item?.name}
          </SelectOption>
        ))}
      </SelectList>
    );
  }
  const onToggle = value => setIsOpen(!value);

  const onSelectOption = (_, value) => {
    let newValues;
    clearPaginationParams();

    if (selectedFilters.find(filter => filter === value)) {
      newValues = selectedFilters.filter(filter => filter !== value);
    } else {
      newValues = [...selectedFilters, value];
    }

    onSelect(option?.id, newValues);
    setSelectedFilter(newValues);
  };

  const toggle = toggleRef => (
    <StyledMenuToggle
      ref={toggleRef}
      onClick={() => onToggle(isOpen)}
      isExpanded={isOpen}
      style={{}}
    >
      {t('common.select')}
      {selectedFilters.length > 0 && (
        <Badge isRead className="mx-2">
          {selectedFilters.length}
        </Badge>
      )}
    </StyledMenuToggle>
  );

  return (
    <div>
      <div>
        <label
          className="font-weight-medium font-12 table-filter-label text-wrap-nowrap"
          htmlFor={filterId}
        >
          {option?.name}
        </label>
      </div>
      <StyledSelect
        id="single-grouped-select"
        role="menu"
        key={filterId}
        isOpen={isOpen}
        selected={selectedFilters}
        onSelect={onSelectOption}
        onOpenChange={nextOpen => setIsOpen(nextOpen)}
        toggle={toggle}
        aria-label={t('common.filter')}
        shouldFocusToggleOnSelect
      >
        {options}
      </StyledSelect>
    </div>
  );
}

function DatepickerFilter({
  option,
  filterId,
  onSelect,
  selectedFiltersValues,
  clearPaginationParams,
}) {
  const initDateState = {
    dateTimeFrom: undefined,
    dateTimeTo: undefined,
  };
  const [date, setDate] = useState(
    option?.initialValue?.dateTimeFrom
      ? option?.initialValue
      : selectedFiltersValues[option?.id] || initDateState
  );
  const [currentStartDate, setCurrentStartDate] = useState({});

  useEffect(() => {
    clearPaginationParams();

    if (currentStartDate?.value) {
      onSelect('date', {
        dateTimeFrom: currentStartDate?.value?.format('x') || moment().add(-1, 'day').format('x'),
        dateTimeTo: date?.dateTimeTo,
      });
    }
  }, [date?.dateTimeTo, currentStartDate?.value]);

  useEffect(() => () => clearPaginationParams(), []);

  return (
    <div>
      <div>
        <label className="font-weight-medium font-12" htmlFor={filterId}>
          {option?.name}
        </label>
      </div>
      <DatePickerWrapper>
        <DatePicker
          date={date}
          setDate={setDate}
          setCurrentStartDate={setCurrentStartDate}
          currentStartDate={currentStartDate}
        />
      </DatePickerWrapper>
    </div>
  );
}

function SelectedOption({ option, onSelect, selectedFiltersValues, clearPaginationParams }) {
  const filterId = `${option?.id}-filter`;

  switch (option?.type) {
    case 'multiSelect':
      return (
        <MultiSelectFilter
          option={option}
          filterId={filterId}
          onSelect={onSelect}
          selectedFiltersValues={selectedFiltersValues}
          clearPaginationParams={clearPaginationParams}
        />
      );
    case 'date':
      return (
        <DatepickerFilter
          option={option}
          filterId={filterId}
          onSelect={onSelect}
          selectedFiltersValues={selectedFiltersValues}
          clearPaginationParams={clearPaginationParams}
        />
      );
    default:
      return (
        <TextFieldFilter
          option={option}
          filterId={filterId}
          onSelect={onSelect}
          selectedFiltersValues={selectedFiltersValues}
          clearPaginationParams={clearPaginationParams}
        />
      );
  }
}

function useMultiSelectFilterSection(filterOptions = [], name, defaultFilterParams) {
  const { t } = useTranslation();
  const options = filterOptions.filter(option => !option.isHidden);

  const {
    params: { systemId },
  } = useRouteMatch();
  const { setQueryParams, getQueryByName, clearParam } = useURLQueryParams(name);
  const paginationUrlName = name ? `${name}-pagination` : '';
  const paginationUrl = useURLQueryParams(paginationUrlName);
  const [selectedFilters, setSelectedFilter] = useState(
    options.filter(option => !isNil(option?.initialValue)).map(option => option?.id)
  );
  const [selectedFiltersValues, setSelectedFilterValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const currentSystem = useSelector(currentSystemSelector);
  const optionElements = options.map(option => (
    <SelectOption
      key={`${option?.id}-option`}
      value={option?.id}
      hasCheckbox
      isSelected={selectedFilters?.includes(option?.id)}
    >
      {option?.name}
    </SelectOption>
  ));
  const onToggle = value => setIsOpen(!value);
  const getSelectedOption = optionId => options.find(option => option?.id === optionId);
  const onSelect = (event, value) => {
    if (selectedFilters.find(filter => filter === value)) {
      setSelectedFilter(selectedFilters.filter(filter => filter !== value));
      const newSelectedFiltersValues = { ...selectedFiltersValues };
      delete newSelectedFiltersValues[value];
      setSelectedFilterValues(newSelectedFiltersValues);
    } else {
      setSelectedFilter([...selectedFilters, value]);
    }
  };
  const onSelectFilterValue = throttle((filterId, filterValue) => {
    setSelectedFilterValues({ ...selectedFiltersValues, [filterId]: filterValue });
  }, 500);
  const hasSelectedFilterValues = useMemo(
    () => hasSelectedFilters(selectedFiltersValues),
    [selectedFiltersValues]
  );

  const clearPaginationParams = () => {
    const paramsPaginationUrl = paginationUrl?.getQueryByName(paginationUrlName);
    if (paramsPaginationUrl?.offset) {
      paginationUrl.setQueryParams({ ...paramsPaginationUrl, offset: 0 });
    }
  };

  const onClearAll = () => {
    clearPaginationParams();

    setTimeout(() => {
      options.forEach(option => {
        option.initialValue = null;
      });

      setSelectedFilter([]);
      setSelectedFilterValues({});
    }, 10);
  };

  const toggle = toggleRef => (
    <StyledMenuToggle ref={toggleRef} onClick={() => onToggle(isOpen)} isExpanded={isOpen}>
      <>
        <FilterIcon className="ml-2" />
        <span className="ml-3">{t('common.filter')}</span>
      </>
      {selectedFilters.length > 0 && (
        <Badge isRead className="mx-2">
          {selectedFilters.length}
        </Badge>
      )}
    </StyledMenuToggle>
  );

  const FilterComponent = (
    <div className="d-flex align-items-end bg-white mb-3" style={{ height: '45px' }}>
      <div style={{ minWidth: '210px', height: '37px' }}>
        <span id={name} hidden>
          {t('components.filters.tableFilters')}
        </span>
        <Select
          role="menu"
          id="checkbox-select"
          isOpen={isOpen}
          selected={selectedFilters}
          onSelect={onSelect}
          onOpenChange={nextOpen => setIsOpen(nextOpen)}
          toggle={toggle}
          aria-label={t('components.filters.tableFilters')}
          aria-labelledby={name}
        >
          <SelectList>{optionElements || []}</SelectList>
        </Select>
      </div>

      {selectedFilters
        .map(selectedFilterId => getSelectedOption(selectedFilterId))
        .map(selectedFilter => (
          <div className="ml-3" key={selectedFilter?.id}>
            <SelectedOption
              option={selectedFilter}
              onSelect={onSelectFilterValue}
              selectedFiltersValues={selectedFiltersValues}
              clearPaginationParams={clearPaginationParams}
            />
          </div>
        ))}
      {hasSelectedFilterValues ? (
        <Tooltip
          content={t('components.filter.clear')}
          position="top"
          exitDelay={150}
          animationDuration={150}
        >
          <Button className="ml-3" onClick={onClearAll}>
            <span label="clearFilter">x</span>
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );

  useEffect(() => {
    setQueryParams(selectedFiltersValues);
  }, [selectedFiltersValues]);

  useEffect(() => {
    if (
      (currentSystem?.uuid && systemId && currentSystem?.uuid !== systemId) ||
      (systemId && !currentSystem?.uuid)
    ) {
      if (defaultFilterParams && Object.values(defaultFilterParams).find(item => item)) {
        options.forEach(option => {
          option.initialValue = defaultFilterParams[option?.id];
        });
        setSelectedFilter(Object.keys(defaultFilterParams));
        setSelectedFilterValues(defaultFilterParams);
      }
    }
  }, [systemId, currentSystem?.uuid]);

  useEffect(() => {
    if (hasSelectedFilterValues) {
      onClearAll();
    }
  }, [systemId]);

  useEffect(() => {
    const restoreParamsFromUrl = () => {
      if (!name) {
        return;
      }

      const paramsFromUrl = getQueryByName(name);

      if (
        !paramsFromUrl &&
        defaultFilterParams &&
        Object.values(defaultFilterParams).find(item => item)
      ) {
        options.forEach(option => {
          option.initialValue = defaultFilterParams[option?.id];
        });
        setSelectedFilter(Object.keys(defaultFilterParams));
        setSelectedFilterValues(defaultFilterParams);
      }

      if (!paramsFromUrl) {
        return;
      }

      if (paramsFromUrl) {
        options.forEach(option => {
          if (!isUndefined(paramsFromUrl[option?.id])) {
            option.initialValue = paramsFromUrl[option?.id];
          }
        });
        setSelectedFilter(Object.keys(paramsFromUrl));
        setSelectedFilterValues(paramsFromUrl ?? {});
      }
    };
    const checkInitialValues = () => {
      const initialFilterValues = {};
      filterOptions
        .filter(option => !isNil(option?.initialValue))
        .forEach(option => {
          initialFilterValues[option?.id] = option?.initialValue;
        });

      if (Object.keys(initialFilterValues).length) {
        setSelectedFilterValues(initialFilterValues);
      }
    };

    restoreParamsFromUrl();
    checkInitialValues();

    return () => {
      clearParam(name);
    };
  }, []);

  return {
    FilterComponent,
    filterParams: selectedFiltersValues,
    hasSelectedFilters: hasSelectedFilterValues,
  };
}

export default useMultiSelectFilterSection;
