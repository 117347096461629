import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionListGroup, ActionListItem, Alert, Button } from '@patternfly/react-core';

import api from 'api';
import useAsync from 'hooks/useAsync';
import ModalHeader from 'components/Common/ModalHeader';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { getUserAdminNames } from 'constants';

import * as S from './styles';

const defaultValues = {
  admin: '',
  enabled: '',
};

function EditUserModal({ isModalVisible, toggleModal, callback, user, warningAlertTitle }) {
  const { t } = useTranslation();
  const userAdminNames = getUserAdminNames();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const { run, isSuccess, isLoading } = useAsync();
  const options = [
    {
      value: false,
      label: userAdminNames.user,
      description: t('pages.adminUserPage.allowedSystemFunctions'),
    },
    {
      value: true,
      label: userAdminNames.admin,
      description: t('pages.adminUserPage.allowedPlatformFunctions'),
    },
  ];
  const enabled = formMethods.watch('enabled');

  useEffect(() => {
    if (user) {
      formMethods.reset({ admin: user.admin, enabled: user.enabled });
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      callback();
      toggleModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isModalVisible) {
      formMethods.reset();
    }
  }, [isModalVisible]);

  const onSubmit = data => run(api.users.updateUser(user.uuid, data));

  return (
    <S.ReactModalStyled isOpen={isModalVisible} onRequestClose={toggleModal}>
      <ModalHeader title={t('pages.adminUserPage.editUserTitle')} toggleModal={toggleModal} />
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputComponent
            name="admin"
            label={t('pages.adminUsersPage.accessLevel')}
            component={inputComponents.radioInput}
            defaultValue={options[0].value}
            options={options}
            disabled={warningAlertTitle}
          />
          {warningAlertTitle && (
            <Alert
              variant="warning"
              isInline
              title={warningAlertTitle}
              className="font-weight-normal hyphens"
            />
          )}
          <InputComponent
            name="enabled"
            label={<div className="mb-2">{t('common.status')}</div>}
            switchLabel={t('common.active')}
            component={inputComponents.switchInput}
          />
          <ActionListGroup className="mt-4">
            <ActionListItem>
              <Button
                onClick={formMethods.handleSubmit(onSubmit)}
                isDisabled={
                  isLoading || formMethods.formState.isSubmitting || (warningAlertTitle && enabled)
                }
                isLoading={isLoading || formMethods.formState.isSubmitting}
              >
                {t('common.saveChanges')}
              </Button>
            </ActionListItem>
            <ActionListItem>
              <Button variant="link" id="cancel-button" className="ml-3" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </S.ReactModalStyled>
  );
}

export default EditUserModal;
