import React, { useState } from 'react';
import { SelectOption, SelectVariant } from '@patternfly/react-core/deprecated';
import { useTranslation } from 'react-i18next';

import { SelectStyled } from './styles';

const SelectComponent = ({ onChange, options, isDate, defaultValue, height }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue ? options[0].label : '');

  const onToggle = () => setOpen(!isOpen);

  const onSelect = (event, selection) => {
    if (!isDate && selection) {
      setSelected(selection);
    }

    const formattedValue = options.find(item => item.label === selection);
    onChange(formattedValue);
    onToggle();
  };

  const clearSelection = () => {
    setSelected('');
    onToggle();
  };

  const optionsData = options.map(option => (
    <SelectOption key={option.value} value={option.label} />
  ));

  return (
    <SelectStyled
      variant={SelectVariant.single}
      aria-label="Select Input"
      onToggle={onToggle}
      onSelect={onSelect}
      onClear={!defaultValue ? clearSelection : undefined}
      selections={selected}
      isOpen={isOpen}
      $isDate={isDate}
      $height={height}
      placeholderText={isDate || t('common.selectFromList')}
      isPlaceholder={!selected.length && !isDate}
    >
      {optionsData}
    </SelectStyled>
  );
};

export default SelectComponent;
