import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useFormContext } from 'react-hook-form';

import api from 'api';
import {
  getSystemCodeValidation,
  getSystemNameValidation,
  systemCodeRegex,
  getSystemFieldsValidateMessage,
} from 'constants/vaidations';
import {
  getSystemCodeInfoMessage,
  getSystemNameInfoMessage,
  getSystemShortNameInfoMessage,
  getSystemDataBaseInfoMessage,
  getSystemLanguageInfoMessage,
  getSystemOSInfoMessage,
} from 'constants/tooltips';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { useTranslation } from 'react-i18next';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import useAsync from 'hooks/useAsync';
import { translit } from 'utils';

import { InputBlockWrapper } from 'components/UI/View';
import { SystemBlockWrapper } from '../../styles';

function GeneralInfo() {
  const { t } = useTranslation();
  const systemCodeInfoMessage = getSystemCodeInfoMessage();
  const systemNameInfoMessage = getSystemNameInfoMessage();
  const systemShortNameInfoMessage = getSystemShortNameInfoMessage();
  const systemDataBaseInfoMessage = getSystemDataBaseInfoMessage();
  const systemLanguageInfoMessage = getSystemLanguageInfoMessage();
  const systemOSInfoMessage = getSystemOSInfoMessage();
  const { watch, setValue } = useFormContext();
  const shortNameValue = watch('shortName');
  const [isCanTraslitCodeValue, setIsCanTraslitCodeValue] = useState(false);
  const systemFieldsValidateMessage = getSystemFieldsValidateMessage();
  const {
    params: { systemId },
  } = useRouteMatch();
  const selectPlaceholder = t('common.selectOrWriteManually');
  const languages = useAsync();
  const operatingSystems = useAsync();
  const databases = useAsync();
  const systemNameValidation = useMemo(() => getSystemNameValidation(systemId), [systemId]);
  const systemCodeValidation = useMemo(() => getSystemCodeValidation(systemId), [systemId]);

  useEffect(() => {
    if (!systemId && isCanTraslitCodeValue) {
      const translittedName = translit(shortNameValue)?.match(systemCodeRegex)?.join('-') || '';
      setValue('code', translittedName, { shouldValidate: !!translittedName });
    }
  }, [shortNameValue, systemId]);

  const focusCallback = isFocus => {
    setIsCanTraslitCodeValue(isFocus);
  };

  useEffect(() => {
    languages.run(api.entry.getDictionaryContent('language'));
    operatingSystems.run(api.entry.getDictionaryContent('os'));
    databases.run(api.entry.getDictionaryContent('database'));
    setValue('step', 1);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.generalInfo')}</div>
      <InputComponent
        label={t('common.fullName')}
        name="name"
        isRequired
        tooltipMessage={`${systemNameInfoMessage}`}
        minLength={2}
        maxLength={255}
        validationRules={systemNameValidation}
        description={systemFieldsValidateMessage.name}
      />
      <InputBlockWrapper columnsCount={2}>
        <InputComponent
          label={t('common.shortName')}
          name="shortName"
          isRequired
          tooltipMessage={`${systemShortNameInfoMessage}`}
          minLength={2}
          maxLength={20}
          description={systemFieldsValidateMessage.shortName}
          focusCallback={focusCallback}
        />
        <InputComponent
          isRequired
          label={t('common.code')}
          name="code"
          tooltipMessage={`${systemCodeInfoMessage}`}
          disabled={systemId}
          validationRules={systemCodeValidation}
          minLength={2}
          maxLength={30}
          description={systemFieldsValidateMessage.code}
        />
      </InputBlockWrapper>
      <InputComponent
        label={t('common.description')}
        name="description"
        component={inputComponents.textarea}
        maxLength={250}
        description={systemFieldsValidateMessage.description}
      />
      <InputComponent
        label={t('pages.addNewSystemPage.databases')}
        name="database"
        component={inputComponents.multiSelect}
        tooltipMessage={`${systemDataBaseInfoMessage}`}
        options={generateSelectOptions(databases?.data?.data || [], 'code', 'code')}
        isMulti
        withAddButton
        placeholder={selectPlaceholder || null}
        description={systemFieldsValidateMessage.database}
      />
      <InputComponent
        label={t('pages.addNewSystemPage.languages')}
        name="language"
        component={inputComponents.multiSelect}
        tooltipMessage={`${systemLanguageInfoMessage}`}
        options={generateSelectOptions(languages?.data?.data || [], 'code', 'code')}
        isMulti
        withAddButton
        placeholder={selectPlaceholder || null}
        description={systemFieldsValidateMessage.language}
      />
      <InputComponent
        label={t('pages.addNewSystemPage.oses')}
        name="os"
        component={inputComponents.multiSelect}
        tooltipMessage={`${systemOSInfoMessage}`}
        options={generateSelectOptions(operatingSystems?.data?.data || [], 'code', 'code')}
        isMulti
        withAddButton
        placeholder={selectPlaceholder || null}
        description={systemFieldsValidateMessage.os}
      />
    </SystemBlockWrapper>
  );
}

export default GeneralInfo;
