import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { Tab, TabTitleText } from '@patternfly/react-core';

import useCardTabs from 'hooks/useCardTabs';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchUserById } from 'store/users/actions';
import { usersSelector } from 'store/users/selectors';
import { currentSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';
import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import paths from 'constants/paths';
import { socketActionKeys } from 'constants';

import UserStatusLabel from '../UsersPage/UsersTable/UserStatusLabel';
import UserProfile from './UserProfile';
import UserAccessRules from './UserAccessRules';
import UserActions from './UserActions';
import AuditTable from '../../AuditRouter/AuditPage/AuditTable';

function CurrentUserPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { systemId, systemType, userId },
  } = useRouteMatch();
  const { currentTab, onSelectTab } = useCardTabs();
  const { checkedPermissions } = useUserPermissions();
  const deleteRoles = useAsync();

  const { selectedUserRoles, selectedUser, selectedUserError } = useSelector(usersSelector);
  const currentSystem = useSelector(currentSystemSelector);

  const userData =
    systemId && userId && selectedUserRoles?.length
      ? {
          ...selectedUser,
          userRoleStatus: selectedUserRoles[0]?.status,
          userRoleUuid: selectedUserRoles[0]?.uuid,
          userStatus: selectedUser?.status,
        }
      : selectedUser;
  const [roleTotal, setRoleTotal] = useState();
  const requestFunction = () => dispatch(fetchUserById(userId));

  useEffect(() => {
    requestFunction();
  }, [userId]);

  const goBack = () =>
    history.push(
      systemId
        ? generatePath(paths.allSystemFullPaths.users, {
            systemId,
            systemType,
          })
        : generatePath(paths.adminFullPaths.users)
    );

  useSocketAsync({
    topic: 'users',
    debounceTime: 600,
    filterFn: ({ object }) => userId === object?.uuid,
    onMessage: async ({ action }) => {
      if (action === socketActionKeys.DELETED) {
        return goBack();
      }
      try {
        if (userId) {
          requestFunction();
        }
      } catch (e) {
        if (e) {
          return goBack();
        }
      }
    },
  });

  const title = (
    <div className="d-flex" style={{ minWidth: '550px' }}>
      {t('common.user')} {selectedUser?.fullName || selectedUser?.login || ''}
      <div className="d-flex flex-row ml-3 font-14 ">
        {selectedUser && <UserStatusLabel user={selectedUser} />}
      </div>
    </div>
  );

  const leftSideContent = (
    <div className="d-flex mr-4">
      {selectedUser && (
        <UserActions
          user={userData}
          userUuid={selectedUser?.uuid}
          roleId={userData?.userRoleUuid}
          deleteRoles={deleteRoles}
          isBlueButtonDropdown
          isAdministration={!systemId}
          currentSystem={systemId && currentSystem}
          roleTotal={roleTotal}
        />
      )}
    </div>
  );

  return (
    <PageLayout
      title={title}
      withoutSidePadding
      withSystemSelector={systemId}
      leftSideContent={leftSideContent}
      isError={selectedUserError}
    >
      <div className="h-100">
        <TabsStyled
          activeKey={currentTab}
          onSelect={onSelectTab}
          aria-label={t('pages.adminUserPage.userProfile')}
        >
          <Tab eventKey={0} title={<TabTitleText>{t('pages.adminUserPage.profile')}</TabTitleText>}>
            <UserProfile {...selectedUser} />
          </Tab>
          {checkedPermissions(scopes.user.userViewSection, !systemId) && (
            <Tab
              eventKey={1}
              title={
                <TabTitleText className="d-flex">
                  {t('pages.adminUserPage.accessRules')}
                  <TotalSummaryBadge total={roleTotal} isSelect={currentTab === 1} />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <UserAccessRules
                  userId={userId}
                  isSuccessDelete={deleteRoles.isSuccess}
                  tableName="systemUserRole"
                  setRoleTotal={setRoleTotal}
                  currentTotal={
                    systemId
                      ? 1
                      : selectedUser?.uuid && selectedUser?.totalSummary?.userRoleTotal?.total
                  }
                />
              </div>
            </Tab>
          )}
          {checkedPermissions(scopes.platform.auditViewSection, true) && (
            <Tab
              eventKey={2}
              title={
                <TabTitleText>
                  {t('common.audit')}
                  <TotalSummaryBadge
                    total={selectedUser?.totalSummary?.auditTotal?.total}
                    isSelect={currentTab === 2}
                  />
                </TabTitleText>
              }
            >
              <div className="py-4">
                {selectedUser && (
                  <div>
                    {!!selectedUser?.totalSummary?.auditTotal?.total && (
                      <div className="m-0 mt-1 mb-2 ml-4 font-14">
                        {t('pages.adminAuditPage.eventsHistoryPeriod', [
                          window._env_.AUDIT_PERIOD_DAYS,
                        ])}
                      </div>
                    )}
                    <AuditTable userAudit={selectedUser.login} />
                  </div>
                )}
              </div>
            </Tab>
          )}
        </TabsStyled>
      </div>
    </PageLayout>
  );
}

export default CurrentUserPage;
