import React from 'react';

import { CheckboxInput } from 'components/UI/InputComponent/styles';
import { Tooltip } from '@patternfly/react-core';

function TableCheckbox({ isChecked, onChange, isDisabled, label, isDisabledMessage }) {
  return isDisabled && isDisabledMessage ? (
    <Tooltip content={isDisabledMessage} exitDelay={150} animationDuration={150}>
      <div>
        <CheckboxInput
          data-checkbox-for={label}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
        />
      </div>
    </Tooltip>
  ) : (
    <CheckboxInput
      data-checkbox-for={label}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
}

export default TableCheckbox;
