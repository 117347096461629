import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

import { RadioInputWrapper, RadioInput, RadioDescriptionText, RadioBlockWrapper } from './styles';

function RadioComponent({ field, name, value, label, description, withMargin, disabled }) {
  const radioRef = useRef();

  const onComponentClick = () => {
    radioRef.current?.click();
  };

  const onChange = () => {
    field.onChange(value);
  };

  return (
    <>
      <RadioInputWrapper withMargin={withMargin} onClick={onComponentClick}>
        <RadioInput
          onChange={onChange}
          ref={radioRef}
          value={value}
          data-radio-for={label}
          checked={value?.toString() === field?.value?.toString()}
          name={name}
          disabled={disabled}
        />
        <span className="font-16">{label}</span>
      </RadioInputWrapper>
      {description && (
        <RadioDescriptionText onClick={onComponentClick}>{description}</RadioDescriptionText>
      )}
    </>
  );
}

const RadioInputController = ({
  name,
  control,
  options,
  rules,
  rowDirection,
  defaultValue,
  disabled,
}) => (
  <>
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioBlockWrapper rowDirection={rowDirection}>
          {options.map(option => (
            <RadioComponent
              key={option.value}
              field={field}
              {...option}
              withMargin
              disabled={disabled}
            />
          ))}
        </RadioBlockWrapper>
      )}
    />
  </>
);

export default RadioInputController;
