import { serverTierStatusKeys } from 'constants';
import { createSelector } from 'reselect';
import { checkedServerError } from 'utils';

const serverTiersState = state => state.serverTiers;

export const serverTiersSelector = createSelector([serverTiersState], serverTiers => serverTiers);

export const currentServerTierSelector = createSelector(
  [serverTiersState],
  ({ currentServerTier }) => currentServerTier
);

export const currentServerTierLoadingSelector = createSelector(
  [serverTiersState],
  ({ currentServerTierLoading }) => checkedServerError(currentServerTierLoading)
);

export const currentServerTierErrorSelector = createSelector(
  [serverTiersState],
  ({ currentServerTierError }) => checkedServerError(currentServerTierError)
);

export const notActiveServerTierSelector = createSelector(
  [serverTiersState],
  ({ currentServerTier }) =>
    currentServerTier?.uuid && currentServerTier?.status !== serverTierStatusKeys.ACTIVE
);

export const notActiveOrErrorServerTierSelector = createSelector(
  [serverTiersState],
  ({ currentServerTier }) =>
    currentServerTier?.uuid &&
    currentServerTier?.status !== serverTierStatusKeys.ACTIVE &&
    currentServerTier?.status !== serverTierStatusKeys.ERROR
);
