import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ExpandableRowContent } from '@patternfly/react-table';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import useTableExpandedItems from 'components/Common/DataTable/hooks/useTableExpandedItems';

import { TextButton } from 'components/UI/Button';
import RelativeDate from 'components/Common/RelativeDate';
import { ReactComponent as PlusSVG } from 'assets/images/audit/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/images/audit/minus.svg';

import TableCheckbox from '../TableCheckbox';
import types from '../types';

import * as S from './styles';

const observerPlaceOffset = 4;

const BodyData = React.forwardRef(
  (
    {
      items,
      index,
      withInfinitePagination,
      columnCount,
      params,
      expandedKey,
      withoutTableHead,
      // checkbox
      withCheckboxes,
      checkedUuids,
      setCheckedUuids,
      isMassChecked,
      setExcludedIds,
      excludedIds,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { isModalVisible, toggleModal, setIsModalVisible } = useModal(false);
    const { getExpandedUuidsBykey, toggleExpandedUuid } = useTableExpandedItems();

    const onCheckboxChange = () => {
      if (isMassChecked) {
        setExcludedIds(
          checkedUuids.includes(items.id)
            ? [...excludedIds, items.id]
            : excludedIds.filter(id => id !== items.id)
        );

        return setCheckedUuids(prevState =>
          prevState.includes(items.id)
            ? prevState.filter(id => id !== items.id)
            : [...prevState, items.id]
        );
      }

      return setCheckedUuids(prevState =>
        prevState.includes(items.id)
          ? prevState.filter(id => id !== items.id)
          : [...prevState, items.id]
      );
    };

    useEffect(() => {
      if (expandedKey) {
        setTimeout(() => {
          setIsModalVisible(getExpandedUuidsBykey(expandedKey).includes(items.id));
        }, 200);
      }
    }, [expandedKey, items]);

    useEffect(() => {
      if (isModalVisible) {
        toggleModal();
      }
    }, [params]);

    const generateContent = (type, key, content, item, width) => {
      if (!content) {
        return '-';
      }

      if (type === types.link) {
        if (!item.to) {
          return content;
        }

        return (
          <S.LinkWrapper $width={width}>
            <TextButton
              variant="link"
              key={key}
              target={item.target}
              onClick={e => {
                e.stopPropagation();
                item?.onClick?.();
              }}
              component={props => <Link {...props} to={item.to} />}
              $tableLink
            >
              {content}
            </TextButton>
          </S.LinkWrapper>
        );
      }

      if (type === types.textLink) {
        return (
          <TextButton
            variant="link"
            key={key}
            onClick={e => {
              e.stopPropagation();
              item.onClick();
            }}
            $tableLink
          >
            {content}
          </TextButton>
        );
      }

      if (type === types.externalLink) {
        return (
          <TextButton
            variant="link"
            key={key}
            href={item.href}
            target={item.target || '_blank'}
            $tableLink
          >
            {content}
          </TextButton>
        );
      }

      if (type === types.expendedContent) {
        const onArrowClick = e => {
          e.preventDefault();
          e.stopPropagation();
          if (expandedKey) {
            toggleExpandedUuid(expandedKey, items.id);
          }

          item.onClick?.();
          toggleModal();
        };

        if (items.isExpanded) {
          if (expandedKey) {
            toggleExpandedUuid(expandedKey, items.id);
          }

          item.onClick?.();
          toggleModal();
        }

        return (
          <S.ExpandedTd
            key={key}
            expand={
              key
                ? {
                    rowIndex: key,
                    isExpanded: isModalVisible,
                    onToggle: e => onArrowClick(e),
                  }
                : undefined
            }
          />
        );
      }

      if (type === types.withId) {
        return (
          <S.TableCell id="target" key={key}>
            {content}
          </S.TableCell>
        );
      }

      if (type === types.relativeTime) {
        return <RelativeDate date={content} />;
      }

      if (type === types.rightContent) {
        return <div className="mr-1">{content}</div>;
      }

      return content;
    };

    const contentForExpand = items.data?.find(item => item.type === types.expendedContent);

    return (
      <>
        <S.TrStyled
          id={items.target || items.id + index}
          key={items.id}
          onClick={items.onRowClick}
          $rowColor={items.rowColor}
        >
          {withCheckboxes && (
            <S.TableCell>
              <TableCheckbox
                isChecked={checkedUuids.includes(items.id)}
                onChange={onCheckboxChange}
                isDisabled={items?.isCheckboxDisabled}
                isDisabledMessage={items?.isCheckboxDisabledMessage}
                label={t('components.table.selectRow')}
              />
            </S.TableCell>
          )}
          {items.data.map(({ type, key, content, width, rowIndex, className, ...item }) => {
            const cssClass = [className, type === types.rightContent && 'text-right']
              .filter(Boolean)
              .join(' ');

            return (
              <S.TdStyled
                className={cssClass}
                key={key + rowIndex}
                id={items.target || key + rowIndex}
                dataLabel={key}
                $withoutTableHead={withoutTableHead}
                $isFilterColumn={item?.isInFilter !== undefined}
                onClick={() => {
                  if (item.isInFilter !== undefined) {
                    items.onCellClick(key, content, !item?.isInFilter);
                  }
                }}
              >
                {generateContent(type, key, content, item, width)}
                <S.IconWrapper
                  onClick={() => {
                    if (item?.addLevelFilter !== undefined) {
                      item?.addLevelFilter?.();
                    }
                  }}
                >
                  {item.isInFilter ? <MinusSVG /> : <PlusSVG />}
                </S.IconWrapper>
              </S.TdStyled>
            );
          })}
          {withInfinitePagination && index === columnCount - observerPlaceOffset && (
            <div ref={ref} />
          )}
        </S.TrStyled>
        {isModalVisible && !!contentForExpand ? (
          <S.TrStyled
            isExpanded={items?.withoutBorderLeft ? undefined : isModalVisible && !!contentForExpand}
          >
            <S.TdExpandedStyled
              $withoutTableHead={withoutTableHead}
              dataLabel={`${items.id} expended`}
              colSpan={columnCount + 1}
            >
              <ExpandableRowContent>{contentForExpand.content}</ExpandableRowContent>
            </S.TdExpandedStyled>
          </S.TrStyled>
        ) : null}
      </>
    );
  }
);

export default BodyData;
