import { serviceTypeKeys } from 'constants';

export const tableFiltersToQueryParams = filters => {
  const { type, isConcatData, ...params } = filters;
  const integrationKey = serviceTypeKeys.INTEGRATION;

  return {
    ...params,
    integration: type?.includes(integrationKey) || undefined,
    type: type?.filter(el => el !== integrationKey && el !== 'WITHOUT_TYPE'),
    ...(type?.includes('WITHOUT_TYPE') && { withoutType: true }),
  };
};
