import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchTierById = createAsyncThunk('tier/fetchTierById', async params => {
  const { data } = await api.tiers.getTier(params);

  return data;
});

export const fetchTiers = createAsyncThunk('tier/fetchTiers', async params => {
  const { type, ...filters } = params;
  const { data } = await api.tiers.getTiers({
    ...filters,
    isDevTier: type?.length === 1 ? type[0] === 'dev' : undefined,
  });

  return data;
});

export const fetchServerTiersStands = createAsyncThunk(
  'tier/fetchServerTiersStands',
  async params => {
    const { type, ...filters } = params;
    const { data } = await api.tiers.getTiers({
      ...filters,
      isDevTier: type?.length === 1 ? type[0] === 'dev' : undefined,
    });

    return data;
  }
);
