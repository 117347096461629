import React, { useEffect, useMemo, useState } from 'react';
import { format, addDays, startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useURLQueryParams from 'hooks/Location/useURLQueryParams';
import DatePicker from 'hooks/useDropdownFilterSection/DatePicker';
import RangePicker from 'components/Common/RangePicker';
import MultiSelectorDropdown from 'components/MultiSelectorDropdown';
import ContextSelectorDropdown from 'components/ContextSelectorDropdown';

import C from 'constants/C';

import * as S from './styles';
import { hasSelectedFilters } from '../../utils';

const { ALLTIME, TODAY, CUSTOM } = C.common.notifications.period;

const dateDropDownId = 'date';

const initDateState = {
  startDate: undefined,
  endDate: undefined,
};

function useDropdownFilterSection(
  filterData = [],
  filtersName = null,
  defaultFilterParams,
  isDatePicker
) {
  const { t } = useTranslation();
  const filtersUrlName = `${filtersName}-filter`;
  const dateDropDownOptions = [
    {
      id: ALLTIME,
      name: t('components.filter.periodAllTime'),
    },
    {
      id: TODAY,
      name: t('components.filter.periodToday'),
    },
    {
      id: CUSTOM,
      name: t('components.filter.periodCustom'),
    },
  ];
  const [filterParams, setFilterParams] = useState({});

  const { setQueryParams, getQueryByName, clearParam } = useURLQueryParams(filtersUrlName);

  const [searchQuery, setSearchQuery] = useState('');

  const [filterSearchQuery, setFilterSearchQuery] = useState('');
  const [filterSearchKey, setFilterSearchKey] = useState('');
  const [currentStartDate, setCurrentStartDate] = useState({});

  const [isShowDateForm, setIsShowDateForm] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const [date, setDate] = useState(initDateState);

  const onFilterChange = (option, id) => {
    setFilterParams(prevState => ({
      ...prevState,
      [id]: option,
    }));
  };

  const onFilterSearchChange = ({ target: { value } }) => {
    setFilterSearchQuery(value || undefined);
  };

  const onFilterSearchKeyChange = option => {
    setFilterSearchKey(option);
  };

  const changeDate = value => {
    if (value === ALLTIME) {
      setDate(initDateState);
    }

    if (value === TODAY) {
      setDate({
        startDate: startOfDay(Date.now()).getTime(),
        endDate: endOfDay(Date.now()).getTime(),
      });
    }

    if (value === CUSTOM) {
      setIsShowDateForm(true);
      setIsShowCalendar(true);

      const paramsFromUrl = getQueryByName(filtersUrlName);
      if (paramsFromUrl?.date) {
        return setDate({
          startDate: paramsFromUrl.date?.startDate,
          endDate: paramsFromUrl.date?.endDate,
        });
      }

      return setDate({
        startDate: new Date().getTime(),
        endDate: endOfDay(
          addDays(new Date(), C.common.notifications.calendarSettings.DEFAULT_RANGE)
        ).getTime(),
      });
    }

    setIsShowDateForm(false);
    setIsShowCalendar(false);
  };

  useEffect(() => {
    const value = filterParams?.[dateDropDownId];
    if (value) {
      changeDate(value);
    }
  }, [filterParams?.[dateDropDownId]]);

  useEffect(() => {
    const restoreParamsFromUrl = () => {
      if (!filtersName) {
        return setFilterParams({});
      }

      const paramsFromUrl = getQueryByName(filtersUrlName);

      if (!paramsFromUrl && defaultFilterParams) {
        setFilterParams(defaultFilterParams);
      }

      if (paramsFromUrl) {
        setDate({
          startDate: paramsFromUrl.date?.startDate,
          endDate: paramsFromUrl.date?.endDate,
        });
        setFilterParams(paramsFromUrl.filterParams ?? {});
        setSearchQuery(paramsFromUrl.searchQuery);

        setFilterSearchQuery(paramsFromUrl.filterSearchQuery);
        setFilterSearchKey(paramsFromUrl.filterSearchKey);
      }
    };

    restoreParamsFromUrl();

    return () => {
      clearParam(filtersUrlName);
    };
  }, []);

  useEffect(() => {
    if (filtersName) {
      setQueryParams({
        filterParams,
        searchQuery: searchQuery?.trim(),
        date,
        filterSearchKey,
        filterSearchQuery,
      });
    }
  }, [filterParams, filtersUrlName, searchQuery, date, filterSearchKey, filterSearchQuery]);

  const toggleCalendar = () => {
    setIsShowCalendar(prevState => !prevState);
  };

  const onChangeDate = range => {
    if (range) {
      setDate({
        startDate: new Date(range[0]?.startDate).getTime(),
        endDate: endOfDay(new Date(range[0]?.endDate)).getTime(),
      });
    }
  };

  const onChangeSearchQuery = ({ target: { value } }) => {
    setSearchQuery(value);
  };

  const onMultiFilterChange = (option, id) => {
    setFilterParams(prevState => {
      const isChecked = prevState[id]?.includes(option);

      return {
        ...prevState,
        [id]: isChecked
          ? prevState[id].filter(item => item !== option)
          : [...(prevState[id] ?? []), option],
      };
    });
  };

  const onDeleteFiltersChange = id => {
    setFilterParams(prevState => ({
      ...prevState,
      [id]: [],
    }));
  };

  const FilterComponent = (
    <S.FilterSection isFilterSearch={filterData.filter(item => item.isMultiJump).length}>
      {filterData?.map(
        ({
          title,
          id,
          optionData,
          dateFilter,
          isMulti,
          isFilterSearch,
          withoutMarginLeft,
          defaultText,
          needDeleteAll,
          marginRight,
          mini,
        }) => {
          const dropDownOptionData = dateFilter ? dateDropDownOptions : [...optionData];

          const dropDownId = dateFilter ? dateDropDownId : id;

          return (
            <S.FilterBlock $marginRight={marginRight} key={id} isFilterSearch={isFilterSearch}>
              {title && <h2 className="font-14 font-weight-semiBold text-black mr-1">{title}</h2>}
              {isFilterSearch && (
                <S.FilterSearchWrapper $withoutMarginLeft={withoutMarginLeft}>
                  <ContextSelectorDropdown
                    optionData={dropDownOptionData}
                    onFilterChange={option => onFilterSearchKeyChange(option)}
                    value={filterSearchKey || dropDownOptionData[0].id}
                    setFilterSearchKey={setFilterSearchKey}
                    key={dropDownId + filtersName}
                    mini={mini}
                  />
                  <S.SearchInput
                    fullWidth={filterData[0]?.fullWidth}
                    value={filterSearchQuery}
                    placeholder={t('components.filter.search')}
                    onChange={onFilterSearchChange}
                  />
                </S.FilterSearchWrapper>
              )}
              {!isFilterSearch && (
                <>
                  {isMulti ? (
                    <MultiSelectorDropdown
                      id={dropDownId}
                      needText={defaultText}
                      optionData={dropDownOptionData}
                      checkedIds={filterParams[dropDownId]}
                      onFilterChange={option => onMultiFilterChange(option, dropDownId)}
                      needDeleteAll={needDeleteAll}
                      onDeleteFiltersChange={() => onDeleteFiltersChange(dropDownId)}
                    />
                  ) : (
                    <ContextSelectorDropdown
                      optionData={dropDownOptionData}
                      onFilterChange={option => onFilterChange(option, dropDownId)}
                      key={dropDownId + filtersName}
                      value={filterParams?.[dropDownId]}
                      mini={mini}
                    />
                  )}
                </>
              )}
              {dateFilter && isShowDateForm && (
                <S.DatesBlock>
                  <S.DatesText onClick={toggleCalendar}>
                    {date?.startDate && format(date.startDate, 'dd.MM.yyyy')}
                    &nbsp;&nbsp;–&nbsp;&nbsp;
                    {date?.endDate && format(date.endDate, 'dd.MM.yyyy')}
                    <S.CalendarIcon />
                  </S.DatesText>
                  {isShowCalendar && (
                    <RangePicker
                      cancelDate={toggleCalendar}
                      saveDate={onChangeDate}
                      startDate={date?.startDate}
                      endDate={date?.endDate}
                      filterStyles
                    />
                  )}
                </S.DatesBlock>
              )}
            </S.FilterBlock>
          );
        }
      )}
    </S.FilterSection>
  );

  const SearchInput = (
    <S.SearchInput
      fullWidth={filterData[0]?.fullWidth}
      value={searchQuery}
      placeholder={t('components.filter.search')}
      onChange={onChangeSearchQuery}
    />
  );

  const DateRangePicker = (
    <DatePicker
      date={date}
      setDate={setDate}
      setCurrentStartDate={setCurrentStartDate}
      currentStartDate={currentStartDate}
    />
  );

  const formattedFilterParams = useMemo(() => {
    if (filterData[1]?.dateFilter) {
      const paramsFromUrl = getQueryByName(filtersUrlName);

      if (paramsFromUrl?.date && !date?.startDate) {
        if (paramsFromUrl?.filterParams?.date === CUSTOM) {
          setDate({
            startDate: paramsFromUrl?.date?.startDate,
            endDate: paramsFromUrl?.date?.endDate,
          });
        }
      }

      const value = filterParams?.[dateDropDownId] || paramsFromUrl?.filterParams.date;

      if (value) {
        if (value === TODAY) {
          setDate({
            startDate: startOfDay(Date.now()).getTime(),
            endDate: endOfDay(Date.now()).getTime(),
          });
        }

        if (value === ALLTIME) {
          setDate(initDateState);
        }

        if (value === CUSTOM && !paramsFromUrl?.date) {
          setDate({
            startDate: new Date().getTime(),
            endDate: endOfDay(
              addDays(new Date(), C.common.notifications.calendarSettings.DEFAULT_RANGE)
            ).getTime(),
          });
        }
      }
    }

    if (
      filterSearchKey &&
      !filterSearchQuery &&
      !Object.values(filterParams).find(item => !!item?.length)
    ) {
      return;
    }

    return {
      ...Object.keys(filterParams).reduce(
        (acc, key) => ({
          ...acc,
          [key]: filterParams[key],
        }),
        {}
      ),
      q: searchQuery?.trim() || undefined,
      startDate:
        date?.startDate || !isDatePicker
          ? date.startDate
          : currentStartDate?.value?.format('x') || moment().add(-1, 'day').format('x'),

      endDate: date?.endDate,
      ...(filterSearchKey && filterSearchQuery ? { [filterSearchKey]: filterSearchQuery } : []),
    };
  }, [
    filterParams,
    searchQuery,
    date?.startDate,
    date?.endDate,
    filterSearchKey,
    filterSearchQuery,
  ]);

  const hasSelectedFilterValues = useMemo(
    () => hasSelectedFilters(formattedFilterParams),
    [formattedFilterParams]
  );

  return {
    filterParams: formattedFilterParams,
    FilterComponent,
    DateRangePicker,
    SearchInput,
    setSearchQuery,
    hasSelectedFilters: hasSelectedFilterValues,
  };
}

export default useDropdownFilterSection;
