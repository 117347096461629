import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchTierQuotes = createAsyncThunk('tierQuotes/fetchServerTiers', async params => {
  const { data } = await api.quotas.getQuotaRequests(params);

  return data;
});

export const fetchQuoteRequest = createAsyncThunk('tierQuotes/fetchQuoteRequest', async params => {
  const { data } = await api.quotas.getQuotaRequests(params);

  return data;
});
