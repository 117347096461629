import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowDownSVG from 'assets/images/system_page/ArrowDown.svg';

export const TableBodyWrapper = styled.tr`
  display: ${({ grid }) => (grid ? 'grid' : 'table-row')};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  padding: 3px 16px 3px 13px;
  margin-left: 0;
  border-bottom: 1px solid #d2d2d2;
  border-left: solid 3px transparent;

  ${({ withBorderLeft }) =>
    withBorderLeft &&
    `    
      border-left-color: #0F69C9;
  `};

  ${({ $rowColor }) =>
    $rowColor &&
    `    
      background-color: ${$rowColor};
  `};

  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      background: #f4fafe;
      cursor: pointer;
    }
  `};
`;

const textWrap = styled.div`
  align-self: center;
  word-break: break-word;
  hyphens: auto;
`;

export const TableCell = styled.div`
  font-size: 14px;
  min-height: 37px;
  align-items: center;
  display: flex;
  word-break: break-word;
  hyphens: auto;
  ${({ withSidePadding }) => withSidePadding && `padding: 0 4px;`}
`;

export const CenterContent = styled(TableCell)`
  justify-content: center;
  word-break: break-word;
  hyphens: auto;
`;

const linkCss = css`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #0f69c9;
  align-items: center;
  cursor: pointer;
  word-break: break-word;
  hyphens: auto;
  width: fit-content;
`;

export const LinkCell = styled(Link)`
  ${textWrap}
  ${linkCss}
`;

export const ExternalLinkCell = styled.a`
  ${linkCss}
`;

export const TextLink = styled.a`
  ${linkCss}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 1px;
  bottom: 1px;
  display: none;
`;

export const ArrowDownIcon = styled.span`
  display: flex;
  height: 7.42px;
  width: 12px;
  background: #6a6e73;
  -webkit-mask-image: url(${ArrowDownSVG});
  mask-image: url(${ArrowDownSVG});
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-position: center;
  transition: transform 0.3s;

  ${({ isExpanded, large }) =>
    (!isExpanded &&
      `
      transform: rotate(-90deg) scale(${large ? 1.5 : 1});
  `) ||
    `transform: scale(${large ? 1.5 : 1});`};
`;

export const ArrowIconWrapper = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const ColumnWrapper = styled.td`
  display: ${({ grid }) => (grid ? 'grid' : 'table-cell')};
  position: relative;
  padding: 0 5px;

  ${({ isFilterColumn }) =>
    isFilterColumn &&
    `
      &:hover {
        background: #f2f6f9;
        cursor: pointer;

        & > div {
          display: flex;
        }
      }
  `};
`;
