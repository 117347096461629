import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRouteMatch } from 'react-router';
import InputComponent from 'components/UI/InputComponent';
import { DeleteButton } from 'components/UI/DeleteButton';

import { getSystemTierNameValidation, getTierNameErrorMessage } from 'constants/vaidations';

import { stepsKeys } from '../../keys';
import * as S from '../../../styles';

const dynamicParentField = stepsKeys.tiers;
const examples = ['prod', 'test', 'stage', 'dev'];

function ExternalTierForm({ fieldUuid, deleteField, index, fieldUuidsLength }) {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { watch, setValue, trigger, clearErrors } = useFormContext();
  const inputsName = fieldUuid ? `${dynamicParentField}[${index}].` : '';
  const serverTierName = watch(`${inputsName}code`);
  const tiers = watch('tiers');
  const systemTierNameValidation = useMemo(() => getSystemTierNameValidation(systemId), [systemId]);

  useEffect(() => {
    setValue(`${inputsName}code`, serverTierName);
  }, [serverTierName]);

  const currentExamples = !systemId
    ? examples.filter(item => !tiers?.find(option => option?.code === item))
    : examples;

  return (
    <>
      {fieldUuid ? (
        <S.DynamicFormWrapper>
          {fieldUuid && (
            <div className="d-flex flex-row justify-content-between">
              {t('common.tier')} {index + 1}
              {fieldUuidsLength && (
                <DeleteButton
                  onClick={() => {
                    deleteField(fieldUuid, index);
                    clearErrors();
                    trigger();
                  }}
                >
                  {t('pages.addNewSystemPage.deleteTier')}
                </DeleteButton>
              )}
            </div>
          )}

          <InputComponent
            label={t('common.name')}
            name={`${inputsName}code`}
            isRequired
            validationRules={systemTierNameValidation}
            maxLength={30}
            description={getTierNameErrorMessage()}
            examples={currentExamples}
          />
        </S.DynamicFormWrapper>
      ) : (
        <div className="d-sm-flex flex-column gap-4 mb-4">
          <InputComponent
            label={t('common.name')}
            name={`${inputsName}code`}
            isRequired
            validationRules={systemTierNameValidation}
            maxLength={30}
            description={getTierNameErrorMessage()}
            examples={currentExamples}
          />
        </div>
      )}
    </>
  );
}

export default ExternalTierForm;
