import React from 'react';

import { declOfNum } from 'utils';
import { getBackupFrequencyUnit } from '../../../constants';

function Frequency({ value = 0, unit, text }) {
  const backupFrequencyUnit = getBackupFrequencyUnit();
  const frequencyConvert = item => backupFrequencyUnit[item];

  return (
    <div className="py-2">
      {text}
      <span className="ml-1">{declOfNum(value, frequencyConvert(unit))}</span>
    </div>
  );
}

export default Frequency;
