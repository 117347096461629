import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: { data: [], total: 0, isLoading: false },
  extraReducers: {
    [actions.fetchDictionaries.pending]: state => {
      state.isLoading = true;
    },
    [actions.fetchDictionaries.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.total = meta.total;
      state.isLoading = false;
    },
    [actions.fetchDictionaries.rejected]: state => {
      state.isLoading = false;
    },
  },
});

export default dictionariesSlice.reducer;
