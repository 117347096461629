import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  DropdownItem,
  DropdownToggle,
  DropdownToggleCheckbox,
} from '@patternfly/react-core/deprecated';
import { useTranslation } from 'react-i18next';

import { checkBoxKeys } from 'constants';
import { declOfNum } from 'utils';

import * as S from './styles';

function CheckBoxSelector({
  checkedUuids = [],
  setCheckedUuids,
  allRenderUuids = [],
  isMassChecked,
  setIsMassChecked,
  excludedIds,
  total,
  massTotal,
  setExcludedIds,
  withoutExcludedIds,
  declensionItems,
  dropdownText,
  withouClearMassCheck,
}) {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!withouClearMassCheck) {
      setIsMassChecked(false);
    }
  }, [systemId]);

  const onToggle = () => setIsOpen(!isOpen);

  const onFocus = () => {
    const element = document.getElementById('toggle-kebab');
    element?.focus();
  };

  const onSelect = () => {
    setIsOpen(false);
    onFocus();
  };

  const onChange = () => {
    setIsMassChecked(false);

    return checkedUuids.length ? setCheckedUuids([]) : setCheckedUuids(allRenderUuids);
  };

  const allCancelCheckClick = () => {
    setCheckedUuids([]);

    if (setExcludedIds) {
      setExcludedIds([]);
    }

    setIsMassChecked(false);
  };

  const allRenderClick = () => {
    setCheckedUuids(allRenderUuids);

    if (setExcludedIds) {
      setExcludedIds([]);
    }

    setIsMassChecked(false);
  };

  const massCheckedClick = () => {
    setIsMassChecked(true);

    if (setExcludedIds) {
      setExcludedIds([]);
    }
  };

  const massCheckedTotal = withoutExcludedIds
    ? massTotal - excludedIds.length
    : total - excludedIds.length;

  const checkedValue = isMassChecked
    ? massTotal - excludedIds.length || massCheckedTotal
    : checkedUuids.length;

  const dropdownItems = [
    <DropdownItem key={checkBoxKeys.NULL} onClick={allCancelCheckClick} component="button">
      {t('components.checkbox.reset')}
    </DropdownItem>,
    <DropdownItem key={checkBoxKeys.ALL_IN_PAGE} onClick={allRenderClick} component="button">
      {`${t('common.selectAll')}${dropdownText ? ` ${dropdownText}` : ''} ${t(
        'common.onThisPage'
      )} (${t('common.selectAll')} ${allRenderUuids.length})`}
    </DropdownItem>,
    <DropdownItem key={checkBoxKeys.ALL} onClick={massCheckedClick} component="button">
      {`${t('common.selectAll')}${dropdownText ? ` ${dropdownText}` : ''} (${t(
        'common.selectAll'
      )} ${withoutExcludedIds ? massTotal : total})`}
    </DropdownItem>,
  ];

  return (
    <S.DropdownStyled
      onSelect={onSelect}
      toggle={
        <DropdownToggle
          splitButtonItems={
            <>
              <DropdownToggleCheckbox
                isChecked={isMassChecked ? massCheckedTotal : !!checkedUuids.length}
                id="split-button-text-checkbox"
                key="split-checkbox"
                aria-label={t('common.selectAll')}
                onClick={onChange}
              />
              <S.TextWrapper onClick={onToggle}>
                {declOfNum(
                  checkedValue,
                  declensionItems || [
                    t('plural.selected1'),
                    t('plural.selected2'),
                    t('plural.selected3'),
                  ],
                  true,
                  true
                )}
              </S.TextWrapper>
            </>
          }
          onToggle={onToggle}
          id="toggle-split-button-text"
        />
      }
      isOpen={isOpen}
      dropdownItems={dropdownItems}
    />
  );
}

export default CheckBoxSelector;
