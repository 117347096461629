import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import C from 'constants/C';
import NotificationItem from 'components/Common/NotificationList/NotificationItem';
import ContextSelectorDropdown from 'components/ContextSelectorDropdown';

import NotificationTypeIco from './NotificationTypeIco';
import BackButton from '../UI/BackButton';
import RangePicker from '../Common/RangePicker';

import * as S from './styles';

const Notifications = ({
  notifications,
  onFilterPeriodChange,
  onFilterStatusChange,
  onFilterTypeChange,
  onFilterStandChange,
  showCalendar,
  showDateForm,
  saveDate,
  cancelDate,
  tiers,
  toggleCalendar,
  startDate,
  endDate,
  title,
  systemId,
}) => {
  const { t } = useTranslation();
  const statusOptions = [
    {
      id: C.common.notifications.status.ALL,
      name: t('misc.notifications.status.ALL'),
    },
    {
      id: C.common.notifications.status.READED,
      name: t('misc.notifications.status.READED'),
    },
    {
      id: C.common.notifications.status.NOTREADED,
      name: t('misc.notifications.status.NOTREADED'),
    },
  ];

  const typesOptions = [
    {
      id: C.common.notifications.type.ALL,
      name: t('misc.notifications.type.ALL'),
    },
    {
      id: C.common.notifications.type.ERROR,
      name: t('misc.notifications.type.ERROR'),
    },
    {
      id: C.common.notifications.type.WARNING,
      name: t('misc.notifications.type.WARNING'),
    },
    {
      id: C.common.notifications.type.INFO,
      name: t('misc.notifications.type.INFO'),
    },
  ];

  const periodOptions = [
    {
      id: C.common.notifications.period.TODAY,
      name: t('misc.notifications.period.TODAY'),
    },
    {
      id: C.common.notifications.period.ALLTIME,
      name: t('misc.notifications.period.ALLTIME'),
    },
    {
      id: C.common.notifications.period.CUSTOM,
      name: t('misc.notifications.period.CUSTOM'),
    },
  ];
  const getNotificationLabel = n =>
    `notification_${typeof n.date === 'string' ? n.date.replace(/ /g, '_') : n.date}`;

  return (
    <S.NotificationsWrapper>
      <S.NotificationHeader>
        <BackButton />
        <S.Filter>
          <S.FilterWrapper>
            <S.NotificationFilterText>
              {t('misc.notifications.coverName.status')}
            </S.NotificationFilterText>
            <ContextSelectorDropdown
              id={C.common.notifications.status.ID}
              optionData={statusOptions}
              onFilterChange={onFilterStatusChange}
            />
          </S.FilterWrapper>

          <S.FilterWrapper>
            <S.NotificationFilterText>
              {t('misc.notifications.coverName.type')}
            </S.NotificationFilterText>
            <ContextSelectorDropdown
              id={C.common.notifications.type.ID}
              optionData={typesOptions}
              onFilterChange={onFilterTypeChange}
            />
          </S.FilterWrapper>

          {!!tiers.length && (
            <S.FilterWrapper>
              <S.NotificationFilterText>{t('common.tiers')}</S.NotificationFilterText>
              <ContextSelectorDropdown
                id={C.common.notifications.stand.ID}
                optionData={tiers}
                onFilterChange={onFilterStandChange}
              />
            </S.FilterWrapper>
          )}

          <ContextSelectorDropdown
            id={C.common.notifications.period.ID}
            optionData={periodOptions}
            onFilterChange={onFilterPeriodChange}
          />

          {showDateForm && (
            <S.Dates onClick={toggleCalendar}>
              <S.DatesText>
                {format(startDate, C.common.dateFormat.DD_MM_YYYY)}
                &nbsp;&nbsp;–&nbsp;&nbsp;
                {format(endDate, C.common.dateFormat.DD_MM_YYYY)}
              </S.DatesText>
              <S.CalendarIco />
            </S.Dates>
          )}
          {showCalendar && (
            <RangePicker
              cancelDate={cancelDate}
              saveDate={saveDate}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </S.Filter>

        <S.NotificationTitle>{title}</S.NotificationTitle>
      </S.NotificationHeader>

      <S.NotificationBody>
        {notifications && notifications.length > 0 ? (
          notifications.map(n => (
            <S.NotificationDateBlock key={n.date}>
              <S.NotificationDate>{moment(n.date).format('DD.MM.YYYY')}</S.NotificationDate>
              <S.NotificationList>
                {n.data.map((item, index) => (
                  <S.NotificationBlock key={item.id}>
                    <S.NotificationTime id={`${getNotificationLabel(n)}_${index + 1}`}>
                      {moment(item.time).format('HH:mm')}
                    </S.NotificationTime>
                    <NotificationTypeIco icon={item.icon} />
                    <NotificationItem
                      label={`${getNotificationLabel(n)}_${index + 1}`}
                      notification={item}
                      systemId={systemId}
                    />
                  </S.NotificationBlock>
                ))}
              </S.NotificationList>
            </S.NotificationDateBlock>
          ))
        ) : (
          <S.NotificationEmpty>
            <S.NotificationEmptyIco />
            <S.NotificationEmptyText> {t('misc.notifications.EMPTY')}</S.NotificationEmptyText>
          </S.NotificationEmpty>
        )}
      </S.NotificationBody>
    </S.NotificationsWrapper>
  );
};

export default Notifications;
