import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, generatePath } from 'react-router';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { serverTierStandsSelector } from 'store/tiers/selectors';
import { fetchServerTiersStands } from 'store/tiers/actions';
import { clearServicesState } from 'store/services/reducers';
import NewDataTable, { types, modifierKeys } from 'components/Common/NewDataTable';
import TierName from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/TiersTable/TierName';
import LastReleaseName from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/Tier/LastReleaseName';
import TierActions from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/TierActions';
import DeployReleaseForm from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/DeployReleaseForm';
import StatusComponent, {
  getSystemStatuses,
  getTierAvailableStatuses,
} from 'components/Common/StatusComponent';
import paths from 'constants/paths';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { systemTypesKeys, getTierStatusNames, getTierTypeNames } from 'constants';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import DeleteTierModal from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/TiersTable/DeleteTierModal';

const tableName = 'serverTier';

function TiersTable({ currentTotal }) {
  const { t } = useTranslation();
  const systemStatuses = getSystemStatuses();
  const tierTypeNames = getTierTypeNames();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const tierStatusNames = getTierStatusNames();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const {
    params: { serverTierId },
  } = useRouteMatch();
  const deployFormModal = useModal();
  const deleteTierModal = useModal();
  const { total, data, isLoading, isError } = useSelector(serverTierStandsSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const filterData = [
    {
      id: 'code',
      name: t('common.name'),
    },
    {
      id: 'type',
      name: t('pages.systemTierPage.tierType'),
      type: 'multiSelect',
      options: formatFilterOptions(tierTypeNames),
    },
    {
      id: 'currentRelease',
      name: t('common.currentRelease'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(tierStatusNames),
    },
    {
      id: 'date',
      name: t('common.updateDate'),
      type: 'date',
    },
  ];
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    tableName
  );

  const headerData = [
    {
      key: 'code',
      label: t('common.name'),
      withSort: true,
      modifier: modifierKeys.nowrap,
    },
    {
      key: 'currentRelease',
      label: t('common.currentRelease'),
      withSort: true,
    },
    {
      key: 'system.shortName',
      label: t('common.system'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updateDate'),
      withSort: true,
    },
    {
      key: 'actions',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];
  const requestParams = useMemo(
    () => ({
      ...filterParams,
      ...filterParams?.date,
      serverTier: serverTierId,
      orderBy: 'lastUpdatedAt',
      ascending: false,
      type: filterParams?.type,
      date: undefined,
    }),
    [serverTierId, filterParams]
  );

  const requestFunction = requestParameters =>
    dispatch(
      fetchServerTiersStands({
        serverTier: serverTierId,
        ...requestParameters,
      })
    );

  useSocketAsync({
    topic: 'tiers',
    filterFn: ({ object }) => object.serverTier?.uuid === serverTierId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  useEffect(() => {
    if (!isShowFilters || !currentTotal) {
      setIsShowFilters(hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, hasSelectedFilters]);

  useEffect(() => () => dispatch(clearServicesState()), []);

  const formattedBodyData = data?.map((tier, index) => ({
    id: `${tier.uuid}${index}`,
    data: [
      {
        key: 'code',
        content: <TierName tier={tier} />,
      },
      {
        key: 'currentRelease',
        content: (
          <LastReleaseName tier={tier} systemId={tier.system.uuid} systemType={tier.system.type} />
        ),
      },
      {
        key: 'system.shortName',
        content: (
          <LinkWithPermission
            system={tier?.system}
            scope={scopes.system.systemViewForm}
            to={
              tier.system?.type
                ? generatePath(paths.routePaths.system, {
                    systemId: tier.system.uuid,
                    systemType: tier.system.type,
                  })
                : undefined
            }
            isTableLink
          >
            <StatusComponent
              statusId={
                tier.system.type === systemTypesKeys.EXTERNAL
                  ? systemTypesKeys.EXTERNAL
                  : tier.system.status
              }
              statusWithTooltip
              statuses={systemStatuses}
              truncate
            >
              {tier.system.shortName}
            </StatusComponent>
          </LinkWithPermission>
        ),
      },
      {
        key: 'status',
        content: (
          <StatusComponent
            statusId={tier?.status}
            statuses={tierAvailableStatuses}
            objectUuid={tier?.uuid}
            objectType="TIER"
          />
        ),
      },
      {
        key: 'lastUpdatedBy',
        content: (
          <LastUpdatedStatusWithLink
            lastUpdatedBy={tier?.lastUpdatedBy}
            scope={scopes.platform.userViewForm}
            isTableLink
            linkTo={
              tier.lastUpdatedBy.uuid
                ? generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.details, {
                    userId: tier.lastUpdatedBy.uuid,
                  })
                : undefined
            }
          />
        ),
      },
      {
        key: 'lastUpdatedAt',
        type: types.relativeTime,
        content: tier.lastUpdatedAt,
      },
      {
        key: 'actions',
        content: (
          <TierActions
            currentTier={tier}
            deployFormModal={deployFormModal}
            deleteTierModal={deleteTierModal}
            requestFunction={tableRef.current?.requestDataWithParams}
          />
        ),
      },
    ],
  }));

  return (
    <>
      {isShowFilters && <div className="d-sm-flex ml-4">{FilterComponent}</div>}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        isLoading={isLoading}
        total={total}
        tableName={tableName}
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={requestParams}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />

      <DeployReleaseForm
        {...deployFormModal}
        isDeployToDevAction={deployFormModal.modalData?.isTierDev}
      />

      <DeleteTierModal
        {...deleteTierModal}
        requestFunction={tableRef.current?.requestDataWithParams}
      />
    </>
  );
}

export default TiersTable;
