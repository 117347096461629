import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ReactModalStyled = styled(ReactModal)`
  position: absolute;
  left: 30%;
  border: 1px solid #cccccc;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 0px;
  margin-right: -40%;
  padding: 0px 8px;
  width: 640px;
  max-height: 88vh;
  top: 10%;
  overflow: auto;
`;

export const Form = styled.form.attrs({
  className: 'd-sm-flex flex-column mx-4 mb-4',
})`
  gap: 24px;
  max-width: 750px;
`;
