import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchStaticAnalysisResultsIssues = createAsyncThunk(
  'staticAnalysisResults/fetchStaticAnalysisResultsIssues',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServiceVersionStaticAnalysisIssues(params);

    return data;
  }
);

export const fetchStaticAnalysisResultsHotspots = createAsyncThunk(
  'staticAnalysisResults/fetchStaticAnalysisResultsHotspots',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServiceVersionStaticAnalysisHotspots(params);

    return data;
  }
);
