import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { checkedUserTotal } from 'utils';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useModal from 'hooks/useModal';
import { tierQuoteRequestSelector } from 'store/tierQuotes/selectors';
import { changeCurrentTierInList } from 'store/tiers/reducers';
import { currentSystemSelector } from 'store/systems/selectors';
import {
  getQuoteOptions,
  systemTypesKeys,
  tierStatusKeys,
  serverTierStatusKeys,
} from 'constants/index';
import { systemTiersAction } from 'constants/paths/systemTierPaths';
import Dropdown from 'components/Dropdown';
import paths from 'constants/paths';
import WarningModal from 'components/Common/WarningModal';
import {
  getProgressTierMessage,
  getQuotaInProgressMessage,
  getRequireApprovalMessage,
  getSystemHaveUpdetedUserMessage,
  getTierHaveDeployMessage,
} from 'constants/tooltips';

import { filterReleasesStatus } from '../DeployReleaseForm';

function TierActions({ currentTier, isBlueButtonDropdown, deployFormModal, deleteTierModal }) {
  const { t, i18n } = useTranslation();
  const quoteOptions = getQuoteOptions();
  const history = useHistory();
  const {
    notActiveSystemMessage,
    notActiveOrErrorSystemMessage,
    checkedPermissions,
    optionsFilterByScopes,
  } = useUserPermissions(currentTier?.system);
  const {
    params: { systemId, tierId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const withoutReleaseMessage = t('pages.systemTierPage.noReleasesFound');
  const { data, run } = useAsync();
  const archive = useAsync();
  const getQuotaInProgress = useAsync();
  const getCurrentSystem = useAsync();
  const quoteRequest = useSelector(tierQuoteRequestSelector);
  const currentSystem = useSelector(currentSystemSelector);
  const archiveModal = useModal();

  const isTierDev = currentTier?.devTier;
  const isTierHasQuote = tierId
    ? quoteRequest
    : currentTier?.totalSummary.quotaTotal.requireApproval;
  const isActiveTier = currentTier?.status === tierStatusKeys.ACTIVE;
  const isHaveDeploy = currentTier?.totalSummary.tierReleaseTotal?.total;
  const isNotEditableTier =
    currentTier?.status &&
    currentTier.status !== tierStatusKeys.ACTIVE &&
    currentTier.status !== tierStatusKeys.ERROR;

  const system = systemId ? currentSystem : getCurrentSystem.data;
  const systemWithoutRelease = systemId
    ? !system?.totalSummary?.releaseTotal.total
    : !data?.data.length;
  const hasQuotaInProgress = getQuotaInProgress?.data?.meta.total > 0;
  const userInProgressMessage = useMemo(
    () => checkedUserTotal(system?.totalSummary?.userTotal),
    [system, i18n.language]
  );

  useEffect(() => {
    if (archive.isSuccess) {
      if (archiveModal.isModalVisible) {
        archiveModal.toggleModal();

        dispatch(
          changeCurrentTierInList({
            uuid: currentTier?.uuid,
            status: tierStatusKeys.ARCHIVE_IN_PROGRESS,
          })
        );
      }
    }
  }, [archive.isSuccess]);

  const requestParams = useMemo(
    () => ({
      system: currentTier?.system?.uuid,
      status: filterReleasesStatus(isTierDev),
      limit: 1,
    }),
    [isTierDev, currentTier]
  );

  const callback = () => {
    if (!systemId) {
      run(api.releases.getReleasesOptions(requestParams));
      getCurrentSystem.run(api.systems.getSystem(currentTier.system?.uuid));
    }

    if (currentTier?.uuid) {
      getQuotaInProgress.run(
        api.quotas.getQuotaRequests({
          limit: 1,
          tier: currentTier.uuid,
          ascending: false,
          status: 'IN_PROGRESS',
        })
      );
    }
  };

  const tierDisabledMessage = useMemo(() => {
    if (notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (!isActiveTier) {
      return getProgressTierMessage();
    }
  }, [systemId, notActiveSystemMessage, isActiveTier, i18n.language]);

  const serverTierDisabledMessage = useMemo(
    () =>
      currentTier?.system.type === systemTypesKeys.INTERNAL &&
      currentTier?.serverTier?.status !== serverTierStatusKeys.ACTIVE
        ? t('validation.incorrectServerTierStatus')
        : undefined,
    [currentTier, i18n.language]
  );

  const serverTierDisabledForDeletingMessage = useMemo(
    () =>
      currentTier?.system.type === systemTypesKeys.INTERNAL &&
      ![serverTierStatusKeys.ACTIVE, serverTierStatusKeys.ERROR].includes(
        currentTier?.serverTier?.status
      )
        ? t('validation.incorrectServerTierStatus2')
        : undefined,
    [currentTier, i18n.language]
  );

  const isNotEditableMessage = useMemo(() => {
    if (notActiveOrErrorSystemMessage) {
      return notActiveOrErrorSystemMessage;
    }

    if (isNotEditableTier) {
      return getProgressTierMessage();
    }
  }, [systemId, notActiveOrErrorSystemMessage, isNotEditableTier, i18n.language]);

  const isNotEditableTierMessage = useMemo(() => {
    if (isNotEditableMessage) {
      return isNotEditableMessage;
    }

    if (isHaveDeploy) {
      return getTierHaveDeployMessage();
    }

    if (userInProgressMessage) {
      return getSystemHaveUpdetedUserMessage();
    }
  }, [isNotEditableMessage, userInProgressMessage, isHaveDeploy, i18n.language]);

  const getTooltipMessage = (isDeploy, isChange) => {
    if (tierDisabledMessage) {
      return tierDisabledMessage;
    }

    if (serverTierDisabledMessage) {
      return serverTierDisabledMessage;
    }

    if (!isChange && userInProgressMessage) {
      return getSystemHaveUpdetedUserMessage();
    }

    if (isDeploy && systemWithoutRelease) {
      return withoutReleaseMessage;
    }

    if (isChange) {
      if (hasQuotaInProgress) {
        return getQuotaInProgressMessage();
      }

      if (isTierHasQuote) {
        return getRequireApprovalMessage();
      }
    }
  };

  const optionsData = useMemo(() => {
    const isExternal = currentTier?.system.type === systemTypesKeys.EXTERNAL;
    const isPlatformScope = isExternal;
    const isArchiveTier = currentTier?.status === tierStatusKeys.ARCHIVE;

    const deployOption = {
      ...quoteOptions.deploy,
      isDisabled: getTooltipMessage(true),
      tooltip: getTooltipMessage(true),
      scope: isTierDev
        ? scopes.deployment.deploymentCreateToDev
        : scopes.deployment.deploymentCreate,
    };

    const deleteOption = {
      ...quoteOptions.delete,
      scope: isExternal ? scopes.platform.externalSystemDeleteTier : scopes.tier.tierDelete,
      isPlatformScope,
      isDisabled: isNotEditableTierMessage || serverTierDisabledForDeletingMessage,
      tooltip: isNotEditableTierMessage || serverTierDisabledForDeletingMessage,
    };

    const changeOption = {
      ...quoteOptions.change,
      isDisabled: getTooltipMessage(null, true),
      tooltip: getTooltipMessage(null, true),
      scope: scopes.tier.tierChangeQuota,
    };

    const archiveOption = {
      ...quoteOptions.archive,
      scope: isExternal ? scopes.platform.externalSystemArchiveTier : scopes.tier.tierArchive,
      isPlatformScope,
      isDisabled: isNotEditableTierMessage || serverTierDisabledForDeletingMessage,
      tooltip: isNotEditableTierMessage || serverTierDisabledForDeletingMessage,
    };

    const restoreOption = {
      ...quoteOptions.restore,
      scope: isExternal ? scopes.platform.externalSystemRestoreTier : scopes.tier.tierRestore,
      isPlatformScope,
      isDisabled:
        !isExternal &&
        (notActiveSystemMessage || userInProgressMessage || serverTierDisabledMessage),
      tooltip:
        notActiveSystemMessage || getSystemHaveUpdetedUserMessage() || serverTierDisabledMessage,
    };

    if (isExternal && !isArchiveTier) {
      return optionsFilterByScopes([archiveOption, deleteOption]);
    }

    if (isArchiveTier) {
      return optionsFilterByScopes([restoreOption]);
    }

    return optionsFilterByScopes([
      ...(isTierHasQuote
        ? [
            ...(checkedPermissions(scopes.tier.tierApproveQuota)
              ? [
                  {
                    ...quoteOptions.accept,
                    scope: scopes.tier.tierApproveQuota,
                    isDisabled:
                      tierDisabledMessage || hasQuotaInProgress || serverTierDisabledMessage,
                    tooltip:
                      tierDisabledMessage ||
                      serverTierDisabledMessage ||
                      getQuotaInProgressMessage(),
                  },
                ]
              : [{ ...quoteOptions.check, scope: scopes.tier.tierViewForm }]),
          ]
        : [
            ...(checkedPermissions(scopes.tier.tierChangeQuota)
              ? []
              : [
                  {
                    ...quoteOptions.request,
                    scope: scopes.tier.tierRequestQuota,
                    isDisabled: tierDisabledMessage,
                    tooltip: tierDisabledMessage,
                  },
                ]),
          ]),
      changeOption,
      deployOption,
      archiveOption,
      deleteOption,
    ]);
  }, [
    isTierHasQuote,
    isTierDev,
    currentTier,
    system,
    systemWithoutRelease,
    hasQuotaInProgress,
    quoteRequest,
    optionsFilterByScopes,
    tierDisabledMessage,
    userInProgressMessage,
    isNotEditableMessage,
    isNotEditableTierMessage,
    i18n.language,
  ]);

  const onFilterChange = id => {
    if (id === 5) {
      deleteTierModal.setModalData(currentTier);
      return deleteTierModal.toggleModal();
    }
    if (deployFormModal && id === 6) {
      deployFormModal.setModalData({
        tierId: currentTier?.uuid,
        system: currentTier?.system,
        isTierDev: currentTier?.devTier,
        currentRelease: currentTier?.currentRelease,
      });
      deployFormModal.toggleModal();
    }
    if (id === 7) {
      return archiveModal.toggleModal();
    }
    if (id === 8) {
      if (currentTier.system.type === systemTypesKeys.EXTERNAL) {
        archive.run(api.tiers.restoreTier({ tierId: currentTier?.uuid }));
        dispatch(
          changeCurrentTierInList({
            uuid: currentTier?.uuid,
            status: tierStatusKeys.RESTORING_IN_PROGRESS,
          })
        );
      } else {
        history.push(
          generatePath(paths.systemTiersFullPaths.restore, {
            systemId: currentTier.system.uuid,
            systemType: currentTier.system.type,
            tierId: currentTier?.uuid,
            action: systemTiersAction.restore,
          })
        );
      }
      return;
    }
    if (id !== 6) {
      return history.push(
        generatePath(paths.systemTierFullPaths.quote, {
          systemId: currentTier.system.uuid,
          systemType: currentTier.system.type,
          tierId: currentTier?.uuid,
          isTierDev: currentTier?.devTier,
          currentRelease: currentTier?.currentRelease,
        })
      );
    }
  };

  const archiveTier = () => {
    archive.run(api.tiers.archiveTier(currentTier?.uuid));
  };

  return (
    <div className="float-right">
      {!!optionsData.length && (
        <>
          <Dropdown
            id={currentTier?.uuid}
            onFilterChange={onFilterChange}
            optionData={optionsData || []}
            isBlueButtonDropdown={isBlueButtonDropdown}
            positionRigh={isBlueButtonDropdown}
            callback={callback}
          />

          <WarningModal
            title={t('pages.systemTierPage.archivingTier')}
            text={t('pages.systemTierPage.archiveTier')}
            isLoading={archive.isLoading}
            onClick={archiveTier}
            name={currentTier?.code}
            isModalVisible={archiveModal.isModalVisible}
            toggleModal={archiveModal.toggleModal}
            buttenText={t('common.archive')}
            warningColor="#f0ab00"
          />
        </>
      )}
    </div>
  );
}

export default React.memo(TierActions);
