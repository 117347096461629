import styled from 'styled-components';
import ContactSvg from 'assets/images/integrations/Contact.svg';

export const ContactIcon = styled.span`
  display: inline-block;
  height: 14.4px;
  width: 10.8px;
  background: #0f69c9;
  -webkit-mask-image: url(${ContactSvg});
  mask-image: url(${ContactSvg});
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-position: center;
  margin: 0 7.8px -2px 0;
`;
