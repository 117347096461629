import React from 'react';

import useModal from 'hooks/useModal';
import ModalDocuments from 'components/Common/ModalDocuments';
import { ReactComponent as ThumbtackSVG } from 'assets/images/integrations/Thumbtack.svg';

import * as S from './styles';

function DocumentsButton({ documents }) {
  const { isModalVisible, toggleModal } = useModal();

  const onDocumentsButtonClick = e => {
    e.preventDefault();

    toggleModal();
  };

  return documents.length ? (
    <div>
      <S.DocumentWrapper onClick={onDocumentsButtonClick}>
        <ThumbtackSVG width={20} />
        {documents.length}
      </S.DocumentWrapper>

      <ModalDocuments
        documents={documents}
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
      />
    </div>
  ) : null;
}

export default DocumentsButton;
