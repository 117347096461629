import styled from 'styled-components';
import { Select, MenuToggle, TextInput } from '@patternfly/react-core';

export const StyledMenuToggle = styled(MenuToggle)`
  width: 210px !important;
  height: 37px !important;
`;

export const StyledTextInput = styled(TextInput)`
  input {
    height: 37px !important;
    min-width: 90px;
  }
`;

export const DatePickerWrapper = styled.div`
  margin-right: -16px;
`;

export const StyledSelect = styled(Select)`
  max-height: 584px;
  overflow: overlay;
`;
