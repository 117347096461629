import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { ActionList, ActionListItem, Alert, Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { checkedTierTotal } from 'utils';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useStorage from 'hooks/useStorage';
import useUserPermissions from 'hooks/useUserPermissions';
import useSocketAsync from 'hooks/useSocketAsync';
import { currentSystemSelector } from 'store/systems/selectors';
import { usersSelector } from 'store/users/selectors';
import { fetchUserById } from 'store/users/actions';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { systemTypesKeys } from 'constants';
import paths from 'constants/paths';
import { tooltipFormDisableMessage } from 'constants/vaidations';
import TextBlock from 'components/UI/TextBlock';

import UserSelect from './UserSelect';
import { systemRole, getSystemRoleLabels } from '../../../../constants/roles';

import * as S from './styles';

function UserCreatePage({ match: { params } }) {
  const { t, i18n } = useTranslation();
  const systemRoleLabels = getSystemRoleLabels();
  const userRoleOptions = [
    {
      value: systemRole.DEV,
      label: systemRoleLabels[systemRole.DEV],
    },
    {
      value: systemRole.OPS,
      label: systemRoleLabels[systemRole.OPS],
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const { systemId, userId, action } = params;

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { storageData } = useStorage('currentSystem');
  const getSystemOptions = useAsync();
  const assignUser = useAsync();
  const userRolesBySystem = useAsync();
  const getSelectedSystem = useAsync();

  const currentSystem = useSelector(currentSystemSelector);
  const { selectedUser, selectedUserIsLoading } = useSelector(usersSelector);
  const [isUserHaveAlerts, setIsUserHaveAlerts] = useState(null);

  const formValue = formMethods.watch();
  const isSystemSelected = !!systemId;
  const isUserSelected = !!userId;
  const isNewAction = action && action === paths.usersActions.new;
  const selectedSystem = systemId ? currentSystem : getSelectedSystem.data;

  const { notActiveSystemMessage } = useUserPermissions(selectedSystem);

  useSocketAsync({
    topic: 'systems',
    debounceTime: 600,
    filterFn: ({ object }) =>
      !systemId && getSelectedSystem.data?.uuid && getSelectedSystem.data.uuid === object?.uuid,
    onMessage: async () =>
      getSelectedSystem.run(api.systems.getSystem(getSelectedSystem.data?.uuid)),
  });

  const notActiveMessage = useMemo(
    () => notActiveSystemMessage || checkedTierTotal(selectedSystem?.totalSummary?.tierTotal),
    [notActiveSystemMessage, selectedSystem?.totalSummary?.tierTotal, i18n.language]
  );

  const systemOptions = useMemo(
    () =>
      generateSelectOptions(getSystemOptions.data?.data, null, null, null, {
        getLabel: system => system?.shortName || system.name,
        getValue: system => system.uuid,
        getDescription: system => system.name,
      }),
    [getSystemOptions.data?.data]
  );

  const title = useMemo(() => {
    if (isSystemSelected && selectedSystem && !isNewAction) {
      const systemLink = generatePath(paths.routePaths.system, {
        systemId: selectedSystem.uuid,
        systemType: selectedSystem.type,
      });
      return (
        <span>
          {t('pages.adminUserCreatePage.assigningToSystem')}{' '}
          <Link to={systemLink}>{storageData.shortName || selectedSystem.shortName}</Link>
        </span>
      );
    }

    if (isUserSelected && selectedUser) {
      return <span>{t('pages.adminUserCreatePage.assigningToSystem')}</span>;
    }

    return <span>{t('pages.adminUserCreatePage.addingUser')}</span>;
  }, [selectedUser, selectedSystem, i18n.language]);

  const onSubmit = async formData => {
    assignUser.run(
      api.users.createUserRole(isUserSelected ? selectedUser?.uuid : formData.user, {
        system: { uuid: isSystemSelected ? selectedSystem.uuid : formData.system },
        name: formData.role,
      })
    );
  };
  const onCancel = () => history.goBack();

  useEffect(() => {
    if (assignUser.isSuccess) {
      onCancel();
    }
  }, [assignUser.isSuccess]);

  const alreadyExistingRoleName = useMemo(() => {
    if (
      userRolesBySystem.data?.data?.length &&
      (formValue.system || systemId) &&
      (formValue.user || userId)
    ) {
      const role = userRolesBySystem.data.data[0].name;

      return systemRoleLabels[role];
    }

    return null;
  }, [userRolesBySystem.data, formValue.system, formValue.user, i18n.language]);

  const isResponsibleUser = useMemo(() => {
    if (
      userRolesBySystem.data?.data?.length &&
      (formValue.system || selectedSystem || systemId) &&
      formValue.user
    ) {
      const systemResponsibleUserUuid = userRolesBySystem.data.data[0].system?.responsible?.uuid;
      const userUuid = isUserSelected ? selectedUser?.uuid : formValue.user;

      return systemResponsibleUserUuid === userUuid;
    }

    return false;
  }, [userRolesBySystem.data, formValue.system, formValue.user]);

  const optionsRequestParams = useMemo(
    () => ({
      type: systemTypesKeys.INTERNAL_WITH_PERMISSION,
      ascending: true,
      orderBy: 'shortName',
    }),
    []
  );

  useEffect(() => {
    if (isUserSelected) {
      dispatch(fetchUserById(userId));
    }
    if (isSystemSelected && !selectedSystem) {
      getSelectedSystem.run(api.systems.getSystem(systemId || formValue.system));
    }
  }, []);

  useEffect(() => {
    if (!isSystemSelected && !systemId && formValue.system) {
      getSelectedSystem.run(api.systems.getSystem(formValue.system));
    } else {
      getSelectedSystem.setData(null);
    }
  }, [formValue.system]);

  useEffect(() => {
    const user = isUserSelected ? selectedUser?.uuid : formValue.user;
    const system = isSystemSelected ? selectedSystem?.uuid || systemId : formValue.system;

    if (user && system) {
      userRolesBySystem.run(api.users.getUserRoles(user, { system, limit: 1, offset: 0 }));
    }
  }, [formValue.user, selectedUser, selectedSystem]);

  const searchFilter = async q => {
    try {
      const filterOptions = await getSystemOptions.run(
        api.systems.getSystemOptions({
          ...optionsRequestParams,
          q,
        })
      );

      return generateSelectOptions(filterOptions.data, null, null, null, {
        getLabel: system => system.shortName || system.name,
        getValue: system => system.uuid,
        getDescription: system => system.name,
      });
    } catch (e) {
      return [];
    }
  };

  const isDisabled =
    isUserHaveAlerts ||
    isResponsibleUser ||
    alreadyExistingRoleName ||
    (isSystemSelected && !formValue?.user) ||
    (!selectedSystem && !formValue.user) ||
    (!selectedSystem && !formValue.system) ||
    notActiveMessage;

  return (
    <PageLayout
      title={title}
      withoutBackButton
      withoutSidePadding
      withSystemSelector={systemId}
      isWaitLoading={userId && selectedUserIsLoading}
    >
      <S.FormWrapper className="ml-4">
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className="d-sm-flex flex-column gap-4"
          >
            <UserSelect
              setIsUserHaveAlerts={setIsUserHaveAlerts}
              selectedSystem={selectedSystem}
              isUserSelected={isUserSelected}
            />

            {isSystemSelected ? (
              <TextBlock greyColor title={t('common.system')} isForm>
                <div className="pre-line medium-line-height font-16">
                  {selectedSystem?.shortName}
                  <div className="font-12 font-weight-normal text-gray mt-1">
                    {selectedSystem?.name}
                  </div>
                </div>
              </TextBlock>
            ) : (
              <div>
                <InputComponent
                  label={t('common.system')}
                  name="system"
                  component={inputComponents.newSelect}
                  isLoading={getSystemOptions.isLoading}
                  newSelectVariant="typeahead"
                  searchFilter={searchFilter}
                  placeholder={t('common.searchByNameAndDescription')}
                  isRequired
                  options={systemOptions}
                  descriptionMaxLength={100}
                />
                {isResponsibleUser && (
                  <div className="text-danger" data-input-error-for={t('common.system')}>
                    {t('pages.adminUserCreatePage.unableToEditRoleofResponsible')}
                  </div>
                )}
              </div>
            )}

            <InputComponent
              label={t('common.role')}
              name="role"
              component={inputComponents.radioInput}
              defaultValue={userRoleOptions[0].value}
              options={userRoleOptions}
              rowDirection
            />

            {alreadyExistingRoleName && (
              <Alert
                variant="warning"
                isInline
                title={t('pages.adminUserCreatePage.roleAlreadyAssigned', [
                  alreadyExistingRoleName,
                ])}
                className="font-weight-normal mb-4"
              />
            )}

            <ActionList className="pt-4">
              <Tooltip
                content={notActiveMessage || (isDisabled && tooltipFormDisableMessage())}
                className={isDisabled ? undefined : 'd-none'}
                exitDelay={150}
                animationDuration={150}
              >
                <ActionListItem>
                  <Button
                    onClick={formMethods.handleSubmit(onSubmit)}
                    isLoading={formMethods.formState.isSubmitting || assignUser.isLoading}
                    isDisabled={
                      formMethods.formState.isSubmitting || assignUser.isLoading || isDisabled
                    }
                  >
                    {t(
                      (isUserSelected && selectedUser) || !systemId
                        ? 'common.assign'
                        : userId
                        ? 'common.saveChanges'
                        : 'common.add'
                    )}
                  </Button>
                </ActionListItem>
              </Tooltip>
              <ActionListItem>
                <Button variant="link" onClick={onCancel}>
                  {t('common.cancel')}
                </Button>
              </ActionListItem>
            </ActionList>
          </form>
        </FormProvider>
      </S.FormWrapper>
    </PageLayout>
  );
}

export default UserCreatePage;
