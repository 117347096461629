import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 24px 27px 24px;
  flex-direction: column;
  align-items: flex-start;
`;

export const System = styled(Link)`
  display: flex;
  cursor: pointer;
  margin: 8px 0;
  align-items: center;
`;

export const BlueButtonWrapper = styled.div`
  margin-top: 30px;
`;
