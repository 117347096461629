import React, { useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useTableSearchParams from 'components/Common/DataTable/hooks/useTableSearchParams';

import { customTableSort } from 'utils';
import useInfinitePagination from './hooks/useInfinitePagination';

import Pagination from './Pagination';
import BodyData from './BodyData';
import TableCheckbox from './TableCheckbox';

import types from './types';

import * as S from './styles';

export { types };

const DataTable = React.forwardRef(
  (
    {
      headerData = [],
      bodyData = [],
      requestFunction,
      requestParams,
      total,
      tableName,
      withWebsockets = false,
      // pagination
      withStandartPagination = false,
      withInfinitePagination = false,
      // style props
      headerColor,
      withSidesMargin,
      labelCenter,
      // checkbox
      withCheckboxes,
      checkedUuids,
      setCheckedUuids,
      // expanded state
      expandedKey,
      clientSort,
      grid,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const rowsCount = bodyData.length;

    const {
      params,
      paginationParams,
      sortParams,
      loadMore,
      changeSortParams,
      changePaginationParams,
    } = useTableSearchParams(
      requestFunction,
      requestParams,
      tableName,
      withInfinitePagination,
      withWebsockets
    );

    const gridTemplateColumns = `${withCheckboxes ? '50px ' : ''}${headerData
      .map(({ width }) => width)
      .join(' ')}`;

    // Intersection scroll pagination hook
    const { ref: paginationRef } = useInfinitePagination({
      rowsCount,
      withInfinitePagination,
      loadMore,
      total,
    });

    const onHeaderCellClick = (withSort, key, event) => {
      event.preventDefault();
      if (!withSort) {
        return;
      }

      const newSort = {
        orderBy: key,
        ascending: sortParams?.orderBy === key ? !sortParams?.ascending : false,
      };

      changeSortParams?.(newSort);
    };

    if (clientSort) {
      customTableSort(bodyData, sortParams?.ascending, sortParams?.orderBy);
    }

    const allIds = useMemo(
      () => bodyData.filter(({ isCheckboxDisabled }) => !isCheckboxDisabled).map(({ id }) => id),
      [bodyData]
    );

    const requestDataWithParams = () => {
      if (requestFunction) {
        requestFunction({ ...params, ...paginationParams, ...sortParams });
      }
    };

    useImperativeHandle(ref, () => ({
      requestDataWithParams,
    }));

    if ((!total && !rowsCount) || (!rowsCount && !requestFunction)) {
      return null;
    }

    return (
      <>
        <S.TableWrapper withSidesMargin={withSidesMargin}>
          <thead>
            <S.TableHeadWrapper
              headerColor={headerColor}
              gridTemplateColumns={gridTemplateColumns}
              grid={grid}
            >
              {withCheckboxes && (
                <S.TableHeaderCell withCheckboxes={withCheckboxes} grid={grid}>
                  <TableCheckbox
                    isChecked={checkedUuids && allIds.length === checkedUuids?.length}
                    onChange={() =>
                      setCheckedUuids(prevState =>
                        prevState.length === allIds.length ? [] : allIds
                      )
                    }
                    label={t('common.selectAll')}
                  />
                </S.TableHeaderCell>
              )}
              {headerData.map(({ label, key, withSort, width }) => (
                <S.TableHeaderCell key={key} labelCenter={labelCenter} width={width} grid={grid}>
                  {withSort ? (
                    <S.TableHeaderCellButton
                      headerColor={headerColor}
                      onClick={event => onHeaderCellClick(withSort, key, event)}
                    >
                      {label}
                      <S.UpSortArrowIcon
                        $isActive={sortParams?.orderBy === key && sortParams?.ascending === true}
                      />
                      <S.DownSortArrowIcon
                        $isActive={sortParams?.orderBy === key && sortParams?.ascending === false}
                      />
                    </S.TableHeaderCellButton>
                  ) : (
                    <>{label}</>
                  )}
                </S.TableHeaderCell>
              ))}
            </S.TableHeadWrapper>
          </thead>

          <tbody>
            {bodyData.map((items, index) => (
              <BodyData
                key={items.id}
                items={items}
                index={index}
                ref={paginationRef}
                gridTemplateColumns={gridTemplateColumns}
                withInfinitePagination={withInfinitePagination}
                rowsCount={rowsCount}
                params={params}
                // checkbox
                withCheckboxes={withCheckboxes}
                checkedUuids={checkedUuids}
                setCheckedUuids={setCheckedUuids}
                expandedKey={expandedKey}
                grid={grid}
              />
            ))}
          </tbody>
        </S.TableWrapper>
        {withStandartPagination && paginationParams && (
          <Pagination
            total={total}
            paginationParams={paginationParams}
            changePaginationParams={changePaginationParams}
            expandedKey={expandedKey}
          />
        )}
      </>
    );
  }
);

export default React.memo(DataTable);
