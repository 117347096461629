import styled from 'styled-components';
import { Wizard } from '@patternfly/react-core/deprecated';

import { InputsBlockWrapper } from 'components/UI/View';

export const WizardStyled = styled(Wizard)`
  border-top: 1px solid #d2d2d2;
  min-height: 70vh;

  .pf-v5-c-wizard__outer-wrap {
    z-index: 1 !important;
  }

  .pf-v5-c-wizard__toggle {
    z-index: 2 !important;
  }

  .pf-v5-c-wizard__nav {
    background-color: #ffffff;
  }
`;

export const Form = styled.form.attrs({
  className: 'd-sm-flex flex-column mx-4 mb-4',
})`
  gap: 24px;
  max-width: 750px;
`;

export const InputsBlock = styled(InputsBlockWrapper)`
  margin: 0;
`;

export const PortsInputsBlock = styled(InputsBlockWrapper)`
  max-width: 750px;
  margin: 0 0 20px 0;
`;

export const DeleteButtonAbsoluteWrapper = styled.div`
  position: absolute;
  right: ${({ isModalForm }) => (isModalForm ? '-25px' : '-35px')};
  top: ${({ withLabel }) => (withLabel ? '8px' : '31px')};
`;

export const ProtocolInputWrapper = styled.div`
  width: 210px;
`;
