import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import { notActiveSystemSelector } from 'store/systems/selectors';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../../../../constants/tooltips';

function CompleteButton({
  issue = [],
  onCompleteButtonClick,
  checkedTasksUuids = [],
  checkedFlagResolved = [],
  setCheckedFlagResolved,
}) {
  const { t, i18n } = useTranslation();
  const { isModalVisible, setIsModalVisible, toggleModal } = useModal(false);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const [isCheckDisabled, setIsCheckDisabled] = useState(true);

  useEffect(() => {
    if (checkedFlagResolved.includes(issue?.uuid)) {
      setIsModalVisible(true);
    }
  }, [checkedFlagResolved]);

  useEffect(() => {
    if (isCheckDisabled && isModalVisible) {
      setIsModalVisible(false);
    }
  }, [isCheckDisabled, isModalVisible]);

  useEffect(() => {
    if (checkedTasksUuids.includes(issue?.uuid)) {
      setIsCheckDisabled(false);
    } else {
      if (isModalVisible) {
        setCheckedFlagResolved(checkedFlagResolved.filter(item => item !== issue?.uuid));
      }
      setIsCheckDisabled(true);
    }
  }, [checkedTasksUuids.length, isModalVisible]);

  const onButtonClick = e => {
    e.preventDefault();
    onCompleteButtonClick(issue.uuid);
    toggleModal();
  };

  return (
    <>
      {issue && (
        <Tooltip
          className={notActiveSystemMessage || isCheckDisabled ? undefined : 'd-none'}
          content={notActiveSystemMessage || t('pages.systemAddReleasesPage.notMarkedInRelease')}
          exitDelay={150}
          animationDuration={150}
        >
          <div className="w-content">
            <S.CompleteButton
              isDisabled={isCheckDisabled}
              $isCompletTask={isModalVisible}
              onClick={onButtonClick}
            >
              <S.CompleteButtonWrapper>
                {isModalVisible ? (
                  <div className="d-flex flex-row text-black">
                    <S.CompleteIcon />
                    {t('pages.systemAddReleasesPage.resolved')}
                  </div>
                ) : (
                  t('pages.systemAddReleasesPage.resolved')
                )}
              </S.CompleteButtonWrapper>
            </S.CompleteButton>
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default CompleteButton;
