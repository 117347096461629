import { useCallback, useLayoutEffect, useReducer, useRef } from 'react';
import { generatePath, useHistory } from 'react-router';
import paths from 'constants/paths';

const reducer = (state, action) => ({
  ...state,
  ...action,
});

// safes from dispatch on unmounted component
function useSafeDispatch(dispatch) {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback(action => (mounted.current ? dispatch(action) : undefined), [dispatch]);
}

// example of usage
// const { run, data, isLoading, isSuccess, isError } = useAsync<DataType>()
// useEffect(() => { run(fetch(params)) }, [fetchData, params])

function useAsync(defaultInitialState) {
  const initialStateRef = useRef({
    status: 'idle',
    data: null,
    error: null,
    ...defaultInitialState,
  });
  const history = useHistory();

  const [{ status, ...state }, setState] = useReducer(reducer, initialStateRef.current);

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback(data => safeSetState({ data, status: 'resolved' }), [safeSetState]);
  const setError = useCallback(
    error => safeSetState({ error, status: 'rejected' }),
    [safeSetState]
  );
  const reset = useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  const run = useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(`argument of run is not a promise`);
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        ({ data }) => {
          setData(data);
          return data;
        },
        error => {
          if (!error) {
            safeSetState({ status: 'pending' });
          } else {
            setError(error);
            return Promise.reject(error);
          }
        }
      );
    },
    [safeSetState, setData, setError]
  );

  const isServerError =
    state?.error?.response &&
    (state.error.response?.status >= 500 || state.error.response?.status === 0);

  if (state.withRedirectForError && isServerError) {
    history.push(generatePath(paths.routePaths.error));
  }

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isRejected: status === 'rejected',
    isSuccess: status === 'resolved',
    isError: isServerError,

    setData,
    setError,
    error: state.error,
    status,
    data: state.data,
    run,
    reset,
  };
}

export default useAsync;
