import React from 'react';
import { Link } from 'react-router-dom';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import PageLayout from 'components/UI/PageLayout';
import { ReactComponent as ErrorSVG } from 'assets/images/main/error.svg';
import { BlueButton } from 'components/UI/Button';
import paths from 'constants/paths';
import { useTranslation } from 'react-i18next';

function AccessErrorPage() {
  const { t } = useTranslation();
  const { platformPermissions } = useUserPermissions();

  const withPermissions = platformPermissions?.includes(scopes.platform.platformView);
  const style = withPermissions ? '' : 'text-red';

  return (
    <PageLayout fullHeightPage>
      <div className="m-auto p-4">
        <div className="text-center">
          <ErrorSVG className="m-4" />
        </div>
        <div className="align-self-center">
          <h2 className="font-24 font-weight-medium text-center my-3">
            {t('pages.errorPage.title')}
          </h2>
          <div className="font-16 font-weight-normal text-center mb-3">
            {t('pages.errorPage.descriptionStart')}{' '}
            <span className={style}>{t('pages.errorPage.descriptionMiddle')}</span> <br />{' '}
            {t('pages.errorPage.descriptionEnd')}
          </div>
          <div className="text-center">
            {withPermissions && (
              <BlueButton component={props => <Link {...props} to={paths.routePaths.main} />}>
                {t('pages.errorPage.goToMainPage')}
              </BlueButton>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default AccessErrorPage;
