import React from 'react';
import GeneralInfo from './GeneralInfo';
import Contacts from './Contacts';
import Tiers from './Tiers';
import i18n from '../../../../i18n';

import { stepsKeys } from './keys';

const { t } = i18n;

export const steps = [
  {
    id: 1,
    key: stepsKeys.generalInfo,
    getName: () => t('common.generalInfo'),
    component: <GeneralInfo />,
    fieldsToValidate: ['name', 'shortName', 'code'],
    defaultData: {
      name: '',
      shortName: '',
      code: '',
      description: '',
    },
  },
  {
    id: 2,
    key: stepsKeys.contactPersons,
    getName: () => t('common.contacts'),
    component: <Contacts />,
    fieldsToValidate: ['contactPersons'],
    fieldsNotRequired: ['contactPersons'],
    defaultData: {
      responsible: '',
    },
  },
  {
    id: 3,
    key: stepsKeys.tiers,
    getName: () => t('common.tiers'),
    component: <Tiers />,
    fieldsToValidate: ['tiers'],
    fieldsNotRequired: ['tiers'],
    defaultData: {
      tiers: [
        {
          code: '',
        },
      ],
    },
  },
];

export const systemModel = {
  ...steps.reduce(
    (acc, { defaultData }) => ({
      ...acc,
      ...defaultData,
    }),
    {}
  ),
};
