import React from 'react';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';
import { dateTimeConverter, isValidUrl } from 'utils';

import useModal from 'hooks/useModal';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import StatusComponent, { getBackupStatuses } from 'components/Common/StatusComponent';
import ModalInfo from 'components/Common/ModalInfo';
import TextBlock from 'components/UI/TextBlock';
import ReadMoreBlock from 'components/Common/ReadMoreBlock';
import TruncateWithTooltipText from 'components/Common/TruncateWithTooltipText';
import { useTranslation } from 'react-i18next';

function TierComponentBackupTable({
  requestParams,
  requestFunction,
  data,
  isLoading,
  hasSelectedFilters,
  tableName,
  isError,
}) {
  const { t } = useTranslation();
  const backupStatuses = getBackupStatuses();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal();
  const headerData = [
    {
      key: 'backupResultUrl',
      label: t('pages.systemTierPage.backupArtifectsLink'),
      width: 25,
    },
    {
      key: 'comment',
      label: t('common.comment'),
      withSort: true,
      width: 40,
    },

    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'backupEndDateTime',
      label: t('common.date'),
      withSort: true,
    },
  ];
  const onInfoClick = item => {
    setModalData(item);
    toggleModal();
  };

  const title = (
    <>
      <span>{t('pages.systemTierPage.componentBackup')}</span>
      <span className="hyphens-break-word ml-1">{modalData?.component?.name}</span>{' '}
      {dateTimeConverter(modalData?.backupEndDateTime)}
    </>
  );

  const formattedBodyData = data?.data?.map((item, index) => ({
    id: `${item?.uuid}${index}`,
    data: [
      {
        key: 'backupResultUrl',
        content: (
          <TruncateWithTooltipText
            icon={
              isValidUrl(item.backupResultUrl) && <ExternalLinkAltIcon className="ml-1 font-14" />
            }
            content={item.backupResultUrl}
          />
        ),
        type: isValidUrl(item.backupResultUrl) ? types.externalLink : null,
        href: item.backupResultUrl,
      },
      {
        key: 'comment',
        content: <ReadMoreBlock content={item.comment} withoutExpandButton />,
        type: item.comment?.length ? types.textLink : null,
        onClick: () => onInfoClick(item),
      },
      {
        key: 'status',
        content: item?.status && (
          <StatusComponent statusId={item?.status} statuses={backupStatuses} />
        ),
      },
      {
        key: 'backupEndDateTime',
        content: item?.backupEndDateTime,
        type: types.relativeTime,
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName={tableName}
        withStandartPagination
        total={data?.meta?.total}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />
      <ModalInfo isModalVisible={isModalVisible} toggleModal={toggleModal} title={title}>
        <TextBlock greyColor font="16">
          {modalData?.comment}
        </TextBlock>
      </ModalInfo>
    </>
  );
}

export default TierComponentBackupTable;
