import styled from 'styled-components';

import { RedTextWhiteButton } from 'components/UI/Button';

export const RedButtonWrapper = styled(RedTextWhiteButton)`
  position: absolute;
  top: 100px;
  right: 24px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 45px 0 24px;
  height: 40px;
  width: auto;
`;

export const BottomWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const BottomButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: 64px;
`;

export const InputWrapper = styled.div`
  height: 30px;
  width: 400px;
  font-size: 20px;
`;
