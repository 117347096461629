export function getTimeRanges() {
  const ranges = [];
  const date = new Date();
  const format = {
    hour: 'numeric',
    minute: 'numeric',
  };

  for (let minutes = 0; minutes < 24 * 60; minutes += 30) {
    date.setHours(0);
    date.setMinutes(minutes);
    ranges.push(date.toLocaleTimeString('ru', format));
  }

  return ranges;
}
