import styled from 'styled-components';
import { Switch, Dropdown, MenuToggle } from '@patternfly/react-core';

export const StyledMenuToggle = styled(MenuToggle)`
  .pf-v5-c-menu-toggle {
    padding: 0 !important;
  }

  .pf-v5-c-menu-toggle__controls {
    padding-left: 0;
  }

  .pf-v5-c-menu-toggle__toggle-icon {
    margin-right: 0;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  left: -72px !important;

  .pf-v5-c-menu__content {
    max-height: 360px !important;
    width: 340px !important;
  }

  .pf-v5-c-menu {
    width: fit-content;
    min-width: fit-content;
  }
`;

export const StyledSwitch = styled(Switch)`
  padding: 9px 16px 9px 16px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #dee2e6 !important;

  .pf-v5-c-switch__toggle {
    background-color: ${({ isChecked }) => (isChecked ? '#06c' : '#f1f2f3')} !important;
    &::before {
      background-color: #fff;
    }
  }

  .pf-v5-c-switch__label {
    color: #000 !important;
  }
`;

export const ShortNameWrapper = styled.span`
  display: list-item;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const SpinnerWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center ml-2',
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.35);
  z-index: 1;
`;
