import styled from 'styled-components';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CalendarSVG from 'assets/images/notifications/calendar.svg';
import IcoEmptyPNG from 'assets/images/notifications/ico-empty-notification.png';

export const NotificationsWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  background: #ffffff;
`;

export const NotificationHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Filter = styled.div`
  display: flex;
  flex: 1;
  margin-top: 16px;
  flex-direction: row;
  * {
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
`;

export const NotificationTitle = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 400;
  color: #151515;
  flex: 1;
  margin-top: 24px;
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  overflow-y: scroll;
  flex: 1;
`;

export const NotificationDateBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const NotificationDate = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationBlock = styled.div`
  display: flex;
  padding: 8px 0;
  width: 100%;
  min-height: 37px;
  align-items: center;
  cursor: pointer;
`;

export const NotificationTime = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #8b8d8f;
  width: 40px;
  margin-right: 16px;
`;

export const Dates = styled.div`
  display: flex;
  padding: 6px 12px;
  width: 230px;
  background: #ffffff;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #6a6e73;
  margin-left: 8px;

  display: flex;
  box-shadow: inset 0px -1px 0px #8a8d90, inset 1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0,
    inset -1px 0px 0px #f0f0f0;
`;

export const DatesText = styled.div`
  display: flex;
  font-size: 14px;
  flex: 1;
  line-height: 21px;
`;

export const CalendarIco = styled.div`
  display: flex;
  width: 14px;
  height: 16px;
  background: url(${CalendarSVG}) no-repeat;
  background-size: cover;
`;

export const NotificationEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 50px;
`;

export const NotificationEmptyIco = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  background: url(${IcoEmptyPNG}) no-repeat;
  background-size: cover;
`;

export const NotificationEmptyText = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  color: #000000;
`;

export const NotificationFilterText = styled.h2.attrs({
  className: 'font-14 font-weight-semiBold text-black mr-1',
})`
  align-self: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`;
