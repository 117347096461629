import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchQuoteRequest, fetchTierQuotes } from 'store/tierQuotes/actions';
import { tierQuotesSelector } from 'store/tierQuotes/selectors';
import StatusComponent, { getQuotesStatuses } from 'components/Common/StatusComponent';
import QuoteLimits from 'components/Common/QuoteLimits';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { getLimitsLabels } from 'constants';

function QuotesTable({
  filterParams,
  setIsShowFilters,
  hasSelectedFilters,
  currentTotal,
  isShowFilters,
}) {
  const { t } = useTranslation();
  const quotesStatuses = getQuotesStatuses();
  const limitsLabels = getLimitsLabels();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId, tierId },
  } = useRouteMatch();

  const headerData = [
    {
      key: 'quote',
      label: `${limitsLabels.CPU} / ${limitsLabels.MEMORY} / ${limitsLabels.STORAGE}`,
    },
    {
      key: 'createdBy',
      label: t('pages.systemTierPage.quotaAuthor'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'createdAt',
      label: t('pages.systemTierPage.quotaCreatedAt'),
      withSort: true,
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'approvedBy',
      label: t('pages.systemTierPage.quotaApprover'),
      minWidth: '150px',
      withSort: true,
    },
    {
      key: 'approvedAt',
      label: t('pages.systemTierPage.quotaApprovalDate'),
      withSort: true,
    },
  ];
  const { data, total, isLoading, isError } = useSelector(tierQuotesSelector);

  const requestFunction = searchParams => {
    dispatch(fetchTierQuotes(searchParams));
  };

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      tier: tierId,
      orderBy: 'approvedAt',
      ascending: false,
    }),
    [tierId, filterParams]
  );

  useSocketAsync({
    topic: 'tiers/quotas',
    filterBySystemUuid: systemId,
    filterFn: ({ object }) => object.tier?.uuid === tierId,
    options: { callBackThrottleTime: 1000 },
    onMessage: () => {
      dispatch(fetchQuoteRequest({ limit: 1, tier: tierId, status: 'NEW' }));
      tableRef.current?.requestDataWithParams({ isSilentMode: true });
    },
  });

  useEffect(() => {
    if (!isShowFilters || !currentTotal) {
      setIsShowFilters?.(hasSelectedFilters || Boolean(currentTotal) || Boolean(total));
    }
  }, [total, currentTotal, hasSelectedFilters]);

  const formattedBodyData = data.map((quote, index) => ({
    id: `${quote.uuid}${index}`,
    data: [
      {
        key: 'quote',
        content: <QuoteLimits limits={quote.limits} />,
      },
      {
        key: 'createdBy',
        content: (
          <LastUpdatedStatusWithLink lastUpdatedBy={quote?.createdBy} isTableLink hideDateTime />
        ),
      },
      {
        key: 'createdAt',
        content: quote.createdAt,
        isDateClientSort: quote.createdAt,
        type: types.relativeTime,
      },
      {
        key: 'status',
        content: (
          <StatusComponent
            statusId={quote.status}
            statuses={quotesStatuses}
            objectUuid={quote.uuid}
            objectType="QUOTA_REQUEST"
          />
        ),
      },
      {
        key: 'approvedBy',
        content: (
          <LastUpdatedStatusWithLink lastUpdatedBy={quote?.approvedBy} isTableLink hideDateTime />
        ),
      },
      {
        key: 'approvedAt',
        content: quote.approvedAt,
        isDateClientSort: quote.approvedAt,
        type: types.relativeTime,
      },
    ],
  }));

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      isLoading={isLoading}
      requestParams={requestParams}
      requestFunction={requestFunction}
      total={total}
      tableName="quotes"
      withStandartPagination
      hasSelectedFilters={hasSelectedFilters}
      showNoDataPerFiltersMessage={isShowFilters}
      isError={isError}
    />
  );
}

export default QuotesTable;
