import React, { useEffect, useState } from 'react';

import { ErrorText } from './styles';

const useFormError = errorMessage => {
  const [errorText, setErrorText] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setErrorText(errorMessage);
    }
  }, [errorMessage]);

  return {
    setErrorText,
    errorComponent: !!errorText && <ErrorText>{errorText}</ErrorText>,
  };
};

export default useFormError;
