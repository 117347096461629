import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import AdminRoute from 'routes/AdminRoute';
import { scopes } from 'constants/scopes';

import BackupsPage from './BackupsPage';
import ComponentBackupPage from './ComponentBackupPage';

const BackupsRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute
      exact
      path={`${path}${paths.tierComponentsPaths.component}`}
      component={ComponentBackupPage}
      scope={scopes.platform.backupViewComponent}
    />

    <Route exact path={path} component={BackupsPage} />

    <Redirect to={path} />
  </Switch>
);

export default BackupsRouter;
