import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';

import AddButton from 'components/UI/AddButton';
import ExternalTierForm from './ExternalTierForm';

import { stepsKeys } from '../keys';
import { SystemBlockWrapper } from '../../styles';

const dynamicParentField = stepsKeys.tiers;

function Tiers() {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField, true);
  const { checkedPermissions } = useUserPermissions();

  useEffect(() => {
    setValue('step', 3);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.tiers')}</div>

      {fieldUuids.map((key, index) => (
        <ExternalTierForm
          key={key}
          fieldUuid={key}
          index={index}
          deleteField={deleteField}
          fieldUuidsLength={fieldUuids.length}
        />
      ))}
      <div className="d-flex mb-3">
        <AddButton
          onClick={addField}
          disabled={!checkedPermissions(scopes.platform.externalSystemCreateTier, true)}
        >
          {t('common.add')}
        </AddButton>
      </div>
    </SystemBlockWrapper>
  );
}

export default Tiers;
