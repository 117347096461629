import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useSocketAsync from 'hooks/useSocketAsync';
import paths from 'constants/paths';
import { TextButton } from 'components/UI/Button';
import StatusComponent, { getReleasesAvailableStatuses } from 'components/Common/StatusComponent';
import { getReleasesTooltip } from 'constants/tooltips';

function LastReleaseName({ tier, systemId, systemType }) {
  const { hasPermissions, checkedPermissions } = useUserPermissions(tier?.system);
  const { data, run, isSuccess } = useAsync();
  const releasesAvailableStatuses = getReleasesAvailableStatuses();
  const releasesTooltip = getReleasesTooltip();
  const [currentRelease, setCurrentRelease] = useState(tier.currentRelease);

  const requestParams = useMemo(
    () => ({
      tier: tier.uuid,
      ascending: false,
      orderBy: 'lastUpdatedAt',
      limit: 1,
    }),
    [tier.uuid]
  );

  const requestFunction = params => {
    if (hasPermissions) {
      run(api.tiers.getDeployedRelease(params));
    }
  };

  useSocketAsync({
    topic: 'tiers/releases',
    filterBySystemUuid: systemId,
    filterFn: ({ object }) => object.tier?.uuid === tier?.uuid,
    options: { callBackThrottleTime: 1300 },
    onMessage: () => requestFunction(requestParams),
  });

  useEffect(() => {
    if (isSuccess) {
      if (tier.uuid === data?.data[0]?.tier.uuid) {
        setCurrentRelease(data?.data[0].release);
      }
    }
  }, [isSuccess]);

  const releaseName = (
    <StatusComponent
      statusId={currentRelease?.status}
      statusWithTooltip
      statuses={releasesAvailableStatuses}
    >
      {checkedPermissions(scopes.release.releaseViewForm) ? (
        <TextButton
          variant="link"
          component={props => (
            <Link
              {...props}
              to={
                systemId && systemType
                  ? generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
                      systemId,
                      systemType,
                      action: paths.releaseActions.deploy,
                      releaseId: currentRelease?.uuid,
                    })
                  : undefined
              }
            />
          )}
        >
          <span className="font-14 mr-2">{currentRelease?.name}</span>
        </TextButton>
      ) : (
        <span className="font-14 mr-2">{currentRelease?.name}</span>
      )}
      {!!currentRelease?.totalSummary?.serviceVersionTotal?.error && (
        <Tooltip
          content={releasesTooltip.releaseVersionError}
          position="top"
          exitDelay={150}
          animationDuration={150}
        >
          <ExclamationCircleIcon className="font-14 mr-2 text-red cursor-pointer" />
        </Tooltip>
      )}
    </StatusComponent>
  );

  return currentRelease?.name ? releaseName : '-';
}

export default LastReleaseName;
