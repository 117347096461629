import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const serviceVersionsSlice = createSlice({
  name: 'serviceVersionsBuildResults',
  initialState: {
    data: [],
    total: 0,
    isLoading: true,
    isError: null,
    vulnerabilities: { data: [], total: 0, isLoading: true, isError: null },
    staticAnalysis: { data: [], isLoading: true },
    unitTesting: { data: [], total: 0, isLoading: true, isError: null },
  },
  reducers: {
    clearStateBuildResults: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.isError = null;
      state.vulnerabilities = { data: [], total: 0, isLoading: true, isError: null };
      state.staticAnalysis.data = [];
      state.staticAnalysis.isLoading = true;
      state.unitTesting = { data: [], total: 0, isLoading: true, isError: null };
    },
    clearStateVulnerabilities: state => {
      state.vulnerabilities = { data: [], total: 0, isLoading: true, isError: null };
    },
  },
  extraReducers: {
    [actions.fetchServiceVersionsBuildResults.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchServiceVersionsBuildResults.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchServiceVersionsBuildResults.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
    [actions.fetchServiceVersionsVulnerabilities.pending]: state => {
      state.vulnerabilities.isLoading = true;
      state.vulnerabilities.isError = null;
    },
    [actions.fetchServiceVersionsVulnerabilities.fulfilled]: (
      state,
      { payload: { data, meta } }
    ) => {
      state.vulnerabilities.data = data;
      state.vulnerabilities.isLoading = false;
      state.vulnerabilities.total = meta.total;
      state.vulnerabilities.isError = null;
    },
    [actions.fetchServiceVersionsVulnerabilities.rejected]: (state, { error }) => {
      state.vulnerabilities.isLoading = false;

      if (checkedServerError(error.message)) {
        state.vulnerabilities.data = [];
        state.vulnerabilities.total = 0;
        state.vulnerabilities.isError = true;
      }
    },
    [actions.fetchServiceVersionsStaticAnalysis.pending]: state => {
      state.staticAnalysis.isLoading = true;
    },
    [actions.fetchServiceVersionsStaticAnalysis.fulfilled]: (state, { payload }) => {
      state.staticAnalysis.data = payload;
      state.staticAnalysis.isLoading = false;
    },
    [actions.fetchServiceVersionsStaticAnalysis.rejected]: state => {
      state.staticAnalysis.isLoading = false;
    },
    [actions.fetchServiceVersionsUnitTesting.pending]: state => {
      state.unitTesting.isLoading = true;
      state.unitTesting.isError = null;
    },
    [actions.fetchServiceVersionsUnitTesting.fulfilled]: (state, { payload: { data, meta } }) => {
      state.unitTesting.data = data;
      state.unitTesting.isLoading = false;
      state.unitTesting.total = meta.total;
      state.unitTesting.isError = null;
    },
    [actions.fetchServiceVersionsUnitTesting.rejected]: (state, { error }) => {
      state.unitTesting.isLoading = false;
      state.unitTesting.isError = checkedServerError(error.message);
    },
  },
});

export const { clearStateBuildResults, clearStateVulnerabilities } = serviceVersionsSlice.actions;

export default serviceVersionsSlice.reducer;
