import http from 'api/config';

const importServices = groupUuid => http.post(`service-imports/${groupUuid}/process`);

const testImportServices = (data, params) =>
  http.post(`service-imports/test-connection`, data, {
    headers: params,
  });

const createImportService = params => http.post(`service-imports`, params);

const getImportService = uuid => http.get(`service-imports/${uuid}`);

const getRepositories = ({ groupUuid, ...params }) =>
  http.get(`service-imports/${groupUuid}/repositories`, { params });

const editRepository = (groupUuid, uuid, params) =>
  http.put(`service-imports/${groupUuid}/repositories/${uuid}`, params);

const massEditRepository = (groupUuid, data) =>
  http.post(`service-imports/${groupUuid}/repositories`, data);

const deleteRepository = (groupUuid, uuid) =>
  http.delete(`service-imports/${groupUuid}/repositories/${uuid}`);

const massDeleteRepository = (groupUuid, data) =>
  http.post(`service-imports/${groupUuid}/repositories/unselect`, data);

const selectRepositories = (groupUuid, data) =>
  http.post(`service-imports/${groupUuid}/select`, data);

const getRepositoriesInfo = (groupUuid, data) =>
  http.post(`service-imports/${groupUuid}/repositories/information`, data);

export default {
  importServices,
  testImportServices,
  createImportService,
  getImportService,
  getRepositories,
  editRepository,
  massEditRepository,
  deleteRepository,
  massDeleteRepository,
  selectRepositories,
  getRepositoriesInfo,
};
