import React from 'react';
import ReactModal from 'react-modal';

import api from 'api';
import { downloadFile } from 'utils';
import { CloseButton } from 'components/UI/CloseButton';
import { BlueButton, TextButton } from 'components/UI/Button';
import { useTranslation } from 'react-i18next';

import { DownloadIcon } from 'hooks/useUploadButton/styles';

import SectionHeader from '../SectionHeader';
import * as S from './styles';

function ModalDocuments({ documents = [], isModalVisible, toggleModal }) {
  const { t } = useTranslation();
  const download = (uuid, name, extension) => {
    api.documents.download(uuid).then(data => {
      downloadFile(data.data, name, extension);
    });
  };

  const downloadAll = () => {
    documents.forEach(document => {
      api.documents.download(document.uuid).then(data => {
        downloadFile(data.data, document.name, document.fileName.split('.').pop());
      });
    });
  };

  return (
    <ReactModal isOpen={isModalVisible} onRequestClose={toggleModal} ariaHideApp={false}>
      <S.ModalWrapper>
        <SectionHeader title={t('common.documents')}>
          <CloseButton onClick={toggleModal} />
        </SectionHeader>
        <S.Body>
          {documents?.map(document => (
            <S.System key={document.uuid}>
              <TextButton
                variant="link"
                icon={<DownloadIcon />}
                iconPosition="right"
                onClick={() => {
                  download(document.uuid, document.name, document.fileName.split('.').pop());
                }}
              >
                {document.name}
              </TextButton>
            </S.System>
          ))}
          <S.BlueButtonWrapper>
            <BlueButton variant="secondary" onClick={downloadAll}>
              {t('components.modalDocuments.downloadAll')}
            </BlueButton>
          </S.BlueButtonWrapper>
        </S.Body>
      </S.ModalWrapper>
    </ReactModal>
  );
}

export default ModalDocuments;
