import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import useDropdownFilterSection from 'hooks/useDropdownFilterSection';
import { FormProvider, useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store/dictionaries/selectors';
import WarningModal from 'components/Common/WarningModal';
import { SmallButtonsWrapperLeftMargin } from 'components/UI/View';
import { BlueButton, BlueTextButton, LightGreenButton } from 'components/UI/Button';
import PageLayout from 'components/UI/PageLayout';
import InputComponent from 'components/UI/InputComponent';
import { RedText } from 'components/UI/InputComponent/styles';
import EditDictionariesTable from './EditDictionariesTable';
import MergeForm from './MergeForm';
import DictionaryForm from '../AddDictionaryPage/DictionaryForm';

import * as S from './styles';

const dictionariesModel = {
  values: [],
};

const filterData = [
  {
    optionData: [],
    fullWidth: true,
  },
];
function EditDictionariesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { run, isSuccess } = useAsync();
  const editDictionary = useAsync();
  const toDeleteDictionary = useAsync();
  const deleteDictionaryWarning = useModal();
  const mergeFormModal = useModal();
  const {
    params: { dictionariesId },
  } = useRouteMatch();
  const { filterParams, SearchInput } = useDropdownFilterSection(filterData, 'dictionaries');

  const [checkedUuids, setCheckedUuids] = useState([]);
  const [checkedValidation, setCheckedValidation] = useState([]);

  const allDictionaries = useSelector(dictionariesSelector);
  const currentDictionary = allDictionaries.data.find(item => item.id === Number(dictionariesId));

  const formMethods = useForm({
    defaultValues: dictionariesModel,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const isCantChangeForm = false;

  useEffect(() => {
    if (!currentDictionary) {
      run(api.dictionaries.getDictionary(dictionariesId));
    }
  }, [dictionariesId]);

  useEffect(() => {
    if (dictionariesId && currentDictionary) {
      formMethods.reset({
        name: currentDictionary?.name,
        oldAttributes: currentDictionary?.attributes.map(({ name, id }) => ({
          name,
          id,
        })),
        values: [],
      });
    }
  }, [currentDictionary]);

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
  }, [isSuccess, toDeleteDictionary.isSuccess]);

  const onDeleteDictionary = e => {
    e.preventDefault();
    // while there is no list of dictionaries that cannot be deleted, check for the first 3.
    if (dictionariesId <= 3) {
      return deleteDictionaryWarning.toggleModal();
    }

    toDeleteDictionary.run(api.dictionaries.deleteDictionary(dictionariesId));
  };

  const onMergeCheckedButton = e => {
    e.preventDefault();
    mergeFormModal.toggleModal();
  };

  const onDeleteCheckedButton = e => {
    e.preventDefault();
    setCheckedUuids([]);
  };

  const title = (
    <S.InputWrapper>
      {t('common.name')}
      <RedText> *</RedText>
      <InputComponent name="name" isRequired />
    </S.InputWrapper>
  );

  const headerContent = (
    <>
      <S.RedButtonWrapper onClick={onDeleteDictionary}>
        {t('pages.adminDictionaries.deleteDictionary')}
      </S.RedButtonWrapper>
      <S.HeaderWrapper>
        {currentDictionary?.attributes.length > 0 && SearchInput}
        <S.BottomButtonWrapper>
          {checkedUuids.length > 0 && (
            <>
              <LightGreenButton $marginleft onClick={onMergeCheckedButton}>
                {t('pages.adminDictionaries.combine')}
              </LightGreenButton>
              <BlueTextButton width="129px" $marginleft onClick={onDeleteCheckedButton}>
                {t('pages.adminDictionaries.deleteSelected')}
              </BlueTextButton>
            </>
          )}
        </S.BottomButtonWrapper>
      </S.HeaderWrapper>
    </>
  );

  const onSubmit = data => {
    const formatedData = {
      name: data.name,
      attributes: [...data.attributes, ...data.oldAttributes],
    };

    editDictionary.run(api.dictionaries.editDictionary(formatedData));
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form>
          <PageLayout title={title}>
            {headerContent}
            <EditDictionariesTable
              currentDictionary={currentDictionary}
              filterParams={filterParams}
              checkedUuids={checkedUuids}
              setCheckedUuids={setCheckedUuids}
              isCantChangeForm={isCantChangeForm}
              checkedValidation={checkedValidation}
              setCheckedValidation={setCheckedValidation}
              toggleModal={mergeFormModal.toggleModal}
            />

            {checkedUuids.length > 0 && (
              <S.BottomWrapper>
                <S.BottomButtonWrapper>
                  <LightGreenButton $marginleft onClick={onMergeCheckedButton}>
                    {t('pages.adminDictionaries.combine')}
                  </LightGreenButton>
                  <BlueTextButton $marginleft onClick={onDeleteCheckedButton}>
                    {t('pages.adminDictionaries.deleteSelected')}
                  </BlueTextButton>
                </S.BottomButtonWrapper>
              </S.BottomWrapper>
            )}

            <DictionaryForm
              dictionariesId={dictionariesId}
              attributes={currentDictionary?.attributes}
              setCheckedValidation={setCheckedValidation}
            />

            <MergeForm
              isModalVisible={mergeFormModal.isModalVisible}
              toggleModal={mergeFormModal.toggleModal}
              attributes={currentDictionary?.attributes}
              checkedUuids={checkedUuids}
              setCheckedUuids={setCheckedUuids}
            />

            <S.ButtonWrapper>
              <BlueButton
                $marginRight
                isDisabled={editDictionary.isLoading}
                onClick={formMethods.handleSubmit(onSubmit)}
              >
                {t('common.saveChanges')}
              </BlueButton>
            </S.ButtonWrapper>

            <WarningModal
              title={t('pages.adminDictionaries.unableToDeleteDictionary')}
              warningColor="#f0ab00"
              {...deleteDictionaryWarning}
            >
              <SmallButtonsWrapperLeftMargin>
                <BlueButton onClick={deleteDictionaryWarning.toggleModal}>
                  {t('pages.adminDictionaries.understood')}
                </BlueButton>
              </SmallButtonsWrapperLeftMargin>
            </WarningModal>
          </PageLayout>
        </form>
      </FormProvider>
    </>
  );
}

export default EditDictionariesPage;
