import React from 'react';
import GeneralInfo from './GeneralInfo';
import ResponsibleUser from './ResponsibleUser';
import Developer from './Developer';
import Tiers from './Tiers';
import Documents from './Documents';
import Contracts from './Contracts';

import i18n from '../../../../i18n';

import { stepsKeys } from './keys';

const { t } = i18n;

export const steps = [
  {
    id: 1,
    key: stepsKeys.generalInfo,
    getName: () => t('common.generalInfo'),
    component: <GeneralInfo />,
    fieldsToValidate: ['name', 'shortName', 'description', 'code'],
    fieldsNotRequired: ['description'],
    defaultData: {
      name: '',
      shortName: '',
      code: '',
      description: '',
      database: [],
      language: [],
      os: [],
    },
  },
  {
    id: 2,
    key: stepsKeys.responsibleUsers,
    getName: () => t('pages.addNewSystemPage.responsible'),
    component: <ResponsibleUser />,
    fieldsToValidate: ['responsible'],
    defaultData: {
      responsible: '',
    },
  },
  {
    id: 3,
    key: stepsKeys.contracts,
    getName: () => t('pages.addNewSystemPage.contracts'),
    component: <Contracts />,
    fieldsToValidate: ['contracts'],
    fieldsNotRequired: ['contracts'],
    defaultData: {
      contracts: [
        {
          description: '',
          endDate: undefined,
          number: '',
          startDate: undefined,
        },
      ],
    },
  },
  {
    id: 4,
    key: stepsKeys.developers,
    getName: () => t('common.developer'),
    component: <Developer />,
    fieldsToValidate: ['developers'],
    fieldsNotRequired: ['developers'],
    defaultData: {
      developers: [
        {
          address: '',
          email: '',
          name: '',
          phone: '',
          representatives: [{ name: '', phone: '', email: '' }],
          site: '',
        },
      ],
    },
  },
  {
    id: 5,
    key: stepsKeys.tiers,
    getName: () => t('common.tiers'),
    component: <Tiers />,
    fieldsToValidate: ['tiers'],
    fieldsNotRequired: ['tiers'],
    defaultData: {
      tiers: [
        {
          code: '',
          CPU: '',
          devTier: undefined,
          incidentTrackerUrl: '',
          MEMORY: '',
          serverTier: { uuid: '' },
          STORAGE: '',
          hosts: [{ value: '' }],
        },
      ],
    },
  },
  {
    id: 6,
    key: stepsKeys.documents,
    getName: () => t('common.documents'),
    component: <Documents />,
    fieldsToValidate: [],
    defaultData: {
      documents: [
        {
          comment: '',
          name: '',
          creationDate: '',
          fileName: '',
          uuid: '',
        },
      ],
    },
  },
];

export const systemModel = {
  ...steps.reduce(
    (acc, { defaultData }) => ({
      ...acc,
      ...defaultData,
    }),
    {}
  ),
};
