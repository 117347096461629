import React, { useState } from 'react';
import { PopoverPosition } from '@patternfly/react-core';

import * as S from './styles';

function PopoverButton({
  headerContent,
  bodyContent,
  footerContent,
  popoverPosition,
  withTopPadding,
  children,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(!isOpen);

  const onToggleClick = e => {
    if (e?.target.id === 'target') {
      return null;
    }

    onToggle();
  };

  return (
    <S.PopoverStyled
      aria-label="Popover"
      position={popoverPosition || PopoverPosition.right}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      isVisible={isOpen}
      shouldClose={onToggleClick}
      shouldOpen={onToggle}
      $withTopPadding={withTopPadding}
      {...props}
    >
      {children}
    </S.PopoverStyled>
  );
}

export default PopoverButton;
