import { Tooltip } from '@patternfly/react-core';
import styled from 'styled-components';

export const TooltipStyled = styled(Tooltip)`
  max-width: 400px;
`;

export const Wrapper = styled.div.attrs({
  className: 'd-sm-flex flex-column justify-content-start',
})`
  ${({ row }) =>
    row &&
    `
    flex-direction: row; 
    gap:0px 4px;
  `};
`;

export const Text = styled.div.attrs({
  className: 'd-flex flex-row font-weight-normal font-14 min-line-height',
})`
  color: ${({ greyColor }) => (greyColor ? '#6A6E73;' : `#151515;`)};
`;

export const TitleText = styled.div.attrs({
  className: 'd-flex flex-row font-14 font-weight-bold text-wrap-nowrap gap-1',
})`
  line-height: 18px;
  color: ${({ greyColor }) => (greyColor ? `#151515;` : '#6A6E73;')};

  ${({ row }) => (row ? `margin-top: 3px;` : `margin-bottom: 8px;`)};

  ${({ isForm }) => isForm && 'font-weight: 500;'}
`;
