import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { checkedUrlValue } from 'utils';

import InputComponent from 'components/UI/InputComponent';
import { getTierHostUrlValidation } from 'constants/vaidations';
import { getTierBaseUrlInfoMessage } from 'constants/tooltips';
import { protocolNames } from 'constants';
import { useTranslation } from 'react-i18next';

function TierHost({ fieldUuid, indexNumber = 0, inputsName = 'hosts', inputsNameValue }) {
  const { t } = useTranslation();
  const { watch, trigger } = useFormContext();
  const allTierHostsValue = watch(`${inputsName}`);
  const tierHostValue = watch(`${inputsNameValue}`);
  const tierBaseUrlInfoMessage = getTierBaseUrlInfoMessage();

  useEffect(() => {
    const tierHosts = allTierHostsValue
      ?.map(item => item.value)
      .filter((item, index) => index !== indexNumber);

    if (!tierHosts?.includes(tierHostValue)) {
      trigger(`${inputsName}`);
    }
  }, [allTierHostsValue, tierHostValue]);

  const tierHostUrlValidation = useMemo(
    () =>
      getTierHostUrlValidation(
        allTierHostsValue?.filter(item => checkedUrlValue(item.value)),
        indexNumber
      ),
    [allTierHostsValue, indexNumber]
  );

  return (
    <InputComponent
      key={fieldUuid}
      label={indexNumber > 0 ? '' : t('pages.addNewSystemPage.baseUrl')}
      name={inputsNameValue}
      validationRules={tierHostUrlValidation}
      tooltipMessage={`${tierBaseUrlInfoMessage}`}
      maxLength={250}
      description={t('pages.addNewSystemPage.tierHostValidation')}
      defaultValue={protocolNames.https}
    />
  );
}

export default TierHost;
