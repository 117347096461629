import styled from 'styled-components';
import EnterpriseIcoSVG from 'assets/images/clusters/pficon-enterprise.svg';

export const EnterpriseIco = styled.div`
  background: url(${EnterpriseIcoSVG}) no-repeat center;
  background-size: contain;
  border: none;
  width: 16px;
  height: 16px;
  align-self: center;
  cursor: pointer;
`;
