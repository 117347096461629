import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import useURLQueryParams from './Location/useURLQueryParams';

const useCardTabs = option => {
  const history = useHistory();
  const { setQueryParams, getParamValue } = useURLQueryParams('tab');
  const [currentTab, setCurrentTab] = useState(
    option?.numberOfTabs && getParamValue()?.index + 1 > option?.numberOfTabs
      ? 0
      : getParamValue()?.index || 0
  );

  const { state } = history.location;

  const onSelectTab = (_, tabIndex) => {
    setCurrentTab(tabIndex);
    setQueryParams({ index: tabIndex });
  };

  useEffect(() => {
    if (state?.currentTab) {
      if (!currentTab) {
        return onSelectTab(true, state.currentTab);
      }
      onSelectTab(true, state.currentTab === currentTab ? state.currentTab : currentTab);
    }
  }, []);

  useEffect(() => {
    if (state?.currentTab === 0) {
      onSelectTab(true, 0);
      history.replace({ state: {} });
    }
  }, [state?.currentTab]);

  useEffect(() => {
    if (state?.isOpenQuotes || state?.currentTab) {
      history.replace({ state: {} });
    }
  }, [currentTab]);

  return {
    currentTab,
    onSelectTab,
  };
};

export default useCardTabs;
