export const usersActions = {
  new: 'new',
};

export const adminUsersPaths = {
  new: `:action(${usersActions.new})`,
  details: `:userId`,
  userDetails: `users/:userId`,
  assign: 'assign',
  assignUsers: ':userId/assign',
};
