import styled from 'styled-components';
import { ChipGroup } from '@patternfly/react-core';
import CloseSVG from 'assets/images/basic/closeSmall.svg';

export const ChipGroupStyled = styled(ChipGroup)`
  padding-top: 5px;
  padding-bottom: 5px;

  .pf-v5-c-chip-group__label {
    min-width: min-content;
  }

  .pf-v5-c-chip-group__close {
    background: url(${CloseSVG}) no-repeat 20px 14px;
    display: flex;

    &:hover {
      background: url(${CloseSVG}) no-repeat 20px 14px;
    }

    svg {
      color: transparent;
    }
  }
`;
