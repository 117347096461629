import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useSocketAsync from 'hooks/useSocketAsync';
import { fetchServices } from 'store/services/actions';
import { servicesSelector } from 'store/services/selectors';
import paths from 'constants/paths';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import StatusComponent, { getServiceAvailableStatuses } from 'components/Common/StatusComponent';
import {} from 'components/Common/StatusComponent/statuses';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import { serviceStatusKeys } from 'constants';

import EditLastVersion from './EditLastVersion';

import { EditLastVersionWrapper } from '../../styles';

function ServicesWithVersionsTable({
  filterParams,
  setAllRenderUuids,
  checkedUuids,
  setCheckedUuids,
  customVersions,
  setCustomVersions,
  isCantChangeForm,
  releaseVersionsData,
  hasSelectedFilters,
  setIsShowFilters,
  isShowFilters,
}) {
  const { t } = useTranslation();
  const serviceAvailableStatuses = getServiceAvailableStatuses();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const deleteService = useAsync();
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
    },
    {
      key: 'code',
      label: t('common.repositoryCode'),
    },
    {
      key: 'lastVersion.tag',
      label: t('pages.systemAddReleasesPage.build'),
    },
  ];
  const {
    params: { systemId, systemType },
  } = useRouteMatch();

  const { data, total, isLoading, isError } = useSelector(servicesSelector);

  function requestFunction(requestParameters) {
    dispatch(fetchServices(requestParameters));
  }

  useSocketAsync({
    topic: 'services',
    debounceTime: 600,
    filterBySystemUuid: systemId,
    onMessage: () => tableRef.current?.requestDataWithParams({ isSilentMode: true }),
  });

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      system: systemId,
      orderBy: 'code',
      ascending: true,
      status: [
        serviceStatusKeys.NEW,
        serviceStatusKeys.ACTIVE,
        serviceStatusKeys.UPDATING_IN_PROGRESS,
        serviceStatusKeys.ERROR,
      ],
    }),
    [systemId, filterParams]
  );

  useEffect(() => {
    if (deleteService.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [deleteService.isSuccess]);

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(total));
    }
  }, [total, hasSelectedFilters]);

  const filteredDataToRender = data.filter(item => {
    const version = customVersions[item.uuid] ?? item.lastVersion;
    let isCheckIncluded = true;

    if (typeof filterParams.check === 'boolean') {
      isCheckIncluded = version?.qualityGateResult === filterParams.check;
    }

    return isCheckIncluded;
  });

  useSocketAsync({
    topic: 'serviceVersions',
    filterBySystemUuid: systemId,
    debounceTime: 600,
    filterFn: ({ object }) =>
      Object.values(customVersions).find(item => item?.uuid === object?.uuid),
    onMessage: async ({ object }) => {
      if (object.uuid) {
        const serviceVersion = await api.serviceVersion.getServiceVersionByUuid(object.uuid);

        if (serviceVersion?.data?.uuid) {
          setCustomVersions(prevState => ({
            ...prevState,
            [object.service?.uuid]: {
              ...serviceVersion?.data,
              pipelineStatus: serviceVersion?.data.pipeline?.status,
              serviceVersionStatus: serviceVersion?.data.status,
            },
          }));
        }
      }
    },
  });

  useEffect(() => {
    if (data.length) {
      setCustomVersions(prevState => ({
        ...prevState,
        ...data.reduce(
          (acc, item) => ({
            ...acc,
            [item.uuid]:
              releaseVersionsData?.find(version => version.service.uuid === item.uuid) ??
              prevState[item.uuid] ??
              item.lastVersion,
          }),
          {}
        ),
      }));
    }
  }, [data, releaseVersionsData]);

  const formattedBodyData = filteredDataToRender.map(item => {
    const version = customVersions?.[item.uuid] ?? item.lastVersion;
    const checkedVersion = customVersions?.[item.uuid];

    return {
      id: item.uuid,
      isCheckboxDisabled: !version?.tag,
      data: [
        {
          key: 'name',
          content: (
            <LinkWithPermission
              scope={scopes.service.serviceViewForm}
              to={generatePath(paths.routePaths.system + paths.systemServicesFullPaths.service, {
                systemId,
                systemType,
                serviceId: item.uuid,
              })}
              isTableLink
              target="_blank"
            >
              {item?.status ? (
                <StatusComponent
                  statusId={item.status}
                  statusWithTooltip
                  statuses={serviceAvailableStatuses}
                >
                  {item.name}
                </StatusComponent>
              ) : (
                item.name
              )}
            </LinkWithPermission>
          ),
        },
        {
          key: 'code',
          content: item.gitlabProjectUrl ? (
            <>
              <span className="hyphens-break-word">{item.code}</span>
              <ExternalLinkAltIcon className="ml-1" />
            </>
          ) : (
            <span className="hyphens-break-word">{item.code || '-'}</span>
          ),
          type: item.gitlabProjectUrl && types.externalLink,
          href: item.gitlabProjectUrl,
        },
        {
          key: 'lastVersion.tag',
          content: !!checkedVersion && (
            <EditLastVersionWrapper className="d-flex flex-row">
              <EditLastVersion
                checkedVersion={checkedVersion}
                label={t('pages.systemAddReleasesPage.build', [item.name])}
                setCustomVersions={setCustomVersions}
                serviceUuid={item.uuid}
                infoTooltip={t('pages.systemAddReleasesPage.selectAnotherBuild')}
              />
            </EditLastVersionWrapper>
          ),
        },
      ],
    };
  });

  return (
    <>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        requestParams={requestParams}
        tableName="repositoriesWithVersions"
        requestFunction={requestFunction}
        withStandartPagination={!isCantChangeForm}
        total={total}
        isLoading={isLoading}
        withCheckboxes={!isCantChangeForm}
        checkedUuids={checkedUuids}
        setCheckedUuids={setCheckedUuids}
        setAllRenderUuids={setAllRenderUuids}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
        isError={isError}
      />
    </>
  );
}

export default ServicesWithVersionsTable;
