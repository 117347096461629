import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import uuid from 'react-uuid';

const useFormDynamicFields = (parentField, onlyAddButtom) => {
  const { control } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: parentField,
  });

  const [fieldUuids, setFieldUuids] = useState([]);

  const addField = e => {
    e?.preventDefault();
    append({});
    setFieldUuids(prevState => [...prevState, uuid()]);
  };

  useEffect(() => {
    if (!fields.length && !onlyAddButtom) {
      addField();
    }
  }, []);

  useEffect(() => {
    if (fields?.length > fieldUuids.length) {
      setFieldUuids(fields?.map(() => uuid()) || [uuid()]);
    }
  }, [fields]);

  const deleteField = (fieldUuid, index) => {
    remove(index);
    setFieldUuids(prevState => prevState?.filter(key => key !== fieldUuid));
  };

  return {
    addField,
    deleteField,
    fieldUuids,
    update,
  };
};

export default useFormDynamicFields;
