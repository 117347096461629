import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import paths from 'constants/paths';
import { notActiveSystemSelector } from 'store/systems/selectors';
import { CloseButton } from 'components/UI/CloseButton';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import StatusComponent, { getTierAvailableStatuses } from 'components/Common/StatusComponent';
import { releaseOptions, tierStatusKeys } from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../../constants/tooltips';

function DeployForm({ isModalVisible, toggleModal, modalData, isDevTier, onSelectTab }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const {
    params: { releaseId },
  } = useRouteMatch();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const getTiersOptions = useAsync();
  const deployRelease = useAsync();
  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'all',
  });
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const [isHaveOptions, setIsHaveOptions] = useState(false);
  const tiersOptions = useMemo(() => {
    if (!isHaveOptions) {
      setIsHaveOptions(!!getTiersOptions.data?.data?.length);
    }

    return generateSelectOptions(getTiersOptions.data?.data, 'uuid', 'name');
  }, [getTiersOptions.data?.data, i18n.language]);
  const optionsRequestParams = useMemo(
    () => ({
      system: modalData?.release.system.uuid,
      isDevTier,
      status: [tierStatusKeys.ACTIVE],
      ascending: true,
      orderBy: 'code',
      limit: 10,
    }),
    [modalData?.release.system.uuid, isDevTier]
  );

  const searchFilter = async q => {
    try {
      const filterOptions = await getTiersOptions.run(
        api.tiers.getTiersOptions({
          ...optionsRequestParams,
          q,
        })
      );

      return generateSelectOptions(
        // Temp. solution. Remove it after fix of template visibility if label = name
        filterOptions.data.map(item => ({ ...item, text: `${item.name} ` })),
        'uuid',
        'text',
        null,
        {
          getLabelTemplate: item => (
            <div className="d-flex justify-content-start align-items-start">
              <StatusComponent
                statusId={item.status}
                statuses={tierAvailableStatuses}
                objectType="TIER"
                statusWithTooltip
                objectUuid={item?.uuid}
                alignItems="start"
              >
                <div>{item.name}</div>
                {item.currentRelease && (
                  <div className="font-12 text-gray-medium">
                    {t('pages.systemReleasesPage.currentVersion', [item.currentRelease?.name])}
                  </div>
                )}
              </StatusComponent>
            </div>
          ),
        }
      );
    } catch (e) {
      return [];
    }
  };

  function requestOptionsFunction(params) {
    getTiersOptions.run(
      api.tiers.getTiersOptions({
        ...optionsRequestParams,
        ...params,
      })
    );
  }

  useEffect(() => {
    if (isModalVisible && modalData?.release.system.uuid) {
      requestOptionsFunction();
    }
    if (!isModalVisible) {
      formMethods.reset();
      getTiersOptions.reset([]);
    }
  }, [isModalVisible, isDevTier, modalData]);

  const onClickGeneratePath = tab => {
    if (releaseId && onSelectTab) {
      onSelectTab(true, tab);
      return;
    }

    return {
      pathname: generatePath(paths.routePaths.system + paths.systemReleasesFullPaths.change, {
        releaseId: modalData?.release.uuid,
        systemId: modalData?.release.system.uuid,
        systemType: modalData?.release.system.type,
        action: releaseOptions.view,
      }),
      state: {
        currentTab: tab,
      },
    };
  };

  useEffect(() => {
    if (deployRelease.isSuccess) {
      toggleModal();
      history.push(onClickGeneratePath(2));
    }
  }, [deployRelease.isSuccess]);

  const onDeployReleaseClick = ({ tier }) => {
    deployRelease.run(
      api.releases.deployRelease(modalData?.release.uuid, {
        tier,
      })
    );
  };

  const onClose = () => {
    formMethods.reset();
    toggleModal();
  };

  const isDeployDisabled = notActiveSystemMessage || (isDevTier && !isHaveOptions);
  const isDisabled = !formMethods.formState.isValid || notActiveSystemMessage;

  return (
    <>
      <S.ReactModalStyled isOpen={isModalVisible} onRequestClose={toggleModal}>
        <div className="d-flex flex-row justify-content-between p-4">
          <span className="font-24 font-weight-medium">{`${t(
            'pages.systemAddReleasesPage.deployTo'
          )}${
            isDevTier
              ? ` ${t('pages.systemAddReleasesPage.devTier')}`
              : ` ${t('pages.systemAddReleasesPage.tier')}`
          }`}</span>
          <CloseButton onClick={onClose} />
        </div>

        <FormProvider {...formMethods}>
          <S.DeployBlockWrapper>
            <InputComponent
              label={t('common.tier')}
              name="tier.uuid"
              component={inputComponents.newSelect}
              newSelectVariant="typeahead"
              searchFilter={searchFilter}
              placeholder={t('common.searchByName')}
              options={tiersOptions}
              withInfiniteScroll={{
                total: getTiersOptions.data?.meta?.total,
                offset: 10,
                loadMore: requestOptionsFunction,
              }}
              isLoading={getTiersOptions.isLoading}
              isRequired
              disabled={isDeployDisabled}
              statuses={tierAvailableStatuses}
            />
          </S.DeployBlockWrapper>

          <ActionListGroup className="p-4">
            <Tooltip
              className={isDisabled ? undefined : 'd-none'}
              content={notActiveSystemMessage || (isDisabled && tooltipFormDisableMessage())}
              exitDelay={150}
              animationDuration={150}
            >
              <ActionListItem>
                <Button
                  onClick={formMethods.handleSubmit(onDeployReleaseClick)}
                  isLoading={deployRelease.isLoading}
                  isDisabled={deployRelease.isLoading || isDisabled}
                >
                  {t('common.deploy')}
                </Button>
              </ActionListItem>
            </Tooltip>

            <ActionListItem>
              <Button variant="link" id="cancel-button" className="ml-3" onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </FormProvider>
      </S.ReactModalStyled>
    </>
  );
}

export default DeployForm;
