import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import WarningModal from 'components/Common/WarningModal';

function DeleteServiceForm({
  requestFunction,
  isModalVisible,
  toggleModal,
  modalData,
  setModalData,
}) {
  const { t } = useTranslation();
  const deleteService = useAsync();

  useEffect(() => {
    if (deleteService.isSuccess) {
      if (requestFunction) {
        requestFunction(modalData);
      }

      setModalData('');
      toggleModal();
    }
  }, [deleteService.isSuccess]);

  return (
    <WarningModal
      title={t('pages.systemServicesPage.deletingService')}
      text={t('pages.systemServicesPage.deleteService')}
      label={t('pages.systemServicesPage.serviceName')}
      isLoading={deleteService.isLoading}
      onClick={() => deleteService.run(api.services.deleteService(modalData.uuid))}
      name={modalData?.name}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    />
  );
}

export default DeleteServiceForm;
