import styled from 'styled-components';
import { Dropdown, MenuToggle } from '@patternfly/react-core';

export const StyledMenuToggle = styled(MenuToggle)`
  width: ${({ $mini, $width, $withSearch }) =>
    $mini || $width ? $width || '130px' : `${$withSearch || '200px'}`} !important;

  .pf-v5-c-menu-toggle {
    padding: 0 !important;
  }

  .pf-v5-c-menu-toggle__controls {
    padding-left: 0;
  }

  .pf-v5-c-menu-toggle__toggle-icon {
    margin-right: 0;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: ${({ $mini, $width }) => ($mini || $width ? $width || '130px' : `${'200px'}`)} !important;
`;
