import React from 'react';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';

import StatusComponent from 'components/Common/StatusComponent';
import { userStatusKeys, getUserAdminNames } from 'constants';
import { useTranslation } from 'react-i18next';
import { getUserStatus } from '../../../../../../components/Common/StatusComponent/statuses';

function UserStatusLabel({ user }) {
  const { t } = useTranslation();
  const userStatus = getUserStatus();
  const userAdminNames = getUserAdminNames();
  const statusText = () => {
    if (user?.status === userStatusKeys.UPDATED) {
      return user.admin ? userAdminNames.admin : userAdminNames.user;
    }
  };

  return (
    <div className="d-flex nowrap">
      <StatusComponent
        statusId={user?.status === userStatusKeys.UPDATED ? user?.enabled : user?.status}
        statusText={statusText()}
        objectUuid={user?.status !== userStatusKeys.UPDATED && user.uuid}
        objectType="USER"
        tooltipText={user?.status === userStatusKeys.UPDATED && userStatus[user?.enabled]?.text}
        statuses={userStatus}
      />
      {!!user?.totalSummary.userRoleTotal?.error && (
        <Tooltip
          content={t('pages.adminUsersPage.activationRoleErrorTooltip')}
          exitDelay={150}
          animationDuration={150}
        >
          <ExclamationCircleIcon className="font-16 text-red ml-2" />
        </Tooltip>
      )}
    </div>
  );
}

export default UserStatusLabel;
