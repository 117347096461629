export const addSystemStorageKey = 'addNewInternalSystemForm';

export const editSystemStorageKey = 'editInternalSystemForm';

export const systemActiveStepStorageKey = 'internalSystemFormActiveStep';

export const systemEditActiveStepStorageKey = 'internalEditSystemFormActiveStep';

export const editSystemUnfilledStages = 'editInternalSystemUnfilledStages';

export const addSystemUnfilledStages = 'addInternalSystemUnfilledStages';

export const stepsKeys = {
  generalInfo: 'generalInfo',
  responsibleUsers: 'responsibleUsers',
  developers: 'developers',
  contracts: 'contracts',
  tiers: 'tiers',
  documents: 'documents',
};
