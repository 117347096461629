import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { notActiveSystemSelector } from 'store/systems/selectors';
import WarningModal from 'components/Common/WarningModal';
import { getProgressSystemMessage } from '../../../../../constants/tooltips';

function ErrorsWarningModal({ isModalVisible, toggleModal, modalData, isLoading, approvedClick }) {
  const { t, i18n } = useTranslation();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  return (
    <WarningModal
      title={
        modalData
          ? t('pages.systemAddReleasesPage.sendingForApproval')
          : t('pages.systemAddReleasesPage.create')
      }
      buttenText={modalData ? t('common.send') : t('common.add')}
      warningColor="#f0ab00"
      warningTooltipMessage={notActiveSystemMessage}
      isLoading={isLoading}
      onClick={approvedClick}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    >
      <div style={{ width: '468px' }}>
        {t('pages.systemAddReleasesPage.buildNotFinishedWarning', [
          modalData
            ? t('pages.systemAddReleasesPage.sendForApprovalRelease')
            : t('pages.systemAddReleasesPage.save'),
        ])}
      </div>
    </WarningModal>
  );
}

export default ErrorsWarningModal;
