import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import TooltipComponent, { placementKeys } from 'components/TooltipComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import { ReactComponent as DeleteSvg } from 'assets/images/newsystem/delete.svg';
import { TextButton } from 'components/UI/Button';

import IntegarationFrequency from './IntegarationFrequency';

import {
  BondTiersFormWrapper,
  InputComponentWrapper,
  SubTitleInEditIntegration,
  BlockServicesWrapper,
  BlockServicesImputWrapper,
  WarningMessage,
  BlockWrapper,
  IntegarationFrequencyTitle,
  TierLinkWrapper,
  TierToUri,
  TierToUriTitle,
  StyledAlert,
} from '../../styles';

const dynamicParentField = 'integrationTiers';

const BondTiersForm = ({
  fieldUuids,
  fieldUuid,
  index,
  deleteField,
  isSystemFrom,
  isSystemTo,
  isSystemFromTiers,
  isSystemToTiers,
  formMethods,
  isCantChangeForm,
  isNotValidate,
}) => {
  const { t } = useTranslation();
  const disableMessage = t('pages.systemIntegrationRelationsPage.asyncType');

  const warningMessage = t('pages.systemIntegrationRelationsPage.incorrectUrl');

  const options = [
    {
      value: true,
      label: t('pages.systemIntegrationRelationsPage.byScheduling'),
    },
    {
      value: false,
      label: t('pages.systemIntegrationRelationsPage.byEvent'),
    },
  ];
  const {
    params: { integrationId },
  } = useRouteMatch();
  const { setValue, watch, trigger } = useFormContext();

  const inputsName = `${dynamicParentField}[${index}]`;
  const tierFromUuidValue = watch(`${inputsName}.tierFrom.uuid`);
  const serviceEndpointTierToUuidValue = watch(`${inputsName}.serviceEndpointTierTo.tier.uuid`);
  const serviceEndpointTierToUriValue = watch(`${inputsName}.serviceEndpointTierTo.uri`);
  const serviceEndpointUuidValue = watch(`${inputsName}.serviceEndpointTierTo.uuid`);

  const serviceEndpointToValue = watch(`serviceEndpointTo`);

  const allIntegrationTiers = watch(`integrationTiers`);

  const currentIntegrationTier = formMethods?.watch(inputsName) ?? watch(inputsName);

  useEffect(() => {
    if (!currentIntegrationTier?.dataExchange) {
      setValue(`${inputsName}.tierFrequency`, { value: '', unit: '' });
    }
  }, [currentIntegrationTier?.dataExchange]);

  const serviceEndpointTierTo = serviceEndpointToValue?.serviceEndpointTiers?.find(
    item => item.tier.uuid === serviceEndpointTierToUuidValue
  );

  const serviceEndpointTierToUri = serviceEndpointTierTo?.uri;

  useEffect(() => {
    if (serviceEndpointTierToUuidValue && serviceEndpointTierTo) {
      setValue(
        `${inputsName}.serviceEndpointTierTo.uuid`,
        serviceEndpointTierTo?.uuid ?? undefined
      );
      setValue(`${inputsName}.serviceEndpointTierTo.uri`, serviceEndpointTierTo?.uri ?? '');
    }
  }, [serviceEndpointTierToUuidValue]);

  useEffect(() => {
    const serviceEndpointTierToIndex = serviceEndpointToValue?.serviceEndpointTiers?.findIndex(
      item => item.tier.uuid === serviceEndpointTierToUuidValue
    );

    if (
      serviceEndpointTierToIndex > -1 &&
      serviceEndpointTierTo?.uri !== serviceEndpointTierToUriValue
    ) {
      setValue(
        `serviceEndpointTo.serviceEndpointTiers[${serviceEndpointTierToIndex}].uri`,
        serviceEndpointTierToUriValue
      );
    }
  }, [serviceEndpointTierToUriValue]);

  useEffect(() => {
    if (
      serviceEndpointTierToUri &&
      serviceEndpointTierTo?.tier &&
      currentIntegrationTier.serviceEndpointTierTo.tier.uuid ===
        serviceEndpointTierTo?.tier?.uuid &&
      serviceEndpointTierToUriValue !== serviceEndpointTierToUri
    ) {
      trigger();
      setValue(`${inputsName}.serviceEndpointTierTo.uri`, serviceEndpointTierToUri);
    }
  }, [serviceEndpointTierToUri]);

  const checkSystemFromTiers = useMemo(() => {
    const allOptions = generateSelectOptions(isSystemFromTiers.data?.data, 'uuid', 'name');
    const checkedTierFrom = allIntegrationTiers.map(
      item =>
        item.serviceEndpointTierTo?.tier?.uuid === serviceEndpointTierToUuidValue &&
        item.tierFrom?.uuid
    );

    if (serviceEndpointTierToUuidValue) {
      return allOptions.filter(
        item => item.value === tierFromUuidValue || !checkedTierFrom.includes(item.value)
      );
    }

    return allOptions;
  }, [isSystemFromTiers.data?.data, tierFromUuidValue, serviceEndpointTierToUuidValue]);

  const checkSystemToTiers = useMemo(() => {
    const allOptions = generateSelectOptions(isSystemToTiers?.data?.data, 'uuid', 'name');

    const checkedTierTo = allIntegrationTiers.map(
      item => item.tierFrom?.uuid === tierFromUuidValue && item.serviceEndpointTierTo?.tier?.uuid
    );

    if (tierFromUuidValue) {
      return allOptions.filter(
        item => item.value === serviceEndpointTierToUuidValue || !checkedTierTo.includes(item.value)
      );
    }

    return allOptions;
  }, [isSystemToTiers?.data, tierFromUuidValue, serviceEndpointTierToUuidValue]);

  const isDisabled = useMemo(
    () =>
      integrationId ? !isCantChangeForm && serviceEndpointTierToUri : serviceEndpointTierToUri,
    [integrationId, isCantChangeForm, serviceEndpointTierToUri]
  );

  const onDeleteClick = e => {
    e.preventDefault();
    deleteField(fieldUuid, index);
  };

  return (
    <BondTiersFormWrapper key={fieldUuid}>
      {fieldUuids.length > 1 && (
        <SubTitleInEditIntegration>
          <TextButton
            variant="link"
            isDanger
            icon={<DeleteSvg />}
            iconPosition="left"
            onClick={onDeleteClick}
          >
            {t('common.delete')}
          </TextButton>
        </SubTitleInEditIntegration>
      )}

      <BlockServicesWrapper>
        <BlockServicesImputWrapper>
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.sendingDataTier')}
            name={`${inputsName}.tierFrom.uuid`}
            component={inputComponents.newSelect}
            options={checkSystemFromTiers}
            placeholder={t('common.notSelected')}
            noOptionsMessage={t('pages.systemIntegrationRelationsPage.tiersAreNotCreated')}
            isRequired
            isLoading={isSystemFromTiers.isLoading}
            maxLength={235}
            withoutErrorsMessage={isNotValidate}
          />
          <TierLinkWrapper>{isSystemFrom?.name}</TierLinkWrapper>
        </BlockServicesImputWrapper>
        <div>
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.receivingDataTier')}
            name={`${inputsName}.serviceEndpointTierTo.tier.uuid`}
            component={inputComponents.newSelect}
            options={checkSystemToTiers}
            placeholder={t('common.notSelected')}
            noOptionsMessage={t('pages.systemIntegrationRelationsPage.tiersAreNotCreated')}
            isRequired
            isLoading={isSystemToTiers.isLoading}
            maxLength={235}
            withoutErrorsMessage={isNotValidate}
          />
          <TierLinkWrapper>{isSystemTo?.name}</TierLinkWrapper>
        </div>

        <InputComponentWrapper>
          {isDisabled ? (
            <>
              <TierToUriTitle>URI</TierToUriTitle>
              <TooltipComponent
                key={index}
                message={isDisabled && disableMessage}
                placement={placementKeys.bottom}
              >
                <TierToUri>{`${serviceEndpointTierToUriValue}`}</TierToUri>
              </TooltipComponent>
            </>
          ) : (
            <InputComponent
              label="URI"
              name={`${inputsName}.serviceEndpointTierTo.uri`}
              isRequired
              withoutErrorsMessage={isNotValidate}
            />
          )}
        </InputComponentWrapper>
      </BlockServicesWrapper>

      {!!serviceEndpointTierToUriValue && serviceEndpointUuidValue === undefined && (
        <WarningMessage>
          <StyledAlert variant="warning" isInline title={warningMessage} />
        </WarningMessage>
      )}

      <BlockWrapper>
        <InputComponent
          label={t('pages.systemIntegrationRelationsPage.dataExchange')}
          name={`${inputsName}.dataExchange`}
          component={inputComponents.radioInput}
          defaultValue={options[0].value}
          options={options}
          rowDirection
        />
      </BlockWrapper>

      {currentIntegrationTier?.dataExchange && (
        <>
          <IntegarationFrequencyTitle>
            {t('pages.systemIntegrationRelationsPage.scheduler')}
          </IntegarationFrequencyTitle>
          <IntegarationFrequency parentName={`${inputsName}.tierFrequency`} withHint />
        </>
      )}
    </BondTiersFormWrapper>
  );
};

export default BondTiersForm;
