import React from 'react';

import StatusComponent, {
  getServiceVersionsPipelineStatuses,
  getServiceVersionsStatuses,
} from 'components/Common/StatusComponent';
import { serviceVersionsStatusKeys } from 'constants';

function ServiceVersionsStatus({ children, version, withoutStatusText, statusWithTooltip }) {
  const serviceVersionsPipelineStatuses = getServiceVersionsPipelineStatuses();
  const serviceVersionsStatuses = getServiceVersionsStatuses();

  if (version.pipeline?.status && version?.status === serviceVersionsStatusKeys.ACTIVE) {
    return (
      <StatusComponent
        statusWithTooltip={statusWithTooltip}
        statusId={version.pipeline.status}
        statuses={serviceVersionsPipelineStatuses}
        withoutStatusText={withoutStatusText}
      >
        {children || version?.tag}
      </StatusComponent>
    );
  }

  return (
    <StatusComponent
      statusWithTooltip={statusWithTooltip}
      statusId={version?.status}
      statuses={serviceVersionsStatuses}
      withoutStatusText={withoutStatusText}
      objectUuid={
        version?.status !== serviceVersionsStatusKeys.ACTIVE &&
        version?.status !== serviceVersionsStatusKeys.PROBLEM_FOUND &&
        version?.uuid
      }
      objectType="SERVICE_VERSION"
    >
      {children || version?.tag}
    </StatusComponent>
  );
}

export default ServiceVersionsStatus;
