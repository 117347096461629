import styled from 'styled-components';

import { Tr, Td } from '@patternfly/react-table';

export const TableCell = styled(Td)`
  font-size: 14px;
  align-items: center;
  display: flex;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 1px;
  bottom: 1px;
  display: none;
`;

export const LinkWrapper = styled.div`
  min-width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;

export const TrStyled = styled(Tr)`
  .pf-v5-c-table__toggle {
    button {
      padding-left: 0;
    }

    .pf-v5-c-table__toggle-icon {
      svg {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }

  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      background: #f4fafe;
      cursor: pointer;
    }
  `};

  ${({ $rowColor }) =>
    $rowColor &&
    `    
      background-color: ${$rowColor};
  `};
`;

export const ExpandedTd = styled(Td)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .pf-v5-c-table__toggle-icon svg {
    height: 20px;
    width: 13px;
  }
`;

export const TdStyled = styled(Td)`
  vertical-align: middle !important;
  height: 50px;
  min-height: 50px;
  hyphens: auto;
  word-break: break-word;

  .pf-v5-m-plain {
    display: flex;
  }

  ${({ $isFilterColumn }) =>
    $isFilterColumn &&
    `
    &:hover {
      background: #f2f6f9;
      cursor: pointer;

      & > div {
        display: flex;
      }
    }
  `};

  ${({ $withoutTableHead }) =>
    $withoutTableHead &&
    ` 
    &::after {
      border-left: 1px solid transparent !important;
    }}
  `}
`;

export const TdExpandedStyled = styled(Td).attrs({
  className: 'p-0 border border-color-transparent',
})`
  .pf-v5-c-table__expandable-row-content {
    padding-top: 0;
  }

  ${({ $withoutTableHead }) =>
    $withoutTableHead &&
    ` 
    &::after {
      border-left: 1px solid transparent !important;
    }}
  `}
`;
