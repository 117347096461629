import React, { useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '@patternfly/react-core';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import PageLayout from 'components/UI/PageLayout';
import paths from 'constants/paths';
import { usersActions } from 'constants/paths/adminUsersPaths';
import { getServerTierStatusNames } from 'constants/index';

import ClusterTable from './ClusterTable';

function ServerTiersPage() {
  const { t } = useTranslation();
  const serverTierStatusNames = getServerTierStatusNames();
  const { checkedPermissions } = useUserPermissions();
  const filterData = [
    {
      id: 'q',
      name: t('common.name'),
    },
    {
      id: 'location',
      name: t('pages.adminServerTiers.location'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(serverTierStatusNames),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'clusters'
  );

  const [isShowFilters, setIsShowFilters] = useState(false);
  const addServerTierButton = checkedPermissions(scopes.platform.clusterCreate, true) && (
    <Button
      component={props => (
        <Link
          {...props}
          to={generatePath(paths.serverTiersPaths.new, {
            action: usersActions.new,
          })}
        />
      )}
    >
      {t('common.add')}
    </Button>
  );

  const leftSideContent = isShowFilters && <div className="mr-4">{addServerTierButton}</div>;

  return (
    <PageLayout
      title={t('common.serverTiers')}
      withoutSidePadding
      leftSideContent={leftSideContent}
      fullHeight
    >
      <div className="d-sm-flex ml-4">{isShowFilters && FilterComponent}</div>
      <ClusterTable
        filterParams={filterParams}
        hasSelectedFilters={hasSelectedFilters}
        addButton={addServerTierButton}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
      />
    </PageLayout>
  );
}

export default ServerTiersPage;
