import { releaseTypeKeys } from 'constants';

export const tableFiltersToQueryParams = filters => {
  const { type, ...params } = filters;

  return {
    ...params,
    hotfix: !type?.length || type.length === 2 ? undefined : type[0] === releaseTypeKeys.HOTFIX,
  };
};
