import React, { useEffect } from 'react';
import {
  Switch,
  Redirect,
  generatePath,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchSystemById } from 'store/systems/actions';
import { currentSystemSelector } from 'store/systems/selectors';
import { clearTiersState } from 'store/tiers/reducers';
import { clearStateServiceVersions } from 'store/serviceVersions/reducers';
import { clearServicesState } from 'store/services/reducers';
import { clearDeployedReleasesState } from 'store/deployedReleases/reducers';
import { clearIssuesState } from 'store/issues/reducers';
import { clearMilestonesState } from 'store/milestones/reducers';
import { clearReleasesState } from 'store/releases/reducers';
import { clearUsersState } from 'store/users/reducers';
import { clearDocumentsState } from 'store/documents/reducers';
import { setCurrentSystemError } from 'store/systems/reducers';
import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { systemTypesKeys } from 'constants';
import AddSystemPage from 'pages/AddNewSystemRouter/AddSystemPage';

import SystemReleasesRouter from './SystemReleasesRouter';
import SystemServicesRouter from './SystemServicesRouter';
import SystemTiersRouter from './SystemTiersRouter';
import SystemIntegrationsRouter from './SystemIntegrationsRouter';
import SystemIssuesRouter from './SystemIssuesRouter';
import SystemDocumentsRouter from './SystemDocumentsRouter';
import NotificationsPage from './NotificationsPage';
import UsersPage from './UsersPage';
import UserCreatePage from '../AdminRouter/UsersRouter/UserCreatePage';
import CurrentUserPage from '../AdminRouter/UsersRouter/CurrentUserPage';
import SystemDetailsPage from './SystemDetailsPage';
import SystemDeploymentsPage from './SystemDeploymentsPage';
import SystemMilestonesRouter from './SystemMilestonesRouter';
import SystemServiceVersionsRouter from './SystemServiceVersionsRouter';

const SystemRouter = ({ match: { path, params } }) => {
  const {
    params: { systemId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { checkedPermissions } = useUserPermissions();
  const currentSystem = useSelector(currentSystemSelector);
  const isExternal = params.systemType === systemTypesKeys.EXTERNAL;

  useEffect(() => {
    if (!isExternal && params.systemId && !currentSystem?.uuid) {
      dispatch(
        fetchSystemById({
          id: params.systemId,
          callback: e => dispatch(setCurrentSystemError(e)),
        })
      );
    }
  }, [params.systemId, isExternal]);

  useEffect(() => {
    if (isExternal && params.systemId) {
      if (checkedPermissions(scopes.platform.externalSystemViewForm, true)) {
        dispatch(
          fetchSystemById({
            id: params.systemId,
            callback: e => dispatch(setCurrentSystemError(e)),
          })
        );
      }
    }
  }, [params.systemId, isExternal]);

  useEffect(() => {
    if (
      !isExternal &&
      params.systemId &&
      currentSystem?.uuid &&
      currentSystem?.uuid !== params.systemId
    ) {
      dispatch(
        fetchSystemById({
          id: params.systemId,
        })
      );
    }
  }, [dispatch, params, currentSystem?.uuid, isExternal]);

  useEffect(() => {
    if (currentSystem?.type && currentSystem.type !== params.systemType) {
      history.replace(location.pathname.replace(params.systemType, currentSystem.type));
    }
  }, [path, currentSystem]);

  useEffect(() => {
    if (!currentSystem?.uuid) {
      dispatch(clearTiersState());
      dispatch(clearStateServiceVersions());
      dispatch(clearServicesState());
      dispatch(clearDeployedReleasesState());
      dispatch(clearIssuesState());
      dispatch(clearMilestonesState());
      dispatch(clearReleasesState());
      dispatch(clearUsersState());
      dispatch(clearDocumentsState());
    }
  }, [systemId]);

  return (
    <Switch>
      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.editSystem}`}
        component={AddSystemPage}
        scope={scopes.system.systemUpdate}
      />

      <Route
        exact
        path={`${path}${paths.systemPaths.notifications}`}
        component={NotificationsPage}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.services}`}
        component={SystemServicesRouter}
        scope={scopes.service.serviceViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.releases}`}
        component={SystemReleasesRouter}
        scope={scopes.release.releaseViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.tiers}`}
        component={SystemTiersRouter}
        scope={isExternal ? scopes.platform.externalSystemViewForm : scopes.tier.tierViewSection}
        isExternal={isExternal}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.integrations}`}
        component={SystemIntegrationsRouter}
        scope={scopes.integration.integrationViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.milestones}`}
        component={SystemMilestonesRouter}
        scope={scopes.milestone.milestoneViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.issues}`}
        component={SystemIssuesRouter}
        scope={scopes.issue.issueViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.documents}`}
        component={SystemDocumentsRouter}
        scope={scopes.document.documentViewSection}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.users}`}
        component={UsersPage}
        scope={scopes.user.userViewSection}
        isPlatformScope={!params.systemId}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.newUser}`}
        component={UserCreatePage}
        scope={scopes.user.userAddToSystem}
        isPlatformScope={!params.systemId}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.assignUsers}`}
        component={UserCreatePage}
        scope={scopes.user.userAddToSystem}
        isPlatformScope={!params.systemId}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.userDetails}`}
        component={CurrentUserPage}
        scope={scopes.platform.userViewForm}
        isPlatformScope
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemPaths.deployments}`}
        component={SystemDeploymentsPage}
        scope={scopes.deployment.deploymentViewSection}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemPaths.serviceVersions}`}
        component={SystemServiceVersionsRouter}
        scope={scopes.serviceVersion.versionViewSection}
      />

      <ProtectedSystemRoute
        exact
        path={path}
        component={SystemDetailsPage}
        scope={isExternal ? scopes.platform.externalSystemViewForm : scopes.tier.tierViewSection}
        isExternal={isExternal}
      />

      <Redirect path={path} to={generatePath(path, params)} />
    </Switch>
  );
};

export default SystemRouter;
