import { createSelector } from 'reselect';

const milestonesState = state => state.milestones;

export const milestonesSelector = createSelector([milestonesState], milestones => milestones);

export const milestonesForSystemEditSelector = createSelector(
  [milestonesState],
  ({ milestonesForSystemEdit }) => milestonesForSystemEdit
);
