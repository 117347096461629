import React from 'react';

import PageLayout from 'components/UI/PageLayout';

import ErrorPageMessage from './ErrorPageMessage';

function ErrorPage() {
  return (
    <PageLayout fullHeightPage>
      <div className="m-auto p-4">
        <ErrorPageMessage />
      </div>
    </PageLayout>
  );
}

export default ErrorPage;
