import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedFeatureRoute from 'routes/ProtectedFeatureRoute';
import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { featureFlags } from 'constants/features';
import { scopes } from 'constants/scopes';

import MilestonesPage from './MilestonesPage';
import EditMilestonePage from './EditMilestonePage';
import CurrentMilestonePage from './CurrentMilestonePage';
import EditIssuesPage from '../SystemIssuesRouter/EditIssuesPage';

const SystemMilestonesRouter = ({ match: { path } }) => (
  <ProtectedFeatureRoute isFeatureEnabled={featureFlags.isIssuesFeatureEnabled}>
    <Switch>
      <ProtectedSystemRoute
        path={`${path}${paths.systemMilestonesPaths.milestoneIssuesNew}`}
        component={EditIssuesPage}
        scope={scopes.issue.issueCreate}
      />

      <ProtectedSystemRoute
        path={`${path}${paths.systemMilestonesPaths.milestoneIdIssuesEdit}`}
        component={EditIssuesPage}
        scope={scopes.issue.issueUpdate}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemMilestonesPaths.new}`}
        component={EditMilestonePage}
        scope={scopes.milestone.milestoneCreate}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemMilestonesPaths.edit}`}
        component={EditMilestonePage}
        scope={scopes.milestone.milestoneUpdate}
      />

      <ProtectedSystemRoute
        exact
        path={`${path}${paths.systemMilestonesPaths.milestone}`}
        component={CurrentMilestonePage}
        scope={scopes.milestone.milestoneViewForm}
      />

      <Route path={path} component={MilestonesPage} />
      <Redirect to={path} />
    </Switch>
  </ProtectedFeatureRoute>
);

export default SystemMilestonesRouter;
