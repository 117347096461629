import styled from 'styled-components';

export const Content = styled.div.attrs({
  className: 'pl-4 mb-5',
})`
  width: 724px;
`;

export const Line = styled.div.attrs({
  className: 'border-top',
})`
  position: absolute;
  margin-left: -24px;
  width: ${({ $width }) => ($width ? `${$width}` : '100vw')};
`;
