import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { serviceStatusKeys } from 'constants';

export const fetchServices = createAsyncThunk('services/fetchServices', async params => {
  const { data } = await api.services.getAllServices(params);

  return data;
});

export const fetchImportService = createAsyncThunk('services/fetchImportService', async params => {
  const { data } = await api.repositories.getImportService(params);
  return data;
});

export const fetchRepositories = createAsyncThunk('services/fetchRepositories', async params => {
  const { data } = await api.repositories.getRepositories(params);
  return data;
});

export const fetchSelectedRepositories = createAsyncThunk(
  'services/fetchSelectedRepositories',
  async params => {
    const { data } = await api.repositories.getRepositories(params);
    return data;
  }
);

export const fetchServiceByActiveStatus = createAsyncThunk(
  'services/fetchServiceByActiveStatus',
  async params => {
    const response = await api.services.getAllServices({
      ...params,
      status: [serviceStatusKeys.ACTIVE],
      limit: 1,
    });

    return response.data?.data && response.data.data[0];
  }
);
