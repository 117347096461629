import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {
    data: [],
    isLoading: true,
    total: 0,
    isError: null,
    tierById: null,
    tierByIdIsLoading: true,
    tierByIdError: null,
    serverTiers: { data: [], isLoading: true, total: 0, isError: null },
  },
  reducers: {
    clearTiersState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.isError = null;
      state.serverTiers.data = [];
      state.serverTiers.total = 0;
      state.serverTiers.isLoading = true;
      state.serverTiers.isError = null;
    },
    addNewTier: (state, { payload }) => {
      state.data = [payload, ...state.data];
      state.total += 1;
    },
    removeTier: (state, { payload }) => {
      if (state.data) {
        state.data = state.data.filter(tier => tier.uuid !== payload);
        state.total -= 1;
      }
    },
    changeCurrentTierInList: (state, { payload }) => {
      state.data = state.data.map(tier => {
        if (tier.uuid === payload?.uuid) {
          return { ...tier, status: payload?.status || tier.status };
        }

        return tier;
      });
    },
    clearCurrentTier: state => {
      state.tierById = null;
      state.tierByIdIsLoading = true;
      state.tierByIdError = null;
    },
    changeCurrentTier: (state, { payload }) => {
      state.tierById = payload;
    },
  },
  extraReducers: {
    [actions.fetchTiers.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchTiers.fulfilled]: (state, { payload: { data, meta } }) => {
      state.data = data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchTiers.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.data = [];
        state.total = 0;
        state.isError = true;
      }
    },
    [actions.fetchTierById.pending]: state => {
      state.tierById = null;
      state.tierByIdIsLoading = true;
      state.tierByIdError = null;
    },
    [actions.fetchTierById.fulfilled]: (state, { payload }) => {
      state.tierById = payload;
      state.tierByIdIsLoading = false;
      state.tierByIdError = null;
    },
    [actions.fetchTierById.rejected]: (state, { error }) => {
      state.tierByIdIsLoading = false;
      state.tierByIdError = error.message;
    },
    [actions.fetchServerTiersStands.pending]: state => {
      state.serverTiers.isLoading = true;
      state.serverTiers.isError = null;
    },
    [actions.fetchServerTiersStands.fulfilled]: (state, { payload: { data, meta } }) => {
      state.serverTiers.data = data;
      state.serverTiers.total = meta.total;
      state.serverTiers.isLoading = false;
      state.serverTiers.isError = null;
    },
    [actions.fetchServerTiersStands.rejected]: (state, { error }) => {
      state.serverTiers.isLoading = false;

      if (checkedServerError(error.message)) {
        state.serverTiers.data = [];
        state.serverTiers.total = 0;
        state.serverTiers.isError = true;
      }
    },
  },
});

export const {
  addNewTier,
  removeTier,
  clearTiersState,
  changeCurrentTier,
  changeCurrentTierInList,
  clearCurrentTier,
} = tiersSlice.actions;

export default tiersSlice.reducer;
