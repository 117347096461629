import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useModal from 'hooks/useModal';
import { serviceVersionsVulnerabilitiesSelector } from 'store/serviceVersionsBuildResults/selectors';
import { clearStateVulnerabilities } from 'store/serviceVersionsBuildResults/reducers';
import NewDataTable from 'components/Common/NewDataTable';
import StatusComponent, { getVulnerabilitiesStatuses } from 'components/Common/StatusComponent';
import ReadMoreBlock from 'components/Common/ReadMoreBlock';
import TextBlock from 'components/UI/TextBlock';
import ModalInfo from 'components/Common/ModalInfo';
import { types } from 'components/Common/DataTable';
import { useTranslation } from 'react-i18next';

function ServiceVersionsVulnerabilitysTable({
  requestParams,
  requestFunction,
  hasSelectedFilters,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vulnerabilitiesStatuses = getVulnerabilitiesStatuses();
  const { isModalVisible, toggleModal, setModalData, modalData } = useModal();
  const { data, isLoading, total, isError } = useSelector(serviceVersionsVulnerabilitiesSelector);
  const headerData = [
    {
      key: 'pkgName',
      label: t('pages.systemServiceVersionPage.package'),
      withSort: true,
    },
    {
      key: 'vulnerabilityId',
      label: t('pages.systemServiceVersionPage.cve'),
      withSort: true,
    },
    {
      key: 'severity',
      label: t('common.severityLevel'),
      withSort: true,
    },
    {
      key: 'installedVersion',
      label: t('pages.systemServiceVersionPage.currentVersion'),
    },
    {
      key: 'fixedVersion',
      label: t('pages.systemServiceVersionPage.fixes'),
    },
    {
      key: 'title',
      label: t('common.name'),
      withSort: true,
    },
  ];
  useEffect(() => () => dispatch(clearStateVulnerabilities()), []);

  const onInfoClick = item => {
    setModalData(item);
    toggleModal();
  };

  const formattedBodyData = data?.map((item, index) => ({
    id: `${item?.uuid}${index}`,
    data: [
      {
        key: 'pkgName',
        content: item.pkgName,
      },
      {
        key: 'vulnerabilityId',
        content: item.vulnerabilityId,
        type: types.externalLink,
        href: item.primaryUrl,
      },
      {
        key: 'severity',
        content: item?.severity && (
          <StatusComponent statusId={item?.severity} statuses={vulnerabilitiesStatuses} />
        ),
      },
      {
        key: 'installedVersion',
        content: item.installedVersion,
      },
      {
        key: 'fixedVersion',
        content: item.fixedVersion,
      },
      {
        key: 'title',
        content: <ReadMoreBlock content={item.title} withoutExpandButton />,
        type: types.textLink,
        onClick: () => onInfoClick(item),
      },
    ],
  }));

  return (
    <>
      <NewDataTable
        headerData={headerData}
        bodyData={formattedBodyData}
        requestFunction={requestFunction}
        requestParams={requestParams}
        isLoading={isLoading}
        tableName="serviceVersionsVulnerabilitys"
        withStandartPagination
        total={total}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage
        isError={isError}
      />
      <ModalInfo
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        title={modalData?.vulnerabilityId}
      >
        <TextBlock greyColor title={t('common.name')}>
          {modalData?.title}
        </TextBlock>
        <TextBlock greyColor title={t('common.description')}>
          <div className="pre-line">{modalData?.description}</div>
        </TextBlock>
      </ModalInfo>
    </>
  );
}

export default ServiceVersionsVulnerabilitysTable;
