import { flatten } from 'flat';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';

import { changeUrlParams, clearParamByName } from 'store/urlParams/reducers';

function useURLQueryParams(key) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { state } = useLocation();

  const query = new URLSearchParams(window.location.search);

  const clearState = () => {
    if (state) {
      history.replace({
        search: `?${query}`,
        state: {},
      });
    }
  };

  const setQueryParams = value => {
    const flattenValue = flatten(value);
    dispatch(changeUrlParams({ [key]: JSON.stringify(flattenValue) }));
  };

  const clearParam = () => {
    dispatch(clearParamByName(key));
  };

  const getQueryByName = name => {
    try {
      const queryParamsJSON = query?.get(name);

      if (flatten.unflatten(JSON.parse(queryParamsJSON))?.system) {
        return JSON.parse(queryParamsJSON);
      }

      return flatten.unflatten(JSON.parse(queryParamsJSON));
    } catch {
      return null;
    }
  };

  const getParamValue = () => getQueryByName(key);

  return {
    getQueryByName,
    setQueryParams,
    getParamValue,
    clearParam,
    clearState,
  };
}

export default useURLQueryParams;
