import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useUploadButton from 'hooks/useUploadButton';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';

import { BlockWrapper, SectionTitle, DynamicFormWrapper, UploadButtonWrapper } from '../../styles';

function ServicesForm({ allProtocols, allAuthTypes, isEditService, editDocuments }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { UploadButtonComponent, files, setFilesToShow, isFilesToShow } = useUploadButton({
    buttonText: t('pages.systemIntegrationRelationsPage.attachFile'),
  });
  const textInputPlaceholder = t('pages.systemIntegrationRelationsPage.writeManually');

  const selectPlaceholder = t('pages.systemIntegrationRelationsPage.selectOrWriteMannually');

  const options = [
    {
      value: null,
      label: t('common.notSelected'),
    },
    {
      value: false,
      label: t('pages.systemIntegrationRelationsPage.sync'),
    },
    {
      value: true,
      label: t('pages.systemIntegrationRelationsPage.async'),
    },
  ];
  useEffect(() => {
    if (isEditService && editDocuments) {
      setFilesToShow(editDocuments);
    }
  }, [editDocuments]);

  useEffect(() => {
    setValue('serviceEndpointTo.documents', [...isFilesToShow, ...files]);
  }, [files, isFilesToShow]);

  const allProtocolsOptions = useMemo(
    () => generateSelectOptions(allProtocols, 'name', 'name'),
    [allProtocols]
  );

  const allAuthTypesOptions = useMemo(
    () => generateSelectOptions(allAuthTypes, 'name', 'name'),
    [allAuthTypes]
  );

  return (
    <>
      <DynamicFormWrapper>
        <BlockWrapper>
          <InputComponent
            label={t('common.name')}
            name="serviceEndpointTo.name"
            isRequired
            maxLength={250}
          />
        </BlockWrapper>
        <BlockWrapper>
          <InputComponent
            component={inputComponents.textarea}
            label={t('common.description')}
            name="serviceEndpointTo.description"
          />
        </BlockWrapper>
        <BlockWrapper columnsCount={2}>
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.protocol')}
            name="serviceEndpointTo.protocol"
            component={inputComponents.newSelect}
            options={allProtocolsOptions}
            placeholder={selectPlaceholder}
            withAddButton
          />
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.authType')}
            name="serviceEndpointTo.authType"
            component={inputComponents.newSelect}
            options={allAuthTypesOptions}
            withAddButton
            placeholder={selectPlaceholder}
          />
        </BlockWrapper>
        <BlockWrapper>
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.asyncType')}
            name="serviceEndpointTo.async"
            component={inputComponents.radioInput}
            defaultValue={options[0].value}
            options={options}
            rowDirection
          />
        </BlockWrapper>
        <BlockWrapper columnsCount={2}>
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.requestData')}
            name="serviceEndpointTo.requestData"
            component={inputComponents.multiSelect}
            isMulti
            withAddButton
            placeholder={textInputPlaceholder}
            maxLength={150}
          />
          <InputComponent
            label={t('pages.systemIntegrationRelationsPage.responseData')}
            name="serviceEndpointTo.responseData"
            component={inputComponents.multiSelect}
            isMulti
            withAddButton
            placeholder={textInputPlaceholder}
            maxLength={150}
          />
        </BlockWrapper>
        <UploadButtonWrapper>
          <SectionTitle>{t('common.documents')}</SectionTitle>
          {UploadButtonComponent}
        </UploadButtonWrapper>
      </DynamicFormWrapper>
    </>
  );
}
export default ServicesForm;
