import styled from 'styled-components';

export const ValueBlockWrapper = styled.div`
  grid-template-columns: 750px 30px;
  margin-bottom: 16px;
  display: grid;
  grid-column-gap: 16px;
  max-width: 800px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const MainTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin: 24px 0 16px;
`;
