import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActionList, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { convertLimits, convertValueToBytes, convertValueToMillicores } from 'utils';

import api from 'api';
import useAvailableResources from 'hooks/useAvailableResources';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchQuoteRequest } from 'store/tierQuotes/actions';
import { currentTierSelector } from 'store/tiers/selectors';
import { tierQuoteRequestSelector } from 'store/tierQuotes/selectors';
import { notActiveSystemSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import { BlueButton } from 'components/UI/Button';
import { showIconByStatus } from 'components/Common/QuoteLimits';
import {
  limitsKeys,
  tierStatusKeys,
  serverTierStatusKeys,
  systemTypesKeys,
  getLimitsLabels,
} from 'constants';
import {
  getQuotaInProgressMessage,
  getProgressTierMessage,
  getProgressSystemMessage,
} from 'constants/tooltips';

import * as S from './styles';

function QuoteRequestSection({ requestQuotaFunction, isExternal }) {
  const { t, i18n } = useTranslation();
  const limitsLabels = getLimitsLabels();
  const dispatch = useDispatch();
  const {
    params: { systemType, systemId, tierId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const { run, isLoading } = useAsync();

  const quoteRequest = useSelector(tierQuoteRequestSelector);
  const currentTier = useSelector(currentTierSelector);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const isQuotaInProgress = useAsync();

  const { warningText, loading, tooltipServerTiersWarningText, tooltipWarningText } =
    useAvailableResources(
      {},
      { uuid: currentTier?.serverTier?.uuid, name: currentTier?.serverTier?.name }
    );

  const isTierHasQuote = !!quoteRequest;
  const isActiveTier = currentTier?.status === tierStatusKeys.ACTIVE;

  const tierDisabledMessage = useMemo(() => {
    if (notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (!isActiveTier) {
      return getProgressTierMessage();
    }

    if (isQuotaInProgress?.data?.meta.total > 0) {
      return getQuotaInProgressMessage();
    }

    if (tooltipWarningText || loading) {
      return tooltipWarningText;
    }

    if (tooltipServerTiersWarningText) {
      return tooltipServerTiersWarningText;
    }
  }, [
    notActiveSystemMessage,
    isActiveTier,
    tooltipServerTiersWarningText,
    isQuotaInProgress?.data?.meta.total,
    isTierHasQuote,
    tooltipWarningText,
    i18n.language,
  ]);

  function requestFunction() {
    dispatch(fetchQuoteRequest({ limit: 1, tier: tierId, ascending: false, status: 'NEW' }));
  }

  useEffect(() => {
    if (tierId) {
      isQuotaInProgress.run(
        api.quotas.getQuotaRequests({
          limit: 1,
          tier: tierId,
          ascending: false,
          status: 'IN_PROGRESS',
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      !isExternal &&
      tierId &&
      ((currentTier?.totalSummary.total && currentTier?.totalSummary.requireApproval) ||
        !quoteRequest)
    ) {
      requestFunction();
    }
  }, [tierId, currentTier?.totalSummary]);

  const rejectQuote = async () => {
    await run(api.quotas.rejectTierQuota(currentTier?.uuid, quoteRequest?.uuid));
    requestFunction();
  };

  const acceptQuote = async () => {
    await run(api.quotas.approveTierQuota(currentTier?.uuid, quoteRequest?.uuid));
    requestFunction();
    setTimeout(() => {
      requestQuotaFunction();
    }, 400);
  };
  const serverTierDisabledMessage = useMemo(
    () =>
      currentTier?.system.type === systemTypesKeys.INTERNAL &&
      currentTier?.serverTier?.status !== serverTierStatusKeys.ACTIVE
        ? t('validation.incorrectServerTierStatus')
        : undefined,
    [currentTier, i18n.language]
  );

  if (!currentTier) {
    return null;
  }

  return (
    <>
      {isTierHasQuote && (
        <S.QuoteRequestSectionWrapper>
          <div className="d-flex justify-content-between mb-2 align-items-baseline">
            <div className="d-sm-flex flex-column">
              <h2 className="font-18 font-weight-medium mb-3">
                {t('pages.systemTierPage.quotaRequest')}
              </h2>
              <span className="font-16">{quoteRequest.description}</span>
            </div>
          </div>

          <div className="d-flex mb-4 mt-2">
            {Object.keys(limitsLabels).map(limitKey => {
              const limit = quoteRequest.limits.find(({ type }) => type === limitKey);
              const formatValue =
                limit?.type === limitsKeys.CPU
                  ? convertValueToMillicores(limit?.value, limit?.unitType)
                  : convertValueToBytes(limit?.value, limit?.unitType);

              return (
                <>
                  <span className="font-16 text-nowrap mr-2">{limitsLabels[limitKey]}</span>
                  <div className="d-flex flex-row align-items-baseline mr-4 text-nowrap">
                    <span className="font-16 font-weight-semiBold">
                      {convertLimits(formatValue, limit?.type, 1, true)}
                    </span>
                    <div>{showIconByStatus(limit?.status)}</div>
                  </div>
                </>
              );
            })}
          </div>

          {checkedPermissions(scopes.tier.tierChangeQuota) && warningText}

          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="secondary"
              id="infoButtom"
              component={props => (
                <Link
                  {...props}
                  to={generatePath(paths.systemTierFullPaths.quote, {
                    systemType,
                    systemId,
                    tierId,
                  })}
                />
              )}
            >
              {t('pages.systemTierPage.seeDetails')}
            </Button>
            <ActionList>
              {checkedPermissions(scopes.tier.tierApproveQuota) && (
                <ActionListItem>
                  <Tooltip
                    className={
                      tierDisabledMessage || serverTierDisabledMessage ? undefined : 'd-none'
                    }
                    content={tierDisabledMessage || serverTierDisabledMessage}
                    exitDelay={150}
                    animationDuration={150}
                  >
                    <div className="mr-2">
                      <BlueButton
                        variant="primary"
                        id="acceptButtom"
                        onClick={acceptQuote}
                        isDisabled={isLoading || tierDisabledMessage || serverTierDisabledMessage}
                        isLoading={isLoading}
                      >
                        {t('common.approve')}
                      </BlueButton>
                    </div>
                  </Tooltip>
                </ActionListItem>
              )}
              {checkedPermissions(scopes.tier.tierRejectQuota) && (
                <Tooltip
                  className={
                    notActiveSystemMessage || serverTierDisabledMessage || !isActiveTier
                      ? undefined
                      : 'd-none'
                  }
                  content={
                    notActiveSystemMessage || serverTierDisabledMessage || getProgressTierMessage()
                  }
                  exitDelay={150}
                  animationDuration={150}
                >
                  <ActionListItem>
                    <BlueButton
                      variant="secondary"
                      id="rejectButtom"
                      onClick={rejectQuote}
                      isDisabled={
                        isLoading ||
                        notActiveSystemMessage ||
                        serverTierDisabledMessage ||
                        !isActiveTier
                      }
                      isLoading={isLoading}
                    >
                      {t('common.reject')}
                    </BlueButton>
                  </ActionListItem>
                </Tooltip>
              )}
            </ActionList>
          </div>
        </S.QuoteRequestSectionWrapper>
      )}
    </>
  );
}

export default QuoteRequestSection;
