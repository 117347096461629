import { createSlice } from '@reduxjs/toolkit';

const expandedItemsSlice = createSlice({
  name: 'expandedItems',
  initialState: { data: {} },
  reducers: {
    changeExpandedItems: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { changeExpandedItems } = expandedItemsSlice.actions;

export default expandedItemsSlice.reducer;
