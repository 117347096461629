import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@patternfly/react-core';

import { ReactComponent as AngleRight } from 'assets/images/services/angle-right.svg';
import { formatFilterOptions } from 'utils';
import { getServiceTypeNames } from 'constants';

function ServicesType({ type, integration }) {
  const { t } = useTranslation();
  const serviceTypeNames = getServiceTypeNames();
  return (
    <div className="d-flex">
      {formatFilterOptions(serviceTypeNames).find(item => item.id === type)?.name || '-'}
      {integration && (
        <Tooltip
          content={t('pages.systemAddServicePage.isIntegrationService')}
          exitDelay={150}
          animationDuration={150}
        >
          <AngleRight className="ml-1" />
        </Tooltip>
      )}
    </div>
  );
}

export default ServicesType;
