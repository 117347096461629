import React from 'react';
import moment from 'moment';
import { Label } from '@patternfly/react-core';

import { ReactComponent as ExtremelySvg } from 'assets/images/audit/level/extremely.svg';
import { ReactComponent as CriticalSvg } from 'assets/images/audit/level/critical.svg';
import { ReactComponent as DebugSvg } from 'assets/images/audit/level/debug.svg';
import { ReactComponent as ErrorSvg } from 'assets/images/audit/level/error.svg';
import { ReactComponent as InfoSvg } from 'assets/images/audit/level/info.svg';
import { ReactComponent as NotificationSvg } from 'assets/images/audit/level/notification.svg';
import { ReactComponent as AlertSvg } from 'assets/images/audit/level/alert.svg';
import { ReactComponent as WarningSvg } from 'assets/images/audit/level/warning.svg';
import i18n from '../../../../i18n';

const { t } = i18n;

export const getAllLevels = () => [
  {
    value: 'EXTREMELY',
    label: t('pages.adminAuditPage.importanceLevels.EXTREMELY'),
    image: (
      <Label color="purple" icon={<ExtremelySvg />}>
        {t('pages.adminAuditPage.importanceLevels.EXTREMELY')}
      </Label>
    ),
  },
  {
    value: 'CRITICAL',
    label: t('pages.adminAuditPage.importanceLevels.CRITICAL'),
    image: (
      <Label color="red" icon={<CriticalSvg />}>
        {t('pages.adminAuditPage.importanceLevels.CRITICAL')}
      </Label>
    ),
  },
  {
    value: 'DEBUG',
    label: t('pages.adminAuditPage.importanceLevels.DEBUG'),
    image: <Label icon={<DebugSvg />}>{t('pages.adminAuditPage.importanceLevels.DEBUG')}</Label>,
  },
  {
    value: 'ERROR',
    label: t('common.error'),
    image: (
      <Label color="red" icon={<ErrorSvg />}>
        {t('common.error')}
      </Label>
    ),
  },
  {
    value: 'INFO',
    label: t('pages.adminAuditPage.importanceLevels.INFO'),
    image: (
      <Label variant="outline" color="blue" icon={<InfoSvg />}>
        {t('pages.adminAuditPage.importanceLevels.INFO')}
      </Label>
    ),
  },
  {
    value: 'NOTIFICATION',
    label: t('pages.adminAuditPage.importanceLevels.NOTIFICATION'),
    image: (
      <Label icon={<NotificationSvg />}>
        {t('pages.adminAuditPage.importanceLevels.NOTIFICATION')}
      </Label>
    ),
  },
  {
    value: 'ALERT',
    label: t('pages.adminAuditPage.importanceLevels.ALERT'),
    image: (
      <Label color="cyan" icon={<AlertSvg />}>
        {t('pages.adminAuditPage.importanceLevels.ALERT')}
      </Label>
    ),
  },
  {
    value: 'WARNING',
    label: t('pages.adminAuditPage.importanceLevels.WARNING'),
    image: (
      <Label color="orange" icon={<WarningSvg />}>
        {t('pages.adminAuditPage.importanceLevels.WARNING')}
      </Label>
    ),
  },
];

export const getDateOptions = () => [
  {
    value: moment().startOf('day'),
    label: t('pages.adminAuditPage.dateOptions.today'),
  },
  {
    value: moment().startOf('week'),
    label: t('pages.adminAuditPage.dateOptions.week'),
  },
  {
    value: moment().add(-15, 'minutes'),
    label: t('pages.adminAuditPage.dateOptions.15minutes'),
  },
  {
    value: moment().add(-30, 'minutes'),
    label: t('pages.adminAuditPage.dateOptions.30minutes'),
  },
  {
    value: moment().add(-1, 'hours'),
    label: t('pages.adminAuditPage.dateOptions.hour'),
  },
  {
    value: moment().add(-24, 'hours'),
    label: t('pages.adminAuditPage.dateOptions.24hours'),
  },
  {
    value: moment().add(-7, 'days'),
    label: t('pages.adminAuditPage.dateOptions.7days'),
  },
  {
    value: moment().add(-30, 'days'),
    label: t('pages.adminAuditPage.dateOptions.30days'),
  },
  {
    value: moment().add(-1, 'years'),
    label: t('pages.adminAuditPage.dateOptions.year'),
  },
];

export const getFilterOptions = () => [
  { value: 'systemCode', label: t('common.system') },
  { value: 'systemComponentCode', label: t('common.component') },
  { value: 'createdBy', label: t('common.account') },
  { value: 'eventCode', label: t('common.eventCode') },
  { value: 'traceId', label: t('common.traceId') },
  { value: 'eventType', label: t('common.eventType') },
];

export const getUserFilterOptions = () => [
  { value: 'systemCode', label: t('common.system') },
  { value: 'systemComponentCode', label: t('common.component') },
  { value: 'eventCode', label: t('common.eventCode') },
  { value: 'traceId', label: t('common.traceId') },
  { value: 'eventType', label: t('common.eventType') },
];
