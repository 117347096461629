import React, { useEffect } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { TextButton } from 'components/UI/Button';
import StatusComponent, { getServerTierAvailableStatuses } from 'components/Common/StatusComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import ClipboardCopyComponent from 'components/ClipboardCopyComponent';
import LinkComponent from 'components/UI/LinkComponent';
import paths from 'constants/paths';
import { systemTypesKeys } from 'constants';

import QuoteRequestSection from './QuoteRequestSection';
import LastReleaseName from '../../SystemTiersPage/Tier/LastReleaseName';
import LimitsMonitoring from '../../SystemTiersPage/Tier/LimitsMonitoring';

import * as S from './styles';

function TierInfo({ currentTier }) {
  const { t } = useTranslation();
  const serverTierAvailableStatuses = getServerTierAvailableStatuses();
  const { hasPermissions, checkedPermissions } = useUserPermissions();
  const {
    params: { systemId, systemType, tierId },
  } = useRouteMatch();

  const { run } = useAsync();

  function requestQuotaFunction() {
    run(api.quotas.getQuota({ systemId, tierId }));
  }

  const isExternalTiers = systemType === systemTypesKeys.EXTERNAL || !hasPermissions;

  useEffect(() => {
    if (tierId && hasPermissions !== undefined && !isExternalTiers) {
      requestQuotaFunction();
    }
  }, [isExternalTiers]);

  if (!currentTier) {
    return null;
  }

  return (
    <div className="bg-gray h-100 pt-4 font-14 font-weight-normal">
      <QuoteRequestSection
        requestQuotaFunction={requestQuotaFunction}
        isExternal={isExternalTiers}
      />
      <div className="d-sm-flex flex-row justify-content-between w-100">
        <div className="bg-white mx-4 mb-4 w-50">
          <DescriptionList
            columnModifier={{
              default: '1Col',
            }}
            className="p-4"
          >
            <h3 className="font-weight-semiBold">{t('common.details')}</h3>
            <S.DescriptionListTermWrapper>
              <DescriptionListGroup>
                <DescriptionListTerm>{t('common.name')}</DescriptionListTerm>
                <DescriptionListDescription>{currentTier.code}</DescriptionListDescription>
              </DescriptionListGroup>
            </S.DescriptionListTermWrapper>
            {!isExternalTiers && (
              <>
                <DescriptionListGroup>
                  <DescriptionListTerm>{t('common.currentRelease')}</DescriptionListTerm>
                  <DescriptionListDescription>
                    <LastReleaseName
                      tier={currentTier}
                      systemId={currentTier.system.uuid}
                      systemType={currentTier.system.type}
                    />
                  </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                  <DescriptionListTerm>{t('common.serverTier')}</DescriptionListTerm>
                  <DescriptionListDescription>
                    {checkedPermissions(scopes.platform.clusterViewForm, true) ? (
                      <TextButton
                        variant="link"
                        component={props => (
                          <Link
                            {...props}
                            to={
                              currentTier.serverTier?.uuid
                                ? generatePath(
                                    paths.adminFullPaths.serverTiers + paths.serverTiersPaths.show,
                                    {
                                      serverTierId: currentTier.serverTier.uuid,
                                    }
                                  )
                                : undefined
                            }
                          />
                        )}
                      >
                        <StatusComponent
                          statusId={currentTier.serverTier?.status}
                          statusWithTooltip
                          statuses={serverTierAvailableStatuses}
                        >
                          <span className="font-14">{currentTier.serverTier?.name}</span>
                        </StatusComponent>
                      </TextButton>
                    ) : (
                      currentTier.serverTier?.name
                    )}
                  </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                  <DescriptionListTerm>{t('pages.systemTierPage.baseUrl')}</DescriptionListTerm>
                  <DescriptionListDescription>
                    <div className="d-sm-flex flex-column">
                      {currentTier.hosts?.length
                        ? currentTier.hosts?.map(url => (
                            <div>
                              <div key={url.uuid}>
                                {url?.value ? <ClipboardCopyComponent content={url.value} /> : '-'}
                              </div>
                            </div>
                          ))
                        : '-'}
                    </div>
                  </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                  <DescriptionListTerm>{t('common.updatedBy')}</DescriptionListTerm>
                  <DescriptionListDescription>
                    <LastUpdatedStatusWithLink
                      lastUpdatedBy={currentTier.lastUpdatedBy}
                      lastUpdatedAt={currentTier.lastUpdatedAt}
                    />
                  </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                  <DescriptionListTerm>{t('common.links')}</DescriptionListTerm>
                  <DescriptionListDescription>
                    {currentTier?.namespaceUrl || currentTier?.argocdApplicationUrl ? (
                      <div className="d-sm-flex flex-column text-wrap-nowrap">
                        <LinkComponent
                          href={currentTier?.namespaceUrl}
                          text={t('pages.systemTierPage.openInDocker')}
                        />
                        <LinkComponent
                          href={currentTier?.argocdApplicationUrl}
                          text={t('pages.systemTierPage.openInGitOps')}
                        />
                      </div>
                    ) : (
                      '-'
                    )}
                  </DescriptionListDescription>
                </DescriptionListGroup>
              </>
            )}
          </DescriptionList>
        </div>
        {!isExternalTiers && (
          <div className="d-flex flex-column gap-4 mr-4 mb-4 w-50">
            {checkedPermissions(scopes.tier.tierViewMonitoring) && (
              <div className="bg-white w-100 h-100 position-relative">
                <LimitsMonitoring
                  requestFunction={
                    checkedPermissions(scopes.tier.tierViewMonitoring)
                      ? api.monitoring.getTierMonitoring
                      : undefined
                  }
                  uuid={currentTier.uuid}
                  itemName={currentTier.code}
                  numbersAfterComma={3}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TierInfo;
