import React from 'react';

import { useTranslation } from 'react-i18next';
import PageLayout from 'components/UI/PageLayout';
import TierBackups from 'pages/SystemRouter/SystemTiersRouter/CurrentTierPage/TierBackups';

function BackupsPage() {
  const { t } = useTranslation();
  return (
    <PageLayout title={t('common.backup')} withoutSidePadding fullHeight>
      <TierBackups isAdministration tableName="administrationBackupComponents" />
    </PageLayout>
  );
}

export default BackupsPage;
