import React, { useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FilterIcon } from '@patternfly/react-icons';
import { useTranslation } from 'react-i18next';

import useDropdownFilterSection from 'hooks/useDropdownFilterSection';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemTiersForDropdownSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import PageLayout from 'components/UI/PageLayout';
import { BlueButton } from 'components/UI/Button';

import NewIntegrationTable from './IntegrationSystemsTable';

function IntegrationsPage({ withSystemHeader = true }) {
  const { t } = useTranslation();
  const title = t('common.integrationRelations');
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();

  const systemTiers = useSelector(currentSystemTiersForDropdownSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const filterData = [
    {
      id: 'multiFilters',
      optionData: [
        {
          id: true,
          name: t('pages.systemIntegrationRelationsPage.income'),
          group: t('pages.systemIntegrationRelationsPage.direction'),
        },
        {
          id: false,
          name: t('pages.systemIntegrationRelationsPage.outcome'),
          group: t('pages.systemIntegrationRelationsPage.direction'),
        },
        ...systemTiers.slice(1),
      ],
      defaultText: (
        <>
          <FilterIcon className="ml-2" />
          <span className="ml-3">{t('common.filter')}</span>
        </>
      ),
      isMulti: true,
      needDeleteAll: false,
    },
  ];

  const { filterParams, SearchInput, FilterComponent, hasSelectedFilters } =
    useDropdownFilterSection(filterData, 'integrationsSystems');

  const addIntegrationButton = systemId &&
    checkedPermissions(scopes.integration.integrationCreate) && (
      <BlueButton
        variant="primary"
        component={props => (
          <Link
            {...props}
            to={generatePath(paths.systemIntegrationsFullPaths.add, {
              systemType,
              systemId,
            })}
          />
        )}
      >
        {t('common.add')}
      </BlueButton>
    );

  const leftSideContent = isShowFilters && <>{addIntegrationButton}</>;

  return (
    <PageLayout
      systemLayout
      withSystemHeader={withSystemHeader}
      baselineTop
      title={title}
      leftSideContent={leftSideContent}
      withSystemSelector
      withoutSideMarggin
    >
      {isShowFilters && (
        <div className="d-sm-flex flex-row ml-4">
          {systemId && FilterComponent}
          {SearchInput}
        </div>
      )}
      <NewIntegrationTable
        filterParams={filterParams}
        setIsShowFilters={setIsShowFilters}
        filterData={filterData}
        hasSelectedFilters={hasSelectedFilters}
        addButton={
          systemId && checkedPermissions(scopes.integration.integrationCreate)
            ? addIntegrationButton
            : undefined
        }
      />
    </PageLayout>
  );
}

export default IntegrationsPage;
