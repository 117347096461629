import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useFormContext } from 'react-hook-form';
import {
  getSystemFieldsValidateMessage,
  getSystemCodeValidation,
  getSystemNameValidation,
  systemCodeRegex,
} from 'constants/vaidations';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { translit } from 'utils';
import {
  getSystemCodeInfoMessage,
  getSystemNameInfoMessage,
  getSystemShortNameInfoMessage,
} from 'constants/tooltips';

import { InputBlockWrapper } from 'components/UI/View';
import { SystemBlockWrapper } from '../../styles';

function GeneralInfo() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const shortNameValue = watch('shortName');
  const systemCodeInfoMessage = getSystemCodeInfoMessage();
  const systemNameInfoMessage = getSystemNameInfoMessage();
  const systemShortNameInfoMessage = getSystemShortNameInfoMessage();
  const {
    params: { systemId },
  } = useRouteMatch();
  const systemFieldsValidateMessage = getSystemFieldsValidateMessage();

  const [isCanTraslitCodeValue, setIsCanTraslitCodeValue] = useState(false);

  const systemNameValidation = useMemo(() => getSystemNameValidation(systemId), [systemId]);
  const systemCodeValidation = useMemo(() => getSystemCodeValidation(systemId), [systemId]);

  useEffect(() => {
    if (!systemId && isCanTraslitCodeValue) {
      const translittedName = translit(shortNameValue)?.match(systemCodeRegex)?.join('-') || '';
      setValue('code', translittedName, { shouldValidate: !!translittedName });
    }
  }, [shortNameValue, systemId]);

  const focusCallback = isFocus => {
    setIsCanTraslitCodeValue(isFocus);
  };

  useEffect(() => {
    setValue('step', 1);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('common.generalInfo')}</div>
      <InputComponent
        label={t('common.fullName')}
        name="name"
        isRequired
        tooltipMessage={`${systemNameInfoMessage}`}
        minLength={2}
        maxLength={255}
        validationRules={systemNameValidation}
        description={systemFieldsValidateMessage.name}
      />
      <InputBlockWrapper columnsCount={2}>
        <InputComponent
          label={t('common.shortName')}
          name="shortName"
          isRequired
          tooltipMessage={`${systemShortNameInfoMessage}`}
          minLength={2}
          maxLength={20}
          description={systemFieldsValidateMessage.shortName}
          focusCallback={focusCallback}
        />
        <InputComponent
          isRequired
          label={t('common.code')}
          name="code"
          tooltipMessage={`${systemCodeInfoMessage}`}
          disabled={systemId}
          validationRules={systemCodeValidation}
          minLength={2}
          maxLength={30}
          description={systemFieldsValidateMessage.code}
        />
      </InputBlockWrapper>

      <InputComponent
        label={t('common.description')}
        name="description"
        component={inputComponents.textarea}
        maxLength={250}
        description={systemFieldsValidateMessage.description}
      />
    </SystemBlockWrapper>
  );
}

export default GeneralInfo;
