import React, { useEffect, useMemo } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { checkedTierTotal } from 'utils';

import api from 'api';
import useModal from 'hooks/useModal';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { getUserName } from 'hooks/useKeycloakAuth';
import { fetchUserById } from 'store/users/actions';
import paths from 'constants/paths';
import WarningModal from 'components/Common/WarningModal';
import Dropdown from 'components/Dropdown';
import { systemRole, getSystemRoleLabels } from 'constants/roles';
import { usersOptions, userStatusKeys } from 'constants';

import EditUserModal from './EditUserModal';

function UserActions({
  userUuid,
  roleId,
  user,
  deleteRoles,
  isBlueButtonDropdown,
  callback,
  isAdministration,
  currentSystem,
  isCantEdit,
  roleTotal,
}) {
  const { t, i18n } = useTranslation();
  const systemRoleLabels = getSystemRoleLabels();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { systemId, userId },
  } = useRouteMatch();
  const { notActiveSystemMessage, optionsFilterByScopes } = useUserPermissions(currentSystem);
  const updateRole = useAsync();
  const revertRolesWarning = useModal();
  const editUserModal = useModal();
  const changeRoleWarning = useModal();

  const currentUser = systemId && !userId ? { ...user, status: user.userStatus } : user;
  const isResponsible = user.existsResponsible;
  const isSystemResponsible = currentSystem?.responsible?.uuid === userUuid;
  const isWithoutRoles = !user.totalSummary?.userRoleTotal?.total;
  const isAllActiveRoles = !(
    user?.totalSummary?.userRoleTotal.error || user?.totalSummary?.userRoleTotal.inProgress
  );
  const haveNotActiveSystem = !!(
    user?.totalSummary?.systemTotal.error || user?.totalSummary?.systemTotal.inProgress
  );
  const hasNotActiveTiers = useMemo(() => checkedTierTotal(user?.tierTotal), [user?.tierTotal]);
  const haveErrorRoles = user?.totalSummary?.userRoleTotal.error;
  const haveProgressRoles = user?.totalSummary?.userRoleTotal.inProgress;

  const notActiveUserMessage = useMemo(() => {
    if (currentUser.status === userStatusKeys.UPDATING_IN_PROGRESS) {
      return t('pages.adminUserPage.refreshingAccessLevelError');
    }

    if (currentUser.status === userStatusKeys.ERROR) {
      return t('pages.adminUserPage.accessLevelError');
    }
  }, [currentUser.status, i18n.language]);

  const getEditTooltipMassage = isAddToSystem => {
    if (notActiveUserMessage) {
      return notActiveUserMessage;
    }

    if (user.status === userStatusKeys.NEW) {
      return t('pages.adminUserCreatePage.invalidUserStatus');
    }

    if (!user.enabled && isAddToSystem) {
      return t('pages.adminUserCreatePage.inactiveUserEditingError');
    }

    if (currentUser.status === userStatusKeys.UPDATED) {
      if (!isAddToSystem) {
        if (systemId && isSystemResponsible) {
          return t('pages.adminUserPage.editResponsibleError');
        }

        if (isResponsible) {
          return t('pages.adminUserPage.editResponsibleError2');
        }
      }

      if (!isAllActiveRoles && isAddToSystem) {
        return t('pages.adminUserPage.incorrectRoleStatusError');
      }
    }
  };

  const getTooltipMassage = (isAddToSystem, isRevert, isEditRole) => {
    if (systemId && notActiveSystemMessage) {
      return notActiveSystemMessage;
    }

    if (user.status === userStatusKeys.NEW) {
      return t('pages.adminUserCreatePage.invalidUserStatus');
    }

    if (!user.enabled) {
      return t('pages.adminUserCreatePage.inactiveUserEditingError');
    }

    if (hasNotActiveTiers) {
      return hasNotActiveTiers;
    }

    if (notActiveUserMessage) {
      return notActiveUserMessage;
    }

    if (currentUser.status === userStatusKeys.UPDATED) {
      if (!isAddToSystem && isResponsible && isAllActiveRoles) {
        if (isSystemResponsible) {
          return t('pages.adminUserPage.isResponsibleError');
        }

        if ((systemId && !isRevert && !isSystemResponsible) || !systemId) {
          return t('pages.adminUserPage.isResponsible');
        }
      }

      if (roleTotal === 0 && systemId) {
        return t('pages.adminUserPage.revokeAllAccessError5');
      }

      if (!isEditRole && isRevert && isWithoutRoles) {
        return t('pages.adminUserPage.revokeAllAccessError');
      }

      if (isRevert && !systemId && isWithoutRoles) {
        return t('pages.adminUserPage.revokeAllAccessError2');
      }

      if (!isEditRole && haveErrorRoles) {
        return t('pages.adminUserPage.revokeAllAccessError3');
      }

      if (!isEditRole && !isAllActiveRoles) {
        return t('pages.adminUserPage.revokeAllAccessError4');
      }
    }

    if (isEditRole) {
      if (haveErrorRoles) {
        return t('pages.adminUserPage.revokeAllAccessError5');
      }

      if (!isSystemResponsible && haveProgressRoles) {
        return t('pages.adminUserPage.revokeAllAccessError4');
      }
    }
  };

  const getWarningAlertTitle = useMemo(() => {
    if (
      !isAllActiveRoles ||
      hasNotActiveTiers ||
      (!systemId && haveNotActiveSystem) ||
      (systemId && notActiveSystemMessage)
    ) {
      return t('pages.adminUserPage.revokeAllAccessError6');
    }
  }, [haveNotActiveSystem, isAllActiveRoles, hasNotActiveTiers, notActiveSystemMessage]);

  const requestFunction = () => dispatch(fetchUserById(userUuid));

  useEffect(() => {
    if (deleteRoles.isSuccess && revertRolesWarning.isModalVisible) {
      revertRolesWarning.setIsModalVisible(false);
      if (callback && systemId && currentSystem) {
        callback();
      }
      if (systemId && !currentSystem) {
        history.goBack();
      }
    }
  }, [deleteRoles.isSuccess]);

  useEffect(() => {
    if (updateRole.isSuccess && changeRoleWarning.isModalVisible) {
      changeRoleWarning.setModalData();
      changeRoleWarning.toggleModal();
    }
  }, [updateRole.isSuccess]);

  const onDeleteRoleConfirm = () => {
    if (systemId) {
      deleteRoles.run(api.users.deleteUserRole(userUuid, roleId));
    } else {
      deleteRoles.run(api.users.deleteAllUserRoles(userUuid));
    }
  };

  const onUpdateRoleConfirm = () => {
    updateRole.run(
      api.users.updateUserRole(userUuid, roleId, {
        uuid: roleId,
        system: { uuid: currentSystem?.uuid },
        name: changeRoleWarning.modalData,
      })
    );
  };

  const onFilterChange = id => {
    if (id === usersOptions.assign) {
      return history.push(
        generatePath(
          systemId
            ? paths.adminUsersPaths.assignUsers
            : paths.adminFullPaths.users + paths.adminUsersPaths.assignUsers,
          {
            userId: userUuid,
          }
        )
      );
    }
    if (id === usersOptions.revert) {
      return revertRolesWarning.toggleModal();
    }

    if (id === usersOptions.editRole) {
      changeRoleWarning.setModalData(
        user.name === systemRole.DEV ? systemRole.OPS : systemRole.DEV
      );
      return changeRoleWarning.toggleModal();
    }

    editUserModal.toggleModal();
  };

  const optionsData = useMemo(
    () =>
      optionsFilterByScopes([
        ...(!isCantEdit
          ? [
              {
                id: usersOptions.edit,
                name: t('common.edit'),
                isDisabled: getEditTooltipMassage(),
                tooltip: getEditTooltipMassage(),
                scope: scopes.platform.userUpdate,
                isPlatformScope: true,
                isHidden: getUserName() === user.login,
              },
            ]
          : []),
        ...(!systemId
          ? [
              {
                id: usersOptions.assign,
                name: t('pages.adminUserPage.assignToSystem'),
                isDisabled: getEditTooltipMassage(true),
                tooltip: getEditTooltipMassage(true),
                scope: scopes.user.userAddToSystem,
                isPlatformScope: isAdministration,
                isHidden: user.admin && user.status === userStatusKeys.UPDATED,
              },
            ]
          : [
              {
                id: usersOptions.editRole,
                name: t('pages.adminUserPage.changeRole'),
                tooltip: getTooltipMassage(null, true, true),
                isDisabled: getTooltipMassage(null, true, true),
                scope: scopes.platform.userUpdateRole,
                isPlatformScope: isAdministration,
              },
            ]),
        {
          id: usersOptions.revert,
          name: systemId
            ? t('pages.adminUserPage.revokeAccess')
            : t('pages.adminUserPage.revokeAccessToAllSystems'),
          isDisabled: getTooltipMassage(null, true),
          tooltip: getTooltipMassage(null, true),
          scope: scopes.user.userRevoke,
          isPlatformScope: isAdministration,
          isHidden: user.admin && user.status === userStatusKeys.UPDATED,
        },
      ]),
    [
      isResponsible,
      isAdministration,
      isWithoutRoles,
      optionsFilterByScopes,
      user,
      hasNotActiveTiers,
      haveNotActiveSystem,
    ]
  );

  return (
    <>
      {!!optionsData.length && (
        <Dropdown
          id={userUuid}
          onFilterChange={onFilterChange}
          optionData={optionsData || []}
          isBlueButtonDropdown={isBlueButtonDropdown}
          positionRigh={isBlueButtonDropdown}
        />
      )}

      <EditUserModal
        {...editUserModal}
        user={user}
        callback={callback || requestFunction}
        warningAlertTitle={getWarningAlertTitle}
      />

      <WarningModal
        title={t('pages.adminUserPage.changingRole')}
        text={t('pages.adminUserPage.changeRole')}
        isLoading={updateRole.isLoading}
        onClick={onUpdateRoleConfirm}
        isModalVisible={changeRoleWarning.isModalVisible}
        toggleModal={changeRoleWarning.toggleModal}
        buttenText={t('pages.adminUserPage.change')}
        warningColor="#f0ab00"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t('pages.adminUserPage.changeRoleConfirm', [
              user?.login,
              currentSystem?.shortName,
              systemRoleLabels[changeRoleWarning.modalData],
            ]),
          }}
        />
      </WarningModal>

      <WarningModal
        title={
          systemId
            ? t('pages.adminUserPage.revokingAccess')
            : t('pages.adminUserPage.revokingAccesses')
        }
        text={
          systemId ? t('pages.adminUserPage.revokeAccess') : t('pages.adminUserPage.revokeAccesses')
        }
        isLoading={deleteRoles.isLoading}
        onClick={onDeleteRoleConfirm}
        isModalVisible={revertRolesWarning.isModalVisible}
        toggleModal={revertRolesWarning.toggleModal}
        buttenText={t('pages.adminUserPage.revoke')}
        warningColor="#f0ab00"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: systemId
              ? t('pages.adminUserPage.revokeAccessConfirm', [
                  user?.login,
                  currentSystem?.shortName,
                ])
              : t('pages.adminUserPage.revokeAccessToAllSystemsConfirm', [user?.login]),
          }}
        />
      </WarningModal>
    </>
  );
}

export default UserActions;
