import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    data: [],
    isLoading: true,
    total: 0,
    isError: null,
    limit: [5, 10, 20, 50, 100],
  },
  reducers: {
    addNewDocument: (state, { payload }) => {
      const newData = state.limit.includes(state.data?.length)
        ? state.data.slice(0, -1)
        : state.data;
      state.data = [payload, ...newData];
      state.total += 1;
    },
    clearDocumentsState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.isError = null;
    },
  },
  extraReducers: {
    [actions.fetchDocuments.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchDocuments.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.total = meta.total;
      state.isLoading = false;
      state.isError = null;
    },
    [actions.fetchDocuments.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
  },
});

export const { addNewDocument, clearDocumentsState } = documentsSlice.actions;

export default documentsSlice.reducer;
