import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  serviceVersionsOptionsIsLoadingSelector,
  serviceVersionsOptionsSelector,
} from 'store/serviceVersions/selectors';
import { fetchServiceVersionOptions } from 'store/serviceVersions/actions';
import { clearServiceVersionsOptions } from 'store/serviceVersions/reducers';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import ContextSelectorOptions from 'components/ContextSelectorOptions';
import ServiceVersionsStatus from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable/ServiceVersionsStatus';
import ServiceVersionsName from 'pages/SystemRouter/SystemServiceVersionsRouter/ServiceVersionsPage/ServiceVersionsTable/ServiceVersionsName';

function ContextSelectorServiceVersions({
  serviceUuid,
  setModalData,
  tag,
  onEditClick,
  toggleModal,
  setCustomVersions,
  version,
}) {
  const dispatch = useDispatch();
  const serviceVersionsOptions = useSelector(serviceVersionsOptionsSelector);
  const systemsOptionsIsLoading = useSelector(serviceVersionsOptionsIsLoadingSelector);
  const [searchValue, setSearchValue] = useState('');

  const requestParams = useMemo(
    () => ({
      service: serviceUuid,
      orderBy: 'createdAt',
      ascending: false,
      limit: 10,
      tagQuery: searchValue,
      isConcatData: false,
    }),
    [serviceUuid, searchValue]
  );

  const requestFunction = requestParameters => {
    dispatch(fetchServiceVersionOptions(requestParameters));
  };

  const callback = option => {
    setModalData({
      tag: option.label,
      uuid: option.value,
      serviceVersionStatus: option.status,
      pipelineStatus: option.pipelineStatus,
      description: option.description.description,
      lastUpdatedAt: option.lastUpdatedAt,
      addedViaPortal: option.description.addedViaPortal,
      usedDefaultBranch: option.description.usedDefaultBranch,
    });
    toggleModal();
  };

  const closeCallback = () => dispatch(clearServiceVersionsOptions());

  const servicesVersionsOptions = useMemo(
    () =>
      generateSelectOptions(serviceVersionsOptions?.data, null, null, null, {
        getLabel: item => item.tag,
        getValue: item => item.uuid,
        getStatus: item => item.serviceVersionStatus,
        getPipelineStatus: item => item.pipelineStatus,
        getDescription: item => item,
        getLastUpdatedAt: item => item.lastUpdatedAt,
      }),
    [serviceVersionsOptions?.data]
  );

  const selectorItem = (option, checkedValue) => (
    <div className="d-sm-flex flex-column">
      <ServiceVersionsStatus
        version={{
          ...(option || checkedValue),
          status: option?.status || checkedValue?.status,
          pipeline: { status: option?.pipelineStatus || checkedValue?.pipelineStatus },
        }}
        statusWithTooltip
      >
        <div className="d-flex text-wrap-balance">
          <span className="font-16 mr-2 text-word-break-wrap">{tag}</span>
        </div>
      </ServiceVersionsStatus>
    </div>
  );

  const getOption = option =>
    option && (
      <ServiceVersionsStatus
        version={{
          ...option,
          status: option?.status,
          pipeline: { status: option?.pipelineStatus },
        }}
        statusWithTooltip
      >
        <ServiceVersionsName option={option} />
      </ServiceVersionsStatus>
    );

  return (
    <ContextSelectorOptions
      total={serviceVersionsOptions?.total}
      options={servicesVersionsOptions}
      getOption={getOption}
      requestParams={requestParams}
      requestFunction={requestFunction}
      isLoading={systemsOptionsIsLoading}
      callback={callback}
      onEditClick={onEditClick}
      checkedValue={version}
      selectorItem={selectorItem}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      isDateDescription
      closeCallback={closeCallback}
      setCustomVersions={setCustomVersions}
      serviceUuid={serviceUuid}
    />
  );
}

export default ContextSelectorServiceVersions;
