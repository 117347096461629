import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchServiceVersions = createAsyncThunk(
  'serviceVersions/fetchServiceVersions',
  async params => {
    const { data } = await api.serviceVersion.getServicesVersions(params);

    return data;
  }
);

export const fetchServiceVersionById = createAsyncThunk(
  'serviceVersions/fetchServiceVersionById',
  async params => {
    const { data } = await api.serviceVersion.getServiceVersionByUuid(params);

    return data;
  }
);

export const fetchServiceVersionOptions = createAsyncThunk(
  'serviceVersions/fetchServiceVersionOptions',
  async ({ isConcatData, ...params }) => {
    const { data } = await api.serviceVersion.getServicesVersionsOptions(params);

    return {
      ...data,
      isConcatData,
    };
  }
);
