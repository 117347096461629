import React from 'react';
import ExternalLinkAltIcon from '@patternfly/react-icons/dist/esm/icons/external-link-alt-icon';

const LinkComponent = ({ href, text, withIcon = true, label }) => {
  if (!href) {
    return null;
  }

  return (
    <a href={href} target="_blank" rel="noreferrer" aria-label={label} className="font-14">
      <span className="mr-2">{text}</span>
      {withIcon && <ExternalLinkAltIcon className="font-14" />}
    </a>
  );
};

export default LinkComponent;
