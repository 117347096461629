import http from 'api/config';

const getTiers = params => http.get(`tiers`, { params });

const getTier = ({ tierId, data }) => http.get(`/tiers/${tierId}`, data);

const editTier = ({ tierId, data }) => http.patch(`/tiers/${tierId}`, data);

const addTier = data => http.post(`/tiers`, data);

const addExternalTier = data => http.post(`/tiers/external`, data);

const deleteTier = ({ tierId }) => http.delete(`/tiers/${tierId}`);

const getDeployedRelease = params => http.get(`/tiers/releases`, { params });

const getTiersOptions = params => http.get(`tiers/options`, { params });

const archiveTier = uuid =>
  http.post(`tiers/${uuid}/archive
`);

const restoreTier = ({ tierId, ...data }) => http.post(`tiers/${tierId}/restore`, data);

export default {
  getTier,
  getTiers,
  addTier,
  addExternalTier,
  editTier,
  deleteTier,
  getDeployedRelease,
  getTiersOptions,
  archiveTier,
  restoreTier,
};
