import styled from 'styled-components';

export const DateText = styled.button.attrs({
  className:
    'border-right-0 border-left-0 cursor-pointer text-black font-16 px-2 py-1 border-top bg-white text-nowrap',
})`
  height: 37px;
  border-bottom: 1px solid #8a8d90;

  &:hover {
    border-bottom-color: #0066cc;
  }

  ${({ isDate }) => isDate && 'padding: 4px 8px 4px 0;'}

  ${({ active }) =>
    active &&
    ` 
    border-color: #0066CC;
    border-width: 2px;
  `};
`;

export const DateTextFirst = styled(DateText)`
  border-left: 1px solid #dee2e6 !important;
  min-width: 144px;

  ${({ isDate }) => isDate && 'padding: 4px 0 4px 8px;'}
`;

export const DateDash = styled(DateText)`
  min-width: auto;
  padding: 4px 0 4px 0;

  ${({ isDate }) => isDate && 'padding: 4px 0;'}
`;
