import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import paths from 'constants/paths';
import TotalSummaryStatuses from 'components/UI/TotalSummaryStatuses';
import { getServiceVersionsPipelineStatuses } from 'components/Common/StatusComponent';
import { systemTypesKeys } from 'constants';
import { getPluralLabel } from 'utils';

import { featureFlags } from '../../../../constants/features';

import * as S from '../styles';

function SystemTotals({ system, showNoDataMessage }) {
  const { t } = useTranslation();
  const serviceVersionsPipelineStatuses = getServiceVersionsPipelineStatuses();
  const { checkedPermissions } = useUserPermissions(system);

  const isInternalSystem = system?.type !== systemTypesKeys.EXTERNAL;

  return (
    <>
      {(!system?.totalSummary || !checkedPermissions(scopes.system.systemViewForm)) && (
        <div className="d-flex justify-content-center py-4">
          <div>
            <S.NoDataImg />
            {showNoDataMessage && <p className="text-center font-14">{t('pages.home.noData')}</p>}
          </div>
        </div>
      )}

      {system?.totalSummary && (
        <>
          {checkedPermissions(scopes.tier.tierViewSection) && (
            <div className="d-flex justify-content-between font-14">
              <Link
                className="font-14"
                to={generatePath(paths.systemFullPaths.tiers, {
                  systemId: system.uuid,
                  systemType: system.type.toLowerCase(),
                })}
              >
                <span className="mr-2 w-total">{system.totalSummary.tierTotal?.total}</span>
                {getPluralLabel(system.totalSummary.tierTotal?.total, [
                  t('plural.tear1'),
                  t('plural.tear2'),
                  t('plural.tear3'),
                ])}
              </Link>

              <TotalSummaryStatuses totalSummary={system.totalSummary.tierTotal} />
            </div>
          )}

          {isInternalSystem && checkedPermissions(scopes.tier.tierViewForm) && (
            <div className="d-flex justify-content-between font-14">
              <Link
                className="font-14"
                to={generatePath(paths.systemFullPaths.tiers, {
                  systemId: system.uuid,
                  systemType: system.type.toLowerCase(),
                })}
              >
                <span className="mr-2 w-total">{system.totalSummary.quotaTotal?.total}</span>
                {getPluralLabel(system.totalSummary.quotaTotal?.total, [
                  t('plural.quotas1'),
                  t('plural.quotas2'),
                  t('plural.quotas3'),
                ])}
              </Link>
              <TotalSummaryStatuses totalSummary={system.totalSummary.quotaTotal} />
            </div>
          )}

          {isInternalSystem && checkedPermissions(scopes.service.serviceViewForm) && (
            <div className="d-flex justify-content-between font-14">
              <Link
                className="font-14"
                to={generatePath(paths.systemFullPaths.services, {
                  systemId: system.uuid,
                  systemType: system.type.toLowerCase(),
                })}
              >
                <span className="mr-2 w-total">{system.totalSummary.serviceTotal?.total}</span>
                {getPluralLabel(system.totalSummary.serviceTotal?.total, [
                  t('plural.service1'),
                  t('plural.service2'),
                  t('plural.service3'),
                ])}
              </Link>

              <TotalSummaryStatuses totalSummary={system.totalSummary.serviceTotal} />
            </div>
          )}

          {featureFlags.isServiceVersionsFeatureEnabled &&
            isInternalSystem &&
            checkedPermissions(scopes.serviceVersion.versionViewSection) && (
              <div className="d-flex justify-content-between font-14">
                <Link
                  className="font-14"
                  to={generatePath(paths.systemFullPaths.serviceVersions, {
                    systemId: system.uuid,
                    systemType: system.type.toLowerCase(),
                  })}
                >
                  <span className="mr-2 w-total">
                    {system.totalSummary.serviceVersionTotal?.total}
                  </span>
                  {getPluralLabel(system.totalSummary.serviceVersionTotal?.total, [
                    t('plural.serviceVersion1'),
                    t('plural.serviceVersion2'),
                    t('plural.serviceVersion3'),
                  ])}
                </Link>

                <TotalSummaryStatuses
                  totalSummary={{
                    ...system.totalSummary.serviceVersionTotal,
                    inProgressMessage: serviceVersionsPipelineStatuses.RUNNING.text,
                  }}
                />
              </div>
            )}

          {isInternalSystem && checkedPermissions(scopes.release.releaseViewSection) && (
            <div className="d-flex justify-content-between font-14">
              <Link
                className="font-14"
                to={generatePath(paths.systemFullPaths.releases, {
                  systemId: system.uuid,
                  systemType: system.type.toLowerCase(),
                })}
              >
                <span className="mr-2 w-total">{system.totalSummary.releaseTotal?.total}</span>
                {getPluralLabel(system.totalSummary.releaseTotal?.total, [
                  t('plural.release1'),
                  t('plural.release2'),
                  t('plural.release3'),
                ])}
              </Link>

              <TotalSummaryStatuses totalSummary={system.totalSummary.releaseTotal} />
            </div>
          )}

          {isInternalSystem &&
            featureFlags.isIssuesFeatureEnabled &&
            checkedPermissions(scopes.milestone.milestoneViewSection) && (
              <div className="d-flex justify-content-between font-14">
                <Link
                  className="font-14"
                  to={generatePath(paths.systemFullPaths.milestones, {
                    systemId: system.uuid,
                    systemType: system.type.toLowerCase(),
                  })}
                >
                  <span className="mr-2 w-total">{system.totalSummary.milestoneTotal?.total}</span>
                  {getPluralLabel(system.totalSummary.milestoneTotal?.total, [
                    t('plural.milestone1'),
                    t('plural.milestone2'),
                    t('plural.milestone3'),
                  ])}
                </Link>
                <TotalSummaryStatuses totalSummary={system.totalSummary.milestoneTotal} />
              </div>
            )}

          {isInternalSystem &&
            featureFlags.isIssuesFeatureEnabled &&
            checkedPermissions(scopes.issue.issueViewSection) && (
              <div className="d-flex justify-content-between font-14">
                <Link
                  className="font-14"
                  to={generatePath(paths.systemFullPaths.issues, {
                    systemId: system.uuid,
                    systemType: system.type.toLowerCase(),
                  })}
                >
                  <span className="mr-2 w-total">{system.totalSummary.issueTotal?.total}</span>
                  {getPluralLabel(system.totalSummary.issueTotal?.total, [
                    t('plural.issue1'),
                    t('plural.issue2'),
                    t('plural.issue3'),
                  ])}
                </Link>
                <TotalSummaryStatuses totalSummary={system.totalSummary.issueTotal} />
              </div>
            )}

          {featureFlags.isIntegrationsFeatureEnabled &&
            checkedPermissions(scopes.integration.integrationViewSection) && (
              <div className="d-flex justify-content-between font-14">
                <Link
                  className="font-14"
                  to={generatePath(paths.systemFullPaths.integrations, {
                    systemId: system.uuid,
                    systemType: system.type.toLowerCase(),
                  })}
                >
                  <span className="mr-2 w-total">
                    {system.totalSummary.integrationTotal?.total}
                  </span>
                  {getPluralLabel(system.totalSummary.integrationTotal?.total, [
                    t('plural.integration1'),
                    t('plural.integration2'),
                    t('plural.integration3'),
                  ])}
                </Link>

                <TotalSummaryStatuses totalSummary={system.totalSummary.integrationTotal} />
              </div>
            )}

          {isInternalSystem && checkedPermissions(scopes.document.documentViewSection) && (
            <div className="d-flex justify-content-between font-14">
              <Link
                className="font-14"
                to={generatePath(paths.systemFullPaths.documents, {
                  systemId: system.uuid,
                  systemType: system.type.toLowerCase(),
                })}
              >
                <span className="mr-2 w-total">{system.totalSummary.documentTotal?.total}</span>
                {getPluralLabel(system.totalSummary.documentTotal?.total, [
                  t('plural.document1'),
                  t('plural.document2'),
                  t('plural.document3'),
                ])}
              </Link>
              <TotalSummaryStatuses totalSummary={system.totalSummary.documentTotal} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SystemTotals;
