import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

import ArrowDownSVG from 'assets/images/system_page/ArrowDown.svg';
import BackSVG from 'assets/images/menu/back-arrow.svg';

export const BackIcon = styled(Link)`
  display: flex;
  height: 15px;
  width: 9px;
  cursor: pointer;
  background: url(${BackSVG}) no-repeat;
  background-size: cover;
`;

export const SystemName = styled.span`
  color: #ffffff;
  font-size: 14px;
  width: 178px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArrowButtonWrapper = styled.div`
  display: flex;
  width: 58px;
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    background: #262a3b;
  }
`;

export const ArrowDownIcon = styled.div`
  width: 100%;
  height: 100%;
  background: #6a6e73;
  -webkit-mask-image: url(${ArrowDownSVG});
  mask-image: url(${ArrowDownSVG});
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-position: center;
  transition: transform 0.3s;

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    transform: rotate(-90deg);
  `};
`;

export const Divider = styled.span`
  height: 1px;
  background: #4e556a;
  margin: 8px 0 8px 24px;
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  max-width: 320px;
  height: 56px;
  padding: 0 24px;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  margin-bottom: 1px;
  position: relative;
  border-left: 6px solid transparent;

  ${({ child }) =>
    child &&
    `
      margin-left: 24px; 
      height: 40px;
      padding: 0 8px 0 18px;
    `};

  .pf-c-badge {
    color: #151515;
    min-width: 24px;
    font-weight: 500;
  }

  .pf-m-read {
    background: rgba(240, 240, 240, 0.5);
  }

  &:hover {
    background: #262a33;
  }

  &.active {
    border-color: #73bcf7;
    background-color: #4f5255;

    .pf-m-read {
      background-color: #73bcf7;
    }

    ${Divider} {
      display: flex;
    }
  }
`;

export const MenuText = styled.span.attrs({
  className: 'd-flex text-wrap-balance',
})`
  color: #ffffff;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 230px;
`;
