import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { currentUrlParamsSelector } from 'store/urlParams/selectors';

function useChangeLocationSearch() {
  const history = useHistory();
  const location = useLocation();
  const currentUrlParams = useSelector(currentUrlParamsSelector);

  const query = new URLSearchParams(location.search);

  const debouncedRequestFunction = debounce(() => {
    if (Object.keys(currentUrlParams).length) {
      Object.keys(currentUrlParams).forEach(key => query.set(key, currentUrlParams[key]));
      history.replace({
        search: `?${query}`,
        state: history.location.state,
        ...((location.pathname.match(/\//g) || []).length <= 3 &&
        location.pathname.slice(-1) !== '/'
          ? { pathname: `${location.pathname}/` }
          : {}),
      });
    }
  });

  useEffect(() => {
    debouncedRequestFunction();
  }, [currentUrlParams]);
}

export default useChangeLocationSearch;
