import React from 'react';
import { Tooltip } from '@patternfly/react-core';

import { dateTimeConverter, relativeTimeConverter } from 'utils';

function RelativeDate({ date, withSeconds, position = 'top', font }) {
  if (!date) {
    return null;
  }

  return (
    <Tooltip
      position={position}
      content={dateTimeConverter(date)}
      exitDelay={150}
      animationDuration={150}
    >
      <div className={`w-content text-nowrap ${font || ''}`}>
        {relativeTimeConverter(date, withSeconds)}
      </div>
    </Tooltip>
  );
}

export default RelativeDate;
