import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import CheckBoxSelector from 'pages/SystemRouter/SystemReleasesRouter/AddReleasePage/CheckBoxSelector';

import ServicesWithVersionsTable from './ServicesWithVersionsTable';

import { ServicesSelectionWrapper } from '../styles';

function ServicesSelection({
  releaseVersionsData,
  customVersions,
  setCustomVersions,
  allCheckBoxData,
  valudedStep,
}) {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedUuids, setCheckedUuids] = useState([]);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const filterData = [
    {
      id: 'nameQuery',
      name: t('common.name'),
    },
    {
      id: 'codeQuery',
      name: t('common.repositoryCode'),
    },
  ];
  const serviceVersionsValue = watch('serviceVersions');

  const { FilterComponent, filterParams } = useMultiSelectFilterSection(
    filterData,
    'repositoriesWithVersions'
  );

  useEffect(() => {
    if (serviceVersionsValue) {
      const versions = serviceVersionsValue.map(item => !!item.service.uuid && item.service.uuid);

      if (versions.find(version => Boolean(version))) {
        setCheckedUuids(versions);
      }
    }

    setValue('step', 2);
  }, []);

  useEffect(() => {
    if (checkedUuids && customVersions) {
      setValue(
        `serviceVersions`,
        checkedUuids?.map(uuid => ({
          uuid:
            customVersions[uuid]?.uuid ||
            allCheckBoxData.find(service => service.uuid === uuid)?.lastVersion.uuid,
          service: {
            uuid,
          },
        }))
      );
    }
  }, [checkedUuids, customVersions]);

  useEffect(() => () => valudedStep(2), []);

  return (
    <ServicesSelectionWrapper>
      <div>
        <div className="font-20 font-weight-medium my-4 ml-4">{t('common.services')}</div>
        {isShowFilters && (
          <div className="d-flex align-items-baseline ml-4">
            <CheckBoxSelector
              checkedUuids={checkedUuids}
              setCheckedUuids={setCheckedUuids}
              allRenderUuids={allRenderUuids}
              allCheckBoxData={allCheckBoxData?.map(service => service.uuid)}
              dropdownText={t('common.services').toLowerCase()}
            />
            {FilterComponent}
          </div>
        )}
        <ServicesWithVersionsTable
          filterParams={filterParams}
          setAllRenderUuids={setAllRenderUuids}
          checkedUuids={checkedUuids}
          setCheckedUuids={setCheckedUuids}
          customVersions={customVersions}
          setCustomVersions={setCustomVersions}
          releaseVersionsData={releaseVersionsData}
          hasSelectedFilters={allCheckBoxData?.length}
          setIsShowFilters={setIsShowFilters}
          isShowFilters={isShowFilters}
        />
      </div>
    </ServicesSelectionWrapper>
  );
}

export default ServicesSelection;
