import styled from 'styled-components';

export const chartWidth = 1000;

export const LabelIcon = styled.div`
  display: inline-flex;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #6753ac;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  font-size: 12px;
`;
