import styled, { css } from 'styled-components';
import { MultipleFileUploadMain } from '@patternfly/react-core';

import DownloadSVG from 'assets/images/document/download.svg';

const iconStyles = css`
  cursor: pointer;
  margin-left: 10px;
  width: 13px;
  height: 12px;
  border: none;
  background: #8b8d8f;
`;

export const DownloadIcon = styled.button`
  ${iconStyles}
  -webkit-mask-image: url(${DownloadSVG});
  mask-image: url(${DownloadSVG});
`;

export const MultipleFileUploadMainStyled = styled(MultipleFileUploadMain)`
  padding-top: 32px;
  gap: 24px;

  .pf-v5-c-multiple-file-upload__info {
    max-width: 505px !important;
    margin: 0 auto;
  }

  .pf-v5-c-multiple-file-upload__title-text-separator {
    margin: 0 !important;
  }
`;
