import { configureStore, combineReducers } from '@reduxjs/toolkit';

import user from './user/reducers';
import users from './users/reducers';
import systems from './systems/reducers';
import serverTiers from './serverTiers/reducers';
import services from './services/reducers';
import releases from './releases/reducers';
import serviceVersions from './serviceVersions/reducers';
import tiers from './tiers/reducers';
import tierQuotes from './tierQuotes/reducers';
import deployedReleases from './deployedReleases/reducers';
import serviceEndpoints from './serviceEndpoints/reducers';
import notificationsCount from './notificationsCount/reducers';
import documents from './documents/reducers';
import dictionaries from './dictionaries/reducers';
import expandedItems from './expandedItems/reducers';
import urlParams from './urlParams/reducers';
import milestones from './milestones/reducers';
import issues from './issues/reducers';
import integrationSystems from './integrationSystems/reducers';
import serverTierDevice from './serverTierDevice/reducers';
import serviceVersionsBuildResults from './serviceVersionsBuildResults/reducers';
import contactPersons from './contactPersons/reducers';
import staticAnalysisResults from './staticAnalysisResults/reducers';

const rootReducer = combineReducers({
  user,
  users,
  systems,
  serverTiers,
  serverTierDevice,
  services,
  releases,
  serviceVersions,
  tiers,
  tierQuotes,
  deployedReleases,
  serviceEndpoints,
  notificationsCount,
  documents,
  dictionaries,
  expandedItems,
  urlParams,
  milestones,
  issues,
  integrationSystems,
  serviceVersionsBuildResults,
  contactPersons,
  staticAnalysisResults,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
