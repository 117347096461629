import styled from 'styled-components';

export const TitleProgressBlock = styled.div.attrs({
  className: 'd-sm-flex flex-row mt-1 ',
})`
  width: 250px;
  height: 8px;
  background-color: rgba(190, 225, 244, 1);
`;

export const TitleProgressBar = styled.div`
  height: 8px;
  background-color: #0f69c9;

  ${({ progress }) => `width: ${progress ? progress * 2.5 : 0}px`};
`;

export const TitleProgressOverdueBar = styled.div`
  height: 8px;
  background-color: #c71c19;

  ${({ progress }) => `width: ${progress ? progress * 2.5 : 0}px`};
`;
