import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedSystemRoute from 'routes/ProtectedSystemRoute';
import paths from 'constants/paths';
import { scopes } from 'constants/scopes';

import DocumentsPage from './DocumentsPage';
import AddDocumentPage from './AddDocumentPage';

const SystemDocumentsRouter = ({ match: { path } }) => (
  <Switch>
    <ProtectedSystemRoute
      exact
      path={`${path}${paths.systemDocumentsPaths.new}`}
      component={AddDocumentPage}
      scope={scopes.document.documentAdd}
    />

    <Route path={path} component={DocumentsPage} />

    <Redirect to={path} />
  </Switch>
);

export default SystemDocumentsRouter;
