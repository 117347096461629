import React from 'react';

import * as S from './styles';

function SectionHeader({ title, children, withoutBorder = true, infoFont }) {
  return (
    <S.Wrapper withoutBorder={withoutBorder}>
      <S.Title infoFont={infoFont}>{title}</S.Title>
      <S.RowWrapper>{children}</S.RowWrapper>
    </S.Wrapper>
  );
}

export default SectionHeader;
