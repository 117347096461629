import styled from 'styled-components';
import { Dropdown, MenuToggle } from '@patternfly/react-core';

export const DescriptionWrapper = styled.div.attrs({
  className: 'font-12 text-gray-light',
})`
  white-space: break-spaces;
  text-align: start;

  margin-left: ${({ $marginLeft }) => ($marginLeft ? '32px' : '0')};
`;

export const StyledMenuToggle = styled(MenuToggle)`
  max-width: 260px !important;

  .pf-v5-c-menu-toggle {
    padding: 0 !important;
  }

  .pf-v5-c-menu-toggle__controls {
    padding-left: 0;
  }

  .pf-v5-c-menu-toggle__toggle-icon {
    margin-right: 0;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .pf-v5-c-menu__content {
    max-height: 300px !important;
    max-width: 260px !important;
    min-width: max-content;
  }

  .pf-v5-c-menu {
    width: fit-content;
    min-width: fit-content;
  }
`;

export const SpinnerWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center ml-2',
})`
  position: absolute;
  top: -4px;
  left: 0;
  right: -100px;
  bottom: 0;
  z-index: 1;
`;
