import React from 'react';
import PlusCircleIcon from '@patternfly/react-icons/dist/esm/icons/plus-circle-icon';

import { useTranslation } from 'react-i18next';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import { DeleteButton } from 'components/UI/DeleteButton';

import { TextButton } from 'components/UI/Button';

import TierHost from './TierHost';
import * as S from './styles';

function TierHosts({ inputsName }) {
  const { t } = useTranslation();
  const { fieldUuids, addField, deleteField } = useFormDynamicFields(inputsName);

  return (
    <S.HostsBlockWrapper key={inputsName}>
      {fieldUuids.map((fieldUuid, index) => (
        <div key={fieldUuid} className="position-relative mb-4">
          <TierHost
            fieldUuid={fieldUuid}
            indexNumber={index}
            inputsName={inputsName}
            inputsNameValue={`${inputsName}[${index}].value`}
          />
          {fieldUuids.length > 1 && (
            <S.DeleteButtonAbsoluteWrapper withLabel={index > 0}>
              <DeleteButton onClick={() => deleteField(fieldUuid, index)} className="mt-1" />
            </S.DeleteButtonAbsoluteWrapper>
          )}
        </div>
      ))}

      <div className="d-flex">
        <TextButton variant="link" icon={<PlusCircleIcon className="font-16" />} onClick={addField}>
          <span className="font-16">{t('pages.addNewSystemPage.addBaseUrl')}</span>
        </TextButton>
      </div>
    </S.HostsBlockWrapper>
  );
}

export default TierHosts;
