import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Tooltip } from '@patternfly/react-core';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import useModal from 'hooks/useModal';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import PageLayout from 'components/UI/PageLayout';
import Dropdown from 'components/Dropdown';
import CheckBoxSelector from 'components/Common/CheckBoxSelector';
import { BlueButton } from 'components/UI/Button';
import paths from 'constants/paths';

import ServicesTable from './ServicesTable';
import { getServiceStatusNames, getServiceTypeNames } from '../../../../constants';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

function ServicesPage({ withSystemHeader = true }) {
  const { t, i18n } = useTranslation();
  const serviceTypeNames = getServiceTypeNames();
  const serviceStatusNames = getServiceStatusNames();
  const history = useHistory();
  const {
    params: { systemId },
  } = useRouteMatch();
  const editServicesModal = useModal();
  const { checkedPermissions, optionsFilterByScopes } = useUserPermissions();

  const currentSystem = useSelector(currentSystemSelector);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedUuids, setCheckedUuids] = useState([]);
  const [excludedIds, setExcludedIds] = useState([]);
  const [isMassChecked, setIsMassChecked] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const filterData = [
    {
      id: 'nameQuery',
      name: t('common.name'),
    },
    {
      id: 'codeQuery',
      name: t('common.repositoryCode'),
    },
    {
      id: 'type',
      name: t('common.serviceType'),
      type: 'multiSelect',
      options: formatFilterOptions(serviceTypeNames),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(serviceStatusNames),
    },
    {
      id: 'date',
      name: t('common.updatedAt'),
      type: 'date',
    },
  ];
  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'services'
  );
  const buttonFilterChange = id => {
    if (id === 'addServices') {
      history.push(generatePath(paths.systemServicesFullPaths.addServices, {}));
      return;
    }

    history.push(generatePath(paths.systemServicesFullPaths.importServices, {}));
  };

  useEffect(() => {
    if (checkedUuids.length) {
      setCheckedUuids([]);
    }
  }, [systemId]);

  const isCantMassEdit =
    !systemId || (systemId && !checkedPermissions(scopes.service.serviceUpdate));

  const optionsData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'addServices',
          name: t('common.add'),
          scope: scopes.service.serviceCreate,
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
        },
        {
          id: 'importServices',
          name: t('pages.systemAddServicePage.import'),
          scope: scopes.service.serviceImport,
          isDisabled: notActiveSystemMessage,
          tooltip: notActiveSystemMessage,
        },
      ]),
    [optionsFilterByScopes]
  );

  const addServceButton = (
    <>
      {systemId && !!optionsData?.length && (
        <Dropdown
          optionData={optionsData}
          onFilterChange={buttonFilterChange}
          isBlueButtonDropdown
          positionRight
        />
      )}
    </>
  );

  const leftSideContent = isShowFilters && <>{addServceButton}</>;
  const massTotal =
    (currentSystem?.totalSummary?.serviceTotal.total || 0) -
    (currentSystem?.totalSummary?.serviceTotal?.inProgress || 0) -
    (currentSystem?.totalSummary?.serviceTotal?.error || 0) -
    (currentSystem?.totalSummary?.serviceTotal?.archive || 0);

  return (
    <PageLayout
      title={t('common.services')}
      leftSideContent={leftSideContent}
      systemLayout
      withSystemHeader={withSystemHeader}
      baselineTop
      withoutSideMarggin
      withSystemSelector
    >
      {isShowFilters && (
        <div className="d-sm-flex flex-row align-items-end mx-4">
          {!isCantMassEdit && (
            <div className="mb-3">
              <CheckBoxSelector
                checkedUuids={checkedUuids}
                setCheckedUuids={setCheckedUuids}
                allRenderUuids={allRenderUuids}
                total={allRenderUuids.length}
                massTotal={massTotal}
                withoutExcludedIds
                isMassChecked={isMassChecked}
                setIsMassChecked={setIsMassChecked}
                excludedIds={excludedIds}
                setExcludedIds={setExcludedIds}
                dropdownText={t('common.services').toLowerCase()}
              />
            </div>
          )}
          {FilterComponent}
          {(isMassChecked || !!checkedUuids.length) && !isCantMassEdit && (
            <Tooltip
              className={notActiveSystemMessage ? undefined : 'd-none'}
              content={notActiveSystemMessage}
              exitDelay={150}
              animationDuration={150}
            >
              <div className="d-sm-flex flex-row mx-3 mb-3">
                <BlueButton
                  variant="primary"
                  onClick={editServicesModal.toggleModal}
                  style={{ height: '37px' }}
                  isAriaDisabled={notActiveSystemMessage}
                >
                  {t('pages.systemServicesPage.massEdit')}
                </BlueButton>
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <ServicesTable
        filterParams={filterParams}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
        hasSelectedFilters={hasSelectedFilters}
        editServicesModal={editServicesModal}
        checkedUuids={checkedUuids}
        setCheckedUuids={setCheckedUuids}
        setAllRenderUuids={setAllRenderUuids}
        isMassChecked={isMassChecked}
        setIsMassChecked={setIsMassChecked}
        setExcludedIds={setExcludedIds}
        excludedIds={excludedIds}
        isCantMassEdit={isCantMassEdit}
        addButton={addServceButton}
      />
    </PageLayout>
  );
}

export default ServicesPage;
