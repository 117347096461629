import styled from 'styled-components';

import CompleteSVG from 'assets/images/tasks/Complete.svg';

export const CompleteIcon = styled.div`
  display: flex;
  height: 12px;
  width: 16px;
  cursor: pointer;
  background: #3e8635;
  -webkit-mask-image: url(${CompleteSVG});
  mask-image: url(${CompleteSVG});
`;

export const FilterComponentWrapper = styled.div`
  margin-right: 16px;
`;

export const ContentWrapperBlock = styled.div`
  padding: 0;
  margin-left: -2px;
  border-left: solid 3px #0f69c9;
`;
