import React from 'react';
import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListGroup,
  DescriptionListDescription,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkComponent from 'components/UI/LinkComponent';

import ServicesType from '../../ServicesPage/ServicesTable/ServicesType';

import * as S from './styles';

function ServiceInfo({ serviceData }) {
  const { t } = useTranslation();

  if (!serviceData) {
    return null;
  }

  return (
    <div className="m-4 w-75">
      <DescriptionList
        columnModifier={{
          default: '1Col',
        }}
      >
        <div className="d-flex flex-row align-items-baseline gap-4 font-14">
          <div className="d-sm-flex flex-column w-100 gap-4">
            <S.DescriptionListTermWrapper>
              <DescriptionListGroup>
                <DescriptionListTerm>{t('common.repositoryCode')}</DescriptionListTerm>
                <DescriptionListDescription>{serviceData.code || '-'}</DescriptionListDescription>
              </DescriptionListGroup>
            </S.DescriptionListTermWrapper>
            <DescriptionListGroup>
              <DescriptionListTerm>{t('common.description')}</DescriptionListTerm>
              <DescriptionListDescription>
                {serviceData.description || '-'}
              </DescriptionListDescription>
            </DescriptionListGroup>
            <DescriptionListGroup>
              <DescriptionListTerm>
                {t('pages.systemServicePage.buildTemplate')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {serviceData.buildTemplate?.name || '-'}
              </DescriptionListDescription>
            </DescriptionListGroup>
            <DescriptionListGroup>
              <DescriptionListTerm>{t('common.serviceType')}</DescriptionListTerm>
              <DescriptionListDescription>
                {serviceData.type ? (
                  <ServicesType type={serviceData.type} integration={serviceData.integration} />
                ) : (
                  '-'
                )}
              </DescriptionListDescription>
            </DescriptionListGroup>
            <DescriptionListGroup>
              <DescriptionListTerm>{t('pages.systemAddServicePage.port')}</DescriptionListTerm>
              {serviceData.ports?.length ? (
                serviceData.ports.map(item => (
                  <DescriptionListDescription key={item.uuid}>
                    {`${item.transportProtocol} ${item.number}`}
                  </DescriptionListDescription>
                ))
              ) : (
                <DescriptionListDescription>-</DescriptionListDescription>
              )}
            </DescriptionListGroup>
            <DescriptionListGroup>
              <DescriptionListTerm>
                {t('pages.systemAddServicePage.relativePath')}
              </DescriptionListTerm>
              <DescriptionListDescription>{serviceData?.path || '-'}</DescriptionListDescription>
            </DescriptionListGroup>
            <DescriptionListGroup>
              <DescriptionListTerm>{t('common.updatedBy')}</DescriptionListTerm>
              <DescriptionListDescription>
                <LastUpdatedStatusWithLink
                  lastUpdatedBy={serviceData.lastUpdatedBy}
                  lastUpdatedAt={serviceData.lastUpdatedAt}
                />
              </DescriptionListDescription>
            </DescriptionListGroup>
          </div>
          <div className="d-sm-flex flex-column gap-4 w-100 text-wrap-nowrap">
            <DescriptionListGroup>
              <DescriptionListTerm>{t('common.links')}</DescriptionListTerm>
              <DescriptionListDescription>
                {serviceData?.gitlabProjectUrl || serviceData?.sonarProjectUrl ? (
                  <div className="d-sm-flex flex-column">
                    <LinkComponent
                      href={serviceData?.gitlabProjectUrl}
                      text={t('pages.systemTierPage.openInGithub')}
                    />
                    <LinkComponent
                      href={serviceData?.sonarProjectUrl}
                      text={t('pages.systemTierPage.openInSonar')}
                    />
                  </div>
                ) : (
                  '-'
                )}
              </DescriptionListDescription>
            </DescriptionListGroup>
          </div>
        </div>
      </DescriptionList>
    </div>
  );
}

export default ServiceInfo;
