import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchServiceVersionsBuildResults } from 'store/serviceVersionsBuildResults/actions';
import { serviceVersionsBuildResultsSelector } from 'store/serviceVersionsBuildResults/selectors';
import {
  currentServiceVersionIsLoadingSelector,
  currentServiceVersionSelector,
} from 'store/serviceVersions/selectors';
import NewDataTable, { types } from 'components/Common/NewDataTable';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';
import paths from 'constants/paths';
import { buildResultTypeKeys, getBuildResultTypeNames } from 'constants';

import BuildResults from './BuildResults';

function ServiceVersionBuildResults() {
  const { t } = useTranslation();
  const tableRef = useRef();
  const buildResultTypeNames = getBuildResultTypeNames();
  const dispatch = useDispatch();
  const {
    params: { serviceVersionId, systemType, systemId },
  } = useRouteMatch();

  const serviceVersion = useSelector(currentServiceVersionSelector);
  const serviceVersionIsLoading = useSelector(currentServiceVersionIsLoadingSelector);
  const headerData = [
    {
      key: 'type',
      label: t('common.type'),
      withSort: true,
    },
    {
      key: 'result',
      label: t('pages.systemServiceVersionPage.result'),
    },
    {
      key: 'createdAt',
      label: t('common.createDate'),
      withSort: true,
    },
  ];

  const filterData = [
    {
      id: 'type',
      name: t('common.type'),
      type: 'multiSelect',
      options: formatFilterOptions(buildResultTypeNames),
    },
  ];
  const { data, isLoading, total, isError } = useSelector(serviceVersionsBuildResultsSelector);
  const { FilterComponent, filterParams, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'serviceVersionBuildResults'
  );
  const [isShowFilters, setIsShowFilters] = useState(false);

  const requestParams = useMemo(
    () => ({
      orderBy: 'createdAt',
      ascending: false,
      ...filterParams,
      isConcatData: undefined,
    }),
    [filterParams]
  );
  const requestFunction = params =>
    serviceVersionId &&
    params?.orderBy &&
    dispatch(fetchServiceVersionsBuildResults({ ...params, serviceVersionId }));

  useEffect(() => {
    if (serviceVersion?.totalSummary?.buildResultTotal?.total >= 0 && !serviceVersionIsLoading) {
      tableRef.current?.requestDataWithParams({ isSilentMode: true });
    }
  }, [serviceVersion?.totalSummary?.buildResultTotal?.total]);

  const currentTotal = serviceVersion?.totalSummary?.buildResultTotal?.total;

  useEffect(() => {
    if (!isShowFilters || (!currentTotal && systemId)) {
      setIsShowFilters?.(hasSelectedFilters || Boolean(total) || Boolean(currentTotal));
    }
  }, [hasSelectedFilters, total, currentTotal]);

  const getGeneratePath = (type, uuid) => {
    switch (type) {
      case buildResultTypeKeys.UNIT_TESTING:
        return generatePath(paths.systemServiceVersionsFullPaths.unitTestingId, {
          systemId,
          systemType,
          serviceVersionId,
          unitTestingId: uuid,
        });
      case buildResultTypeKeys.STATIC_ANALYSIS:
        return generatePath(paths.systemServiceVersionsFullPaths.staticAnalysisId, {
          systemId,
          systemType,
          serviceVersionId,
          staticAnalysisId: uuid,
        });
      default:
        return generatePath(paths.systemServiceVersionsFullPaths.vulnerabilityId, {
          systemId,
          systemType,
          serviceVersionId,
          vulnerabilityId: uuid,
        });
    }
  };

  const formattedBodyData = data.map(item => ({
    id: item.uuid,
    data: [
      {
        key: 'type',
        content: (
          <LinkWithPermission
            system={item?.system}
            scope={scopes.system.systemViewForm}
            to={
              systemType && item.type
                ? getGeneratePath(buildResultTypeKeys[item.type], item.uuid)
                : undefined
            }
            isTableLink
          >
            <span className="mr-2">{buildResultTypeNames[item.type]}</span>
            {item?.existsProblem && (
              <Tooltip
                content={item.problemDescription}
                position="top"
                exitDelay={150}
                animationDuration={150}
              >
                <ExclamationCircleIcon className="font-14 mr-2 text-red cursor-pointer" />
              </Tooltip>
            )}
          </LinkWithPermission>
        ),
      },
      {
        key: 'result',
        content: <BuildResults totalSummary={item?.totalSummary} type={item.type} />,
      },
      {
        key: 'createdAt',
        type: types.relativeTime,
        content: item.createdAt,
      },
    ],
  }));

  return (
    <div className="mt-4">
      {isShowFilters && <div className="d-sm-flex ml-4">{FilterComponent}</div>}

      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        total={total}
        isLoading={isLoading}
        tableName="serviceVersionBuildResults"
        withStandartPagination
        requestFunction={requestFunction}
        requestParams={requestParams}
        hasSelectedFilters={hasSelectedFilters}
        showNoDataPerFiltersMessage={isShowFilters}
        emptyTableMessage={
          <div className="text-center font-20 font-weight-medium">
            <p>{t('common.tableNoDataMessage')}</p>
            <p>{t('common.tableNoDataMessage2')}</p>
          </div>
        }
        isError={isError}
      />
    </div>
  );
}

export default ServiceVersionBuildResults;
