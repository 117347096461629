import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router';

import { Tab, TabTitleText } from '@patternfly/react-core';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useCardTabs from 'hooks/useCardTabs';
import useModal from 'hooks/useModal';
import useSocketAsync from 'hooks/useSocketAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { fetchTierById } from 'store/tiers/actions';
import {
  currentTierErrorSelector,
  currentTierSelector,
  tiersSelector,
} from 'store/tiers/selectors';
import { clearTierQuotesState } from 'store/tierQuotes/reducers';
import { changeCurrentTier } from 'store/tiers/reducers';
import PageLayout from 'components/UI/PageLayout';
import StatusComponent, { getTierAvailableStatuses } from 'components/Common/StatusComponent';
import SystemDeploymentsTable from 'pages/SystemRouter/SystemDeploymentsPage/SystemDeploymentsTable';
import DocumentsTable from 'pages/SystemRouter/SystemDocumentsRouter/DocumentsPage/DocumentsTable';
import paths from 'constants/paths';
import { tierEventKeys, socketActionKeys, getTierTypeNames } from 'constants';
import TotalSummaryBadge from 'components/Common/TotalSummaryBadge';

import { TabsStyled } from 'pages/SystemRouter/SystemServicesRouter/CurrentServicePage/styles';
import TierInfo from './TierInfo';
import TierQuotes from './TierQuotes';
import TierBackups from './TierBackups';
import TierActions from '../SystemTiersPage/TierActions';
import DeployReleaseForm from '../SystemTiersPage/DeployReleaseForm';
import DeleteTierModal from '../SystemTiersPage/TiersTable/DeleteTierModal';

import * as S from './styles';

function CurrentTierPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const tierAvailableStatuses = getTierAvailableStatuses();
  const tierTypeNames = getTierTypeNames();
  const dispatch = useDispatch();
  const {
    params: { systemId, systemType, tierId },
  } = useRouteMatch();
  const { hasPermissions, checkedPermissions } = useUserPermissions();
  const { currentTab, onSelectTab } = useCardTabs();
  const deployFormModal = useModal();
  const deleteTierModal = useModal();

  const deleteRelease = useAsync();
  const currentTier = useSelector(currentTierSelector);
  const currentTierError = useSelector(currentTierErrorSelector);
  const { isLoading } = useSelector(tiersSelector);
  const [tierBackupsTotal, setTierBackupsTotal] = useState();

  const { state } = history.location;

  const goBack = () =>
    history.push(
      generatePath(paths.systemFullPaths.tiers, {
        systemId,
        systemType,
      })
    );

  const requestFunction = async () => {
    try {
      if (tierId) {
        const newTier = await api.tiers.getTier({
          systemId,
          tierId,
        });
        dispatch(changeCurrentTier(newTier.data));
      }
    } catch (e) {
      if (e) {
        dispatch(changeCurrentTier(null));
      }
    }
  };

  useSocketAsync({
    topic: 'tiers/quotas',
    filterBySystemUuid: systemId,
    filterFn: ({ object }) => object.tier?.uuid === tierId,
    options: { callBackThrottleTime: 1000 },
    onMessage: () => requestFunction(),
  });

  useSocketAsync({
    topic: 'tiers',
    filterFn: ({ object }) => tierId === object?.uuid,
    onMessage: ({ action }) => {
      if (action === socketActionKeys.DELETED) {
        return goBack();
      }

      requestFunction();
    },
  });

  useEffect(() => {
    dispatch(fetchTierById({ systemId, tierId }));
  }, []);

  useEffect(() => {
    if (state?.isOpenQuotes) {
      onSelectTab(state?.isOpenQuotes);
    }
  }, [state?.isOpenQuotes]);

  useEffect(() => {
    if (currentTier && hasPermissions !== undefined && !hasPermissions && !isLoading) {
      history.push(
        generatePath(paths.routePaths.system, {
          systemId,
          systemType,
        })
      );
    }
  }, [currentTier, hasPermissions, isLoading]);

  useEffect(() => {
    dispatch(clearTierQuotesState());
  }, [tierId]);

  useEffect(() => {
    if (deleteRelease.isSuccess) {
      goBack();
    }
  }, [deleteRelease.isSuccess]);

  useEffect(
    () => () => {
      history.replace({ state: {} });
    },
    []
  );

  const isExternal = systemType === paths.systemTypes.external;

  const [isShowFilters, setIsShowFilters] = useState(false);

  const leftSideContent = (
    <div className="mx-4">
      {hasPermissions && (
        <TierActions
          currentTier={currentTier}
          deployFormModal={deployFormModal}
          deleteTierModal={deleteTierModal}
          isBlueButtonDropdown
          requestFunction={goBack}
        />
      )}
    </div>
  );

  const title = (
    <div className="d-flex">
      {currentTier?.code}
      <div className="d-flex flex-row ml-3 title-line-height">
        <StatusComponent
          statusId={currentTier?.status}
          statuses={tierAvailableStatuses}
          objectUuid={currentTier?.uuid}
          isExternalSystem={isExternal}
          objectType="TIER"
        />
        {currentTier?.devTier && <S.DevLabel>{tierTypeNames.dev}</S.DevLabel>}
      </div>
    </div>
  );

  return (
    <PageLayout
      title={title}
      leftSideContent={leftSideContent}
      withoutSidePadding
      withSystemSelector
      alignSelfCenter
      isWaitLoading
      isError={currentTierError}
    >
      <S.TabsWrapper>
        {isExternal || !hasPermissions ? (
          <TabsStyled
            activeKey={currentTab}
            onSelect={onSelectTab}
            aria-label={t('common.authorizationService')}
          >
            <Tab
              eventKey={tierEventKeys.descriptionKey}
              title={<TabTitleText>{t('common.description')}</TabTitleText>}
            >
              <TierInfo
                currentTier={currentTier}
                onSelectTab={onSelectTab}
                isExternal={isExternal}
              />
            </Tab>
          </TabsStyled>
        ) : (
          <TabsStyled
            activeKey={currentTab}
            onSelect={onSelectTab}
            aria-label={t('common.authorizationService')}
          >
            <Tab
              eventKey={tierEventKeys.descriptionKey}
              title={<TabTitleText>{t('common.description')}</TabTitleText>}
            >
              <TierInfo currentTier={currentTier} />
            </Tab>
            <Tab
              eventKey={tierEventKeys.deploymentsKey}
              title={
                <TabTitleText className="d-flex">
                  {t('common.deployments')}
                  <TotalSummaryBadge
                    total={currentTier?.totalSummary?.tierReleaseTotal.total}
                    isSelect={currentTab === tierEventKeys.deploymentsKey}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <SystemDeploymentsTable
                  systemId={systemId}
                  tierId={tierId}
                  currentTotal={currentTier?.totalSummary?.tierReleaseTotal.total}
                />
              </div>
            </Tab>
            <Tab
              eventKey={tierEventKeys.quotesKey}
              title={
                <TabTitleText className="d-flex">
                  {t('pages.adminServerTiers.quotaRequests')}
                  <TotalSummaryBadge
                    total={currentTier?.totalSummary?.quotaTotal.total}
                    isSelect={currentTab === tierEventKeys.quotesKey}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <TierQuotes
                  currentTotal={currentTier?.uuid && currentTier?.totalSummary?.quotaTotal?.total}
                />
              </div>
            </Tab>
            {checkedPermissions(scopes.tier.tierViewBackup) && (
              <Tab
                eventKey={tierEventKeys.backupsKey}
                title={
                  <TabTitleText className="d-flex">
                    {t('common.backup')}
                    <TotalSummaryBadge
                      total={tierBackupsTotal}
                      isSelect={currentTab === tierEventKeys.backupsKey}
                    />
                  </TabTitleText>
                }
              >
                <div className="mt-4">
                  <TierBackups setTierBackupsTotal={setTierBackupsTotal} />
                </div>
              </Tab>
            )}
            <Tab
              eventKey={tierEventKeys.documentsKey}
              title={
                <TabTitleText className="d-flex">
                  {t('common.documents')}
                  <TotalSummaryBadge
                    total={currentTier?.totalSummary?.documentTotal.total}
                    isSelect={currentTab === tierEventKeys.documentsKey}
                  />
                </TabTitleText>
              }
            >
              <div className="mt-4">
                <DocumentsTable
                  setIsShowFilters={setIsShowFilters}
                  isShowFilters={isShowFilters}
                  currentTotal={
                    currentTier?.uuid && currentTier?.totalSummary?.documentTotal?.total
                  }
                />
              </div>
            </Tab>
          </TabsStyled>
        )}
      </S.TabsWrapper>

      <DeployReleaseForm
        {...deployFormModal}
        isDeployToDevAction={deployFormModal.modalData?.isTierDev}
      />

      <DeleteTierModal {...deleteTierModal} />
    </PageLayout>
  );
}

export default CurrentTierPage;
