import styled from 'styled-components';
import { Dropdown } from '@patternfly/react-core/deprecated';

export const DropdownStyled = styled(Dropdown)`
  margin-right: 16px;
  font-size: 16px;
  .pf-v5-c-dropdown__menu {
    background-color: #ffffff;
    padding: 5px 0;
  }

  .pf-v5-c-dropdown__toggle {
    height: 37px;
  }

  .pf-v5-c-dropdown__toggle-check {
    input {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .pf-v5-c-dropdown__menu-item {
    padding: 9px 18px 9px 16px;
  }
`;

export const TextWrapper = styled.span`
  display: inline-block;
  width: 115px;
  cursor: pointer;
`;
