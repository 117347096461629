export const serverTiersActions = {
  edit: 'edit',
};

export const serverTiersPaths = {
  new: 'new',
  show: ':serverTierId',
  edit: `:serverTierId/:action(${serverTiersActions.edit})`,
  newEquipment: `:serverTierId/equipment/new`,
  editEquipment: `:serverTierId/equipment/:equipmentId/:action(${serverTiersActions.edit})`,
};
