import styled from 'styled-components';

import CompleteSVG from 'assets/images/tasks/Complete.svg';
import { BlueButton } from 'components/UI/Button';

export const CompleteButtonWrapper = styled.div`
  min-width: 121px;
`;

export const CompleteButton = styled(BlueButton)`
  display: flex;

  ${({ $isCompletTask }) =>
    $isCompletTask &&
    `
    background-color: #e3f1e1 !important;
    border-color: transparent !important;
  `};
`;

export const CompleteIcon = styled.div.attrs({
  className: 'mr-1',
})`
  height: 12px;
  width: 16px;
  background: #3e8635;
  -webkit-mask-image: url(${CompleteSVG});
  mask-image: url(${CompleteSVG});
`;
