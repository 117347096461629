import { serviceVersionsStatusKeys, serviceVersionsPipelineKeys } from 'constants';

export const tableFiltersToQueryParams = filters => {
  const { pipelineStatus, ...params } = filters;
  const pipeline = pipelineStatus?.filter(item =>
    Object.keys(serviceVersionsPipelineKeys).includes(item)
  );
  const status = pipelineStatus?.filter(item =>
    Object.keys(serviceVersionsStatusKeys).includes(item)
  );

  return {
    ...params,
    pipelineStatus: pipeline,
    status,
  };
};
