import {
  addSystemStorageKey,
  editSystemStorageKey,
  systemActiveStepStorageKey,
  systemEditActiveStepStorageKey,
  editSystemUnfilledStages,
  addSystemUnfilledStages,
} from './keys';
import { steps, systemModel } from './steps';

export default {
  allSteps: steps,
  stepsForEdit: steps.slice(0, 4),
  systemModel,
  editSystemStorageKey,
  addSystemStorageKey,
  editSystemUnfilledStages,
  addSystemUnfilledStages,
  systemActiveStepStorageKey,
  systemEditActiveStepStorageKey,
  stepIndexToSave: 3,
};
