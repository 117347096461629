import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions from 'hooks/useUserPermissions';
import { notificationsCountSelector } from 'store/notificationsCount/selectors';
import { addNotificationsCount } from 'store/notificationsCount/reducers';
import { currentSystemLoadingSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import { compareNotificationByDate } from 'components/Notifications/helpers';
import NotificationTypeIco from 'components/Notifications/NotificationTypeIco';
import C from 'constants/C';

import NotificationItem from './NotificationItem';

import * as S from './styles';

function NotificationList({ systemId, tierId, name, count, mainblockHeight }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { systemType },
  } = useRouteMatch();
  const { hasPermissions } = useUserPermissions();
  const { run, data, isLoading, setData } = useAsync();

  const [expandedIds, setExpandedIds] = useState([]);
  const allNotificationsCount = useSelector(notificationsCountSelector);
  const currentSystemLoading = useSelector(currentSystemLoadingSelector);

  const formattedNotificationsData = useMemo(
    () => compareNotificationByDate({ data }),
    [data?.data, i18n.language]
  );
  const notificationsCount = allNotificationsCount[tierId || systemId];
  const newNotificationsCount = data?.meta.total || count;

  useEffect(() => {
    dispatch(
      addNotificationsCount({
        notificationsCount: newNotificationsCount,
        uuid: tierId || systemId,
      })
    );
  }, [newNotificationsCount, systemId, tierId, data]);

  useEffect(() => {
    if (currentSystemLoading) {
      setData();
    }
  }, [currentSystemLoading]);

  function expandNotification(id) {
    setExpandedIds(prevState => {
      const expandedIndex = prevState.indexOf(id);
      if (expandedIndex !== -1) {
        return prevState.filter((_, index) => index !== expandedIndex);
      }

      return [...prevState, id];
    });
  }

  const getNotifications = () => {
    run(
      api.notifications.getNotifications({
        readType: C.common.notifications.status.NOTREADED,
        tier: tierId,
        system: systemId,
      })
    );
  };

  useEffect(() => {
    if (hasPermissions && systemType === paths.systemTypes.internal) {
      getNotifications();
    }
  }, [hasPermissions, systemId]);

  const generateLink = () => {
    if (systemId && tierId) {
      return generatePath(paths.systemTierFullPaths.notifications, {
        systemId,
        systemType: paths.systemTypes.internal,
        tierId,
      });
    }

    if (systemId) {
      return generatePath(paths.systemFullPaths.notifications, {
        systemId,
        systemType: paths.systemTypes.internal,
      });
    }
  };

  return (
    <div className="bg-white">
      <div className="border-bottom px-4 py-3">
        <h3 className="d-flex justify-content-between">
          <span className="font-weight-semiBold font-14">{name}</span>
          {hasPermissions && systemType === paths.systemTypes.internal && (
            <Link className="font-14" to={generateLink()}>
              {t('components.notificationList.seeAll')}
            </Link>
          )}
        </h3>
      </div>

      <S.NotificationBody $mainblockHeight={mainblockHeight}>
        {(isLoading || currentSystemLoading) && (
          <S.SystemsLoaderWrapper>
            <Loader
              type="TailSpin"
              color="#048E94"
              height={40}
              width={40}
              style={{ AlignSelf: 'center' }}
            />
          </S.SystemsLoaderWrapper>
        )}
        {!isLoading && !currentSystemLoading && (
          <>
            {formattedNotificationsData.map(notifications => (
              <S.NotificationDateBlock key={notifications.date}>
                <S.NotificationDate>{notifications.getTitle(i18n.language)}</S.NotificationDate>
                <S.NotificationList>
                  {notifications.data.map((notification, index) => (
                    <S.NotificationBlock
                      key={notification.id}
                      onClick={() => expandNotification(notification.id)}
                    >
                      {expandedIds.includes(notification.id) ? (
                        <S.NotificationExpanded>
                          <S.NotificationRow>
                            <S.NotificationTime
                              id={`notification_${notifications
                                .getTitle(i18n.language)
                                .replace(/ /g, '_')}_${index + 1}`}
                            >
                              {notification.getTitle(i18n.language)}
                            </S.NotificationTime>
                            <S.NotificationArrow down />
                          </S.NotificationRow>
                          <S.NotificationRow>
                            <NotificationTypeIco icon={notification.icon} />
                            <NotificationItem
                              notification={notification}
                              systemId={systemId}
                              label={`notification_${notifications
                                .getTitle(i18n.language)
                                .replace(/ /g, '_')}_${index + 1}`}
                              systemType={systemType}
                            />
                          </S.NotificationRow>
                        </S.NotificationExpanded>
                      ) : (
                        <>
                          <S.NotificationTime>
                            {notification.getTitle(i18n.language)}
                          </S.NotificationTime>
                          <NotificationTypeIco icon={notification.icon} />
                          <S.NotificationText
                            aria-labelledby={`notification_${notifications
                              .getTitle(i18n.language)
                              .replace(/ /g, '_')}_${index + 1}`}
                          >
                            {notification.text}
                          </S.NotificationText>
                          <S.NotificationArrow />
                        </>
                      )}
                    </S.NotificationBlock>
                  ))}
                </S.NotificationList>
              </S.NotificationDateBlock>
            ))}
            {!notificationsCount && (
              <S.NotificationEmpty>
                <S.NotificationEmptyIco />
                <S.NotificationEmptyText>{t('misc.notifications.EMPTY')}</S.NotificationEmptyText>
              </S.NotificationEmpty>
            )}
          </>
        )}
      </S.NotificationBody>
    </div>
  );
}

export default NotificationList;
