import React, { useEffect, useState } from 'react';
import { Divider, DropdownList, Tooltip } from '@patternfly/react-core';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import * as S from './styles';

function Dropdown({
  optionData,
  onFilterChange,
  buttonLabel,
  isBlueButtonDropdown,
  positionLeft,
  directionTop,
  withoutPadding,
  withoutMarginLeft,
  isDisabled,
  callback,
  splitButtonOptions,
  blueButtonContent,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => setIsOpen(!isOpen);

  const onFocus = () => {
    const element = document.getElementById('toggle-kebab');
    if (element) {
      element?.focus();
    }
  };

  const onSelect = () => {
    setIsOpen(false);
    onFocus();
  };

  useEffect(() => {
    if (callback && isOpen) {
      callback();
    }
  }, [isOpen]);

  const onClick = (e, id) => {
    e.preventDefault();
    onFilterChange(id);
  };

  const menuToggle = toggleRef => (
    <S.StyledMenuToggle
      ref={toggleRef}
      id={isBlueButtonDropdown ? 'toggle-primary' : 'toggle-kebab'}
      aria-label={buttonLabel}
      variant={isBlueButtonDropdown ? 'primary' : 'plain'}
      onClick={onToggle}
      isExpanded={isOpen}
      isDisabled={isDisabled}
      $withoutPadding={withoutPadding || !isBlueButtonDropdown}
      $withoutMarginLeft={withoutMarginLeft}
      $isBlueButtonDropdown={isBlueButtonDropdown}
      splitButtonOptions={
        splitButtonOptions && {
          variant: 'action',
          items: [splitButtonOptions],
        }
      }
    >
      {isBlueButtonDropdown ? (
        !withoutMarginLeft &&
        (blueButtonContent || <span className="ml-1">{t('components.select.actions')}</span>)
      ) : (
        <EllipsisVIcon />
      )}
    </S.StyledMenuToggle>
  );

  const dropdownItems = optionData.map(option => (
    <div key={option.id + option.name}>
      {option.tooltip?.length && option.isDisabled ? (
        <Tooltip
          key={option.id ?? uuid()}
          content={option.tooltip}
          position="left"
          exitDelay={150}
          animationDuration={150}
        >
          <S.DropdownItemStyled
            key={option.id}
            value={option.id}
            onClick={e => onClick(e, option.id)}
            isDisabled={!option.tooltip && option.isDisabled}
            component={!option.href && 'button'}
            href={option.href}
            isAriaDisabled={option.isDisabled}
          >
            {option.name}
          </S.DropdownItemStyled>
        </Tooltip>
      ) : (
        <>
          {option.id === 'separator' ? (
            <Divider component="li" />
          ) : (
            <div>
              <S.DropdownItemStyled
                key={option.id}
                value={option.id}
                onClick={e => onClick(e, option.id)}
                isDisabled={!option.tooltip && option.isDisabled}
                component={!option.href && 'button'}
                href={option.href}
                isAriaDisabled={option.isDisabled}
              >
                {option.name}
              </S.DropdownItemStyled>
            </div>
          )}
        </>
      )}
    </div>
  ));

  return (
    <S.DropdownStyled
      isOpen={isOpen}
      onSelect={onSelect}
      toggle={toggleRef => menuToggle(toggleRef)}
      onOpenChange={open => setIsOpen(open)}
      $withoutMarginLeft={withoutMarginLeft}
      popperProps={{
        position: positionLeft ? 'left' : 'right',
        direction: directionTop ? 'up' : 'down',
      }}
    >
      <DropdownList>{dropdownItems}</DropdownList>
    </S.DropdownStyled>
  );
}

export default Dropdown;
