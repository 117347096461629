import React from 'react';
import { generatePath } from 'react-router';

import paths from 'constants/paths';
import TextBlock from 'components/UI/TextBlock';
import ClipboardCopyComponent from 'components/ClipboardCopyComponent';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import { useTranslation } from 'react-i18next';

function ServerTierDetails({ currentServerTier }) {
  const { t } = useTranslation();

  return (
    <div className="flex-column font-14">
      {currentServerTier?.location && (
        <div className="my-4">
          <TextBlock greyColor title={t('pages.adminServerTiers.location')} id="location">
            <div className="word-wrap-break text-overflow-ellipsis">
              {currentServerTier.location}
            </div>
          </TextBlock>
        </div>
      )}
      {currentServerTier?.consoleUrl && (
        <div className="my-4">
          <TextBlock greyColor title={t('pages.adminAddServerTier.dashboard')} id="consoleUrl">
            <div className="word-wrap-break text-overflow-ellipsis">
              <ClipboardCopyComponent content={currentServerTier.consoleUrl} />
            </div>
          </TextBlock>
        </div>
      )}
      {currentServerTier?.clusterApiUrl && (
        <div className="my-4">
          <TextBlock
            greyColor
            title={t('pages.adminAddServerTier.clusterApiUrl')}
            id="clusterApiUrl"
          >
            <div className="word-wrap-break text-overflow-ellipsis">
              <ClipboardCopyComponent content={currentServerTier.clusterApiUrl} />
            </div>
          </TextBlock>
        </div>
      )}
      {currentServerTier?.lastUpdatedBy && (
        <div className="my-4">
          <TextBlock greyColor title={t('common.updatedBy')} id="lastUpdatedBy">
            <LastUpdatedStatusWithLink
              lastUpdatedBy={currentServerTier.lastUpdatedBy}
              lastUpdatedAt={currentServerTier.lastUpdatedAt}
              linkTo={
                currentServerTier.lastUpdatedBy?.uuid
                  ? generatePath(paths.adminFullPaths.users + paths.adminUsersPaths.details, {
                      userId: currentServerTier.lastUpdatedBy.uuid,
                    })
                  : undefined
              }
            />
          </TextBlock>
        </div>
      )}
    </div>
  );
}

export default ServerTierDetails;
