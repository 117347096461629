import styled from 'styled-components';
import { DatePicker } from '@patternfly/react-core';
import moment from 'moment';
import { dateConverter } from 'utils';

import './styles.css';

export const StyledDatePicker = styled(DatePicker)`
  min-width: 100% !important;
  vertical-align: top;

  .pf-v5-c-date-picker__input {
    .pf-v5-c-input-group {
      height: 37px !important;

      .pf-v5-c-input-group__item {
        &:first-child {
          width: inherit;
        }
      }

      .pf-v5-c-form-control {
        ${({ value, isDisabled }) =>
          value &&
          `
            &::before {
              content: '${dateConverter(moment(value).format())}';
              position: absolute;
              background: ${isDisabled ? '#f0f0f0' : '#fff'};
              top: 0px;
              align-items: center;
              padding-left: 8px;
              padding-top: 4px;
              height: 37px;
              width: 100%;
            }
          `};

        input {
          display: none;
        }
      }

      .pf-v5-c-form-control {
        border-top-color: #f0f0f0;
        border-left-color: #f0f0f0;
        border-right-color: #f0f0f0;
        border-bottom-color: #8a8d90;
        pointer-events: none;
        flex: 1;

        ::placeholder {
          color: #a6aaad;
          opacity: 1;
        }
      }

      .pf-v5-c-button:disabled::after {
        border-top-color: #f0f0f0;
        border-left-color: #f0f0f0;
        border-right-color: #f0f0f0;
        border-bottom-color: #8a8d90;
      }
    }
  }
`;
