import styled from 'styled-components';

import { Popover } from '@patternfly/react-core';

export const PopoverStyled = styled(Popover)`
  max-width: 750px;

  .pf-v5-c-popover__content {
    max-height: 700px;
    overflow: auto;
  }

  .pf-v5-c-title {
    font-weight: 500;
  }

  .pf-v5-c-button {
    ${({ $withTopPadding }) => $withTopPadding && 'top: 18px;'}
  }
`;
