import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLimitsLabels, limitsKeys } from 'constants';
import { convertLimits } from 'utils';

import * as S from './styles';

function ClusterParams({ resource, type, checkedUnit }) {
  const { t } = useTranslation();
  const limitsLabels = getLimitsLabels();
  const formatUsedValue =
    type === limitsKeys.CPU ? parseFloat(resource?.used) * 1000 : resource?.used;
  const formatTotalValue =
    type === limitsKeys.CPU ? parseFloat(resource?.total) * 1000 : resource?.total;

  return (
    <div key={resource?.code}>
      <div className="d-flex font-12 nowrap">
        {limitsLabels[type]} (
        {resource?.used > 0 && resource?.total
          ? ((resource.used / resource.total) * 100).toFixed(0)
          : 0}
        %)
      </div>
      <S.ClusterParamsUse>
        <span>{convertLimits(formatUsedValue, resource?.code, 1, true)}</span>
        {t('pages.adminServerTiers.of')}{' '}
        {convertLimits(formatTotalValue, resource?.code, 1, true, checkedUnit)}
      </S.ClusterParamsUse>
    </div>
  );
}

export default ClusterParams;
