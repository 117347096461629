import React from 'react';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';

const InlineEditButtons = ({ onCancelClick }) => (
  <>
    <TimesIcon className="ml-3 cursor-pointer" onClick={onCancelClick} />
  </>
);

export default InlineEditButtons;
