import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { isDueDateWasExceeded } from 'utils';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { DeleteButton } from 'components/UI/DeleteButton';
import { InputBlockWrapper } from 'components/UI/View';
import { StyledAlert } from 'hooks/useAvailableResources/styles';
import { getDefaultMaxMessage } from 'constants/vaidations';
import { useTranslation } from 'react-i18next';

const dynamicParentField = 'contracts';

const ContractsForm = ({ fieldUuids, fieldUuid, index, deleteField, milestones }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const fieldsDescriptionMap = {
    number: getDefaultMaxMessage(),
    name: t('validation.maxLength', [300]),
  };
  const isStartDateValue = watch(`${dynamicParentField}[${index}].startDate`);
  const isEndDateValue = watch(`${dynamicParentField}[${index}].endDate`);
  const contractValue = watch(`${dynamicParentField}[${index}]`);
  const isContractsValue = watch(`${dynamicParentField}`);

  const isOverlapContractDates = watch(`overlapContractDates`);

  const overlapContractDates = isContractsValue.filter(
    item =>
      (isDueDateWasExceeded() && item.endDate > isStartDateValue) ||
      (item.startDate < isEndDateValue && item.endDate > isEndDateValue)
  );

  useEffect(() => {
    if (isStartDateValue > isEndDateValue) {
      setValue(`${dynamicParentField}[${index}].endDate`, undefined);
    }
  }, [isStartDateValue, isEndDateValue]);

  useEffect(() => {
    if (overlapContractDates.length && !isOverlapContractDates) {
      setValue(`overlapContractDates`, true);
    }
    if (!overlapContractDates.length && isOverlapContractDates) {
      setValue(`overlapContractDates`, false);
    }
  }, [overlapContractDates.length]);

  const warningText = useMemo(() => {
    const values = milestones.map(milestone => `"${milestone.name}"`);

    if (milestones.length) {
      return (
        <StyledAlert
          variant="warning"
          isInline
          title={`${t('pages.addNewSystemPage.contractEditDisabledTooltip')}
      ${values.join(', ')}`}
        />
      );
    }
  }, [milestones]);

  useEffect(() => {
    if (milestones.length) {
      const defaultContract = milestones?.find(
        item => item.contract?.uuid === contractValue?.uuid
      )?.contract;

      if (defaultContract?.uuid) {
        setValue(`${dynamicParentField}[${index}]`, { ...defaultContract });
      }
    }
  }, [milestones?.length]);

  return (
    <div className="d-sm-flex flex-column border p-4 gap-4" key={fieldUuid}>
      <div className="d-flex flex-row justify-content-between">
        {t('common.contract')} {index + 1}
        {fieldUuids.length > 1 && milestones.length === 0 && (
          <DeleteButton onClick={() => deleteField(fieldUuid, index)}>
            {t('pages.addNewSystemPage.deleteContract')}
          </DeleteButton>
        )}
      </div>

      <InputComponent
        label={t('pages.addNewSystemPage.contractNumber')}
        name={`${dynamicParentField}[${index}].number`}
        disabled={milestones.length > 0}
        description={fieldsDescriptionMap.number}
        maxLength={150}
      />
      <InputBlockWrapper columnsCount={2}>
        <InputComponent
          label={t('common.startDate')}
          name={`${dynamicParentField}[${index}].startDate`}
          component={inputComponents.dateInput}
          disabled={milestones.length > 0}
        />
        <InputComponent
          label={t('common.endDate')}
          name={`${dynamicParentField}[${index}].endDate`}
          component={inputComponents.dateInput}
          minDate={isStartDateValue}
          disabled={milestones.length > 0}
        />
      </InputBlockWrapper>
      <InputComponent
        label={t('pages.addNewSystemPage.workName')}
        name={`${dynamicParentField}[${index}].description`}
        disabled={milestones.length > 0}
        description={fieldsDescriptionMap.name}
        maxLength={300}
      />
      {warningText}
    </div>
  );
};

export default ContractsForm;
