import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'constants/paths';
import AdminRoute from 'routes/AdminRoute';
import { scopes } from 'constants/scopes';

import UsersPage from './UsersPage';
import CurrentUserPage from './CurrentUserPage';
import UserCreatePage from './UserCreatePage';

const UsersRouter = ({ match: { path } }) => (
  <Switch>
    <AdminRoute
      exact
      path={`${path}${paths.adminUsersPaths.new}`}
      component={UserCreatePage}
      scope={scopes.user.userAddToSystem}
    />

    <AdminRoute
      exact
      path={`${path}${paths.adminUsersPaths.details}`}
      component={CurrentUserPage}
      scope={scopes.platform.userViewForm}
    />

    <AdminRoute
      exact
      path={`${path}${paths.adminUsersPaths.assignUsers}`}
      component={UserCreatePage}
      scope={scopes.user.userAddToSystem}
    />

    <Route exact path={path} component={UsersPage} />

    <Redirect to={path} />
  </Switch>
);

export default UsersRouter;
