import http from 'api/config';

const getAllReleases = params => http.get(`releases`, { params });

const getReleasesOptions = params => http.get(`releases/options`, { params });

const getRelease = uuid => http.get(`releases/${uuid}`);

const createRelease = body => http.post(`releases`, body);

const editRelease = (releaseUuid, body) => http.put(`releases/${releaseUuid} `, body);

const sendToApprovalRelease = uuid => http.post(`releases/${uuid}/request-approval`);

const declineRelease = (uuid, params) => http.post(`releases/${uuid}/cancel`, params);

const approveRelease = uuid => http.post(`releases/${uuid}/approve`);

const deployRelease = (uuid, params) => http.post(`releases/${uuid}/deploy`, params);

const deleteRelease = uuid => http.delete(`releases/${uuid}`);

const getReleaseIssues = (uuid, params) => http.get(`releases/${uuid}/issues`, { params });

const getReleaseVersions = (uuid, params) => http.get(`releases/${uuid}/versions`, { params });

export default {
  getAllReleases,
  getReleasesOptions,
  createRelease,
  editRelease,
  sendToApprovalRelease,
  declineRelease,
  approveRelease,
  deployRelease,
  getRelease,
  deleteRelease,
  getReleaseIssues,
  getReleaseVersions,
};
