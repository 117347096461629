import { useTranslation } from 'react-i18next';

import React, { useMemo } from 'react';
import useFormDynamicFields from 'hooks/useFormDynamicFields';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { DeleteButton } from 'components/UI/DeleteButton';
import { InputBlockWrapper } from 'components/UI/View';
import AddButton from 'components/UI/AddButton';

import { phoneValidation, emailValidation, getContactsDescriptionMap } from 'constants/vaidations';
import { DeleteButtonAbsoluteWrapper } from '../../../styles';

function Representative({ parentField }) {
  const { t } = useTranslation();
  const contactsDescriptionMap = getContactsDescriptionMap();
  const dynamicParentField = `${parentField}.representatives`;
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField);
  const phoneValidationRules = useMemo(() => phoneValidation, []);
  const emailValidationRules = useMemo(() => emailValidation, []);

  return (
    <div className="d-sm-flex flex-column gap-4">
      {t('common.representatives')}
      {fieldUuids.map((key, index) => (
        <InputBlockWrapper columnsCount={3} key={key}>
          <InputComponent
            label={t('common.fio')}
            name={`${dynamicParentField}[${index}].name`}
            maxLength={150}
            description={contactsDescriptionMap.name}
            isTextInput
          />
          <InputComponent
            label={t('common.phone')}
            name={`${dynamicParentField}[${index}].phone`}
            component={inputComponents.phoneInput}
            description={contactsDescriptionMap.phone}
            validationRules={phoneValidationRules}
          />
          <InputComponent
            label={t('common.email')}
            name={`${dynamicParentField}[${index}].email`}
            maxLength={300}
            description={contactsDescriptionMap.email}
            placeholder="___@___.__"
            validationRules={emailValidationRules}
          />

          {fieldUuids.length > 1 && (
            <DeleteButtonAbsoluteWrapper>
              <DeleteButton onClick={() => deleteField(key, index)} />
            </DeleteButtonAbsoluteWrapper>
          )}
        </InputBlockWrapper>
      ))}
      <div className="d-flex mb-3">
        <AddButton onClick={addField}>{t('pages.addNewSystemPage.addRepresentative')}</AddButton>
      </div>
    </div>
  );
}

export default Representative;
