import React from 'react';
import { Badge } from '@patternfly/react-core';

function TotalSummaryBadge({ total = 0, isSelect }) {
  return (
    <Badge className="ml-1" isRead={!isSelect}>
      {total}
    </Badge>
  );
}

export default TotalSummaryBadge;
