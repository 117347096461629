import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const serverTiersSlice = createSlice({
  name: 'serverTiers',
  initialState: {
    data: [],
    isLoading: true,
    total: 0,
    isError: null,
    currentServerTier: {},
    currentServerTierLoading: true,
    currentServerTierError: null,
  },
  reducers: {
    resetCurrentServerTier: state => {
      state.currentServerTier = {};
      state.currentServerTierLoading = false;
    },
    changeCurrentServerTier: (state, { payload }) => {
      state.currentServerTier = payload;
      state.currentServerTierLoading = false;
    },
    clearServerTiersState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
      state.isError = null;
    },
  },
  extraReducers: {
    [actions.fetchServerTiers.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchServerTiers.fulfilled]: (state, { payload: { data, meta, isConcatData } }) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchServerTiers.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
    [actions.fetchServerTier.pending]: state => {
      state.currentServerTier = {};
      state.currentServerTierLoading = true;
      state.currentServerTierError = null;
    },
    [actions.fetchServerTier.fulfilled]: (state, { payload }) => {
      state.currentServerTier = payload;
      state.currentServerTierLoading = false;
      state.currentServerTierError = null;
    },
    [actions.fetchServerTier.rejected]: (state, { error }) => {
      state.currentServerTierLoading = false;

      if (checkedServerError(error?.message)) {
        state.currentServerTierError = error.message;
      }
    },
  },
});

export const { changeCurrentServerTier, resetCurrentServerTier, clearServerTiersState } =
  serverTiersSlice.actions;

export default serverTiersSlice.reducer;
