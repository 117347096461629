import { systemTypes } from './newSystemPaths';

export const routePaths = {
  main: '/',
  error: '/error',
  accessError: '/access-error',
  help: '/help',
  documents: '/help/documents',
  system: `/systems/:systemType(${systemTypes.internal}|${systemTypes.external})/:systemId/`,
  newSystem: '/new-system/',
  admin: '/admin/',
  systems: '/systems/',
  monitoring: '/monitoring',
};
