import styled, { css } from 'styled-components';

import CanceledSVG from 'assets/images/statuses/canceled.svg';
import RunningSVG from 'assets/images/system_page/Deploying.svg';
import PendingSVG from 'assets/images/statuses/pending.svg';
import JobSuccessSVG from 'assets/images/pipeline/success.svg';
import JobSuccessHoverSVG from 'assets/images/pipeline/success-hover.svg';
import JobManualSVG from 'assets/images/pipeline/manual.svg';
import JobManualHoverSVG from 'assets/images/pipeline/manual-hover.svg';
import JobFailedSVG from 'assets/images/pipeline/failed.svg';
import JobFailedHoverSVG from 'assets/images/pipeline/failed-hover.svg';
import JobCanceledSVG from 'assets/images/pipeline/canceled.svg';
import JobCanceledHoverSVG from 'assets/images/pipeline/canceled-hover.svg';
import JobWarningSVG from 'assets/images/pipeline/warning.svg';
import JobWarningHoverSVG from 'assets/images/pipeline/warning-hover.svg';
import JobPendingSVG from 'assets/images/pipeline/pending.svg';
import JobPendingHoverSVG from 'assets/images/pipeline/pending-hover.svg';
import JobRunningSVG from 'assets/images/pipeline/running.svg';
import JobRunningHoverSVG from 'assets/images/pipeline/running-hover.svg';
import JobCreatedSVG from 'assets/images/pipeline/created.svg';
import JobCreatedHoverSVG from 'assets/images/pipeline/created-hover.svg';
import JobSkippedSVG from 'assets/images/pipeline/skipped.svg';
import JobSkippedHoverSVG from 'assets/images/pipeline/skipped-hover.svg';
import JobScheduledSVG from 'assets/images/pipeline/scheduled.svg';
import JobScheduledHoverSVG from 'assets/images/pipeline/scheduled-hover.svg';

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 15px;
  background: ${({ background }) => background};
  border-radius: 24px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  color: ${({ color }) => color};
`;

export const StatusWithTooltip = styled.span.attrs({
  className: 'text-white font-12 px-2 text-nowrap',
})`
  background: ${({ background }) => background};
  border-radius: 22px;
  line-height: 18px;
`;

export const StatusWrapper = styled.span`
  line-height: 21px;
`;

export const ContentWrapper = styled.div`
  ${({ $isSystemList }) => $isSystemList && 'min-width: 190px;'}
`;

export const Circle = styled.div`
  display: inline-flex;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  background: ${({ background }) => background};
`;

export const Text = styled.div`
  font-size: 11.5px;
  font-weight: 400;
  white-space: nowrap;
`;

export const StatusWithoutIconWrapper = styled.div.attrs({
  className: 'py-1 pr-2 text-gray-light',
})`
  display: inline-flex;
  border-radius: 24px;
  align-items: center;
`;

export const StatusTooltipWrapper = styled.span.attrs({
  className: 'd-flex mr-2',
})`
  padding-top: 1px;

  ${({ baselineAlign }) =>
    baselineAlign &&
    `
      width: 33px;
      display: flex;
      align-self: baseline;
      padding-top: 4px;
    `}
`;

export const StatusText = styled.div`
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  color: #151515;
  white-space: nowrap;
`;

const DeployStatusWrapper = styled.div`
  color: #151515;
  height: 13px;
  width: 13px;
`;

export const CanceledIco = styled(DeployStatusWrapper)`
  display: inline-flex;
  background: #3c3f42;
  -webkit-mask-image: url(${CanceledSVG});
  mask-image: url(${CanceledSVG});
`;

export const RunningIco = styled(DeployStatusWrapper)`
  display: inline-flex;
  background: #009596;
  transform: matrix(1, 0, 0, -1, 0, 0);
  -webkit-mask-image: url(${RunningSVG});
  mask-image: url(${RunningSVG});
`;

export const PendingIco = styled(DeployStatusWrapper)`
  background: #c48b1f;
  -webkit-mask-image: url(${PendingSVG});
  mask-image: url(${PendingSVG});
`;

export const IconStatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ $withPointerCursor }) => $withPointerCursor && 'cursor: pointer;'}
`;

const JobIconStyles = css`
  background-position: center !important;
  width: 24px;
  height: 24px;
`;

export const JobScheduledIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobScheduledSVG}) no-repeat;

  &:hover {
    background: url(${JobScheduledHoverSVG}) no-repeat;
  }
`;

export const JobCanceledIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobCanceledSVG}) no-repeat;

  &:hover {
    background: url(${JobCanceledHoverSVG}) no-repeat;
  }
`;

export const JobFailedIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobFailedSVG}) no-repeat;

  &:hover {
    background: url(${JobFailedHoverSVG}) no-repeat;
  }
`;

export const JobWarningIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobWarningSVG}) no-repeat;

  &:hover {
    background: url(${JobWarningHoverSVG}) no-repeat;
  }
`;

export const JobPendingIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobPendingSVG}) no-repeat;

  &:hover {
    background: url(${JobPendingHoverSVG}) no-repeat;
  }
`;

export const JobRunningIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobRunningSVG}) no-repeat;

  &:hover {
    background: url(${JobRunningHoverSVG}) no-repeat;
  }
`;

export const JobManualIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobManualSVG}) no-repeat;

  &:hover {
    background: url(${JobManualHoverSVG}) no-repeat;
  }
`;

export const JobSuccessIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobSuccessSVG}) no-repeat;

  &:hover {
    background: url(${JobSuccessHoverSVG}) no-repeat;
  }
`;

export const JobCreatedIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobCreatedSVG}) no-repeat;

  &:hover {
    background: url(${JobCreatedHoverSVG}) no-repeat;
  }
`;

export const JobSkippedIcon = styled.div`
  ${JobIconStyles}
  background: url(${JobSkippedSVG}) no-repeat;

  &:hover {
    background: url(${JobSkippedHoverSVG}) no-repeat;
  }
`;
