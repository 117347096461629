import { createSlice } from '@reduxjs/toolkit';
import { checkedServerError } from 'utils';

import * as actions from './actions';

const deployedReleasesSlice = createSlice({
  name: 'deployedReleases',
  initialState: { data: [], isLoading: true, total: 0, isError: null },
  reducers: {
    clearDeployedReleasesState: state => {
      state.data = [];
      state.total = 0;
      state.isLoading = true;
    },
  },
  extraReducers: {
    [actions.fetchDeployedReleases.pending]: state => {
      state.isLoading = true;
      state.isError = null;
    },
    [actions.fetchDeployedReleases.fulfilled]: (
      state,
      { payload: { data, meta, isConcatData } }
    ) => {
      state.data = isConcatData ? [...state.data, ...data] : data;
      state.isLoading = false;
      state.total = meta.total;
      state.isError = null;
    },
    [actions.fetchDeployedReleases.rejected]: (state, { error }) => {
      state.isLoading = false;

      if (checkedServerError(error.message)) {
        state.isError = true;
        state.data = [];
        state.total = 0;
      }
    },
  },
});

export const { clearDeployedReleasesState } = deployedReleasesSlice.actions;

export default deployedReleasesSlice.reducer;
