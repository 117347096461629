import http from 'api/config';

const getContactPersons = ({ systemId, ...params }) =>
  http.get(`systems/${systemId}/contact-persons`, { params });

const getAllContactPersons = systemId => http.get(`systems/${systemId}/contact-persons/all`);

export default {
  getContactPersons,
  getAllContactPersons,
};
