import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchDictionaries = createAsyncThunk(
  'dictionaries/fetchDictionaries',
  async params => {
    const response = await api.dictionaries.getDictionaries(params);
    return response.data;
  }
);
