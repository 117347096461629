import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReleaseBlockWrapper } from '../styles';

function Documents({ UploadButtonComponent }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('step', 4);
  }, []);

  return (
    <ReleaseBlockWrapper>
      <div className="font-20 font-weight-medium mb-4">{t('common.documents')}</div>

      {UploadButtonComponent}
    </ReleaseBlockWrapper>
  );
}

export default Documents;
