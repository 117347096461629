import http from 'api/config';

const getAllBackups = params => http.get(`backups/`, { params });
const getBackups = params => http.get(`backups/components`, { params });

const getBackupComponent = uuid => http.get(`backups/components/${uuid}`);

const changeComponentsFrequency = (componentUuid, data) =>
  http.patch(`backups/components/${componentUuid}`, data);

export default {
  getBackups,
  getAllBackups,
  getBackupComponent,
  changeComponentsFrequency,
};
