import styled from 'styled-components';
import { Wizard } from '@patternfly/react-core';

export const WizardStyled = styled(Wizard).attrs({
  className: 'border-top',
})`
  .pf-v5-svg {
    width: 15px !important;
    height: 15px;
  }

  .pf-v5-c-select__menu {
    max-height: 300px;
  }

  .pf-v5-c-wizard__main {
    z-index: auto;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
  }

  .pf-v5-c-wizard__outer-wrap {
    z-index: 1 !important;
    min-height: auto;
  }

  .pf-v5-c-wizard__toggle {
    z-index: 2 !important;
  }

  .pf-v5-c-wizard__footer {
    z-index: 10;
    padding: 24px 16px 16px 24px !important;
    flex-direction: row-reverse;
    align-self: baseline;

    .pf-m-primary {
      ${({ $isLastStep }) => $isLastStep && 'display: none !important;'}
    }

    .pf-v5-c-wizard__footer-cancel {
      display: none;
    }

    button {
      margin-right: 8px;
      margin-bottom: 16px;
    }
  }

  .pf-v5-c-wizard__nav {
    width: 260px;
  }

  .pf-v5-c-wizard__outer-wrap {
    padding-left: 260px;
  }

  .pf-v5-c-wizard__nav-link {
    span:nth-child(2n) {
      margin-left: 0px !important;
    }
  }

  .pf-v5-c-wizard__nav-link::before {
    background-color: #909caa;
    color: #fff;
  }

  .pf-m-current.pf-v5-c-wizard__nav-link::before {
    background-color: #0066a1;
    color: #fff;
  }

  .pf-m-current {
    color: #0066a1 !important;
  }
`;

export const WizardBlockWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-column gap-4',
})`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '700px')};
`;

export const SaveButtonWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: -84px;
  right: 24px;
`;

export const CanselButtonWrapper = styled.div`
  position: absolute;
  z-index: 11;
  bottom: -60px;
  margin-left: 185px;

  ${({ $isBackHidden }) => $isBackHidden && 'margin-left: 100px;'}
  ${({ $isLastStep }) => $isLastStep && 'margin-left: 90px;'}
`;

export const NavItem = styled.span`
  .pf-m-current span {
    color: #0066a1 !important;
  }
`;

export const SystemBlockWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-column gap-4',
})`
  width: 700px;
`;

export const DeleteButtonAbsoluteWrapper = styled.div`
  position: absolute;
  right: -37px;
  top: 33px;
`;
