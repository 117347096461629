import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useTreeViewMultiSelectFilterSection from 'hooks/useTreeViewMultiSelectFilterSection';
import { importServiceSelector } from 'store/services/selectors';
import CheckBoxSelector from 'components/Common/CheckBoxSelector';
import { sortList } from 'utils';

import SelectedRepositoriesTable from './SelectedRepositoriesTable';

import { ServicesSelectionWrapper } from '../styles';

function RepositoriesSelection({ excludedIds, setExcludedIds, isMassChecked, setIsMassChecked }) {
  const { t } = useTranslation();
  const { setValue, watch, getValues } = useFormContext();
  const [isShowFilters, setIsShowFilters] = useState(false);
  const group = watch('group');
  const totalSummary = group?.totalSummary;

  const filterData = [
    {
      id: 'language',
      name: t('common.programmingLanguage'),
      type: 'treeMultiSelect',
      children: sortList(Object.keys(totalSummary?.languagesTotal || {})).map(key => ({
        id: key,
        name: key?.length ? key : t('common.notAssigned'),
        parentId: 'language',
        number: totalSummary?.languagesTotal[key],
      })),
    },
    {
      type: 'select',
      options: [
        { id: 'nameQuery', name: t('common.name') },
        { id: 'codeQuery', name: t('common.repositoryCode') },
      ],
    },
  ];

  const { filterParams, FilterComponent, ChipGroupComponent, hasSelectedFilters } =
    useTreeViewMultiSelectFilterSection(filterData);

  const serviceImportSelect = useAsync();
  const getImportService = useSelector(importServiceSelector);
  const checkedRepositoriesValue = watch('checkedRepositories');
  const groupUuidValue = watch('groupUuid');
  const [isTotal, setIsTotal] = useState();
  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedRepositoriesUuids, setCheckedRepositoriesUuids] = useState(
    checkedRepositoriesValue.map(item => item.uuid) || []
  );

  useEffect(() => {
    setValue('step', 2);
  }, []);

  useEffect(() => {
    if (checkedRepositoriesUuids) {
      setValue(
        `checkedRepositories`,
        checkedRepositoriesUuids.map(item => ({
          uuid: item,
        }))
      );
    }
  }, [checkedRepositoriesUuids]);

  useEffect(() => {
    if (getImportService.totalSummary?.repositoryTotal) {
      setIsTotal(getImportService.totalSummary.repositoryTotal.total);
    }
  }, [getImportService.totalSummary?.repositoryTotal]);

  useEffect(
    () => () => {
      const checkedRepositories = getValues('checkedRepositories');
      const isMassCheckedValue = getValues('isMassChecked');
      const excludedIdsValue = getValues('excludedIds');

      serviceImportSelect.run(
        api.repositories.selectRepositories(
          groupUuidValue,
          !isMassCheckedValue
            ? { repositories: checkedRepositories }
            : {
                excludeRepositories: excludedIdsValue.map(uuid => ({
                  uuid,
                })),
              }
        )
      );
    },
    []
  );

  return (
    <ServicesSelectionWrapper>
      <div className="font-20 font-weight-medium mt-4 mb-3 ml-4">
        {t('pages.systemImportServicePage.repositoryList')}
      </div>
      {isShowFilters && (
        <div className="d-flex mx-4 align-items-start">
          <div className="d-flex align-items-end">
            <div className="mb-3">
              <CheckBoxSelector
                checkedUuids={checkedRepositoriesValue}
                setCheckedUuids={setCheckedRepositoriesUuids}
                allRenderUuids={allRenderUuids}
                total={allRenderUuids.length}
                massTotal={isTotal}
                withoutExcludedIds
                isMassChecked={isMassChecked}
                setIsMassChecked={setIsMassChecked}
                excludedIds={excludedIds}
                withouClearMassCheck
                dropdownText={t('common.repositories')}
              />
            </div>
            {FilterComponent}
          </div>
          {ChipGroupComponent}
        </div>
      )}
      <SelectedRepositoriesTable
        filterParams={filterParams}
        checkedRepositoriesUuids={checkedRepositoriesUuids}
        setCheckedRepositoriesUuids={setCheckedRepositoriesUuids}
        setAllRenderUuids={setAllRenderUuids}
        isMassChecked={isMassChecked}
        setExcludedIds={setExcludedIds}
        excludedIds={excludedIds}
        hasSelectedFilters={hasSelectedFilters}
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
      />
    </ServicesSelectionWrapper>
  );
}

export default RepositoriesSelection;
