import i18n from '../i18n';

const { t } = i18n;

export const getTooltipMessage = () => ({
  inProgressMessage: t('constants.tooltips.inProgressMessage'),
  requiredField: t('constants.tooltips.requiredField'),
});

export const getReleasesTooltip = () => ({
  releaseVersionError: t('constants.tooltips.releaseVersionError'),
});

export const getSystemNameInfoMessage = () => t('constants.tooltips.systemNameInfoMessage');

export const getSystemShortNameInfoMessage = () =>
  t('constants.tooltips.systemShortNameInfoMessage');

export const getSystemCodeInfoMessage = () => t('constants.tooltips.systemCodeInfoMessage');

export const getSystemDataBaseInfoMessage = () => t('constants.tooltips.systemDataBaseInfoMessage');

export const getSystemLanguageInfoMessage = () => t('constants.tooltips.systemLanguageInfoMessage');

export const getSystemOSInfoMessage = () => t('constants.tooltips.systemOSInfoMessage');

export const getSystemUserInfoMessage = () => t('constants.tooltips.systemUserInfoMessage');

export const getSystemHaveUpdetedTierMessage = () =>
  t('constants.tooltips.systemHaveUpdetedTierMessage');

export const getSystemHaveUpdetedUserMessage = () =>
  t('constants.tooltips.systemHaveUpdetedUserMessage');

export const getTierBaseUrlInfoMessage = () => t('constants.tooltips.tierBaseUrlInfoMessage');

export const getTierIsDevInfoMessage = () => t('constants.tooltips.tierIsDevInfoMessage');

export const getVertionTagInfoMessage = () => t('constants.tooltips.vertionTagInfoMessage');

export const getVertionDescriptionInfoMessage = () =>
  t('constants.tooltips.vertionDescriptionInfoMessage');

export const getMilestoneNameInfoMessage = () => t('constants.tooltips.MilestoneNameInfoMessage');

export const getProgressSystemMessage = () => t('constants.tooltips.progressSystemMessage');

export const getProgressServerTierMessage = () => t('constants.tooltips.progressServerTierMessage');

export const getProgressTierMessage = () => t('constants.tooltips.progressTierMessage');

export const getTierHaveDeployMessage = () => t('constants.tooltips.tierHaveDeployMessage');

export const getQuotaInProgressMessage = () => t('constants.tooltips.quotaInProgressMessage');

export const getRequireApprovalMessage = () => t('constants.tooltips.requireApprovalMessage');

export const getSubmitForQuotaApprovalUnavailable = () =>
  t('constants.tooltips.submitForQuotaApprovalUnavailable');

export const getServerTierWarningMessage = () => t('constants.tooltips.serverTierWarningMessage');

export const getProgressServiceMessage = () => t('constants.tooltips.progressServiceMessage');

export const getNotActiveServiceMessage = () => t('constants.tooltips.notActiveServiceMessage');

export const getNotHaveContratsMessage = () => t('constants.tooltips.notHaveContratsMessage');

export const getProgressServiceVersionMessage = () =>
  t('constants.tooltips.progressServiceVersionMessage');
