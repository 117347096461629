import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import useStorage from 'hooks/useStorage';
import paths from 'constants/paths';

import * as styles from './styles';

const BackButton = ({ withoutSidePadding, withMarginBottom, backLink }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { storageData, clearStorageData } = useStorage('historyLength');

  useEffect(() => {
    if (storageData && history.length !== storageData) {
      clearStorageData();
    }
  }, [history.length, storageData]);

  const onBackButtonClick = () => {
    if (backLink) {
      return history.replace(backLink);
    }

    if (history.length < 3) {
      return history.replace(paths.routePaths.main);
    }

    if (storageData && history.length < 4) {
      clearStorageData();
      return history.replace(paths.routePaths.main);
    }

    if (storageData) {
      clearStorageData();
      return history.go(-2);
    }

    return history.goBack();
  };

  return (
    <styles.BackButton
      withoutSidePadding={withoutSidePadding}
      withMarginBottom={withMarginBottom}
      onClick={onBackButtonClick}
    >
      {t('components.backButton.back')}
    </styles.BackButton>
  );
};

export default BackButton;
