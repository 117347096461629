import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { logout, getUserName } from 'hooks/useKeycloakAuth';

import * as S from './styles';

import { availableLanguages } from '../../../constants';

function TopMenu({ setIsShowLeftMenu }) {
  const { t, i18n } = useTranslation();
  const onMenuIconClick = () => {
    setIsShowLeftMenu(prevState => !prevState);
  };
  const onLogout = () => {
    logout({ redirectUri: window.location.origin });
  };
  const onSetLanguage = language => {
    i18n.changeLanguage(language);
    moment.locale(language);
  };

  return (
    <S.Wrapper>
      <S.MenuBlock>
        <S.MenuIco onClick={onMenuIconClick} />
        <S.Logo to="/">{t('misc.main.NAME')}</S.Logo>
      </S.MenuBlock>
      <S.RightBlock>
        <S.LanguageList>
          {availableLanguages.map(language => (
            <button
              key={language.key}
              className={
                i18n.language.startsWith(language.key) ? 'text-white' : 'text-gray-tooltip'
              }
              onClick={() => onSetLanguage(language.key)}
            >
              {language.label}
            </button>
          ))}
        </S.LanguageList>
        <S.Name>{getUserName()}</S.Name>
        <S.ExitBlock>
          <S.ExitText onClick={onLogout}>{t('misc.authorization.EXIT')}</S.ExitText>
        </S.ExitBlock>
      </S.RightBlock>
    </S.Wrapper>
  );
}

export default TopMenu;
