import React from 'react';
import InProgressIcon from '@patternfly/react-icons/dist/esm/icons/in-progress-icon';
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import ServerGroupIcon from '@patternfly/react-icons/dist/esm/icons/server-group-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';

import { ReactComponent as SuccessSVG } from 'assets/images/pipeline/success.svg';
import { ReactComponent as ErrorSVG } from 'assets/images/statuses/error.svg';
import { ReactComponent as PendingSVG } from 'assets/images/pipeline/pending.svg';
import { ReactComponent as SystemPendingSVG } from 'assets/images/pipeline/system-pending.svg';

import {
  quoteStatusKeys,
  serviceStatusKeys,
  releaseStatusKeys,
  systemStatusKeys,
  tierStatusKeys,
  backupStatusKeys,
  issueStatusKeys,
  serviceVersionsPipelineKeys,
  serviceStageStatusKeys,
  serviceJobStatusKeys,
  releasesDeployStatusKeys,
  userStatusKeys,
  milestoneStatusKeys,
  serverTierStatusKeys,
  intervalMethodKeys,
  componentsStatusKeys,
  serviceVersionsStatusKeys,
  systemTypesKeys,
  roleStatusKeys,
  vulnerabilitySeverityKeys,
  qualityGateKeys,
  unitTestingStatusKeys,
  vulnerabilityProbabilityKeys,
  staticAnalysisSeverityKeys,
  getStaticAnalysisSeverityNames,
  getVulnerabilityProbabilityStatusNames,
  getTierStatusNames,
  getIssueStatusNames,
  getMilestoneStatusNames,
  getReleaseStatusNames,
  getBackupStatusNames,
  getServerTierStatusNames,
  getQuoteStatusNames,
  getServiceStatusNames,
  getSystemStatusNames,
  getSystemTypesNames,
  getReleasesDeployStatusNames,
  getServiceVersionsStatusNames,
  getServiceVersionsPipelineNames,
  getServiceJobStatusNames,
  getServiceStageStatusNames,
  getUserStatusNames,
  getRoleStatusNames,
  getIntervalMethodNames,
  getUnitTestingStatusNames,
  getVulnerabilitySeverityNames,
  getQualityGateNames,
  getRepositoryStatusNames,
  repositoryStatusKeys,
} from 'constants/index';

import * as S from './styles';
import i18n from '../../../i18n';

const { t } = i18n;

export const getQuotesStatuses = () => {
  const quoteStatusNames = getQuoteStatusNames();

  return {
    [quoteStatusKeys.SET]: {
      text: quoteStatusNames.SET,
      color: 'cyan',
    },
    [quoteStatusKeys.REJECTED]: {
      text: quoteStatusNames.REJECTED,
      color: 'red',
    },
    [quoteStatusKeys.ERROR]: {
      text: quoteStatusNames.ERROR,
      color: 'red',
    },
    [quoteStatusKeys.NEW]: {
      text: quoteStatusNames.NEW,
      color: 'blue',
    },
    [quoteStatusKeys.IN_PROGRESS]: {
      text: quoteStatusNames.IN_PROGRESS,
      color: 'blue',
    },
  };
};

export const getServerTierAvailableStatuses = () => {
  const serverTierStatusNames = getServerTierStatusNames();

  return {
    [serverTierStatusKeys.NEW]: {
      text: serverTierStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.serverTierStatusShortNames.NEW'),
      background: '#004080',
    },
    [serverTierStatusKeys.ACTIVE]: {
      text: serverTierStatusNames.ACTIVE,
      color: 'green',
      smallText: t('constants.serverTierStatusShortNames.ACTIVE'),
      background: '#3E8635',
    },
    [serverTierStatusKeys.UPDATING_IN_PROGRESS]: {
      text: serverTierStatusNames.UPDATING_IN_PROGRESS,
      color: 'blue',
      smallText: t('constants.serverTierStatusShortNames.UPDATING_IN_PROGRESS'),
      background: '#004080',
    },
    [serverTierStatusKeys.ERROR]: {
      text: serverTierStatusNames.ERROR,
      color: 'red',
      smallText: t('constants.serverTierStatusShortNames.ERROR'),
      background: '#C9190B',
    },
    [serverTierStatusKeys.ARCHIVE]: {
      text: serverTierStatusNames.ARCHIVE,
      color: 'grey',
      smallText: t('constants.serverTierStatusShortNames.ARCHIVE'),
      background: 'grey',
    },
    [serverTierStatusKeys.ARCHIVE_IN_PROGRESS]: {
      text: serverTierStatusNames.ARCHIVE_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serverTierStatusShortNames.ARCHIVE_IN_PROGRESS'),
      background: 'grey',
    },
    [serverTierStatusKeys.RESTORING_IN_PROGRESS]: {
      text: serverTierStatusNames.RESTORING_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serverTierStatusShortNames.RESTORING_IN_PROGRESS'),
      background: 'grey',
    },
    [serverTierStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: serverTierStatusNames.REMOVAL_IN_PROGRESS,
      color: 'grey',
      smallText: 'У',
      background: 'grey',
    },
  };
};

export const getServiceAvailableStatuses = () => {
  const serviceStatusNames = getServiceStatusNames();

  return {
    [serviceStatusKeys.NEW]: {
      text: serviceStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.serviceStatusShortNames.NEW'),
      background: '#004080',
    },
    [serviceStatusKeys.ACTIVE]: {
      text: serviceStatusNames.ACTIVE,
      color: 'green',
      smallText: t('constants.serviceStatusShortNames.ACTIVE'),
      background: 'green',
    },
    [serviceStatusKeys.UPDATING_IN_PROGRESS]: {
      text: serviceStatusNames.UPDATING_IN_PROGRESS,
      color: 'blue',
      smallText: t('constants.serviceStatusShortNames.UPDATING_IN_PROGRESS'),
      background: '#004080',
    },
    [serviceStatusKeys.ARCHIVE]: {
      text: serviceStatusNames.ARCHIVE,
      color: 'grey',
      smallText: t('constants.serviceStatusShortNames.ARCHIVE'),
      background: 'grey',
    },
    [serviceStatusKeys.ERROR]: {
      text: serviceStatusNames.ERROR,
      color: 'red',
      smallText: t('constants.serviceStatusShortNames.ERROR'),
      background: '#c9190b',
    },
    [serviceStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: serviceStatusNames.REMOVAL_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serviceStatusShortNames.REMOVAL_IN_PROGRESS'),
      background: 'grey',
    },
    [serviceStatusKeys.ARCHIVE_IN_PROGRESS]: {
      text: serviceStatusNames.ARCHIVE_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serviceStatusShortNames.ARCHIVE_IN_PROGRESS'),
      background: 'grey',
    },
    [serviceStatusKeys.RESTORING_IN_PROGRESS]: {
      text: serviceStatusNames.RESTORING_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serviceStatusShortNames.RESTORING_IN_PROGRESS'),
      background: 'grey',
    },
  };
};

export const getRepositoryAvailableStatuses = () => {
  const repositoryStatusNames = getRepositoryStatusNames();

  return {
    [repositoryStatusKeys.NEW]: {
      text: repositoryStatusNames.NEW,
      color: 'red',
    },
    [repositoryStatusKeys.READY]: {
      text: repositoryStatusNames.READY,
      color: 'green',
    },
  };
};

export const getReleasesAvailableStatuses = () => {
  const releaseStatusNames = getReleaseStatusNames();

  return {
    [releaseStatusKeys.NEW]: {
      text: releaseStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.releaseStatusShortNames.NEW'),
      background: '#004080',
    },
    [releaseStatusKeys.READY]: {
      text: releaseStatusNames.READY,
      color: 'orange',
      smallText: t('constants.releaseStatusShortNames.READY'),
      background: 'orange',
    },
    [releaseStatusKeys.APPROVED]: {
      text: releaseStatusNames.APPROVED,
      color: 'green',
      smallText: t('constants.releaseStatusShortNames.APPROVED'),
      background: 'green',
    },
    [releaseStatusKeys.CANCELLED]: {
      text: releaseStatusNames.CANCELLED,
      color: 'red',
      smallText: t('constants.releaseStatusShortNames.CANCELLED'),
      background: '#c9190b',
    },
  };
};

export const getBackupStatuses = () => {
  const backupStatusNames = getBackupStatusNames();

  return {
    [backupStatusKeys.SUCCESS]: {
      text: backupStatusNames.SUCCESS,
      color: 'green',
    },
    [backupStatusKeys.OVERDUE]: {
      text: backupStatusNames.OVERDUE,
      color: 'red',
    },
    [backupStatusKeys.ERROR]: {
      text: backupStatusNames.ERROR,
      color: 'red',
    },
  };
};

export const getSystemStatuses = () => {
  const systemStatusNames = getSystemStatusNames();
  const systemTypesNames = getSystemTypesNames();
  const serviceVersionsStatusNames = getServiceVersionsStatusNames();

  return {
    [systemStatusKeys.NEW]: {
      text: systemStatusNames.NEW,
      icon: <InProgressIcon />,
      color: 'blue',
      smallText: t('constants.systemStatusShortNames.NEW'),
      background: '#004080',
    },
    [systemStatusKeys.ACTIVE]: {
      text: systemStatusNames.ACTIVE,
      icon: <CheckCircleIcon />,
      color: 'green',
      smallText: t('constants.systemStatusShortNames.ACTIVE'),
      background: 'green',
    },
    [systemStatusKeys.UPDATING_IN_PROGRESS]: {
      text: systemStatusNames.UPDATING_IN_PROGRESS,
      icon: <InProgressIcon />,
      color: 'blue',
      smallText: t('constants.systemStatusShortNames.UPDATING_IN_PROGRESS'),
      background: '#004080',
    },
    [systemStatusKeys.ERROR]: {
      text: systemStatusNames.ERROR,
      icon: <ExclamationCircleIcon />,
      color: 'red',
      smallText: t('constants.systemStatusShortNames.ERROR'),
      background: '#c9190b',
    },
    [systemStatusKeys.ARCHIVE]: {
      text: systemStatusNames.ARCHIVE,
      icon: <ServerGroupIcon className="font-12 text-gray-medium" />,
      color: 'grey',
      smallText: t('constants.systemStatusShortNames.ARCHIVE'),
      background: 'grey',
    },
    [systemStatusKeys.ARCHIVE_IN_PROGRESS]: {
      text: systemStatusNames.ARCHIVE_IN_PROGRESS,
      icon: <ServerGroupIcon className="font-12 text-gray-medium" />,
      color: 'grey',
      smallText: t('constants.systemStatusShortNames.ARCHIVE_IN_PROGRESS'),
      background: 'grey',
    },
    [systemStatusKeys.RESTORING_IN_PROGRESS]: {
      text: systemStatusNames.RESTORING_IN_PROGRESS,
      icon: <ServerGroupIcon className="font-12 text-gray-medium" />,
      color: 'grey',
      smallText: t('constants.systemStatusShortNames.RESTORING_IN_PROGRESS'),
      background: 'grey',
    },
    [systemStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: serviceVersionsStatusNames.REMOVAL_IN_PROGRESS,
      icon: <ServerGroupIcon className="font-12 text-gray-medium" />,
      color: 'grey',
      smallText: t('constants.systemStatusShortNames.REMOVAL_IN_PROGRESS'),
      background: 'grey',
    },
    [systemTypesKeys.EXTERNAL]: {
      text: systemTypesNames.EXTERNAL,
      icon: <InfoCircleIcon />,
      color: 'purple',
      smallText: t('constants.systemStatusShortNames.EXTERNAL'),
      background: 'purple',
    },
  };
};

export const getTierAvailableStatuses = () => {
  const tierStatusNames = getTierStatusNames();

  return {
    [tierStatusKeys.PENDING]: {
      text: tierStatusNames.PENDING,
      color: 'blue',
      smallText: t('constants.tierStatusShortNames.PENDING'),
      background: '#004080',
    },
    [tierStatusKeys.NEW]: {
      text: tierStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.tierStatusShortNames.NEW'),
      background: '#004080',
    },
    [tierStatusKeys.ACTIVE]: {
      text: tierStatusNames.ACTIVE,
      color: 'green',
      smallText: t('constants.tierStatusShortNames.ACTIVE'),
      background: 'green',
    },
    [tierStatusKeys.ARCHIVE]: {
      text: tierStatusNames.ARCHIVE,
      color: 'grey',
      smallText: t('constants.tierStatusShortNames.ARCHIVE'),
      background: 'grey',
    },
    [tierStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: tierStatusNames.REMOVAL_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.tierStatusShortNames.REMOVAL_IN_PROGRESS'),
      background: 'grey',
    },
    [tierStatusKeys.ARCHIVE_IN_PROGRESS]: {
      text: tierStatusNames.ARCHIVE_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.tierStatusShortNames.ARCHIVE_IN_PROGRESS'),
      background: 'grey',
    },
    [tierStatusKeys.RESTORING_IN_PROGRESS]: {
      text: tierStatusNames.RESTORING_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.tierStatusShortNames.RESTORING_IN_PROGRESS'),
      background: 'grey',
    },
    [tierStatusKeys.ERROR]: {
      text: tierStatusNames.ERROR,
      color: 'red',
      smallText: t('constants.tierStatusShortNames.ERROR'),
      background: '#C9190B',
    },
  };
};

export const getMilestoneStatus = () => {
  const milestoneStatusNames = getMilestoneStatusNames();

  return {
    [milestoneStatusKeys.OPEN]: {
      text: milestoneStatusNames.OPEN,
      color: 'blue',
      smallText: t('constants.milestoneStatusSortNames.OPEN'),
      background: '#2B9AF3',
    },
    [milestoneStatusKeys.OVERDUE]: {
      text: milestoneStatusNames.OVERDUE,
      color: 'red',
      smallText: t('constants.milestoneStatusSortNames.OVERDUE'),
      background: '#c9190b',
    },
    [milestoneStatusKeys.CLOSED]: {
      text: milestoneStatusNames.CLOSED,
      color: 'green',
      smallText: t('constants.milestoneStatusSortNames.CLOSED'),
      background: 'green',
    },
  };
};

export const getTasksStatuses = () => {
  const issueStatusNames = getIssueStatusNames();

  return {
    [issueStatusKeys.NEW]: {
      text: issueStatusNames.NEW,
      color: 'purple',
    },
    [issueStatusKeys.IN_PROGRESS]: {
      text: issueStatusNames.IN_PROGRESS,
      color: 'blue',
    },
    [issueStatusKeys.IMPLEMENTED]: {
      text: issueStatusNames.IMPLEMENTED,
      color: 'orange',
    },
    [issueStatusKeys.DONE]: {
      text: issueStatusNames.DONE,
      color: 'cyan',
    },
  };
};

export const getReleasesDeployStatus = () => {
  const releasesDeployStatusNames = getReleasesDeployStatusNames();

  return {
    [releasesDeployStatusKeys.DEPLOYED]: {
      text: releasesDeployStatusNames.DEPLOYED,
      color: 'green',
    },
    [releasesDeployStatusKeys.ERROR]: {
      text: releasesDeployStatusNames.ERROR,
      color: 'red',
    },
    [releasesDeployStatusKeys.IN_PROGRESS]: {
      text: releasesDeployStatusNames.IN_PROGRESS,
      color: 'blue',
    },
  };
};

export const getServiceVersionsStatuses = () => {
  const serviceVersionsStatusNames = getServiceVersionsStatusNames();

  return {
    [serviceVersionsStatusKeys.NEW]: {
      text: serviceVersionsStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.serviceVersionsStatusShortNames.NEW'),
      background: '#004080',
    },
    [serviceVersionsStatusKeys.ACTIVE]: {
      text: serviceVersionsStatusNames.ACTIVE,
      color: 'blue',
      smallText: t('constants.serviceVersionsStatusShortNames.NEW'),
      background: 'blue',
    },
    [serviceVersionsStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: serviceVersionsStatusNames.REMOVAL_IN_PROGRESS,
      color: 'grey',
      smallText: t('constants.serviceVersionsStatusShortNames.REMOVAL_IN_PROGRESS'),
      background: 'grey',
    },
    [serviceVersionsStatusKeys.ERROR]: {
      text: serviceVersionsStatusNames.ERROR,
      color: 'red',
      smallText: t('constants.serviceVersionsStatusShortNames.ERROR'),
      background: '#C9190B',
    },
    [serviceVersionsStatusKeys.PROBLEM_FOUND]: {
      text: serviceVersionsStatusNames.PROBLEM_FOUND,
      color: 'red',
      smallText: t('constants.serviceVersionsStatusShortNames.PROBLEM_FOUND'),
      background: '#C9190B',
    },
  };
};

export const getServiceVersionsPipelineStatuses = () => {
  const serviceVersionsPipelineNames = getServiceVersionsPipelineNames();

  return {
    [serviceVersionsPipelineKeys.WAITING_FOR_RESOURCE]: {
      text: serviceVersionsPipelineNames.WAITING_FOR_RESOURCE,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.WAITING_FOR_RESOURCE'),
      background: 'grey',
    },
    [serviceVersionsPipelineKeys.CREATED]: {
      text: serviceVersionsPipelineNames.CREATED,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.CREATED'),
      background: 'grey',
    },
    [serviceVersionsPipelineKeys.SCHEDULED]: {
      text: serviceVersionsPipelineNames.SCHEDULED,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.SCHEDULED'),
      background: 'grey',
    },
    [serviceVersionsPipelineKeys.MANUAL]: {
      text: serviceVersionsPipelineNames.MANUAL,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.MANUAL'),
      background: 'grey',
    },
    [serviceVersionsPipelineKeys.PREPARING]: {
      text: serviceVersionsPipelineNames.PREPARING,
      color: 'orange',
      smallText: t('constants.serviceVersionsPipelineShortNames.PREPARING'),
      background: 'orange',
    },
    [serviceVersionsPipelineKeys.PENDING]: {
      text: serviceVersionsPipelineNames.PENDING,
      color: 'orange',
      smallText: t('constants.serviceVersionsPipelineShortNames.PENDING'),
      background: 'orange',
    },
    [serviceVersionsPipelineKeys.RUNNING]: {
      text: serviceVersionsPipelineNames.RUNNING,
      color: 'orange',
      smallText: t('constants.serviceVersionsPipelineShortNames.RUNNING'),
      background: 'orange',
    },
    [serviceVersionsPipelineKeys.SUCCESS]: {
      text: serviceVersionsPipelineNames.SUCCESS,
      color: 'green',
      smallText: t('constants.serviceVersionsPipelineShortNames.SUCCESS'),
      background: 'green',
    },
    [serviceVersionsPipelineKeys.FAILED]: {
      text: serviceVersionsPipelineNames.FAILED,
      color: 'red',
      smallText: t('constants.serviceVersionsPipelineShortNames.FAILED'),
      background: '#C9190B',
    },
    [serviceVersionsPipelineKeys.CANCELED]: {
      text: serviceVersionsPipelineNames.CANCELED,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.CANCELED'),
      background: 'grey',
    },
    [serviceVersionsPipelineKeys.SKIPPED]: {
      text: serviceVersionsPipelineNames.SKIPPED,
      color: 'grey',
      smallText: t('constants.serviceVersionsPipelineShortNames.SKIPPED'),
      background: 'grey',
    },
  };
};

export const getServiceJobsStatus = () => {
  const serviceJobStatusNames = getServiceJobStatusNames();

  return {
    [serviceJobStatusKeys.CANCELLED]: {
      text: serviceJobStatusNames.CANCELLED,
      icon: <S.JobCanceledIcon />,
    },
    [serviceJobStatusKeys.FAILED]: {
      text: serviceJobStatusNames.FAILED,
      icon: <S.JobFailedIcon />,
    },
    [serviceJobStatusKeys.WARNING]: {
      text: serviceJobStatusNames.WARNING,
      icon: <S.JobWarningIcon />,
    },
    [serviceJobStatusKeys.PENDING]: {
      text: serviceJobStatusNames.PENDING,
      icon: <S.JobPendingIcon />,
    },
    [serviceJobStatusKeys.SCHEDULED]: {
      text: serviceJobStatusNames.SCHEDULED,
      icon: <S.JobScheduledIcon />,
    },
    [serviceJobStatusKeys.RUNNING]: {
      text: serviceJobStatusNames.RUNNING,
      icon: <S.JobRunningIcon />,
    },
    [serviceJobStatusKeys.MANUAL]: {
      text: serviceJobStatusNames.MANUAL,
      icon: <S.JobManualIcon />,
    },
    [serviceJobStatusKeys.SUCCESS]: {
      text: serviceJobStatusNames.SUCCESS,
      icon: <S.JobSuccessIcon />,
    },
    [serviceJobStatusKeys.CREATED]: {
      text: serviceJobStatusNames.CREATED,
      icon: <S.JobCreatedIcon />,
    },
    [serviceJobStatusKeys.SKIPPED]: {
      text: serviceJobStatusNames.SKIPPED,
      icon: <S.JobSkippedIcon />,
    },
  };
};

export const getServiceStagesStatus = () => {
  const serviceStageStatusNames = getServiceStageStatusNames();

  return {
    [serviceStageStatusKeys.SUCCESS]: {
      text: serviceStageStatusNames.SUCCESS,
      icon: <S.JobSuccessIcon />,
    },
    [serviceStageStatusKeys.RUNNING]: {
      text: serviceStageStatusNames.RUNNING,
      icon: <S.JobRunningIcon />,
    },
    [serviceStageStatusKeys.MANUAL]: {
      text: serviceStageStatusNames.MANUAL,
      icon: <S.JobManualIcon />,
    },
    [serviceStageStatusKeys.CREATED]: {
      text: serviceStageStatusNames.CREATED,
      icon: <S.JobCreatedIcon />,
    },
    [serviceStageStatusKeys.SCHEDULED]: {
      text: serviceStageStatusNames.SCHEDULED,
      icon: <S.JobScheduledIcon />,
    },
    [serviceStageStatusKeys.PENDING]: {
      text: serviceStageStatusNames.PENDING,
      icon: <S.JobPendingIcon />,
    },
    [serviceStageStatusKeys.FAILED]: {
      text: serviceStageStatusNames.FAILED,
      icon: <S.JobFailedIcon />,
    },
    [serviceStageStatusKeys.CANCELLED]: {
      text: serviceStageStatusNames.CANCELLED,
      icon: <S.JobCanceledIcon />,
    },
    [serviceStageStatusKeys.SKIPPED]: {
      text: serviceStageStatusNames.SKIPPED,
      icon: <S.JobSkippedIcon />,
    },
  };
};

export const getUserStatus = () => {
  const userStatusNames = getUserStatusNames();

  return {
    [userStatusKeys.ACTIVE]: {
      text: userStatusNames.ACTIVE,
      color: 'green',
      smallText: t('constants.userStatusShortNames.ACTIVE'),
      background: 'green',
    },
    [userStatusKeys.INACTIVE]: {
      text: userStatusNames.INACTIVE,
      color: 'grey',
      smallText: t('constants.userStatusShortNames.INACTIVE'),
      background: 'grey',
    },
    [userStatusKeys.ERROR]: {
      text: userStatusNames.ERROR,
      color: 'red',
      smallText: t('constants.userStatusShortNames.ERROR'),
      background: '#C9190B',
    },
    [userStatusKeys.UPDATING_IN_PROGRESS]: {
      text: userStatusNames.UPDATING_IN_PROGRESS,
      color: 'orange',
      smallText: t('constants.userStatusShortNames.UPDATING_IN_PROGRESS'),
      background: 'orange',
    },
    [userStatusKeys.NEW]: {
      text: userStatusNames.NEW,
      color: 'blue',
      smallText: t('constants.userStatusShortNames.NEW'),
      background: 'blue',
    },
  };
};

export const getRoleStatus = () => {
  const roleStatusNames = getRoleStatusNames();

  return {
    [roleStatusKeys.PENDING]: {
      text: roleStatusNames.PENDING,
      color: 'blue',
    },
    [roleStatusKeys.NEW]: {
      text: roleStatusNames.NEW,
      color: 'blue',
    },
    [roleStatusKeys.ACTIVE]: {
      text: roleStatusNames.ACTIVE,
      color: 'green',
    },
    [roleStatusKeys.REMOVAL_IN_PROGRESS]: {
      text: roleStatusNames.REMOVAL_IN_PROGRESS,
      color: 'grey',
    },
    [roleStatusKeys.ERROR]: {
      text: roleStatusNames.ERROR,
      color: 'red',
    },
    [roleStatusKeys.UPDATING_IN_PROGRESS]: {
      text: roleStatusNames.UPDATING_IN_PROGRESS,
      color: 'orange',
    },
  };
};

export const getIntervalSettingMethod = () => {
  const intervalMethodNames = getIntervalMethodNames();

  return {
    [intervalMethodKeys.AUTOMATICALLY]: {
      text: intervalMethodNames.AUTOMATICALLY,
      smallText: t('constants.intervalMethodShortNames.AUTOMATICALLY'),
      background: '#0066CC',
    },
    [intervalMethodKeys.MANUALLY]: {
      text: intervalMethodNames.MANUALLY,
      smallText: t('constants.intervalMethodShortNames.MANUALLY'),
      background: '#009596',
    },
  };
};

export const systemComponentsStatusIcons = {
  [componentsStatusKeys.ACTIVE]: {
    icon: <SuccessSVG className="mr-2" />,
  },
  [componentsStatusKeys.IN_PROGRESS]: {
    icon: <SystemPendingSVG className="mr-2" />,
  },
  [componentsStatusKeys.ERROR]: {
    icon: <ErrorSVG className="mr-2" />,
  },
};

export const componentsStatusIcons = {
  [componentsStatusKeys.ACTIVE]: {
    icon: <SuccessSVG className="mr-2" />,
  },
  [componentsStatusKeys.IN_PROGRESS]: {
    icon: <PendingSVG className="mr-2" />,
  },
  [componentsStatusKeys.ERROR]: {
    icon: <ErrorSVG className="mr-2" />,
  },
};

export const getUnitTestingStatuses = () => {
  const unitTestingStatusNames = getUnitTestingStatusNames();

  return {
    [unitTestingStatusKeys.SKIPPED]: {
      text: unitTestingStatusNames.SKIPPED,
      color: 'grey',
    },
    [unitTestingStatusKeys.PASSED]: {
      text: unitTestingStatusNames.PASSED,
      color: 'green',
    },
    [unitTestingStatusKeys.FAILURE]: {
      text: unitTestingStatusNames.FAILURE,
      color: 'orange',
    },
    [unitTestingStatusKeys.ERROR]: {
      text: unitTestingStatusNames.ERROR,
      color: 'red',
    },
  };
};

export const getVulnerabilitiesStatuses = () => {
  const vulnerabilitySeverityNames = getVulnerabilitySeverityNames();

  return {
    [vulnerabilitySeverityKeys.LOW]: {
      text: vulnerabilitySeverityNames.LOW,
      color: 'grey',
    },
    [vulnerabilitySeverityKeys.MEDIUM]: {
      text: vulnerabilitySeverityNames.MEDIUM,
      color: 'gold',
    },
    [vulnerabilitySeverityKeys.HIGH]: {
      text: vulnerabilitySeverityNames.HIGH,
      color: 'orange',
    },
    [vulnerabilitySeverityKeys.CRITICAL]: {
      text: vulnerabilitySeverityNames.CRITICAL,
      color: 'red',
    },
  };
};

export const getQualityGateStatuses = () => {
  const qualityGateNames = getQualityGateNames();

  return {
    [qualityGateKeys.OK]: {
      text: qualityGateNames.OK,
      color: 'green',
    },
    [qualityGateKeys.WARN]: {
      text: qualityGateNames.WARN,
      color: 'orange',
    },
    [qualityGateKeys.ERROR]: {
      text: qualityGateNames.ERROR,
      color: 'red',
    },
  };
};

export const getSeverityStatuses = () => {
  const staticAnalysisSeverityNames = getStaticAnalysisSeverityNames();

  return {
    [staticAnalysisSeverityKeys.BLOCKER]: {
      text: staticAnalysisSeverityNames.BLOCKER,
      color: 'red',
    },
    [staticAnalysisSeverityKeys.CRITICAL]: {
      text: staticAnalysisSeverityNames.CRITICAL,
      color: 'red',
    },
    [staticAnalysisSeverityKeys.MAJOR]: {
      text: staticAnalysisSeverityNames.MAJOR,
      color: 'orange',
    },
    [staticAnalysisSeverityKeys.MINOR]: {
      text: staticAnalysisSeverityNames.MINOR,
      color: 'grey',
    },
    [staticAnalysisSeverityKeys.INFO]: {
      text: staticAnalysisSeverityNames.INFO,
      color: 'purple',
    },
  };
};

export const getVulnerabilityProbabilityStatuses = () => {
  const vulnerabilityProbabilityStatusNames = getVulnerabilityProbabilityStatusNames();

  return {
    [vulnerabilityProbabilityKeys.HIGH]: {
      text: vulnerabilityProbabilityStatusNames.HIGH,
      color: 'red',
    },
    [vulnerabilityProbabilityKeys.MEDIUM]: {
      text: vulnerabilityProbabilityStatusNames.MEDIUM,
      color: 'orange',
    },
    [vulnerabilityProbabilityKeys.LOW]: {
      text: vulnerabilityProbabilityStatusNames.LOW,
      color: 'grey',
    },
  };
};
