import React, { useEffect } from 'react';

import api from 'api';
import WarningModal from 'components/Common/WarningModal';
import { useTranslation } from 'react-i18next';

function DeleteServiceVertionForm({
  isModalVisible,
  toggleModal,
  modalData,
  setModalData,
  deleteServiceVersion,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (deleteServiceVersion.isSuccess) {
      setModalData('');
      toggleModal();
    }
  }, [deleteServiceVersion.isSuccess]);

  return (
    <WarningModal
      title={t('pages.systemServiceVersionPage.deletingServiceVersion')}
      text={t('pages.systemServiceVersionPage.deleteServiceVersion')}
      label={t('pages.systemServiceVersionPage.serviceVersionName')}
      isLoading={deleteServiceVersion.isLoading}
      onClick={() =>
        deleteServiceVersion.run(api.serviceVersion.deleteServiceVersion(modalData.uuid))
      }
      name={modalData?.tag}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    />
  );
}

export default DeleteServiceVertionForm;
