import React, { useMemo } from 'react';

import TiersTable from './TiersTable';

function ServerTierStands({ currentTotal }) {
  const requestParams = useMemo(
    () => ({
      orderBy: 'name',
      ascending: true,
    }),
    []
  );

  return (
    <div className="mt-4">
      <TiersTable filterParams={requestParams} currentTotal={currentTotal} />
    </div>
  );
}

export default ServerTierStands;
