import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { isBoolean } from 'lodash';

import useURLQueryParams from 'hooks/Location/useURLQueryParams';

const defaultPaginationParams = {
  limit: 10,
  offset: 0,
};

const defaultParams = {
  isConcatData: false,
  q: undefined,
};

const defaultSortParams = {
  ascending: undefined,
  orderBy: undefined,
};

function useTableSearchParams(
  requestFunction,
  requestParams,
  tableName,
  withInfinitePagination,
  withWebsockets
) {
  const paginationUrlName = tableName ? `${tableName}-pagination` : '';
  const sortUrlName = tableName ? `${tableName}-sort` : '';
  const [params, setParams] = useState(null);
  const [isTableGetParams, setIsTableGetParams] = useState(false);
  const [paginationParams, setPaginationParams] = useState(null);
  const [sortParams, setSortParams] = useState(defaultSortParams);
  const { setQueryParams, getQueryByName, clearParam } = useURLQueryParams(paginationUrlName);
  const sortUrl = useURLQueryParams(sortUrlName);

  const debounceTime = withWebsockets ? 0 : 100;

  const changePaginationParams = newParams => {
    setPaginationParams(newParams);
  };

  const loadMore = loadParams => {
    if (!params && !paginationParams) {
      return;
    }

    changePaginationParams({
      offset: loadParams
        ? loadParams.offset + loadParams.limit
        : paginationParams.offset + paginationParams.limit,
      limit: paginationParams.limit,
    });
    setParams(prevState => ({ ...prevState, isConcatData: true }));
  };

  const changeSortParams = newParams => {
    setSortParams(newParams);
  };

  const checkIsTableGetParams = useCallback(
    debounce(() => {
      setIsTableGetParams(true);
    }, 50),
    []
  );

  useEffect(() => {
    if (!isTableGetParams) {
      checkIsTableGetParams();
    }
  }, [requestParams, isTableGetParams]);

  useEffect(() => {
    if (requestParams) {
      const { offset, limit, ascending, orderBy, ...otherParams } = requestParams;
      const ascendingValue = isBoolean(ascending) ? ascending : defaultSortParams.ascending;

      setParams({ ...defaultParams, ...otherParams });

      const paramsFromUrl = getQueryByName(paginationUrlName);
      const sortFromUrl = sortUrl.getQueryByName(sortUrlName);

      if (paginationParams) {
        changePaginationParams({
          offset: 0,
          limit: paramsFromUrl?.limit || limit || defaultPaginationParams.limit,
        });
      }

      if (sortParams) {
        changeSortParams({
          ascending: isBoolean(sortFromUrl?.ascending) ? sortFromUrl?.ascending : ascendingValue,
          orderBy: sortFromUrl?.orderBy || orderBy || defaultSortParams.orderBy,
        });
      }
    }
  }, [requestParams]);

  const debouncedRequestFunction = useCallback(
    debounce(newParams => {
      requestFunction(newParams);
    }, debounceTime),
    []
  );

  useEffect(() => {
    if (params && paginationParams && sortParams && requestFunction) {
      debouncedRequestFunction({ ...params, ...paginationParams, ...sortParams }, requestFunction);
    }
  }, [params, paginationParams]);

  useEffect(() => {
    if (params && paginationParams && sortParams && requestFunction) {
      if (withInfinitePagination) {
        changePaginationParams({
          offset: 0,
          limit: 10,
        });
        setParams({ ...params, isConcatData: false });
      }
      debouncedRequestFunction(
        { ...params, ...paginationParams, ...sortParams, isTable: true },
        requestFunction
      );
    }
  }, [sortParams]);

  useEffect(() => {
    const restoreParamsFromUrl = () => {
      const currentPaginationParams = {
        offset: requestParams?.offset ?? defaultPaginationParams.offset,
        limit: requestParams?.limit ?? defaultPaginationParams.limit,
      };
      const currentSortParams = {
        ascending: isBoolean(requestParams?.ascending)
          ? requestParams?.ascending
          : defaultSortParams.ascending,
        orderBy: requestParams?.orderBy ?? defaultSortParams.orderBy,
      };

      if (!isTableGetParams) {
        setSortParams(currentSortParams);
        return setPaginationParams(currentPaginationParams);
      }

      const paramsFromUrl = getQueryByName(paginationUrlName);
      const sortFromUrl = sortUrl.getQueryByName(sortUrlName);

      if (!paramsFromUrl && !sortFromUrl) {
        setSortParams(currentSortParams);
        return setPaginationParams(currentPaginationParams);
      }

      changePaginationParams(paramsFromUrl);
      changeSortParams(sortFromUrl);
    };

    restoreParamsFromUrl();

    return () => {
      clearParam();
      sortUrl.clearParam();
    };
  }, [isTableGetParams]);

  useEffect(() => {
    if (paginationParams && isTableGetParams && paginationUrlName) {
      setQueryParams(paginationParams);
    }
  }, [paginationParams, isTableGetParams, paginationUrlName]);

  useEffect(() => {
    if (sortParams && isTableGetParams && sortUrlName) {
      sortUrl.setQueryParams(sortParams);
    }
  }, [sortParams, isTableGetParams, sortUrlName]);

  return {
    params,
    paginationParams,
    sortParams,
    loadMore,
    changeSortParams,
    changePaginationParams,
  };
}

export default useTableSearchParams;
