import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';
import { userRolesFiltersToQueryParams, usersTableFiltersToQueryParams } from './formatters';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async params => {
  const { admin, ...filters } = params;
  const { data } = await api.users.getUsers(
    usersTableFiltersToQueryParams({
      ...filters,
      admin: admin?.length === 1 ? admin[0] === 'admin' : undefined,
    })
  );

  return data;
});

export const fetchSystemUsers = createAsyncThunk('users/fetchSystemUsers', async params => {
  const { data } = await api.users.getSystemUsers(usersTableFiltersToQueryParams(params));

  return data;
});

export const fetchUserById = createAsyncThunk('users/fetchUserById', async id => {
  const { data } = await api.users.getUserById(id);

  return data;
});

export const fetchUserRoles = createAsyncThunk('users/fetchUserRoles', async params => {
  const { id, ...payload } = params;

  const { data } = await api.users.getUserRoles(id, userRolesFiltersToQueryParams(payload));

  return data;
});

export const updateUser = createAsyncThunk('users/updateUser', async (params, { dispatch }) => {
  const { id, ...payload } = params;
  const { data } = await api.users.updateUser(id, payload);

  dispatch(fetchUserById(id));

  return data;
});
