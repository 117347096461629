import React, { useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { fetchImportService, fetchRepositories } from 'store/services/actions';
import { importServiceIsLoadingSelector, repositoriesSelector } from 'store/services/selectors';
import { clearRepositoriesState, clearSelectedRepositoriesState } from 'store/services/reducers';
import NewDataTable from 'components/Common/NewDataTable';

import { tableFiltersToQueryParams } from '../formatters';

function SelectedRepositoriesTable({
  filterParams,
  checkedRepositoriesUuids,
  setCheckedRepositoriesUuids,
  setAllRenderUuids,
  isMassChecked,
  setExcludedIds,
  excludedIds,
  hasSelectedFilters,
  tableName,
  isShowFilters,
  setIsShowFilters,
}) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { watch, setValue } = useFormContext();

  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'code',
      label: t('common.repositoryCode'),
      withSort: true,
    },
    {
      key: 'languageName',
      label: t('common.programmingLanguage'),
      withSort: true,
    },
  ];
  const { data, total, isLoading, isError } = useSelector(repositoriesSelector);
  const isLoadingImportService = useSelector(importServiceIsLoadingSelector);

  const groupUuidValue = watch('groupUuid');
  const connectionValue = watch('connection');

  const requestFunction = requestParameters => {
    if (groupUuidValue?.length && requestParameters.groupUuid) {
      dispatch(fetchImportService(groupUuidValue));
      dispatch(fetchRepositories(tableFiltersToQueryParams(requestParameters)));
    }
  };

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      ascending: true,
      orderBy: 'name',
      system: systemId,
      groupUuid: groupUuidValue,
    }),
    [filterParams, groupUuidValue]
  );

  useEffect(() => {
    if (!connectionValue) {
      dispatch(clearRepositoriesState());
      dispatch(clearSelectedRepositoriesState());
      setValue('checkedRepositories', []);
    }
  }, []);

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(checkedRepositoriesUuids) || Boolean(total));
    }
  }, [total, hasSelectedFilters]);

  const formattedBodyData = data?.map(repository => ({
    id: repository.uuid,
    data: [
      {
        key: 'name',
        content: repository.name,
      },
      {
        key: 'code',
        content: repository.code,
      },
      {
        key: 'languageName',
        content:
          repository?.languageName &&
          `${repository?.languageName} (${repository?.languagePercentage}%)`,
      },
    ],
  }));

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      requestFunction={requestFunction}
      requestParams={requestParams}
      withStandartPagination
      total={total}
      withCheckboxes
      tableName={tableName}
      isLoading={isLoading || isLoadingImportService}
      setAllRenderUuids={setAllRenderUuids}
      checkedUuids={checkedRepositoriesUuids}
      setCheckedUuids={setCheckedRepositoriesUuids}
      isMassChecked={isMassChecked}
      setExcludedIds={setExcludedIds}
      excludedIds={excludedIds}
      hasSelectedFilters={hasSelectedFilters}
      showNoDataPerFiltersMessage={isShowFilters}
      emptyTableMessage={t('pages.systemServicesPage.noRepositories')}
      isError={isError}
    />
  );
}

export default SelectedRepositoriesTable;
