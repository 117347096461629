import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAsync from 'hooks/useAsync';

import api from 'api';
import WarningModal from 'components/Common/WarningModal';

function DeleteEquipmentModal({ isModalVisible, modalData, toggleModal, refreshTableData }) {
  const { t } = useTranslation();
  const { run, isSuccess, isLoading } = useAsync();
  const onDeleteCurrentDeviceFile = () =>
    run(api.serverTiers.deleteEquipment(modalData?.serverTierUuid, modalData?.equipmentUuid));

  useEffect(() => {
    if (isSuccess) {
      refreshTableData();
      toggleModal();
    }
  }, [isSuccess]);

  return (
    <WarningModal
      title={t('pages.adminServerTiers.deletingEquipment')}
      text={t('pages.adminServerTiers.deleteEquipment')}
      label={t('pages.adminServerTiers.equipmentName')}
      isLoading={isLoading}
      onClick={onDeleteCurrentDeviceFile}
      name={modalData?.name}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
    />
  );
}

export default DeleteEquipmentModal;
