import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { currentSystemSelector } from 'store/systems/selectors';

import PageLayout from 'components/UI/PageLayout';
import SystemDeploymentsTable from './SystemDeploymentsTable';

function SystemDeploymentsPage() {
  const { t } = useTranslation();
  const currentSystem = useSelector(currentSystemSelector);

  return (
    <PageLayout title={t('common.deployments')} systemLayout withSystemHeader withSystemSelector>
      <SystemDeploymentsTable
        currentTotal={
          currentSystem?.uuid && Boolean(currentSystem?.totalSummary?.tierReleaseTotal.total)
        }
      />
    </PageLayout>
  );
}

export default SystemDeploymentsPage;
