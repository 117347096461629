import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chart,
  ChartArea,
  ChartAxis,
  ChartGroup,
  ChartLine,
  createContainer,
  ChartLegendTooltip,
  ChartThemeColor,
} from '@patternfly/react-charts';

import { getLimitsLabels, limitsKeys, unitMemoryNames } from 'constants';
import { convertLimits, formatLimitsToGib, getFormattedDate } from 'utils';

import * as S from '../styles';

function LimitSection({
  code,
  total,
  usageChart,
  requestsChart,
  used,
  duration,
  itemName,
  numbersAfterComma = 1,
  isLastItem,
}) {
  const { t } = useTranslation();
  const limitsLabels = getLimitsLabels();
  const containerRef = useRef();
  const [width, setWidth] = useState(300);

  const defaultChart = [
    { x: Date.now() - duration * 1000, y: 0 },
    { x: Date.now(), y: 0 },
  ];

  useEffect(() => {
    const handleChangeWidth = () => {
      setWidth((containerRef.current?.clientWidth || 300) + 100);
    };
    handleChangeWidth();
    window.addEventListener('resize', handleChangeWidth);

    return () => {
      window.removeEventListener('resize', handleChangeWidth);
    };
  }, []);

  const isNotCpuValue = code !== limitsKeys.CPU;
  const CursorVoronoiContainer = createContainer('voronoi', 'cursor');
  const legendData = [
    { childName: code, name: t('pages.systemTierPage.inUse') },
    {
      childName: 'total requested',
      name: t('pages.systemTierPage.requested'),
      symbol: { type: 'dash' },
    },
  ];

  const generateChart = item => ({
    x: item[0] * 1000,
    y: formatLimitsToGib(item[1], code),
  });

  const formattedUsageChart = useMemo(() => {
    const formattedChart = usageChart?.map(generateChart);
    return formattedChart?.length ? formattedChart : defaultChart;
  }, [requestsChart]);

  const formattedRequestChart = useMemo(() => {
    const formattedChart = requestsChart?.map(generateChart);
    return formattedChart?.length ? formattedChart : defaultChart;
  }, [requestsChart]);

  const formatUsedValue = code === limitsKeys.CPU ? parseFloat(used) * 1000 : used;
  const formatTotalValue = code === limitsKeys.CPU ? parseFloat(total) * 1000 : total;

  return (
    <S.Row key={code} isLastItem={isLastItem}>
      <S.ResourcesLeft>
        <S.ResourcesBlock>
          <S.ResourcesTitle id={`tier_${itemName}_${code}_availableQuantity`}>
            {limitsLabels[code]}
          </S.ResourcesTitle>
          <S.Avialable flexStart aria-labelledby={`tier_${itemName}_${code}_availableQuantity`}>
            {convertLimits(formatTotalValue - formatUsedValue, code, numbersAfterComma, true)}{' '}
            {t('pages.systemTierPage.available')}
          </S.Avialable>
        </S.ResourcesBlock>
        <S.ResourcesBlock>
          <S.Using id={`tier_${itemName}_${code}_usingQuantity`} className="text-right">
            {convertLimits(formatUsedValue, code, numbersAfterComma, true)}
          </S.Using>
          <S.Avialable
            aria-labelledby={`tier_${itemName}_${code}_usingQuantity`}
            className="text-right"
          >
            {t('pages.systemTierPage.from')}{' '}
            {convertLimits(formatTotalValue, code, numbersAfterComma, true)}
          </S.Avialable>
        </S.ResourcesBlock>
      </S.ResourcesLeft>
      <S.ResourcesRight>
        <div ref={containerRef} style={{ flex: 1, height: 120 }}>
          <Chart
            padding={{
              bottom: 50,
              left: 50,
              right: 10,
              top: 30,
            }}
            legendData={legendData}
            legendComponent={<></>}
            height={180}
            width={width}
            containerComponent={
              <CursorVoronoiContainer
                cursorDimension="x"
                labels={({ datum }) =>
                  `${+Number(datum.y).toFixed(1)} ${isNotCpuValue ? unitMemoryNames.gib : ''}`
                }
                labelComponent={
                  <ChartLegendTooltip
                    legendData={legendData}
                    title={datum => `${getFormattedDate(datum.x, 'HH:mm')}`}
                  />
                }
                mouseFollowTooltips
                voronoiDimension="x"
              />
            }
            minDomain={{ y: 0 }}
            themeColor={ChartThemeColor.multiUnordered}
          >
            <ChartAxis dependentAxis tickFormat={value => +Number(value).toFixed(3)} />
            <ChartAxis tickFormat={value => getFormattedDate(value, 'HH:mm')} />
            <ChartGroup>
              <ChartArea name={code} data={formattedUsageChart} interpolation="monotoneX" />
              <ChartLine
                data={formattedRequestChart}
                style={{
                  data: {
                    strokeDasharray: '3,3',
                  },
                }}
                name="total requested"
              />
            </ChartGroup>
          </Chart>
        </div>
      </S.ResourcesRight>
    </S.Row>
  );
}

export default React.memo(LimitSection);
