import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useTableExpandedItems from 'components/Common/DataTable/hooks/useTableExpandedItems';

import { ReactComponent as PlusSVG } from 'assets/images/audit/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/images/audit/minus.svg';

import useModal from 'hooks/useModal';
import types from '../types';
import TableCheckbox from '../TableCheckbox';

import * as S from './styles';

const observerPlaceOffset = 4;

const BodyData = React.forwardRef(
  (
    {
      items,
      index,
      gridTemplateColumns,
      withInfinitePagination,
      rowsCount,
      params,
      withCheckboxes,
      checkedUuids,
      setCheckedUuids,
      expandedKey,
      grid,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { isModalVisible, toggleModal, setIsModalVisible } = useModal();
    const { getExpandedUuidsBykey, toggleExpandedUuid } = useTableExpandedItems();

    const onCheckboxChange = () =>
      setCheckedUuids(prevState =>
        prevState.includes(items.id)
          ? prevState.filter(id => id !== items.id)
          : [...prevState, items.id]
      );

    useEffect(() => {
      if (expandedKey) {
        setTimeout(() => {
          setIsModalVisible(getExpandedUuidsBykey(expandedKey).includes(items.id));
        }, 200);
      }
    }, [expandedKey]);

    useEffect(() => {
      if (isModalVisible) {
        toggleModal();
      }
    }, [params]);

    const generateContent = (type, key, content, item) => {
      if (type === types.link) {
        return (
          <S.LinkCell key={key} to={item.to} onClick={item.onClick} target={item.target}>
            {content}
          </S.LinkCell>
        );
      }

      if (type === types.button) {
        return (
          <button
            key={key}
            aria-label={item.ariaLabel}
            className="bg-transparent border-0 p-0 justify-content-start"
          >
            {content}
          </button>
        );
      }

      if (type === types.textLink) {
        return (
          <S.TextLink
            key={key}
            onClick={e => {
              e.stopPropagation();
              item.onClick();
            }}
          >
            {content}
          </S.TextLink>
        );
      }

      if (type === types.externalLink) {
        return (
          <S.ExternalLinkCell key={key} href={item.href} target={item.target}>
            {content}
          </S.ExternalLinkCell>
        );
      }

      if (type === types.expendedContent) {
        const onArrowClick = e => {
          e.preventDefault();
          e.stopPropagation();
          if (expandedKey) {
            toggleExpandedUuid(expandedKey, items.id);
          }
          item.onClick?.();
          toggleModal();
        };

        return (
          <S.ArrowIconWrapper key={key} onClick={onArrowClick} aria-label={item.ariaLabel}>
            <S.ArrowDownIcon isExpanded={isModalVisible} />
          </S.ArrowIconWrapper>
        );
      }

      if (type === types.centerContent) {
        return <S.CenterContent key={key}>{content}</S.CenterContent>;
      }

      return (
        <S.TableCell id="target" key={key}>
          {content}
        </S.TableCell>
      );
    };

    const contentForExpand = items.data?.find(item => item.type === types.expendedContent)?.content;

    return (
      <>
        <S.TableBodyWrapper
          withBorderLeft={isModalVisible}
          gridTemplateColumns={gridTemplateColumns}
          $rowColor={items.rowColor}
          grid={grid}
          onClick={items.onRowClick}
        >
          {withCheckboxes && (
            <S.ColumnWrapper>
              <S.TableCell>
                <TableCheckbox
                  isChecked={checkedUuids.includes(items.id)}
                  onChange={onCheckboxChange}
                  isDisabled={items?.isCheckboxDisabled}
                  label={t('components.table.selectRow')}
                />
              </S.TableCell>
            </S.ColumnWrapper>
          )}
          {items.data.map(({ type, key, content, ...item }) => (
            <S.ColumnWrapper
              key={key}
              grid={grid}
              isFilterColumn={item.isInFilter !== undefined}
              onClick={() => {
                if (item.isInFilter !== undefined) {
                  items.onCellClick(key, content, !item.isInFilter);
                }
              }}
            >
              {generateContent(type, key, content, item)}
              <S.IconWrapper
                onClick={() => {
                  if (item.addLevelFilter !== undefined) {
                    item.addLevelFilter?.();
                  }
                }}
              >
                {item.isInFilter ? <MinusSVG /> : <PlusSVG />}
              </S.IconWrapper>
            </S.ColumnWrapper>
          ))}
          {withInfinitePagination && index === rowsCount - observerPlaceOffset && <div ref={ref} />}
        </S.TableBodyWrapper>
        {isModalVisible && contentForExpand}
      </>
    );
  }
);

export default BodyData;
