import React, { useState } from 'react';
import { ExpandableSection, ExpandableSectionVariant } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

function ReadMoreBlock({ content, withoutExpandButton }) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content?.length) {
    return '-';
  }

  const onToggle = value => {
    setIsExpanded(value);
  };

  if (withoutExpandButton) {
    return (
      <ExpandableSection
        variant={ExpandableSectionVariant.truncate}
        isExpanded={false}
        truncateMaxLines={2}
      >
        {content}
      </ExpandableSection>
    );
  }

  return (
    <ExpandableSection
      variant={ExpandableSectionVariant.truncate}
      toggleText={isExpanded ? t('common.selectAll') : t('components.readMore.expand')}
      onToggle={(_event, value) => onToggle(value)}
      isExpanded={isExpanded}
      truncateMaxLines={1}
      isDetached={content.length < 100}
    >
      {content}
    </ExpandableSection>
  );
}

export default ReadMoreBlock;
