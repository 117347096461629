import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { notActiveSystemSelector } from 'store/systems/selectors';
import { issueOptions, issueStatusKeys } from 'constants';
import { dateConverter } from 'utils';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import Dropdown from 'components/Dropdown';
import StatusComponent, { getTasksStatuses } from 'components/Common/StatusComponent';
import {} from 'components/Common/StatusComponent/statuses';
import LastUpdatedStatusWithLink from 'components/Common/LastUpdatedStatusWithLink';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import paths from 'constants/paths';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../../../constants/tooltips';

function ReleaseTasksTable({
  allIssuesInRelease,
  releaseIssuesRequestParams,
  requestFunction,
  isApprovedRelease,
  hasSelectedFilters,
  isShowFilters,
}) {
  const { t, i18n } = useTranslation();
  const tasksStatuses = getTasksStatuses();
  const headerData = [
    {
      key: 'name',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'milestone.name',
      label: t('common.milestone'),
      withSort: true,
    },
    {
      key: 'startDate',
      label: t('common.startDate'),
      right: true,
      withSort: true,
    },
    {
      key: 'dueDate',
      label: t('common.plannedEndDate'),
      right: true,
      withSort: true,
    },
    {
      key: 'implemented',
      label: t('pages.systemAddReleasesPage.resolved'),
    },
    {
      key: 'status',
      label: t('common.status'),
      withSort: true,
    },
    {
      key: 'lastUpdatedBy',
      label: t('common.updatedBy'),
      withSort: true,
    },
    {
      key: 'lastUpdatedAt',
      label: t('common.updatedAt'),
      withSort: true,
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const { checkedPermissions } = useUserPermissions();
  const tableRef = useRef();

  const changeIssue = useAsync();

  useEffect(() => {
    if (changeIssue.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }
  }, [changeIssue.isSuccess]);

  const changeIssueClick = (status, issue) => {
    changeIssue.run(
      api.issues.changeAllIssues(
        {
          issueIdList: [issue.uuid],
          status,
        },
        issue.milestone.uuid
      )
    );
  };

  const onFilterChange = (id, issue) => {
    if (id === issueOptions.taskSolved) {
      return changeIssueClick(issueStatusKeys.DONE, issue);
    }

    if (id === issueOptions.sendForRevision) {
      return changeIssueClick(issueStatusKeys.IN_PROGRESS, issue);
    }
  };

  const formattedBodyData = allIssuesInRelease.data?.data?.map(({ issue, implemented }) => {
    const menuOptions = [];

    if (issue.totalSummary?.tierReleaseTotal.prod) {
      menuOptions.push({
        id: issueOptions.taskSolved,
        name: t('pages.systemReleasePage.resolved'),
        isHidden: issue.status === issueStatusKeys.DONE,
        isDisabled: notActiveSystemMessage,
        tooltip: notActiveSystemMessage,
      });
    }

    if (issue.totalSummary?.tierReleaseTotal.dev && !issue.totalSummary?.tierReleaseTotal.prod) {
      menuOptions.push({
        id: issueOptions.sendForRevision,
        name: t('pages.systemReleasePage.forReworking'),
        isDisabled: notActiveSystemMessage,
        tooltip: notActiveSystemMessage,
      });
    }

    const isDeployedIssue =
      isApprovedRelease &&
      (!!issue.totalSummary?.tierReleaseTotal.prod || !!issue.totalSummary?.tierReleaseTotal.dev);

    return {
      id: issue.uuid,
      data: [
        {
          key: 'name',
          content: (
            <LinkWithPermission
              system={issue?.system}
              scope={scopes.issue.issueViewForm}
              to={generatePath(paths.systemFullPaths.issuesId, {
                systemId: issue.system.uuid,
                systemType: issue.system.type,
                issuesId: issue.uuid,
              })}
              isTableLink
            >
              {issue.name}
            </LinkWithPermission>
          ),
        },
        {
          key: 'milestone.name',
          content: issue.milestone?.name,
        },
        {
          key: 'startDate',
          content: <div className="float-right">{dateConverter(issue.startDate)}</div>,
        },
        {
          key: 'dueDate',
          content: <div className="float-right">{dateConverter(issue.dueDate)}</div>,
        },
        {
          key: 'implemented',
          content: implemented ? <S.CompleteIcon /> : '-',
        },
        {
          key: 'status',
          content: issue?.status && (
            <StatusComponent statusId={issue.status} statuses={tasksStatuses} />
          ),
        },
        {
          key: 'lastUpdatedBy',
          content: (
            <LastUpdatedStatusWithLink
              lastUpdatedBy={issue?.lastUpdatedBy}
              system={issue?.system}
              isTableLink
            />
          ),
        },
        {
          key: 'lastUpdatedAt',
          type: types.relativeTime,
          content: issue.lastUpdatedAt,
        },
        ...(isDeployedIssue && menuOptions.length
          ? [
              {
                key: 'dropdownMenu',
                content: checkedPermissions(scopes.release.releaseUpdateIssues) &&
                  (issue.status === issueStatusKeys.DONE ||
                    issue.status === issueStatusKeys.IMPLEMENTED) && (
                    <Dropdown
                      id={issue.uuid}
                      optionData={menuOptions}
                      onFilterChange={id => onFilterChange(id, issue)}
                    />
                  ),
              },
            ]
          : [
              {
                key: 'dropdownMenu',
                content: <></>,
              },
            ]),
      ],
    };
  });

  return (
    <NewDataTable
      ref={tableRef}
      headerData={headerData}
      bodyData={formattedBodyData}
      requestFunction={requestFunction}
      requestParams={releaseIssuesRequestParams}
      withStandartPagination
      total={allIssuesInRelease.data?.meta?.total}
      tableName="releaseTasks"
      isLoading={allIssuesInRelease.isLoading}
      hasSelectedFilters={hasSelectedFilters}
      showNoDataPerFiltersMessage={isShowFilters}
      isError={allIssuesInRelease.isError}
    />
  );
}

export default ReleaseTasksTable;
