import http from 'api/config';

const getServiceVersions = ({ serviceUuid, ...params }) =>
  http.get(`services/${serviceUuid}/versions`, { params });

const getServicesVersions = params => http.get(`service-versions`, { params });

const getServiceVersionByUuid = versionUuid => http.get(`service-versions/${versionUuid}`);

const getServiceVersionArtifacts = (versionUuid, params) =>
  http.get(`service-versions/${versionUuid}/artifacts`, { params });

const getServiceVersionBuildResults = ({ serviceVersionId, ...params }) =>
  http.get(`service-versions/${serviceVersionId}/build-results`, { params });

const getServiceVersionVulnerabilities = ({ buildResultUuid, ...params }) =>
  http.get(`build-results/${buildResultUuid}/vulnerabilities`, { params });

const getServiceVersionUnitTesting = ({ buildResultUuid, ...params }) =>
  http.get(`build-results/${buildResultUuid}/unit-test-results`, { params });

const getServiceVersionStaticAnalysis = ({ buildResultUuid, ...params }) =>
  http.get(`build-results/${buildResultUuid}/static-analysis-result`, { params });

const getServiceVersionStaticAnalysisIssues = ({ staticAnalysisResultUuid, ...params }) =>
  http.get(`static-analysis-results/${staticAnalysisResultUuid}/issues`, {
    params,
  });

const getServiceVersionStaticAnalysisHotspots = ({ staticAnalysisResultUuid, ...params }) =>
  http.get(`static-analysis-results/${staticAnalysisResultUuid}/hotspots`, {
    params,
  });

const addServiceVersion = params => http.post(`service-versions`, params);

const deleteServiceVersion = versionUuid => http.delete(`service-versions/${versionUuid}`);

const getServicesVersionsOptions = params => http.get(`service-versions/options`, { params });

export default {
  getServiceVersions,
  getServicesVersions,
  getServiceVersionByUuid,
  getServiceVersionArtifacts,
  getServiceVersionBuildResults,
  getServiceVersionVulnerabilities,
  getServiceVersionUnitTesting,
  getServiceVersionStaticAnalysis,
  getServiceVersionStaticAnalysisIssues,
  getServiceVersionStaticAnalysisHotspots,
  addServiceVersion,
  deleteServiceVersion,
  getServicesVersionsOptions,
};
