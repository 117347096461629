import moment from 'moment';
import { format } from 'date-fns';
import { scopes } from 'constants/scopes';
import { unitMemoryKeys, cpuUnitKeys, limitsKeys, unitMemoryNames, protocolNames } from 'constants';
import {
  getSystemHaveUpdetedTierMessage,
  getSystemHaveUpdetedUserMessage,
} from 'constants/tooltips';
import i18n from '../i18n';

const { t } = i18n;
moment.relativeTimeRounding(Math.floor);

export const declOfNum = (number, titles, withNumber = true, needText = false) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const numberWithRound = Math.round(number);

  if (needText) {
    return `${withNumber ? `${numberWithRound || 0} ` : ''}${
      titles[
        numberWithRound % 100 > 4 && numberWithRound % 100 < 20
          ? 2
          : cases[numberWithRound % 10 < 5 ? numberWithRound % 10 : 5]
      ]
    }`;
  }

  return `
      ${(withNumber && numberWithRound) || ''} 
      ${
        titles[
          numberWithRound % 100 > 4 && numberWithRound % 100 < 20
            ? 2
            : cases[numberWithRound % 10 < 5 ? numberWithRound % 10 : 5]
        ]
      }
    `;
};

export const getPluralLabel = (count, labels) => {
  const k = Math.abs(count) % 100;
  const n1 = k % 10;

  if (k > 10 && k < 20) {
    return labels[2];
  }
  if (n1 > 1 && n1 < 5) {
    return labels[1];
  }
  if (n1 === 1) {
    return labels[0];
  }
  return labels[2];
};

export const downloadFile = (data, fileName, extension) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([data]));
  link.setAttribute('download', `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
};

export const percent = (limit, value) =>
  +limit && +value ? `${Math.round(((value || 0) * 100) / (limit || 0))}%` : `0%`;

export const dateTimeConverter = (timestamp, withSeconds, noTime = '-') => {
  if (!timestamp) {
    return noTime;
  }

  const date = new Date(timestamp);
  const timeFormat = withSeconds
    ? date.toLocaleTimeString()
    : date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

  return `${date.toLocaleDateString()}, ${timeFormat}`;
};

export const timeConverter = timestamp => {
  if (!timestamp) {
    return '-';
  }
  const date = new Date(timestamp);

  return `${date.toLocaleTimeString()}`;
};

export const dateConverter = timestamp => {
  if (!timestamp) {
    return '-';
  }
  const date = new Date(timestamp);

  return `${date.toLocaleDateString()}`;
};

export const getFormattedDate = (date, formatString) => {
  if (!date) {
    return '-';
  }

  return format(date, formatString);
};

export const generateRoutePath = (location, path) => `${location.pathname}${path}`;

export const generateDropdownData = (data, emptyOptionName, nameField) => {
  const emptyOption = {
    id: undefined,
    name: emptyOptionName,
  };

  if (!data) {
    return [emptyOption];
  }

  return [
    emptyOption,
    ...data.map(({ uuid, ...item }) => ({
      id: uuid,
      name: item?.[[nameField ?? 'name']] ?? '',
      group: t('common.tiers'),
    })),
  ];
};

export function translit(text) {
  const converter = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sch',
    ь: '',
    ы: 'y',
    ъ: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',
  };

  return text
    ?.toLowerCase()
    ?.split('')
    ?.reduce((acc, char) => acc + (converter[char] || char), '');
}

export const formatLimitsToGib = (value, code, unit) => {
  if (code === limitsKeys.CPU) {
    return +Number(value || 0)?.toFixed(3);
  }

  const formatValue = +Number((value || 0) / 1073741824)?.toFixed(3);

  return unit ? `${formatValue} ${unit}` : formatValue;
};

const convertibleQuantities = [
  {
    unitValue: 1125899906842624,
    unit: 'PiB',
  },
  {
    unitValue: 1099511627776,
    unit: 'TiB',
  },
  { id: unitMemoryKeys.gib, unitValue: 1073741824, unit: unitMemoryNames.gib },
  {
    id: unitMemoryKeys.mib,
    unitValue: 1048576,
    unit: unitMemoryNames.mib,
  },
  {
    unitValue: 1024,
    unit: 'KiB',
  },
  {
    unitValue: 1,
    unit: 'B',
  },
];

export const getConvertibleCpuQuantities = () => [
  {
    id: cpuUnitKeys.cores,
    unitValue: 1000,
    unit: [t('plural.core1'), t('plural.core2'), t('plural.core3')],
  },
  {
    id: cpuUnitKeys.millicores,
    unitValue: 1,
    unit: [t('plural.millicore1'), t('plural.millicore2'), t('plural.millicore3')],
    numbersAfterComma: '0',
  },
];

export const convertLimits = (value = 0, code, numbersAfterComma = 3, withUnit, checkedUnit) => {
  const convertibleCpuQuantities = getConvertibleCpuQuantities();

  if (code === limitsKeys.CPU) {
    const currentValue = parseFloat(value);
    const currentUnit = convertibleCpuQuantities.find(
      item => Number(currentValue) - item.unitValue >= 0
    );

    if (currentUnit) {
      const formatValue = +(currentValue / currentUnit.unitValue)?.toFixed(
        currentUnit?.numbersAfterComma || numbersAfterComma
      );

      const validUnit = formatValue > 1 ? currentUnit.unit[2] : currentUnit.unit[1];

      if (Number.isInteger(formatValue)) {
        return withUnit
          ? `${formatValue} ${
              checkedUnit ? validUnit : declOfNum(formatValue, currentUnit.unit, false, true)
            }`
          : formatValue;
      }

      return withUnit
        ? `${formatValue} ${checkedUnit ? validUnit : currentUnit.unit[1]}`
        : formatValue;
    }

    return 0;
  }

  const currentValue = parseFloat(value);
  const currentUnit = convertibleQuantities.find(
    item => Number(currentValue) - item.unitValue >= 0
  );

  if (currentUnit) {
    const formatValue = +(currentValue / currentUnit.unitValue)?.toFixed(numbersAfterComma);

    return withUnit ? `${formatValue} ${currentUnit.unit}` : formatValue;
  }

  return 0;
};

export const convertValueToMillicores = (value, unit) => {
  const convertibleCpuQuantities = getConvertibleCpuQuantities();
  const currentUnit = convertibleCpuQuantities.find(item => item.id === unit);
  const unitValue = currentUnit?.unitValue;

  if (unitValue) {
    return +(Number(value) * unitValue).toFixed(currentUnit?.numbersAfterComma || 3);
  }

  return Number(value);
};

export const convertValueToBytes = (value, unit) => {
  const unitValue = convertibleQuantities.find(item => item.id === unit)?.unitValue;

  if (unitValue && unit) {
    return +(Number(value) * unitValue).toFixed(3);
  }

  return Number(value);
};

export const generateFullPaths = (pathsObject, route) =>
  Object.getOwnPropertyNames(pathsObject).reduce(
    (acc, key) => ({
      ...acc,
      [key]: route + pathsObject[key],
    }),
    {}
  );

export const getLastApprovedDateReleaseName = releases => {
  if (releases?.length) {
    const maxApproveDate = Math.max(...releases.map(item => item.approvedAt));
    return releases.find(item => item.approvedAt === maxApproveDate);
  }
};

export function customTableSort(data, ascending, key) {
  return data.sort((a, b) => {
    const first = a.data.find(element => element.key === key);
    const second = b.data.find(element => element.key === key);

    if (first?.isDateClientSort) {
      const firstValue = Number.isFinite(first.isDateClientSort)
        ? first.isDateClientSort
        : +moment(first.isDateClientSort, 'MM-DD-YYYY hh:mm:ss').format('x');
      const secondValue = Number.isFinite(second.isDateClientSort)
        ? second.isDateClientSort
        : +moment(second.isDateClientSort, 'MM-DD-YYYY hh:mm:ss').format('x');

      if (firstValue > secondValue) {
        return ascending ? 1 : -1;
      }
      if (firstValue < secondValue) {
        return ascending ? -1 : 1;
      }
      return 0;
    }

    const firstValue = first?.value || first?.content;
    const secondValue = second?.value || second?.content;
    if (firstValue > secondValue) {
      return ascending ? 1 : -1;
    }
    if (firstValue < secondValue) {
      return ascending ? -1 : 1;
    }
    return 0;
  });
}

export const isActualFilterFunction = ({ startDate, endDate }) =>
  moment().isSameOrBefore(moment(endDate).endOf('day')) &&
  moment().isSameOrAfter(moment(startDate));

export const dateIsExceeded = date => moment().isSameOrAfter(moment(date).endOf('day'));

export const isDueDateWasExceeded = (date, closedAt) =>
  moment(date).isSameOrAfter(moment(closedAt).endOf('day'));

export const getFilteredOptions = (search, options, searchFilter) =>
  !search?.trim() || searchFilter
    ? options
    : options.filter(option => option.props.value?.toLowerCase().includes(search?.toLowerCase()));

export const hasSelectedFilters = filters =>
  filters &&
  Object.keys(filters).some(key =>
    Array.isArray(filters[key]) ? filters[key].length : filters[key]
  );

export const checkedSystemPermission = (userPermissions, scope, systemCode) => {
  const isCanDoAll = userPermissions
    .find(permission => permission?.rsname === 'platform')
    ?.scopes.includes(scopes.platform.platformDoAll);

  if (isCanDoAll) {
    return true;
  }

  return userPermissions
    .find(permission => {
      if (systemCode) {
        return permission?.rsname && permission.rsname === systemCode;
      }

      return permission?.rsname === 'platform';
    })
    ?.scopes.includes(scope);
};

export const isValidUrl = string => {
  try {
    const url = new URL(string);

    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const checkedUrlValue = url =>
  url?.length && url !== protocolNames.https && url !== protocolNames.http ? url : undefined;

export const validateUrlValue = (string, withMessage) => {
  if (!string) {
    return true;
  }

  if (withMessage) {
    const url = checkedUrlValue(string);

    return url && !isValidUrl(string) ? t('validation.incorrectUrl') : true;
  }

  if (!withMessage) {
    return isValidUrl(string);
  }
};

export const relativeTimeConverter = date => moment(date).fromNow();

export const formatFilterOptions = names =>
  Object.keys(names).map(key => ({ id: key, name: names[key] }));

export const getUserDescription = user => {
  let description = '';

  if (user?.login) {
    description += `${t('common.login')}: ${user.login}`;
  }

  if (user?.email) {
    description += `, email: ${user.email}`;
  }

  if (user?.phone) {
    description += `, ${t('misc.tel')}: ${user.phone}`;
  }

  if (user?.workplace) {
    description += `, ${t('misc.jobAddress')}: ${user.workplace}`;
  }

  if (description?.length) {
    description += '.';
  }

  return description;
};

export const checkedTierTotal = (tierTotal, message) =>
  tierTotal &&
  (tierTotal?.error ||
    tierTotal?.inProgress ||
    tierTotal?.archiveInProgress ||
    tierTotal?.restoreInProgress ||
    tierTotal?.removalInProgress ||
    tierTotal?.pending) &&
  (message || getSystemHaveUpdetedTierMessage());

export const checkedUserTotal = (userTotal, message) =>
  userTotal &&
  (userTotal?.notUpdatedUser || userTotal?.error || userTotal?.inProgress) &&
  (message || getSystemHaveUpdetedUserMessage());

export const isFormValid = formState =>
  !Object.keys(formState.errors)?.length && !formState.isSubmitting;

export const checkedServerError = message => {
  if (message && typeof message === 'number') {
    return message >= 500 || message === 0;
  }

  const status = message?.length && Number(message?.split(' ')?.pop());

  if (message === 'Network Error') {
    return true;
  }

  return typeof status === 'number' && (status >= 500 || status === 0);
};

export const getTagNumber = tag => tag && tag?.match(new RegExp(`\\d+\\.\\d+\\.\\d+`))?.[0];

export const incrementVersion = (lastVersionTag, callback) => {
  const tag = getTagNumber(lastVersionTag);

  if (tag) {
    const incrementString = lastTagNumber => {
      const number = parseInt(lastTagNumber, 10) + 1;
      const formattedNumber = String(number).padStart(lastTagNumber.length, '0');

      return formattedNumber;
    };

    const arrayTagNumbers = tag.split('.');
    arrayTagNumbers.splice(2, 1, incrementString(arrayTagNumbers.pop()));
    const newTagValue = lastVersionTag.replace(tag, arrayTagNumbers.join('.'));

    callback(newTagValue);
  }
};

export const getApiErrorMessage = (
  error,
  translator,
  defaultMessage = null,
  skipWithProperty = false
) => {
  const errorData = error?.response?.data;
  const violations = errorData?.violations || [];
  const filteredViolations = skipWithProperty ? violations.filter(v => !v.property) : violations;
  const codesMessages = filteredViolations
    .map(e => e.code)
    .filter(Boolean)
    .map(code => translator(`api.errorCode.${code}`))
    .join('. ');
  const messages =
    filteredViolations.map(e => e.message).join('. ') || errorData?.message || defaultMessage;

  if (errorData?.message) {
    // TODO: remove "if condition" after new api error model will be implemented on BE
    return errorData?.message;
  }

  return codesMessages || messages;
};

export const sortList = (list, language = 'en') =>
  list.sort((a, b) => a.localeCompare(b, language, { sensitivity: 'base' }));
