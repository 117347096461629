import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const fetchContactPersons = createAsyncThunk(
  'contactPersons/fetchContactPersons',
  async params => {
    const response = await api.contactPersons.getContactPersons(params);

    return response.data;
  }
);
