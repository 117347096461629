import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TextBlock from 'components/UI/TextBlock';
import { InformationBlockWrapper } from 'components/UI/View';
import SectionHeader from 'components/Common/SectionHeader';
import { CloseButton } from 'components/UI/CloseButton';
import { phoneNumberValidation } from 'constants/vaidations';

import * as S from './styles';

function ContactsInfoModal({ developer, responsible, isModalVisible, toggleModal }) {
  const { t } = useTranslation();
  const title = <S.TitleWrapper>{t('common.contacts')}</S.TitleWrapper>;
  const representatives = [...(developer?.representatives || [])].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <ReactModal isOpen={isModalVisible} onRequestClose={toggleModal} ariaHideApp={false}>
      <S.Wrapper>
        <SectionHeader title={title}>
          <CloseButton onClick={toggleModal} />
        </SectionHeader>
        {responsible && (
          <S.WindowCompanyInfo>
            <InformationBlockWrapper columnsCount={3}>
              <TextBlock title={t('common.responsibleFio')} id="responsible">
                <S.NameWrapper>{responsible?.fullName}</S.NameWrapper>
              </TextBlock>
              <TextBlock title={t('common.phone')} id="companyPhone">
                <S.LinkWrapper href={`tel:${phoneNumberValidation(responsible?.phone)}`}>
                  {responsible?.phone}
                </S.LinkWrapper>
              </TextBlock>
              <TextBlock title={t('common.email')} id="companyEmail">
                <S.LinkWrapper href={`mailto:${responsible?.email || ''}`}>
                  {responsible?.email}
                </S.LinkWrapper>
              </TextBlock>
            </InformationBlockWrapper>
          </S.WindowCompanyInfo>
        )}
        {developer?.name && (
          <S.WindowCompanyInfo>
            <S.WindowCompanyNameInfoLine>
              <TextBlock title={t('common.work')} id="companyName">
                <S.CompanyWrapper aria-labelledby="companyName">{developer.name}</S.CompanyWrapper>
              </TextBlock>
            </S.WindowCompanyNameInfoLine>
            <InformationBlockWrapper columnsCount={3}>
              <TextBlock title={t('common.phone')} id="companyPhone">
                <S.LinkWrapper href={`tel:${phoneNumberValidation(developer?.phone)}`}>
                  {developer?.phone}
                </S.LinkWrapper>
              </TextBlock>
              <TextBlock title={t('common.email')} id="companyEmail">
                <S.LinkWrapper href={`mailto:${developer?.email || ''}`}>
                  {developer?.email}
                </S.LinkWrapper>
              </TextBlock>
              <TextBlock title={t('common.site')} id="companySite">
                <Link to={`//${developer?.site}`} target="_blank">
                  {developer?.site}
                </Link>
              </TextBlock>
            </InformationBlockWrapper>
            <InformationBlockWrapper>
              <TextBlock
                title={t('common.address')}
                text={developer?.address}
                id="companyAddress"
              />
            </InformationBlockWrapper>
          </S.WindowCompanyInfo>
        )}
        {!!representatives.length && (
          <S.WindowRespEmployeeInfo>
            <S.InformationBlockHead>
              <S.InformationBlockTableHeadLine columnsCount={3}>
                <td>
                  <TextBlock title={t('common.fio')} />
                </td>
                <td>
                  <TextBlock title={t('common.phone')} />
                </td>
                <td>
                  <TextBlock title={t('common.email')} />
                </td>
              </S.InformationBlockTableHeadLine>
            </S.InformationBlockHead>
            {representatives.map(({ uuid, name, email, phone }) => (
              <S.InformationBlockTableLine columnsCount={3} key={uuid}>
                <td>
                  <TextBlock>
                    <S.NameWrapper>{name}</S.NameWrapper>
                  </TextBlock>
                </td>
                <td>
                  <TextBlock>
                    <S.LinkWrapper href={`tel:${phoneNumberValidation(phone)}`}>
                      {phone}
                    </S.LinkWrapper>
                  </TextBlock>
                </td>
                <td>
                  <TextBlock>
                    <S.LinkWrapper href={`mailto:${email}`}>{email}</S.LinkWrapper>
                  </TextBlock>
                </td>
              </S.InformationBlockTableLine>
            ))}
          </S.WindowRespEmployeeInfo>
        )}
      </S.Wrapper>
    </ReactModal>
  );
}

export default ContactsInfoModal;
