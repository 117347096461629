import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 315px;
  height: 40px;
`;

export const InputComponentWrapper = styled.div`
  width: 44px;
  height: 28px;
  margin: -9px 8px 0 8px;
`;

export const InputSelectWrapper = styled.div`
  width: 150px;
  height: 37px;
`;
