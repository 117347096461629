import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useAsync from 'hooks/useAsync';
import useModal from 'hooks/useModal';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys } from 'components/Common/NewDataTable';
import PopoverButton from 'components/Common/PopoverButton';
import DocumentsButton from 'components/Common/DocumentsButton';
import WarningModal from 'components/Common/WarningModal';
import { BlueButton, TextButton } from 'components/UI/Button';
import { TitleInfoIcon } from 'components/UI/TitleInfoIcon';
import TooltipComponent from 'components/TooltipComponent';
import Dropdown from 'components/Dropdown';
import { expandedKeys } from 'components/Common/DataTable/hooks/useTableExpandedItems';
import { tableFiltersToQueryParams } from 'pages/SystemRouter/SystemIntegrationsRouter/formatters';

import { ReactComponent as VectorLeftSvg } from 'assets/images/integrations/VectorLeft.svg';
import { ReactComponent as VectorRightSvg } from 'assets/images/integrations/VectorRight.svg';
import { ReactComponent as CubesSvg } from 'assets/images/integrations/Cubes.svg';

import IntegrationDeployWrapper from './IntegrationDeployWrapper';
import ServiceEndpointData from './ServiceEndpointData';

import * as S from './styles';

function IntegrationsTable({ requestParams, requestFunction, systemUuid }) {
  const { t } = useTranslation();
  const {
    params: { systemId, systemType },
  } = useRouteMatch();
  const history = useHistory();
  const { checkedPermissions, optionsFilterByScopes } = useUserPermissions();
  const tableRef = useRef();

  const { run, data, isLoading } = useAsync();
  const deleteIntegration = useAsync();
  const allIntegrations = data?.data || [];

  const editServices = useModal();

  const integrationsRequestParams = useMemo(
    () => ({
      ...requestParams,
      orderBy: 'serviceEndpointName',
      ascending: true,
      systemWith: systemUuid,
    }),
    [systemUuid, requestParams]
  );
  function integrationsRequestFunction(params) {
    run(api.integrations.getIntegrations(tableFiltersToQueryParams(params)));
  }

  useEffect(() => {
    if (deleteIntegration.isSuccess) {
      tableRef.current?.requestDataWithParams();
    }

    if (data?.meta.total <= 1) {
      requestFunction(requestParams);
    }
  }, [deleteIntegration.isSuccess]);

  const onEditOtherServicesClick = uuid =>
    history.push(
      generatePath(paths.systemIntegrationsFullPaths.editService, {
        systemId,
        systemType,
        action: paths.integrationsAction.editService,
        integrationId: uuid,
      })
    );

  const onFilterChange = (value, item) => {
    if (value === 'edit') {
      if (item.systemFrom.uuid === systemId) {
        editServices.setModalData(item);
        editServices.toggleModal();
        return;
      }

      return onEditOtherServicesClick(item.uuid);
    }

    deleteIntegration.run(api.integrations.deleteIntegration(item.uuid));
  };

  const optionsData = useMemo(
    () =>
      optionsFilterByScopes([
        {
          id: 'edit',
          name: t('common.edit'),
        },
        {
          id: 'delete',
          name: t('common.delete'),
          scope: scopes.integration.integrationDelete,
        },
      ]),
    []
  );

  const headerData = [
    {
      key: 'serviceEndpointName',
      label: t('common.name'),
      withSort: true,
    },
    {
      key: 'tiers',
      label: t('common.tiers'),
    },
    ...(systemId
      ? [
          {
            key: 'inbound',
            label: t('pages.systemIntegrationRelationsPage.direction'),
            withSort: true,
          },
        ]
      : []),
    {
      key: 'protocol',
      label: t('pages.systemIntegrationRelationsPage.protocol'),
    },
    {
      key: 'authType',
      label: t('pages.systemIntegrationRelationsPage.authType'),
    },
    {
      key: 'async',
      label: t('pages.systemIntegrationRelationsPage.asyncType'),
    },
    {
      key: 'documents',
      label: t('common.documents'),
    },
    {
      key: 'dropdownMenu',
      label: '',
      modifier: modifierKeys.fitContent,
    },
  ];

  const formattedBodyData = allIntegrations.map((item, index) => {
    const vectorMessage = item.inbound
      ? t('pages.systemIntegrationRelationsPage.incomeDirection')
      : t('pages.systemIntegrationRelationsPage.outcomeDirection');

    const headerContent = (
      <>
        {t('pages.systemIntegrationRelationsPage.relationsBetweenTiers')}
        {item.description && <S.Description>{item.description}</S.Description>}
        {!!(
          item.serviceEndpointTo.requestData.length || item.serviceEndpointTo.responseData.length
        ) && <ServiceEndpointData serviceEndpointTo={item.serviceEndpointTo} key={item.uuid} />}
      </>
    );

    const bodyContent = (
      <IntegrationDeployWrapper
        integrationTiers={item.integrationTiers}
        integrationSystemFrom={item.systemFrom}
        integrationSystemTo={item.systemTo}
        integrationUuid={item?.uuid}
      />
    );

    return {
      id: item?.uuid,
      data: [
        {
          key: 'serviceEndpointName',
          content: (
            <S.NameWrapper id={`integration${item?.serviceEndpointTo.uuid}`}>
              <S.ServiceEndpointToWrapper>
                {item?.serviceEndpointTo.name}
                {item?.serviceEndpointTo.description && (
                  <TooltipComponent message={item?.serviceEndpointTo.description ?? ''}>
                    <TitleInfoIcon />
                  </TooltipComponent>
                )}
              </S.ServiceEndpointToWrapper>
              <div
                className="font-12 font-weight-normal text-gray-light"
                aria-labelledby={`integration${item?.serviceEndpointTo.uuid}`}
              >
                {item.systemTo?.name}
              </div>
            </S.NameWrapper>
          ),
        },
        {
          key: 'tiers',
          ariaLabel: `open info about ${index + 1}`,
          content: (
            <>
              {!!item?.serviceEndpointTo.serviceEndpointTiers.length && (
                <PopoverButton headerContent={headerContent} bodyContent={bodyContent}>
                  <TextButton variant="link" key={item.uuid} icon={<CubesSvg />}>
                    <S.ServiceEndpointTiersCounter>
                      {item?.integrationTiers.length}
                    </S.ServiceEndpointTiersCounter>
                  </TextButton>
                </PopoverButton>
              )}
            </>
          ),
        },
        ...(systemId
          ? [
              {
                key: 'inbound',
                content: (
                  <>
                    <S.VectorWrapper>
                      <TooltipComponent key={item?.uuid} message={vectorMessage}>
                        {item.inbound ? <VectorLeftSvg /> : <VectorRightSvg />}
                      </TooltipComponent>
                    </S.VectorWrapper>
                  </>
                ),
              },
            ]
          : []),
        {
          key: 'protocol',
          content: item.serviceEndpointTo.protocol,
        },
        {
          key: 'authType',
          content: item.serviceEndpointTo.authType,
        },
        {
          key: 'async',
          content:
            item.serviceEndpointTo.async === null ? (
              t('pages.systemIntegrationRelationsPage.notSelected')
            ) : (
              <>
                {item.serviceEndpointTo.async
                  ? t('pages.systemIntegrationRelationsPage.async')
                  : t('pages.systemIntegrationRelationsPage.sync')}
              </>
            ),
        },
        {
          key: 'documents',
          content: <DocumentsButton documents={item.serviceEndpointTo.documents || []} />,
        },
        {
          key: 'dropdownMenu',
          content: checkedPermissions(scopes.integration.integrationViewSection) && systemId && (
            <Dropdown
              id={item.uuid}
              optionData={optionsData}
              onFilterChange={id => onFilterChange(id, item)}
            />
          ),
        },
      ],
    };
  });

  return (
    <>
      <div className="font-weight-semiBold font-14 pb-2 pl-4">
        {t('pages.systemIntegrationRelationsPage.integrationInSystem')}
      </div>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        isLoading={isLoading}
        total={data?.meta.total}
        requestFunction={integrationsRequestFunction}
        requestParams={integrationsRequestParams}
        withStandartPagination
        tableName={`integration-${systemUuid}`}
        expandedKey={expandedKeys.integrations}
      />
      <WarningModal
        title={t('pages.systemIntegrationRelationsPage.integrationEdit')}
        warningColor="#f3c051"
        {...editServices}
      >
        {t('pages.systemIntegrationRelationsPage.changeServiceWarning', [
          editServices.modalData?.serviceEndpointTo.name,
          editServices.modalData?.systemTo.name,
        ])}
        <S.RowButtonsWrapper>
          <BlueButton onClick={() => onEditOtherServicesClick(editServices.modalData?.uuid)}>
            {t('pages.systemIntegrationRelationsPage.continue')}
          </BlueButton>
        </S.RowButtonsWrapper>
      </WarningModal>
    </>
  );
}

export default IntegrationsTable;
