import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ReactModalStyled = styled(ReactModal)`
  position: absolute;
  left: 37%;
  top: 25%;
  border: 1px solid #cccccc;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 0px 8px;
`;

export const Form = styled.form.attrs({
  className: 'd-sm-flex flex-column mx-4 mb-4',
})`
  width: 640px;
  gap: 24px;
`;
