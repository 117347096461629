import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import isBoolean from 'lodash/isBoolean';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { notActiveSystemSelector } from 'store/systems/selectors';
import { BlueButton } from 'components/UI/Button';
import ModalHeader from 'components/Common/ModalHeader';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import ServicePorts from 'pages/SystemRouter/SystemServicesRouter/AddServicePage/ServiceForm/ServicePorts';
import {
  serviceTypeKeys,
  getServiceFormTooltip,
  getServiceTypeOptions,
  getServiceTypeNames,
} from 'constants';
import { getProgressSystemMessage } from 'constants/tooltips';

import * as S from './styles';

function MultipleEditModal({
  isModalVisible,
  toggleModal,
  modalData,
  callback,
  isMassChecked,
  checkedUuids,
  groupUuidValue,
  excludedIds = [],
  checkedRepositoriesFormatedData,
}) {
  const { t, i18n } = useTranslation();
  const serviceTypeOptions = getServiceTypeOptions();
  const serviceFormTooltip = getServiceFormTooltip();
  const {
    params: { systemId },
  } = useRouteMatch();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { run, isSuccess, isLoading } = useAsync();
  const buildTemplates = useAsync();
  const getAllBuildTemplatesOptions = useAsync();
  const getRepositoriesInfo = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const typeValue = formMethods.watch('type');
  const isTypeWithoutFields = typeValue?.trim() && typeValue !== serviceTypeKeys.LIBRARY;
  const title = `${t('common.massEditing')} ${t('pages.systemServicesPage.ofServices')}`;

  function requestOptionsFunction(params) {
    buildTemplates.run(
      api.entry.getBuildTemplate({ limit: 10, ascending: true, orderBy: 'name', ...params })
    );
  }

  useEffect(() => {
    if (!isModalVisible) {
      buildTemplates.setData([]);
      getRepositoriesInfo.setData({});
      formMethods.reset();
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (modalData) {
      formMethods.reset(modalData);
    }
  }, [modalData]);

  useEffect(() => {
    if (isModalVisible) {
      if (!getAllBuildTemplatesOptions.data?.data?.length) {
        getAllBuildTemplatesOptions.run(api.entry.getBuildTemplate());
      }

      if (checkedRepositoriesFormatedData) {
        getRepositoriesInfo.run(
          groupUuidValue
            ? api.repositories.getRepositoriesInfo(groupUuidValue, checkedRepositoriesFormatedData)
            : api.services.getServiceInfo({
                ...(checkedRepositoriesFormatedData || {}),
                systemUuid: systemId,
              })
        );
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isSuccess) {
      callback?.(modalData?.uuid);

      toggleModal();
    }
  }, [isSuccess]);

  const onSubmit = async ({ buildTemplate, ports, path, type, integration }) => {
    const filterPorts = ports?.filter(port => port?.number);
    const formatedData = {
      integration,
      buildTemplate: Number.isInteger(buildTemplate?.id) ? { id: buildTemplate.id } : undefined,
      isRemovedTemplate: isBoolean(buildTemplate?.id) || undefined,
      path: path || undefined,
      type: type?.trim() || undefined,
      ports: filterPorts?.length ? filterPorts : undefined,
      ...(type === serviceTypeKeys.LIBRARY || !type?.trim()
        ? {
            ports: undefined,
            path: undefined,
            integration: undefined,
          }
        : {}),
      ...(type === serviceTypeKeys.INTERNAL
        ? {
            path: undefined,
            integration: undefined,
          }
        : {}),
    };

    if (groupUuidValue) {
      return run(
        api.repositories.massEditRepository(
          groupUuidValue,
          !isMassChecked
            ? { repositories: checkedUuids, repositoryTemplate: formatedData }
            : {
                excludeRepositories: excludedIds.map(item => ({ uuid: item })),
                repositoryTemplate: formatedData,
              }
        )
      );
    }

    run(
      api.services.changeAllServices(
        !isMassChecked
          ? {
              services: checkedUuids.map(item => ({ uuid: item })),
              serviceTemplate: formatedData,
              systemUuid: systemId,
            }
          : {
              excludeServices: excludedIds.map(item => ({ uuid: item })),
              serviceTemplate: formatedData,
              systemUuid: systemId,
            }
      )
    );
  };

  const searchFilter = async q => {
    try {
      const filterOptions = await buildTemplates.run(api.entry.getBuildTemplate({ limit: 10, q }));

      return generateSelectOptions(filterOptions.data, 'id', 'name');
    } catch (e) {
      return [];
    }
  };

  const optionWithoutValue = { value: ' ', label: t('common.noChanges') };

  const allBuildTemplateOptions = useMemo(
    () =>
      isModalVisible && buildTemplates.data?.data?.length
        ? generateSelectOptions(buildTemplates.data?.data, 'id', 'name')
        : [],
    [buildTemplates.data?.data]
  );

  const dynamicDescription = useMemo(() => {
    if (
      !getAllBuildTemplatesOptions.data?.data?.length ||
      !getRepositoriesInfo?.data?.currentTemplates
    ) {
      return null;
    }

    const value = getRepositoriesInfo?.data?.currentTemplates.join('; ');

    if (value?.length) {
      return `${t('pages.systemServicesPage.currentSelection')}: ${value}`;
    }
  }, [
    getAllBuildTemplatesOptions.data?.data,
    getRepositoriesInfo?.data?.currentTemplates,
    i18n.language,
  ]);

  const typeDynamicDescription = useMemo(() => {
    if (
      !getAllBuildTemplatesOptions.data?.data?.length ||
      !getRepositoriesInfo?.data?.currentTypes
    ) {
      return null;
    }

    const value = getRepositoriesInfo?.data?.currentTypes
      .map(item => getServiceTypeNames()[item])
      .join('; ');

    if (value?.length) {
      return `${t('pages.systemServicesPage.currentSelection')}: ${value}`;
    }
  }, [
    getAllBuildTemplatesOptions.data?.data,
    getRepositoriesInfo?.data?.currentTypes,
    i18n.language,
  ]);

  return (
    <S.ReactModalStyled
      isOpen={isModalVisible}
      onRequestClose={toggleModal}
      isSmallModal={isTypeWithoutFields}
      groupUuidValue={groupUuidValue}
    >
      <ModalHeader title={title} toggleModal={toggleModal} />
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <InputComponent
            label={t('pages.systemAddServicePage.buildTemplate')}
            name="buildTemplate.id"
            component={inputComponents.newSelect}
            newSelectVariant="typeahead"
            searchFilter={searchFilter}
            options={allBuildTemplateOptions}
            optionWithoutValue={[
              ...(buildTemplates.data?.meta?.offset ? [] : [optionWithoutValue]),
            ]}
            withInfiniteScroll={{
              total: buildTemplates.data?.meta?.total,
              offset: 10,
              loadMore: requestOptionsFunction,
            }}
            height="300px"
            placeholder={t('common.noChanges')}
            isLoading={buildTemplates.isLoading}
            tooltipMessage={serviceFormTooltip.buildTemplate}
            dynamicDescription={dynamicDescription}
            isModalForm
          />

          <InputComponent
            label={t('common.serviceType')}
            name="type"
            component={inputComponents.newSelect}
            options={serviceTypeOptions}
            optionWithoutValue={[optionWithoutValue]}
            placeholder={t('common.noChanges')}
            tooltipMessage={serviceFormTooltip.type}
            withInfiniteScroll
            dynamicDescription={typeDynamicDescription}
          />
          {isTypeWithoutFields && (
            <ServicePorts
              isNotRequired
              placeholder={t('common.noChanges')}
              optionWithoutValue={optionWithoutValue}
              isModalForm
            />
          )}
          {typeValue === serviceTypeKeys.EXTERNAL && (
            <div className="mt-2">
              <InputComponent
                name="integration"
                switchLabel={t('pages.systemAddServicePage.isIntegrationService')}
                withoutLabel
                component={inputComponents.switchInput}
                tooltipMessage={serviceFormTooltip.integration}
              />
            </div>
          )}

          <ActionListGroup className="mt-2">
            <Tooltip
              className={notActiveSystemMessage ? undefined : 'd-none'}
              content={notActiveSystemMessage}
              exitDelay={150}
              animationDuration={150}
            >
              <ActionListItem>
                <BlueButton
                  $marginRight
                  onClick={formMethods.handleSubmit(onSubmit)}
                  isDisabled={
                    isLoading || formMethods.formState.isSubmitting || notActiveSystemMessage
                  }
                  isLoading={isLoading || formMethods.formState.isSubmitting}
                >
                  {t('common.saveChanges')}
                </BlueButton>
              </ActionListItem>
            </Tooltip>
            <ActionListItem>
              <Button variant="link" id="cancel-button" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </S.ReactModalStyled>
  );
}

export default MultipleEditModal;
