import { createSelector } from 'reselect';

const staticAnalysisResultsState = state => state.staticAnalysisResults;

export const staticAnalysisIssuesSelector = createSelector(
  [staticAnalysisResultsState],
  ({ staticAnalysisIssues }) => staticAnalysisIssues
);

export const staticAnalysisHotspotsSelector = createSelector(
  [staticAnalysisResultsState],
  ({ staticAnalysisHotspots }) => staticAnalysisHotspots
);
