import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';
import useStorage from 'hooks/useStorage';
import { expandedKeys } from 'components/Common/DataTable/hooks/useTableExpandedItems';
import { fetchIntegrationSystems } from 'store/integrationSystems/actions';
import { integrationSystemsSelector } from 'store/integrationSystems/selectors';
import paths from 'constants/paths';
import NewDataTable, { modifierKeys, types } from 'components/Common/NewDataTable';
import ContactsInfoModal from 'components/Common/SystemHeader/ContactsInfoModal';
import { TextButton } from 'components/UI/Button';
import IntegrationsTable from './IntegrationsTable';
import { tableFiltersToQueryParams } from '../../formatters';

function IntegrationSystemsTable({
  filterParams,
  setIsShowFilters,
  hasSelectedFilters,
  addButton,
}) {
  const { t } = useTranslation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    params: { systemId },
  } = useRouteMatch();
  const headerData = [
    {
      key: 'showDeploy',
      label: '',
      modifier: modifierKeys.fitContent,
    },
    {
      key: 'code',
      label: t('pages.systemAddEditIntegrationsPage.code'),
      width: 80,
    },
  ];
  const { data, total, isLoading } = useSelector(integrationSystemsSelector);
  const contactsModal = useModal();

  const { storageData, setToStorage } = useStorage('extendedIntegrations');

  const [isExtendedIntegrations, setIsExtendedIntegrations] = useState(
    storageData?.extendedIntegrations || []
  );

  useEffect(() => {
    if (isExtendedIntegrations && data.length) {
      setToStorage({ extendedIntegrations: isExtendedIntegrations });
    }
  }, [isExtendedIntegrations]);

  function requestFunction(requestParams) {
    dispatch(fetchIntegrationSystems(tableFiltersToQueryParams(requestParams)));
  }
  const requestParams = useMemo(
    () => ({
      ...filterParams,
      system: systemId,
    }),
    [systemId, filterParams]
  );

  const showNoDataPerFiltersMessage = useMemo(
    () => Object.keys(filterParams || {}).length,
    [total]
  );

  useEffect(() => {
    setIsShowFilters(hasSelectedFilters || Boolean(total));
  }, [total]);

  const formattedBodyData = [...data]
    .sort((a, b) => (a.shortName > b.shortName ? 1 : -1))
    .map(item => ({
      id: item.uuid,
      data: [
        {
          key: 'showDeploy',
          type: types.expendedContent,
          onClick: () =>
            setIsExtendedIntegrations(prevState =>
              prevState.includes(item.uuid)
                ? prevState.filter(id => id !== item.uuid)
                : [...prevState, item.uuid]
            ),
          isExpanded: isExtendedIntegrations.includes(item.uuid),
          content: (
            <IntegrationsTable
              requestParams={requestParams}
              requestFunction={requestFunction}
              systemUuid={item.uuid}
            />
          ),
        },
        {
          key: 'code',
          content: (
            <div className="d-md-flex flex-column">
              <TextButton
                variant="link"
                href={generatePath(paths.routePaths.system, {
                  systemId: item.uuid,
                  systemType: item.type,
                })}
              >
                <div className="font-24">{item.shortName}</div>
              </TextButton>
              <div className="font-16 font-weight-normal text-gray-light">{item.name}</div>
            </div>
          ),
        },
      ],
    }));

  return (
    <>
      <NewDataTable
        ref={tableRef}
        headerData={headerData}
        bodyData={formattedBodyData}
        total={total}
        isLoading={isLoading}
        requestParams={requestParams}
        requestFunction={requestFunction}
        withStandartPagination
        withoutTableHead
        compactVariant
        tableName="integrationsSystems"
        expandedKey={expandedKeys.integrationsSystems}
        expandedRowsCount={headerData.length}
        hasSelectedFilters={hasSelectedFilters}
        addButton={addButton}
        showNoDataPerFiltersMessage={showNoDataPerFiltersMessage}
      />
      <ContactsInfoModal
        key={`${contactsModal.modalData?.uuid} + 'contacts'`}
        developer={contactsModal.modalData?.developers[0]}
        responsible={contactsModal.modalData?.responsible}
        {...contactsModal}
      />
    </>
  );
}

export default IntegrationSystemsTable;
