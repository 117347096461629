import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';

import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import { currentSystemLoadingSelector, currentSystemSelector } from 'store/systems/selectors';

const ProtectedSystemRoute = ({
  exact,
  path,
  scope,
  isExternal,
  isPlatformScope,
  component: Component,
}) => {
  const { checkedPermissions, ErrorRedirectComponent } = useUserPermissions();
  const currentSystem = useSelector(currentSystemSelector);
  const systemLoading = useSelector(currentSystemLoadingSelector);

  const isPlatform =
    currentSystem?.responsible || systemLoading
      ? false
      : Object.values(scopes.platform).includes(scope);

  if (
    isExternal ||
    (currentSystem === undefined && !systemLoading) ||
    (currentSystem === null && systemLoading === null)
  ) {
    return (
      <Route
        exact={exact}
        path={path}
        component={
          scope === scopes.tier.tierViewForm ||
          scope === scopes.system.systemViewForm ||
          checkedPermissions(scope, true)
            ? Component
            : ErrorRedirectComponent
        }
      />
    );
  }

  if (scope && currentSystem && systemLoading === false) {
    return (
      <Route
        exact={exact}
        path={path}
        component={
          checkedPermissions(scope, isPlatformScope || isPlatform)
            ? Component
            : ErrorRedirectComponent
        }
      />
    );
  }

  if (!scope || systemLoading === true || (systemLoading === false && currentSystem === null)) {
    return <Route exact={exact} path={path} component={Component} />;
  }

  return <Route exact={exact} path={path} component={ErrorRedirectComponent} />;
};

export default ProtectedSystemRoute;
