import { useState } from 'react';
import useWebsocket, { ReadyState } from 'react-use-websocket';
import isNil from 'lodash/isNil';
import { protocolNames } from 'constants';

import { getAccessToken } from './useKeycloakAuth';

const ENV = window._env_;
const CALLBACK_THROTTLE_TIME = 2000;
const websocketReconnectInterval = Number(ENV.WEBSOCKET_RECONNECT_INTERVAL) || 5;

const getSerializedParams = params => {
  const str = [];

  Object.keys(params)
    .filter(key => !isNil(params[key]))
    .forEach(key => {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    });

  return str.join('&');
};

const getUrlWithParams = (topic, params) => {
  const accessToken = getAccessToken();
  const paramsString = getSerializedParams({ ...params, accessToken });
  const socketOrigin = ENV.HYPERION_API_BASE_URL.replace(protocolNames.https, protocolNames.wss);

  return `${socketOrigin}/api/portal/${topic}?${paramsString}`;
};

function useSocketAsync({
  topic,
  queryParams = {},
  filterByUuid,
  filterBySystemUuid,
  filterFn,
  onOpen,
  onMessage,
  onClose,
  onError,
  options = {},
  debounceTime,
}) {
  const [timerId, setTimerId] = useState(null);

  const url = getUrlWithParams(topic, queryParams);
  const { sendMessage, lastMessage, readyState } = useWebsocket(url, {
    share: true,
    onOpen,
    onMessage: message => {
      if (typeof onMessage !== 'function') {
        return;
      }

      const data = JSON.parse(message.data);

      if (filterBySystemUuid && data.object.system?.uuid !== filterBySystemUuid) {
        return;
      }

      if (filterByUuid && data.object?.uuid !== filterByUuid) {
        return;
      }

      if (filterFn && !filterFn(data)) {
        return;
      }

      if (debounceTime) {
        if (timerId) {
          clearTimeout(timerId);
        }

        const newTimerId = setTimeout(() => onMessage(data), debounceTime);

        setTimerId(newTimerId);

        return;
      }

      const callBackThrottleTime = options?.callBackThrottleTime || CALLBACK_THROTTLE_TIME;

      setTimeout(() => onMessage(data), callBackThrottleTime);
    },
    onClose,
    onError,
    retryOnError: true,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: attemptNumber =>
      Math.min(2 ** attemptNumber * (websocketReconnectInterval * 1000), 50000),
    ...options,
  });

  return {
    isOpen: readyState === ReadyState.OPEN,
    isClosed: readyState === ReadyState.CLOSED,
    isPending: readyState === ReadyState.CONNECTING,
    lastMessage,
    sendMessage,
  };
}

export default useSocketAsync;
