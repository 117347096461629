import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useAsync from 'hooks/useAsync';
import { useTranslation } from 'react-i18next';
import { currentSystemSelector, currentSystemLoadingSelector } from 'store/systems/selectors';

import { getSystemUserInfoMessage } from 'constants/tooltips';

import UserSelect from '../../../../AdminRouter/UsersRouter/UserCreatePage/UserSelect';
import { SystemBlockWrapper } from '../../styles';
import { checkedTierTotal } from '../../../../../utils';

function ResponsibleUser() {
  const { t } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const systemUserInfoMessage = getSystemUserInfoMessage();
  const currentSystem = useSelector(currentSystemSelector);
  const currentSystemLoading = useSelector(currentSystemLoadingSelector);
  const getCurrentSystem = useAsync();
  const { watch, setValue } = useFormContext();
  const [isUserHaveAlerts, setIsUserHaveAlerts] = useState(null);

  const responsibleValue = watch(`responsible`);
  const userOptionValue = watch('userOption');
  const isEditableUser =
    systemId && currentSystem?.responsible?.uuid === responsibleValue ? false : isUserHaveAlerts;
  const hasInvalidTiers = !!checkedTierTotal(currentSystem?.totalSummary?.tierTotal);
  const isDisabled =
    hasInvalidTiers ||
    (systemId &&
      !currentSystemLoading &&
      !checkedPermissions(scopes.system.systemUpdateResponsible));
  const initialUserOptions = userOptionValue?.login ? userOptionValue : currentSystem?.responsible;
  useEffect(() => {
    if (isBoolean(isUserHaveAlerts)) {
      setValue('isUserHaveAlerts', isUserHaveAlerts);
    }
  }, [isUserHaveAlerts]);

  useEffect(() => {
    if (systemId && responsibleValue) {
      getCurrentSystem.run(api.systems.getSystem(systemId));
    }
  }, [responsibleValue]);

  useEffect(() => {
    setValue('step', 2);
  }, []);

  return (
    <SystemBlockWrapper>
      <div className="font-20 font-weight-medium">{t('pages.addNewSystemPage.responsible')}</div>

      <UserSelect
        fieldName="responsible"
        initialOptions={initialUserOptions}
        disabled={isDisabled}
        checkedValue={responsibleValue}
        tooltip={systemUserInfoMessage}
        setIsUserHaveAlerts={setIsUserHaveAlerts}
        selectedSystem={systemId && getCurrentSystem.data}
        isEditableUser={isEditableUser}
      />
    </SystemBlockWrapper>
  );
}

export default ResponsibleUser;
