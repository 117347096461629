import React from 'react';
import { downloadFile } from 'utils';

import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import Dropdown from 'components/Dropdown';
import { useTranslation } from 'react-i18next';

function DocumentActions({ document, deleteDocumentFormModal }) {
  const { t } = useTranslation();
  const { notActiveOrErrorSystemMessage, optionsFilterByScopes } = useUserPermissions(
    document?.system
  );

  const downloadFileClick = async () => {
    const response = await api.documents.download(document.uuid);
    downloadFile(response.data, document.name, document.fileName.split('.').pop());
  };

  const onFilterChange = id => {
    if (id === 'download') {
      return downloadFileClick();
    }

    deleteDocumentFormModal.setModalData(document);
    deleteDocumentFormModal.toggleModal();
  };

  const optionData = optionsFilterByScopes([
    {
      id: 'download',
      name: t('pages.systemDocumentsPage.download'),
    },
    {
      id: 'delete',
      name: t('common.delete'),
      isDisabled: notActiveOrErrorSystemMessage,
      tooltip: notActiveOrErrorSystemMessage,
      scope: scopes.document.documentDelete,
    },
  ]);

  return (
    <div className="float-right">
      {!!optionData.length && (
        <Dropdown
          id={document?.uuid}
          optionData={optionData}
          onFilterChange={value => onFilterChange(value)}
          withoutPadding
          buttonLabel={t('pages.systemDocumentsPage.changeDocument', [document?.name])}
        />
      )}
    </div>
  );
}

export default DocumentActions;
