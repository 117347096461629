import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #21242c;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  margin-top: 76px;
  width: 320px;
  height: 100%;
  display: flex;
  transform: translateX(-100%);
  transition: 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  height: -webkit-fill-available;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #4f5255;
  }

  ${({ showLeftMenu }) => showLeftMenu && 'transform: translateX(0);'};
`;
