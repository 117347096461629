import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatFilterOptions } from 'utils';
// import { Progress, ProgressMeasureLocation, ProgressSize } from '@patternfly/react-core';

import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { fetchServiceVersionsUnitTesting } from 'store/serviceVersionsBuildResults/actions';
import { serviceVersionsBuildResultsSelector } from 'store/serviceVersionsBuildResults/selectors';
import TextBlock from 'components/UI/TextBlock';
import { buildResultTypeKeys, getBuildResultTypeNames, getUnitTestingStatusNames } from 'constants';

import ServiceVersionsUnitTestingTable from './ServiceVersionsUnitTestingTable';
import BuildResults from '../BuildResults';

const tableName = 'serviceVersionsUnitTesting';

function ServiceVersionsUnitTesting() {
  const { t } = useTranslation();
  const unitTestingStatusNames = getUnitTestingStatusNames();
  const buildResultTypeNames = getBuildResultTypeNames();
  const {
    params: { unitTestingId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { data } = useSelector(serviceVersionsBuildResultsSelector);
  const unitTesting = data.find(item => item.type === buildResultTypeKeys.UNIT_TESTING);
  const filterData = [
    {
      id: 'name',
      name: t('common.name'),
    },
    {
      id: 'className',
      name: t('pages.systemServiceVersionPage.class'),
    },
    {
      id: 'status',
      name: t('common.status'),
      type: 'multiSelect',
      options: formatFilterOptions(unitTestingStatusNames),
    },
  ];
  const { filterParams, hasSelectedFilters, FilterComponent } = useMultiSelectFilterSection(
    filterData,
    tableName
  );

  const requestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'status',
      ascending: true,
      buildResultUuid: unitTestingId,
    }),
    [filterParams]
  );
  const requestFunction = params => dispatch(fetchServiceVersionsUnitTesting(params));

  return (
    <>
      <div className="px-4 pt-4 pb-3">
        <h2 className="font-20 font-weight-medium mb-4">
          {buildResultTypeNames[buildResultTypeKeys.UNIT_TESTING]}
        </h2>
        <div className="d-flex flex-row">
          <div className="mr-4 pr-4">
            <TextBlock greyColor title={t('pages.systemServiceVersionPage.result')}>
              <BuildResults
                withoutTooltip
                totalSummary={unitTesting?.totalSummary}
                type={unitTesting?.type}
              />
            </TextBlock>
          </div>
          {/* FIX IT */}
          {/* <div className="mt-3" style={{ width: '340px' }}>
          <TextBlock greyColor title="Покрытие">
            <div className="min-line-height font-14 mb-2">22%</div>
            <Progress
              value={22}
              title=""
              size={ProgressSize.sm}
              measureLocation={ProgressMeasureLocation.none}
            />
          </TextBlock>
        </div> */}
        </div>
      </div>

      <div className="ml-4">{FilterComponent}</div>
      <ServiceVersionsUnitTestingTable
        requestParams={requestParams}
        requestFunction={requestFunction}
        hasSelectedFilters={hasSelectedFilters}
        tableName={tableName}
      />
    </>
  );
}

export default ServiceVersionsUnitTesting;
