import styled from 'styled-components';
import ExclamationTriangleSVG from 'assets/images/system_page/exclamation-triangle.svg';

export const ExclamationTriangleIcon = styled.div.attrs({
  className: 'mr-3',
})`
  min-width: 27px;
  height: 24px;
  background: ${({ color }) => color ?? '#c71c19'};
  -webkit-mask-image: url(${ExclamationTriangleSVG});
  mask-image: url(${ExclamationTriangleSVG});
`;

export const ModalBody = styled.form.attrs({
  className: 'text-word-break-wrap p-4',
})`
  width: ${({ $width }) => $width || '520px'};
`;
