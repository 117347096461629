import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getReleaseNameValidation } from 'constants/vaidations';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { getTagNumber } from 'utils';

import { ReleaseBlockWrapper } from '../styles';

const ENV = window._env_;
const tagFormatRequirementsUrl = ENV.TAG_FORMAT_REQUIREMENTS_URL;

function GeneralInformation({ valudedStep, lastVersionName, isLoading }) {
  const { t, i18n } = useTranslation();
  const {
    params: { systemId, releaseId },
  } = useRouteMatch();
  const { watch, setValue } = useFormContext();
  const name = watch('name');
  const releaseNameValidation = useMemo(
    () => getReleaseNameValidation(systemId, releaseId),
    [systemId, releaseId]
  );
  const nameMessage = t('pages.systemAddReleasesPage.nameMessage');
  const hotfixMessage = t('pages.systemAddReleasesPage.hotfixMessage');

  useEffect(() => {
    setValue('step', 1);
  }, []);

  useEffect(() => () => valudedStep(1), []);

  const dynamicDescription = useMemo(() => {
    if (name?.length && !getTagNumber(name)) {
      return (
        <span>
          {t('pages.systemAddReleasesPage.notMatchMessage')}
          <a className="" href={tagFormatRequirementsUrl} target="_blank" rel="noreferrer">
            {' '}
            {t('pages.systemAddReleasesPage.seeDetails')}
          </a>
        </span>
      );
    }

    if (lastVersionName?.length && !releaseId) {
      return `${t('pages.systemAddReleasesPage.lastVersion')}: ${lastVersionName}`;
    }
  }, [lastVersionName, name, i18n.language]);

  return (
    <ReleaseBlockWrapper>
      <div className="font-20 font-weight-medium mb-4">{t('common.generalInformation')}</div>
      <div className="mb-4">
        <InputComponent
          label={t('common.version')}
          name="name"
          isRequired
          validationRules={releaseNameValidation}
          tooltipMessage={nameMessage}
          description={t('validation.maxLength', [50])}
          maxLength={50}
          dynamicDescription={dynamicDescription}
          isLoading={isLoading}
        />
      </div>
      <div className="mb-4">
        <InputComponent
          label={t('pages.systemAddReleasesPage.detailedDescription')}
          name="description"
          component={inputComponents.textarea}
          description={t('validation.maxLength', [255])}
          maxLength={255}
        />
      </div>
      <div className="mt-1">
        <InputComponent
          name="hotfix"
          switchLabel={t('common.hotfix')}
          withoutLabel
          component={inputComponents.switchInput}
          tooltipMessage={hotfixMessage}
        />
      </div>
    </ReleaseBlockWrapper>
  );
}

export default GeneralInformation;
