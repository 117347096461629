import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { formatFilterOptions } from 'utils';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useAsync from 'hooks/useAsync';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import useMultiSelectFilterSection from 'hooks/useMultiSelectFilterSection';
import { releaseStatusKeys, getIssueNewStatusNames, getIssueStatusNames } from 'constants';
import paths from 'constants/paths';
import { routePaths } from 'constants/paths/routePaths';
import { releaseActions } from 'constants/paths/systemReleasesPaths';

import ReleaseTasksTable from './ReleaseTasksTable';

function ReleaseTasks({ approveRelease, changeRelease, setTasksTotal, data, run }) {
  const { t } = useTranslation();
  const history = useHistory();
  const issueStatusNames = getIssueStatusNames();
  const issueNewStatusNames = getIssueNewStatusNames();
  const {
    params: { systemId, releaseId },
  } = useRouteMatch();
  const { checkedPermissions } = useUserPermissions();
  const allIssuesInRelease = useAsync();
  const [isShowFilters, setIsShowFilters] = useState(false);

  const filterData = [
    {
      id: 'issueName',
      name: t('common.name'),
    },
    {
      id: 'milestoneName',
      name: t('common.milestone'),
    },
    {
      id: 'status',
      type: 'multiSelect',
      name: t('common.status'),
      options: formatFilterOptions(
        data?.status !== releaseStatusKeys.NEW ? issueStatusNames : issueNewStatusNames
      ),
    },
  ];

  const { filterParams, FilterComponent, hasSelectedFilters } = useMultiSelectFilterSection(
    filterData,
    'releaseTasks'
  );

  function releaseIssuesRequestFunction(params) {
    allIssuesInRelease.run(api.releases.getReleaseIssues(releaseId, params));
  }

  const releaseIssuesRequestParams = useMemo(
    () => ({
      ...filterParams,
      orderBy: 'startDate',
      ascending: false,
    }),
    [filterParams]
  );

  useEffect(() => {
    if (approveRelease.isSuccess || changeRelease.isSuccess) {
      run?.(api.releases.getRelease(releaseId));
      allIssuesInRelease.run(api.releases.getReleaseIssues(releaseId, releaseIssuesRequestParams));

      history.push(
        generatePath(routePaths.system + paths.systemReleasesFullPaths.change, {
          systemId,
          systemType: paths.systemTypes.internal,
          releaseId,
          action: checkedPermissions(scopes.deployment.deploymentCreate)
            ? releaseActions.deploy
            : releaseActions.deployToDev,
        })
      );
    }
  }, [approveRelease.isSuccess, changeRelease.isSuccess]);

  useEffect(() => {
    if (!isShowFilters) {
      setIsShowFilters(hasSelectedFilters || Boolean(allIssuesInRelease.data?.meta?.total));
    }
  }, [allIssuesInRelease.data?.meta?.total, hasSelectedFilters]);

  useEffect(() => {
    if (setTasksTotal) {
      setTasksTotal(allIssuesInRelease.data?.meta?.total);
    }
  }, [allIssuesInRelease.data?.meta?.total]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mt-4 mx-4">
        <div className="d-flex align-items-start">{isShowFilters && FilterComponent}</div>
      </div>

      <ReleaseTasksTable
        isApprovedRelease={data?.status === releaseStatusKeys.APPROVED}
        filterParams={filterParams}
        releaseIssuesRequestParams={releaseIssuesRequestParams}
        allIssuesInRelease={allIssuesInRelease}
        requestFunction={releaseIssuesRequestFunction}
        hasSelectedFilters={hasSelectedFilters}
        isShowFilters={isShowFilters}
      />
    </div>
  );
}

export default ReleaseTasks;
