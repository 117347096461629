import styled from 'styled-components';

import IcoEmptyPNG from 'assets/images/notifications/ico-empty-notification.png';

export const NoDataImg = styled.span`
  display: inline-block;
  width: 80px;
  height: 80px;
  background: url(${IcoEmptyPNG}) no-repeat;
  background-size: cover;
  margin-left: 5px;
`;

export const Wrapper = styled.div.attrs({
  className: 'p-2 p-md-3 p-lg-4 bg-white system-card',
})`
  min-height: 278px;
`;

export const NameWrapper = styled.span.attrs({
  className: 'hyphens',
})`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamps }) => lineClamps};
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
