import http from 'api/config';
// import { getComponentsMock } from './mock';

const getIntegrationSystems = params => http.get(`integrations/systems`, { params });

const getIntegrations = params => http.get(`integrations`, { params });

const getIntegrationById = integrationId => http.get(`integrations/${integrationId}`);

const addNewIntegration = body => http.post(`integrations`, body);

const changeIntegration = body => http.put(`integrations`, body);

const allIntegrationTiersOptions = params => http.get(`tiers/options`, { params });

const allIntegrationServicesOptions = params =>
  http.get(`integrations/services/options`, { params });

const deleteIntegration = integratioinId => http.delete(`integrations/${integratioinId}`);

export default {
  getIntegrationSystems,
  getIntegrations,
  getIntegrationById,
  addNewIntegration,
  changeIntegration,
  allIntegrationTiersOptions,
  allIntegrationServicesOptions,
  deleteIntegration,
};
