export const staticAnalysisAction = {
  problems: 'problems',
  unsafeAreas: 'unsafeAreas',
};

export const systemServiceVersionsPaths = {
  details: `/:serviceVersionId`,
  new: `/new`,
  vulnerabilityId: `/:serviceVersionId/vulnerability-analysis/:vulnerabilityId`,
  unitTestingId: `/:serviceVersionId/unit-testing/:unitTestingId`,
  staticAnalysisId: `/:serviceVersionId/static-analysis/:staticAnalysisId`,
  problems: `/:serviceVersionId/static-analysis/:staticAnalysisId/:action(${staticAnalysisAction.problems})`,
  unsafeAreas: `/:serviceVersionId/static-analysis/:staticAnalysisId/:action(${staticAnalysisAction.unsafeAreas})`,
};
