import { systemTypesKeys } from 'constants';

export const systemTypes = {
  internal: systemTypesKeys.INTERNAL,
  external: systemTypesKeys.EXTERNAL,
};

export const newSystemPaths = {
  add: `:systemType(${systemTypes.internal}|${systemTypes.external})`,
};
