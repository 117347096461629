import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import useFormDynamicFields from 'hooks/useFormDynamicFields';
import { DeleteButton } from 'components/UI/DeleteButton';
import { WhiteButton } from 'components/UI/Button';
import InputComponent from 'components/UI/InputComponent';
import { dictionaryValueErrorMessage } from 'constants/vaidations';
import { RedText } from 'components/UI/InputComponent/styles';
import { getTooltipMessage } from 'constants/tooltips';
import TooltipComponent from 'components/TooltipComponent';
import * as S from './styles';

const dynamicParentField = 'attributes';

function DictionaryForm({ dictionariesId, attributes, setCheckedValidation = null }) {
  const tooltipMessage = getTooltipMessage();
  const { t } = useTranslation();
  const { addField, deleteField, fieldUuids } = useFormDynamicFields(dynamicParentField);
  const { trigger } = useFormContext();

  const dictionaryValues = useWatch({ name: 'attributes' });

  useEffect(() => {
    if (setCheckedValidation) {
      setCheckedValidation([]);
    }
    if (dictionaryValues) {
      trigger('attributes');
    }
  }, [dictionaryValues]);

  return (
    <>
      {dictionariesId ? (
        <S.MainTitle>{t('pages.adminDictionaries.newValues')}</S.MainTitle>
      ) : (
        <S.SubTitle>
          {t('pages.adminDictionaries.values')}
          <TooltipComponent message={tooltipMessage.requiredField}>
            <RedText> *</RedText>
          </TooltipComponent>
        </S.SubTitle>
      )}
      {fieldUuids.map((key, index) => (
        <S.ValueBlockWrapper key={key}>
          <InputComponent
            name={`${dynamicParentField}[${index}]`}
            isRequired
            validationRules={{
              validate: value => {
                const currentValue = attributes?.find(
                  item => item.name.toLowerCase() === value.toLowerCase()
                );

                if (setCheckedValidation && currentValue) {
                  setCheckedValidation([currentValue.id]);
                }

                return currentValue
                  ? `${t('pages.adminDictionaries.valueAlreadyExists')} (ID = ${currentValue.id})`
                  : index === dictionaryValues.indexOf(value) || dictionaryValueErrorMessage;
              },
            }}
            tooltipMessage={`${t('pages.adminDictionaries.cannotDuplicateValue')} ${
              tooltipMessage.requiredField
            }`}
          />
          {(dictionariesId || index > 0) && (
            <DeleteButton $marginTop={8} onClick={() => deleteField(key, index)} />
          )}
        </S.ValueBlockWrapper>
      ))}
      <WhiteButton onClick={addField}>+ {t('common.add')}</WhiteButton>
    </>
  );
}
export default DictionaryForm;
