export const integrationsAction = {
  editService: 'editService',
  editTiersBound: 'editTiersBound',
};

export const systemIntegrationsPaths = {
  new: `/new`,
  add: '/add',
  editService: `/:integrationId/:action(${integrationsAction.editService})`,
  editTiersBound: `/:integrationId/:action(${integrationsAction.editTiersBound})`,
};
