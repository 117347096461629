export const usersTableFiltersToQueryParams = tableFilter => {
  const { status, role, ...filters } = tableFilter;

  const output = {
    ...filters,
    enabled: !status?.length || status.length === 2 ? undefined : status?.[0] === 'ACTIVE',
    role: role?.length === 1 ? role[0] : undefined,
  };

  Object.keys(output).forEach(key => {
    if (typeof output[key] === 'string') {
      output[key] = output[key]?.trim() || undefined;
    }
  });

  return output;
};

export const userRolesFiltersToQueryParams = tableFilter => {
  const { role, fieldToFilter, q, isConcatData, ...filters } = tableFilter;
  const isResponsible = role?.includes('isResponsible');
  const userRoles = role?.filter(item => item !== 'isResponsible');

  return {
    ...filters,
    [fieldToFilter]: q?.trim() || undefined,
    role: userRoles?.length === 1 ? userRoles[0] : undefined,
    isResponsible,
  };
};
