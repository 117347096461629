import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useUploadButton from 'hooks/useUploadButton';
import useSocketAsync from 'hooks/useSocketAsync';
import { notActiveServerTierSelector } from 'store/serverTiers/selectors';
import { changeCurrentServerTier, resetCurrentServerTier } from 'store/serverTiers/reducers';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { InputsBlockWrapper } from 'components/UI/View';
import { getDefaultNumberValidateMessage, tooltipFormDisableMessage } from 'constants/vaidations';
import paths from 'constants/paths';
import { generateSelectOptions } from 'components/UI/InputComponent/utils';
import {
  unitMemoryOptions,
  getLimitsLabels,
  limitsKeys,
  documentsTypeKeys,
  cpuUnitKeys,
  equipmentCpuOptions,
  unitMemoryKeys,
} from 'constants';
import { declOfNum, getConvertibleCpuQuantities } from 'utils';

import { Form } from './styles';
import { getProgressServerTierMessage } from '../../../../constants/tooltips';

const defaultModel = {
  CPU: { value: '', unitType: cpuUnitKeys.cores },
  MEMORY: { value: '', unitType: unitMemoryKeys.gib },
  STORAGE: { value: '', unitType: unitMemoryKeys.gib },
};

function ChangeEquipmentPage() {
  const { t, i18n } = useTranslation();
  const convertibleCpuQuantities = getConvertibleCpuQuantities();
  const dispatch = useDispatch();
  const {
    params: { serverTierId, equipmentId, action },
  } = useRouteMatch();
  const { run, isLoading, isSuccess } = useAsync();
  const limitsLabels = getLimitsLabels();
  const serverTierDevice = useAsync();
  const history = useHistory();
  const notActiveServerTier = useSelector(notActiveServerTierSelector);
  const notActiveServerTierMessage = useMemo(
    () => notActiveServerTier && getProgressServerTierMessage(),
    [notActiveServerTier, i18n.language]
  );
  const fieldsDescriptionMap = {
    name: t('validation.maxLength', [60]),
    description: t('validation.maxLength', [500]),
  };
  const formMethods = useForm({
    defaultValues: defaultModel,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const cpuValue = formMethods.watch('CPU');
  const isNotEditAction =
    (!serverTierId || !equipmentId) && action !== paths.serverTiersActions.edit;

  const { UploadButtonComponent, changeFilesOnEditSubmit } = useUploadButton({
    objectType: documentsTypeKeys.EQUIPMENT,
    setValue: formMethods.setValue,
    filesToShow: serverTierDevice.data?.documents,
    parentUuid: serverTierId,
    isEditAction: !isNotEditAction,
    descriptionMaxLength: 500,
  });

  useSocketAsync({
    topic: 'server-tiers',
    filterFn: ({ object }) => serverTierId === object?.uuid,
    onMessage: async () => {
      try {
        if (serverTierId) {
          const newServerTiers = await api.serverTiers.getServerTier(serverTierId);
          dispatch(changeCurrentServerTier(newServerTiers.data));
          return;
        }
      } catch (e) {
        if (e) {
          return dispatch(resetCurrentServerTier());
        }
      }
    },
  });

  useEffect(() => {
    if (!isNotEditAction) {
      serverTierDevice.run(api.serverTiers.getServerTierDevice({ serverTierId, equipmentId }));
    }
  }, []);

  useEffect(() => {
    if (serverTierDevice.data && paths.serverTiersActions.edit === action && serverTierId) {
      formMethods.reset({
        ...serverTierDevice.data,
        CPU: serverTierDevice.data?.limits?.find(({ type }) => type === limitsKeys.CPU),
        MEMORY: serverTierDevice.data?.limits?.find(({ type }) => type === limitsKeys.MEMORY),
        STORAGE: serverTierDevice.data?.limits?.find(({ type }) => type === limitsKeys.STORAGE),
      });
    }
  }, [serverTierDevice.data]);

  const onClickGeneratePath = tab => ({
    pathname: generatePath(paths.adminFullPaths.serverTiers + paths.serverTiersPaths.show, {
      serverTierId,
    }),
    state: {
      currentTab: tab,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push(onClickGeneratePath(1));
    }
  }, [isSuccess]);

  const onSubmit = ({
    name,
    description,
    addedDocuments,
    removedDocuments,
    documentsDescription,
    ...data
  }) => {
    const requestFunction =
      serverTierId && equipmentId
        ? api.serverTiers.updateServerTierDevice
        : api.serverTiers.createServerTierDevice;

    const formatedDocuments = addedDocuments?.map(file => ({
      ...file,
      description: documentsDescription,
    }));

    const formatedData = {
      addedDocuments: formatedDocuments,
      removedDocuments,
      name,
      description,
      limits: [
        { ...data[limitsKeys.CPU], type: limitsKeys.CPU },
        { ...data[limitsKeys.MEMORY], type: limitsKeys.MEMORY },
        { ...data[limitsKeys.STORAGE], type: limitsKeys.STORAGE },
      ],
      uuid: equipmentId || uuid(),
      serverTierId,
    };

    changeFilesOnEditSubmit(documentsDescription);

    run(requestFunction(formatedData));
  };

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  const pageTitle = isNotEditAction
    ? t('pages.adminAddServerTier.createEquipment')
    : t('pages.adminAddServerTier.editEquipment');

  const getCpuOptions = useMemo(
    () =>
      generateSelectOptions(equipmentCpuOptions, 'id', null, null, {
        getLabel: option =>
          declOfNum(
            cpuValue.value,
            convertibleCpuQuantities.find(item => item.id === option.id).unit,
            false,
            true
          ),
        getUnits: option => convertibleCpuQuantities.find(item => item.id === option.id).unit,
      }),
    [cpuValue.value]
  );

  const validedFields = ['name', 'CPU.value', 'MEMORY.value', 'STORAGE.value'];

  const isDisabled =
    notActiveServerTierMessage ||
    validedFields.filter(field => !formMethods.getValues(field))?.length ||
    !!Object.keys(formMethods.formState.errors).length;

  return (
    <FormProvider {...formMethods}>
      <PageLayout title={pageTitle} fullHeight withoutSidePadding>
        <div className="w-50">
          <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputsBlockWrapper>
              <InputComponent
                label={t('common.name')}
                name="name"
                isRequired
                maxLength={60}
                description={fieldsDescriptionMap.name}
              />
            </InputsBlockWrapper>
            <InputsBlockWrapper columnsCount={3}>
              <div className="d-flex flex-row gap-1 align-items-end">
                <InputComponent
                  label={limitsLabels.CPU}
                  name="CPU.value"
                  component={inputComponents.numberInput}
                  isRequired
                  intNumber
                  errorText={t('validation.fillRequiredField')}
                  description={getDefaultNumberValidateMessage()}
                  descriptionNoWrap
                  placeholder="0"
                />
                <InputComponent
                  name="CPU.unitType"
                  component={inputComponents.newSelect}
                  options={getCpuOptions}
                  valueForNameDeclension={cpuValue.value}
                  withoutErrorsMessage
                  withoutClearButton
                  disabled
                  disabledWithoutAngelIcon
                />
              </div>
              <div className="d-flex flex-row gap-1 align-items-end">
                <InputComponent
                  label={limitsLabels.MEMORY}
                  name="MEMORY.value"
                  component={inputComponents.numberInput}
                  isRequired
                  intNumber
                  errorText={t('validation.fillRequiredField')}
                  description={getDefaultNumberValidateMessage()}
                  descriptionNoWrap
                  placeholder="0"
                />
                <InputComponent
                  name="MEMORY.unitType"
                  component={inputComponents.newSelect}
                  options={unitMemoryOptions}
                  withoutErrorsMessage
                  withoutClearButton
                />
              </div>
              <div className="d-flex flex-row gap-1 align-items-end">
                <InputComponent
                  label={limitsLabels.STORAGE}
                  name="STORAGE.value"
                  component={inputComponents.numberInput}
                  isRequired
                  intNumber
                  errorText={t('validation.fillRequiredField')}
                  description={getDefaultNumberValidateMessage()}
                  descriptionNoWrap
                  placeholder="0"
                />
                <InputComponent
                  name="STORAGE.unitType"
                  component={inputComponents.newSelect}
                  options={unitMemoryOptions}
                  withoutErrorsMessage
                  withoutClearButton
                />
              </div>
            </InputsBlockWrapper>
            <InputsBlockWrapper>
              <InputComponent
                component={inputComponents.textarea}
                label={t('pages.systemTierPage.additionalInfo')}
                name="description"
                maxLength={500}
                description={fieldsDescriptionMap.description}
              />
            </InputsBlockWrapper>

            {UploadButtonComponent}

            <ActionListGroup className="pt-4">
              <Tooltip
                content={notActiveServerTierMessage || (isDisabled && tooltipFormDisableMessage())}
                className={isDisabled ? undefined : 'd-none'}
                exitDelay={150}
                animationDuration={150}
              >
                <ActionListItem>
                  <Button
                    onClick={formMethods.handleSubmit(onSubmit)}
                    isDisabled={formMethods.formState.isSubmitting || isLoading || isDisabled}
                    isLoading={formMethods.formState.isSubmitting || isLoading}
                  >
                    {t(isNotEditAction ? 'common.add' : 'common.saveChanges')}
                  </Button>
                </ActionListItem>
              </Tooltip>
              <ActionListItem>
                <Button variant="link" id="cancel-button" className="ml-3" onClick={closeForm}>
                  {t('common.cancel')}
                </Button>
              </ActionListItem>
            </ActionListGroup>
          </Form>
        </div>
      </PageLayout>
    </FormProvider>
  );
}

export default ChangeEquipmentPage;
