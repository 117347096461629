import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import { Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import InputComponent from 'components/UI/InputComponent';
import { deletedNnameValidation } from 'constants/vaidations';

import * as S from './styles';

function WarningModal({
  children,
  isModalVisible,
  toggleModal,
  withIcon = true,
  isDisabled,
  isLoading,
  warningTooltipMessage,
  onClick,
  title,
  text,
  label = '',
  name,
  buttenText,
  warningColor = '#c71c19',
  width,
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const nameValue = formMethods.watch('name');
  const cantSubmit =
    isLoading || isDisabled || warningTooltipMessage || (label && nameValue !== name);

  useEffect(() => {
    if (isModalVisible && nameValue !== name) {
      formMethods.setValue('name', '');
    }
    if (!isModalVisible) {
      formMethods.reset();
      formMethods.clearErrors();
    }
  }, [isModalVisible]);

  const modalHeader = (
    <div className="d-flex mb-3">
      {withIcon && <S.ExclamationTriangleIcon color={warningColor} />}
      <div className="font-24 font-weight-medium hyphens">{title}</div>
    </div>
  );

  const onSubmit = e => {
    if (!cantSubmit) {
      onClick(e);
    }
  };

  const validationRules = useMemo(() => deletedNnameValidation(name), [name]);

  return (
    <ReactModal isOpen={isModalVisible} ariaHideApp={false} onRequestClose={toggleModal}>
      <FormProvider {...formMethods}>
        <S.ModalBody $width={width} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {modalHeader}

          {children && !label ? (
            <>{children}</>
          ) : (
            <div>
              {text}
              {name?.length && <b>{` ${name}`}</b>}?
              {label && (
                <div className="mt-3">
                  <span className="pre-line">{t('common.confirmDeleteMessage', [label])}</span>
                  <div className=" mt-3">
                    <InputComponent
                      label={label.replace(label[0], label[0].toUpperCase())}
                      name="name"
                      isRequired
                      validationRules={validationRules}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-row align-items-start mt-4">
            <Tooltip
              key={name}
              className={warningTooltipMessage ? undefined : 'd-none'}
              content={
                (warningTooltipMessage?.length && warningTooltipMessage) ||
                t('validation.fillRequiredField')
              }
              exitDelay={150}
              animationDuration={150}
            >
              <div className="mr-2">
                <Button onClick={onSubmit} isDisabled={cantSubmit} isLoading={isLoading}>
                  {buttenText || t('common.delete')}
                </Button>
              </div>
            </Tooltip>

            <Button variant="link" onClick={toggleModal}>
              {t('common.cancel')}
            </Button>
          </div>
        </S.ModalBody>
      </FormProvider>
    </ReactModal>
  );
}

export default WarningModal;
