export const generateSelectOptions = (data, valueKey, labelKey, descriptionKey, params) =>
  data?.map(item => ({
    value: params?.getValue ? params.getValue(item) : item[valueKey],
    label: params?.getLabel ? params.getLabel(item) : item[labelKey],
    labelTemplate: params?.getLabelTemplate ? params.getLabelTemplate(item) : null,
    disabled: params?.getDisabled ? params.getDisabled(item) : params?.isDisabled,
    tooltipMessage:
      params?.getDisabled && params?.getDisabled(item)
        ? params?.tooltipMessage
        : (params?.getTooltipMessage && params.getTooltipMessage(item)) || null,
    description: params?.getDescription
      ? params.getDescription(item)
      : descriptionKey
      ? item[descriptionKey]
      : null,
    status: params?.getStatus ? params.getStatus(item) : null,
    pipelineStatus: params?.getPipelineStatus ? params.getPipelineStatus(item) : null,
    lastUpdatedAt: params?.getLastUpdatedAt ? params.getLastUpdatedAt(item) : null,
    units: params?.getUnits ? params.getUnits(item) : null,
    meta: params?.getMeta ? params.getMeta(item) : null,
  })) ?? [];
