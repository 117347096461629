import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActionList, ActionListItem, Button, Tooltip } from '@patternfly/react-core';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useFormError from 'hooks/useFormError';
import useUploadButton from 'hooks/useUploadButton';
import { notActiveSystemSelector } from 'store/systems/selectors';
import { BlueButton } from 'components/UI/Button';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../constants/tooltips';

const defaultValues = { name: '', documents: [], description: '' };

function AddDocumentPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { errorComponent, setErrorText } = useFormError();
  const { UploadButtonComponent } = useUploadButton({
    isAddDocumentPage: true,
    withoutDescription: true,
    objectType: 'SYSTEM',
    errorComponent,
    isRequired: true,
    onlyOneDocument: true,
    setValue: formMethods.setValue,
  });
  const { run, isLoading, isSuccess } = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const name = formMethods.watch('name');
  const documentsValue = formMethods.watch('documents');
  const title = t('components.upload.addDocument');
  const onCancel = () => history.goBack();

  useEffect(() => {
    if (errorComponent && documentsValue?.length) {
      setErrorText();
    }

    if (documentsValue?.length && !name) {
      formMethods.setValue('name', documentsValue[0].name);
      formMethods.trigger('name');
    }
  }, [documentsValue]);

  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }, [isSuccess]);

  const onSubmit = ({ documents, ...formData }) => {
    if (!documents.length) {
      return setErrorText(t('components.upload.documentRequired'));
    }

    run(api.documents.changeFile(documents[0].uuid, formData));
  };

  const isDisabled =
    notActiveSystemMessage || !formMethods.formState.isValid || !name || !documentsValue?.length;

  return (
    <FormProvider {...formMethods}>
      <PageLayout title={title} fullHeight withSystemSelector withoutSidePadding>
        <S.FormWrapper onSubmit={formMethods.handleSubmit(onSubmit)}>
          <S.FormBlocksWrapper>
            <InputComponent
              label={t('common.name')}
              name="name"
              maxLength={255}
              isRequired
              description={t('validation.maxLength', [255])}
            />
            <InputComponent
              label={t('common.description')}
              name="description"
              component={inputComponents.textarea}
              maxLength={500}
              description={t('validation.maxLength', [500])}
            />

            {UploadButtonComponent}

            <ActionList>
              <Tooltip
                content={notActiveSystemMessage || (isDisabled && tooltipFormDisableMessage())}
                className={isDisabled ? undefined : 'd-none'}
                exitDelay={150}
                animationDuration={150}
              >
                <ActionListItem>
                  <BlueButton
                    onClick={formMethods.handleSubmit(onSubmit)}
                    isLoading={formMethods.formState.isSubmitting || isLoading}
                    isDisabled={isLoading || formMethods.formState.isSubmitting || isDisabled}
                  >
                    {t('common.add')}
                  </BlueButton>
                </ActionListItem>
              </Tooltip>
              <ActionListItem>
                <Button variant="link" onClick={onCancel}>
                  {t('common.cancel')}
                </Button>
              </ActionListItem>
            </ActionList>
          </S.FormBlocksWrapper>
        </S.FormWrapper>
      </PageLayout>
    </FormProvider>
  );
}

export default AddDocumentPage;
