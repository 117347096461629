import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CubesSvg } from 'assets/images/table/cubes.svg';

import { getEmptyTableInfo } from 'constants';
import ErrorPageMessage from 'pages/ErrorPage/ErrorPageMessage';

import * as S from '../styles';

function EmptyTable({
  showNoDataPerFiltersMessage,
  tableName,
  addButton,
  emptyTableMessage,
  isError,
}) {
  const { t } = useTranslation();
  const emptyTableInfo = getEmptyTableInfo();
  const text = emptyTableInfo.find(element => element.id === tableName)?.data;

  if (isError) {
    return (
      <div
        className="d-flex flex-column py-4 justify-content-center align-items-center"
        style={{ marginTop: '82px' }}
      >
        <ErrorPageMessage />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column py-4 justify-content-center align-items-center">
      {showNoDataPerFiltersMessage ? (
        <>
          <S.SearchIco />
          <p className="ml-3 py-3 font-14">
            <b>{t('components.table.noData')}</b>
          </p>
          <p className="text-gray font-14">{t('components.table.noFilterData')}</p>
        </>
      ) : (
        <>
          <CubesSvg />
          <p className="ml-3 py-3 font-20 font-weight-medium">
            {emptyTableMessage ||
              (text ? (
                <p>
                  {t('components.table.stillNotAdded')} {text}
                </p>
              ) : (
                <p>{t('components.table.stillNotAddedNothing')}</p>
              ))}
          </p>
          {addButton && <>{addButton}</>}
        </>
      )}
    </div>
  );
}

export default EmptyTable;
