import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Alert, Tooltip, ActionListItem, ActionListGroup, Button } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import useSystemPathGenerate from 'hooks/useSystemPathGenerate';
import { currentSystemSelector, notActiveSystemSelector } from 'store/systems/selectors';
import paths from 'constants/paths';
import {
  getDefaultMaxMessage,
  getMilestoneNameValidation,
  tooltipFormDisableMessage,
} from 'constants/vaidations';
import PageLayout from 'components/UI/PageLayout';
import InputComponent, { inputComponents } from 'components/UI/InputComponent';
import { dateConverter, isActualFilterFunction } from 'utils';
import { getMilestoneNameInfoMessage, getProgressSystemMessage } from 'constants/tooltips';
import LinkWithPermission from 'components/Common/LinkWithPermission';
import { scopes } from 'constants/scopes';

import * as S from './styles';

const milestonesModel = {
  name: '',
  description: '',
  startDate: '',
  endDate: '',
  contract: '',
};

function EditMilestonePage() {
  const { t, i18n } = useTranslation();
  const MilestoneNameInfoMessage = getMilestoneNameInfoMessage();
  const history = useHistory();
  const {
    params: { systemId, milestoneId, action },
  } = useRouteMatch();
  const { run, data } = useAsync();
  const allMilestones = useAsync();
  const saveMilestone = useAsync();
  const deleteMilestone = useAsync();
  const getContractsOptions = useAsync();
  const fieldsDescriptionMap = {
    name: getDefaultMaxMessage(),
    description: t('validation.maxLength', [1000]),
  };
  const currentSystem = useSelector(currentSystemSelector);
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );
  const formMethods = useForm({
    defaultValues: milestonesModel,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [mainContract, setMainContract] = useState();

  const valueContract = formMethods.watch('contract');
  const valueStartDate = formMethods.watch('startDate');
  const valueEndDate = formMethods.watch('endDate');
  const currentContract =
    currentSystem?.contracts && currentSystem.contracts?.find(item => item?.uuid === valueContract);
  const isEditAction = action === paths.issuesAction.edit;
  const title = isEditAction
    ? t('pages.systemAddMilestonePage.edit')
    : t('pages.systemAddMilestonePage.create');
  const systemNameValidation = useMemo(
    () => getMilestoneNameValidation(systemId, milestoneId),
    [systemId, milestoneId]
  );

  useEffect(() => {
    if (!mainContract && !milestoneId) {
      setMainContract(currentSystem?.contracts.find(isActualFilterFunction));
    }
  }, [currentSystem?.contracts]);

  const checkedMilestones = milestoneId
    ? allMilestones.data?.data?.filter(item => item.uuid !== milestoneId)
    : allMilestones.data?.data;
  const disabledMilestonesMessage = checkedMilestones?.map(item => (
    <p>
      <span>
        {item.name} ({dateConverter(item.startDate)}–{dateConverter(item.endDate)})
      </span>
      <br />
    </p>
  ));

  const formatOption = contract =>
    contract?.uuid && {
      value: contract.uuid,
      label: `№${contract.number} ${t('pages.systemAddMilestonePage.term')}: ${dateConverter(
        contract.startDate
      )}${contract.endDate ? `–${dateConverter(contract.endDate)}` : ''}`,
    };

  const generateContractsOptions = (contracts = []) =>
    contracts?.map(contract => formatOption(contract));

  const checkedValue = useMemo(
    () => formatOption(milestoneId ? data?.contract : mainContract),
    [data?.contract, mainContract]
  );

  const contractsOptions = useMemo(
    () => generateContractsOptions(getContractsOptions.data?.data),
    [getContractsOptions.data?.data]
  );

  const searchFilter = async q => {
    try {
      const filterOptions = await getContractsOptions.run(
        api.systems.getContracts(systemId, {
          numberQuery: q,
        })
      );

      return generateContractsOptions(filterOptions.data);
    } catch (e) {
      return [];
    }
  };

  function requestFunction() {
    run(api.milestones.getMilestonesById(milestoneId));
  }

  function requestOptionsFunction(params) {
    getContractsOptions.run(
      api.systems.getContracts(systemId, {
        limit: 10,
        ...params,
      })
    );
  }

  useEffect(() => {
    if (isEditAction) {
      requestFunction();
    }
  }, []);

  useEffect(() => {
    if (!isEditAction && mainContract) {
      formMethods.reset({
        ...formMethods.getValues(),
        contract: mainContract.uuid,
      });
    }
  }, [mainContract]);

  useEffect(() => {
    if (valueStartDate > valueEndDate) {
      formMethods.setValue('endDate', '');
    }
  }, [valueStartDate, valueEndDate]);

  useEffect(() => {
    if (valueContract && valueEndDate && valueStartDate) {
      allMilestones.run(
        api.milestones.getAllMilestones({
          system: systemId,
          contract: valueContract,
          startFrom: valueStartDate,
          endTo: valueEndDate,
        })
      );
    }
  }, [valueStartDate, valueEndDate, valueContract]);

  useEffect(() => {
    if (data && isEditAction) {
      formMethods.reset({
        name: data.name,
        description: data.description,
        contract: data.contract.uuid,
        startDate: data.startDate,
        endDate: data.endDate,
      });
    }
  }, [data]);

  useEffect(() => {
    if (saveMilestone.isSuccess) {
      history.goBack();
    }
  }, [saveMilestone.isSuccess]);

  useEffect(() => {
    if (deleteMilestone.isSuccess) {
      history.goBack();
    }
  }, [deleteMilestone.isSuccess]);

  const onSubmit = ({ name, description, startDate, endDate, contract }) => {
    const requestSubmitFunction = isEditAction
      ? api.milestones.editMilestone
      : api.milestones.createMilestone;

    const formatedData = {
      name,
      description,
      startDate,
      endDate,
      uuid: milestoneId,
      contract: {
        uuid: contract,
      },
      system: { uuid: systemId },
    };

    saveMilestone.run(requestSubmitFunction(formatedData, milestoneId));
  };

  const warningAlert = useMemo(() => {
    if (valueStartDate && currentContract?.startDate > valueStartDate) {
      return t('pages.systemAddMilestonePage.milestoneStartsBeforeContract');
    }

    if (valueStartDate && currentContract?.endDate < valueStartDate) {
      return t('pages.systemAddMilestonePage.milestoneStartsAfterContract');
    }

    if (currentContract?.endDate < valueEndDate) {
      return t('pages.systemAddMilestonePage.milestoneEndsAfterContract');
    }

    if (disabledMilestonesMessage?.length) {
      const text = (
        <div>
          <>
            {disabledMilestonesMessage?.length > 1
              ? t('pages.systemAddMilestonePage.milestonesAlreadyExists')
              : t('pages.systemAddMilestonePage.milestonesAlreadyExist')}
          </>
          <br />
          {disabledMilestonesMessage}
        </div>
      );

      return text;
    }
  }, [disabledMilestonesMessage, currentContract, valueStartDate, valueEndDate]);

  const closeForm = () => {
    formMethods.reset();
    history.goBack();
  };

  const validedFields = ['name', 'contract', 'startDate', 'endDate'];

  const isDisabled =
    notActiveSystemMessage ||
    warningAlert ||
    validedFields.filter(field => !formMethods.getValues(field))?.length ||
    !!Object.keys(formMethods.formState.errors).length;

  return (
    <>
      <FormProvider {...formMethods}>
        <PageLayout title={title} fullHeight withSystemSelector withoutSidePadding withoutFlex>
          <S.TasksForm onSubmit={formMethods.handleSubmit(onSubmit)}>
            <S.TasksBlockWrapper>
              <InputComponent
                label={t('common.name')}
                name="name"
                validationRules={systemNameValidation}
                isRequired
                maxLength={150}
                description={fieldsDescriptionMap.name}
                tooltipMessage={`${MilestoneNameInfoMessage}`}
              />
              <InputComponent
                label={t('common.description')}
                name="description"
                component={inputComponents.textarea}
                maxLength={1000}
                description={fieldsDescriptionMap.description}
              />
              <S.InputComponentWrapper>
                <InputComponent
                  label={t('common.contract')}
                  name="contract"
                  component={inputComponents.newSelect}
                  newSelectVariant="typeahead"
                  searchFilter={searchFilter}
                  options={contractsOptions}
                  checkedValue={checkedValue}
                  withInfiniteScroll={{
                    total: getContractsOptions.data?.meta.total,
                    offset: 10,
                    loadMore: requestOptionsFunction,
                  }}
                  isLoading={getContractsOptions.isLoading}
                  placeholder={t('common.searchByName')}
                  isRequired
                />
                <div
                  className={`font-12 text-gray-light ${
                    formMethods.formState.errors.contract ? 'mt-4' : 'mt-2'
                  }`}
                >
                  {`${
                    mainContract?.uuid === valueContract
                      ? `${t('pages.systemAddMilestonePage.actualContractSelected')} `
                      : ''
                  }${t('pages.systemAddMilestonePage.contractAddedOn')} `}
                  <LinkWithPermission
                    system={currentSystem}
                    scope={scopes.system.systemUpdate}
                    to={`${useSystemPathGenerate(paths.systemPaths.editSystem)}?step=3`}
                    isTableLink
                    font="12px"
                    linkStyles="d-inline text-underline"
                  >
                    {t('pages.systemAddMilestonePage.onEditSystemPage')}
                  </LinkWithPermission>
                </div>
              </S.InputComponentWrapper>
              {currentContract && (
                <>
                  <div className="d-flex flex-row">
                    <InputComponent
                      label={t('common.startDate')}
                      name="startDate"
                      component={inputComponents.dateInput}
                      isRequired
                      minDate={currentContract?.startDate}
                      maxDate={currentContract?.endDate}
                      errorText={t('validation.fillRequiredField')}
                    />
                    <div className="d-flex flex-row ml-4 w-100">
                      <InputComponent
                        label={t('common.endDate')}
                        name="endDate"
                        component={inputComponents.dateInput}
                        isRequired
                        minDate={valueStartDate || currentContract?.startDate}
                        maxDate={currentContract?.endDate}
                        errorText={t('validation.fillRequiredField')}
                      />
                    </div>
                  </div>
                  {warningAlert && <Alert variant="warning" isInline title={warningAlert} />}
                </>
              )}
              <ActionListGroup className="py-4">
                <Tooltip
                  content={notActiveSystemMessage || (isDisabled && tooltipFormDisableMessage())}
                  className={isDisabled ? undefined : 'd-none'}
                  exitDelay={150}
                  animationDuration={150}
                >
                  <ActionListItem>
                    <Button
                      onClick={formMethods.handleSubmit(onSubmit)}
                      isLoading={formMethods.formState.isSubmitting || saveMilestone.isLoading}
                      isDisabled={
                        formMethods.formState.isSubmitting || saveMilestone.isLoading || isDisabled
                      }
                    >
                      {t(milestoneId ? 'common.saveChanges' : 'common.add')}
                    </Button>
                  </ActionListItem>
                </Tooltip>
                <ActionListItem>
                  <Button variant="link" id="cancel-button" className="ml-3" onClick={closeForm}>
                    {t('common.cancel')}
                  </Button>
                </ActionListItem>
              </ActionListGroup>
            </S.TasksBlockWrapper>
          </S.TasksForm>
        </PageLayout>
      </FormProvider>
    </>
  );
}

export default EditMilestonePage;
