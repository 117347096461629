import React from 'react';
import Loader from 'react-loader-spinner';

import { LoaderWrapper } from 'components/UI/LoadingIndicator/styles';

const LoadingIndicator = ({ small }) => {
  const param = small ? 20 : 40;

  return (
    <LoaderWrapper>
      <Loader
        type="TailSpin"
        color="#048E94"
        height={param}
        width={param}
        style={{ AlignSelf: 'center' }}
      />
    </LoaderWrapper>
  );
};

export default LoadingIndicator;
