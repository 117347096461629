import { createSelector } from 'reselect';

const serviceVersionsBuildResultsState = state => state.serviceVersionsBuildResults;

export const serviceVersionsBuildResultsSelector = createSelector(
  [serviceVersionsBuildResultsState],
  serviceVersionsBuildResults => serviceVersionsBuildResults
);

export const serviceVersionsVulnerabilitiesSelector = createSelector(
  [serviceVersionsBuildResultsState],
  ({ vulnerabilities }) => vulnerabilities
);

export const serviceVersionsStaticAnalysisSelector = createSelector(
  [serviceVersionsBuildResultsState],
  ({ staticAnalysis }) => staticAnalysis
);

export const serviceVersionsUnitTestingSelector = createSelector(
  [serviceVersionsBuildResultsState],
  ({ unitTesting }) => unitTesting
);
