import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useTreeViewMultiSelectFilterSection from 'hooks/useTreeViewMultiSelectFilterSection';
import useModal from 'hooks/useModal';
import { importServiceSelector } from 'store/services/selectors';
import CheckBoxSelector from 'components/Common/CheckBoxSelector';
import { sortList } from 'utils';
import {
  getServiceImportTypeNames,
  getRepositoryStatusNames,
  repositoryTemplateNames,
  serviceImportTypeKeys,
  repositoryStatusKeys,
} from 'constants';

import RepositoriesTable from './RepositoriesTable';
import RepositoriesMultipleActions from './RepositoriesTable/RepositoriesMultipleActions';

import { ServicesSelectionWrapper } from '../styles';

function RepositoriesImport({ excludedIds, setExcludedIds, isMassChecked, setIsMassChecked }) {
  const { t, i18n } = useTranslation();
  const { setValue, watch } = useFormContext();
  const serviceTypeNames = getServiceImportTypeNames();
  const repositoryStatusNames = getRepositoryStatusNames();
  const importService = useSelector(importServiceSelector);
  const totalSummary = importService?.totalSummary;

  const templateFormated = (templates = []) => {
    if (templates.includes(repositoryTemplateNames.WITHOUT_TEMPLATE)) {
      const newTemplates = templates.filter(
        template => template !== repositoryTemplateNames.WITHOUT_TEMPLATE
      );
      const sortedTemplatesNames = sortList(
        newTemplates.map(template => repositoryTemplateNames[template])
      );
      const listWithTemplateNames = sortedTemplatesNames.map(
        name =>
          Object.keys(repositoryTemplateNames).filter(
            key => repositoryTemplateNames[key] === name
          )[0]
      );

      return [repositoryTemplateNames.WITHOUT_TEMPLATE, ...listWithTemplateNames];
    }

    return sortList(templates.map(template => repositoryTemplateNames[template]));
  };

  const filterData = useMemo(
    () => [
      {
        id: 'status',
        name: t('common.status'),
        type: 'treeMultiSelect',
        children: Object.keys(repositoryStatusKeys)
          .reverse()
          .filter(key => Object.keys(totalSummary?.statusTotal).includes(key))
          .map(key => ({
            id: key,
            name: repositoryStatusNames[key],
            parentId: 'status',
            number: totalSummary?.statusTotal[key],
          })),
      },
      {
        id: 'language',
        name: t('common.programmingLanguage'),
        type: 'treeMultiSelect',
        children: sortList(Object.keys(totalSummary?.selectedServicesLanguagesTotal || {})).map(
          key => ({
            id: key,
            name: key?.length ? key : t('common.notAssigned'),
            parentId: 'language',
            number: totalSummary?.selectedServicesLanguagesTotal[key],
          })
        ),
      },
      {
        id: 'template',
        name: t('pages.systemServicePage.buildTemplate'),
        type: 'treeMultiSelect',
        children: templateFormated(Object.keys(totalSummary?.templateTotal || {})).map(key => ({
          id: key,
          name:
            key === repositoryTemplateNames.WITHOUT_TEMPLATE
              ? t('common.notAssigned')
              : repositoryTemplateNames[key],
          parentId: 'template',
          number: totalSummary?.templateTotal[key],
        })),
      },
      {
        id: 'type',
        name: t('common.serviceType'),
        type: 'treeMultiSelect',
        children: Object.keys(serviceImportTypeKeys)
          .filter(key => Object.keys(totalSummary?.serviceTypeTotal).includes(key))
          .map(key => ({
            id: key,
            name: serviceTypeNames[key],
            parentId: 'type',
            number: totalSummary?.serviceTypeTotal[key],
          })),
      },
      {
        type: 'select',
        options: [
          { id: 'nameQuery', name: t('common.name') },
          { id: 'codeQuery', name: t('common.repositoryCode') },
        ],
      },
    ],
    [totalSummary, i18n.language]
  );

  const { filterParams, FilterComponent, ChipGroupComponent, hasSelectedFilters } =
    useTreeViewMultiSelectFilterSection(filterData);
  const multipleEditRepositoryModal = useModal();
  const multipleDeleteRepositoryModal = useModal();
  const getImportService = useSelector(importServiceSelector);
  const checkedSelectedRepositoriesValue = watch('checkedSelectedRepositories');
  const [isTotal, setIsTotal] = useState();
  const [allRenderUuids, setAllRenderUuids] = useState([]);
  const [checkedRepositoriesUuids, setCheckedRepositoriesUuids] = useState(
    checkedSelectedRepositoriesValue.map(item => item.uuid) || []
  );

  useEffect(() => {
    setValue('step', 3);
  }, []);

  useEffect(() => {
    if (checkedRepositoriesUuids) {
      setValue(
        `checkedSelectedRepositories`,
        checkedRepositoriesUuids.map(item => ({
          uuid: item,
        }))
      );
    }
  }, [checkedRepositoriesUuids]);

  useEffect(() => {
    if (getImportService.totalSummary?.statusTotal) {
      const newTotalValue =
        Object.values(getImportService.totalSummary.statusTotal)?.reduce(
          (acc, number) => acc + number,
          0
        ) || 0;
      setIsTotal(newTotalValue);

      if (!newTotalValue && checkedRepositoriesUuids?.length) {
        setCheckedRepositoriesUuids([]);
      }
    }
  }, [
    getImportService.totalSummary?.statusTotal?.NEW,
    getImportService.totalSummary?.statusTotal?.READY,
  ]);

  const checkedRepositoriesFormatedData = !isMassChecked
    ? { repositories: checkedSelectedRepositoriesValue }
    : {
        excludeRepositories: excludedIds?.map(item => ({ uuid: item })),
      };

  const massEditChange = isEdit => {
    if (isEdit) {
      multipleEditRepositoryModal.setModalData();
      return multipleEditRepositoryModal.toggleModal();
    }

    multipleDeleteRepositoryModal.setModalData(checkedRepositoriesFormatedData);
    return multipleDeleteRepositoryModal.toggleModal();
  };

  return (
    <ServicesSelectionWrapper>
      <div className="font-20 font-weight-medium mt-4 mb-3 ml-4">
        {t('pages.systemServicePage.creationServices')}
        <div className="font-14 font-weight-normal mt-2">
          {t('pages.systemServicePage.servicesImportText')}
        </div>
      </div>

      <div className="d-flex mx-4 align-items-start">
        <div className="d-flex align-items-start justify-content-between w-100">
          <div className="d-flex align-items-end">
            <div className="mb-3">
              <CheckBoxSelector
                checkedUuids={checkedSelectedRepositoriesValue}
                setCheckedUuids={setCheckedRepositoriesUuids}
                allRenderUuids={allRenderUuids}
                total={allRenderUuids.length}
                massTotal={isTotal}
                withoutExcludedIds
                isMassChecked={isMassChecked}
                setIsMassChecked={setIsMassChecked}
                excludedIds={excludedIds}
                dropdownText={t('common.services').toLowerCase()}
              />
            </div>
            {FilterComponent}
          </div>
          <div className="align-self-center">
            <RepositoriesMultipleActions
              massEditChange={massEditChange}
              isDisabled={checkedSelectedRepositoriesValue?.length < 2}
            />
          </div>
        </div>
        {ChipGroupComponent}
      </div>
      <RepositoriesTable
        filterParams={filterParams}
        checkedRepositoriesUuids={checkedRepositoriesUuids}
        setCheckedRepositoriesUuids={setCheckedRepositoriesUuids}
        setAllRenderUuids={setAllRenderUuids}
        isMassChecked={isMassChecked}
        setExcludedIds={setExcludedIds}
        excludedIds={excludedIds}
        multipleEditRepositoryModal={multipleEditRepositoryModal}
        hasSelectedFilters={hasSelectedFilters}
        multipleDeleteRepositoryModal={multipleDeleteRepositoryModal}
        checkedRepositoriesFormatedData={checkedRepositoriesFormatedData}
      />
    </ServicesSelectionWrapper>
  );
}

export default RepositoriesImport;
