import React, { useEffect, useState } from 'react';
import { ChipGroup, Chip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { SelectDirection, SelectOption, SelectVariant } from '@patternfly/react-core/deprecated';

import { StyledMultiSelect } from './styles';

const titleIdMulti = 'multi-typeahead-select-id-1';

function AsyncSelectComponent({ onChange, isMulti, disabled, options = [], values = [] }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isOptions, setOptions] = useState(options);
  const [selected, setSelected] = useState([]);
  const toggleAriaLabel = t('components.select.optionsMenu');
  useEffect(() => {
    setSelected(values.map(item => item.value));
  }, [values]);

  useEffect(() => {
    if (options.length && isMulti && !isOpen) {
      setOptions(options);
    }
  }, [options]);

  const onToggle = () => setOpen(!isOpen);

  const onSelect = (event, selection) => {
    if (isMulti) {
      if (selected.includes(selection)) {
        setSelected(
          prevState => !!prevState.length && prevState.filter(item => item !== selection)
        );
      } else {
        setSelected(prevState => [...prevState, selection]);
      }
    }

    const formattedValue = [...selected, selection];

    onChange(formattedValue);
    onToggle();
  };

  const clearSelection = () => {
    setSelected(isMulti ? [] : '');

    onToggle();
  };

  const deleteItem = id => {
    const copyOfChips = [...selected];
    const filteredCopy = copyOfChips.filter(chip => chip !== id);
    setSelected(filteredCopy);
  };

  const optionsData = isOptions?.map(option => (
    <SelectOption key={option.value} value={option.label} />
  ));

  return (
    <StyledMultiSelect
      variant={SelectVariant.typeaheadMulti}
      aria-label="Select value"
      typeAheadAriaLabel="Select a state"
      onToggle={onToggle}
      onSelect={onSelect}
      onClear={clearSelection}
      selections={selected}
      isOpen={isOpen}
      aria-labelledby={titleIdMulti}
      direction={SelectDirection.down}
      placeholderText={selected.length ? '' : t('pages.adminAuditPage.selectValue')}
      isDisabled={disabled}
      clearSelectionsAriaLabel={t('components.select.clearAll')}
      toggleAriaLabel={toggleAriaLabel}
      removeSelectionAriaLabel={t('common.delete')}
      createText={t('common.add')}
      noResultsFoundText={t('common.noResults')}
      chipGroupComponent={
        <ChipGroup
          collapsedText={selected.length > 3 ? `${selected.length - 3} ${t('common.more')}` : ''}
          expandedText={t('common.hide')}
        >
          {selected.map(currentChip => (
            <Chip key={currentChip} onClick={() => deleteItem(currentChip)}>
              {currentChip}
            </Chip>
          ))}
        </ChipGroup>
      }
    >
      {optionsData}
    </StyledMultiSelect>
  );
}
export default AsyncSelectComponent;
