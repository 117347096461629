import React from 'react';
import { Tooltip } from '@patternfly/react-core';

import { NameWrapper } from '../styles';

function SystemName({ name, maxLength, lineClamps = 1 }) {
  return (
    <Tooltip
      className={name.length > maxLength ? undefined : 'd-none'}
      content={name}
      exitDelay={150}
      animationDuration={150}
    >
      <NameWrapper lineClamps={lineClamps}>{name}</NameWrapper>
    </Tooltip>
  );
}

export default SystemName;
