import React from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import useUserPermissions, { scopes } from 'hooks/useUserPermissions';
import LimitsMonitoring from 'pages/SystemRouter/SystemTiersRouter/SystemTiersPage/Tier/LimitsMonitoring';

import ServerTierDetails from './ServerTierDetails';

function ServerTierReview({ currentServerTier }) {
  const { t } = useTranslation();
  const { checkedPermissions } = useUserPermissions();

  return (
    <div className="bg-gray h-100 font-14 font-weight-normal pt-4 px-4">
      <div className="d-sm-flex flex-column gap-4 flex-lg-row justify-content-between">
        <div className="bg-white col-lg-6">
          <div className="bg-white p-4 mb-3">
            <h3 className="font-weight-semiBold font-14">{t('common.details')}</h3>
            <ServerTierDetails currentServerTier={currentServerTier} />
          </div>
        </div>
        {checkedPermissions(scopes.platform.clusterViewMonitoring, true) && (
          <div className="w-100">
            <div className="bg-white col-12">
              <div className="bg-white">
                <LimitsMonitoring
                  requestFunction={
                    checkedPermissions(scopes.platform.clusterViewMonitoring, true)
                      ? api.monitoring.getServerTierMonitoring
                      : undefined
                  }
                  uuid={currentServerTier?.uuid}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServerTierReview;
