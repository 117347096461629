import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { ActionListGroup, ActionListItem, Button, Tooltip } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import api from 'api';
import useAsync from 'hooks/useAsync';
import { notActiveSystemSelector } from 'store/systems/selectors';
import ModalHeader from 'components/Common/ModalHeader';
import ServiceForm from 'pages/SystemRouter/SystemServicesRouter/AddServicePage/ServiceForm';
import { serviceTypeKeys } from 'constants';
import { tooltipFormDisableMessage } from 'constants/vaidations';

import * as S from './styles';
import { getProgressSystemMessage } from '../../../../../../../constants/tooltips';

function EditRepositoryModal({ isModalVisible, toggleModal, modalData, callback, groupUuidValue }) {
  const { t, i18n } = useTranslation();
  const {
    params: { systemId },
  } = useRouteMatch();
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { run, isSuccess, isLoading } = useAsync();
  const notActiveSystem = useSelector(notActiveSystemSelector);
  const notActiveSystemMessage = useMemo(
    () => (notActiveSystem ? getProgressSystemMessage() : null),
    [notActiveSystem, i18n.language]
  );

  const typeValue = formMethods.watch(`type`);
  const portsValue = formMethods.watch(`ports`);

  useEffect(() => {
    if (modalData) {
      formMethods.reset(modalData);
    }
  }, [modalData]);

  useEffect(() => {
    if (isSuccess) {
      callback();
      toggleModal();
    }
  }, [isSuccess]);

  const onSubmit = async ({ ports, path, type, integration, ...data }) => {
    const formatedData = {
      ...data,
      integration,
      type,
      path,
      ports,
      ...(type === serviceTypeKeys.LIBRARY
        ? {
            ports: undefined,
            path: undefined,
            integration: undefined,
          }
        : {}),
      ...(type === serviceTypeKeys.INTERNAL
        ? {
            path: undefined,
            integration: undefined,
          }
        : {}),
      system: { uuid: systemId },
    };

    run(api.repositories.editRepository(groupUuidValue, data.uuid, formatedData));
  };

  const validedFields = [
    'name',
    'code',
    'buildTemplate.id',
    typeValue === serviceTypeKeys.INTERNAL || typeValue === serviceTypeKeys.EXTERNAL
      ? ['number', 'transportProtocol']
      : undefined,
    typeValue === serviceTypeKeys.EXTERNAL ? 'path' : undefined,
  ];

  const isDisabled =
    notActiveSystemMessage ||
    validedFields.filter(field => {
      if (typeof field === 'string') {
        return !formMethods.getValues(field);
      }

      return field?.filter(item => portsValue.find(port => !port[item]))?.length;
    })?.length ||
    !!Object.keys({ ...formMethods.formState.errors?.name, ...formMethods.formState.errors?.code })
      .length;

  return (
    <S.ReactModalStyled isOpen={isModalVisible} onRequestClose={toggleModal}>
      <ModalHeader
        title={t('pages.systemImportServicePage.editRepository')}
        toggleModal={toggleModal}
      />
      <FormProvider {...formMethods}>
        <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ServiceForm
            groupUuidValue={groupUuidValue}
            serviceUuid={modalData?.uuid}
            modalData={modalData}
            isModalForm
            isModalVisible={isModalVisible}
          />
          <ActionListGroup>
            <Tooltip
              className={isDisabled ? undefined : 'd-none'}
              content={notActiveSystemMessage || tooltipFormDisableMessage()}
              exitDelay={150}
              animationDuration={150}
            >
              <ActionListItem>
                <Button
                  onClick={formMethods.handleSubmit(onSubmit)}
                  isLoading={formMethods.formState.isSubmitting || isLoading}
                  isDisabled={formMethods.formState.isSubmitting || isLoading || isDisabled}
                >
                  {t('common.saveChanges')}
                </Button>
              </ActionListItem>
            </Tooltip>
            <ActionListItem>
              <Button variant="link" id="cancel-button" className="ml-3" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ActionListItem>
          </ActionListGroup>
        </S.Form>
      </FormProvider>
    </S.ReactModalStyled>
  );
}

export default EditRepositoryModal;
